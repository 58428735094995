import { Injectable, Inject, InjectionToken, Optional } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Allows an application to specify a host url that the UrlInterceptor
 * will apply to the beginning of all http requests.
 *
 * ```Typescript
 * providers: [
 *      {
 *          provide: RFX_HTTP_HOST_URL,
 *          useValue: 'https://myhost.com'
 *      }
 * ]
 * ```
 */
export const RFX_HTTP_HOST_URL = new InjectionToken<string>('rfx.http.host.url');

/**
 * Allows an application to specify a RegExp that the UrlInterceptor will
 * check against before applying the host url on an outgoing request. Any
 * url that matches the regex will not have the host url prepended to it.
 *
 * ```Typescript
 * export const ignoreFactory = () => new RegExp('ignoredExpression');
 * ...
 * providers: [
 *      {
 *          provide: RFX_HTTP_URL_IGNORE_CASES,
 *          useFactory: ignoreFactory
 *      }
 * ]
 * ```
 */
export const RFX_HTTP_URL_IGNORE_CASES = new InjectionToken<RegExp>('riafox.http.url.ignorecases');

/**
 * {@link UrlInterceptor}
 *
 * Provides an interceptor that will prepend a given host name to the beginning
 * of all http requests made by the application
 *
 * ```Typescript
 * providers: [
 *      {
 *          provide: HTTP_INTERCEPTORS,
 *          useClass: UrlInterceptor,
 *          multi: true
 *      }
 * ]
 */
@Injectable()
export class UrlInterceptor implements HttpInterceptor {
    constructor(
        @Optional() @Inject(RFX_HTTP_HOST_URL) private _hostUrl: string,
        @Optional() @Inject(RFX_HTTP_URL_IGNORE_CASES) private _ignoreCases: RegExp
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let url = req.url;
        const protocolsToExclude = ['http', 'https', 'file', 'assets'];
        let excluded = false;
        protocolsToExclude.forEach(p => (excluded = url.indexOf(p) === 0 ? true : excluded));
        if (!excluded && typeof this._hostUrl === 'string' && this._hostUrl !== '') {
            if (!!this._ignoreCases && this._ignoreCases instanceof RegExp) {
                if (!this._ignoreCases.test(url)) {
                    url = this._hostUrl + url;
                }
            } else {
                url = this._hostUrl + url;
            }
        }
        const urlReq = req.clone({ url: url });
        return next.handle(urlReq);
    }
}
