export class RfpServiceSettings {
    public hasClientFee: boolean;
    public hasMaterial: boolean;
    public parentControl: boolean;

    constructor(params?: Partial<RfpServiceSettings>) {
        if (!!params) {
            this.hasClientFee = params.hasClientFee || this.hasClientFee;
            this.hasMaterial = params.hasMaterial || this.hasMaterial;
            this.parentControl = params.parentControl || this.parentControl;
        }
    }
}
