import { InvoiceMonthlyType } from '@gm2/common';

export class CreateMonthlyInvoiceDto {
    public month: number;
    public year: number;
    public monthlyType: InvoiceMonthlyType;
    public landscaper: string;

    constructor(params?: Partial<CreateMonthlyInvoiceDto>) {
        if (!!params) {
            this.month = typeof params.month === 'number' ? params.month : this.month;
            this.year = typeof params.year === 'number' ? params.year : this.year;
            this.monthlyType = params.monthlyType || this.monthlyType;
            this.landscaper = params.landscaper || this.landscaper;
        }
    }
}
