export class LocationOperationHours {
    public weekdays: string = '';
    public friday: string = '';
    public weekend: string = '';

    constructor(params?: Partial<LocationOperationHours>) {
        if (!!params) {
            this.weekdays = params.weekdays || this.weekdays;
            this.friday = params.friday || this.friday;
            this.weekend = params.weekend || this.weekend;
        }
    }
}
