import { WorkOrderLaborWithoutIds } from '@gm2/common';

export class WorkOrderLabor extends WorkOrderLaborWithoutIds {
	public _id: string = '';

	constructor(params?: Partial<WorkOrderLabor>){
		super(params);
		if(!!params){
			this._id = params._id || this._id;
		}
	}
}