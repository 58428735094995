import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserServiceNew } from '@ngx-gm2/shared/services/user.service';

export namespace SharedActions {
    export class ResetToInitialState {
        static readonly type = '[Shared] Get Reset To Initial State';
    }

    export class ShowConfirmModal {
        static readonly type = '[Shared] Show Confirm Modal';

        constructor(public options: ConfirmDialogOptions) {
        }
    }

    export class ShowConfirmModalResultConfirm {
        static readonly type = '[Shared] Show Confirm Modal Result Confirm';

        constructor(public params: { [key: string]: any }) {
        }
    }

    export class ShowConfirmModalResultCancel {
        static readonly type = '[Shared] Show Confirm Modal Result Cancel';

        constructor(public params: { [key: string]: any }) {
        }
    }
}
export enum ConfirmDialogSelection {
    CANCEL,
    OK,
}
export enum ConfirmDialogType {
    INFO,
    WARN,
    CONFIRM,
}

export interface ConfirmDialogOptions {
    disableClose?: boolean;
    type: ConfirmDialogType;
    title: string;
    msg: string;
    description?: string;
    confirm?: string;
    cancel?: string;
    action?: () => {};
    cancelAction?: () => {};
    params?: {};
}

export interface Shared {
    name: string;
}

const initialState: Shared = {
    name: 'test'
};

@State<Shared>({
    name: 'shared',
    defaults: initialState
})


@Injectable()
export class SharedState {

    constructor(private _dialog: MatDialog,
                private userService:UserServiceNew) {
    }

    @Action(SharedActions.ShowConfirmModal)
    public showConfirmModal(ctx: StateContext<SharedState>, action: SharedActions.ShowConfirmModal) {
        // return  this.userService.openDialog();
        // const dialogRef = this._dialog.open(ConfirmModalComponentV2, {
        //     maxHeight: '90vh',
        //     disableClose: false,
        //     data: {
        //         // message: action.options.msg,
        //         // buttonGreen: false
        //     }
        // });
        // return  dialogRef
        //     .pipe(
        //         map(result => {
        //             if (result.selection == ConfirmDialogSelection.OK && result.type === ConfirmDialogType.CONFIRM) {
        //                 return ctx.dispatch(new SharedActions.ShowConfirmModalResultConfirm({ tesT: 1 }));
        //
        //             } else {
        //                 return ctx.dispatch(new SharedActions.ShowConfirmModalResultCancel({ tesT: 1 }));
        //
        //             }
        //         }),
        //         catchError(err => throwError(() => err))
        //     ).subscribe()

    }
}
