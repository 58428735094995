export class LocationsRecentMediaDto {
	public locationIds: string[];
	public maxMedia: number;

	constructor(params?: Partial<LocationsRecentMediaDto>) {
		if(!!params){
			this.locationIds = params.locationIds || this.locationIds;
			this.maxMedia = params.maxMedia || this.maxMedia;
		}
	}
}