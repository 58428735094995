import { LaborWithoutIds } from '@gm2/common';

export class Labor extends LaborWithoutIds {
	public _id: string;

	constructor(params?: Partial<Labor>){
		super(params);
		if(!!params){
			this._id = params._id || this._id;
		}
	}
}