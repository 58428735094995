import { InvoiceRejectionReason } from '../../enums';

export class InvoiceDeclinedWithoutIds {
    public timesheetIds: any[] = [];
    public message: string;
    public reason: InvoiceRejectionReason;

    constructor(params?: Partial<InvoiceDeclinedWithoutIds>) {
        if (!!params) {
            this.timesheetIds = Array.isArray(params.timesheetIds)
                ? params.timesheetIds
                : this.timesheetIds;

            this.message = params.message || this.message;
            this.reason = params.reason || this.reason;
        }
    }
}
