
import { WorkOrderDetailsWithoutIds } from './work-order-details';
import { WorkOrderPhaseWithoutIds } from './work-order-phase';

export class WorkOrderPhaseWithWorkOrderDetailsWithoutIds extends WorkOrderPhaseWithoutIds {
    public workOrderDetails: WorkOrderDetailsWithoutIds;

    constructor(params?: Partial<WorkOrderPhaseWithWorkOrderDetailsWithoutIds>) {
        super(params);
        if (!!params) {
            this.workOrderDetails = !!params.workOrderDetails
                ? new WorkOrderDetailsWithoutIds(params.workOrderDetails)
                : this.workOrderDetails;
        }
    }
}
