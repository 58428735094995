export enum ServiceTypeSubClass {
    Contractual = '[ServiceTypeSubClass] Contractual',
    NonContractCustomers = '[ServiceTypeSubClass] Non-contract customers',
    OpAdmin = '[ServiceTypeSubClass] Op Admin',
    SnowRemoval = '[ServiceTypeSubClass] Snow Removal',
    WorkOrder = '[ServiceTypeSubClass] Work Order'
}

export namespace ServiceTypeSubClass {
    export function toString(type: ServiceTypeSubClass): string {
        switch (type) {
            case ServiceTypeSubClass.Contractual:
                return 'Contractual';
            case ServiceTypeSubClass.NonContractCustomers:
                return 'Non-contract customers';
            case ServiceTypeSubClass.OpAdmin:
                return 'Op Admin';
            case ServiceTypeSubClass.SnowRemoval:
                return 'Snow Removal';
            case ServiceTypeSubClass.WorkOrder:
                return 'Work Order';
            default:
                return '';
        }
    }

    export const members: ServiceTypeSubClass[] = [
        ServiceTypeSubClass.Contractual,
        ServiceTypeSubClass.NonContractCustomers,
        ServiceTypeSubClass.OpAdmin,
        ServiceTypeSubClass.SnowRemoval,
        ServiceTypeSubClass.WorkOrder
    ];
}
