<rfx-grid-filter-control>
	<mat-form-field appearance="outline">
		<mat-label>{{ placeholder }}</mat-label> 
		<input 
			matInput 
			type="text" 
			placeholder=""
			[formControl]="controlGroup.get('value')"
		/>
	</mat-form-field>
</rfx-grid-filter-control>