export enum MaterialActiveIngredient {
    Rocksalt = '[MaterialActiveIngredient] Rocksalt',
    Magnesium = '[MaterialActiveIngredient] Magnesium',
    Calcium = '[MaterialActiveIngredient] Calcium',
    Sand = '[MaterialActiveIngredient] Sand',
    LiquidDeIcer = '[MaterialActiveIngredient] Liquid De-Icer'
}

export namespace MaterialActiveIngredient {
    export function toString(activeIngredient: MaterialActiveIngredient): string {
        switch (activeIngredient) {
            case MaterialActiveIngredient.Rocksalt:
                return 'Rocksalt';
            case MaterialActiveIngredient.Magnesium:
                return 'Magnesium';
            case MaterialActiveIngredient.Calcium:
                return 'Calcium';
            case MaterialActiveIngredient.Sand:
                return 'Sand';
            case MaterialActiveIngredient.LiquidDeIcer:
                return 'Liquid De-Icer';
            default:
                return '';
        }
    }

    export function ineffectiveTemperature(activeIngredient: MaterialActiveIngredient): number {
        switch (activeIngredient) {
            case MaterialActiveIngredient.Rocksalt:
                return 15;
            case MaterialActiveIngredient.Magnesium:
                return 0;
            case MaterialActiveIngredient.Calcium:
                return -13;
            default:
                return;
        }
    }

    export const members: MaterialActiveIngredient[] = [
        MaterialActiveIngredient.Rocksalt,
        MaterialActiveIngredient.Magnesium,
        MaterialActiveIngredient.Calcium,
        MaterialActiveIngredient.Sand,
        MaterialActiveIngredient.LiquidDeIcer
    ];

    export const consumptionRateMembers: MaterialActiveIngredient[] = [
        MaterialActiveIngredient.Rocksalt,
        MaterialActiveIngredient.Magnesium,
        MaterialActiveIngredient.Calcium
    ];
}
