import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RfxFormField, RfxFormFieldType } from '@refactor/common';

@Component({
    selector: 'rfx-form-field-generator',
    templateUrl: './form-field-generator.component.html',
    styleUrls: ['./form-field-generator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldGeneratorComponent {
    public readonly RfxFormFieldType: typeof RfxFormFieldType = RfxFormFieldType;

    /**
     * Data for formfield.
     */
    @Input() public field: RfxFormField<string>;

    /**
     * If preview is true, display fields without formGroup/formControlNames/errors.
     * Defaults to false.
     */
    @Input() public preview: boolean = false;

    /**
     * Reference to parent formGroup.
     * Only required if preview is false.
     */
    @Input() public form?: UntypedFormGroup;

    public compareWith(selectValue: any, userSelectedValue: any): boolean {
        return (
            !!selectValue &&
            !!userSelectedValue &&
            selectValue === userSelectedValue
        );
    }
}
