export class UserNotificationSettings {
    // Admin
    public newCompanyRegistered: boolean = false;
    public spProfileComplete: boolean = false;
    public serviceOrMaterialsPendingReview: boolean = false;
    public invoicePendingApproval: boolean = false;
    public siteEngineeringPlanApproved: boolean = false;

    // SP
    public bidAwarded: boolean = false;
    public invoiceRejected: boolean = false;
    public timesheetDeclined: boolean = false;
    public joinRequest: boolean = false;
    public workOrderApprovedForInvoice: boolean = false;
    public workOrderPhaseAssignmentOffer: boolean = false;

    // SP or Admin
    public insuranceLapsed: boolean = false;

    // Client
    public siteEngineeringPlanAwaitingApproval: boolean = false;

    constructor(params?: Partial<UserNotificationSettings>) {
        if (!!params) {
            this.newCompanyRegistered = params.newCompanyRegistered || this.newCompanyRegistered;
            this.spProfileComplete = params.spProfileComplete || this.spProfileComplete;
            this.serviceOrMaterialsPendingReview = params.serviceOrMaterialsPendingReview || this.serviceOrMaterialsPendingReview;
            this.invoicePendingApproval = params.invoicePendingApproval || this.invoicePendingApproval;
            this.bidAwarded = params.bidAwarded || this.bidAwarded;
            this.invoiceRejected = params.invoiceRejected || this.invoiceRejected;
            this.insuranceLapsed = params.insuranceLapsed || this.insuranceLapsed;
            this.siteEngineeringPlanApproved = params.siteEngineeringPlanApproved || this.siteEngineeringPlanApproved;
            this.timesheetDeclined = params.timesheetDeclined || this.timesheetDeclined;
            this.joinRequest = params.joinRequest || this.joinRequest;
            this.workOrderApprovedForInvoice = params.workOrderApprovedForInvoice || this.workOrderApprovedForInvoice;
            this.workOrderPhaseAssignmentOffer = params.workOrderPhaseAssignmentOffer || this.workOrderPhaseAssignmentOffer;
            this.siteEngineeringPlanAwaitingApproval = params.siteEngineeringPlanAwaitingApproval || this.siteEngineeringPlanAwaitingApproval;
        }
    }
}
