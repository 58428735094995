import { isNull } from '../../utils';

export enum ReportType {
    User = '[ReportType] User',
    ExportLocations = '[ReportType] Export Locations',
    WorkOrderBilling = '[ReportType] Work Order Billing',
    AllServiceReport = '[ReportType] All Service Report',
    AwardedBidsReport = '[ReporType] Awarded Bids Report',
    DailyService = '[ReportType] Daily Service Report',
    ClientInvoice = '[ReportType] Client Invoice Report',
    SPBilling = '[ReportType] SP Billing Report',
    TimeSpent = '[ReportType] Time Spent Report',
    ActiveLocations = '[ReportType] Active Locations By Tenant'
}

export namespace ReportType {
    export function toString(type: ReportType): string {
        switch (type) {
            case ReportType.User:
                return 'User';
            case ReportType.ExportLocations:
                return 'Export Locations';
            case ReportType.WorkOrderBilling:
                return 'Work Order Billing';
            case ReportType.AllServiceReport:
                return 'All Service Report'
            case ReportType.AwardedBidsReport:
                return 'Awarded Bids Report'
            case ReportType.DailyService:
                return 'Daily Service Report'
            case ReportType.ClientInvoice:
                return 'Client Invoice Report'
            case ReportType.SPBilling:
                return 'SP Billing Report'
            case ReportType.TimeSpent:
                return 'Time Spent Report'
            case ReportType.ActiveLocations:
                return 'Active Locations By Tenant'
            default:
                return '';
        }
    }

    export const members: ReportType[] = [
        ReportType.User,
        ReportType.ExportLocations,
        ReportType.WorkOrderBilling,
        ReportType.AllServiceReport,
        ReportType.AwardedBidsReport,
        ReportType.DailyService,
        ReportType.ClientInvoice,
        ReportType.SPBilling,
        ReportType.TimeSpent,
        ReportType.ActiveLocations
    ];
}
