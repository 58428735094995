export type SortDirection = SortDirection.ESortDirection;

export namespace SortDirection {
    export const enum ESortDirection {
        Ascending = 1,
        Descending = -1
    }

    export const Ascending = ESortDirection.Ascending;
    export const Descending = ESortDirection.Descending;
    export const members: SortDirection[] = [SortDirection.Ascending, SortDirection.Descending];

    export function toString(type: SortDirection): string {
        switch (type) {
            case SortDirection.Ascending:
                return 'asc';
            case SortDirection.Descending:
                return 'desc';
            default:
                return '';
        }
    }

    export function fromString(direction: string): SortDirection {
        switch (direction.trim().toLowerCase()) {
            case 'asc':
            case 'ascending':
                return SortDirection.Ascending;
            case 'desc':
            case 'descending':
                return SortDirection.Descending;
            default:
                return null;
        }
    }
}
