<form [formGroup]="rfpPricingForm" (ngSubmit)="submitRfpPricing(rfpPricingForm)">
    <div class="service-pricing-wrapper" formArrayName="services" [class.monthly]="isMonthly">
        <!-- Monthly Contract -->
        <ng-container *ngIf="isMonthly; else notMonthly">
            <div class="monthly-services">
                <div
                    class="basic-service"
                    [formGroupName]="i"
                    *ngFor="let service of servicePricingFormArray.controls; index as i"
                >
                    <strong>{{ service.get('name').value }}</strong>
                    <p>{{ service.get('description').value }}</p>
                </div>
            </div>
            <div class="right">
                <div class="right-interior">
                    <!-- *ngIf="isSPContract()" -->
                    <mat-form-field
                        floatLabel="always"
                        *ngIf="false"
                    >
                        <mat-label>Requested Price</mat-label>
                        <input
                            matInput
                            currencyMask
                            [options]="{
                                align: 'left',
                                prefix: '$ '
                            }"
                            [formControl]="rfpPricingForm.controls.monthlyRequestedPrice"
                        />
                    </mat-form-field>
                    <mat-form-field floatLabel="always">
                        <mat-label>Client Price</mat-label>
                        <input
                            matInput
                            currencyMask
                            [options]="{
                                align: 'left',
                                prefix: '$ '
                            }"
                            [formControl]="rfpPricingForm.controls.monthlyClientPrice"
                        />
                    </mat-form-field>
                </div>
                <div class="actions">
                    <!-- *ngIf="isSPContract()" -->
                    <mat-form-field
                        floatLabel="always"
                        *ngIf="false"
                    >
                        <mat-label>Requested Price Label</mat-label>
                        <input
                            matInput
                            placeholder="Suggested Price"
                            [formControl]="rfpPricingForm.controls.requestedPriceLabel"
                        />
                    </mat-form-field>
                    <button mat-raised-button type="submit" color="primary">
                        Save Pricing
                    </button>
                </div>
            </div>
        </ng-container>

        <!-- Per Push and Hourly Contract -->
        <ng-template #notMonthly>
            <div
                class="service"
                [formGroupName]="i"
                *ngFor="let service of servicePricingFormArray.controls; index as i"
            >
                <div class="left">
                    <strong>{{ service.get('name').value }}</strong>
                    <p>{{ service.get('description').value }}</p>
                </div>
                <ng-template
                        [ngIf]="isSPContract() || hasClientPricing(service) || hasMaterialPricing(service) || hasClientFee(service)">
                <div class="right">
                    <!-- *ngIf="isSPContract()" -->
                    <mat-form-field
                        floatLabel="always"
                        *ngIf="false"
                    >
                        <mat-label>Requested Price</mat-label>
                        <input
                            matInput
                            currencyMask
                            [options]="{
                                align: 'left',
                                prefix: '$ '
                            }"
                            formControlName="requestedPrice"
                        />
                    </mat-form-field>
                    <!-- isChild NAND hasParentControl -->
                    <mat-form-field
                            floatLabel="always">
                        <mat-label>Client Price</mat-label>
                        <input
                            matInput
                            currencyMask
                            [options]="{
                                align: 'left',
                                prefix: '$ '
                            }"
                            formControlName="clientPrice"
                        />
                    </mat-form-field>
                    <mat-form-field
                            floatLabel="always"
                            *ngIf="hasMaterialPricing(service) || contract.serviceSource === '[ServiceSource] ClientServices'">
                        <mat-label>Material Price</mat-label>
                        <input
                            matInput
                            currencyMask
                            [options]="{
                                align: 'left',
                                prefix: '$ '
                            }"
                            formControlName="materialPrice"
                        />
                    </mat-form-field>
                    <mat-form-field
                            floatLabel="always"
                            *ngIf="hasClientFee(service)">
                        <mat-label>Client Fee</mat-label>
                        <input
                            matInput
                            currencyMask
                            [options]="{
                                align: 'left',
                                prefix: '$ '
                            }"
                            formControlName="clientFee"
                        />
                    </mat-form-field>
                </div>
                </ng-template>
            </div>
            <div class="actions">
                <!-- *ngIf="contract.identity.assigneeType === AssigneeType.ServicePartner" -->
                <mat-form-field
                    floatLabel="always"
                    *ngIf="false"
                >
                    <mat-label>Requested Price Label</mat-label>
                    <input
                        matInput
                        placeholder="Suggested Price"
                        [formControl]="rfpPricingForm.controls.requestedPriceLabel"
                    />
                </mat-form-field>
                <button [disabled]="!submitDisabledStatus" mat-raised-button type="submit" color="primary">
                    Save Pricing
                </button>
            </div>
        </ng-template>
    </div>
</form>
