import { Address } from '../base/address';
import { CompanyTier, CompanyRating, ContactType } from '../../enums';
import { BaseMediaDocumentWithoutIds } from '../media/base-media-document';

export class CompanyProfileWithoutIds {
    public _id: any;
    public name: string = '';
    public phone: string = '';
    public fax?: string;
    public address: Address = new Address();
    public email: string = '';
    public preferredContactType: ContactType = ContactType.Email;
    public logo: BaseMediaDocumentWithoutIds = null;
    public tier: CompanyTier = null;
    public rating: CompanyRating = null;

    constructor(params?: Partial<CompanyProfileWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.phone = params.phone || this.phone;
            this.fax = params.fax || this.fax;
            this.address = !!params.address ? new Address(params.address) : this.address;
            this.email = params.email || this.email;
            this.preferredContactType = params.preferredContactType || this.preferredContactType;
            this.logo = !!params.logo ? new BaseMediaDocumentWithoutIds(params.logo) : this.logo;
            this.tier = params.tier || this.tier;
            this.rating = params.rating || this.rating;
        }
    }
}
