import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized, RouterStateSnapshot } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { map, mergeMap, switchMap, takeUntil, withLatestFrom,  } from 'rxjs/operators';
import { RfxHelpService } from '../../services/rfx-help.service'
import { RfxHelpModalComponent } from '../rfx-help-modal/rfx-help-modal.component';
import { RfxHelpSelectOption } from '@refactor/common';

@Component({
  selector: 'rfx-help-menu',
  templateUrl: './rfx-help-menu.component.html',
  styleUrls: ['./rfx-help-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RfxHelpMenuComponent implements OnInit {

    public availableHelp$: BehaviorSubject<RfxHelpSelectOption[]> =
        new BehaviorSubject([]);

    private _onDestroy$: Subject<void> =
        new Subject();

    constructor(
        private readonly _dialog: MatDialog,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _helpService: RfxHelpService
    ) {}

    ngOnInit(): void {
        // Need to reconstruct the generic form of the url as it would
        // have been before substituting route parameters (e.g.,
        // '/support/help/edit/:id' in place of
        // '/support/help/edit/0123456789').
        const initSnap = !!this._activatedRoute['_routerState'] &&
            !!this._activatedRoute['_routerState']['snapshot']
                ? this._activatedRoute['_routerState']['snapshot']
                : null;
        if (!!initSnap) {
            const url = this._urlFromRouterSnapshot(initSnap);
            this._helpService
                .getRfxHelp(encodeURI(url))
                .subscribe(options => {
                    if (!!options) {
                        this.availableHelp$.next(options);
                    }
                });
        }
        // subsequent route snapshots
        this._router
            .events
            .pipe(
                takeUntil(this._onDestroy$),
                map(event => {
                    return (event instanceof RoutesRecognized)
                        ? this._urlFromRouterSnapshot(event.state)
                        : null;
                }),
                mergeMap(url => {
                    return !!url
                        ? this._helpService.getRfxHelp(encodeURI(url))
                        : of(null)
                })
            )
            .subscribe(options => {
                if (!!options) {
                    this.availableHelp$.next(options);
                }
            });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    private _urlFromRouterSnapshot(snap: RouterStateSnapshot): string {
        const getParamMap = (node: object): object => {
            while (node['children'].length > 0) {
                node = node['children'][0];
            }
            return node['value']['params'];
        };
        const params = getParamMap(snap['_root']);
        let url = snap.url;
        Object.entries(params).forEach(kv => {
            url = url.replace(kv[1], `:${kv[0]}`)
        });
        return url;
    }

    public selectHelp(h: RfxHelpSelectOption): void {
        this._helpService
            .getRfxHelpById(h._id)
            .subscribe(help => {
                this._dialog.open(
                    RfxHelpModalComponent,
                    {
                        autoFocus: true,
                        data: {
                            rfxHelp: help
                        }
                    }
                );
            });
    }
}
