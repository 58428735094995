import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RfxGridFilterControlComponent, RfxGridFilterSelectOption } from '@refactor/ngx/grid';
import { ServiceService, ToastService } from '@gm2/ui-common';
import {
    Observable,
    Subject,
    ReplaySubject,
    BehaviorSubject,
    combineLatest,
    merge,
    EMPTY
} from 'rxjs';
import { map, takeUntil, switchMap, tap, shareReplay, catchError, filter } from 'rxjs/operators';

@Component({
    selector: 'gm2-grid-filter-service-activities-service-multiple',
    templateUrl: './grid-filter-service-activities-service-multiple.component.html',
    styleUrls: ['./grid-filter-service-activities-service-multiple.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: RfxGridFilterControlComponent,
            useExisting: GridFilterServiceActivitiesServiceMultipleComponent
        }
    ],
    // styling mat options proved very difficult without disabling encapsulation
    encapsulation: ViewEncapsulation.None
})
export class GridFilterServiceActivitiesServiceMultipleComponent implements OnInit {
    @Input()
    public name: string = 'serviceIds';
    @Input()
    public placeholder: string = 'Services *';
    @Input()
    public options: RfxGridFilterSelectOption[];
    @Input()
    public limit: number;

    // Inputs for filtering services
    private _serviceType$: BehaviorSubject<string> = new BehaviorSubject(null);
    private _client$: BehaviorSubject<string[]> = new BehaviorSubject(null);
    private _locations$: BehaviorSubject<string[]> = new BehaviorSubject(null);
    private _timeFrame$: BehaviorSubject<string> = new BehaviorSubject(null);
    private _strategicAccountManager$: BehaviorSubject<string> = new BehaviorSubject(null);
    private _productionManager$: BehaviorSubject<string> = new BehaviorSubject(null);

    @Input()
    public set serviceType(value: string) {
        this._serviceType$.next(value);
    }
    @Input()
    public set clients(value: string[]) {
        this._client$.next(value);
    }
    @Input()
    public set locations(value: string[]) {
        this._locations$.next(value);
    }
    @Input()
    public set timeFrame(value: string) {
        this._timeFrame$.next(value);
    }
    @Input()
    public set strategicAccountManager(value: string) {
        this._strategicAccountManager$.next(value);
    }
    @Input()
    public set productionManager(value: string) {
        this._productionManager$.next(value);
    }

    public initialOptions: RfxGridFilterSelectOption[] = null;

    public gridServices$: Observable<RfxGridFilterSelectOption[]> = combineLatest(
        this._serviceType$,
        this._client$,
        this._locations$,
        this._timeFrame$,
        this._strategicAccountManager$,
        this._productionManager$
    ).pipe(
        tap(
            ([
                serviceType,
                clients,
                locations,
                timeFrame,
                strategicAccountManager,
                productionManager
            ]) => {
                // console.log('serviceType: ', serviceType);
                // console.log('clients: ', clients);
                // console.log('locations: ', locations);
                // console.log('timeFrame: ', timeFrame);
                // console.log('strategicAccountManager: ', strategicAccountManager);
                // console.log('productionManager: ', productionManager);
            }
        ),
        switchMap(
            ([
                serviceTypeId,
                clientIds,
                locationIds,
                timeFrame,
                strategicAccountManagerId,
                productionManagerId
            ]) =>
                this._serviceService
                    // .getServices()
                    .getServicesFiltered({
                        serviceTypeId: serviceTypeId,
                        timeFrame: timeFrame,
                        clientIds: clientIds,
                        locationIds: locationIds,
                        strategicAccountManagerId: strategicAccountManagerId,
                        productionManagerId: productionManagerId
                    })
                    .pipe(
                        catchError((err, _) => {
                            console.log('unable to get servies', err);
                            // this._toastService.error('Could not create note', err.error.message);
                            return EMPTY;
                        }),
                        map(services =>
                            services
                                .map(service => ({
                                    value: service._id,
                                    label: service.name
                                }))
                        )
                    )
        ),
        shareReplay(1)
    );

    public controlGroup: UntypedFormGroup;

    public searchControl: UntypedFormControl = new UntypedFormControl();

    private _onDestroy: Subject<void> = new Subject<void>();

    private _selectedItems: string[];

    public changed(): void {
        if (!this.limit) {
            return;
        }
        if (this.controlGroup.controls.values.value.length <= this.limit) {
            this._selectedItems = this.controlGroup.controls.values.value;
        } else {
            this.controlGroup.controls.values.setValue(this._selectedItems);
            this._toast.error('Limit Reached');
        }
    }

    private filterOnSearch(): void {
        if (!this.options || !this.initialOptions) {
            return;
        }
        let search = this.searchControl.value;
        if (!search) {
            this.options = this.initialOptions.slice();
            return;
        } else {
            search = search.toLowerCase();
        }
        this.options = this.initialOptions.filter(
            service => service.label.toLowerCase().indexOf(search) > -1
        );
    }

    constructor(private _serviceService: ServiceService, private _toast: ToastService) {}

    ngOnInit(): void {
        this.controlGroup = new UntypedFormGroup({
            values: new UntypedFormControl('')
        });
        this.gridServices$
            .pipe(
                filter((services: any) => !!services),
                takeUntil(this._onDestroy)
            )
            .subscribe(services => {
                this.options = services;
                this.initialOptions = services;

                // if (
                //     this.initialOptions === null ||
                //     this.options.length > this.initialOptions.length
                // ) {
                //     this.initialOptions = services;
                // }
            });
        this.searchControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterOnSearch();
        });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}
