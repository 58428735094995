import { CompanyIdentityWithoutIds } from '../company/company-identity';
import { LocationIdentityWithoutIds } from '../location/location-identity';
import { WorkOrderWorkCategory, WorkOrderStatus, WorkOrderLaborCostType, WorkOrderBillingType } from '../../enums';

export class WorkOrderDetailsWithoutIds {
    public _id: any;
    public clientIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public locationIdentity: LocationIdentityWithoutIds = new LocationIdentityWithoutIds();
    public workOrderNumber: string;
    public completionDate: Date;
    public approvedForInvoicingDate: Date;
    public workOrderStatus: WorkOrderStatus;
    public workCategory: WorkOrderWorkCategory;
    public costType: WorkOrderLaborCostType;
    public billingType: WorkOrderBillingType;

    constructor(params?: Partial<WorkOrderDetailsWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.clientIdentity = !!params.clientIdentity
                ? new CompanyIdentityWithoutIds(params.clientIdentity)
                : this.clientIdentity;
            this.locationIdentity = !!params.locationIdentity
                ? new LocationIdentityWithoutIds(params.locationIdentity)
                : this.locationIdentity;
            this.workOrderNumber = params.workOrderNumber || this.workOrderNumber;
            this.completionDate = params.completionDate || this.completionDate;
            this.approvedForInvoicingDate = params.approvedForInvoicingDate || this.approvedForInvoicingDate;
            this.workOrderStatus = params.workOrderStatus || this.workOrderStatus;
            this.workCategory = params.workCategory || this.workCategory;
            this.costType = params.costType || this.costType;
            this.billingType = params.billingType || this.billingType;
        }
    }
}
