import { Injectable } from '@angular/core';
import { UserState } from '@gm2/ui-state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { RfxFormBuilderUIUtil } from '@refactor/ngx/form-builder';
import { Permission } from '@gm2/common';

// Needs to be defined in ui-library becuase it utilizes ui-state
//
// Causes a circular dependency when defined in ui-common where you might
// expect it to be

@Injectable()
export class FormBuilderUIUtil extends RfxFormBuilderUIUtil {
    @Select(UserState.userPermissions)
    public permissions$: Observable<Permission[]>;

    public async canCreate(): Promise<boolean> {
        const p = await this.permissions$.pipe(take(1)).toPromise();
        return p.includes(Permission.FormBuilder);
    }
    public async canEdit(): Promise<boolean> {
        const p = await this.permissions$.pipe(take(1)).toPromise();
        return p.includes(Permission.FormBuilder);
    }
    public async canDelete(): Promise<boolean> {
        const p = await this.permissions$.pipe(take(1)).toPromise();
        return p.includes(Permission.FormBuilder);
    }
    public async canExportData(): Promise<boolean> {
        const p = await this.permissions$.pipe(take(1)).toPromise();
        return p.includes(Permission.FormBuilder);
    }

    public getConfirmFunctions(): { [key: string]: () => Promise<boolean> } {
        return {};
    }
}
