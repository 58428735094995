const enum EAdapterType {
    MapValue = '[Adapter Type] map value',
    MapClass = '[Adapter Type] map class'
}

type AdapterType = EAdapterType;

namespace AdapterType {
    /** MapValue */
    export const MapValue = EAdapterType.MapValue;
    /** MapClass */
    export const MapClass = EAdapterType.MapClass;
    /**
     * Contains an ordered array of the valid AdapterType members
     */
    export const members: AdapterType[] = [AdapterType.MapClass, AdapterType.MapValue];
}

export { AdapterType };
