import { isNull } from '../../utils';

export class RfpServiceWithoutIds {
    public serviceId: any;
    public requestedPrice: number;
    public clientPrice: number;
    public materialPrice: number;
    public clientFee: number;

    constructor(params?: Partial<RfpServiceWithoutIds>) {
        if (!!params) {
            this.serviceId = params.serviceId || this.serviceId;
            this.requestedPrice = !isNull(params.requestedPrice)
                ? params.requestedPrice
                : this.requestedPrice;
            this.clientPrice = !isNull(params.clientPrice) ? params.clientPrice : this.clientPrice;
            this.materialPrice = !isNull(params.materialPrice) ? params.materialPrice : this.materialPrice;
            this.clientFee = !isNull(params.clientFee) ? params.clientFee : this.clientFee;
        }
    }
}
