<div class="crl-fields">
<mat-form-field>
    <mat-select
        [formControl]="client"
        placeholder="Select Client"
        [compareWith]="compareWith"
    >
        <mat-option>
            <ngx-mat-select-search
                [formControl]="clientSearch"
                placeholderLabel="Search"
                noEntriesFoundLabel="No matching results found"
            ></ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="null">
            Select Client
        </mat-option>
        <mat-option
            *ngFor="let client of (clientsFiltered$ | async)"
            [value]="client._id"
        >
            {{ client.profile.name }}
        </mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field>
    <mat-select
        [formControl]="formControl"
        placeholder="Select Location"
        [compareWith]="compareWith"
    >
        <mat-option>
            <ngx-mat-select-search
                [formControl]="locationSearch"
                placeholderLabel="Search"
                noEntriesFoundLabel="No matching results found"
            ></ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="null">
            Select Location
        </mat-option>
        <mat-option
            *ngFor="let location of (locationsFiltered$ | async)"
            [value]="location._id"
        >
            {{ location.identity.name }}
        </mat-option>
    </mat-select>
</mat-form-field>
</div>
