import { RfpWithoutIds } from '@gm2/common';
import { Company } from '../company/Company';

export class Rfp extends RfpWithoutIds {
    public _id: string = '';
    public companyId: string = '';
    public awardedCompanyContract?: Pick<Company, 'companies' | 'profile' | 'type'>;

    constructor(params?: Partial<Rfp>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.companyId = params.companyId || this.companyId;
            this.awardedCompanyContract = !!params.awardedCompanyContract ? params.awardedCompanyContract : this.awardedCompanyContract;
        }
    }
}
