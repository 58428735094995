import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BillingType } from '@gm2/common';

interface IDialogData {
    originalBillingType: BillingType;
    newBillingType: BillingType;
}

@Component({
    selector: 'gm2-confirm-pricing-change-modal',
    templateUrl: './confirm-pricing-change.component.html',
    styleUrls: ['./confirm-pricing-change.component.scss']
})
export class ConfirmPricingChangeComponent implements OnInit {
    /**
     * Forward BillingType enum to view
     */
    public BillingType: typeof BillingType = BillingType;
    public originalBillingType: BillingType;
    public newBillingType: BillingType;

    constructor(
        private readonly _dialogRef: MatDialogRef<ConfirmPricingChangeComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) data: IDialogData
    ) {
        if (!data.originalBillingType) {
            throw new Error('No original billing type specified');
        }

        if (!data.newBillingType) {
            throw new Error('No new billing type specified');
        }

        this.originalBillingType = data.originalBillingType;
        this.newBillingType = data.newBillingType;
    }

    ngOnInit(): void {}

    public onCancel(): void {
        this._dialogRef.close(false);
    }

    public onAccept(): void {
        this._dialogRef.close(true);
    }
}
