import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    ViewEncapsulation,
    ContentChild,
    ChangeDetectorRef,
    Directive,
    ViewContainerRef,
    ElementRef,
    ViewChild,
    TemplateRef
} from '@angular/core';
import { RfxGridDataSource } from '../GridDataSource';
import { GridListComponent } from '../grid-list/grid-list.component';
import { Subscription } from 'rxjs';
import { GridActionBarComponent } from '../grid-action-bar/grid-action-bar.component';
import { GridPaginatorComponent } from '../grid-paginator/grid-paginator.component';
import { GridFilterComponent } from '../grid-filter/grid-filter.component';
import { GridDetailViewItemDef } from '../grid-detail-view/grid-detail-view.component';
import { Pagination } from '@refactor/common';
import { GridHeaderComponent } from '../grid-header/grid-header.component';

@Directive({
    selector: '[rfxDetailViewOutlet]'
})
export class GridDetailViewOutlet {
    constructor(
        public viewContainer: ViewContainerRef,
        public elementRef: ElementRef
    ) {}
}

@Component({
    selector: 'rfx-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    exportAs: 'rfxGrid'
})
export class GridComponent<T> implements OnInit {
    @Input()
    public dataSource: RfxGridDataSource<any>;

    @ContentChild(GridHeaderComponent)
    public _pageHeaderNonStatic: GridHeaderComponent;
    @ContentChild(GridHeaderComponent, { static: true })
    public _pageHeaderStatic: GridHeaderComponent;
    public get _pageHeader(): GridHeaderComponent {
        return this._pageHeaderNonStatic || this._pageHeaderStatic;
    }

    @ContentChild(GridListComponent)
    public _gridListChildNonStatic: GridListComponent<T>;
    @ContentChild(GridListComponent, { static: true })
    public _gridListChildStatic: GridListComponent<T>;
    public get _gridListChild(): GridListComponent<T> {
        // TODO(dallas): we need this hacky workaround in order to support both Ivy
        // and ViewEngine. We should clean this up once Ivy is the default renderer.
        return this._gridListChildNonStatic || this._gridListChildStatic;
    }

    @ContentChild(GridFilterComponent)
    public _gridFilterChildNonStatic: GridFilterComponent<T>;
    @ContentChild(GridFilterComponent, { static: true })
    public _gridFilterChildStatic: GridFilterComponent<T>;
    public get _gridFilterChild(): GridFilterComponent<T> {
        return this._gridFilterChildNonStatic || this._gridFilterChildStatic;
    }

    @ContentChild(GridActionBarComponent)
    public _gridActionBarChildNonStatic: GridActionBarComponent<T>;
    @ContentChild(GridActionBarComponent, { static: true })
    public _gridActionBarChildStatic: GridActionBarComponent<T>;
    public get _gridActionBarChild(): GridActionBarComponent<T> {
        return (
            this._gridActionBarChildNonStatic || this._gridActionBarChildStatic
        );
    }

    @ContentChild(GridPaginatorComponent)
    public _gridPaginatorChildNonStatic: GridPaginatorComponent<T>;
    @ContentChild(GridPaginatorComponent, { static: true })
    public _gridPaginatorChildStatic: GridPaginatorComponent<T>;
    public get _gridPaginatorChild(): GridPaginatorComponent<T> {
        return (
            this._gridPaginatorChildNonStatic || this._gridPaginatorChildStatic
        );
    }

    @ContentChild(GridDetailViewItemDef)
    public _gridDetailViewNonStatic: GridDetailViewItemDef<T>;
    @ContentChild(GridDetailViewItemDef, { static: true })
    public _gridDetailViewStatic: GridDetailViewItemDef<T>;
    public get _gridDetailView(): GridDetailViewItemDef<T> {
        return this._gridDetailViewNonStatic || this._gridDetailViewStatic;
    }

    @ViewChild(GridDetailViewOutlet)
    public _detailOutlet: GridDetailViewOutlet;
    @ViewChild('noDetailSelected', { static: true, read: TemplateRef })
    public _noDetailDefault: TemplateRef<any>;

    private _selectionWatcher: Subscription;
    private _detailItemWatcher: Subscription;

    constructor(private _cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (!this.dataSource) {
            throw new Error(
                'A RfxGridDataSource must be provided for the grid!'
            );
        }
        if (!!this._gridListChild) {
            this._gridListChild.dataSource = this.dataSource;
        }
        if (!!this._gridFilterChild) {
            this._gridFilterChild.dataSource = this.dataSource;
        }
        if (!!this._gridActionBarChild) {
            this._gridActionBarChild.dataSource = this.dataSource;
        }
        if (!!this._gridPaginatorChild) {
            this._gridPaginatorChild.dataSource = this.dataSource;
        }
        if (!!this.dataSource._selection) {
            this._selectionWatcher = this.dataSource._selection.changed.subscribe(
                () => {
                    this._cd.markForCheck();
                }
            );
        }
    }

    ngAfterViewInit(): void {
        if (!!this._gridDetailView) {
            // If detail view exists, then bind to detail item
            this._detailItemWatcher = this.dataSource.detailViewItem$.subscribe(
                item => {
                    this._detailOutlet.viewContainer.clear();
                    if (!!item) {
                        let itemIndex = -1;
                        // Better finder if available for rfx objects
                        if (!!item._id) {
                            itemIndex = this.dataSource.data.findIndex(
                                i => item._id === i._id
                            );
                        } else {
                            itemIndex = this.dataSource.data.indexOf(item);
                        }
                        if (itemIndex === -1) {
                            this._detailOutlet.viewContainer.createEmbeddedView(
                                this._noDetailDefault
                            );
                            return;
                        }
                        const hasNext = (): boolean =>
                            this.dataSource.data.length - 1 > itemIndex;
                        const hasNextPage = (): boolean =>
                            this.dataSource.count >
                            this.dataSource.pagination.page *
                                this.dataSource.pagination.limit;
                        const hasPrevious = (): boolean => itemIndex > 0;
                        const hasPreviousPage = (): boolean =>
                            this.dataSource.pagination.page > 1;
                        this._detailOutlet.viewContainer.createEmbeddedView(
                            this._gridDetailView.template,
                            {
                                $implicit: {
                                    item: item,
                                    hasNext: (): boolean =>
                                        hasNext() || hasNextPage(),
                                    next: (): void => {
                                        if (hasNext()) {
                                            const nextItem = this.dataSource
                                                .data[itemIndex + 1];
                                            this.dataSource.openDetailView(
                                                nextItem
                                            );
                                        } else if (hasNextPage()) {
                                            this.dataSource.pagination = new Pagination(
                                                {
                                                    page:
                                                        this.dataSource
                                                            .pagination.page +
                                                        1,
                                                    limit: this.dataSource
                                                        .pagination.limit
                                                }
                                            );
                                        }
                                    },
                                    hasPrevious: (): boolean =>
                                        hasPrevious() || hasPreviousPage(),
                                    previous: (): void => {
                                        if (hasPrevious()) {
                                            const previousItem = this.dataSource
                                                .data[itemIndex - 1];
                                            this.dataSource.openDetailView(
                                                previousItem
                                            );
                                        } else if (hasPreviousPage()) {
                                            this.dataSource.pagination = new Pagination(
                                                {
                                                    page:
                                                        this.dataSource
                                                            .pagination.page -
                                                        1,
                                                    limit: this.dataSource
                                                        .pagination.limit
                                                }
                                            );
                                        }
                                    },
                                    close: (): void => {
                                        this.dataSource.closeDetailView();
                                    }
                                }
                            }
                        );
                    } else {
                        this._detailOutlet.viewContainer.createEmbeddedView(
                            this._noDetailDefault
                        );
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (!!this._selectionWatcher) {
            this._selectionWatcher.unsubscribe();
        }
        if (!!this._detailItemWatcher) {
            this._detailItemWatcher.unsubscribe();
        }
    }

    public get hasSelection(): boolean {
        return (
            !!this.dataSource._selection &&
            !!this.dataSource._selection.hasValue()
        );
    }

    public get hasPageHeader(): boolean {
        return !!this._pageHeader;
    }
}
