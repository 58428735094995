<div
    cdkDropList
    cdkDropListSortingDisabled
    class="field-list"
    [cdkDropListData]="trayFields"
    [cdkDropListEnterPredicate]="noReturnPredicate"
>
    <div class="field-box" *ngFor="let field of trayFields" cdkDrag>
        {{ field.label }}
    </div>
</div>
