import { QuestionnaireAnswers } from "./QuestionnaireAnswers";

export class SubcontractorInfoCreateDto {
    public subcontractorId: string;
    public contractOwnerId: string;
    public requireTimeAllocation: boolean;
    // public questionnaireAnswers: QuestionnaireAnswers = new QuestionnaireAnswers;
    public questionnaireAnswers: any;

    constructor(params?: Partial<SubcontractorInfoCreateDto>) {
        if (!!params) {
            this.subcontractorId = params.subcontractorId || this.subcontractorId;
            this.contractOwnerId = params.contractOwnerId || this.contractOwnerId;
            this.requireTimeAllocation = params.requireTimeAllocation || this.requireTimeAllocation;
            this.questionnaireAnswers = params.questionnaireAnswers || this.questionnaireAnswers;

            // this.questionnaireAnswers = !!params.questionnaireAnswers
            //     ? new QuestionnaireAnswers(params.questionnaireAnswers)
            //     : this.questionnaireAnswers;
        }
    }
}
