import { MappingWithoutIds } from '@gm2/common';
import { IMappingLike } from './IMappingLike';
import { LatLngLiteral } from '@google/maps';

export class Mapping extends MappingWithoutIds implements IMappingLike {
    public _id: string = '';
    public serviceTypeId: string = '';
    public mappingCategoryId: string = '';
    public path: LatLngLiteral[] = [];

    constructor(params?: Partial<Mapping>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId;
            this.mappingCategoryId = params.mappingCategoryId || this.mappingCategoryId;
        }

        this.path = this.points.map(point => ({
            lng: point[0],
            lat: point[1]
        }));
    }
}
