import { TimesheetDates } from '../timesheet/timesheet-dates';

export class LocationServiceHistoryWithoutIds {
    public serviceId: any;
    public serviceName: string;
    public timezone: string;
    public dates: TimesheetDates;

    constructor(params?: Partial<LocationServiceHistoryWithoutIds>) {
        if (!!params) {
            this.serviceId = params.serviceId || this.serviceId;
            this.serviceName = params.serviceName || this.serviceName;
            this.timezone = params.timezone || this.timezone;
            this.dates = !!params.dates ? new TimesheetDates(params.dates) : this.dates;
        }
    }
}
