import { ServiceClassification } from '../../enums';
import { BaseMediaDocumentWithUrlWithoutIds } from '../media/base-media-document-with-url';

export class LocationServiceTypeWithoutIds {
    public _id: any;
    public enabled: boolean = false;
    public enabledClassifications: ServiceClassification[] = [];
    public image?: BaseMediaDocumentWithUrlWithoutIds;

    constructor(params?: Partial<LocationServiceTypeWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.enabled = !!params.enabled;
            this.enabledClassifications = Array.isArray(params.enabledClassifications) ? params.enabledClassifications : this.enabledClassifications;
            this.image = params.image
                ? new BaseMediaDocumentWithUrlWithoutIds(params.image)
                : this.image;
        }
    }
}
