import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyType } from '@gm2/common';

@Component({
    selector: 'gm2-welcome-modal',
    templateUrl: './welcome-modal.component.html',
    styleUrls: ['./welcome-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeModalComponent implements OnInit {
    public readonly paymentGuidelinesPath: string =
        '/assets/2020 SP Payment Guidelines-Revised 03.02.2020.pdf';
    public readonly insuranceRequirementsPath: string = '/assets/Schedule C.2020.Final.pdf';
    public readonly CompanyType: typeof CompanyType = CompanyType;

    constructor(
        public dialogRef: MatDialogRef<WelcomeModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {}

    public close(): void {
        this.dialogRef.close();
    }
}
