<h2 mat-dialog-title>Select Service Partner</h2>
<div mat-dialog-content>
	<form [formGroup]="servicePartnerForm">
		<mat-form-field
			appearance="outline"
		>
		<mat-label>Service Partner</mat-label> 
			<mat-select 
				required
				[formControl]="servicePartnerForm.get('servicePartner')"
			>
				<mat-option>
					<ngx-mat-select-search
						[formControl]="searchControl" 
						placeholderLabel="Search"
						noEntriesFoundLabel="No matching results found"
					></ngx-mat-select-search>
				</mat-option>
				<mat-option 
					*ngFor="let o of (options$ | async)"
					[value]="o.value" 
				> 
					{{ o.label }}
				</mat-option>
			</mat-select> 
		</mat-form-field>
		<mat-spinner 
			*ngIf="(loading$ | async)"
			[diameter]="30"
		></mat-spinner>
		<div class="buttons">
	    <button
	    	*ngIf="allowCancel$ | async"
	    	mat-flat-button 
	    	(click)="close()"
    	>
    		Cancel
    	</button>
	    <button 
	    	mat-flat-button 
	    	color="primary" 
	    	(click)="select()"
	    	[disabled]="!servicePartnerForm.valid"
    	>
    		Select
    	</button>
		</div>
	</form>
</div>

