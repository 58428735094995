import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TermsModalComponent } from '../modals/terms-modal/terms-modal.component';
import { WelcomeModalComponent } from '../modals/welcome-modal/welcome-modal.component';
import {
    ConfirmModalComponent,
    ImageLightboxModalComponent,
    ConfirmPricingChangeComponent,
    AppliedContractAddLocationModalComponent,
    DeclineInvoiceModalComponent,
    SelectPreferredBidModalComponent,
    ConfirmTimesheetEditComponent,
    WorkOrderActivateModalComponent,
    DeclineWorkorderInvoiceModalComponent,
    ImageCarouselModalComponent,
} from '../modals';
import { FinishMappingAreaModalComponent } from '../modals/finish-mapping-area-modal/finish-mapping-area-modal.component';
import {
    MappingType,
    BillingType,
    ServiceType,
    IMappingDetails,
    LocationImageDto,
    LocationSimple,
    TimesheetSimple,
    RfpBidsSimple,
    Bid,
    CompanyType,
    LightboxImage,
    ServiceChild,
    ServiceChildrenDto,
    WorkOrderStatus,
    WorkOrderActiveStatus,
    WorkOrderPhaseWithWorkOrderDetails,
    BaseMediaDocumentWithUrl,
    ServiceSource, CompanySettings, CompanySimple
} from '@gm2/ui-common';
import { AddMappingCategoryModalComponent } from '../modals/add-mapping-category-modal/add-mapping-category-modal.component';
import { ConfirmDeleteModalComponent, ConfirmDuplicateLocationModalComponent } from '../modals';
import { HttpErrorResponse } from '@angular/common/http';
import { SpecificationDocumentsModalComponent } from '../modals/specification-documents-modal/specification-documents-modal.component';
import { Observable, Subject } from 'rxjs';
import { DateModalComponent } from '../modals/date-modal/date-modal.component';
import { DateRangeModalComponent } from '../modals/date-range-modal/date-range-modal.component';
import { CompanyQuestionnaireModalComponent } from '../modals/company-questionnaire-modal/company-questionnaire-modal.component';
import { ServicePartnerModalComponent } from '../modals/service-partner-modal/service-partner-modal.component';
import { StringInputModalComponent } from '../modals/string-input-modal/string-input-modal.component';
import { UploadPricingCsvModalComponent } from '../modals/upload-pricing-csv-modal/upload-pricing-csv-modal.component';
import { MonthlyInvoiceModalComponent } from '../modals/monthly-invoice-modal/monthly-invoice-modal.component';
import { ChildServiceModalComponent } from '../modals/child-service-modal/child-service-modal.component';
import { ServiceEditModalComponent } from '../modals/company-service-edit/company-service-edit.component';
import { GeospatialControlModalComponent } from '../modals/geospatial-services-control-modal/geospatial-control-modal.component';
import { GeospatialModel } from '../../../../../apps/ngx-gm2/src/app/geospatial/models/usage.model';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    constructor(private _dialog: MatDialog) {
    }

    public termsAndConditions(
        termsAccepted: boolean,
        pdfSource: string,
    ): MatDialogRef<TermsModalComponent> {
        const dialogRef: MatDialogRef<TermsModalComponent> = this._dialog.open(
            TermsModalComponent,
            {
                autoFocus: false,
                maxHeight: '90vh',
                data: {
                    accepted: termsAccepted,
                    pdf: pdfSource,
                },
            },
        );
        return dialogRef;
    }

    public welcome(type: CompanyType): MatDialogRef<WelcomeModalComponent> {
        const dialogRef = this._dialog.open(WelcomeModalComponent, {
            autoFocus: false,
            maxHeight: '90vh',
            data: {
                type: type,
            },
        });
        return dialogRef;
    }

    public confirm(message: string, buttonGreen = false): MatDialogRef<ConfirmModalComponent> {
        const dialogRef = this._dialog.open(ConfirmModalComponent, {
            autoFocus: false,
            maxHeight: '90vh',
            data: {
                message,
                buttonGreen,
            },
        });
        return dialogRef;
    }

    public finishMappingArea(
        type: MappingType,
        serviceTypeId$: Subject<string>,
        serviceTypes: ServiceType[],
        preload?: IMappingDetails,
    ): MatDialogRef<FinishMappingAreaModalComponent> {
        return this._dialog.open(FinishMappingAreaModalComponent, {
            autoFocus: true,
            data: { type, serviceTypeId$, serviceTypes, preload },
        });
    }

    public addMappingCategory(): MatDialogRef<AddMappingCategoryModalComponent> {
        return this._dialog.open(AddMappingCategoryModalComponent, {
            autoFocus: true,
        });
    }

    public addContractAppliedLocation(
        locations: LocationSimple[],
    ): MatDialogRef<AppliedContractAddLocationModalComponent> {
        return this._dialog.open(AppliedContractAddLocationModalComponent, {
            autoFocus: true,
            data: { locations },
        });
    }

    /**
     * @param text          The action to confirm. This should be in title case.
     *                      Defaults to "Delete"
     * @param confirmation  The final confirmation text used in the "confirm"
     *                      button. This should be in title case. Defaults to
     *                      the same value as the text parameter.
     */
    public confirmDelete(
        text?: string,
        confirmation?: string,
    ): MatDialogRef<ConfirmDeleteModalComponent> {
        return this._dialog.open(ConfirmDeleteModalComponent, {
            autoFocus: true,
            data: { text, confirmation },
        });
    }

    public confirmDuplicateLocationAddress(
        error: HttpErrorResponse,
    ): MatDialogRef<ConfirmDuplicateLocationModalComponent> {
        return this._dialog.open(ConfirmDuplicateLocationModalComponent, {
            autoFocus: true,
            data: { error },
        });
    }

    public confirmTimesheetEdit(
        message: string,
        confirmation?: string,
    ): MatDialogRef<ConfirmTimesheetEditComponent> {
        return this._dialog.open(ConfirmTimesheetEditComponent, {
            autoFocus: false,
            data: { message: message, confirmation: confirmation },
        });
    }

    public specificationDocuments(
        contractAppliedId: string,
    ): MatDialogRef<SpecificationDocumentsModalComponent> {
        return this._dialog.open(SpecificationDocumentsModalComponent, {
            autoFocus: true,
            data: { contractAppliedId },
        });
    }

    public openImageLightbox(image: LightboxImage): MatDialogRef<ImageLightboxModalComponent> {
        return this._dialog.open(ImageLightboxModalComponent, {
            autoFocus: true,
            data: { image },
        });
    }

    public openImageCarousel(images: BaseMediaDocumentWithUrl[], index: number): MatDialogRef<ImageCarouselModalComponent> {
        return this._dialog.open(ImageCarouselModalComponent, {
            autoFocus: true,
            data: { images, index },
        });
    }

    public date(message: string): MatDialogRef<DateModalComponent> {
        return this._dialog.open(DateModalComponent, {
            autoFocus: true,
            data: { message },
        });
    }

    public editCompanyService(service: any, message: string): MatDialogRef<ServiceEditModalComponent> {
        return this._dialog.open(ServiceEditModalComponent, {
            autoFocus: true,
            data: { service, message },
        });
    }

    public dateRange(showClearOption: boolean = false): MatDialogRef<DateRangeModalComponent> {
        return this._dialog.open(DateRangeModalComponent, {
            autoFocus: true,
            data: { showClearOption },
        });
    }

    public openQuestionnaire(
        answers: Observable<any>,
    ): MatDialogRef<CompanyQuestionnaireModalComponent> {
        return this._dialog.open(CompanyQuestionnaireModalComponent, {
            autoFocus: true,
            data: { answers },
        });
    }

    public servicePartner(
        allowCancel: boolean,
        awardedLandscapers?: { name: string; _id: string }[],
    ): MatDialogRef<ServicePartnerModalComponent> {
        return this._dialog.open(ServicePartnerModalComponent, {
            autoFocus: true,
            disableClose: true,
            data: { allowCancel, awardedLandscapers },
        });
    }

    public stringInput(
        message: string,
        placeholder: string,
        forceResponse: boolean = false,
    ): MatDialogRef<StringInputModalComponent> {
        return this._dialog.open(StringInputModalComponent, {
            autoFocus: true,
            disableClose: forceResponse,
            data: { message, placeholder, forceResponse },
        });
    }

    public confirmPricingChange(
        originalBillingType: BillingType,
        newBillingType: BillingType,
    ): MatDialogRef<ConfirmPricingChangeComponent, boolean> {
        return this._dialog.open(ConfirmPricingChangeComponent, {
            autoFocus: true,
            data: { originalBillingType, newBillingType },
        });
    }

    /**
     * On close returns a boolean indicating whether the contract pricing data
     * needs to be reloaded or not
     */
    public pricingQuickUpload(
        contractId: string,
        source: ServiceSource,
        parentControl: boolean,
    ): MatDialogRef<UploadPricingCsvModalComponent, boolean> {
        return this._dialog.open(UploadPricingCsvModalComponent, {
            autoFocus: true,
            data: { contractId, source, parentControl },
        });
    }

    public monthlyInvoice(landscapers?: { name: string, _id: string }[]): MatDialogRef<MonthlyInvoiceModalComponent> {
        return this._dialog.open(MonthlyInvoiceModalComponent, {
            autoFocus: true,
            data: {
                landscapers,
            },
        });
    }

    /**
     * On close returns true if the invoice was successfully declined and false
     * if the modal was closed without declining the invoice.
     */
    public declineInvoice(
        invoiceId: string,
        timesheets: TimesheetSimple[],
    ): MatDialogRef<DeclineInvoiceModalComponent, boolean> {
        return this._dialog.open(DeclineInvoiceModalComponent, {
            autoFocus: true,
            data: { invoiceId, timesheets },
        });
    }

    public declineWorkorderInvoice(
        invoiceId: string,
        phases: WorkOrderPhaseWithWorkOrderDetails[],
    ): MatDialogRef<DeclineWorkorderInvoiceModalComponent> {
        return this._dialog.open(DeclineWorkorderInvoiceModalComponent, {
            autoFocus: true,
            data: { invoiceId, phases },
        });
    }

    public selectPreferredBid(approvedBids: Bid[]): MatDialogRef<SelectPreferredBidModalComponent> {
        return this._dialog.open(SelectPreferredBidModalComponent, {
            autoFocus: true,
            data: { approvedBids: approvedBids, modalService: this },
        });
    }

    public childService(
        serviceId: string,
        child: ServiceChild,
    ): MatDialogRef<ChildServiceModalComponent> {
        return this._dialog.open(ChildServiceModalComponent, {
            autoFocus: true,
            data: {
                serviceId: serviceId,
                serviceChild: child,
            },
        });
    }

    public activateWorkOrder(
        status: WorkOrderStatus,
        activeStatus: WorkOrderActiveStatus,
        companyType: CompanyType,
    ): MatDialogRef<WorkOrderActivateModalComponent> {
        return this._dialog.open(WorkOrderActivateModalComponent, {
            autoFocus: true,
            data: {
                status: status,
                activeStatus: activeStatus,
                companyType: companyType,
            },
        });
    }

    public openGeospatialControl(data: GeospatialModel.CompanyUsage): MatDialogRef<GeospatialControlModalComponent> {
        return this._dialog.open(GeospatialControlModalComponent, { autoFocus: true, data });
    }
}
