import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Route, RouteConfigLoadEnd } from '@angular/router';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map, catchError, finalize, switchMap, take, takeUntil, tap, shareReplay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { RfxHelpService } from '../../services/rfx-help.service';
import { RfxHelpRouteAggregationService } from '../../services/rfx-help-route-aggregation.service';
import { RfxHelpCreateDto } from '../../models/rfx-help-create.dto';
import { RfxHelpEditDto } from '../../models/rfx-help-edit.dto';
import { RfxHelp } from '@refactor/common';

@Component({
  selector: 'rfx-help-edit',
  templateUrl: './rfx-help-edit.component.html',
  styleUrls: ['./rfx-help-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RfxHelpEditComponent implements OnInit {

    @ViewChild('pageToggle')
    public pageToggle: MatSlideToggle;

    public listUrl$: Observable<string> =
        this._activatedRoute.data.pipe(
            map(data => data.listUrl)
        );

    public form: UntypedFormGroup = this._formBuilder.group({
        name: ['', Validators.required],
        description: [''],
        content: [''],
        pages: ['', Validators.required],
        videoIds: this._formBuilder.array([])
    });

    public currentHelp$: Observable<RfxHelp> =
        this._activatedRoute.paramMap.pipe(
            take(1),
            map(params => params.get('id')),
            switchMap(id => {
                return !!id
                    ? this._helpService.getRfxHelpById(id)
                    : of(null)
            }),
            catchError(err => {
                console.error(err);
                return of(null);
            }),
            tap(help => {
                if (!!help) {
                    for (const v of help.videoIds) {
                        this.addVideoId();
                    }
                    this.form.patchValue(help);
                    const hasPages = !!help.pages &&
                        Array.isArray(help.pages) &&
                        help.pages.length > 0;
                    if (hasPages && !!this.pageToggle) {
                        this.pageToggle.toggle();
                        this.pageAvailabilityChange({
                            source: null,
                            checked: true
                        });
                    }
                }
            }),
            shareReplay(1)
        );

    public submitting$: BehaviorSubject<boolean> =
        new BehaviorSubject(false);
    private _onDestroy$: Subject<void> =
        new Subject();
    public availableRoutes$: BehaviorSubject<string[]>
        = new BehaviorSubject([]);
    public specificPages$: BehaviorSubject<boolean> =
        new BehaviorSubject(false);
    public slideLabel$: Observable<string> =
        this.specificPages$.pipe(
            map(v => v ? 'Specific Pages' : 'All Pages')
        );

    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _helpService: RfxHelpService,
        private readonly _toast: ToastrService,
        private readonly _routeAggService: RfxHelpRouteAggregationService
    ) {}

    ngOnInit(): void {
        this._routeAggService
            .routes$
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(routes => {
                this.availableRoutes$.next(routes);
            });
        this.specificPages$
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(specific => {
                if (specific) {
                    this.form.controls.pages.enable();
                } else {
                    this.form.controls.pages.disable();
                }
            });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    public addVideoId(): void {
        (this.form.controls.videoIds as UntypedFormArray).push(
            new UntypedFormControl('', Validators.required)
        );
    }

    public removeVideoId(idx: number): void {
        (this.form.controls.videoIds as UntypedFormArray).removeAt(idx);
    }

    public asArray(c: AbstractControl): UntypedFormArray {
        return (c as UntypedFormArray);
    }

    public pageAvailabilityChange(event: MatSlideToggleChange): void {
        this.specificPages$.next(event.checked);
    }

    public async onSubmit(): Promise<void> {
        if (!this.form.valid) {
            return;
        }

        this.submitting$.next(true);
        const dto = { ...this.form.value };
        if (!dto.pages) {
            dto.pages = null;
        }
        const currentHelp = await this.currentHelp$.pipe(take(1)).toPromise();

        if (!!currentHelp) {
            this._helpService
                .editRfxHelp(currentHelp._id, dto)
                .pipe(finalize(() => this.submitting$.next(false)))
                .subscribe(
                    () => {
                        this._toast.success('Changes Saved');
                        this.goBack();
                    },
                    err => {
                        console.error(err);
                        this._toast.error('Could not Save', err);
                    }
                )

        } else {
            this._helpService
                .createRfxHelp(dto)
                .pipe(finalize(() => this.submitting$.next(false)))
                .subscribe(
                    () => {
                        this._toast.success('Help Created');
                        this.goBack();
                    },
                    err => {
                        console.error(err);
                        this._toast.error('Could not Create', err);
                    }
                )
        }

    }

    public async goBack(): Promise<void> {
        const listUrl = await this.listUrl$.pipe(take(1)).toPromise();
        this._router.navigateByUrl(listUrl);
    }
}
