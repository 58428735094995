import { CompanyServiceWithoutIds } from '../company/company-service';
import { PackageSnapShotWithoutIds } from '../package/package-snap-shot';

export class RfpSnapShotWithoutIds {
    public package: PackageSnapShotWithoutIds = new PackageSnapShotWithoutIds();
    public companyService: CompanyServiceWithoutIds = new CompanyServiceWithoutIds();

    constructor(params?: Partial<RfpSnapShotWithoutIds>) {
        if (!!params) {
            this.package = !!params.package
                ? new PackageSnapShotWithoutIds(params.package)
                : this.package;

            this.companyService = !!params.companyService
                ? new CompanyServiceWithoutIds(params.companyService)
                : this.companyService;
        }
    }
}
