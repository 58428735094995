import { MaterialMeasurementType } from '@gm2/common';

export class TimesheetServiceMaterial {
    public _id: string = '';
    public name: string = '';
    public amount: number = 0;
    public measurement: MaterialMeasurementType = null;
    public type?: any;
    public snapShot?: any
    public quantity?: any;

    constructor(params?: Partial<TimesheetServiceMaterial>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.amount = params.amount || this.amount;
            this.measurement = params.measurement || this.measurement;
            this.snapShot = params.snapShot || this.snapShot;
            this.quantity = params.quantity || this.quantity;
        }
    }
}
