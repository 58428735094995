import { WorkOrderPhaseWithoutIds } from '@gm2/common';

export class WorkOrderPhase extends WorkOrderPhaseWithoutIds {
	public id: string = '';

	constructor(params?: Partial<WorkOrderPhase>){
		super(params);
		if(!!params){
			this.id = params.id || this.id;
		}
	}
}