export class CompanyIdentityWithoutIds {
    public _id: any;
    public name: string = '';

    constructor(params?: Partial<CompanyIdentityWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
        }
    }
}
