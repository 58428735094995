export class SelectOptionWithoutIds {
    public _id: any;
    public label: string;

    constructor(params?: Partial<SelectOptionWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.label = params.label || this.label;
        }
    }
}
