<h2 mat-dialog-title>Child Service</h2>
<div mat-dialog-content>
	<form [formGroup]="form">
		<mat-form-field>
            <mat-label>Service Name</mat-label>
            <input 
                matInput 
                required
                placeholder="Service Name"
                formControlName="name"
	  	    />
            <mat-error [rfxError]="this.form.get('name')"></mat-error>
		</mat-form-field>
        <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea
                matInput
                required
                placeholder="Description"
                formControlName="description"
            ></textarea>
            <mat-error [rfxError]="this.form.get('description')"></mat-error>
        </mat-form-field>
        <mat-checkbox formControlName="hasMaterial">
            Service has Material
        </mat-checkbox>
        <mat-checkbox formControlName="hasClientFee">
            Service has Client Fee
        </mat-checkbox>
		<div class="buttons">
    	    <button 
    	    	mat-flat-button 
    	    	(click)="close()"
        	>
        		Cancel
        	</button>
    	    <button 
    	    	mat-flat-button 
    	    	color="primary" 
    	    	(click)="submit()"
                [disabled]="(isSubmitting$ | async)"
        	>
        		Submit
        	</button>
		</div>
	</form>
</div>

