<!--DATA: {{dataSource.data$|json}}-->
<div class="rfx-grid-wrapper">
    <div
        class="rfx-grid-header"
        [class.rfx-grid-header--detail-view-open]="
            dataSource.detailViewOpen$ | async
        "
        [class.rfx-grid-header--no-page-header]="!hasPageHeader"
        [class.rfx-grid-header--has-selection]="hasSelection"
    >
        <ng-content select="rfx-grid-header"></ng-content>
        <ng-content select="rfx-grid-action-bar"></ng-content>
    </div>
    <div class="rfx-grid-container">
        <ng-content select="rfx-grid-filter"></ng-content>
        <div
            class="rfx-grid-content"
            [class.rfx-grid-content--no-page-header]="!hasPageHeader"
            [class.rfx-grid-content--has-selection]="hasSelection"
        >
            <ng-content select="div"></ng-content>
            <ng-content select="rfx-grid-list"></ng-content>
            <ng-content select="rfx-grid-paginator"></ng-content>
        </div>
        <div
            class="detail-view-container"
            [class.detail-view-container--open]="
                dataSource.detailViewOpen$ | async
            "
            *ngIf="_gridDetailView"
        >
            <div
                class="detail-view-container__backdrop"
                (click)="dataSource.closeDetailView()"
            ></div>
            <div
                class="detail-view-container__content"
                rfxDetailViewOutlet
            ></div>
        </div>
    </div>
</div>
<ng-template #noDetailSelected>
    <h3>No Item Selected</h3>
</ng-template>
