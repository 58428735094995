import { BaseDoc } from '../base/base-doc';
import { CompanyIdentityWithoutIds } from '../company/company-identity';
import { InvoiceStatus, InvoiceType } from '../../enums';
import { InvoiceDeclinedWithoutIds } from './invoice-declined';
import { InvoiceHistoryWithoutIds } from './invoice-history';
import { InvoiceTotals } from './invoice-totals';
import { InvoiceMonthlyTimeframe } from './invoice-monthly-timeframe';
import { CompanyIdentity } from '../../../../../ui-common/src';

export class InvoiceWithoutIds extends BaseDoc {
    public _id: any;
    public companyIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public invoiceNumber: string;
    public invoiceStatus: InvoiceStatus;
    public totals: InvoiceTotals = new InvoiceTotals();
    public timesheetIds: any[] = [];
    public declined: InvoiceDeclinedWithoutIds = new InvoiceDeclinedWithoutIds();
    public history: InvoiceHistoryWithoutIds[] = [];
    public isMonthly: boolean = false;
    public monthlyTimeframe: InvoiceMonthlyTimeframe;
    public multipleLocations: boolean = false;
    public invoiceType: InvoiceType;
    public landscaperId?: string;
    public landscaper?: CompanyIdentity;

    constructor(params?: Partial<InvoiceWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.invoiceNumber = params.invoiceNumber || this.invoiceNumber;
            this.invoiceStatus = params.invoiceStatus || this.invoiceStatus;

            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentityWithoutIds(params.companyIdentity)
                : this.companyIdentity;

            this.totals = !!params.totals ? new InvoiceTotals(params.totals) : this.totals;

            this.timesheetIds = Array.isArray(params.timesheetIds)
                ? params.timesheetIds
                : this.timesheetIds;

            this.declined = !!params.declined
                ? new InvoiceDeclinedWithoutIds(params.declined)
                : this.declined;

            this.history = Array.isArray(params.history)
                ? params.history.map(history => new InvoiceHistoryWithoutIds(history))
                : this.history;

            this.isMonthly = params.isMonthly || this.isMonthly;

            this.monthlyTimeframe = !!params.monthlyTimeframe
                ? new InvoiceMonthlyTimeframe(params.monthlyTimeframe)
                : this.monthlyTimeframe;

            this.multipleLocations = params.multipleLocations || this.multipleLocations;

            this.invoiceType = params.invoiceType || this.invoiceType;
            this.landscaperId = params.landscaperId ?? this.landscaperId;
            this.landscaper = params.landscaper ?? this.landscaper;
        }
    }
}
