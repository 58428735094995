import { PaginationModel } from '../../interface';

export class Pagination implements PaginationModel {
    public static readonly defaultPage: number = 1;
    public static readonly defaultLimit: number = 25;

    private _page: number = Pagination.defaultPage;
    private _limit: number = Pagination.defaultLimit;

    constructor(params?: Partial<PaginationModel>) {
        if (!!params) {
            this.page = params.page || this._page;
            this.limit = params.limit || this._limit;
        }
    }

    public set page(value: number) {
        if (value <= 0) {
            value = Pagination.defaultPage;
        }

        this._page = value;
    }

    public get page(): number {
        return this._page;
    }

    public set limit(value: number) {
        if (value <= 0) {
            value = Pagination.defaultLimit;
        }

        this._limit = value;
    }

    public get limit(): number {
        return this._limit;
    }

    public get skip(): number {
        return (this.page - 1) * (this.limit || 1);
    }

    /**
     * Magic method that gets called by JSON.stringify() and allows us to fix
     * the JSON representation of the getter fields.
     *
     * See https://stackoverflow.com/a/42107611
     */
    public toJSON(): PaginationModel {
        return {
            page: this.page,
            limit: this.limit
        }
    }
}
