import { Injectable } from '@angular/core';
import {
    HttpService,
    Post,
    Body,
    Get,
    Query,
    Header,
    Path,
    MapValue,
    MapClass,
    Put,
    Delete,
    ResponseType,
} from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { UserCreateDto, User, UserProfile, UserSimple } from '../models';
import { PaginatedList, GridParams } from '@refactor/common';
import { Permission, UserNotificationSettings } from '@gm2/common';

/**
 * @deprecated
 */
@Injectable({
    providedIn: 'root',
})
export class UserService extends HttpService {
    @Get('/user/profile')
    @MapClass(UserProfile)
    public initUserProfile(@Header('Authorization') token: string): Observable<UserProfile> {
        return null;
    }

    @Get('/user')
    @MapClass(User)
    public getLoggedInUserWithToken(@Header('Authorization') token: string): Observable<User> {
        return null;
    }

    @Get('/user')
    @MapClass(User)
    public getLoggedInUser(token?: any): Observable<User> {
        return null;
    }

    @Get('/user/permissions')
    public getUserPermissions(): Observable<Permission[]> {
        return null;
    }

    @Post('/user/create')
    public createUser(@Body() body: UserCreateDto): Observable<void> {
        return null;
    }

    @Post('/user/crew/create')
    public createCrewUser(@Body() body: UserCreateDto): Observable<void> {
        return null;
    }

    @Put('/user/crew/edit/:crewUserId')
    public editCrewUser(
        @Path('crewUserId') id: string,
        @Body() dto: UserCreateDto,
    ): Observable<User> {
        return null;
    }

    @Put('/user/:userId')
    @MapClass(User)
    public editUser(@Path('userId') id: string, @Body() dto: UserCreateDto): Observable<User> {
        return null;
    }

    @Get('/user/:userId')
    @MapClass(User)
    public getUser(@Path('userId') id: string): Observable<User> {
        return null;
    }

    @Put('/user/delete')
    public deleteUsers(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }

    @Post('/user/csvExport', {
        responseType: ResponseType.Text,
    })
    public userCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }

    @Post('/user/crew/csvExport', {
        responseType: ResponseType.Text,
    })
    public crewCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }

    @Post('/user/listSimple')
    @MapValue(
        res =>
            new PaginatedList<User>({
                count: res.count,
                docs: res.docs.map(d => new User(d)),
            }),
    )
    public getUserList(@Body() params: GridParams): Observable<PaginatedList<User>> {
        return null;
    }

    @Put('/user/seenWelcomeModal')
    public seenWelcomeModal(): Observable<any> {
        return null;
    }

    @Get('/user/impersonate/start/:id')
    @MapValue(res => res.token)
    public getImpersonationToken(@Path('id') id: string): Observable<string> {
        return null;
    }

    @Get('/user/impersonate/startnotadmin/:id')
    @MapValue(res => res.token)
    public getInternalImpersonationToken(@Path('id') id: string): Observable<string> {
        return null;
    }

    @Get('/user/byCompany/:id')
    @MapClass(UserSimple)
    public getUserSimpleByCompanyId(@Path('id') id: string): Observable<UserSimple[]> {
        return null;
    }

    @Get('/user/impersonate/stop')
    @MapValue(res => res.token)
    public stopImpersonating(): Observable<string> {
        return null;
    }

    @Get('/user/perm/strategicAccountManager')
    @MapClass(UserSimple)
    public getStrategicAccountManagersByCompanyId(
        @Query('companyId') id?: string,
    ): Observable<UserSimple[]> {
        return null;
    }

    @Get('/user/perm/productionManager')
    @MapClass(UserSimple)
    public getProductionManagersByCompanyId(
        @Query('companyId') id?: string,
    ): Observable<UserSimple[]> {
        return null;
    }

    @Get('/user/perm/facilityManager')
    @MapClass(UserSimple)
    public getFacilitiesManagersByCompanyId(
        @Query('companyId') id: string,
    ): Observable<UserSimple[]> {
        return null;
    }

    @Get('/user/perm/facilityManager/client')
    @MapClass(User)
    public clientGetFacilityManagers(): Observable<User[]> {
        return null;
    }

    @Put('/user/notificationSettings/sp')
    public editSpNotificationSettings(@Body() dto: UserNotificationSettings): Observable<void> {
        return null;
    }

    @Put('/user/notificationSettings/client')
    public editClientNotificationSettings(@Body() dto: UserNotificationSettings): Observable<void> {
        return null;
    }

    @Put('/user/notificationSettings/admin')
    public editAdminNotificationSettings(@Body() dto: UserNotificationSettings): Observable<void> {
        return null;
    }

    @Put('/user/notificationSettings/landscaper')
    public editLandscaperNotificationSettings(@Body() dto: UserNotificationSettings): Observable<void> {
        return null;
    }

    @Post('/user/listSimple?crew=true')
    @MapValue(
        res =>
            new PaginatedList<User>({
                count: res.count,
                docs: res.docs.map(d => new User(d)),
            }),
    )
    public getCrewList(@Body() params: GridParams): Observable<PaginatedList<User>> {
        return null;
    }

    @Post('/user/perm/facilityManagers')
    @MapClass(UserSimple)
    public getFacilitiesManagersByCompanyIds(
        @Body() ids: string[],
    ): Observable<UserSimple[]> {
        return null;
    }

    @Post('/user/check/byEmail')
    public checkByEmail(
        @Body() { email: string },
    ): Observable<boolean> {
        return null;
    }


    @Post('/user/create/bulk')
    public createUsers(@Body() data: { users: any[]}): Observable<string> {
        return null;
    }
}
