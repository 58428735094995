export enum InvoiceRejectionReason {
    DuplicateServices = '[InvoiceRejectionReason] DuplicateServices',
    IncorrectMaterialAmount = '[InvoiceRejectionReason] IncorrectMaterialAmount',
    ServicesNotPerformed = '[InvoiceRejectionReason] ServicesNotPerformed',
    WeatherSiteConditions = '[InvoiceRejectionReason] WeatherSiteConditions',
    TemperatureSnowDepth = '[InvoiceRejectionReason] TemperatureSnowDepth',
    BillingDate = '[InvoiceRejectionReason] BillingDate',
    UnauthorizedServices = '[InvoiceRejectionReason] UnauthorizedServices',
    Other = '[InvoiceRejectionReason] Other'
}

export namespace InvoiceRejectionReason {
    export function toString(reason: InvoiceRejectionReason): string {
        switch (reason) {
            case InvoiceRejectionReason.DuplicateServices:
                return 'Duplicated services in invoice';
            case InvoiceRejectionReason.IncorrectMaterialAmount:
                return 'Incorrect material amounts';
            case InvoiceRejectionReason.ServicesNotPerformed:
                return 'Services not performed';
            case InvoiceRejectionReason.WeatherSiteConditions:
                return 'Weather/site conditions not correct';
            case InvoiceRejectionReason.TemperatureSnowDepth:
                return 'Temperature, snow depth, or other incorrect';
            case InvoiceRejectionReason.BillingDate:
                return 'Services performed are more than 90 days from billing date';
            case InvoiceRejectionReason.UnauthorizedServices:
                return 'Unauthorized services';
            case InvoiceRejectionReason.Other:
                return 'Other';
            default:
                return '';
        }
    }

    export const members: InvoiceRejectionReason[] = [
        InvoiceRejectionReason.DuplicateServices,
        InvoiceRejectionReason.IncorrectMaterialAmount,
        InvoiceRejectionReason.ServicesNotPerformed,
        InvoiceRejectionReason.WeatherSiteConditions,
        InvoiceRejectionReason.TemperatureSnowDepth,
        InvoiceRejectionReason.BillingDate,
        InvoiceRejectionReason.UnauthorizedServices,
        InvoiceRejectionReason.Other
    ];
}
