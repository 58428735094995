import { AwardedBidWithoutIds } from '../rfp/awarded-bid';
import { ClientPricingWithoutIds } from '../rfp/client-pricing';
import { PackageSnapShotWithoutIds } from '../package/package-snap-shot';
import { ClientCompantServiceWithoutIds } from '../company/client-company-service';

export class TimesheetRfpSnapShotWithoutIds {
    public awardedBid: AwardedBidWithoutIds;
    public clientPricing: ClientPricingWithoutIds;
    public package: PackageSnapShotWithoutIds;
    public companyService: ClientCompantServiceWithoutIds;

    constructor(params?: Partial<TimesheetRfpSnapShotWithoutIds>) {
        if (!!params) {
            this.awardedBid = !!params.awardedBid
                ? new AwardedBidWithoutIds(params.awardedBid)
                : this.awardedBid;

            this.clientPricing = !!params.clientPricing
                ? new ClientPricingWithoutIds(params.clientPricing)
                : this.clientPricing;

            this.package = !!params.package
                ? new PackageSnapShotWithoutIds(params.package)
                : this.package;

            this.companyService = !!params.companyService
                ? new ClientCompantServiceWithoutIds(params.companyService)
                : this.companyService;
        }
    }
}
