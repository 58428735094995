import { ValidatorFn, AbstractControl } from '@angular/forms';

/**
 * Validator that requires the uploaded file to match specific file types.
 * @param types Array of file extensions. Ex: ['png', 'jpg', 'pdf']
 * @returns An error map with the requiredFileType property if the validation check fails, otherwise null.
 */
export function RequiredFileType(types: Array<string>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        // path here is this -> 'C:\fakepath\Zone720-hardedge-B.png'
        const path = control.value;
        if (path && types.length > 0) {
            const extension = path
                .split('.')[1]
                .toLowerCase()
                .trim();

            if (!types.includes(extension)) {
                return {
                    requiredFileType: true
                };
            }
            return null;
        }
        return null;
    };
}

/** Error message for requiredFileType error. */
export const requiredFileTypeError: any = {
    requiredFileType: 'Unsupported file type'
};
