import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LightboxImage } from '@gm2/ui-common';

@Component({
    selector: 'gm2-image-lightbox-modal',
    templateUrl: './image-lightbox-modal.component.html',
    styleUrls: ['./image-lightbox-modal.component.scss']
})
export class ImageLightboxModalComponent implements OnInit {
    public readonly image: LightboxImage;

    constructor(
        private readonly dialogRef: MatDialogRef<ImageLightboxModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        data: {
            image: LightboxImage;
        }
    ) {
        this.image = data.image;
    }

    ngOnInit(): void {}

    public onCancel(): void {
        this.dialogRef.close(null);
    }
}
