import { DateTimeBucket } from '@refactor/common';
import { parse } from 'date-fns';

export class TimezoneAwareDateTimeBucket extends DateTimeBucket {
    public timezone: string;

    constructor(params?: Partial<TimezoneAwareDateTimeBucket> | Date) {
        super(params);
        if (!!params) {
            if (params instanceof Date) {
                this.timezone = 'UTC';
            } else {
                this.timezone = params.timezone || this.timezone;
            }
        }
    }
}

export function dateToTimezoneAwareDateTimeBucket(date: Date, timezone: string): TimezoneAwareDateTimeBucket {
    // convert to timezone date string
    // output format is: 11/25/2014, 2:09:00 AM
    const timezoneDateStr = date.toLocaleString('en-US', { timeZone: timezone, });
    // parse the string back into the a Date. This tricks the Date object that date object it UTC and the DateTimeBucket object will be converted correctly
    const localDate = parse(timezoneDateStr, 'MM/dd/yyyy, h:mm:ss a', new Date());
    const timezoneBucket = new TimezoneAwareDateTimeBucket(localDate);
    timezoneBucket.timezone = timezone;
    return timezoneBucket;
}
