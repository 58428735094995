import { Component, ChangeDetectionStrategy, Input, ViewContainerRef } from '@angular/core';

@Component({
    selector: 'rfx-app-defined-field-target',
    templateUrl: './app-defined-field-target.component.html',
    styleUrls: ['./app-defined-field-target.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppDefinedFieldTargetComponent {
    @Input()
    public key: string;
    @Input()
    public fieldId: string;

    constructor(public viewContainerRef: ViewContainerRef) {}
}
