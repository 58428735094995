import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorMessageDirective } from './directives/error-message.directive';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AddressComponent } from './components/address/address.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatFormFieldModule
    ],
    declarations: [ErrorMessageDirective, AddressFormComponent, AddressComponent],
    exports: [ErrorMessageDirective, AddressFormComponent, AddressComponent]
})
export class RfxFormsModule {}
