import { Injectable } from '@angular/core';
import { Router, Route, RouteConfigLoadEnd } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RfxHelpRouteAggregationService {

    private _routes$: BehaviorSubject<string[]> =
        new BehaviorSubject<string[]>([]);
    public routes$: Observable<string[]> =
        this._routes$.pipe(shareReplay(1));
    private _onDestroy$: Subject<void> =
        new Subject<void>();

    constructor(
        private readonly _router: Router
    ) {
        this._router
            .events
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(e => {
                if (e instanceof RouteConfigLoadEnd) {
                    const routes = [];
                    this._discoverRoutes('', this._router.config, routes);
                    const unique = [ ...new Set(routes) ];
                    this._routes$.next(unique.sort());
                }
            });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();   
    }

    private _discoverRoutes(parent: string, routes: Route[], out: string[]): void {
        const fullPath = (route?: Route): string => {
            if (route) {
                let path = route.path;
                if (path === '' && route.redirectTo) {
                    path = route.redirectTo;
                }
                return (`${parent}/${path}`).replace('//','/');
            }
            return parent;
        }

        for (const r of routes) {
            const p = fullPath(r);
            if (!r.children && !r.loadChildren) {
                out.push(p);
            } 

            if (r.children) {
                this._discoverRoutes(p, r.children, out);
            }

            if (r.loadChildren) {
                const cfg = !!r['_loadedConfig']
                    ? { ...r['_loadedConfig'] }
                    : null;
                if (!cfg) {
                    /*
                        Router modules with a forChild declaration and no
                        declaration / load behavior in the forRoot call of
                        the router module will lack a configuration. We can
                        ignore these as they aren't accessible routes.
                    */
                } else {
                    const arr = cfg['routes'];
                    for (let _a = 0; _a < arr.length; ++_a) {
                        const a = arr[_a];
                        if (a.children) {
                            this._discoverRoutes(p, a.children, out);
                        }
                    }                    
                }
            }
        }
    }
}
