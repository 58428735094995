import { BaseDoc } from '../base/base-doc';
import { UserProfileWithoutIds } from './user-profile';
import { UserCustomFields } from './user-custom-fields';
import { UserSettings } from './user-settings';
import { CompanyIdentityWithoutIds } from '../company/company-identity';
import { UserNotificationSettings } from './user-notification-settings';

/**
 * Does not include password or other sensitive fields, for that see the
 * `FullUserWithoutIds` class.
 */

export class UserWithoutIds extends BaseDoc {
    public _id: any;
    public email: string = '';
    public companyId: any;
    public companyIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public crewName: string = '';
    public crewSize: number = 0;
    public roleIds: any[] = [];
    public tagIds: any[] = [];
    public profile: UserProfileWithoutIds = new UserProfileWithoutIds();
    public custom: UserCustomFields = new UserCustomFields();
    public settings: UserSettings = new UserSettings();
    public notificationSettings: UserNotificationSettings = new UserNotificationSettings();

    constructor(params?: Partial<UserWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.email = params.email || this.email;
            this.companyId = params.companyId || this.companyId;
            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentityWithoutIds(params.companyIdentity)
                : this.companyIdentity;
            this.crewName = params.crewName || this.crewName;
            this.crewSize = params.crewSize || this.crewSize;
            this.roleIds = Array.isArray(params.roleIds) ? params.roleIds : this.roleIds;
            this.profile = !!params.profile
                ? new UserProfileWithoutIds(params.profile)
                : this.profile;
            this.custom = !!params.custom ? new UserCustomFields(params.custom) : this.custom;
            this.settings = !!params.settings ? new UserSettings(params.settings) : this.settings;
            this.notificationSettings = !!params.notificationSettings ? new UserNotificationSettings(params.notificationSettings) : this.notificationSettings;
        }

        this.email = this.email.toLowerCase();
    }
}
