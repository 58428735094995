import { StringFilter, StringTermMultipleFilter, DateRangeFilter } from '@refactor/common';

export class WorkOrderGridFilters {
    public workOrderNumber: StringFilter = new StringFilter();
    public servicePartnerInvoiceNumber: StringFilter = new StringFilter();
    public clientIds: StringTermMultipleFilter = new StringTermMultipleFilter();
    public locationIds: StringTermMultipleFilter = new StringTermMultipleFilter();
    public activeStatus: StringFilter = new StringFilter();
    public workOrderStatuses: StringTermMultipleFilter = new StringTermMultipleFilter();
    public priority: StringFilter = new StringFilter();
    public workCategory: StringFilter = new StringFilter();
    public billingType: StringFilter = new StringFilter();
    public scheduledDate: DateRangeFilter = new DateRangeFilter();
    public completeByDate: DateRangeFilter = new DateRangeFilter();
    public createdDate: DateRangeFilter = new DateRangeFilter();

    constructor(params?: Partial<WorkOrderGridFilters>) {
        if (!!params) {
            this.workOrderNumber = !!params.workOrderNumber ? new StringFilter(params.workOrderNumber) : this.workOrderNumber;
            this.servicePartnerInvoiceNumber = !!params.servicePartnerInvoiceNumber
                ? new StringFilter(params.servicePartnerInvoiceNumber)
                : this.servicePartnerInvoiceNumber;
            this.clientIds = !!params.clientIds ? new StringTermMultipleFilter(params.clientIds) : this.clientIds;
            this.locationIds = !!params.locationIds ? new StringTermMultipleFilter(params.locationIds) : this.locationIds;
            this.activeStatus = !!params.activeStatus ? new StringFilter(params.activeStatus) : this.activeStatus;
            this.workOrderStatuses = !!params.workOrderStatuses ? new StringTermMultipleFilter(params.workOrderStatuses) : this.workOrderStatuses;
            this.priority = !!params.priority ? new StringFilter(params.priority) : this.priority;
            this.workCategory = !!params.workCategory ? new StringFilter(params.workCategory) : this.workCategory;
            this.billingType = !!params.billingType ? new StringFilter(params.billingType) : this.billingType;
            this.scheduledDate = !!params.scheduledDate ? new DateRangeFilter(params.scheduledDate) : this.scheduledDate;
            this.completeByDate = !!params.completeByDate ? new DateRangeFilter(params.completeByDate) : this.completeByDate;
            this.createdDate = !!params.createdDate ? new DateRangeFilter(params.createdDate) : this.createdDate;
        }
    }
}
