export enum WorkOrderPhaseType {
    ONEGM = '[WorkOrderPhaseType] ONEGM',
    ServicePartner = '[WorkOrderPhaseType] ServicePartner',
    FixedRate = '[WorkOrderPhaseType] FixedRate'
}

export namespace WorkOrderPhaseType {
    export function toString(type: WorkOrderPhaseType): string {
        switch (type) {
            case WorkOrderPhaseType.ONEGM:
                return 'WORK_ORDER.ONE_GROUNDS_MANAGEMENT_PHASE';
            case WorkOrderPhaseType.ServicePartner:
                return 'WORK_ORDER.SERVICE_PARTNER_PHASE';
            case WorkOrderPhaseType.FixedRate:
                return 'WORK_ORDER.FIXED_RATE_SERVICE';
            default:
                return '';
        }
    }

    export const members: WorkOrderPhaseType[] = [
        WorkOrderPhaseType.ONEGM,
        WorkOrderPhaseType.ServicePartner,
        WorkOrderPhaseType.FixedRate,
    ];
}
