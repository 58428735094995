import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RfxGridFilterControlComponent } from '@refactor/ngx/grid';

@Component({
	selector: 'gm2-grid-filter-zip',
	templateUrl: './grid-filter-zip.component.html',
	styleUrls: ['./grid-filter-zip.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: RfxGridFilterControlComponent,
			useExisting: GridFilterZipComponent
		}
	]
})
export class GridFilterZipComponent implements OnInit {
	@Input()
	public name: string = 'zip';
	@Input()
	public placeholder: string = 'Zip';

	public controlGroup: UntypedFormGroup;

	constructor() {}

	ngOnInit(): void {
		this.controlGroup = new UntypedFormGroup({
			value: new UntypedFormControl('')
		});
	}
}
