export enum MaterialOptions {
    Has_Material = '[MaterialOptions] Yes',
    No_Material = '[MaterialOptions] No',
}

export namespace MaterialOptions {
    export function toString(type: MaterialOptions): string {
        switch (type) {
            case MaterialOptions.Has_Material:
                return 'Yes';
            case MaterialOptions.No_Material:
                return 'No';
            default:
                return '';
        }
    }

    export function toOptions(type: string): string {
        switch (type) {
            case 'Yes':
                return MaterialOptions.Has_Material;
            case 'No':
                return MaterialOptions.No_Material;
            default:
                return '';
        }
    }

    export const members: MaterialOptions[] = [
        MaterialOptions.Has_Material,
        MaterialOptions.No_Material,
    ];
}
