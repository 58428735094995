import { DeviceInfo } from '../device/device-info';

export class TimesheetNativeApp {
    public deviceInfo: DeviceInfo = null;
    public offlineId?: string;

    constructor(params?: Partial<TimesheetNativeApp>) {
        if (!!params) {
            this.deviceInfo = params.deviceInfo || this.deviceInfo;
            /*
                Explicity remove the offlineId field if no value is
                provided. A value of undefined will be converted to
                null upon collection insert which can create
                duplicate key errors for offlineId
            */
            if (!!params.offlineId) {
                this.offlineId = params.offlineId;
            } else {
                delete this.offlineId;
            }
        }
    }
}
