export enum LabelPlacement {
    Right = 'right',
    Left = 'left'
}

export namespace LabelPlacement {
    export function toString(type: LabelPlacement): string {
        switch (type) {
            case LabelPlacement.Right:
                return 'Right';
            case LabelPlacement.Left:
                return 'Left';
            default:
                return '';
        }
    }

    export const members: LabelPlacement[] = [
        LabelPlacement.Right,
        LabelPlacement.Left
    ];
}
