import { HttpResponse } from '@angular/common/http';

/**
 * @ignore
 *
 * Provides the HttpAdapter methods used by the [HttpService]{@link HttpService}
 */
export class HttpAdapter {
    private static _successCode: number = 200;
    private static _invalidCode: number = 400;

    /**
     * Applies an adapter function to transform the response body
     *
     * @param res Http Response object
     * @param adapterFn Function to transform the response body
     * @returns Http Response object
     */
    public static baseMapValue(res: HttpResponse<any>, adapterFn?: Function): HttpResponse<any> {
        if (!res) {
            return;
        } else if (res.status === HttpAdapter._successCode) {
            try {
                return res.clone({
                    body: adapterFn ? adapterFn.call(this, res.body) : res.body
                });
            } catch (e) {
                return res.clone({
                    status: HttpAdapter._invalidCode,
                    statusText: `@rfx/ngx-http Adapter failed with error: ${JSON.stringify(e)}`
                });
            }
        } else {
            return res;
        }
    }

    /**
     * Passes the response body into the constructor of a given class
     *
     * @param res Http Response object
     * @param classToMap A class with a constructor
     * @returns Http Response object
     */
    public static baseMapClass<T>(
        res: HttpResponse<any>,
        classToMap: new (params: any) => T
    ): HttpResponse<T | T[]> {
        if (!classToMap) {
            return res;
        } else if (!res) {
            return;
        } else if (res.status === HttpAdapter._successCode) {
            try {
                if (Array.isArray(res.body)) {
                    let mapped: Array<T> = [];
                    let validData = true;
                    for (const m of res.body) {
                        if (!!m && typeof m === 'object' && !Array.isArray(m)) {
                            mapped = [...mapped, new classToMap(m)];
                        } else {
                            validData = false;
                            break;
                        }
                    }
                    if (!validData) {
                        throw new Error('Cannot map non-object in array response type to class');
                    }
                    return res.clone({ body: mapped });
                } else if (!!res.body && typeof res.body === 'object') {
                    return res.clone({ body: new classToMap(res.body) });
                } else {
                    throw new Error('Cannot map non-object response type to class');
                }
            } catch (e) {
                return res.clone({
                    status: HttpAdapter._invalidCode,
                    statusText: `@rfx/ngx-http Adapter failed to map ${JSON.stringify(
                        res.body
                    )} to ${JSON.stringify(classToMap)} with error: ${JSON.stringify(e)}`
                });
            }
        } else {
            return res;
        }
    }
}
