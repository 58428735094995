// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    webserver: 'https://multi-beta.westus.azurecontainer.io',
    servicePartnerEmail: 'spdocs@onegm.com',
    adminRoleId: '533cd68f03d943863961eb49',
    adminCompanyId: '533cd6ef03d943863961eb4c',
    isMultiTenant: true,
    googleMaps: {
        apiKey: 'AIzaSyBOVibETgdq-aS8CtZdo2r7-cjRV48ao0o'
    }
};
