import { Injectable } from '@angular/core';
import { HttpService, Post, Body, Query, ResponseType, Header, Path, Get, MapClass, Put, Delete } from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { Mapping } from '../models/mapping/Mapping';
import { MappingCreateDto } from '../models/mapping/MappingCreateDto';
import { MappingUpdateDto } from '../models/mapping/MappingUpdateDto';
import { LocationOrigin, MappingLocationDatePair, MappingMobileCacheDatum } from '@gm2/common';

@Injectable({
    providedIn: 'root'
})
export class MappingService extends HttpService {
    @Get('/mapping/location/:id')
    @MapClass(Mapping)
    public getMappingForLocation(
        @Path('id') id: string
    ): Observable<Mapping[]> {
        return null;
    }

    @Post('/mapping/mobilecache')
    @MapClass(Mapping)
    public getMobileCacheMappingData(
        @Body('locationDatePairs') locationDatePairs: MappingLocationDatePair[]
    ): Observable<MappingMobileCacheDatum[]> {
        return null;
    }

    @Get('/mapping/location/:id/origin')
    @MapClass(LocationOrigin)
    public getMappingOriginForLocation(@Path('id') id: string): Observable<LocationOrigin> {
        return null;
    }

    @Put('/mapping')
    public updateMapping(@Body() mapping: MappingUpdateDto): Observable<void> {
        return null;
    }

    @Post('/mapping')
    public createMapping(@Body() mapping: MappingCreateDto): Observable<string> {
        return null;
    }

    @Delete('/mapping')
    public deleteMapping(@Query('id') id: string): Observable<void> {
        return null;
    }

    @Post('/mapping/location/:id/adminapprove')
    public adminApproveMapping(@Path('id') id: string): Observable<void> {
        return null;
    }

    @Post('/mapping/location/:id/clientapprove')
    public clientApproveMapping(@Path('id') id: string): Observable<void> {
        return null;
    }

    @Post('/mapping/location/:id/resetapprove')
    public resetApprove(@Path('id') id: string): Observable<void> {
        return null;
    }
}
