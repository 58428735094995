import { IterableDiffers, TemplateRef, Directive } from '@angular/core';

@Directive({
    selector: '[rfxGridItem]',
    inputs: ['when: rfxGridItemWhen']
})
export class RfxGridItem<T> {
    public when: (index: number, rowData: T) => boolean;

    constructor(public template: TemplateRef<any>, protected _differs: IterableDiffers) {}
}

export interface RfxGridItemOutletContext<T> {
    /** Data for the item */
    $implicit?: T;

    /** Index of the data object in the provided data array. */
    index?: number;

    /** Length of the number of total rows */
    count?: number;

    /** True if this item is the first row */
    first?: boolean;

    /** True if this item is the last row */
    last?: boolean;
}
