<rfx-grid-filter-control>
    <mat-form-field appearance="outline">
        <mat-label>{{ placeholder }}</mat-label>
        <mat-select [formControl]="controlGroup.get('value')">
            <mat-option *ngFor="let o of allOptions" [value]="o.value">
                {{ o.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</rfx-grid-filter-control>
