export enum CompanyTier {
    PreQualified = '[CompanyTier] PreQualified',
    Qualified = '[CompanyTier] Qualified',
    Preferred = '[CompanyTier] Preferred'
}

export namespace CompanyTier {
    export function toString(type: CompanyTier): string {
        switch (type) {
            case CompanyTier.PreQualified:
                return 'PreQualified';
            case CompanyTier.Qualified:
                return 'Qualified';
            case CompanyTier.Preferred:
                return 'Preferred';
            default:
                return '';
        }
    }

    export const members: CompanyTier[] = [
        CompanyTier.PreQualified,
        CompanyTier.Qualified,
        CompanyTier.Preferred
    ];
}
