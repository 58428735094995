import { IStringFilter } from '../../interface';

export class StringFilter implements IStringFilter {
    public value: string = '';

    constructor(params?: Partial<StringFilter>) {
        if (!!params) {
            this.value = params.value || this.value;
        }
    }
}
