import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { Material, ApproveType, MaterialActiveIngredient } from '@gm2/ui-common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'gm2-materials-list',
    templateUrl: './materials-list.component.html',
    styleUrls: ['./materials-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialsListComponent implements OnInit {
    // Inputs
    @Input()
    public materials$: Observable<Material[]>;
    @Input()
    public canModify: boolean = false;
    @Input()
    public showCompanyColumn: boolean = false;

    // Outputs
    @Output()
    public approve: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    public disapprove: EventEmitter<string> = new EventEmitter<string>();

    // Enums
    public ApproveType: typeof ApproveType = ApproveType;
    public MaterialActiveIngredient: typeof MaterialActiveIngredient = MaterialActiveIngredient;

    // Variables related to the table
    public displayedColumns: string[] = ['name', 'price', 'activeIngredient', 'description'];
    public pendingMaterials$: Observable<Material[]>;
    public approvedMaterials$: Observable<Material[]>;
    public disapprovedMaterials$: Observable<Material[]>;

    constructor() {}

    ngOnInit(): void {
        // Only show company column if showCompanyColumn is true
        if (this.showCompanyColumn) {
            this.displayedColumns.push('company');
        }
        // Only show actions column if canModify is true
        if (this.canModify) {
            this.displayedColumns.push('actions');
        }

        this.materials$.subscribe(res => {
            console.log(res);
        });

        // Wait for input to get value before populating pending/approved/disapproved
        this.pendingMaterials$ = this.materials$.pipe(
            map(materials =>
                materials.filter(mat => mat.approveStatus === ApproveType.ApprovalRequested)
            )
        );
        this.approvedMaterials$ = this.materials$.pipe(
            map(materials => materials.filter(mat => mat.approveStatus === ApproveType.Approved))
        );
        this.disapprovedMaterials$ = this.materials$.pipe(
            map(materials => materials.filter(mat => mat.approveStatus === ApproveType.Disapproved))
        );
    }
}
