import { MappingType, MappingClassification } from '../../enums';
import { isNull } from '../../utils';
import { BaseDoc } from '../base/base-doc';

export class MappingWithoutIds extends BaseDoc {
    public _id: any;
    public locationId: any;
    public name: string = '';
    public serviceTypeId: any;
    public mappingCategoryId: any;
    public type: MappingType;
    public mappingClassification: MappingClassification;
    public area: number = 0;
    public width: number = 0;
    public notes: string = '';

    // long/lat array
    public points: [number, number][] = [];

    constructor(params?: Partial<MappingWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.locationId = params.locationId || this.locationId;
            this.name = params.name || this.name;
            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId;
            this.mappingCategoryId = params.mappingCategoryId || this.mappingCategoryId;
            this.type = params.type || this.type;
            this.mappingClassification = params.mappingClassification || this.mappingClassification;
            this.area = params.area || this.area;
            this.width = params.width || this.width;
            this.notes = params.notes || this.notes;
            this.points = Array.isArray(params.points) ? params.points : this.points;
        }
    }
}
