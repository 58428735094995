<h2 mat-dialog-title>Welcome to ONE Grounds Management</h2>
<div mat-dialog-content>
    <p *ngIf="data.type === CompanyType.Admin || data.type === CompanyType.Service_Partner">
        We are very excited to learn more about you and the services you provide. Please review our
        <a [href]="paymentGuidelinesPath" target="_blank">payment guidelines</a>
        and
        <a [href]="insuranceRequirementsPath" target="_blank">insurance requirements</a> before
        continuing. We look forward to a great partnership in providing services to our many
        clients.
        <br />
        <br />
        Thank you,
        <br />
        The ONE Team.
    </p>
    <p *ngIf="data.type === CompanyType.Client">
        We are very excited to learn more about you and the services you need. Please take a moment
        to explore our site. We look forward to a great partnership in providing services to you
        that fit your business needs.
        <br />
        <br />
        Thank you,
        <br />
        The ONE Team.
    </p>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button color="primary" (click)="close()">Close</button>
</div>
