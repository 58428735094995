import { BidServiceWithoutIds } from './bid-service';
import { BillingType } from '../../enums';
import { CompanyIdentityWithoutIds } from '../company/company-identity';
import { DateBucket, parseISODate } from '@refactor/common';
import { isNull } from '../../utils';

export class BidWithoutIds {
    public billingType: BillingType;
    public services: BidServiceWithoutIds[] = [];
    public monthlyRate: number = null; // only needed on monthly bids
    public minChargeDuration: number = null;
    public companyIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public createdDate: Date = new Date();
    public createdDateBucket: DateBucket = new DateBucket();
    public modifiedDate: Date = new Date();
    public modifiedDateBucket: DateBucket = new DateBucket();
    public materials?: any[]

    constructor(params?: Partial<BidWithoutIds>) {
        if (!!params) {
            this.billingType = params.billingType || this.billingType;

            this.services = Array.isArray(params.services)
                ? params.services.map(service => new BidServiceWithoutIds(service))
                : this.services;

            this.monthlyRate = !isNull(params.monthlyRate) ? params.monthlyRate : this.monthlyRate;
            this.minChargeDuration = !isNull(params.minChargeDuration)
                ? params.minChargeDuration
                : this.minChargeDuration;

            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentityWithoutIds(params.companyIdentity)
                : this.companyIdentity;

            this.createdDate = !!params.createdDate
                ? parseISODate(params.createdDate)
                : this.createdDate;

            this.createdDateBucket = !!params.createdDateBucket
                ? new DateBucket(params.createdDateBucket)
                : this.createdDateBucket;

            this.modifiedDate = !!params.modifiedDate
                ? parseISODate(params.modifiedDate)
                : this.modifiedDate;

            this.modifiedDateBucket = !!params.modifiedDateBucket
                ? new DateBucket(params.modifiedDateBucket)
                : this.modifiedDateBucket;

            this.materials = !!params.materials
                ? params.materials
                : this.materials;
        }
    }
}
