export enum Month {
    January = '[Month] January',
    February = '[Month] February',
    March = '[Month] March ',
    April = '[Month] April',
    May = '[Month]  May',
    June = '[Month] June',
    July = '[Month] July',
    August = '[Month] August',
    September = '[Month]September ',
    October = '[Month] October',
    November = '[Month] November',
    December = '[Month] December'
}

export namespace Month {
    export function toNum(monthEnum: Month): number {
        switch (monthEnum) {
            case Month.January:
                return 0;
            case Month.February:
                return 1;
            case Month.March:
                return 2;
            case Month.April:
                return 3;
            case Month.May:
                return 4;
            case Month.June:
                return 5;
            case Month.July:
                return 6;
            case Month.August:
                return 7;
            case Month.September:
                return 8;
            case Month.October:
                return 9;
            case Month.November:
                return 10;
            case Month.December:
                return 11;
            default:
                return null;
        }
    }

    export function toString(monthEnum: Month): string {
        switch (monthEnum) {
            case Month.January:
                return 'January';
            case Month.February:
                return 'February';
            case Month.March:
                return 'March';
            case Month.April:
                return 'April';
            case Month.May:
                return 'May';
            case Month.June:
                return 'June';
            case Month.July:
                return 'July';
            case Month.August:
                return 'August';
            case Month.September:
                return 'September';
            case Month.October:
                return 'October';
            case Month.November:
                return 'November';
            case Month.December:
                return 'December';
            default:
                return null;
        }
    }

    export function stringToEnum(month: string): string {
        switch (month) {
            case 'January':
                return Month.January;
            case 'February':
                return Month.February;
            case  'March':
                return Month.March;
            case 'April' :
                return Month.April;
            case 'May' :
                return Month.May;
            case 'June' :
                return Month.June;
            case 'July':
                return Month.July;
            case 'August':
                return Month.August;
            case 'September' :
                return Month.September;
            case  'October':
                return Month.October;
            case 'November':
                return Month.November;
            case 'December':
                return Month.December;
            default:
                return null;
        }
    }

    export const members: Month[] = [
        Month.January,
        Month.February,
        Month.March,
        Month.April,
        Month.May,
        Month.June,
        Month.July,
        Month.August,
        Month.September,
        Month.October,
        Month.November,
        Month.December,
    ];
}
