export class CompanyServiceArea {
    public zipcode: string = '';
    public radius: number = 0;

    constructor(params?: Partial<CompanyServiceArea>) {
        if (!!params) {
            this.zipcode = params.zipcode || this.zipcode;
            this.radius = params.radius || this.radius;
        }
    }
}
