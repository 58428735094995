export enum WorkOrderPhaseStatus {
    ServicePartnerOffered = '[WorkOrderPhaseStatus] ServicePartnerOffered',
    ServicePartnerRejected = '[WorkOrderPhaseStatus] ServicePartnerRejected',
    Accepted = '[WorkOrderPhaseStatus] Accepted',
    Complete = '[WorkOrderPhaseStatus] Complete'
}

export namespace WorkOrderPhaseStatus {
    export function toString(type: WorkOrderPhaseStatus): string {
        switch (type) {
            case WorkOrderPhaseStatus.ServicePartnerOffered:
                return 'ServicePartnerOffered';
            case WorkOrderPhaseStatus.ServicePartnerRejected:
                return 'ServicePartnerRejected';
            case WorkOrderPhaseStatus.Accepted:
                return 'Accepted';
            case WorkOrderPhaseStatus.Complete:
                return 'Complete';
            default:
                return '';
        }
    }

    export const members: WorkOrderPhaseStatus[] = [
        WorkOrderPhaseStatus.ServicePartnerOffered,
        WorkOrderPhaseStatus.ServicePartnerRejected,
        WorkOrderPhaseStatus.Accepted,
        WorkOrderPhaseStatus.Complete
    ];

    // Do not include if status is ServicePartnerRejected
    export const servicePartnerQueryMembers: WorkOrderPhaseStatus[] = [
        WorkOrderPhaseStatus.ServicePartnerOffered,
        WorkOrderPhaseStatus.Accepted,
        WorkOrderPhaseStatus.Complete
    ];
}
