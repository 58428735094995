import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { RfxGridAction } from '../GridAction';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
    selector: 'rfx-grid-action-bar-control',
    templateUrl: './grid-action-bar-control.component.html',
    styleUrls: ['./grid-action-bar-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridActionBarControlComponent<T> implements OnInit, RfxGridAction<T> {
    private _selectionWatcher: Subscription;

    public selection: SelectionModel<T>;

    @Output()
    public selectedItems: EventEmitter<T[]> = new EventEmitter<T[]>();

    constructor() {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        if (!!this._selectionWatcher) {
            this._selectionWatcher.unsubscribe();
        }
    }

    public setSelection(selection: SelectionModel<T>): void {
        this.selection = selection;
        if (!!this.selection) {
            if (!!this._selectionWatcher) {
                this._selectionWatcher.unsubscribe();
            }
            this._selectionWatcher = this.selection.changed.subscribe(items => {
                this.selectedItems.emit(this.selection.selected);
            });
        }
    }
}
