import { BaseMediaDocument } from './BaseMediaDocument';
import { ObjectId } from '@refactor/nst/db/mongo';

export class BaseMediaDocumentWithCollectionId extends BaseMediaDocument{
	public collectionId: ObjectId;

	constructor(params?: Partial<BaseMediaDocumentWithCollectionId>){
		super(params);
		if(!!params){
			this.collectionId = !!params.collectionId ? params.collectionId : this.collectionId;
		}
	}
}
