import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RfxFormsModule } from '@refactor/ngx/forms';
import { RfxGridModule } from '@refactor/ngx/grid';
import { RfxHelpListComponent } from './components/rfx-help-list/rfx-help-list.component';
import { RfxHelpEditComponent } from './components/rfx-help-edit/rfx-help-edit.component';
import { RfxHelpMenuComponent } from './components/rfx-help-menu/rfx-help-menu.component';
import { RfxHelpModalComponent } from './components/rfx-help-modal/rfx-help-modal.component';
import { RfxHelpRouteAggregationService } from './services/rfx-help-route-aggregation.service';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        RfxFormsModule,
        RfxGridModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatDialogModule
    ],
    declarations: [
        RfxHelpListComponent,
        RfxHelpEditComponent,
        RfxHelpMenuComponent,
        RfxHelpModalComponent
    ],
    exports: [
        RfxHelpListComponent,
        RfxHelpEditComponent,
        RfxHelpMenuComponent
    ]
})
export class RfxHelpModule {
    constructor(
        private readonly _aggService: RfxHelpRouteAggregationService
    ) {}
}
