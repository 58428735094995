import { OnegmAbstractNonMonthlyCharges } from './onegm-abstract-non-monthly-charges';
import { RfpServiceWithoutIds } from '@gm2/common';

export class OnegmHourlyCharges extends OnegmAbstractNonMonthlyCharges {
    public readonly minutesPerHour: number = 60;

    public calculateServiceClientTotal(
        duration: number,
        clientPricingService: RfpServiceWithoutIds
    ): number {
        const durationInHours = duration / this.minutesPerHour || 0;
        return (
            durationInHours * (clientPricingService.clientPrice || 0) +
            (clientPricingService.clientFee || 0)
        );
    }
}
