import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'gm2-date-range-modal',
    templateUrl: './date-range-modal.component.html',
    styleUrls: ['./date-range-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeModalComponent implements OnInit {

    public dateRangeForm: UntypedFormGroup = this._fb.group({
        startDate: ['', Validators.required],
        endDate: ['', Validators.required]
    });

    constructor(
        private _fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<DateRangeModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {}

    public close(): void {
        this.dialogRef.close(DateRangeModalComponent.Option.Cancel);
    }

    public clear(): void {
        this.dialogRef.close(DateRangeModalComponent.Option.Clear);
    }

    public select(): void {
        this.dialogRef.close(this.dateRangeForm);
    }
}

export namespace DateRangeModalComponent {

    export enum Option {
        Cancel = '[DateRangeModalComponent Option] Cancel',
        Clear = '[DateRangeModalComponent Option] Clear',
    }
}