import { Component, ChangeDetectionStrategy } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError, shareReplay, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FormBuilderService } from '../../services/form-builder.service';
import { RfxForm } from '@refactor/common';

@Component({
    selector: 'rfx-form-respond',
    templateUrl: './form-respond.component.html',
    styleUrls: ['./form-respond.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormRespondComponent {
    public listUrl$: Observable<string> = this._activatedRoute.data.pipe(
        map(data => data.listUrl),
        shareReplay(1)
    );

    public form$: Observable<RfxForm> = this._activatedRoute.paramMap.pipe(
        map(params => params.get('formId')),
        switchMap(id => this._formService.getFormById(id)),
        catchError(err => {
            console.error('could not retrieve from', err);
            return of(null);
        }),
        shareReplay(1)
    );

    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _formService: FormBuilderService,
        private readonly _router: Router,
        private readonly _toast: ToastrService
    ) {}

    public onSuccess(): void {
        this._toast.success('Response Submitted!');
        this.goBack();
    }

    public onFailure(message: string): void {
        this._toast.error(message);
    }

    public goBack(): void {
        this.listUrl$.pipe(take(1)).subscribe(url => {
            this._router.navigateByUrl(url);
        });
    }
}
