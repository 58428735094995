<gm2-banner
    *ngIf="user.permissions.includes(Permission.RfpBidCreate) && !(profileComplete$ | async)"
    (click)="completeProfile()"
>
    In order to bid to perform a service, please complete your company profile.
</gm2-banner>
<gm2-banner *ngIf="(isImpersonating$ | async)" (click)="stopImpersonating()">
    You are currently impersonating {{ user.profile.firstName }} {{ user.profile.lastName }}. Click
    to stop impersonation.
</gm2-banner>
<div class="header-content">
    <a routerLink="/dashboard" class="logo">
        <img src="/assets/allgrounds-logo.png" />
    </a>
    <!-- <label class="system-name"><em>{{'COMMON.APP_NAME'|translate}}</em></label> -->
    <!-- 2021-12-06 temporarily removed help object because it's broken -->
    <a
        class="header-button help"
        href="https://support.allgrounds.com/support/home"
        target="_self"
    >
        <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
        <span class="button-label">Help</span>
    </a>
    <div
        class="header-button notification"
        *ngIf="user.permissions.includes(Permission.UserNotificationsEdit)"
        routerLink="/profile/notifications/"
    >
        <fa-icon [icon]="['far', 'bell']"></fa-icon>
        <span class="count" *ngIf="notificationCount > 0">{{ notificationCount }}</span>
        <span class="button-label">Notifications</span>
    </div>
    <label class="who-am-i">
        <span> {{ user.profile.firstName }} {{ user.profile.lastName }} </span> |
        <span routerLink="/manage/company/edit/{{ user.companyId }}">
            {{ user.companyIdentity.name }}
        </span>
    </label>
    <div class="logout" (click)="logout.emit()">
        Log Out
        <fa-icon [icon]="['far', 'sign-out']"></fa-icon>
    </div>
</div>
