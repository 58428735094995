import { isNull } from '../../utils';

export class LaborWithoutIds {
    public _id: any;
    public name: string;
    public description: string;
    public rateExternal: number;
    public rateInternal: number;

    constructor(params?: Partial<LaborWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.description = params.description || this.description;
            this.rateExternal = !isNull(params.rateExternal)
                ? params.rateExternal
                : this.rateExternal;
            this.rateInternal = !isNull(params.rateInternal)
                ? params.rateInternal
                : this.rateInternal;
        }
    }
}
