import { WorkOrderPhase } from './WorkOrderPhase';
import { WorkOrderMaterialDto } from './WorkOrderMaterialDto';
import { WorkOrderEquipmentDto } from './WorkOrderEquipmentDto';
import { WorkOrderLaborDto } from './WorkOrderLaborDto';
import { WorkOrderPhaseType } from '@gm2/common';
import { ObjectId } from '@refactor/nst/db/mongo';

export class WorkOrderPhaseDto {
    public id: string = '';
    public material: WorkOrderMaterialDto[] = null;
    public equipment: WorkOrderEquipmentDto[] = null;
    public labor: WorkOrderLaborDto[] = null;
    public description: string = '';
    public dimensions: string = '';
    public comments: string = '';
    public phaseType: WorkOrderPhaseType = null;
    public servicePartnerId: ObjectId = null;
    public customClientPrice: number = null;
    public phaseSalesTax: number = null;

    constructor(params?: Partial<WorkOrderPhaseDto>) {
        if (!!params) {
            this.id = params.id || this.id;
            this.material = params.material || this.material;
            this.equipment = params.equipment || this.equipment;
            this.labor = params.labor || this.labor;
            this.description = params.description || this.description;
            this.dimensions = params.dimensions || this.dimensions;
            this.comments = params.comments || this.comments;
            this.phaseType = params.phaseType || this.phaseType;
            this.servicePartnerId = params.servicePartnerId || this.servicePartnerId;
            this.customClientPrice = params.customClientPrice || this.customClientPrice;
            this.phaseSalesTax = params.phaseSalesTax || this.phaseSalesTax;
        }
    }
}
