import { TimesheetWithoutIds, TimesheetDates } from '@gm2/common';

export class Timesheet extends TimesheetWithoutIds {
    public _id: string = '';
    public dates: TimesheetDates = {
        tzAwareBucket: null,
        timesheetEndDate: null,
        timesheetEndDateBucket: null,
        timesheetStartDate: null,
        timesheetStartDateBucket: null,
        systemEndDate: null,
        systemStartDate: null,
        systemEndDateBucket: null,
        systemStartDateBucket: null
    };

    constructor(params?: Partial<Timesheet>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.dates = params.dates || this.dates;
        }
    }
}
