import {
    Component,
    OnInit,
    Input,
    ChangeDetectorRef,
    ContentChild,
    TemplateRef,
    ChangeDetectionStrategy
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Address } from '../../models/Address';
import { State } from '../../models/State';

/**
 * Address Component
 *
 * Provides a component that handles all of the logic around creating
 * an address form while leaving the template to be defined by the
 * consumer of the component.
 */
@Component({
    selector: 'rfx-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressComponent implements OnInit {
    /**
     * Gets a reference to the template that the user provides
     */
    @ContentChild(TemplateRef, { static: true })
    public addressTemplate: TemplateRef<any>;

    /**
     * @ignore
     */
    private _addressFormGroup: UntypedFormGroup = Address.toFormGroup();
    /**
     * Set an instance of a FormGroup to use
     */
    @Input()
    public set addressFormGroup(value: UntypedFormGroup) {
        if (value instanceof UntypedFormGroup) {
            this._addressFormGroup = value;
        }
    }
    /**
     * Returnns the currently used FormGroup instance
     */
    public get addressFormGroup(): UntypedFormGroup {
        return this._addressFormGroup;
    }

    /**
     * Returns the current value of the address form
     */
    public get value(): Address {
        return new Address(this.addressFormGroup);
    }

    /**
     * List of States available for the user to choose
     */
    public State: typeof State = State;

    /**
     * @ignore
     */
    constructor(private _cd: ChangeDetectorRef) {}

    /**
     * @ignore
     */
    ngOnInit(): void {}

    /**
     * Validatos the current inputs in the form
     *
     * @returns validated status
     */
    public validate = (): boolean => {
        this.addressFormGroup.updateValueAndValidity();
        for (const controlKey in this.addressFormGroup.controls) {
            this.addressFormGroup.controls[controlKey].markAsTouched();
        }
        this._cd.detectChanges();
        return this.addressFormGroup.valid;
    };
}
