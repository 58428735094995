import { ClientPricingWithoutIds, BidWithoutIds } from '../../models';
import { BillingType } from '../../enums';
import { Injectable } from "@angular/core";

@Injectable()
export class RfpCalculationService {

    public getGrossProfit(
        rfpContractBillingType: BillingType,
        rfpClientPricing: ClientPricingWithoutIds,
        spBid: BidWithoutIds
    ): number {
        if (rfpContractBillingType !== spBid.billingType) {
            return null;
        }

        const toPercent = 100;

        let grossProfit: number;
        if (rfpContractBillingType === BillingType.Monthly &&
            rfpClientPricing.monthlyClientPrice &&
            spBid.monthlyRate) {
            grossProfit = (((rfpClientPricing.monthlyClientPrice) - (spBid.monthlyRate)) / (rfpClientPricing.monthlyClientPrice)) * toPercent;
        } else {
            let totalClient = 0;
            let totalBid = 0;
            for (const s of rfpClientPricing.services) {
                totalClient += !!s.clientPrice ? s.clientPrice : 0;
            }
            for (const s of spBid.services) {
                totalBid += !!s.amount ? s.amount : 0;
            }

            if (!totalClient || totalClient === 0) {
                // No client pricing for services
                return null;
            }

            grossProfit = (totalClient - totalBid) / totalClient * toPercent;
        }

        return grossProfit;
    }
}
