import { InvoiceRejectionReason } from '../../enums';

export class WorkOrderInvoiceDeclinedWithoutIds {
    public phaseIds: any[] = [];
    public message: string;
    public reason: InvoiceRejectionReason;

    constructor(params?: Partial<WorkOrderInvoiceDeclinedWithoutIds>) {
        if (!!params) {
            this.phaseIds = Array.isArray(params.phaseIds)
                ? params.phaseIds
                : this.phaseIds;

            this.message = params.message || this.message;
            this.reason = params.reason || this.reason;
        }
    }
}
