import { Injectable, InjectionToken, Inject, Optional } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Allows an application to specify an auth token to be used during the
 * duration of the application. This token is only read once and will not
 * respond to updates. Use
 * [RFX_HTTP_AUTH_TOKEN_STREAM]{@link RFX_HTTP_AUTH_TOKEN_STREAM} for changing
 * auth tokens during the application lifecycle.
 *
 * ```Typescript
 * providers: [
 *      {
 *          provide: RFX_HTTP_AUTH_TOKEN,
 *          useValue: 'MCTsje83N31wJhd3cl4NKkEM'
 *      }
 * ]
 * ```
 */
export const RFX_HTTP_AUTH_TOKEN = new InjectionToken<string>('rfx.http.auth.token');

/**
 * Allows an application to provide a stream of auth tokens to the
 * AuthInterceptor. The interceptor will always apply the most recent token
 * to the http requests. The factory should be based on a ReplaySubject or
 * BehaviorSubject from the rxjs library. A basic Subject will not emit on
 * initial subscribe and so the first value will be missed as opposed to the
 * ReplaySubject or BehaviorSubject both of which emit the current value on
 * subscribe.
 *
 * ```Typescript
 * export const tokenFactory = () => myBehaviorSubject.asObservable();
 * ...
 * providers: [
 *      {
 *          provide: RFX_HTTP_AUTH_TOKEN_STREAM,
 *          useFactory: tokenFactory
 *      }
 * ]
 * ```
 */
export const RFX_HTTP_AUTH_TOKEN_STREAM = new InjectionToken<Observable<string>>(
    'rfx.http.auth.tokenstream'
);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        @Optional() @Inject(RFX_HTTP_AUTH_TOKEN) private _token: string,
        @Optional() @Inject(RFX_HTTP_AUTH_TOKEN_STREAM) private _tokenStream: Observable<string>
    ) {
        if (typeof this._token !== 'string') {
            this._token = '';
        }
        if (this._tokenStream instanceof Observable) {
            this._tokenStream.subscribe(token => {
                this._token = token;
            });
        }
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const newOpts = { headers: req.headers };
        if (typeof this._token === 'string' && this._token !== '' && !req.headers.has('Authorization')) {
            newOpts.headers = req.headers.set('Authorization', `Bearer ${this._token}`);
        }
        const newReq = req.clone(newOpts);
        return next.handle(newReq);
    }
}
