import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { RfxGridFilterControlComponent, RfxGridFilterSelectOption } from '@refactor/ngx/grid';
import { UserService, User, Permission } from '@gm2/ui-common';
import { Observable, Subject, iif, of, combineLatest } from 'rxjs';
import { map, takeUntil, switchMap, take, shareReplay } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { UserState } from '@gm2/ui-state';

@Component({
	selector: 'gm2-grid-filter-facility-manager',
	templateUrl: './grid-filter-facility-manager.component.html',
	styleUrls: ['./grid-filter-facility-manager.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: RfxGridFilterControlComponent,
			useExisting: GridFilterFacilityManagerComponent
		}
	]
})
export class GridFilterFacilityManagerComponent implements OnInit {
    @Select(UserState.userPermissions)
    public permissions$: Observable<Permission[]>;
	@Input()
	public name: string = 'facilityManagerUserId';
	@Input()
	public placeholder: string = 'Facility Manager';
    @Input()
    public selectedFmRoleId$: Observable<string> = undefined;
    @Output()
    public fmRoleIds: EventEmitter<string[]> =
        new EventEmitter<string[]>();

    public facilityManagers$: Observable<User[]> = undefined;

    public options$: Observable<RfxGridFilterSelectOption[]> = undefined;

	public controlGroup: UntypedFormGroup;

	private _onDestroy$: Subject<void> = new Subject();

	constructor(
        private _userService: UserService
	){}

	ngOnInit(): void {
		this.controlGroup = new UntypedFormGroup({
			value: new UntypedFormControl('')
		});
        combineLatest([
            this.facilityManagers$,
            this.controlGroup.controls.value.valueChanges
        ])
        .pipe(takeUntil(this._onDestroy$))
        .subscribe(([fms, selFmId]) => {
            const fm = fms.find(fm => fm._id === selFmId);
            this.fmRoleIds.emit(!!fm ? fm.roleIds : null);
        });
	}

    ngOnChanges(): void {
        // initialize facility managers pipe once permissions defined
        if (this.facilityManagers$ === undefined && !!this.permissions$) {
            this.facilityManagers$ = this.permissions$.pipe(
                switchMap(permissions =>
                    iif(
                        () => permissions.includes(Permission.FacilityManagerFilterDisplay),
                        this._userService.clientGetFacilityManagers(),
                        of(null)
                    )
                ),
                shareReplay(1)
            );
        }
        // initialize options pipe once input defined
        if (this.options$ === undefined &&
            !!this.facilityManagers$ &&
            !!this.selectedFmRoleId$
        ) {
            this.options$ = combineLatest([
                this.facilityManagers$,
                this.selectedFmRoleId$
            ]).pipe(
                takeUntil(this._onDestroy$),
                map(([fmsRaw, selRoleId]) => {
                    if (!!fmsRaw && fmsRaw.length > 0) {
                        const fms = (!!selRoleId)
                            ? fmsRaw.filter(fm => fm.roleIds.includes(selRoleId))
                            : fmsRaw;
                        return [
                            { value: null, label: '' },
                            ...fms.sort((a, b) =>
                                a.profile.firstName + a.profile.lastName <
                                    b.profile.firstName + b.profile.lastName
                                        ? -1 : 1
                            ).map(fm => ({
                                value: fm._id,
                                label: `${fm.profile.firstName} ${fm.profile.lastName}`
                            }))
                        ];
                    } else {
                        return [{ value: null, label: 'None Found'}];
                    }
                })
            );
        }
    }

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

}
