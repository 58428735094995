import { Injectable } from '@angular/core';
import { HttpService, Post, Body } from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { ILogEntry } from '@gm2/common';

@Injectable({
    providedIn: 'root'
})
export class AppErrorService extends HttpService {
    @Post('/appError')
    public submitError(@Body() logs: ILogEntry[]): Observable<void> {
        return null;
    }
}
