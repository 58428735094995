<div class="time" [formGroup]="form">
    <mat-form-field>
        <input
            matInput
            [placeholder]="'TIMESHEET.HOURS'|translate"
            type="number"
            min="0"
            formControlName="hours"
        />
        <mat-error [rfxError]="getControl('hours')"></mat-error>
    </mat-form-field>
    <mat-form-field>
        <input
            matInput
            [placeholder]="'TIMESHEET.MINUTES'|translate"
            type="number"
            min="0"
            formControlName="minutes"
        />
        <mat-error [rfxError]="getControl('minutes')"></mat-error>
    </mat-form-field>
</div>
<ng-container *ngIf="(timeAvailable$ | async) as time">
    <button
        mat-flat-button
        color="primary"
        type="button"
        class="allocate"
        (click)="allocateTime(time)"
        [disabled]="time <= 0"
        *ngIf="time !== 0"
    >
        {{ timeAvailableString$ | async }}
    </button>
</ng-container>
