/**
 *
 * @remarks
 * The permissions for some actions (such as user creation) are scoped to the
 * company the permitted user belongs to. Some of these permissions have an
 * "External" variant that should allow for that action on companies other than
 * your own. These will _likely_ only be used in the Admin and Super Admin roles
 * but the exact roles are undefined.  There also appears to be some "Internal"
 * variants that are simply more explicit about this behavior.
 */
export enum Permission {
    ImportLocations = '[Permission] importLocations',

    UserProfile = '[Permission] userProfile',
    UserView = '[Permission] userView',
    UserCreate = '[Permission] userCreate',
    UserEdit = '[Permission] userEdit',
    UserEditPasswords = '[Permission] userEditPasswords',
    UserDelete = '[Permission] userDelete',
    UserImpersonate = '[Permission] userImpersonate',
    UserNotificationsEdit = '[Permission] userNotificationsEdit',

    /**
     * Special permission that allows a user to manage resources not explicitly
     * assigned to their company. This means that any permissions a user has
     * been granted will now also apply to them in the context of any company
     * in the site.
     */
    ExternalResources = '[Permission] externalResources',

    CompanyProfile = '[Permission] companyProfile',
    CompanyViewExternal = '[Permission] companyViewExternal',
    CompanyDeleteExternal = '[Permission] companyDeleteExternal',
    CompanyCreateExternal = '[Permission] companyCreateExternal',
    CompanyEditExternal = '[Permission] companyEditExternal',
    CompanyEditInsurance = '[Permission] companyEditServicePartnerInsurance',
    CompanyEditStatus = '[Permission] companyEditServicePartnerStatus',

    RoleCreate = '[Permission] roleCreate',
    RoleView = '[Permission] roleView',
    RoleEdit = '[Permission] roleEdit',
    RoleDelete = '[Permission] roleDelete',

    ServiceTypeCreate = '[Permission] serviceTypeCreate',
    ServiceTypeView = '[Permission] serviceTypeView',
    ServiceTypeEdit = '[Permission] serviceTypeEdit',
    ServiceTypeDelete = '[Permission] serviceTypeDelete',
    ServiceCreate = '[Permission] serviceCreate',
    ServiceView = '[Permission] serviceView',
    ServiceEdit = '[Permission] serviceEdit',
    ServiceDelete = '[Permission] serviceDelete',
    ServiceImport = '[Permission] serviceImport',

    CompanyMaterialCreate = '[Permission] companyMaterialCreate',
    CompanyMaterialDelete = '[Permission] companyMaterialDelete',
    CompanyMaterialActiveIngredientEdit = '[Permission] companyMaterialActiveIngredientEdit',
    CompanyMaterialApprove = '[Permission] companyMaterialApprove',

    CompanyServiceCreate = '[Permission] companyServiceCreate',
    CompanyServiceDelete = '[Permission] companyServiceDelete',
    CompanyServiceApprove = '[Permission] companyServiceApprove',

    CompanyLaborCreate = '[Permission] companyLaborCreate',
    CompanyLaborDelete = '[Permission] companyLaborDelete',

    SpTimeAllocationToggle = '[Permission] spTimeAllocationToggle',

    PackageCreate = '[Permission] packageCreate',
    PackageView = '[Permission] packageView',
    PackageEdit = '[Permission] packageEdit',
    PackageDelete = '[Permission] packageDelete',

    TagCreate = '[Permission] tagCreate',
    TagView = '[Permission] tagView',
    TagEdit = '[Permission] tagEdit',
    TagDelete = '[Permission] tagDelete',

    LocationCreate = '[Permission] locationCreate',
    LocationView = '[Permission] locationView',
    LocationEdit = '[Permission] locationEdit',
    LocationDelete = '[Permission] locationDelete',
    LocationViewInactive = '[Permission] locationViewInactive',
    LocationActivate = '[Permission] locationActivate',
    LocationImagesViewAll = '[Permission] locationImagesViewAll',
    LocationImagesEdit = '[Permission] locationImagesEdit',
    LocationImagesDelete = '[Permission] locationImagesDelete',
    LocationNotePrivateView = '[Permission] locationNotePrivateView',
    LocationNotePrivateCreate = '[Permission] locationNotePrivateCreate',
    LocationNoteCreate = '[Permission] locationNoteCreate',
    LocationSiteEngineeringPlanView = '[Permission] locationSiteEngineeringPlanView',
    LocationSiteEngineeringPlanManage = '[Permission] locationSiteEngineeringPlanManage',
    LocationSiteEngineeringPlanApprove = '[Permission] locationSiteEngineeringPlanApprove',
    LocationServiceActivityListView = '[Permission] locationServiceActivityListView',
    LocationImport = '[Permission] locationImport',

    ContractCreate = '[Permission] contractCreate',
    ContractView = '[Permission] contractView',
    ContractEdit = '[Permission] contractEdit',
    ContractLocationModify = '[Permission] contractLocationModify',
    ContractDelete = '[Permission] contractDelete',
    ContractUnaward = '[Permission] contractUnaward',

    HelpCreate = '[Permission] helpCreate',
    HelpView = '[Permission] helpView',
    HelpEdit = '[Permission] helpEdit',
    HelpDelete = '[Permission] helpDelete',

    InvoiceCreateEdit = '[Permission] invoiceCreateEdit',
    InvoiceView = '[Permission] invoiceView',
    InvoiceApproval = '[Permission] invoiceApproval',
    InvoicePost = '[Permission] invoicePost',
    InvoiceBilling = '[Permission] invoiceBilling',
    InvoiceDecline = '[Permission] invoiceDecline',
    InvoiceSpecialDecline = '[Permission] invoiceSpecialDecline',

    RfpList = '[Permission] rfpList',
    RfpFirstApproval = '[Permission] rfpFirstApproval',
    RfpAward = '[Permission] rfpAward',
    RfpClientPricing = '[Permission] rfpClientPricing',
    RfpBidCreate = '[Permission] rfpBidCreate',

    WorkorderView = '[Permission] workorderView',
    WorkorderCreate = '[Permission] workorderCreate',
    WorkorderEditPricing = '[Permission] workorderEditPricing',
    WorkorderPending = '[Permission] workorderPending',
    WorkorderMarkForReview = '[Permission] workorderMarkForReview',
    WorkorderApprove = '[Permission] workorderApprove',
    WorkorderAutoApprove = '[Permission] workorderAutoApprove',
    WorkorderUnApprove = '[Permission] workorderUnApprove',
    WorkorderReject = '[Permission] workorderReject',
    WorkorderServicePartnerReject = '[Permission] workorderServicePartnerReject',
    WorkorderClientReject = '[Permission] workorderClientReject',
    WorkorderComplete = '[Permission] workorderComplete',
    WorkorderPost = '[Permission] workorderPost',
    WorkorderBilling = '[Permission] workorderBilling',
    WorkorderHoldRelease = '[Permission] workorderHoldRelease',
    WorkorderActivation = '[Permission] workorderActivation',
    WorkorderPhaseServicePartnerOffered = '[Permission] workorderPhaseServicePartnerOffered',
    WorkorderPhaseServicePartnerRejected = '[Permission] workorderPhaseServicePartnerRejected',
    WorkorderPhaseAccepted = '[Permission] workorderPhaseAccepted',
    WorkorderPhaseComplete = '[Permission] workorderPhaseComplete',
    WorkorderCreateNote = '[Permission] workorderCreateNote',
    WorkorderDeleteAllNotes = '[Permission] workorderDeleteAllNotes',
    /*
        All 'WorkorderApp' permissions appear to be
        unused anywhere in the gm2 codebase
    */
    WorkorderAppSearchCreated = '[Permission] workorderAppSearchCreated',
    WorkorderAppSearchApproved = '[Permission] workorderAppSearchApproved',
    WorkorderAppSearchDeclined = '[Permission] workorderAppSearchDeclined',
    WorkorderAppSearchCompleted = '[Permission] workorderAppSearchCompleted',
    WorkorderAppSearchApprovedForInvoicing = '[Permission] workorderAppSearchForInvoicing',
    WorkorderAppSearchInvoiced = '[Permission] workorderAppSearchInvoiced',
    WorkorderAppSearchActive = '[Permission] workorderAppSearchActive',
    WorkorderAppSearchHold = '[Permission] workorderAppSearchHold',
    WorkorderAppSearchInactive = '[Permission] workorderAppSearchInactive',
    WorkorderSeeDaysInCurrentStatus = '[Permission] workorderSeeDaysInCurrentStatus',
    WorkorderReferredByView = '[Permission] workorderReferredByView',
    WorkorderReferredByEdit = '[Permission] workorderReferredByEdit',

    ReportingManage = '[Permission] reportingManage',
    ReportingAdminReport30Day = '[Permission] reportingAdminReport30Day',
    ReportingAllServices = '[Permission] reportingAllServices',
    ReportingAllServicesSeason = '[Permission] reportingServicesSeason',
    ReportingAppErrorReports = '[Permission] reportingAppErrorReporting',
    ReportingAvailableBids = '[Permission] reportingAvailableBids',
    ReportingAwardedBids = '[Permission] reportingAwardedBids',
    ReportingClientMonthlyInvoiceReport = '[Permission] reportingClientMonthlyInvoiceReport',
    ReportingClientInvoiceReport = '[Permission] reportingClientInvoiceReport',
    ReportingClientMonthlySnowActivityReport = '[Permission] reportingClientMonthlySnowActivityReport',
    ReportingClientWorkOrderReport = '[Permission] reportingClientWorkOrderReport',
    ReportingCompanyQSR = '[Permission] reportingCompanyQSR',
    ReportingLocationReport = '[Permission] reportingLocationReport',
    ReportingPackageList = '[Permission] reportingPackageList',
    ReportingTotalFootage = '[Permission] reportingTotalFootage',
    ReportingPurchaseOrderRepot = '[Permission] reportingPurchaseOrderReport',
    ReportingBenefitReport = '[Permission] reportingBenefitReport',
    ReportingDiversityReport = '[Permission] reportingDiversityReport',
    ReportingSustainabilityReport = '[Permission] reportingSustainabilityReport',
    ReportingQcRegions = '[Permission] reportingQcRegions',
    ReportingSpBillingReport = '[Permission] reportingSpBillingReport',
    ReportingSpBidReport = '[Permission] reportingSpBidReport',
    ReportingSpEmailList = '[Permission] reportingSpEmailList',
    ReportingWorkOrderBillingReport = '[Permission] reportingWorkOrderBillingReport',
    ReportingClientReport30Day = '[Permission] reportingClientReport30Day',
    ReportingLandscapeDataReport = '[Permission] reportingLandscapeDataReport',
    ReportingDailyServices = '[Permission] reportingDailyServices',
    ReportingTimeSpentCrews = '[Permission] reportingTimeSpentCrews',

    FormList = '[Permission] formList',
    FormBuilder = '[Permission] formBuilder',
    // FormCreate = '[Permission] formCreate',
    // FormGet = '[Permission] formGet',
    // FormEdit = '[Permission] formEdit',
    // FormDelete = '[Permission] formDelete',
    // FormListUi = '[Permission] formListUi',
    // FormSubmit = '[Permission] formSubmit',
    // FormExport = '[Permission] formExport',
    // FormGetRecentResponse = '[Permission] formGetRecentResponse',

    BuilderIndex = '[Permission] builderIndex',
    BuilderLogin = '[Permission] builderLogin',
    BuilderReport = '[Permission] builderReport',

    TimesheetManage = '[Permission] timesheetManage',
    TimesheetOneGMEdit = '[Permission] timesheetOneGMEdit',
    TimesheetValidation = '[Permission] timesheetValidation',
    SubmitTimesheetOnBehalfOfServicePartner = '[Permission] submitTSOnBehalfOfSP',

    CrewView = '[Permission] crewView',
    CrewCreate = '[Permission] crewCreate',
    CrewEdit = '[Permission] crewEdit',
    CrewDelete = '[Permission] crewDelete',

    ReportDataCompany = '[Permission] reportDataCompany',
    ReportDataCompanyPrice = '[Permission] reportDataCompanyPrice',
    ReportDataContractApplied = '[Permission] reportDataContractApplied',
    ReportDataContractTemplate = '[Permission] reportDataContractTemplate',
    ReportDataForms = '[Permission] reportDataForms',
    ReportDataFormsUserData = '[Permission] reportDataFormsUserData',
    ReportDataInspection = '[Permission] reportDataInspection',
    ReportDataInspectionQuestions = '[Permission] reportDataInspectionQuestions',
    ReportDataInvoice = '[Permission] reportDataInvoice',
    ReportDataLocation = '[Permission] reportDataLocation',
    ReportDataMapping = '[Permission] reportDataMapping',
    ReportDataMedia = '[Permission] reportDataMedia',
    ReportDataNote = '[Permission] reportDataNote',
    ReportDataNotification = '[Permission] reportDataNotification',
    ReportDataPackage = '[Permission] reportDataPackage',
    ReportDataRfp = '[Permission] reportDataRfp',
    ReportDataRole = '[Permission] reportDataRole',
    ReportDataService = '[Permission] reportDataService',
    ReportDataServiceType = '[Permission] reportDataServiceType',
    ReportDataTimesheet = '[Permission] reportDataTimesheet',
    ReportDataUser = '[Permission] reportDataUser',
    ReportDataWorkorder = '[Permission] reportDataWorkorder',
    ReportDataWorkorderHistory = '[Permission] reportDataWorkorderHistory',
    ReportDataLogs = '[Permission] reportDataLogs',

    LoginBuilderView = '[Permission] loginBuilderView',
    LoginBuilderCreate = '[Permission] loginBuilderCreate',
    LoginBuilderEdit = '[Permission] loginBuilderEdit',
    LoginBuilderDelete = '[Permission] loginBuilderDelete',

    /**
     * Unlisted permissions that allows a user to see the corresponding menu
     * item. Each "display" permission should be accompanied by a "view"
     * permission that should be used for actually checking if a user has
     * access to some resource. The display permission should only be used for
     * showing the menu item.
     *
     * The Admin role has all of these display permissions. The three client
     * roles have the LocationDisplay and LocationServiceActivityListDisplay
     * permissions but not the RfpListDisplay permission.  Many roles have the
     * view permissions.
     *
     * May 26th 2020
     */
    ServiceTypeDisplay = '[Permission] serviceTypeDisplay',
    LocationDisplay = '[Permission] locationDisplay',
    LocationServiceActivityListDisplay = '[Permission] locationServiceActivityListDisplay',
    RfpListDisplay = '[Permission] rfpListDisplay',

    /**
     * special permissions to support client facility manager filter components
     */
    FacilityManagerFilterDisplay = '[Permission] facilityManagerFilterDisplay',
    FacilityManagerAppearsInFilter = '[Permission] facilityManagerAppearsInFilter',

    /**
     * These are special permissions used to put on roles that are for a specific type of user
     */
    IsLocationStrategicAccountManager = '[Permission] isLocationStrategicAccountManager',
    IsLocationProductionManager = '[Permission] isLocationProductionManager',
    IsLocationFacilityManager = '[Permission] isLocationFacilityManager',

    /**
     * A special permission used only in the temporary password reset tokens.
     * This is intentionally left out of the toString and members fields
     * (unlisted).
     */
    ResetPassword = '[Permission] reset password',
    /**
     * A special permission used only in refresh tokens for getting a new auth
     * token. This should be unlisted.
     */
    RefreshToken = '[Permission] refresh token',
    /**
     * A special permission used only in timesheet tokens for creating (only)
     * timesheets from an un-expiring token.
     */
    TimesheetCreate = '[Permission] timesheet token',

    /**
     * MultiTenant
     */
    InviteCreate = '[Permission] Invite Create',
    CompanyImport = '[Permission] companyImport',
    SuperAdmin = '[Permission] Super Admin',
    ClientServiceImport = '[Permission] clientServiceImport',
    ContractImport = '[Permission] contractImport',
    UserImport = '[Permission] userImport',

    /**
     * Quickbooks
     */
    ManageQBConnection = '[Permission] manageQBConnection',
    TriggerQBManually = '[Permission] triggerQBManually',
    ConfigureQBSync = '[Permission] configureQBSync',

    ViewCompanyInvitations = '[Permission] viewCompanyInvitations',
    AcceptCompanyInvitations = '[Permission] acceptCompanyInvitations',
    RejectCompanyInvitations = '[Permission] rejectCompanyInvitations',


    /**
     * Routes
     */
    ManageOwnRoutes = '[Permission] manageOwnRoutes',
    CreateCrewRoutes = '[Permission] createCrewRoutes',
    EditCrewRoutes = '[Permission] editCrewRoutes',
    DeleteCrewRoutes = '[Permission] deleteCrewRoutes',
    ViewCrewRoutes = '[Permission] viewCrewRoutes',
    DispatchWorkOrders = '[Permission] dispatchWorkOrders',
    ViewActualTimeSpentOnLocation = '[Permission] viewActualTimeSpentOnLocation',
    ManageGeospatialServices = '[Permission] manageGeospatialServices',
}

export namespace Permission {
    export function toString(type: Permission): string {
        switch (type) {
            case Permission.ImportLocations:
                return 'Import Locations';
            case Permission.UserProfile:
                return 'User Profile';
            case Permission.UserView:
                return 'View Users';
            case Permission.UserCreate:
                return 'Create Users';
            case Permission.UserEdit:
                return 'Edit Users';
            case Permission.UserEditPasswords:
                return 'Edit Passwords';
            case Permission.UserDelete:
                return 'Delete Users';
            case Permission.UserImpersonate:
                return 'Impersonate Users';
            case Permission.UserNotificationsEdit:
                return 'User Notifications Edit';

            case Permission.ExternalResources:
                return 'Manage External Resources';

            case Permission.CompanyProfile:
                return 'View / Edit Company Profile';
            case Permission.CompanyViewExternal:
                return 'View External Company';
            case Permission.CompanyDeleteExternal:
                return 'Delete External Company';
            case Permission.CompanyCreateExternal:
                return 'Create External Company';
            case Permission.CompanyEditExternal:
                return 'Edit External Company';
            case Permission.CompanyEditInsurance:
                return 'Edit Company Insurance';
            case Permission.CompanyEditStatus:
                return 'Edit Company Status';
            case Permission.SpTimeAllocationToggle:
                return 'Sp Time Allocation Toggle';

            case Permission.RoleCreate:
                return 'Create Roles';
            case Permission.RoleView:
                return 'View Roles';
            case Permission.RoleEdit:
                return 'Edit Roles';
            case Permission.RoleDelete:
                return 'Delete Roles';

            case Permission.ServiceTypeCreate:
                return 'Create Service Types';
            case Permission.ServiceTypeView:
                return 'View Service Types';
            case Permission.ServiceTypeEdit:
                return 'Edit Service Types';
            case Permission.ServiceTypeDelete:
                return 'Delete Service Types';
            case Permission.ServiceCreate:
                return 'Create Services';
            case Permission.ServiceView:
                return 'View Services';
            case Permission.ServiceEdit:
                return 'Edit Services';
            case Permission.ServiceDelete:
                return 'Delete Services';
            case Permission.ServiceImport:
                return 'Import Services';

            case Permission.CompanyMaterialCreate:
                return 'Create Company Materials';
            case Permission.CompanyMaterialDelete:
                return 'Delete Company Materials';
            case Permission.CompanyMaterialActiveIngredientEdit:
                return 'Edit Company Materials Types';
            case Permission.CompanyMaterialApprove:
                return 'Approve Company Materials';

            case Permission.CompanyServiceCreate:
                return 'Create Company Service';
            case Permission.CompanyServiceDelete:
                return 'Delete Company Service';
            case Permission.CompanyServiceApprove:
                return 'Approve Company Service';

            case Permission.CompanyLaborCreate:
                return 'Create Company Labor';
            case Permission.CompanyLaborDelete:
                return 'Delete Company Labor';

            case Permission.PackageCreate:
                return 'Create Package';
            case Permission.PackageView:
                return 'View Package';
            case Permission.PackageEdit:
                return 'Edit Package';
            case Permission.PackageDelete:
                return 'Delete Package';

            case Permission.TagCreate:
                return 'Create Tag';
            case Permission.TagView:
                return 'View Tag';
            case Permission.TagEdit:
                return 'Edit Tag';
            case Permission.TagDelete:
                return 'Delete Tag';

            case Permission.LocationCreate:
                return 'Create Location';
            case Permission.LocationView:
                return 'View Location';
            case Permission.LocationEdit:
                return 'Edit Location';
            case Permission.LocationDelete:
                return 'Delete Location';
            case Permission.LocationViewInactive:
                return 'View Inactive Location';
            case Permission.LocationActivate:
                return 'Activate Location';
            case Permission.LocationImagesViewAll:
                return 'View All Location Iimages';
            case Permission.LocationImagesEdit:
                return 'Edit Location Image';
            case Permission.LocationImagesDelete:
                return 'Delete Location Image';
            case Permission.LocationNotePrivateView:
                return 'View Location Private Note';
            case Permission.LocationNotePrivateCreate:
                return 'Create Location Private Note';
            case Permission.LocationNoteCreate:
                return 'Create Location Note';
            case Permission.LocationSiteEngineeringPlanView:
                return 'View Site Engineering Plan';
            case Permission.LocationSiteEngineeringPlanManage:
                return 'Manage Location Site Engineering Plan';
            case Permission.LocationSiteEngineeringPlanApprove:
                return 'Approvate Location Site Engineering Plan';
            case Permission.LocationServiceActivityListView:
                return 'View Location Service Activities';
            case Permission.IsLocationStrategicAccountManager:
                return 'View Location Strategic Account Managers';
            case Permission.IsLocationProductionManager:
                return 'View Location Production Managers';
            case Permission.IsLocationFacilityManager:
                return 'View Location Facility Managers';

            case Permission.ContractCreate:
                return 'Create Contract';
            case Permission.ContractView:
                return 'View Contract';
            case Permission.ContractEdit:
                return 'Edit Contract';
            case Permission.ContractLocationModify:
                return 'Modify Locations on Contract';
            case Permission.ContractDelete:
                return 'Delete Contract';
            case Permission.ContractUnaward:
                return 'Unaward Contract';

            case Permission.HelpCreate:
                return 'Create Help';
            case Permission.HelpView:
                return 'View Help';
            case Permission.HelpEdit:
                return 'Edit Help';
            case Permission.HelpDelete:
                return 'Delete Help';

            case Permission.InvoiceCreateEdit:
                return 'Create / Edit Invoice';
            case Permission.InvoiceView:
                return 'View Invoice';
            case Permission.InvoiceApproval:
                return 'Approve Invoice';
            case Permission.InvoicePost:
                return 'Set Invoice Post Status';
            case Permission.InvoiceBilling:
                return 'Set Invoice Billing Status';
            case Permission.InvoiceDecline:
                return 'Decline Invoice';
            case Permission.InvoiceSpecialDecline:
                return 'Special Decline Invoice';

            case Permission.RfpList:
                return 'View Rfp';
            case Permission.RfpFirstApproval:
                return 'Set Rfp First Approvals';
            case Permission.RfpAward:
                return 'Award Rfp';
            case Permission.RfpClientPricing:
                return 'Set Rfp Client Pricing';
            case Permission.RfpBidCreate:
                return 'Place Bids on RFPs';

            case Permission.WorkorderView:
                return 'View Workorder';
            case Permission.WorkorderCreate:
                return 'Create Workorder';
            case Permission.WorkorderEditPricing:
                return 'Workorder Edit Pricing';
            case Permission.WorkorderPending:
                return 'Workorder Mark Pending';
            case Permission.WorkorderMarkForReview:
                return 'Workorder Mark for review';
            case Permission.WorkorderApprove:
                return 'Approve Workorder';
            case Permission.WorkorderAutoApprove:
                return 'Auto Approve Workorder';
            case Permission.WorkorderUnApprove:
                return 'Unapprove Workorder';
            case Permission.WorkorderReject:
                return 'Reject Workorder';
            case Permission.WorkorderComplete:
                return 'Complete Workorder';
            case Permission.WorkorderPost:
                return 'Set Workorder Post Status';
            case Permission.WorkorderBilling:
                return 'Set Workorder Billing Status';
            case Permission.WorkorderHoldRelease:
                return 'Hold Release Workorder';
            case Permission.WorkorderActivation:
                return 'Workorder Activation';
            case Permission.WorkorderPhaseServicePartnerOffered:
                return 'Offer Workorder To Service Partner';
            case Permission.WorkorderPhaseServicePartnerRejected:
                return 'Service Partner Reject Workorder';
            case Permission.WorkorderPhaseAccepted:
                return 'Set Workorder Phase Accepted Status';
            case Permission.WorkorderPhaseComplete:
                return 'Set Workorder Phase Complete Status';
            case Permission.WorkorderCreateNote:
                return 'Create Workorder Note';
            case Permission.WorkorderDeleteAllNotes:
                return 'Delete All Workorder Note';
            case Permission.WorkorderAppSearchCreated:
                return 'Create Workorder App Search';
            case Permission.WorkorderAppSearchApproved:
                return 'Approved Workorder App Search';
            case Permission.WorkorderAppSearchDeclined:
                return 'Declined Workorder App Search';
            case Permission.WorkorderAppSearchCompleted:
                return 'Completed Workorder App Search';
            case Permission.WorkorderAppSearchApprovedForInvoicing:
                return 'Approved for invoicing Workorder App Search';
            case Permission.WorkorderAppSearchInvoiced:
                return 'Invoiced Workorder App Search';
            case Permission.WorkorderAppSearchActive:
                return 'Active Workorder App Search';
            case Permission.WorkorderAppSearchHold:
                return 'Hold Workorder App Search';
            case Permission.WorkorderAppSearchInactive:
                return 'Inactive Workorder App Search';
            case Permission.WorkorderSeeDaysInCurrentStatus:
                return 'View Workorder Days in current status';
            case Permission.WorkorderReferredByView:
                return 'View Workorder Referred By Field';
            case Permission.WorkorderReferredByEdit:
                return 'Edit Workorder Referred By Field';
            case Permission.WorkorderServicePartnerReject:
                return 'Reject Service Partner Workorder';
            case Permission.WorkorderClientReject:
                return 'Reject Client Workorder';

            case Permission.ReportingManage:
                return 'Manage Reporting';
            case Permission.ReportingAdminReport30Day:
                return 'Reporting Admin Report 30 Day';
            case Permission.ReportingAllServices:
                return 'Reporting All Services';
            case Permission.ReportingAllServicesSeason:
                return 'Reporting All Services Season';
            case Permission.ReportingAppErrorReports:
                return 'Reporting App Error Reports';
            case Permission.ReportingAvailableBids:
                return 'Reporting Available Bids';
            case Permission.ReportingAwardedBids:
                return 'Reporting Awarded Bids';
            case Permission.ReportingClientMonthlyInvoiceReport:
                return 'Reporting Client Monthly Invoice EReport';
            case Permission.ReportingClientInvoiceReport:
                return 'Reporting Client invoice Report';
            case Permission.ReportingClientMonthlySnowActivityReport:
                return 'Reporting Client Monthly Snow Activity Report';
            case Permission.ReportingClientWorkOrderReport:
                return 'Reporting Client Work Order Report';
            case Permission.ReportingCompanyQSR:
                return 'Reporting Company QSR';
            case Permission.ReportingLocationReport:
                return 'Reporting Location Report';
            case Permission.ReportingPackageList:
                return 'Reporting Package List';
            case Permission.ReportingTotalFootage:
                return 'Reporting Total Footage';
            case Permission.ReportingPurchaseOrderRepot:
                return 'Reporting Purchase Order Report';
            case Permission.ReportingBenefitReport:
                return 'Reporting Benefit Report';
            case Permission.ReportingDiversityReport:
                return 'Reporting Diversity Report';
            case Permission.ReportingSustainabilityReport:
                return 'Reporting Sustainability Report';
            case Permission.ReportingQcRegions:
                return 'Reporting Qc Regions';
            case Permission.ReportingSpBillingReport:
                return 'Reporting Sp Billing Report';
            case Permission.ReportingSpBidReport:
                return 'Reporting Sp Bid Report';
            case Permission.ReportingSpEmailList:
                return 'Reporting Sp Email List';
            case Permission.ReportingWorkOrderBillingReport:
                return 'Reporting Work Order Billing Report';
            case Permission.ReportingClientReport30Day:
                return 'Reporting Client report 30 day';
            case Permission.ReportingLandscapeDataReport:
                return 'Reporting Landscape Data Report';
            case Permission.ReportingDailyServices:
                return 'Reporting Daily Services';
            case Permission.ReportingTimeSpentCrews:
                return 'Reporting Time Spent by Crews'

            case Permission.FormList:
                return 'Form List';
            case Permission.FormBuilder:
                return 'Form Builder';

            case Permission.BuilderIndex:
                return 'Builder index';
            case Permission.BuilderLogin:
                return 'Builder Login';
            case Permission.BuilderReport:
                return 'Builder Report';

            case Permission.TimesheetManage:
                return 'Manage Timsheets';
            case Permission.TimesheetOneGMEdit:
                return 'TimesheetOneGMEdit';
            case Permission.TimesheetValidation:
                return 'Timesheet Validation';
            case Permission.SubmitTimesheetOnBehalfOfServicePartner:
                return 'Submit timesheet on Service Partner’s behalf'

            case Permission.CrewCreate:
                return 'Create Crew';
            case Permission.CrewView:
                return 'View Crew';
            case Permission.CrewEdit:
                return 'Edit Crew';
            case Permission.CrewDelete:
                return 'Delete Crew';

            case Permission.ReportDataCompany:
                return 'Company Report Data';
            case Permission.ReportDataCompanyPrice:
                return 'Company Price Report Data';
            case Permission.ReportDataContractApplied:
                return 'Applied Contract Report Data';
            case Permission.ReportDataContractTemplate:
                return 'Contract Template Report Data';
            case Permission.ReportDataForms:
                return 'Forms Report Data';
            case Permission.ReportDataFormsUserData:
                return 'Forms User Data Report Data';
            case Permission.ReportDataInspection:
                return 'Inspection Report Data';
            case Permission.ReportDataInspectionQuestions:
                return 'Inspection Questions Report Data';
            case Permission.ReportDataInvoice:
                return 'Invoice Report Data';
            case Permission.ReportDataLocation:
                return 'Location Report Data';
            case Permission.ReportDataMapping:
                return 'Mapping Report Data';
            case Permission.ReportDataMedia:
                return 'Media Report Data';
            case Permission.ReportDataNote:
                return 'Note Report Data';
            case Permission.ReportDataNotification:
                return 'Notification Report Data';
            case Permission.ReportDataPackage:
                return 'Package Report Data';
            case Permission.ReportDataRfp:
                return 'Rfp Report Data';
            case Permission.ReportDataRole:
                return 'Role Report Data';
            case Permission.ReportDataService:
                return 'Service Report Data';
            case Permission.ReportDataServiceType:
                return 'Service Type Report Data';
            case Permission.ReportDataTimesheet:
                return 'Timesheet Report Data';
            case Permission.ReportDataUser:
                return 'User Report Data';
            case Permission.ReportDataWorkorder:
                return 'Workorder Report Data';
            case Permission.ReportDataWorkorderHistory:
                return 'Workorder History Report Data';

            case Permission.ServiceTypeDisplay:
                return 'Show Service Types Menu Item';
            case Permission.LocationDisplay:
                return 'Show Locations Menu Item';
            case Permission.LocationServiceActivityListDisplay:
                return 'Show Service Activities Menu Item';
            case Permission.RfpListDisplay:
                return 'Show Bids Menu Item';

            case Permission.InviteCreate:
                return 'Create Invitation';
            case Permission.CompanyImport:
                return 'Company Import';

            case Permission.ClientServiceImport:
                return 'Import Client Services';
            case Permission.ContractImport:
                return 'Import Contract';

            case Permission.ManageQBConnection:
                return 'Manage QuickBooks connection';
            case Permission.TriggerQBManually:
                return 'Trigger QuickBooks Sync manually';
            case Permission.ConfigureQBSync:
                return 'Configure QuickBooks sync';

            case Permission.ViewCompanyInvitations:
                return 'View company invitations';
            case Permission.AcceptCompanyInvitations:
                return 'Accept company invitation';
            case Permission.RejectCompanyInvitations:
                return 'Reject company invitation';

            case Permission.ManageOwnRoutes:
                return 'Manage own routes';
            case Permission.CreateCrewRoutes:
                return 'Create crew routes';
            case Permission.EditCrewRoutes:
                return 'Edit crew routes';
            case Permission.DeleteCrewRoutes:
                return 'Delete crew routes';
            case Permission.ViewCrewRoutes:
                return 'View crew routes';
            case Permission.DispatchWorkOrders:
                return 'Dispatch Work Orders';
            case Permission.ViewActualTimeSpentOnLocation:
                return 'View Actual Time Spent On Location';
            case Permission.ManageGeospatialServices:
                return 'Manage Geospatial Services';
            default:
                return '';
        }
    }

    export const members: Permission[] = [
        Permission.ImportLocations,
        Permission.UserProfile,
        Permission.UserView,
        Permission.UserCreate,
        Permission.UserEdit,
        Permission.UserEditPasswords,
        Permission.UserDelete,
        Permission.UserImpersonate,
        Permission.ExternalResources,
        Permission.UserNotificationsEdit,
        Permission.CompanyProfile,
        Permission.CompanyViewExternal,
        Permission.CompanyDeleteExternal,
        Permission.CompanyCreateExternal,
        Permission.CompanyEditExternal,
        Permission.CompanyEditInsurance,
        Permission.CompanyEditStatus,
        Permission.SpTimeAllocationToggle,
        Permission.RoleCreate,
        Permission.RoleView,
        Permission.RoleEdit,
        Permission.RoleDelete,
        Permission.ServiceTypeCreate,
        Permission.ServiceTypeView,
        Permission.ServiceTypeEdit,
        Permission.ServiceTypeDelete,
        Permission.ServiceCreate,
        Permission.ServiceView,
        Permission.ServiceEdit,
        Permission.ServiceDelete,
        Permission.ServiceImport,
        Permission.CompanyMaterialCreate,
        Permission.CompanyMaterialDelete,
        Permission.CompanyMaterialActiveIngredientEdit,
        Permission.CompanyMaterialApprove,
        Permission.CompanyServiceCreate,
        Permission.CompanyServiceDelete,
        Permission.CompanyServiceApprove,
        Permission.CompanyLaborCreate,
        Permission.CompanyLaborDelete,
        Permission.PackageCreate,
        Permission.PackageView,
        Permission.PackageEdit,
        Permission.PackageDelete,
        Permission.TagCreate,
        Permission.TagView,
        Permission.TagEdit,
        Permission.TagDelete,

        Permission.LocationCreate,
        Permission.LocationView,
        Permission.LocationEdit,
        Permission.LocationDelete,
        Permission.LocationViewInactive,
        Permission.LocationActivate,
        Permission.LocationImagesViewAll,
        Permission.LocationImagesEdit,
        Permission.LocationImagesDelete,
        Permission.LocationNotePrivateView,
        Permission.LocationNotePrivateCreate,
        Permission.LocationNoteCreate,
        Permission.LocationSiteEngineeringPlanView,
        Permission.LocationSiteEngineeringPlanManage,
        Permission.LocationSiteEngineeringPlanApprove,
        Permission.LocationServiceActivityListView,
        Permission.ContractCreate,
        Permission.ContractView,
        Permission.ContractEdit,
        Permission.ContractLocationModify,
        Permission.ContractDelete,
        Permission.ContractUnaward,
        Permission.HelpCreate,
        Permission.HelpView,
        Permission.HelpEdit,
        Permission.HelpDelete,
        Permission.InvoiceSpecialDecline,
        Permission.InvoiceCreateEdit,
        Permission.InvoiceView,
        Permission.InvoiceApproval,
        Permission.InvoicePost,
        Permission.InvoiceBilling,
        Permission.InvoiceDecline,
        Permission.RfpList,
        Permission.RfpFirstApproval,
        Permission.RfpAward,
        Permission.RfpClientPricing,
        Permission.RfpBidCreate,

        Permission.WorkorderView,
        Permission.WorkorderCreate,
        Permission.WorkorderEditPricing,
        Permission.WorkorderPending,
        Permission.WorkorderMarkForReview,
        Permission.WorkorderApprove,
        Permission.WorkorderAutoApprove,
        Permission.WorkorderUnApprove,
        Permission.WorkorderReject,
        Permission.WorkorderComplete,
        Permission.WorkorderPost,
        Permission.WorkorderBilling,
        Permission.WorkorderHoldRelease,
        Permission.WorkorderActivation,
        Permission.WorkorderPhaseServicePartnerOffered,
        Permission.WorkorderPhaseServicePartnerRejected,
        Permission.WorkorderPhaseAccepted,
        Permission.WorkorderPhaseComplete,
        Permission.WorkorderCreateNote,
        Permission.WorkorderDeleteAllNotes,
        Permission.WorkorderAppSearchCreated,
        Permission.WorkorderAppSearchApproved,
        Permission.WorkorderAppSearchDeclined,
        Permission.WorkorderAppSearchCompleted,
        Permission.WorkorderAppSearchApprovedForInvoicing,
        Permission.WorkorderAppSearchInvoiced,
        Permission.WorkorderAppSearchActive,
        Permission.WorkorderAppSearchHold,
        Permission.WorkorderAppSearchInactive,
        Permission.WorkorderSeeDaysInCurrentStatus,
        Permission.WorkorderReferredByView,
        Permission.WorkorderReferredByEdit,
        Permission.WorkorderServicePartnerReject,
        Permission.WorkorderClientReject,

        Permission.ReportingManage,
        Permission.ReportingAdminReport30Day,
        Permission.ReportingAllServices,
        Permission.ReportingAllServicesSeason,
        Permission.ReportingAppErrorReports,
        Permission.ReportingAvailableBids,
        Permission.ReportingAwardedBids,
        Permission.ReportingClientMonthlyInvoiceReport,
        Permission.ReportingClientInvoiceReport,
        Permission.ReportingClientMonthlySnowActivityReport,
        Permission.ReportingClientWorkOrderReport,
        Permission.ReportingCompanyQSR,
        Permission.ReportingLocationReport,
        Permission.ReportingPackageList,
        Permission.ReportingTotalFootage,
        Permission.ReportingPurchaseOrderRepot,
        Permission.ReportingBenefitReport,
        Permission.ReportingDiversityReport,
        Permission.ReportingSustainabilityReport,
        Permission.ReportingQcRegions,
        Permission.ReportingSpBillingReport,
        Permission.ReportingSpBidReport,
        Permission.ReportingSpEmailList,
        Permission.ReportingWorkOrderBillingReport,
        Permission.ReportingClientReport30Day,
        Permission.ReportingLandscapeDataReport,
        Permission.ReportingDailyServices,
        Permission.ReportingTimeSpentCrews,
        Permission.FormList,
        Permission.FormBuilder,
        Permission.BuilderIndex,
        Permission.BuilderLogin,
        Permission.BuilderReport,
        Permission.TimesheetManage,
        Permission.TimesheetOneGMEdit,
        Permission.TimesheetValidation,
        Permission.SubmitTimesheetOnBehalfOfServicePartner,
        Permission.CrewView,
        Permission.CrewCreate,
        Permission.CrewEdit,
        Permission.CrewDelete,

        Permission.ReportDataCompany,
        Permission.ReportDataCompanyPrice,
        Permission.ReportDataContractApplied,
        Permission.ReportDataContractTemplate,
        Permission.ReportDataForms,
        Permission.ReportDataFormsUserData,
        Permission.ReportDataInspection,
        Permission.ReportDataInspectionQuestions,
        Permission.ReportDataInvoice,
        Permission.ReportDataLocation,
        Permission.ReportDataMapping,
        Permission.ReportDataMedia,
        Permission.ReportDataNote,
        Permission.ReportDataNotification,
        Permission.ReportDataPackage,
        Permission.ReportDataRfp,
        Permission.ReportDataRole,
        Permission.ReportDataService,
        Permission.ReportDataServiceType,
        Permission.ReportDataTimesheet,
        Permission.ReportDataUser,
        Permission.ReportDataWorkorder,
        Permission.ReportDataWorkorderHistory,

        Permission.IsLocationStrategicAccountManager,
        Permission.IsLocationProductionManager,
        Permission.IsLocationFacilityManager,

        Permission.FacilityManagerFilterDisplay,
        Permission.FacilityManagerAppearsInFilter,

        Permission.ServiceTypeDisplay,
        Permission.LocationDisplay,
        Permission.LocationServiceActivityListDisplay,
        Permission.RfpListDisplay,
        Permission.ResetPassword,
        Permission.RefreshToken,
        Permission.ManageQBConnection,
        Permission.TriggerQBManually,
        Permission.ConfigureQBSync,

        Permission.ManageOwnRoutes,
        Permission.CreateCrewRoutes,
        Permission.EditCrewRoutes,
        Permission.DeleteCrewRoutes,
        Permission.ViewCrewRoutes,
        Permission.DispatchWorkOrders,
        Permission.ViewActualTimeSpentOnLocation,
        Permission.ManageGeospatialServices,
    ];

    export const membersSupport: Permission[] = [Permission.ImportLocations];

    export const membersUser: Permission[] = [
        Permission.UserProfile,
        Permission.UserView,
        Permission.UserCreate,
        Permission.UserEdit,
        Permission.UserEditPasswords,
        Permission.UserDelete,
        Permission.UserImpersonate,
        Permission.UserNotificationsEdit
    ];

    export const membersCompany: Permission[] = [
        Permission.ExternalResources,
        Permission.CompanyProfile,
        Permission.CompanyViewExternal,
        Permission.CompanyDeleteExternal,
        Permission.CompanyCreateExternal,
        Permission.CompanyEditExternal,
        Permission.CompanyEditInsurance,
        Permission.CompanyEditStatus,
        Permission.SpTimeAllocationToggle,
        Permission.CompanyLaborCreate,
        Permission.CompanyLaborDelete,
        Permission.ViewCompanyInvitations,
        Permission.AcceptCompanyInvitations,
        Permission.RejectCompanyInvitations
    ];

    export const membersRole: Permission[] = [
        Permission.RoleCreate,
        Permission.RoleView,
        Permission.RoleEdit,
        Permission.RoleDelete
    ];

    export const membersService: Permission[] = [
        Permission.ServiceTypeCreate,
        Permission.ServiceTypeView,
        Permission.ServiceTypeEdit,
        Permission.ServiceTypeDelete,
        Permission.ServiceCreate,
        Permission.ServiceView,
        Permission.ServiceEdit,
        Permission.ServiceDelete,
        Permission.ServiceTypeDisplay,
        Permission.ServiceImport
    ];

    export const membersCompanyMaterial: Permission[] = [
        Permission.CompanyMaterialCreate,
        Permission.CompanyMaterialDelete,
        Permission.CompanyMaterialActiveIngredientEdit,
        Permission.CompanyMaterialApprove
    ];

    export const membersCompanyService: Permission[] = [
        Permission.CompanyServiceCreate,
        Permission.CompanyServiceDelete,
        Permission.CompanyServiceApprove
    ];

    export const membersPackage: Permission[] = [
        Permission.PackageCreate,
        Permission.PackageView,
        Permission.PackageEdit,
        Permission.PackageDelete
    ];

    export const membersTag: Permission[] = [
        Permission.TagCreate,
        Permission.TagView,
        Permission.TagEdit,
        Permission.TagDelete
    ];

    export const membersLocation: Permission[] = [
        Permission.LocationCreate,
        Permission.LocationView,
        Permission.LocationEdit,
        Permission.LocationDelete,
        Permission.LocationViewInactive,
        Permission.LocationActivate,
        Permission.LocationImagesViewAll,
        Permission.LocationImagesEdit,
        Permission.LocationImagesDelete,
        Permission.LocationNotePrivateView,
        Permission.LocationNotePrivateCreate,
        Permission.LocationNoteCreate,
        Permission.LocationSiteEngineeringPlanView,
        Permission.LocationSiteEngineeringPlanManage,
        Permission.LocationSiteEngineeringPlanApprove,
        Permission.LocationServiceActivityListView,
        Permission.IsLocationStrategicAccountManager,
        Permission.IsLocationProductionManager,
        Permission.IsLocationFacilityManager,
        Permission.LocationDisplay,
        Permission.LocationServiceActivityListDisplay
    ];

    export const membersContract: Permission[] = [
        Permission.ContractCreate,
        Permission.ContractView,
        Permission.ContractEdit,
        Permission.ContractLocationModify,
        Permission.ContractDelete,
        Permission.ContractUnaward
    ];

    export const membersHelp: Permission[] = [
        Permission.HelpCreate,
        Permission.HelpView,
        Permission.HelpEdit,
        Permission.HelpDelete
    ];

    export const membersInvoice: Permission[] = [
        Permission.InvoiceSpecialDecline,
        Permission.InvoiceCreateEdit,
        Permission.InvoiceView,
        Permission.InvoiceApproval,
        Permission.InvoicePost,
        Permission.InvoiceBilling,
        Permission.InvoiceDecline
    ];

    export const membersRfp: Permission[] = [
        Permission.RfpList,
        Permission.RfpFirstApproval,
        Permission.RfpAward,
        Permission.RfpClientPricing,
        Permission.RfpBidCreate,
        Permission.RfpListDisplay
    ];

    export const membersWorkorder: Permission[] = [
        Permission.WorkorderView,
        Permission.WorkorderCreate,
        Permission.WorkorderEditPricing,
        Permission.WorkorderPending,
        Permission.WorkorderMarkForReview,
        Permission.WorkorderApprove,
        Permission.WorkorderAutoApprove,
        Permission.WorkorderUnApprove,
        Permission.WorkorderReject,
        Permission.WorkorderComplete,
        Permission.WorkorderPost,
        Permission.WorkorderBilling,
        Permission.WorkorderHoldRelease,
        Permission.WorkorderActivation,
        Permission.WorkorderPhaseServicePartnerOffered,
        Permission.WorkorderPhaseServicePartnerRejected,
        Permission.WorkorderPhaseAccepted,
        Permission.WorkorderPhaseComplete,
        Permission.WorkorderCreateNote,
        Permission.WorkorderDeleteAllNotes,
        Permission.WorkorderAppSearchCreated,
        Permission.WorkorderAppSearchApproved,
        Permission.WorkorderAppSearchDeclined,
        Permission.WorkorderAppSearchCompleted,
        Permission.WorkorderAppSearchApprovedForInvoicing,
        Permission.WorkorderAppSearchInvoiced,
        Permission.WorkorderAppSearchActive,
        Permission.WorkorderAppSearchHold,
        Permission.WorkorderAppSearchInactive,
        Permission.WorkorderSeeDaysInCurrentStatus,
        Permission.WorkorderReferredByView,
        Permission.WorkorderReferredByEdit,
        Permission.WorkorderServicePartnerReject,
        Permission.WorkorderClientReject
    ];

    export const membersReporting: Permission[] = [
        Permission.ReportingManage,
        Permission.ReportingAdminReport30Day,
        Permission.ReportingAllServices,
        Permission.ReportingAllServicesSeason,
        Permission.ReportingAppErrorReports,
        Permission.ReportingAvailableBids,
        Permission.ReportingAwardedBids,
        Permission.ReportingClientMonthlyInvoiceReport,
        Permission.ReportingClientInvoiceReport,
        Permission.ReportingClientMonthlySnowActivityReport,
        Permission.ReportingClientWorkOrderReport,
        Permission.ReportingCompanyQSR,
        Permission.ReportingLocationReport,
        Permission.ReportingPackageList,
        Permission.ReportingTotalFootage,
        Permission.ReportingPurchaseOrderRepot,
        Permission.ReportingBenefitReport,
        Permission.ReportingDiversityReport,
        Permission.ReportingSustainabilityReport,
        Permission.ReportingQcRegions,
        Permission.ReportingSpBillingReport,
        Permission.ReportingSpBidReport,
        Permission.ReportingSpEmailList,
        Permission.ReportingWorkOrderBillingReport,
        Permission.ReportingClientReport30Day,
        Permission.ReportingLandscapeDataReport,
        Permission.ReportingDailyServices,
        Permission.ReportingTimeSpentCrews
    ];

    export const membersForm: Permission[] = [Permission.FormList, Permission.FormBuilder];

    export const membersBuilder: Permission[] = [
        Permission.BuilderIndex,
        Permission.BuilderLogin,
        Permission.BuilderReport
    ];

    export const membersTimesheet: Permission[] = [
        Permission.TimesheetManage,
        Permission.TimesheetOneGMEdit,
        Permission.TimesheetValidation,
        Permission.SubmitTimesheetOnBehalfOfServicePartner,
    ];

    export const membersCrew: Permission[] = [
        Permission.CrewView,
        Permission.CrewCreate,
        Permission.CrewEdit,
        Permission.CrewDelete
    ];

    export const membersReportData: Permission[] = [
        Permission.ReportDataCompany,
        Permission.ReportDataCompanyPrice,
        Permission.ReportDataContractApplied,
        Permission.ReportDataContractTemplate,
        Permission.ReportDataForms,
        Permission.ReportDataFormsUserData,
        Permission.ReportDataInspection,
        Permission.ReportDataInspectionQuestions,
        Permission.ReportDataInvoice,
        Permission.ReportDataLocation,
        Permission.ReportDataMapping,
        Permission.ReportDataMedia,
        Permission.ReportDataNote,
        Permission.ReportDataNotification,
        Permission.ReportDataPackage,
        Permission.ReportDataRfp,
        Permission.ReportDataRole,
        Permission.ReportDataService,
        Permission.ReportDataServiceType,
        Permission.ReportDataTimesheet,
        Permission.ReportDataUser,
        Permission.ReportDataWorkorder,
        Permission.ReportDataWorkorderHistory
    ];

    export const invitesData: Permission[] = [
        Permission.InviteCreate
    ];

    export const membersQuickbooks: Permission[] = [
        Permission.ManageQBConnection,
        Permission.TriggerQBManually,
        Permission.ConfigureQBSync
    ];

    export const geospatial: Permission[] = [
        Permission.ManageOwnRoutes,
        Permission.CreateCrewRoutes,
        Permission.EditCrewRoutes,
        Permission.DeleteCrewRoutes,
        Permission.ViewCrewRoutes,
        Permission.DispatchWorkOrders,
        Permission.ViewActualTimeSpentOnLocation,
        Permission.ManageGeospatialServices,
    ];

    export enum PermissionRule {
        HasAll = '[PermissionMode] Has all',
        HasOne = '[PermissionMode] Has one',
    }
}
