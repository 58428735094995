<rfx-grid-filter-control>
    <mat-form-field appearance="outline">
        <mat-label [innerText]="placeholder"></mat-label>
        <input  matInput
                [owlDateTimeTrigger]="trigger"
                [owlDateTime]="trigger"
                [selectMode]="selectMode"
                [formControl]="baseControl"
                [readonly]="true" />
        <owl-date-time #trigger pickerType="calendar"></owl-date-time>
    </mat-form-field>
</rfx-grid-filter-control>
