
import { InvoiceWithoutIds } from './invoice';

export class InvoiceWithClientNumbersWithoutIds extends InvoiceWithoutIds {
    public clientInvoiceNumbers: string[] = [];

    constructor(params?: Partial<InvoiceWithClientNumbersWithoutIds>) {
        super(params);
        if (!!params) {
            this.clientInvoiceNumbers = params.clientInvoiceNumbers || this.clientInvoiceNumbers;
        }
    }
}
