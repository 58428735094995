import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
    WorkOrderStatus,
    WorkOrderActiveStatus,
    canSetWorkOrderActiveStatusActive,
    CompanyType
} from '@gm2/ui-common';

interface IData {
    status: WorkOrderStatus;
    activeStatus: WorkOrderActiveStatus;
    companyType: CompanyType;
}

@Component({
    selector: 'gm2-work-order-activate-modal',
    templateUrl: './work-order-activate-modal.component.html',
    styleUrls: ['./work-order-activate-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkOrderActivateModalComponent implements OnInit {
    public WorkOrderStatus: typeof WorkOrderStatus = WorkOrderStatus;

    public statusOptions: WorkOrderStatus[] = [];

    public form: UntypedFormGroup = this._fb.group({
        message: ['', Validators.required],
        status: ['', Validators.required],
    });

    constructor(
        private _fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<WorkOrderActivateModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IData
    ) {
        for (const m of WorkOrderStatus.members) {
            const permitted = canSetWorkOrderActiveStatusActive(
                data.companyType,
                data.status,
                data.activeStatus,
                m
            );
            if (permitted) {
                this.statusOptions.push(m);
            }
        }
    }

    ngOnInit(): void {}

    public close(): void {
        this.dialogRef.close(false);
    }

    public confirm(): void {
        this.dialogRef.close({
            status: this.form.value.status,
            message: this.form.value.message
        });
    }
}
