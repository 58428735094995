import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RfxGridFilterControlComponent, RfxGridFilterSelectOption } from '@refactor/ngx/grid';
import { TagService } from '@gm2/ui-common';
import { Observable, Subject, ReplaySubject, iif, EMPTY, of } from 'rxjs';
import { map, takeUntil, switchMap, catchError, shareReplay, take, tap } from 'rxjs/operators';

@Component({
    selector: 'gm2-grid-filter-tag-multiple',
    templateUrl: './grid-filter-tag-multiple.component.html',
    styleUrls: ['./grid-filter-tag-multiple.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {
            provide: RfxGridFilterControlComponent,
            useExisting: GridFilterTagMultipleComponent
        }
    ]
})
export class GridFilterTagMultipleComponent implements OnInit {
    @Input()
    public name: string = 'tagIds';
    @Input()
    public placeholder: string = 'Tags';

    @Input()
    public companyId$: Observable<string> = null;

    public initialOptions: RfxGridFilterSelectOption[] = null;

    public controlGroup: UntypedFormGroup;

    public searchControl: UntypedFormControl = new UntypedFormControl();

    public options: RfxGridFilterSelectOption[];

    private _onDestroy: Subject<void> = new Subject<void>();

    private filterOnSearch(): void {
        if (!this.options || !this.initialOptions) {
            return;
        }
        let search = this.searchControl.value;
        if (!search) {
            this.options = this.initialOptions.slice();
            return;
        } else {
            search = search.toLowerCase();
        }
        this.options = this.initialOptions.filter(
            tag => tag.label.toLowerCase().indexOf(search) > -1
        );
    }

    constructor(
        private _tagService: TagService
    ) {}

    ngOnInit(): void {
        this.controlGroup = new UntypedFormGroup({
            values: new UntypedFormControl('')
        });

        this.companyId$.pipe(
            take(1),
            tap(id => console.log(id)),
            switchMap( cId =>
                !!cId ? this._tagService.getTags(cId) : of([])
            )
        ).subscribe(tags => {
            this.initialOptions = tags.map(t => ({
                label: t.label, value: t._id
            })).sort((a, b) => a.label > b.label ? 1 : -1);
            this.options = this.initialOptions;
        });

        this.searchControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterOnSearch();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}
