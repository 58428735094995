import { INumberFilter } from '../../interface';
import { isNumber } from 'lodash';

export class NumberFilter implements INumberFilter {
    public value: number = 0;

    constructor(params?: Partial<NumberFilter>) {
        if (!!params) {
            this.value = isNumber(params.value) ? params.value : this.value;
        }
    }
}
