import { WorkOrderPhaseSimpleWithoutIds } from '@gm2/common';
import { WorkOrderInvoiceIdentity } from '../work-order-invoice/work-order-invoice-identity';

export class WorkOrderPhaseSimple extends WorkOrderPhaseSimpleWithoutIds {
    public workOrderInvoiceIdentity: WorkOrderInvoiceIdentity;

    constructor(params?: Partial<WorkOrderPhaseSimple>) {
        super(params);
        if (!!params) {
            this.workOrderInvoiceIdentity = !!params.workOrderInvoiceIdentity
                ? new WorkOrderInvoiceIdentity(params.workOrderInvoiceIdentity)
                : this.workOrderInvoiceIdentity;
        }
    }
}
