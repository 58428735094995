import { Component, EventEmitter, OnInit, ChangeDetectionStrategy, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { RfxGridFilterControlComponent, RfxGridFilterSelectOption } from '@refactor/ngx/grid';
import { CompanyService } from '@gm2/ui-common';
import { CompanyType } from '@gm2/common';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'gm2-grid-filter-client',
    templateUrl: './grid-filter-client.component.html',
    styleUrls: ['./grid-filter-client.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: RfxGridFilterControlComponent,
            useExisting: GridFilterClientComponent
        }
    ]
})
export class GridFilterClientComponent implements OnInit {
    @Input()
    public name: string = 'companyId';
    @Input()
    public placeholder: string = 'Client';
    @Input()
    public appearance: string = 'outline';
    @Input()
    public controlRef: UntypedFormControl;

    @Input()
    public clientRequired: string;
    public clientRequiredBool: boolean = false;

    public includeBlankOption?: boolean;

    @Input('includeBlankOption')
    public set setIncludeBlankOption(value: boolean | '') {
        this.includeBlankOption = value === '' || value;
    }

    @Input()
    public loadingSpinner: boolean = false;

    @Output()
    public clientChange: EventEmitter<MatSelectChange> =
        new EventEmitter<MatSelectChange>();

    public options$: BehaviorSubject<RfxGridFilterSelectOption[]> =
        new BehaviorSubject<RfxGridFilterSelectOption[]>([]);

    public initialOptions: RfxGridFilterSelectOption[] = null;

    public gridCompanies$: Observable<RfxGridFilterSelectOption[]>;

    public controlGroup: UntypedFormGroup;

    public searchControl: UntypedFormControl = new UntypedFormControl();

    private _onDestroy: Subject<void> = new Subject<void>();

    constructor(
        private _companyService: CompanyService
    ) {}

    ngOnInit(): void {
        if (!!this.clientRequired) {
            this.clientRequiredBool = true;
        }

        if (!this.controlRef) {
            this.controlGroup = new UntypedFormGroup({
                value: new UntypedFormControl('')
            });
        }

        this.gridCompanies$ = this._companyService
            .getSimpleList(CompanyType.Client).pipe(
                map(companies => companies.map(company => ({
                    value: company._id,
                    label: company.profile.name
                }))),
                // blank option
                map(options => {
                    if (this.includeBlankOption) {
                        return [{ value: null, label: 'AllClients' }, ...options];
                    }

                    return options;
                })
            );

        this.gridCompanies$.subscribe(companies => {
            if (!!companies) {
                this.options$.next(companies);
                if (this.initialOptions === null || this.options$.value.length > this.initialOptions.length) {
                    this.initialOptions = companies;
                }
                this.loadingSpinner = false;
            }
        });

        this.searchControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterOnSearch();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    public onClientChanged(event: MatSelectChange): void {
        this.clientChange.emit(event);
    }

    private filterOnSearch(): void {
        if (!this.options$.value || !this.initialOptions) {
            return;
        }
        let search = this.searchControl.value;
        if (!search) {
            this.options$.next(this.initialOptions.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.options$.next(
            this.initialOptions.filter(
                company => company.label.toLowerCase().indexOf(search) > -1
            )
        );
    }
}

