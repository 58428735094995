
export function regexLike(value: string): RegExp {
    return new RegExp(value, 'i');
}
export function regexStartsWith(value: string): RegExp {
    return new RegExp('^' + value, 'i');
}
export function regexEndsWith(value: string): RegExp {
    return new RegExp(value + '$', 'i');
}
export function regexMatch(value: string): RegExp {
    return new RegExp('^' + value + '$', 'i');
}