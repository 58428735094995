<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="header">
        <h1>
            {{ !!(currentHelp$ | async) ? 'Edit Help' : 'Create Help'}}
        </h1>
        <div class="buttons">
            <button
                mat-button
                type="button"
                (click)="goBack()"
            >
                <mat-icon>arrow_back</mat-icon>
                Back to List
            </button>
            <button
                mat-raised-button
                type="submit"
                [disabled]="submitting$ | async"
            >
                <mat-spinner
                    [diameter]="17"
                    *ngIf="submitting$ | async"
                ></mat-spinner>
                Submit
            </button>
        </div>
    </div>
    <mat-card>
        <mat-form-field appearance="standard">
            <mat-label>Name</mat-label>
            <input
                matInput
                type="text"
                formControlName="name"
                placeholder="Name"
            />
            <mat-error [rfxError]="form.controls.name"></mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>Description</mat-label>
            <input
                matInput
                type="text"
                formControlName="description"
                placeholder="Description"
            />
            <mat-error [rfxError]="form.controls.description"></mat-error>
        </mat-form-field>
        <mat-slide-toggle
            #pageToggle
            (change)="pageAvailabilityChange($event)"
        >
            Available On - {{ (slideLabel$ | async) }}
        </mat-slide-toggle>
        <mat-form-field appearance="standard">
            <mat-label>Selected Pages</mat-label>
            <mat-select formControlName="pages" multiple>
                <mat-option *ngFor="let r of (availableRoutes$ | async)" [value]="r">
                    {{ r }}
                </mat-option>
            </mat-select>
            <mat-error [rfxError]="form.controls.pages"></mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>Content</mat-label>
            <textarea
                matInput
                formControlName="content"
                placeholder=""
            ></textarea>
            <mat-error [rfxError]="form.controls.content"></mat-error>
        </mat-form-field>
        <ng-container formArrayName="videoIds">
            <ng-container *ngFor="let control of asArray(form.controls.videoIds).controls; let i = index">
                <mat-form-field>
                    <mat-label>{{ 'Video #' + (i+1) + ' YouTube ID' }}</mat-label>
                    <input
                        matInput
                        type="text"
                        [formControl]="control"
                        [placeholder]="'Video #' + (i+1) + ' YouTube ID'"
                    />
                    <mat-icon
                        matSuffix
                        (click)="removeVideoId(i)"
                    >
                        cancel
                    </mat-icon>
                    <mat-error [rfxError]="control"></mat-error>
                </mat-form-field>
            </ng-container>
        </ng-container>
        <mat-card-actions>
            <button
                mat-raised-button
                type="button"
                (click)="addVideoId()"
            >
                Add Video
            </button>
        </mat-card-actions>
    </mat-card>
</form>
