<h2 mat-dialog-title>Response Date Range</h2>
<form [formGroup]="form">
    <div mat-dialog-content>
        <mat-form-field>
            <input
                matInput
                placeholder="Date Range"
                formControlName="dateRange"
                [owlDateTimeTrigger]="dr"
                [owlDateTime]="dr"
                [selectMode]="'range'"
            />
            <mat-icon matSuffix [owlDateTimeTrigger]="dr">date_range</mat-icon>
            <owl-date-time [pickerType]="'calendar'" #dr></owl-date-time>
        </mat-form-field>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-raised-button (click)="close()">Cancel</button>
        <button mat-raised-button (click)="confirm()">Export</button>
    </div>
</form>
