export enum MappingType {
    Area = '[MappingType] Area',
    Line = '[MappingType] Line',
    Point = '[MappingType] Point'
}

export namespace MappingType {
    export function toString(type: MappingType): string {
        switch (type) {
            case MappingType.Area:
                return 'Area';
            case MappingType.Line:
                return 'Line';
            case MappingType.Point:
                return 'Point';
            default:
                return '';
        }
    }

    export const members: MappingType[] = [
        MappingType.Area,
        MappingType.Line,
        MappingType.Point
    ];

}
