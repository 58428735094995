import { BaseDoc } from '../base/base-doc';
import { CompanyIdentityWithoutIds } from '../company/company-identity';
import { RfpIdentityWithoutIds } from '../rfp/rfp-identity';
import { UserIdentityWithoutIds } from '../user/user-identity';
import { TimesheetSnapShotWithoutIds } from './timesheet-snap-shot';
import { TimesheetDates } from './timesheet-dates';
import { TimesheetCharges } from './timesheet-charges';
import { TimesheetServicesWithoutIds } from './timesheet-services';
import { TimesheetValidationWithoutIds } from './timesheet-validation';
import { TimesheetNativeApp } from './timesheet-native-app';
import {
    ServiceTimesheetField,
    TimesheetStatus,
    ServiceClassification,
    TimesheetType
} from '../../enums';
import { InvoiceIdentityWithoutIds } from '../invoice/invoice-identity';
import { TimesheetFormIdentityWithoutIds } from './timesheet-form-identity';

export class TimesheetWithoutIds extends BaseDoc {
    public _id: any;
    public rfpIdentity: RfpIdentityWithoutIds = new RfpIdentityWithoutIds();
    public companyIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public userIdentity: UserIdentityWithoutIds = new UserIdentityWithoutIds();
    public snapShot: TimesheetSnapShotWithoutIds = new TimesheetSnapShotWithoutIds();
    public dates: TimesheetDates = new TimesheetDates();
    public nativeApp: TimesheetNativeApp = new TimesheetNativeApp();
    public isComplete: boolean = false;
    public timesheetNumber: string;
    public clientCompanyInvoiceNumber: string;
    public charges: TimesheetCharges = new TimesheetCharges();
    public services: TimesheetServicesWithoutIds[] = [];
    public serviceTypeFields: { [key in ServiceTimesheetField]?: number | string } = {};
    public validation: TimesheetValidationWithoutIds = new TimesheetValidationWithoutIds();
    public crewSize: number;
    public expectedMaterials: { [key in ServiceClassification]?: number } = {};
    // for status tracking of images upload asynchronously from the mobile app
    public asyncImageCount: number;
    public syncedImageCount: number;

    // if the contract status is anything but WON, you can't edit a timesheet!
    public isLocked: boolean = false;
    public timesheetStatus: TimesheetStatus;
    public invoiceIdentity: InvoiceIdentityWithoutIds;
    public timesheetType: TimesheetType;
    public formIdentity: TimesheetFormIdentityWithoutIds;

    constructor(params?: Partial<TimesheetWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;

            this.isComplete = params.isComplete || this.isComplete;
            this.timesheetNumber = params.timesheetNumber || this.timesheetNumber;
            this.clientCompanyInvoiceNumber =
                params.clientCompanyInvoiceNumber || this.clientCompanyInvoiceNumber;
            this.timesheetType = params.timesheetType || this.timesheetType;

            this.rfpIdentity = !!params.rfpIdentity
                ? new RfpIdentityWithoutIds(params.rfpIdentity)
                : this.rfpIdentity;
            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentityWithoutIds(params.companyIdentity)
                : this.companyIdentity;
            this.userIdentity = !!params.userIdentity
                ? new UserIdentityWithoutIds(params.userIdentity)
                : this.userIdentity;
            this.snapShot = !!params.snapShot
                ? new TimesheetSnapShotWithoutIds(params.snapShot)
                : this.snapShot;

            this.dates = !!params.dates ? new TimesheetDates(params.dates) : this.dates;

            this.nativeApp = !!params.nativeApp
                ? new TimesheetNativeApp(params.nativeApp)
                : this.nativeApp;
            this.charges = !!params.charges ? new TimesheetCharges(params.charges) : this.charges;
            this.services = !!params.services
                ? params.services.map(s => new TimesheetServicesWithoutIds(s))
                : this.services;

            this.serviceTypeFields = !!params.serviceTypeFields
                ? params.serviceTypeFields
                : this.serviceTypeFields;

            this.validation = !!params.validation
                ? new TimesheetValidationWithoutIds(params.validation)
                : this.validation;

            this.crewSize = params.crewSize || this.crewSize;
            this.isLocked = params.isLocked || this.isLocked;
            this.timesheetStatus = params.timesheetStatus || this.timesheetStatus;

            this.invoiceIdentity = !!params.invoiceIdentity
                ? new InvoiceIdentityWithoutIds(params.invoiceIdentity)
                : this.invoiceIdentity;

            this.expectedMaterials = !!params.expectedMaterials
                ? params.expectedMaterials
                : this.expectedMaterials;

            this.formIdentity = !!params.formIdentity
                ? new TimesheetFormIdentityWithoutIds(params.formIdentity)
                : this.formIdentity;

            this.asyncImageCount = params.asyncImageCount || this.asyncImageCount;
            this.syncedImageCount = params.syncedImageCount || this.syncedImageCount;
        }
    }
}
