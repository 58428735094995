export enum TimesheetValidationStatus {
    Submitted = '[TimesheetValidationStatus] Submitted',
    Declined = '[TimesheetValidationStatus] Declined',
    Approved = '[TimesheetValidationStatus] Approved'
}

export namespace TimesheetValidationStatus {
    export function toString(status: TimesheetValidationStatus): string {
        switch (status) {
            case TimesheetValidationStatus.Submitted:
                return 'Submitted';
            case TimesheetValidationStatus.Declined:
                return 'Declined';
            case TimesheetValidationStatus.Approved:
                return 'Approved';
            default:
                return '';
        }
    }

    export const members: TimesheetValidationStatus[] = [
        TimesheetValidationStatus.Submitted,
        TimesheetValidationStatus.Declined,
        TimesheetValidationStatus.Approved
    ];
}
