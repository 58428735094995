import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { RfxAppDefinedField } from '@refactor/ngx/form-builder';
import { UntypedFormControl } from '@angular/forms';
import { startWith, debounceTime, switchMap, map, tap, catchError, distinctUntilChanged, shareReplay, withLatestFrom, takeUntil } from 'rxjs/operators';
import { CompanyType, CompanyService, CompanySimple, LocationSimple, LocationService } from '@gm2/ui-common';
import { of, Observable, combineLatest, Subject } from 'rxjs';

const DEBOUNCE_MS = 150;

@Component({
    selector: 'gm2-rfx-form-crl-fields',
    templateUrl: './rfx-form-crl-fields.component.html',
    styleUrls: ['./rfx-form-crl-fields.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RfxFormCrlFieldsComponent extends RfxAppDefinedField implements OnInit, OnDestroy {
    public readonly client: UntypedFormControl = new UntypedFormControl();
    public readonly clientSearch: UntypedFormControl = new UntypedFormControl();
    public readonly locationSearch: UntypedFormControl = new UntypedFormControl();

    private readonly _onDestroy$: Subject<void> = new Subject();

    public readonly allLocations$: Observable<LocationSimple[]> =
        this._locationService.getAwardedSimpleList().pipe(shareReplay(1));

    public readonly clientsFiltered$: Observable<CompanySimple[]> =
        this.clientSearch.valueChanges.pipe(
            startWith(''),
            debounceTime(DEBOUNCE_MS),
            tap(name => {
                if (typeof name !== 'string') {
                    throw new Error();
                }
            }),
            map(name => name.toLowerCase()),
            switchMap(name =>
                this._companyService.getSimpleList(CompanyType.Client).pipe(
                    map(clients =>
                        clients.filter(client =>
                            client.profile.name
                                .toLowerCase()
                                .includes(name)))
                )
            ),
            catchError(_error => {
                return of([]);
            })
        );

    public readonly locationsFiltered$: Observable<LocationSimple[]> =
        combineLatest([
            this.locationSearch.valueChanges.pipe(
                startWith(''),
                debounceTime(DEBOUNCE_MS),
                tap(name => {
                    if (typeof name !== 'string') {
                        throw new Error();
                    }
                }),
                map(name => name.toLowerCase()),
            ),
            this.client.valueChanges.pipe(
                startWith(null),
                distinctUntilChanged()
            )
        ]).pipe(
            switchMap(([name, clientId]) =>
                this.allLocations$.pipe(
                    map(locations =>
                        locations.filter(location => {
                            if (clientId && clientId !== location.companyIdentity._id) {
                                return false;
                            }

                            return location.identity.name
                                .toLowerCase()
                                .includes(name);
                        }))
                )
            ),
            catchError(_error => {
                return of([]);
            })
        );

    constructor(
        private readonly _companyService: CompanyService,
        private readonly _locationService: LocationService
    ) {
        super();
    }

    public ngOnInit(): void {
        // Need to set client when location is selected
        this.formControl.valueChanges.pipe(
            takeUntil(this._onDestroy$),
            withLatestFrom(this.allLocations$),
            map(([locationId, locations]) =>
                locations.find(location => location._id === locationId))
        ).subscribe(location => {
            this.client.setValue(
                location.companyIdentity._id,
                { emitEvent: false }
            );
        })
    }

    public ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    public compareWith(left: string, right: string): boolean {
        return left === right;
    }
}
