<ng-container *ngIf="(formGroupInitialized$ | async); else noForm">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-card>
            <div
                *ngFor="let field of (rfxForm$ | async)?.fields"
                class="form-row"
                [class.hidden]="!(showField$ | async)[field._id]"
            >
                <ng-container [ngSwitch]="field.type">
                    <rfx-form-field-generator
                        *ngSwitchDefault
                        [form]="form"
                        [field]="field"
                        [preview]="preview"
                    ></rfx-form-field-generator>
                    <ng-container
                        *ngSwitchCase="RfxFormFieldType.ApplicationDefined"
                    >
                        <rfx-app-defined-field-target
                            [key]="field.appDefinedField"
                            [fieldId]="field._id"
                        ></rfx-app-defined-field-target>
                    </ng-container>
                </ng-container>
            </div>
            <mat-card-actions>
                <button
                    mat-raised-button
                    type="submit"
                    [disabled]="!this.form.valid || (submitting$ | async)"
                >
                    <mat-spinner *ngIf="(submitting$ | async)"></mat-spinner>
                    Submit
                </button>
            </mat-card-actions>
        </mat-card>
    </form>
</ng-container>
<ng-template #noForm>
    <div class="loading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
