import { ServiceChild } from './service-child';

export class ServiceChildrenDto {
    public _id: string;
    public name: string;
    public description: string;
    public hasMaterial: boolean;
    public hasClientFee: boolean;

    constructor(params?: Partial<ServiceChildrenDto>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.description = params.description || this.description;
            this.hasMaterial = params.hasMaterial !== true ? false : params.hasMaterial;
            this.hasClientFee = params.hasClientFee !== true ? false : params.hasClientFee;
        }
    }

    public toServiceChild(): ServiceChild {
        return new ServiceChild({
            _id: this._id,
            name: this.name,
            description: this.description,
            settings: {
                hasMaterial: this.hasMaterial,
                hasClientFee: this.hasClientFee
            }
        });
    }
}
