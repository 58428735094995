<h2 mat-dialog-title>{{ data.message }}</h2>
<div mat-dialog-content>
	<form [formGroup]="form">
		<mat-form-field>
    		<textarea
    		  	matInput 
    		  	[placeholder]="data.placeholder"
    		  	formControlName="str"
    	  	></textarea>
		</mat-form-field>
		<div class="buttons">
    	    <button
                *ngIf="!(forceResponse$ | async)"
    	    	mat-flat-button 
    	    	(click)="close()"
        	>
        		Cancel
        	</button>
    	    <button 
    	    	mat-flat-button 
    	    	color="primary" 
    	    	(click)="confirm()"
    	    	[disabled]="!form.valid"
        	>
        		Confirm
        	</button>
		</div>
	</form>
</div>

