export enum WorkOrderBillingType {
    Billable = '[WorkOrderBillingType] Billable',
    Contractual = '[WorkOrderBillingType] Contractual',
    CapitalProject = '[WorkOrderBillingType] CapitalProject',
    AddedService = '[WorkOrderBillingType] AddedService',
    BillableSnow = '[WorkOrderBillingType] BillableSnow',
    NonBillableSnow = '[WorkOrderBillingType] NonBillableSnow',
    Warranty = '[WorkOrderBillingType] Warranty',
    SelfPerform = '[WorkOrderBillingType] SelfPerform'
}

export namespace WorkOrderBillingType {
    export function toString(type: WorkOrderBillingType): string {
        switch (type) {
            case WorkOrderBillingType.Billable:
                return 'Billable';
            case WorkOrderBillingType.Contractual:
                return 'Contractual';
            case WorkOrderBillingType.CapitalProject:
                return 'Capital Project';
            case WorkOrderBillingType.AddedService:
                return 'Contract Added Service';
            case WorkOrderBillingType.SelfPerform:
                return 'Self Perform';
            case WorkOrderBillingType.BillableSnow:
                return 'Snow Service Call';
            case WorkOrderBillingType.NonBillableSnow:
                return 'Snow Service Complaint';
            case WorkOrderBillingType.Warranty:
                return 'Warranty';
            default:
                return '';
        }
    }

    export const members: WorkOrderBillingType[] = [
        WorkOrderBillingType.Billable,
        WorkOrderBillingType.Contractual,
        WorkOrderBillingType.CapitalProject,
        WorkOrderBillingType.AddedService,
        WorkOrderBillingType.SelfPerform,
        WorkOrderBillingType.BillableSnow,
        WorkOrderBillingType.NonBillableSnow,
        WorkOrderBillingType.Warranty
    ];
}
