import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
    Input
} from '@angular/core';
import { Subject, ReplaySubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
    WorkOrderPhaseStatus,
    WorkOrderWorkCategory,
    WorkOrderBillingType,
    WorkOrderSimple,
    Note,
    NoteService,
    CompanyType,
    calcWorkOrderDaysInCurrentStatus
} from '@gm2/ui-common';

@Component({
    selector: 'gm2-workorder-flyout',
    templateUrl: './workorder-flyout.component.html',
    styleUrls: ['./workorder-flyout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkorderFlyoutComponent implements OnInit, OnDestroy {
    public WorkOrderPhaseStatus: typeof WorkOrderPhaseStatus = WorkOrderPhaseStatus;
    public WorkOrderWorkCategory: typeof WorkOrderWorkCategory = WorkOrderWorkCategory;
    public WorkOrderBillingType: typeof WorkOrderBillingType = WorkOrderBillingType;
    public CompanyType: typeof CompanyType = CompanyType;
    public daysInCurrentStatus: (w: WorkOrderSimple) => number = calcWorkOrderDaysInCurrentStatus;

    public workorder$: Subject<WorkOrderSimple> =
        new ReplaySubject();
    public notes$: Observable<Note[]> = this.workorder$.pipe(
        switchMap(item =>
            this._noteService.getNotesForWorkOrder(item._id)
        )
    );

    @Input()
    public companyType: CompanyType;
    @Input()
    public set workorder(value: WorkOrderSimple) {
        if (typeof value === 'undefined' || value === null) {
            throw new Error('You must provide a workorder');
        }
        this.workorder$.next(value);
    }
    @Input()
    public companyName?: string;

    constructor(
        private readonly _noteService: NoteService,
    ) {}

    public ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        this.workorder$.complete();
    }

    public getCompanyName(): string {
        return !!this.companyName ? `${this.companyName.trim()} Total` : 'Total';
    }
}
