import { ContractStatus, ContractType } from '../../enums';
import { PackageSnapShotWithoutIds } from '../package/package-snap-shot';
import { BaseDoc } from '../base/base-doc';

export class ContractSimpleWithoutIds extends BaseDoc {
    public _id: any = null;
    public name: string = '';
    public serviceTypeId: any = null;
    public contractStatus: ContractStatus = null;
    public contractType: ContractType = null;
    public packages: PackageSnapShotWithoutIds[] = [];

    constructor(params?: Partial<ContractSimpleWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId;
            this.contractStatus = params.contractStatus || this.contractStatus;
            this.contractType = params.contractType || this.contractType;
            if (params.packages && Array.isArray(params.packages)) {
                this.packages = params.packages.map(service => new PackageSnapShotWithoutIds(service));
            }
        }
    }
}
