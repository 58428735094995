import { Injectable } from '@angular/core';
import { Body, Get, Post, HttpService, Delete, Path, MapClass, Put, Query } from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { ContractSimple, ContractApplied, Rfp, BaseMediaDocumentWithUrl } from '../models';

@Injectable({
    providedIn: 'root'
})
export class ContractAppliedService extends HttpService {
    @Get('/contractApplied/byLocation/:locationId')
    public getContractByLocation(@Path('locationId') id: string): Observable<ContractSimple[]> {
        return null;
    }

    @Get('/contractApplied/:contractId')
    public getContractApplied(@Path('contractId') id: string): Observable<any> {
        return null;
    }

    @Get('/contractApplied/:contractId/restrictionCheck')
    public getContractAppliedRestrictionCheck(@Path('contractId') id: string): Observable<any> {
        return null;
    }

    @Put('/contractApplied/locationAdd/:contractId')
    public addLocationToAppliedContract(
        @Path('contractId') contractId: string,
        @Body() data: { locationId: string }
    ): Observable<any> {
        return null;
    }

    @Put('/contractApplied/locationRemove/:contractId')
    public removeLocationFromAppliedContract(
        @Path('contractId') contractId: string,
        @Body() data: { locationId: string }
    ): Observable<any> {
        return null;
    }

    @Put('/contractApplied/:contractId')
    @MapClass(ContractApplied)
    public editAppliedContract(@Path('contractId') id: string, @Body() body: any): Observable<any> {
        return null;
    }

    @Post('/contractApplied/apply')
    public applyContract(@Body() data: any): Observable<string> {
        return null;
    }

    @Post('/contractApplied/create/bulk')
    public createContracts(@Body() data: any): Observable<string> {
        return null;
    }

    @Delete('/contractApplied/:contractId')
    public deleteContract(@Path('contractId') id: string): Observable<void> {
        return null;
    }

    @Delete('/contractApplied/:contractId/image')
    public deleteContractDocument(
        @Path('contractId') id: string,
        @Query('path') path: string
    ): Observable<void> {
        return null;
    }

    @Get('/contractApplied/:contractId/documents')
    public getAppliedContractDocuments(
        @Path('contractId') id: string
    ): Observable<BaseMediaDocumentWithUrl[]> {
        return null;
    }

    @Post('/contractApplied/:contractId/duplicate')
    public duplicateContract(@Path('contractId') id: string): Observable<string> {
        return null;
    }

    @Post('/contractApplied/list/byIds')
    public getContractAppliedListByIds(@Body() ids: string[]): Observable<any> {
        return null;
    }

    @Post('/contractApplied/check/byName')
    public checkByName(@Body() data: { name: string, companyId: string }): Observable<boolean> {
        return null;
    }
}
