export enum ContractStatus {
    Proposed = '[ContractStatus] Proposed',
    Submitted = '[ContractStatus] Submitted',
    Won = '[ContractStatus] Won',
    Lost = '[ContractStatus] Lost',
    Expire = '[ContractStatus] Expire',
    Deleted = '[ContractStatus] Deleted'
}

export namespace ContractStatus {
    export function toString(type: ContractStatus): string {
        switch (type) {
            case ContractStatus.Proposed:
                return 'Proposed';
            case ContractStatus.Submitted:
                return 'Submitted';
            case ContractStatus.Won:
                return 'Won';
            case ContractStatus.Lost:
                return 'Lost';
            case ContractStatus.Expire:
                return 'Expire';
            case ContractStatus.Deleted:
                return 'Deleted';
            default:
                return '';
        }
    }

    export function toContractStatus(status: string): ContractStatus {
        switch (status) {
            case 'Proposed':
                return ContractStatus.Proposed;
            case 'Submitted':
                return ContractStatus.Submitted;
            case 'Won':
                return ContractStatus.Won;
            case 'Lost':
                return ContractStatus.Lost;
            case 'Expire':
                return ContractStatus.Expire;
            case 'Deleted':
                return ContractStatus.Deleted;
            default:
                return ContractStatus.Won;
        }
    }

    export const members: ContractStatus[] = [
        ContractStatus.Proposed,
        ContractStatus.Submitted,
        ContractStatus.Won,
        ContractStatus.Lost,
        ContractStatus.Expire,
        ContractStatus.Deleted
    ];
}
