import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RfxGridFilterControlComponent, RfxGridFilterSelectOption } from '@refactor/ngx/grid';
import { map } from 'rxjs/operators';

@Component({
	selector: 'gm2-grid-filter-zip-radius',
	templateUrl: './grid-filter-zip-radius.component.html',
	styleUrls: ['./grid-filter-zip-radius.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: RfxGridFilterControlComponent,
			useExisting: GridFilterZipRadiusComponent
		}
	]
})
export class GridFilterZipRadiusComponent implements OnInit {
	@Input()
	public name: string = 'zipRadius';
	@Input()
	public placeholder: string = 'Select Zip Radius';
	@Input()
	public options: RfxGridFilterSelectOption[] =
    // Hardcoded zip radii values in miles
    [{ value: null, label: ''},
    // tslint:disable-next-line:no-magic-numbers
	    ...[5, 10, 15, 25, 50, 75, 100].map(radius => ({
	        value: radius,
	        label: `${radius} miles`
	    }))
    ];

	public controlGroup: UntypedFormGroup;

	constructor() {}

	ngOnInit(): void {
		this.controlGroup = new UntypedFormGroup({
			value: new UntypedFormControl('')
		});
	}
}
