export enum SiteConditionType {
    BlackIce = '[SiteConditionType] Black Ice',
    DriftSnow = '[SiteConditionType] Drift Snow',
    Icy = '[SiteConditionType] Icy',
    PackedSnow = '[SiteConditionType] Packed Snow',
    SkiffSnow = '[SiteConditionType] SkiffSnow',
    Slush = '[SiteConditionType] Slush',
    SnowIce = '[SiteConditionType] SnowIce',
    Snowy = '[SiteConditionType] Snowy',
    WetSurfaces = '[SiteConditionType] WetSurfaces'
}

export namespace SiteConditionType {
    export function toString(type: SiteConditionType): string {
        switch (type) {
            case SiteConditionType.BlackIce:
                return 'Black Ice';
            case SiteConditionType.DriftSnow:
                return 'Drifted Snow';
            case SiteConditionType.Icy:
                return 'Icy';
            case SiteConditionType.PackedSnow:
                return 'Packed Snow';
            case SiteConditionType.SkiffSnow:
                return 'Skiff of Snow';
            case SiteConditionType.Slush:
                return 'Slush';
            case SiteConditionType.SnowIce:
                return 'Snow and Ice';
            case SiteConditionType.Snowy:
                return 'Snowy';
            case SiteConditionType.WetSurfaces:
                return 'Wet Surfaces';
            default:
                return '';
        }
    }

    export const members: SiteConditionType[] = [
        SiteConditionType.BlackIce,
        SiteConditionType.DriftSnow,
        SiteConditionType.Icy,
        SiteConditionType.PackedSnow,
        SiteConditionType.SkiffSnow,
        SiteConditionType.Slush,
        SiteConditionType.SnowIce,
        SiteConditionType.Snowy,
        SiteConditionType.WetSurfaces
    ];
}
