export class TimesheetCharges {
    public total: number = 0;
    public clientCompanyTotal: number = 0;
    public durationMinutes: number = 0;
    public errors: string[] = [];

    constructor(params?: Partial<TimesheetCharges>) {
        if (!!params) {
            this.total = params.total || this.total;
            this.clientCompanyTotal = params.clientCompanyTotal || this.clientCompanyTotal;
            this.durationMinutes = params.durationMinutes || this.durationMinutes;
            this.errors = Array.isArray(params.errors) ? params.errors : this.errors;
        }
    }
}
