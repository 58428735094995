import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RfxGridFilterControlComponent, RfxGridFilterSelectOption } from '@refactor/ngx/grid';
import { LocationService, LocationSimple } from '@gm2/ui-common';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'gm2-grid-filter-location',
    templateUrl: './grid-filter-location.component.html',
    styleUrls: ['./grid-filter-location.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: RfxGridFilterControlComponent,
            useExisting: GridFilterLocationComponent
        }
    ]
})
export class GridFilterLocationComponent implements OnInit {
    @Input()
    public name: string = 'locationId';
    @Input()
    public placeholder: string = 'Location';
    @Input()
    public appearance: string = 'outline';
    @Input()
    public controlRef: UntypedFormControl;
    @Input()
    public companyId$: Observable<string> = null;

    @Input()
    public locationRequired: string;
    public locationRequiredBool: boolean = false;

    public includeBlankOption?: boolean;

    @Input('includeBlankOption')
    public set setIncludeBlankOption(value: boolean | '') {
        this.includeBlankOption = value === '' || value;
    }

    @Input()
    public loadingSpinner: boolean = false;

    private _onDestroy: Subject<void> = new Subject<void>();

    public options$: BehaviorSubject<RfxGridFilterSelectOption[]> =
        new BehaviorSubject<RfxGridFilterSelectOption[]>([]);

    public initialOptions: RfxGridFilterSelectOption[] = null;

    public gridLocations$: Observable<RfxGridFilterSelectOption[]>;

    public controlGroup: UntypedFormGroup;

    public searchControl: UntypedFormControl = new UntypedFormControl();

    private setupLocationPipe(o: Observable<LocationSimple[]>): Observable<RfxGridFilterSelectOption[]> {
        return o.pipe(
            map(locations => locations.map(location => ({
                value: location._id,
                label: location.identity.name
            }))),
            // blank option
            map(options => {
                if (this.includeBlankOption) {
                    return [{ value: null, label: 'All Locations' }, ...options];
                }

                return options;
            })
        );
    }

    private setLocationSubscription(): void {
        this.gridLocations$.pipe(takeUntil(this._onDestroy))
            .subscribe(locations => {
                if (!!locations) {
                    this.options$.next(locations);
                    if (this.initialOptions === null || this.options$.value.length > this.initialOptions.length) {
                        this.initialOptions = locations;
                    }
                    this.loadingSpinner = false;
                }
            });
    }

    constructor(
        private _locationService: LocationService
    ) {}

    ngOnInit(): void {

        if (!!this.locationRequired) {
            this.locationRequiredBool = true;
        }

        if (!this.controlRef) {
            this.controlGroup = new UntypedFormGroup({
                value: new UntypedFormControl('')
            });
        }

        if (!!this.companyId$) {
            console.log('-- grid filter location, received observable');
            this.companyId$.subscribe(companyId => {
                console.log('-- grid filter, companyId emission: ', companyId);
                if (!!companyId) {
                    this.gridLocations$ = this.setupLocationPipe(
                        this._locationService.getSimpleList(companyId)
                    );
                    this.setLocationSubscription();
                }
            });
        } else {
            console.log('-- grid filter location, no Observable passed');
            this.gridLocations$ = this.setupLocationPipe(
                this._locationService.getSimpleList()
            );
            this.setLocationSubscription();
        }

        this.searchControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterOnSearch();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    private filterOnSearch(): void {
        if (!this.options$.value || !this.initialOptions) {
            return;
        }
        let search = this.searchControl.value;
        if (!search) {
            this.options$.next(this.initialOptions.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.options$.next(
            this.initialOptions.filter(
                location => location.label.toLowerCase().indexOf(search) > -1
            )
        );
    }
}

