import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { CompanyState } from '@gm2/ui-state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { take, switchMap, tap, withLatestFrom, startWith, map } from 'rxjs/operators';
import { CompanyType } from '@gm2/ui-common';

@Injectable()
export class DashboardRedirectGuard implements CanActivate {
    @Select(CompanyState.companyType)
    public type: Observable<string>;

    constructor(private _router: Router) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.type.pipe(
            take(1),
            map(type => {
                switch (type) {
                    case CompanyType.Admin:
                        this._router.navigateByUrl('/dashboard/admin');
                        return true;
                    case CompanyType.Client:
                        this._router.navigateByUrl('/dashboard/client');
                        return true;
                    case CompanyType.Service_Partner:
                        this._router.navigateByUrl('/dashboard/service-partner');
                        return true;
                    default:
                        const url = state.url;
                        this._router.navigate(['/auth/login'], {
                            queryParams: {
                                redirect: url
                            }
                        });
                        return false;
                }
            })
        );
    }
}
