import { TimesheetRfpSnapShotWithoutIds } from './timesheet-rfp-snap-shot';
import { ContractIdentityWithoutIds } from '../contract/contract-identity';

export class TimesheetSnapShotWithoutIds {
    public rfp: TimesheetRfpSnapShotWithoutIds = new TimesheetRfpSnapShotWithoutIds();
    public contractIdentity: ContractIdentityWithoutIds;

    constructor(params?: Partial<TimesheetSnapShotWithoutIds>) {
        if (!!params) {
            this.rfp = !!params.rfp ? new TimesheetRfpSnapShotWithoutIds(params.rfp) : this.rfp;
            this.contractIdentity = !!params.contractIdentity
                ? new ContractIdentityWithoutIds(params.contractIdentity)
                : this.contractIdentity;
        }
    }
}
