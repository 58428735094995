import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { map, takeUntil, take, debounceTime, skip, filter, bufferCount, tap, startWith } from 'rxjs/operators';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SelectOption } from '@gm2/ui-common';

@Component({
    selector: 'gm2-search-select',
    templateUrl: './search-select.component.html',
    styleUrls: ['./search-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchSelectComponent implements OnInit {
    @Input()
    public fControl: UntypedFormControl;
    @Input()
    public placeholder: string;
    @Input()
    public baseOptions$: Observable<SelectOption[]>;
    @Input()
    public onSubmit$: Observable<void>;
    @Input()
    public multi?: boolean = false;
    @Input()
    public appearance: string;

    public options$: BehaviorSubject<SelectOption[]> =
        new BehaviorSubject([]);
    public searchControl: UntypedFormControl =
        new UntypedFormControl(null);
    private _onDestroy$: Subject<void> =
        new Subject();

    constructor(
        private readonly _changeDetectorRef: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        // clear form control value if options change
        // skip value reset on first iteration
        // to allow patching existing data
        this.baseOptions$
            .pipe(
                takeUntil(this._onDestroy$),
                startWith(null),
                // tslint:disable-next-line: no-magic-numbers
                bufferCount(2, 1),
                filter(([ops0, ops1]) => Array.isArray(ops0) &&
                    Array.isArray(ops1) &&
                    this._differentOptions(ops0, ops1)
                )
            )
            .subscribe(ops => {
                this.fControl.setValue(null);
                this.searchControl.setValue(null);
            });
        // reset the search control if base
        // options change underneath
        // keep options up to date
        this.baseOptions$
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(ops => {
                this.searchControl.setValue(null);
                this.options$.next(ops);
            });
        // filter results on search
        combineLatest([
            this.baseOptions$,
            this.searchControl.valueChanges
        ])
            .pipe(
                // tslint:disable-next-line: no-magic-numbers
                debounceTime(100),
                takeUntil(this._onDestroy$)
            )
            .subscribe(([iOptions, val]) =>
                this._filterOnSearch(iOptions, val)
            );
        // update value and validity on submissions
        this.onSubmit$
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(() => {
                this.fControl.updateValueAndValidity({ emitEvent: false });
                // change detection for error rendering has trouble sometimes
                this._changeDetectorRef.detectChanges();
            });
    }

    public ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    private _differentOptions(
        a: SelectOption[],
        b: SelectOption[]
    ): boolean {
        if (!a && !b) {
            return false;
        } else if (!a && !!b || !!a && !b) {
            return true;
        } else {
            if (a.length !== b.length) {
                return true;
            } else {
                let _i = 0;
                for (; _i < a.length; ++_i) {
                    if (a[_i]._id !== b[_i]._id ||
                        a[_i].label !== b[_i].label) {
                        return true;
                    }
                }
                return false;
            }
        }
    }

    private _filterOnSearch(
        iOptions: SelectOption[],
        val: string
    ): void {
        if (!iOptions || iOptions.length === 0) {
            return;
        }
        if (!val) {
            this.options$.next(iOptions);
        } else {
            this.options$.next(
                iOptions.filter(o =>
                    o.label.toLowerCase()
                        .indexOf(val.toLowerCase()) > -1
                )
            );
        }
    }
}
