import { BaseMediaDocument } from './BaseMediaDocument';

export class BaseMediaDocumentWithUrl extends BaseMediaDocument {
    public url: string = '';
    constructor(params?: Partial<BaseMediaDocumentWithUrl>) {
        super(params);
        if (!!params) {
            this.url = params.url || this.url;
        }
    }
}
