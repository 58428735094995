import { Component, OnInit, Input } from '@angular/core';
import { SiteTitleService } from '@gm2/ui-common';
declare let gtag: Function;

@Component({
    selector: 'gm2-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
    private _title: string = '';
    @Input()
    public set title(value: string) {
        this._title = value;
        this._titleService.setTitle(`${value} | Allgrounds`);
    }
    public get title(): string {
        return this._title;
    }

    private _detail: string = '';
    @Input()
    public set detail(value: string) {
        this._detail = value;
    }
    public get detail(): string {
        return this._detail;
    }

    constructor(private _titleService: SiteTitleService) {}

    ngOnInit(): void {}
}
