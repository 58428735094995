export class CompanyQuestionnaire {
    public yearsInIndustry: string = '';
    public workVehicles: number = 0;
    public capacity: string = '';
    public employeesNeedToHire: string = '';
    public offerDiscounts: string = '';
    public canUseApp: string = '';
    public licensesOrCerts: string = '';
    public haveEmployees: string = '';
    public workersCompInsuranceExpiration: string = '';
    public generalLiabilityInsuranceExpiration: string = '';
    public vehicleInsuranceExpiration: string = '';
    public umbrellaInsuranceExpiration: string = '';
    public payByDirectDeposit: string = '';

    constructor(params?: Partial<CompanyQuestionnaire>) {
        if (!!params) {
            this.yearsInIndustry = params.yearsInIndustry || this.yearsInIndustry;
            this.workVehicles = params.workVehicles || this.workVehicles;
            this.capacity = params.capacity || this.capacity;
            this.employeesNeedToHire = params.employeesNeedToHire || this.employeesNeedToHire;
            this.offerDiscounts = params.offerDiscounts || this.offerDiscounts;
            this.canUseApp = params.canUseApp || this.canUseApp;
            this.licensesOrCerts = params.licensesOrCerts || this.licensesOrCerts;
            this.haveEmployees = params.haveEmployees || this.haveEmployees;
            this.workersCompInsuranceExpiration =
                params.workersCompInsuranceExpiration || this.workersCompInsuranceExpiration;
            this.generalLiabilityInsuranceExpiration =
                params.generalLiabilityInsuranceExpiration ||
                this.generalLiabilityInsuranceExpiration;
            this.vehicleInsuranceExpiration =
                params.vehicleInsuranceExpiration || this.vehicleInsuranceExpiration;
            this.umbrellaInsuranceExpiration =
                params.umbrellaInsuranceExpiration || this.umbrellaInsuranceExpiration;
            this.payByDirectDeposit = params.payByDirectDeposit || this.payByDirectDeposit;
        }
    }
}
