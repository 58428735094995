import { Permission, Role, ShortUserForm, ToastService, User, UserCreateDto, UserNotificationSettings, UserProfile } from '@gm2/ui-common';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { Receiver } from '@ngxs-labs/emitter';
import { UserServiceNew } from '@ngx-gm2/shared/services/user.service';
import { Injectable, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

export namespace UserActions {
    export class ResetToInitialState {
        static readonly type = '[User] Reset To Initial State';
    }

    export class SetPagination {
        static readonly type = '[User] Set Pagination';

        constructor(public page: number, public perPage: number) {
        }
    }

    export class AttachPlByEmail {
        static readonly type = '[User] Add relation user to certain PL';

        constructor(public email: string, public companyId: string) {
        }
    }

    export class GetUserById {
        static readonly type = '[User] Find User By Id';

        constructor(public userId: string | null) {
        }
    }

    export class GetMyUser {
        static readonly type = '[User] Get My User';
    }

    export class SubmitUserForm {
        static readonly type = '[User] Edit User';

        constructor(public dto: UserCreateDto & Record<string, any>) {
        }
    }

    export class SetProps {
        static readonly type = '[User] Set State Props';

        constructor(public propsToRewrite: Partial<UserStateModel>) {
        }
    }
}

export interface UserStateModel {
    user: User; // me
    notificationCount: number;
    totalLandscapersCount: number;
    perPage: number;
    page: number;
    landscapers: any[];
    selectedUser: User; // user for pre-fill create user form (NOT me)
    availableRoles: Role[];
    shortUserFormValue: ShortUserForm;
    isLoading: boolean;
    isSubmitting: boolean;
}

@State<UserStateModel>({
    name: 'user',
    defaults: {
        user: undefined,
        notificationCount: 0,
        totalLandscapersCount: 0,
        landscapers: null,
        perPage: 10,
        page: 0,
        selectedUser: null,
        availableRoles: null,
        shortUserFormValue: null,
        isLoading: false,
        isSubmitting: false
    }
})

@Injectable()
export class UserState {

    constructor(
        private userService: UserServiceNew,
        private toastService: ToastService,
        private readonly router: Router,
        private readonly ngZone: NgZone,
    ) {
    }

    @Selector()
    @ImmutableSelector()
    public static user(state: UserStateModel): User {
        return state.user;
    }

    @Selector()
    @ImmutableSelector()
    public static selectedUser(state: UserStateModel): User {
        return state.selectedUser;
    }

    @Selector()
    @ImmutableSelector()
    public static selectedShortUserForm(state: UserStateModel): ShortUserForm {
        return state.shortUserFormValue;
    }

    @Selector()
    @ImmutableSelector()
    public static userPermissions(state: UserStateModel): Permission[] {
        return state.user.permissions;
    }

    @Selector()
    @ImmutableSelector()
    public static userRoles(state: UserStateModel): string[] {
        return state.user.roleIds;
    }

    @Selector()
    @ImmutableSelector()
    public static seenWelcomeModal(state: UserStateModel): boolean {
        return state.user.settings.seenWelcomeModal;
    }

    @Selector()
    @ImmutableSelector()
    public static userNotificationCount(state: UserStateModel): number {
        return state.notificationCount;
    }

    @Selector()
    @ImmutableSelector()
    public static isLoading(state: UserStateModel): boolean {
        return state.isLoading;
    }

    @Selector()
    @ImmutableSelector()
    public static isSubmitting(state: UserStateModel): boolean {
        return state.isSubmitting;
    }

    @Receiver({ type: '[User] set user' })
    @ImmutableContext()
    public static setUser(
        { setState }: StateContext<UserStateModel>,
        { payload }: { payload: User }
    ): void {
        setState((state: UserStateModel) => {
            state.user = payload;
            console.log('setting the user', state.user);
            console.log('setState: ', setState);
            console.log('payload: ', payload);
            return state;
        });
    }

    @Receiver({ type: '[User] set seenWelcomeModal user setting state', payload: false })
    @ImmutableContext()
    public static setSeenWelcomeModalState(
        { setState }: StateContext<UserStateModel>,
        { payload }: { payload: boolean }
    ): void {
        setState((state: UserStateModel) => {
            state.user.settings.seenWelcomeModal = payload;
            return state;
        });
    }

    @Receiver({ type: '[User] set profile state', payload: false })
    @ImmutableContext()
    public static setProfile(
        { setState }: StateContext<UserStateModel>,
        { payload }: { payload: UserProfile }
    ): void {
        setState((state: UserStateModel) => {
            state.user.profile = payload;
            return state;
        });
    }

    @Receiver({ type: '[User] set notificationSettings state', payload: false })
    @ImmutableContext()
    public static setNotificationSettings(
        { setState }: StateContext<UserStateModel>,
        { payload }: { payload: UserNotificationSettings }
    ): void {
        setState((state: UserStateModel) => {
            state.user.notificationSettings = payload;
            return state;
        });
    }

    @Receiver({ type: '[User] set notificationCount state', payload: false })
    @ImmutableContext()
    public static setNotificationCount(
        { setState }: StateContext<UserStateModel>,
        { payload }: { payload: number }
    ): void {
        setState((state: UserStateModel) => {
            state.notificationCount = payload;
            return state;
        });
    }

    @Selector()
    @ImmutableSelector()
    public static selectLandscapers(state: UserStateModel): any[] {
        return state.landscapers;
    }

    @Selector()
    @ImmutableSelector()
    public static selectTotalItemsCount(state: UserStateModel): number {
        return state.totalLandscapersCount;
    }

    @Selector()
    @ImmutableSelector()
    public static selectPage(state: UserStateModel): number {
        return state.page;
    }

    @Selector()
    @ImmutableSelector()
    public static selectPerPage(state: UserStateModel): number {
        return state.perPage;
    }

    @Action(UserActions.SetProps)
    public setStateProps(ctx: StateContext<UserStateModel>, { propsToRewrite }: UserActions.SetProps): void {
        ctx.setState({
            ...ctx.getState(),
            ...propsToRewrite
        })
    }

    @Action(UserActions.AttachPlByEmail)
    public attachPLByEmail(ctx: StateContext<UserStateModel>, { email, companyId }: UserActions.AttachPlByEmail): Observable<UserStateModel> {
        ctx.setState({ ...ctx.getState(), isLoading: true });

        return this.userService.attachPlByEmail(email, companyId)
            .pipe(
                map(() => {
                    ctx.setState({ ...ctx.getState(), selectedUser: null });
                    this.toastService.success('User was added to this PL tenant');
                    this.ngZone.run(() => this.router.navigateByUrl('/support/user/list'));
                    return of(null);

                }),
                catchError((response) => {
                    // if user was not found set selected user to null and unblock full form
                    if (response.error.statusCode === 404) {
                        ctx.setState({
                            ...ctx.getState(),
                            selectedUser: null,
                            shortUserFormValue:{email, companyId}
                        });
                        this.ngZone.run(() => this.router.navigateByUrl('/support/user/create-full'));
                    } else {
                        this.toastService.error(response.error.message);
                    }
                    return of(null);
                }),
                finalize(() => ctx.setState({ ...ctx.getState(), isLoading: false }))
            );
    }

    @Action(UserActions.GetUserById)
    public getUserById(ctx: StateContext<UserStateModel>, { userId }: UserActions.GetUserById): Observable<UserStateModel> {
        // reset selected user anyway
        ctx.setState({ ...ctx.getState(), selectedUser: null });

        if (!userId) {
            return of(null);
        }

        ctx.setState({ ...ctx.getState(), isLoading: true });
        return this.userService.getUserById(userId)
            .pipe(
                map((user) => ctx.setState({
                    ...ctx.getState(),
                    selectedUser: user,
                })),
                catchError(err => {
                    this.toastService.error('Error getting user');
                    return of(null);
                }),
                finalize(() => ctx.setState({ ...ctx.getState(), isLoading: false }))
            );
    }

    @Action(UserActions.SubmitUserForm)
    public submitUserForm(ctx: StateContext<UserStateModel>, { dto }: UserActions.SubmitUserForm): Observable<any> {
        ctx.setState({ ...ctx.getState(), isSubmitting: true });

        if (!dto) {
            return of(null);
        }

        return (dto.userId
            ? this.userService.editUser(dto)
            : this.userService.createUser(dto))
            .pipe(
                map((res) => this.ngZone.run(() => this.router.navigateByUrl('/support/user/list'))),
                catchError((res) => {
                    if (typeof res.error.message === 'string') {
                        this.toastService.error('Error creating user:' + res.message);
                    } else if (Array.isArray(res.error.message)) {
                        const [firstError] = res.error.message;
                        const [firstErrorMessage] = Object.values(firstError.constraints)
                        this.toastService.error('Error creating user:' + firstErrorMessage);
                    } else {
                        this.toastService.error('Error creating user:' + res.message);
                    }
                    return of(null);
                }),
                finalize(() => ctx.setState({ ...ctx.getState(), isSubmitting: false }))
            );
    }


    @Action(UserActions.GetMyUser)
    public getMyUser(ctx: StateContext<UserStateModel>): Observable<UserStateModel> {

        return this.userService.getMyUser()
            .pipe(
                map((user) => ctx.setState({ ...ctx.getState(), user })),
                catchError(err => {
                    this.toastService.error('Error getting user');
                    return of(null);
                }),
                finalize(() => ctx.setState({ ...ctx.getState(), isLoading: false }))
            );
    }
}
