
import { BaseMediaDocumentWithoutIds } from './base-media-document';

export class BaseMediaDocumentWithUrlWithoutIds extends BaseMediaDocumentWithoutIds {
    public url: string = '';

    constructor(params?: Partial<BaseMediaDocumentWithUrlWithoutIds>) {
        super(params);
        if (!!params) {
            this.url = params.url || this.url;
        }
    }
}
