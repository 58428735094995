import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Directive,
    ViewContainerRef,
    ElementRef,
    ViewChild,
    ContentChildren,
    QueryList,
    IterableDiffer,
    Input,
    TrackByFunction,
    IterableDiffers,
    IterableChangeRecord,
    ChangeDetectorRef,
    ComponentFactoryResolver,
    ComponentFactory
} from '@angular/core';
import { GridListItemDef } from '../grid-list-item/grid-list-item.component';
import { RfxGridDataSource } from '../GridDataSource';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RfxGridItemOutletContext } from '../Row';
import { GridRowComponent } from '../grid-row/grid-row.component';
import { SelectionModel } from '@angular/cdk/collections';

interface RenderRow<T> {
    data: T;
    dataIndex: number;
    rowDef: GridListItemDef<T>;
}

@Directive({
    selector: '[rfxGridRowOutlet]'
})
export class GridRowOutlet {
    constructor(
        public viewContainer: ViewContainerRef,
        public elementRef: ElementRef
    ) {}
}

@Component({
    selector: 'rfx-grid-list',
    templateUrl: './grid-list.component.html',
    styleUrls: ['./grid-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'rfxGridList'
})
export class GridListComponent<T> implements OnInit {
    private _unsub: Subject<void> = new Subject<void>();
    private _dataSubscription: Subscription;
    private _data: Array<T> = [];
    // prettier-ignore
    private _cachedRenderRowsMap: Map<T, WeakMap<GridListItemDef<T>, RenderRow<T>[]>>
        = new Map<T, WeakMap<GridListItemDef<T>, RenderRow<T>[]>>();
    private _renderRows: RenderRow<T>[] = [];
    private _dataDiffer: IterableDiffer<RenderRow<T>>;

    private _trackByFn: TrackByFunction<T>;
    @Input()
    public get trackBy(): TrackByFunction<T> {
        return this._trackByFn;
    }
    public set trackBy(fn: TrackByFunction<T>) {
        if (fn != null && typeof fn !== 'function') {
            console.warn(
                `trackBy must be a function, but received ${JSON.stringify(fn)}`
            );
        }
        this._trackByFn = fn;
    }

    @ViewChild(GridRowOutlet, { static: true })
    public _rowOutlet: GridRowOutlet;
    @ContentChildren(GridListItemDef)
    public _rowDefs: QueryList<GridListItemDef<T>>;

    private _dataSource: RfxGridDataSource<T>;
    public set dataSource(source: RfxGridDataSource<T>) {
        if (!!source) {
            this._dataSource = source;
            if (source.trackBy) {
                this.trackBy = source.trackBy;
            }
            if (source._selection) {
                this._selection = source._selection;
            }
            this.loading$ = source.loading$;
        }
    }

    public loading$: Observable<boolean> = of(false);

    private _selection: SelectionModel<T>;
    private _gridRowFactory: ComponentFactory<GridRowComponent<T>>;

    constructor(
        protected _differs: IterableDiffers,
        private _cd: ChangeDetectorRef,
        private _cfResolver: ComponentFactoryResolver
    ) {}

    ngOnInit(): void {
        this._gridRowFactory = this._cfResolver.resolveComponentFactory<
            GridRowComponent<T>
        >(GridRowComponent);
        // Configure trackBy function
        this._dataDiffer = this._differs
            .find([])
            .create((_i: number, dataRow: RenderRow<T>) =>
                this.trackBy
                    ? this.trackBy(dataRow.dataIndex, dataRow.data)
                    : dataRow
            );
    }

    ngAfterContentChecked(): void {
        // Make sure a grid definition was provided
        if (this._rowDefs.length !== 1) {
            throw new Error(
                'Specify a single grid list item definition for rendering.'
            );
        }

        if (!this._dataSubscription) {
            this._observeDataStream();
        }
    }

    ngOnDestroy(): void {
        this._rowOutlet.viewContainer.clear();

        this._unsub.next();
        this._unsub.complete();
    }

    public get selectAllChecked(): boolean {
        return (
            this.hasSelection &&
            this._dataSource._selection.selected.length === this._data.length
        );
    }

    public get selectAllIndeterminate(): boolean {
        return !this.selectAllChecked && this.hasSelection;
    }

    public get hasSelection(): boolean {
        return (
            !!this._dataSource._selection &&
            this._dataSource._selection.hasValue()
        );
    }

    public get isMultiSelection(): boolean {
        return (
            !!this._dataSource._selection &&
            this._dataSource._selection.isMultipleSelection()
        );
    }

    public selectAll(): void {
        if (this.selectAllChecked) {
            this._selection.clear();
        } else {
            this._data.forEach(d => {
                this._selection.select(d);
            });
        }
    }

    /**
     * Renders rows based on the latest set of data.
     */
    public renderRows(): void {
        this._renderRows = this._getRenderRows();
        const changes = this._dataDiffer.diff(this._renderRows);
        if (!changes) {
            return;
        }

        const viewContainer = this._rowOutlet.viewContainer;

        changes.forEachOperation(
            (
                record: IterableChangeRecord<RenderRow<T>>,
                prevIndex: number | null,
                currentIndex: number | null
            ) => {
                if (record.previousIndex == null) {
                    this._insertRow(record.item, currentIndex);
                } else if (currentIndex == null) {
                    viewContainer.remove(prevIndex);
                } else {
                    const view = viewContainer.get(prevIndex);
                    viewContainer.move(view, currentIndex);
                }
            }
        );
    }

    private _observeDataStream(): void {
        // If no data source set, do nothing
        if (!this._dataSource) {
            return;
        }

        const dataStream: Observable<T[]> = this._dataSource.data$;

        this._dataSubscription = dataStream
            .pipe(takeUntil(this._unsub))
            .subscribe(data => {
                this._data = data || [];
                this.renderRows();
            });
    }

    private _getRenderRows(): RenderRow<T>[] {
        const renderRows: RenderRow<T>[] = [];

        const prevCachedRenderRows = this._cachedRenderRowsMap;
        this._cachedRenderRowsMap = new Map();

        for (let i = 0; i < this._data.length; i++) {
            const data = this._data[i];
            const renderRowForData = this._getRenderRowForData(
                data,
                i,
                prevCachedRenderRows.get(data)
            );
            if (!this._cachedRenderRowsMap.has(data)) {
                this._cachedRenderRowsMap.set(data, new WeakMap());
            }
            const cache = this._cachedRenderRowsMap.get(renderRowForData.data);
            if (cache.has(renderRowForData.rowDef)) {
                cache.get(renderRowForData.rowDef).push(renderRowForData);
            } else {
                cache.set(renderRowForData.rowDef, [renderRowForData]);
            }
            renderRows.push(renderRowForData);
        }

        return renderRows;
    }

    private _getRenderRowForData(
        data: T,
        dataIndex: number,
        cache?: WeakMap<GridListItemDef<T>, RenderRow<T>[]>
    ): RenderRow<T> {
        const rowDef = this._rowDefs.first;
        const cachedRenderRows =
            cache && cache.has(rowDef) ? cache.get(rowDef) : [];
        if (cachedRenderRows.length) {
            const dataRow = cachedRenderRows.shift();
            dataRow.dataIndex = dataIndex;
            return dataRow;
        } else {
            return { data, rowDef, dataIndex };
        }
    }

    private _insertRow(renderRow: RenderRow<T>, renderIndex: number): void {
        const rowDef = renderRow.rowDef;
        const context: RfxGridItemOutletContext<T> = {
            $implicit: renderRow.data
        };
        this._renderRow(
            this._gridRowFactory,
            this._rowOutlet,
            rowDef,
            renderIndex,
            context
        );
    }

    private _renderRow(
        factory: ComponentFactory<GridRowComponent<T>>,
        outlet: GridRowOutlet,
        rowDef: GridListItemDef<T>,
        index: number,
        context: RfxGridItemOutletContext<T> = {}
    ): void {
        const res = outlet.viewContainer.createComponent(factory, index);
        res.instance.initValues(context.$implicit, this._dataSource);
        res.instance.createItemView(rowDef.template, context);
    }
}
