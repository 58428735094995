<ng-container *ngIf="workorder$ | async as workorder">
    <h2 class="detail-view__title">{{'WORK_ORDER.DETAILS'| translate}}</h2>
    <div class="detail-view__group">
        <label>{{'COMMON.LOCATIONS'| translate}}:</label>
        <span>{{ workorder.locationName }}</span>
    </div>
    <div *ngIf="workorder?.plIdentity?.name" class="detail-view__group">
        <label>{{'WORK_ORDER.LANDSCAPER' | translate}}:</label>
        <span>{{ workorder?.plIdentity?.name }}</span>
    </div>
    <div *ngIf="workorder.createdByCompanyIdentity" class="detail-view__group">
        <label>{{'WORK_ORDER.CREATED'| translate}}:</label>
        <span>{{ workorder.createdByCompanyIdentity?.name }}</span>
    </div>
    <div class="detail-view__group">
        <label>{{'WORK_ORDER.CREATED_DATE'| translate}}:</label>
        <span>{{ workorder.createdDate | date: 'M/d/yy' }}</span>
    </div>
    <div class="detail-view__group">
        <label>{{'WORK_ORDER.MODIFIED_DATE'| translate}}:</label>
        <span>{{ workorder.modifiedDate | date: 'M/d/yy' }}</span>
    </div>
    <div class="detail-view__group">
        <label>{{'WORK_ORDER.LABEL5'| translate}}:</label>
        <span>{{ WorkOrderWorkCategory.toString(workorder.workCategory)| translate }}</span>
    </div>
    <div class="detail-view__group">
        <label>{{'WORK_ORDER.WO_NUMBER'| translate}}:</label>
        <span>{{ workorder.workOrderNumber || 'N/A' }}</span>
    </div>
    <div
        *ngIf="companyType === CompanyType.Admin ||
        companyType === CompanyType.Landscaper ||
        companyType === CompanyType.Service_Partner"
        class="detail-view__group"
    >
        <label>{{'WORK_ORDER.LABEL2'| translate}}:</label>
        <span>{{ workorder.servicePartnerInvoiceNumber || 'N/A' }}</span>
    </div>
    <div class="detail-view__group">
        <label>{{'WORK_ORDER.DAYS_IN_STATUS'| translate}}:</label>
        <span>{{ daysInCurrentStatus(workorder) }}</span>
    </div>
    <div
        *ngIf="companyType === CompanyType.Admin ||
        (companyType === CompanyType.Landscaper && !workorder.isSubcontractor) ||
        companyType === CompanyType.Client"
        class="detail-view__group"
    >
        <label>{{'WORK_ORDER.BILLING_TYPE'| translate}}:</label>
        <span>{{ WorkOrderBillingType.toString(workorder.billingType)| translate }}</span>
    </div>
    <div
        *ngIf="companyType === CompanyType.Admin ||
        companyType === CompanyType.Landscaper ||
        companyType === CompanyType.Service_Partner"
        class="detail-view__group"
    >
        <label>
            {{
                companyType === CompanyType.Admin
                    ? 'Proxet Total'
                    : (companyType === CompanyType.Landscaper && !workorder.isSubcontractor)
                    ? getCompanyName()
                    : ('WORK_ORDER.TOTAL'| translate)
            }}
        </label>
        <span>{{ workorder.oneGmTotal | currency }}</span>
    </div>
    <div
        *ngIf="companyType === CompanyType.Admin ||
        (companyType === CompanyType.Landscaper && !workorder.isSubcontractor)"
        class="detail-view__group"
    >
        <label>{{'WORK_ORDER.SP_TOTAL'| translate}}:</label>
        <span>{{ workorder.spTotal | currency }}</span>
    </div>
    <div
        *ngIf="companyType === CompanyType.Admin ||
        (companyType === CompanyType.Landscaper && !workorder.isSubcontractor) ||
        companyType === CompanyType.Client"
        class="detail-view__group"
    >
        <label>{{'WORK_ORDER.CLIENT_PRICE'| translate}}:</label>
        <span>{{ workorder.clientPrice | currency }}</span>
    </div>
    <div class="detail-view__group">
        <div class="phases" *ngFor="let p of workorder.phases; let i = index">
            <h4>{{'WORK_ORDER.PHASE'| translate}} {{ i + 1 }}</h4>
            <ng-container
                *ngIf="companyType === CompanyType.Admin ||
                companyType === CompanyType.Landscaper ||
                companyType === CompanyType.Service_Partner"
            >
                <label>{{'WORK_ORDER.STATUS'| translate}}:</label>
                <span>{{ WorkOrderPhaseStatus.toString(p.phaseStatus)| translate }}</span>
                <br/>
            </ng-container>
            <label>{{'COMMON.DESCRIPTION'| translate}}:</label>
            <span>{{ p.description }}</span>
        </div>
    </div>
    <h2 class="detail-view__title">{{'COMMON.NOTES'| translate}}</h2>
    <ng-container *ngIf="notes$ | async as notes">
        <ul class="note-list" *ngIf="notes.length > 0; else noNotes">
            <li class="notes" *ngFor="let n of notes">
                {{ n.note }}
            </li>
        </ul>
        <ng-template #noNotes>
            <div class="detail-view__group">
                <label>{{'COMMON.NO_NOTES'| translate}}</label>
            </div>
        </ng-template>
    </ng-container>
</ng-container>
