import { RfpServiceWithoutIds, TimesheetWithoutIds } from '@gm2/common';

export function getClientPricingService(
    rfpServices: RfpServiceWithoutIds[],
    serviceId: string
): RfpServiceWithoutIds {
    for (const rfpService of rfpServices) {
        if (rfpService.serviceId.toString() === serviceId) {
            return rfpService;
        }
    }
    return null;
}

export function calculateClientMaterialsTotal(timesheet: TimesheetWithoutIds): number {
    let total = 0;
    // TODO: un comment the approved material check
    for (const service of timesheet.services) {
        if (service.hasOwnProperty('material') && !!service.material) {
            const clientPricingService = getClientPricingService(
                timesheet.snapShot.rfp.clientPricing.services,
                service.serviceId.toString()
            );
            total +=
                clientPricingService !== null
                    ? service.material.quantity * (clientPricingService.materialPrice || 0)
                    : 0;
        }
    }

    return total;
}
