import { CompanyType, WorkOrderActiveStatus, WorkOrderBillingType, WorkOrderLaborCostType, WorkOrderLaborSelectedRate, WorkOrderPhaseStatus, WorkOrderPhaseType, WorkOrderStatus } from '../../enums';
import { isNull } from '../../utils';
import { WorkOrderEquipmentWithoutIds, WorkOrderLaborWithoutIds, WorkOrderMaterialWithoutIds, WorkOrderPhaseWithoutIds, WorkOrderSimpleWithoutIds, WorkOrderWithoutIds } from '../../models';

// Active Statuses
export function canSetWorkOrderActiveStatusActive(
    companyType: CompanyType,
    currentWorkOrderStatus: WorkOrderStatus,
    currentActiveStatus: WorkOrderActiveStatus,
    targetWorkOrderStatus: WorkOrderStatus,
): boolean {
    return canSetWorkOrderStatuses(
        companyType,
        currentWorkOrderStatus,
        currentActiveStatus,
        targetWorkOrderStatus,
        WorkOrderActiveStatus.Active,
    );
}

export function canSetWorkOrderActiveStatusHold(
    companyType: CompanyType,
    currentWorkOrderStatus: WorkOrderStatus,
    currentActiveStatus: WorkOrderActiveStatus,
): boolean {
    return canSetWorkOrderStatuses(
        companyType,
        currentWorkOrderStatus,
        currentActiveStatus,
        currentWorkOrderStatus,
        WorkOrderActiveStatus.Hold,
    );
}

export function canSetWorkOrderActiveStatusInactive(
    companyType: CompanyType,
    currentWorkOrderStatus: WorkOrderStatus,
    currentActiveStatus: WorkOrderActiveStatus,
): boolean {
    return canSetWorkOrderStatuses(
        companyType,
        currentWorkOrderStatus,
        currentActiveStatus,
        currentWorkOrderStatus,
        WorkOrderActiveStatus.Inactive,
    );
}

// Work Order Statuses
export function canSetWorkOrderStatusCreated(
    companyType: CompanyType,
    currentWorkOrderStatus: WorkOrderStatus,
    currentActiveStatus: WorkOrderActiveStatus,
): boolean {
    return canSetWorkOrderStatuses(
        companyType,
        currentWorkOrderStatus,
        currentActiveStatus,
        WorkOrderStatus.Created,
        currentActiveStatus,
    );
}

export function canSetWorkOrderStatusPending(
    companyType: CompanyType,
    currentWorkOrderStatus: WorkOrderStatus,
    currentActiveStatus: WorkOrderActiveStatus,
): boolean {
    return canSetWorkOrderStatuses(
        companyType,
        currentWorkOrderStatus,
        currentActiveStatus,
        WorkOrderStatus.Pending,
        currentActiveStatus,
    );
}

export function canSetWorkOrderStatusReview(
    companyType: CompanyType,
    currentWorkOrderStatus: WorkOrderStatus,
    currentActiveStatus: WorkOrderActiveStatus,
): boolean {
    return canSetWorkOrderStatuses(
        companyType,
        currentWorkOrderStatus,
        currentActiveStatus,
        WorkOrderStatus.Review,
        currentActiveStatus,
    );
}

export function canSetWorkOrderStatusApproved(
    companyType: CompanyType,
    currentWorkOrderStatus: WorkOrderStatus,
    currentActiveStatus: WorkOrderActiveStatus,
): boolean {
    return canSetWorkOrderStatuses(
        companyType,
        currentWorkOrderStatus,
        currentActiveStatus,
        WorkOrderStatus.Approved,
        currentActiveStatus,
    );
}

export function canSetWorkOrderStatusRejected(
    companyType: CompanyType,
    currentWorkOrderStatus: WorkOrderStatus,
    currentActiveStatus: WorkOrderActiveStatus,
): boolean {
    return canSetWorkOrderStatuses(
        companyType,
        currentWorkOrderStatus,
        currentActiveStatus,
        WorkOrderStatus.Rejected,
        currentActiveStatus,
    );
}

export function canSetWorkOrderStatusServicePartnerRejected(
    companyType: CompanyType,
    currentWorkOrderStatus: WorkOrderStatus,
    currentActiveStatus: WorkOrderActiveStatus,
    workOrder: WorkOrderWithoutIds | WorkOrderSimpleWithoutIds,
): boolean {
    return (
        !!workOrder.createdByCompanyType &&
        (workOrder.createdByCompanyType === CompanyType.Service_Partner || workOrder.createdByCompanyType === CompanyType.Landscaper) &&
        canSetWorkOrderStatuses(
            companyType,
            currentWorkOrderStatus,
            currentActiveStatus,
            WorkOrderStatus.ServicePartnerRejected,
            currentActiveStatus,
        )
    );
}

export function canSetWorkOrderStatusClientRejected(
    companyType: CompanyType,
    currentWorkOrderStatus: WorkOrderStatus,
    currentActiveStatus: WorkOrderActiveStatus,
    workOrder: WorkOrderWithoutIds | WorkOrderSimpleWithoutIds,
): boolean {
    return (
        !!workOrder.createdByCompanyType &&
        workOrder.createdByCompanyType === CompanyType.Client &&
        canSetWorkOrderStatuses(
            companyType,
            currentWorkOrderStatus,
            currentActiveStatus,
            WorkOrderStatus.ClientRejected,
            currentActiveStatus,
        )
    );
}

export function canSetWorkOrderStatusCompleted(
    companyType: CompanyType,
    workOrder: WorkOrderWithoutIds,
): boolean {
    if (Array.isArray(workOrder.phases)) {
        let phasesComplete = true;
        for(const p of workOrder.phases) {
            phasesComplete = phasesComplete && p.phaseStatus === WorkOrderPhaseStatus.Complete;
        }
        return (
            phasesComplete &&
            canSetWorkOrderStatuses(
                companyType,
                workOrder.workOrderStatus,
                workOrder.activeStatus,
                WorkOrderStatus.Completed,
                workOrder.activeStatus,
            )
        );
    }

    return false;
}

export function canSetWorkOrderStatusPosted(
    companyType: CompanyType,
    currentWorkOrderStatus: WorkOrderStatus,
    currentActiveStatus: WorkOrderActiveStatus,
): boolean {
    return canSetWorkOrderStatuses(
        companyType,
        currentWorkOrderStatus,
        currentActiveStatus,
        WorkOrderStatus.Posted,
        currentActiveStatus,
    );
}

export function canSetWorkOrderStatusPaymentProcessed(
    companyType: CompanyType,
    currentWorkOrderStatus: WorkOrderStatus,
    currentActiveStatus: WorkOrderActiveStatus,
): boolean {
    return canSetWorkOrderStatuses(
        companyType,
        currentWorkOrderStatus,
        currentActiveStatus,
        WorkOrderStatus.PaymentProcessed,
        currentActiveStatus,
    );
}

/**
 *
 * @param companyType
 * @param currentWorkOrderStatus
 * @param currentActiveStatus
 * @param currentPhaseStatus
 * @param targetPhaseStatus
 * @param isCompletedCheck - parameter for check permission for show complete button
 */
export function canSetWorkOrderPhaseStatus(
    companyType: CompanyType,
    currentWorkOrderStatus: WorkOrderStatus,
    currentActiveStatus: WorkOrderActiveStatus,
    currentPhaseStatus: WorkOrderPhaseStatus,
    targetPhaseStatus: WorkOrderPhaseStatus,
    isCompletedCheck?: boolean,
): boolean {
    const map = {
        [CompanyType.Admin]: {
            [WorkOrderPhaseStatus.ServicePartnerOffered]: [
                WorkOrderPhaseStatus.Accepted,
                WorkOrderPhaseStatus.ServicePartnerRejected,
            ],
            [WorkOrderPhaseStatus.Accepted]: [
                WorkOrderPhaseStatus.ServicePartnerOffered,
                WorkOrderPhaseStatus.Complete,
            ],
            [WorkOrderPhaseStatus.ServicePartnerRejected]: [
                WorkOrderPhaseStatus.ServicePartnerOffered,
            ],

        },
        [CompanyType.Landscaper]: {
            [WorkOrderPhaseStatus.ServicePartnerOffered]: [
                WorkOrderPhaseStatus.Accepted,
                WorkOrderPhaseStatus.ServicePartnerRejected,
            ],
            [WorkOrderPhaseStatus.Accepted]: [
                WorkOrderPhaseStatus.ServicePartnerOffered,
                WorkOrderPhaseStatus.Complete,
            ],
            [WorkOrderPhaseStatus.ServicePartnerRejected]: [
                WorkOrderPhaseStatus.ServicePartnerOffered,
            ],

        },
        [CompanyType.SuperAdmin]: {
            [WorkOrderPhaseStatus.ServicePartnerOffered]: [
                WorkOrderPhaseStatus.Accepted,
                WorkOrderPhaseStatus.ServicePartnerRejected,
            ],
            [WorkOrderPhaseStatus.Accepted]: [
                WorkOrderPhaseStatus.ServicePartnerOffered,
                WorkOrderPhaseStatus.Complete,
            ],
            [WorkOrderPhaseStatus.ServicePartnerRejected]: [
                WorkOrderPhaseStatus.ServicePartnerOffered,
            ],

        },
        [CompanyType.Service_Partner]: {
            [WorkOrderPhaseStatus.ServicePartnerOffered]: [
                WorkOrderPhaseStatus.Accepted,
                WorkOrderPhaseStatus.ServicePartnerRejected,
            ],
            [WorkOrderPhaseStatus.Accepted]: [
                WorkOrderPhaseStatus.ServicePartnerOffered,
                WorkOrderPhaseStatus.Complete,
            ],
            //[WorkOrderPhaseStatus.Complete]: [WorkOrderPhaseStatus.Accepted]
        },
    };
    /**
     * Old WO do not have phases
     */
    if (currentPhaseStatus) {
        const isValidWorkOrderStatus = [
            WorkOrderStatus.Approved,
            //WorkOrderStatus.ServicePartnerRequested,
            WorkOrderStatus.Created,
        ].includes(currentWorkOrderStatus);
        const isWorkOrderActive = currentActiveStatus === WorkOrderActiveStatus.Active;
        if (!isValidWorkOrderStatus || !isWorkOrderActive) {
            return false;
        }
        if (!map[companyType] || !map[companyType][currentPhaseStatus]) {
            return false;
        }
    } else {
        if (currentWorkOrderStatus === WorkOrderStatus.Approved && !currentPhaseStatus && isCompletedCheck) {
            return true;
        } else {
            return false;
        }
    }


    return map[companyType][currentPhaseStatus].includes(targetPhaseStatus);
}


function canSetWorkOrderStatuses(
    companyType: CompanyType,
    currentWorkOrderStatus: WorkOrderStatus,
    currentActiveStatus: WorkOrderActiveStatus,
    newWorkOrderStatus: WorkOrderStatus,
    newActiveStatus: WorkOrderActiveStatus,
): boolean {
    const permittedActions = getPermittedActions();
    if (
        isNull(permittedActions[companyType]) ||
        isNull(permittedActions[companyType][currentActiveStatus]) ||
        isNull(permittedActions[companyType][currentActiveStatus][currentWorkOrderStatus])
    ) {
        return false;
    }
    const workOrderStatusChanged: boolean = currentWorkOrderStatus !== newWorkOrderStatus;
    const activeStatusChanged: boolean = currentActiveStatus !== newActiveStatus;
    if (!workOrderStatusChanged && !activeStatusChanged) {
        return false;
    }
    const workOrderStatusOk: boolean = permittedActions[companyType][currentActiveStatus][
        currentWorkOrderStatus
        ].includes(newWorkOrderStatus);
    const activeStatusOk: boolean = permittedActions[companyType][currentActiveStatus][
        currentWorkOrderStatus
        ].includes(newActiveStatus);
    return workOrderStatusOk || activeStatusOk;
}

let _permittedActions: {
    [companyTypeKey: string]: {
        [activeStatusKey: string]: {
            [statusKey: string]: string[];
        };
    };
} = null;

function getPermittedActions(): {
    [companyTypeKey: string]: {
        [activeStatusKey: string]: {
            [statusKey: string]: string[];
        };
    };
} {
    if (!isNull(_permittedActions)) {
        return _permittedActions;
    }
    const permittedActions: {
        [companyTypeKey: string]: {
            [activeStatusKey: string]: {
                [statusKey: string]: string[];
            };
        };
    } = {};

    permittedActions[CompanyType.Admin] = {};
    permittedActions[CompanyType.SuperAdmin] = {};
    permittedActions[CompanyType.Landscaper] = {};
    permittedActions[CompanyType.Client] = {};
    permittedActions[CompanyType.Service_Partner] = {};
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Active] = {};
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Hold] = {};
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Inactive] = {};

    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Active] = {};
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Hold] = {};
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Inactive] = {};

    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Active] = {};
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Hold] = {};
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Inactive] = {};

    permittedActions[CompanyType.Client][WorkOrderActiveStatus.Active] = {};
    permittedActions[CompanyType.Client][WorkOrderActiveStatus.Hold] = {};
    permittedActions[CompanyType.Client][WorkOrderActiveStatus.Inactive] = {};
    permittedActions[CompanyType.Service_Partner][WorkOrderActiveStatus.Active] = {};

    // Admin - Active
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Active][WorkOrderStatus.Template] = [
        WorkOrderStatus.Created,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Active][WorkOrderStatus.Template] = [
        WorkOrderStatus.Created,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Active][WorkOrderStatus.Template] = [
        WorkOrderStatus.Created,
    ];


    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Active][
        WorkOrderStatus.ClientRequested
        ] = [WorkOrderStatus.Created, WorkOrderStatus.ClientRejected];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Active][
        WorkOrderStatus.ClientRequested
        ] = [WorkOrderStatus.Created, WorkOrderStatus.ClientRejected];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Active][
        WorkOrderStatus.ClientRequested
        ] = [WorkOrderStatus.Created, WorkOrderStatus.ClientRejected];

    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Active][
        WorkOrderStatus.ServicePartnerRequested
        ] = [WorkOrderStatus.Created, WorkOrderStatus.ServicePartnerRejected];

    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Active][
        WorkOrderStatus.ServicePartnerRequested
        ] = [WorkOrderStatus.Created, WorkOrderStatus.ServicePartnerRejected];

    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Active][
        WorkOrderStatus.ServicePartnerRequested
        ] = [WorkOrderStatus.Created, WorkOrderStatus.ServicePartnerRejected];


    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Active][WorkOrderStatus.Created] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Active][WorkOrderStatus.Created] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Active][WorkOrderStatus.Created] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Active][WorkOrderStatus.Pending] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];

    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Active][WorkOrderStatus.Pending] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];

    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Active][WorkOrderStatus.Pending] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Active][WorkOrderStatus.Review] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Active][WorkOrderStatus.Review] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Active][WorkOrderStatus.Review] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Active][WorkOrderStatus.Approved] = [
        //WorkOrderStatus.Created,
        WorkOrderStatus.Completed,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Active][WorkOrderStatus.Approved] = [
        //WorkOrderStatus.Created,
        WorkOrderStatus.Completed,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Active][WorkOrderStatus.Approved] = [
        //WorkOrderStatus.Created,
        WorkOrderStatus.Completed,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Active][WorkOrderStatus.Rejected] = [
        WorkOrderStatus.Created,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Active][WorkOrderStatus.Rejected] = [
        WorkOrderStatus.Created,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Active][WorkOrderStatus.Rejected] = [
        WorkOrderStatus.Created,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Active][
        WorkOrderStatus.ServicePartnerRejected
        ] = [WorkOrderStatus.Created, WorkOrderActiveStatus.Inactive];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Active][
        WorkOrderStatus.ServicePartnerRejected
        ] = [WorkOrderStatus.Created, WorkOrderActiveStatus.Inactive];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Active][
        WorkOrderStatus.ServicePartnerRejected
        ] = [WorkOrderStatus.Created, WorkOrderActiveStatus.Inactive];

    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Active][
        WorkOrderStatus.ClientRejected
        ] = [WorkOrderStatus.Created, WorkOrderActiveStatus.Inactive];

    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Active][
        WorkOrderStatus.ClientRejected
        ] = [WorkOrderStatus.Created, WorkOrderActiveStatus.Inactive];

    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Active][
        WorkOrderStatus.ClientRejected
        ] = [WorkOrderStatus.Created, WorkOrderActiveStatus.Inactive];


    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Active][WorkOrderStatus.Completed] = [
        WorkOrderStatus.Posted,
        WorkOrderActiveStatus.Inactive,
    ];

    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Active][WorkOrderStatus.Completed] = [
        WorkOrderStatus.Posted,
        WorkOrderActiveStatus.Inactive,
    ];

    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Active][WorkOrderStatus.Completed] = [
        WorkOrderStatus.Posted,
        WorkOrderActiveStatus.Inactive,
    ];

    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Active][WorkOrderStatus.Posted] = [
        WorkOrderStatus.PaymentProcessed,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Active][WorkOrderStatus.Posted] = [
        WorkOrderStatus.PaymentProcessed,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Active][WorkOrderStatus.Posted] = [
        WorkOrderStatus.PaymentProcessed,
        WorkOrderActiveStatus.Inactive,
    ];

    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Active][
        WorkOrderStatus.PaymentProcessed
        ] = [WorkOrderActiveStatus.Inactive];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Active][
        WorkOrderStatus.PaymentProcessed
        ] = [WorkOrderActiveStatus.Inactive];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Active][
        WorkOrderStatus.PaymentProcessed
        ] = [WorkOrderActiveStatus.Inactive];
    // Admin - Hold
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Template] = [
        WorkOrderStatus.Created,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Template] = [
        WorkOrderStatus.Created,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Hold][WorkOrderStatus.Template] = [
        WorkOrderStatus.Created,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Created] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Created] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Hold][WorkOrderStatus.Created] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Pending] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Pending] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Hold][WorkOrderStatus.Pending] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Review] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Review] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Hold][WorkOrderStatus.Review] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Approved] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Completed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Approved] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Completed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Hold][WorkOrderStatus.Approved] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Completed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Rejected] = [
        WorkOrderStatus.Created,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Rejected] = [
        WorkOrderStatus.Created,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Hold][WorkOrderStatus.Rejected] = [
        WorkOrderStatus.Created,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Completed] = [
        WorkOrderStatus.Posted,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Completed] = [
        WorkOrderStatus.Posted,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Hold][WorkOrderStatus.Completed] = [
        WorkOrderStatus.Posted,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Posted] = [
        WorkOrderStatus.PaymentProcessed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Hold][WorkOrderStatus.Posted] = [
        WorkOrderStatus.PaymentProcessed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Hold][WorkOrderStatus.Posted] = [
        WorkOrderStatus.PaymentProcessed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Hold][
        WorkOrderStatus.PaymentProcessed
        ] = [WorkOrderActiveStatus.Active, WorkOrderActiveStatus.Inactive];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Hold][
        WorkOrderStatus.PaymentProcessed
        ] = [WorkOrderActiveStatus.Active, WorkOrderActiveStatus.Inactive];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Hold][
        WorkOrderStatus.PaymentProcessed
        ] = [WorkOrderActiveStatus.Active, WorkOrderActiveStatus.Inactive];
    // Admin - Inactive
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.Template
        ] = [WorkOrderStatus.Created, WorkOrderActiveStatus.Active];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.Template
        ] = [WorkOrderStatus.Created, WorkOrderActiveStatus.Active];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.Template
        ] = [WorkOrderStatus.Created, WorkOrderActiveStatus.Active];

    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Inactive][WorkOrderStatus.Created] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Inactive][WorkOrderStatus.Created] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Inactive][WorkOrderStatus.Created] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Inactive][WorkOrderStatus.Pending] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Inactive][WorkOrderStatus.Pending] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Inactive][WorkOrderStatus.Pending] = [
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Inactive][WorkOrderStatus.Review] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Inactive][WorkOrderStatus.Review] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Inactive][WorkOrderStatus.Review] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Active,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.Approved
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Completed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.Approved
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Completed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.Approved
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Completed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.Rejected
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.Rejected
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.Rejected
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.ServicePartnerRejected
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.ServicePartnerRejected,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.ServicePartnerRejected
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.ServicePartnerRejected,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.ServicePartnerRejected
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Approved,
        WorkOrderStatus.ServicePartnerRejected,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.Completed
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderStatus.ServicePartnerRejected,
        WorkOrderStatus.Completed,
        WorkOrderStatus.Posted,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.Completed
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderStatus.ServicePartnerRejected,
        WorkOrderStatus.Completed,
        WorkOrderStatus.Posted,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.Completed
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderStatus.ServicePartnerRejected,
        WorkOrderStatus.Completed,
        WorkOrderStatus.Posted,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Inactive][WorkOrderStatus.Posted] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderStatus.ServicePartnerRejected,
        WorkOrderStatus.Completed,
        WorkOrderStatus.Posted,
        WorkOrderStatus.PaymentProcessed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Inactive][WorkOrderStatus.Posted] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderStatus.ServicePartnerRejected,
        WorkOrderStatus.Completed,
        WorkOrderStatus.Posted,
        WorkOrderStatus.PaymentProcessed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Inactive][WorkOrderStatus.Posted] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderStatus.ServicePartnerRejected,
        WorkOrderStatus.Completed,
        WorkOrderStatus.Posted,
        WorkOrderStatus.PaymentProcessed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Admin][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.PaymentProcessed
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderStatus.ServicePartnerRejected,
        WorkOrderStatus.Completed,
        WorkOrderStatus.Posted,
        WorkOrderStatus.PaymentProcessed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.SuperAdmin][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.PaymentProcessed
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderStatus.ServicePartnerRejected,
        WorkOrderStatus.Completed,
        WorkOrderStatus.Posted,
        WorkOrderStatus.PaymentProcessed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];
    permittedActions[CompanyType.Landscaper][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.PaymentProcessed
        ] = [
        WorkOrderStatus.Created,
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderStatus.ServicePartnerRejected,
        WorkOrderStatus.Completed,
        WorkOrderStatus.Posted,
        WorkOrderStatus.PaymentProcessed,
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Inactive,
    ];

    // Client - Active
    permittedActions[CompanyType.Client][WorkOrderActiveStatus.Active][WorkOrderStatus.Pending] = [
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.Client][WorkOrderActiveStatus.Active][WorkOrderStatus.Review] = [
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Hold,
    ];
    // Client - Hold
    permittedActions[CompanyType.Client][WorkOrderActiveStatus.Hold][WorkOrderStatus.Pending] = [
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Hold,
    ];
    permittedActions[CompanyType.Client][WorkOrderActiveStatus.Hold][WorkOrderStatus.Review] = [
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Hold,
    ];
    // Client - Inactive
    permittedActions[CompanyType.Client][WorkOrderActiveStatus.Inactive][
        WorkOrderStatus.Pending
        ] = [WorkOrderStatus.Approved, WorkOrderStatus.Rejected, WorkOrderActiveStatus.Hold];
    permittedActions[CompanyType.Client][WorkOrderActiveStatus.Inactive][WorkOrderStatus.Review] = [
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderActiveStatus.Hold,
    ];
    // SP - Active
    permittedActions[CompanyType.Service_Partner][WorkOrderActiveStatus.Active][
        WorkOrderStatus.Created
        ] = [WorkOrderStatus.Pending];

    _permittedActions = permittedActions;
    return permittedActions;
}

const MINUTES_IN_HOUR = 60;

export function zeroOutWorkOrderSalesTax(billingType: WorkOrderBillingType): boolean {
    return [WorkOrderBillingType.Contractual, WorkOrderBillingType.Warranty].includes(billingType);
}

export function zeroOutWorkOrderClientPrice(billingType: WorkOrderBillingType): boolean {
    return [WorkOrderBillingType.Contractual, WorkOrderBillingType.Warranty].includes(billingType);
}

// Material, equipment, or labor single item totals (wo.phases.[item].total)
export function calcPhaseLaborItemEstimate(labor: WorkOrderLaborWithoutIds): number {
    return !!labor.labor ? labor.hours * labor.labor.rateExternal : 0;
}

export function calcPhaseLaborItemTotal(
    labor: WorkOrderLaborWithoutIds,
    costType: WorkOrderLaborCostType,
): number {
    let newTotal = 0;
    switch (costType) {
        case WorkOrderLaborCostType.NewHoursEstimate:
            let rate;
            if (!!labor.servicePartnerRate) {
                rate = labor.servicePartnerRate;
            } else if (labor.labor && labor.labor.rateExternal) {
                rate = labor.labor.rateExternal;
            } else {
                rate = 0;
            }
            const hoursSpent = labor.hours;
            newTotal = hoursSpent * rate;
            break;
        case WorkOrderLaborCostType.MinutesActual:
            if (!!labor.completionInfo) {
                let rate;
                if (!!labor.servicePartnerRate) {
                    rate = labor.servicePartnerRate;
                } else if (labor.labor && labor.labor.rateExternal) {
                    rate = labor.labor.rateExternal;
                } else {
                    rate = 0;
                }
                const hoursSpent = labor.completionInfo.timespent / MINUTES_IN_HOUR;
                newTotal = hoursSpent * rate;
                break;
            }
        case WorkOrderLaborCostType.LegacyHoursEstimate:
            newTotal = calcPhaseLaborItemEstimate(labor);
            break;
        default:
            newTotal = 0;
            break;
    }
    if (!!labor.markup) {
        newTotal = newTotal * (1 + labor.markup / 100);
    }
    return !isNaN(newTotal) ? newTotal : 0;
}

export function calcPhaseEquipmentItemTotal(equipment: WorkOrderEquipmentWithoutIds): number {
    // tslint:disable-next-line:no-magic-numbers
    return equipment.quantity * equipment.price * (1 + equipment.markup / 100);
}

export function calcPhaseMaterialItemTotal(material: WorkOrderMaterialWithoutIds): number {
    // tslint:disable-next-line:no-magic-numbers
    return material.quantity * material.price * (1 + material.markup / 100);
}

// Phase totals (wo.phases.xxxTotal)
export function calcPhaseMaterialTotal(phase: WorkOrderPhaseWithoutIds): number {
    let materialTotal = 0;
    for(const m of phase.material) {
        materialTotal += m.total;
    }
    return materialTotal;
}

export function calcPhaseEquipmentTotal(phase: WorkOrderPhaseWithoutIds): number {
    let equipmentTotal = 0;
    for(const e of phase.equipment) {
        equipmentTotal += e.total;
    }
    return equipmentTotal;
}

export function calcPhaseLaborTotal(phase: WorkOrderPhaseWithoutIds): number {
    let laborTotal = 0;
    for(const l of phase.labor) {
        laborTotal += l.total;
    }
    return !isNaN(laborTotal) ? laborTotal : 0;
}

export function calcPhaseSalesTaxTotal(
    phase: WorkOrderPhaseWithoutIds,
    billingType: WorkOrderBillingType,
): number {
    // silly function but helps with keeping calculation pattern consistent
    return zeroOutWorkOrderSalesTax(billingType) ? 0 : phase.phaseSalesTax;
}

export function calcPhaseTotal(
    phase: WorkOrderPhaseWithoutIds,
    billingType: WorkOrderBillingType,
): number {
    if (zeroOutWorkOrderClientPrice(billingType)) {
        return 0;
    }
    return (
        phase.materialTotal +
        phase.equipmentTotal +
        phase.laborTotal +
        calcPhaseSalesTaxTotal(phase, billingType)
    );
}

// Work order top level totals (workOrder.totals.xxx) - OGM facing
export function calcWorkOrderMaterialTotal(phases: WorkOrderPhaseWithoutIds[]): number {
    let total = 0;
    for(const p of phases) {
        for(const m of p.material) {
            total += m.quantity * m.price;
        }
    }
    return total;
}

export function calcWorkOrderEquipmentTotal(phases: WorkOrderPhaseWithoutIds[]): number {
    let total = 0;
    for(const p of phases) {
        for(const e of p.equipment) {
            total += e.quantity * e.price;
        }
    }
    return total;
}

export function calcWorkOrderLaborTotalExternal(phases: WorkOrderPhaseWithoutIds[]): number {
    let total = 0;
    for(const p of phases) {
        for(const l of p.labor) {
            total += l.total;
        }
    }
    return total;
}

function laborCostRate(l: WorkOrderLaborWithoutIds): number {
    switch (l.selectedRate) {
        case WorkOrderLaborSelectedRate.OneGMRate:
            return !!l.labor ? l.labor.rateInternal : 0;
        case WorkOrderLaborSelectedRate.ServicePartnerRate:
            return !!l.servicePartnerRate ? l.servicePartnerRate : 0;
        default:
            throw new Error(`Unsupported selected rate ${l.selectedRate}`);
    }
}

export function calcWorkOrderLaborTotal(
    phases: WorkOrderPhaseWithoutIds[],
    costType: WorkOrderLaborCostType,
): number {
    /*
        Note that this method is using the internal labor
        rates to calculate cost, -not- the external labor
        rate to calculate client pricing
    */
    if (!costType) {
        costType = WorkOrderLaborCostType.LegacyHoursEstimate;
    }
    let total = 0;
    for(const p of phases) {
        for(const l of p.labor) {
            switch (costType) {
                case WorkOrderLaborCostType.NewHoursEstimate:
                    total += l.hours * laborCostRate(l);
                case WorkOrderLaborCostType.MinutesActual:
                    if (!!l.completionInfo) {
                        const hoursSpent = l.completionInfo.timespent / MINUTES_IN_HOUR;
                        total += hoursSpent * laborCostRate(l);
                    }
                    break;
                case WorkOrderLaborCostType.LegacyHoursEstimate:
                    total += l.hours * laborCostRate(l);
                    break;
                default:
                    throw new Error(`Unsupported cost type ${costType}`);
            }
        }
    }
    return isNaN(total) ? 0 : total;
}

// client facing totals
export function calcClientWorkOrderMaterialTotal(
    phases: WorkOrderPhaseWithoutIds[],
    billingType: WorkOrderBillingType,
): number {
    if (zeroOutWorkOrderClientPrice(billingType)) {
        return 0;
    }
    let total = 0;
    for(const p of phases) {
        for(const m of p.material) {
            total += calcPhaseMaterialItemTotal(m);
        }
    }
    return total;
}

export function calcClientWorkOrderEquipmentTotal(
    phases: WorkOrderPhaseWithoutIds[],
    billingType: WorkOrderBillingType,
): number {
    if (zeroOutWorkOrderClientPrice(billingType)) {
        return 0;
    }
    let total = 0;
    for(const p of phases) {
        for(const e of p.equipment) {
            total += calcPhaseEquipmentItemTotal(e);
        }
    }
    return total;
}

export function calcClientWorkOrderLaborTotal(
    phases: WorkOrderPhaseWithoutIds[],
    costType: WorkOrderLaborCostType,
    billingType: WorkOrderBillingType,
): number {
    if (zeroOutWorkOrderClientPrice(billingType)) {
        return 0;
    }
    let total = 0;
    for(const p of phases) {
        for(const l of p.labor) {
            total += calcPhaseLaborItemTotal(l, costType);
        }
    }
    return total;
}

export function calcClientWorkOrderSalesTaxTotal(
    phases: WorkOrderPhaseWithoutIds[],
    billingType: WorkOrderBillingType,
): number {
    if (zeroOutWorkOrderSalesTax(billingType)) {
        return 0;
    }
    let total = 0;
    for(const p of phases) {
        const salesTax = p.phaseSalesTax || 0;
        total += salesTax;
    }
    return total;
}

export function calcWorkOrderClientTotal(
    phases: WorkOrderPhaseWithoutIds[],
    billingType: WorkOrderBillingType,
): number {
    if (zeroOutWorkOrderClientPrice(billingType)) {
        return 0;
    }
    let ce = 0;
    for(const p of phases) {
        if (p.phaseType !== WorkOrderPhaseType.FixedRate) {
            for(const m of p.material) {
                ce += m.total;
            }
            for(const e of p.equipment) {
                ce += e.total;
            }
            for(const l of p.labor) {
                ce += l.total;
            }
        } else {
            ce += p.customClientPrice;
        }
    }
    return ce;
}

export function calcWorkOrderClientPretaxPrice(
    phases: WorkOrderPhaseWithoutIds[],
    billingType: WorkOrderBillingType,
): number {
    if (zeroOutWorkOrderClientPrice(billingType)) {
        return 0;
    }
    let pretax = 0;
    for(const p of phases) {
        if (p.phaseType !== WorkOrderPhaseType.FixedRate) {
            for(const m of p.material) {
                pretax += m.total;
            }
            for(const e of p.equipment) {
                pretax += e.total;
            }
            for(const l of p.labor) {
                pretax += l.total;
            }
        } else {
            pretax += p.customClientPrice;
        }
    }
    return pretax;
}

export function calcWorkOrderClientPrice(
    phases: WorkOrderPhaseWithoutIds[],
    billingType: WorkOrderBillingType,
): number {
    if (zeroOutWorkOrderClientPrice(billingType)) {
        return 0;
    }
    let total = 0;
    for(const p of phases) {
        const salesTax = calcPhaseSalesTaxTotal(p, billingType) || 0;
        total += calcWorkOrderClientPretaxPrice([p], billingType) + salesTax;
    }
    return total;
}

export function calcWorkOrderOgmTotal(
    phases: WorkOrderPhaseWithoutIds[],
    costType: WorkOrderLaborCostType,
): number {
    // const isOGMPhasesExist = phases.some(phase => phase.phaseType === WorkOrderPhaseType.ONEGM);
    // const isSPPhasesExist = phases.some(phase => phase.phaseType === WorkOrderPhaseType.ServicePartner);
    // let tmpPhases = [...phases];
    // if (isOGMPhasesExist && isSPPhasesExist) {
    const tmpPhases = [...phases].filter(phase => phase.phaseType === WorkOrderPhaseType.ONEGM);
    // }
    const materialTotal = calcWorkOrderMaterialTotal(tmpPhases);
    const equipmentTotal = calcWorkOrderEquipmentTotal(tmpPhases);
    const laborTotal = calcWorkOrderLaborTotal(tmpPhases, costType);
    return materialTotal + equipmentTotal + laborTotal;
}

export function calcWorkOrderSpTotal(
    phases: WorkOrderPhaseWithoutIds[],
    costType: WorkOrderLaborCostType,
): number {
    if (!costType) {
        costType = WorkOrderLaborCostType.LegacyHoursEstimate;
    }
    const spPhases = [...phases].filter(phase => phase.phaseType === WorkOrderPhaseType.ServicePartner);
    const materialTotal = calcWorkOrderMaterialTotal(spPhases);
    const equipmentTotal = calcWorkOrderEquipmentTotal(spPhases);
    const laborTotal = calcWorkOrderLaborTotal(spPhases, costType);
    return materialTotal + equipmentTotal + laborTotal;
}

export function calcWorkOrderClientMarkUp(
    phases: WorkOrderPhaseWithoutIds[],
    costType: WorkOrderLaborCostType,
    billingType: WorkOrderBillingType,
): number {
    const workOrderClientPretax = calcWorkOrderClientPretaxPrice(phases, billingType);
    const workOrderOgmTotal = calcWorkOrderOgmTotal(phases, costType);
    const workOrderSpTotal = calcWorkOrderSpTotal(phases, costType);
    return (workOrderClientPretax - workOrderOgmTotal - workOrderSpTotal);

}

export function calcWorkOrderGrossProfit(
    phases: WorkOrderPhaseWithoutIds[],
    costType: WorkOrderLaborCostType,
    billingType: WorkOrderBillingType,
): number {
    const cp = calcWorkOrderClientPretaxPrice(phases, billingType);
    const ogmTotal = calcWorkOrderOgmTotal(phases, costType);
    const workOrderSpTotal = calcWorkOrderSpTotal(phases, costType);
    return cp - ogmTotal - workOrderSpTotal;
}

export function calcWorkOrderGrossProfitPercent(
    phases: WorkOrderPhaseWithoutIds[],
    costType: WorkOrderLaborCostType,
    billingType: WorkOrderBillingType,
): number {
    const cp = calcWorkOrderClientPretaxPrice(phases, billingType);
    const gp = calcWorkOrderGrossProfit(phases, costType, billingType);
    return cp > 0 ? gp / cp : 0;
}

export function calcWorkOrderDaysInCurrentStatus(
    item: WorkOrderWithoutIds | WorkOrderSimpleWithoutIds,
): number {
    const date = !!item.lastStatusChangedDate
        ? new Date(item.lastStatusChangedDate)
        : new Date(item.createdDate);
    const today = new Date();
    const difference = Math.abs(today.getTime() - date.getTime());
    // tslint:disable:no-magic-numbers
    return Math.ceil(difference / (1000 * 3600 * 24));
}

/*
    To support the addition of a service partner experience
    to work orders the following 'calcInternal' functions
    have been added to make the correct cost (receiavables)
    breakdown available to service partners in the UI.

    They are -NOT- meant to be used to record data to work
    order documents or otherwise create trusted financial
    data points. They are -ONLY- to make life a little
    easier in the work order UI.

    Pat D May 26 2021
*/

export function calcInternalPhaseLaborItemEstimate(labor: WorkOrderLaborWithoutIds): number {
    return labor.hours * laborCostRate(labor);
}

export function calcInternalPhaseLaborItemTotal(
    labor: WorkOrderLaborWithoutIds,
    costType: WorkOrderLaborCostType,
): number {
    switch (costType) {
        case WorkOrderLaborCostType.NewHoursEstimate:
            let newTotal = 0;
            let rate;
            if (!!labor.servicePartnerRate) {
                rate = labor.servicePartnerRate;
            } else if (labor.labor && labor.labor.rateExternal) {
                rate = labor.labor.rateExternal;
            } else {
                rate = 0;
            }
            const hoursSpent = labor.hours;
            newTotal = hoursSpent * rate;
            return newTotal;
        case WorkOrderLaborCostType.MinutesActual:
            let total = 0;
            if (!!labor.completionInfo) {
                let rate;
                if (!!labor.servicePartnerRate) {
                    rate = labor.servicePartnerRate;
                } else if (labor.labor && labor.labor.rateExternal) {
                    rate = labor.labor.rateExternal;
                } else {
                    rate = 0;
                }
                const hoursSpent = labor.completionInfo.timespent / MINUTES_IN_HOUR;
                total = hoursSpent * rate;
            }
            return total;
        case WorkOrderLaborCostType.LegacyHoursEstimate:
            return calcInternalPhaseLaborItemEstimate(labor);
        default:
            return 0;
    }
}

export function calcInternalPhaseEquipmentItemTotal(
    equipment: WorkOrderEquipmentWithoutIds,
): number {
    return equipment.quantity * equipment.price;
}

export function calcInternalPhaseMaterialItemTotal(material: WorkOrderMaterialWithoutIds): number {
    return material.quantity * material.price;
}

export function calcInternalPhaseMaterialTotal(phase: WorkOrderPhaseWithoutIds): number {
    let internalMaterialTotal = 0;
    for(const m of phase.material) {
        internalMaterialTotal += calcInternalPhaseMaterialItemTotal(m);
    }
    return internalMaterialTotal;
}

export function calcInternalPhaseEquipmentTotal(phase: WorkOrderPhaseWithoutIds): number {
    let internalEquipmentTotal = 0;
    for(const e of phase.equipment) {
        internalEquipmentTotal += calcInternalPhaseEquipmentItemTotal(e);
    }
    return internalEquipmentTotal;
}

export function calcInternalPhaseLaborTotal(
    phase: WorkOrderPhaseWithoutIds,
    costType: WorkOrderLaborCostType,
): number {
    let internalLaborTotal = 0;
    for(const l of phase.labor) {
        internalLaborTotal += calcInternalPhaseLaborItemTotal(l, costType);
    }
    return internalLaborTotal;
}

export function calcInternalPhaseTotal(
    phase: WorkOrderPhaseWithoutIds,
    costType: WorkOrderLaborCostType,
): number {
    const internalMaterialTotal = calcInternalPhaseMaterialTotal(phase);
    const internalEquipmentTotal = calcInternalPhaseEquipmentTotal(phase);
    const internalLaborTotal = calcInternalPhaseLaborTotal(phase, costType);
    return internalMaterialTotal + internalEquipmentTotal + internalLaborTotal;
}
