import { State, Selector, StateContext, Action } from '@ngxs/store';
import {
    IMappingCategoryLike,
    ServiceTypeService,
    ServiceType,
    IServiceTypeLike,
    ToastService
} from '@gm2/ui-common';
import { ImmutableSelector, ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Receiver } from '@ngxs-labs/emitter';
import { Observable, EMPTY } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';

export interface ServiceTypeCreateStateModel {
    _id: string | null;
    mappingCategories: IMappingCategoryLike[] | null;
}

@State<ServiceTypeCreateStateModel>({
    name: 'serviceTypeCreate',
    defaults: {
        _id: null,
        mappingCategories: null
    }
})
@Injectable()
export class ServiceTypeCreateState {
    private static serviceTypeService: ServiceTypeService;
    private static _toast: ToastService;

    constructor(injector: Injector) {
        ServiceTypeCreateState.serviceTypeService = injector.get<ServiceTypeService>(
            ServiceTypeService
        );
        ServiceTypeCreateState._toast = injector.get<ToastService>(ToastService);
    }

    @Selector()
    @ImmutableSelector()
    public static mappingCategories(
        state: ServiceTypeCreateStateModel
    ): IMappingCategoryLike[] | null {
        return state.mappingCategories;
    }

    @Receiver({ type: '[ServiceTypeCreate] Reset Service Type' })
    @ImmutableContext()
    public static resetServiceType(
        context: StateContext<ServiceTypeCreateStateModel>,
        data: { payload: IServiceTypeLike }
    ): void {
        context.setState((state: ServiceTypeCreateStateModel) => {
            state._id = null;
            state.mappingCategories = null;

            if (data.payload) {
                state._id = data.payload._id;
                state.mappingCategories = data.payload.mappingCategories || null;
            }

            return state;
        });
    }

    @Receiver({ type: '[ServiceTypeCreate] Add MappingCategory' })
    @ImmutableContext()
    public static addMappingCategory(
        context: StateContext<ServiceTypeCreateStateModel>,
        data: { payload: IMappingCategoryLike }
    ): Observable<string> {
        const state = context.getState();
        state.mappingCategories = state.mappingCategories || [];
        state.mappingCategories.push(data.payload);

        if (state._id) {
            return ServiceTypeCreateState.serviceTypeService
                .addMappingCategory(state._id, data.payload)
                .pipe(
                    tap(newid => {
                        data.payload._id = newid;
                        context.setState(state);
                    })
                );
        } else {
            context.setState(state);
            return EMPTY;
        }
    }

    @Receiver({ type: '[ServiceTypeCreate] Delete Mapping Category' })
    @ImmutableContext()
    public static deleteMappingCategory(
        context: StateContext<ServiceTypeCreateStateModel>,
        data: { payload: number }
    ): Observable<void> {
        const state = context.getState();
        const existing = state.mappingCategories.splice(data.payload, 1);
        console.log(state.mappingCategories, existing);

        // If mapCategory has id, it's on the server so delete it.
        if (existing[0]._id) {
            return ServiceTypeCreateState.serviceTypeService
                .deleteMappingCategory(state._id, existing[0]._id)
                .pipe(
                    catchError(err => {
                        this._toast.error(err);
                        return EMPTY;
                    }),
                    tap(() => context.setState(state))
                );
        } else {
            context.setState(state);
            return EMPTY;
        }
    }
}
