import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { ApproveType, ClientCreateDto, Company, CompanyCreateDto, CompanyPriceService, CompanyProfileDto, CompanyRating, CompanyService, CompanyServiceDto, CompanyStatus, CompanyType, ContactType, InsuranceStatus, Note, NoteCreateDto, NoteService, NotificationService, ServiceApproveDto, ServicePartnerCompliant, ServicePartnerCreateDto, State, Status, ToastService, User, UserService } from '@gm2/ui-common';
import { ModalService } from '@gm2/ui-library';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, iif, merge, Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, map, pluck, shareReplay, startWith, switchMap, switchMapTo, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { AuthState, CompanyState, UserState } from '@gm2/ui-state';
import { DatePipe } from '@angular/common';
import { CompanyQuestionnaireAnswersWithoutIds, NoteType, Permission } from '@gm2/common';
import * as jsonToFormData from 'json-form-data';
import { ClientCompanyService, Labor, UserSimple } from '@gm2/api-common';
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import {
    ServiceClassification,
    ServiceType,
    ServiceTypeService,
    MaterialOptions
} from '@gm2/ui-common';
import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'gm2-date-modal',
    templateUrl: './company-service-edit.component.html',
    styleUrls: ['./company-service-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceEditModalComponent implements OnInit {

    public clientServiceGroup: UntypedFormGroup = this._fb.group({
        serviceName: ['', Validators.required],
        serviceDescription: [''],
        serviceClassification: ['', Validators.required],
        serviceTypeId: ['', Validators.required],
        hasMaterial: ['']
    });

    public MaterialOptions: typeof MaterialOptions = MaterialOptions;
    public ServiceClassification: typeof ServiceClassification = ServiceClassification;
    public readonly serviceTypes$: Observable<
    ServiceType[]
    > = this._serviceTypeService.getServiceTypes().pipe(shareReplay(1));

    constructor(
        private _fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<ServiceEditModalComponent>,
        private readonly _serviceTypeService: ServiceTypeService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.clientServiceGroup.setValue({
            serviceName: [this.data.service.name],
            serviceDescription: [this.data.service.description],
            serviceClassification: [this.data.service.serviceClassification],
            serviceTypeId: [this.data.service.serviceTypeId],
            hasMaterial: [this.data.service.material]
        })
        this.clientServiceGroup.controls.serviceName.setValue(this.data.service.name)
        this.clientServiceGroup.controls.serviceDescription.setValue(this.data.service.description)
        this.clientServiceGroup.controls.serviceTypeId.setValue(this.data.service.serviceTypeId)
        this.clientServiceGroup.controls.serviceClassification.setValue(this.data.service.serviceClassification)
        this.clientServiceGroup.controls.hasMaterial.setValue(MaterialOptions.toOptions(this.data.service.material))
    }

    public close(): void {
        this.dialogRef.close(false);
    }

    public select(): void {
        this.dialogRef.close({
            serviceName: this.clientServiceGroup.controls.serviceName.value,
            serviceDescription: this.clientServiceGroup.controls.serviceDescription.value,
            serviceClassification: this.clientServiceGroup.controls.serviceClassification.value,
            serviceTypeId: this.clientServiceGroup.controls.serviceTypeId.value,
            hasMaterial: this.clientServiceGroup.controls.hasMaterial.value
        });
    }
}