import { IBillingTypeChargeStrategy } from '../interfaces/i-billing-type-charge-strategy';
import { calculateClientMaterialsTotal, getClientPricingService } from '../util/methods';
import {
    RfpServiceWithoutIds,
    TimesheetWithoutIds,
    MaterialWithoutIds,
    TimesheetServicesWithoutIds,
    ServiceWithoutIds,
    ServiceSettings
} from '@gm2/common';

export abstract class OnegmAbstractNonMonthlyCharges implements IBillingTypeChargeStrategy {
    //#region Abstract Methods
    public abstract calculateServiceClientTotal(
        duration: number,
        clientPricingService: RfpServiceWithoutIds
    ): number;
    //#endregion

    //#region IBillingTypeChargeStrategy Methods

    public calculateCompanyCharges(
        timesheet: TimesheetWithoutIds,
        approvedMaterialDict: { [key: string]: MaterialWithoutIds }
    ): { total: number; duration: number; errors: string[] } {
        return null;
    }

    public calculateClientCharges(
        timesheet: TimesheetWithoutIds,
        companyTotalDuration: number
    ): { total: number; duration: number; errors: string[] } {
        if (!timesheet.snapShot.rfp.clientPricing) {
            return {
                duration: 0,
                total: 0,
                errors: []
            };
        }

        const serviceDurationDict: {
            [serviceId: string]: number;
        } = this.getTotalDurationByService(timesheet);

        let clientTotal: number = 0;
        let durationTotal: number = 0;
        const errors: string[] = [];

        for (const serviceId in serviceDurationDict) {
            // find the clientPricing service based on if the package parent or child service is in control
            const clientPricingService = getClientPricingService(
                timesheet.snapShot.rfp.clientPricing.services,
                serviceId
            );

            if (!!clientPricingService) {
                errors.push(`No Client Price data for serviceId - ${serviceId}`);
                continue;
            }

            const currentServiceDuration = serviceDurationDict[serviceId];

            const serviceClientTotal = this.calculateServiceClientTotal(
                currentServiceDuration,
                clientPricingService
            );

            clientTotal += serviceClientTotal;
            durationTotal += currentServiceDuration;
        }

        const materialTotal = calculateClientMaterialsTotal(timesheet);

        return {
            duration: durationTotal,
            total: clientTotal + materialTotal,
            errors: errors
        };
    }

    //#endregion

    //#region Helper Methods
    public getTotalDurationByService(
        timesheet: TimesheetWithoutIds
    ): { [serviceId: string]: number } {
        const serviceDurationDict: { [serviceId: string]: number } = {};
        const packageServices = timesheet.snapShot.rfp.package.services;
        for (const service of timesheet.services) {
            const serviceResult = this.determineServiceAndDuration(service, packageServices);

            if (!serviceDurationDict.hasOwnProperty(serviceResult.serviceId)) {
                serviceDurationDict[serviceResult.serviceId] = 0;
            }

            serviceDurationDict[serviceResult.serviceId] += serviceResult.duration;
        }

        return serviceDurationDict;
    }

    private determineServiceAndDuration(
        timeSheetService: TimesheetServicesWithoutIds,
        packageServices: ServiceWithoutIds[]
    ): { serviceId: string; duration: number } {
        const packageServiceResult = this.getPackageService(
            packageServices,
            timeSheetService.serviceId.toString()
        );

        // we are either dealing with parent or child serviceId based on settings
        const serviceId = packageServiceResult.settings.parentControl
            ? packageServiceResult.parentServiceId
            : packageServiceResult.childServiceId;

        // if the parentControl is true, then we dont want to add the serviceDuration to the total duration.
        const result = {
            serviceId,
            duration: timeSheetService.durationMinutes
        };

        return result;
    }

    private getPackageService(
        packageServices: ServiceWithoutIds[],
        serviceId: string
    ): { settings: ServiceSettings; parentServiceId: string; childServiceId: string } {
        for (const parentService of packageServices) {
            const parentServiceId = parentService._id.toString();
            if (parentServiceId === serviceId) {
                return {
                    settings: parentService.settings,
                    parentServiceId: parentServiceId,
                    childServiceId: parentServiceId
                };
            }
            for (const childService of parentService.children) {
                const childServiceId = childService._id.toString();
                if (childServiceId === serviceId) {
                    return {
                        settings: parentService.settings,
                        parentServiceId: parentServiceId,
                        childServiceId: childServiceId
                    };
                }
            }
        }
    }

    //#endregion
}
