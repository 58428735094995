import { BaseDoc } from '../base/base-doc';
import { LocationContact } from './location-contact';
import { LocationMapping } from './location-mapping';
import { LocationCustom } from './location-custom';
import { LocationRecentWithoutIds } from './location-recent';
import { LocationServiceTypeWithoutIds } from './location-service-type';
import { MongoGeoPoint } from '../base/mongo-geo-point';
import { CompanyIdentityWithoutIds } from '../company/company-identity';
import { LocationIdentityWithoutIds } from './location-identity';
import { UserIdentityWithoutIds } from '../user/user-identity';
import { TagWithoutIds } from '../tag/tag';

export class LocationWithoutIds extends BaseDoc {
    public _id: any;
    public companyIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public identity: LocationIdentityWithoutIds = new LocationIdentityWithoutIds();
    public contact: LocationContact = null;
    public gps: MongoGeoPoint = null;
    public tags: any[] = [];
    public tagsExpanded: TagWithoutIds[] = []; // not stored on document, populated by lookup
    public mapping: LocationMapping = new LocationMapping();
    public custom: LocationCustom = new LocationCustom();
    public serviceTypes: LocationServiceTypeWithoutIds[] = [];
    public recent: LocationRecentWithoutIds = new LocationRecentWithoutIds();
    public facilitiesManagers: UserIdentityWithoutIds[] = [];
    public strategicAccountManagers: UserIdentityWithoutIds[] = [];
    public productionManagers: UserIdentityWithoutIds[] = [];
    public createdByIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();

    constructor(params?: Partial<LocationWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentityWithoutIds(params.companyIdentity)
                : this.companyIdentity;
            this.identity = !!params.identity
                ? new LocationIdentityWithoutIds(params.identity)
                : this.identity;
            this.contact = !!params.contact ? new LocationContact(params.contact) : this.contact;

            this.gps = !!params.gps ? new MongoGeoPoint(params.gps) : this.gps;

            this.mapping = !!params.mapping ? new LocationMapping(params.mapping) : this.mapping;
            this.custom = !!params.custom ? new LocationCustom(params.custom) : this.custom;
            this.serviceTypes = Array.isArray(params.serviceTypes)
                ? params.serviceTypes.map(s => new LocationServiceTypeWithoutIds(s))
                : this.serviceTypes;
            this.recent = !!params.recent
                ? new LocationRecentWithoutIds(params.recent)
                : this.recent;
            this.facilitiesManagers = Array.isArray(params.facilitiesManagers)
                ? params.facilitiesManagers.map(u => new UserIdentityWithoutIds(u))
                : this.facilitiesManagers;
            this.strategicAccountManagers = Array.isArray(params.strategicAccountManagers)
                ? params.strategicAccountManagers.map(u => new UserIdentityWithoutIds(u))
                : this.strategicAccountManagers;
            this.productionManagers = Array.isArray(params.productionManagers)
                ? params.productionManagers.map(u => new UserIdentityWithoutIds(u))
                : this.productionManagers;
            this.tags = params.tags || this.tags;
            this.tagsExpanded = Array.isArray(params.tagsExpanded)
                ? params.tagsExpanded.map(t => new TagWithoutIds(t))
                : this.tagsExpanded;
            this.createdByIdentity = !!params.createdByIdentity
                ? new CompanyIdentityWithoutIds(params.createdByIdentity)
                : this.createdByIdentity;
        }
    }
}
