import { CompanyType, BillingType } from '../enums';

interface ITimesheetDateInfo {
    timesheetStartDate: Date;
    timesheetEndDate: Date;
}

interface ITimesheetDurationInfo {
    dates: ITimesheetDateInfo;
    crewSize: number;
}

/**
 * NOTE: Timesheet and TimesheetSimple can be implicitly cast to the
 * ITimesheetDurationInfo type used on the parameter of this function.
 *
 * @param timesheet An object containing the timesheet start date, end date, and
 *                  crew size.
 * @return Timesheet duration in minutes rounded to the nearest integer.
 */
export function timesheetDurationMinutes(t: ITimesheetDurationInfo): number {
    const millisecondsInMinute = 60000;
    const start = t.dates.timesheetStartDate.getTime();
    const end = t.dates.timesheetEndDate.getTime();
    const durationMinutes = (end - start) / millisecondsInMinute;

    // Need to round minutes count because user inputs are integers
    //
    // Crew size multiplier is applied to integer value to line up with user experience
    return Math.round(durationMinutes) * t.crewSize;
}

export function requireTimeAllocation(
    companyType: CompanyType,
    billingType: BillingType,
    spAllocReqd: boolean
): boolean {
    if (!spAllocReqd && billingType !== BillingType.Hourly) {
        return false
    }
    return companyType === CompanyType.Admin ||
        companyType === CompanyType.Landscaper ||
        billingType === BillingType.Hourly ||
        spAllocReqd;
}
