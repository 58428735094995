import { Component } from '@angular/core';
import { RouterStateService } from './core/state/router';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@ngx-gm2/env';
import { filter, delay } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

declare let gtag: Function;
@Component({
    selector: 'gm2-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(
        private _routerStateService: RouterStateService,
        private _router: Router,
        private _titleService: Title
    ) {
        // Google analytics implementation
        this._router.events
            .pipe(
                filter(_ => environment.production),
                filter(event => event instanceof NavigationEnd),
                delay(0)
            )
            .subscribe((event: NavigationEnd) => {
                // Current page title (richer analytics)
                const title = this._titleService.getTitle();

                gtag('config', 'UA-4210921-3', {
                    page_path: event.urlAfterRedirects,
                    page_title: title
                });
            });

        this._router.initialNavigation();
    }
}
