import { CompanyProfileWithoutIds, CompanyProfileType } from '@gm2/common';
import { BaseMediaDocument } from '../media/BaseMediaDocument';

export class CompanyProfile extends CompanyProfileWithoutIds {
    public id: string = '';
    public logo: BaseMediaDocument = null;
    public companyProfileType: CompanyProfileType = null;

    constructor(params?: Partial<CompanyProfile>) {
        super(params);
        if (!!params) {
            this.id = params.id || this.id;
            this.logo = !!params.logo ? new BaseMediaDocument(params.logo) : this.logo;
            this.companyProfileType = params.companyProfileType || this.companyProfileType;
        }
    }
}
