export enum CompanyType {
    Admin = '[CompanyType] Admin',
    Client = '[CompanyType] Client',
    Service_Partner = '[CompanyType] Service Partner',
    Landscaper = '[CompanyType] Landscaper',
    SuperAdmin = '[CompanyType] Super Admin'
}

export namespace CompanyType {
    export function toString(type: CompanyType): string {
        switch (type) {
            case CompanyType.Admin:
                return 'Admin';
            case CompanyType.Client:
                return 'Client';
            case CompanyType.Service_Partner:
                return 'Service Partner';
            case CompanyType.Landscaper:
                return 'Landscaper';
            case CompanyType.SuperAdmin:
                return 'SuperAdmin'
            default:
                return '';
        }
    }

    export const members: CompanyType[] = [
        CompanyType.Admin,
        CompanyType.Client,
        CompanyType.Service_Partner,
        CompanyType.Landscaper,
    ];
}
