/// <reference types="googlemaps" />

import { IPoly } from './IPoly';
import { IOverlay } from './IOverlay';
import { LatLngLiteral } from '@google/maps';
import { MappingType } from '@gm2/common';
import { IIdentifiable } from '../IIdentifiable';

/**
 * I wrote the following comment in 2015 when I rewrote this the first time and
 * I still don't know what these constants are.
 *
 * I have no idea what these numbers represent and why they are multiplied by
 * the value returned by Google's area compute functions but it's there in the
 * original mapping so I have to include them otherwise the area calculations
 * will be incorrect and significantly smaller now.
 */
const RANDOM_MAGIC_AREA_COMPUTE_MULTIPLIER: number = 10.76; // 1 meter ^2 has 10.76 foot^2

/**
 * Another random magic number from the first iteration of the SEP.
 */
export const RANDOM_MAGIC_LINE_AREA_COMPUTE_MULTIPLIER: number = 0.0254; // meters in 1 inch.

export class Polyline implements Partial<IIdentifiable>, IPoly, IOverlay {
    public _id?: string;
    public overlay: google.maps.Polyline;
    public type: MappingType;
    public width: number;

    /**
     *
     * @param overlay
     * @param width in inch
     * @param _id
     */
    public constructor(overlay: google.maps.Polyline, width: number = 60, _id?: string) {
        this.overlay = overlay;
        this.type = MappingType.Line;
        this.width = width;
        this._id = _id;
    }

    public get area(): number {
        const path = google.maps.geometry.spherical.computeLength(this.overlay.getPath()) //in meters
        return path
            * RANDOM_MAGIC_AREA_COMPUTE_MULTIPLIER
            * RANDOM_MAGIC_LINE_AREA_COMPUTE_MULTIPLIER
            * this.width; //inches
    }

    public get path(): LatLngLiteral[] {
        return this.overlay
            .getPath()
            .getArray()
            .map(point => point.toJSON());
    }

    public set path(value: LatLngLiteral[]) {
        this.overlay.setPath(value);
    }
}
