export enum WorkOrderInvoiceStatus {
    InProgress = '[WorkOrderInvoiceStatus] InProgress',
    Submitted = '[WorkOrderInvoiceStatus] Submitted',
    Approved = '[WorkOrderInvoiceStatus] Approved',
    Rejected = '[WorkOrderInvoiceStatus] Rejected',
    Posted = '[WorkOrderInvoiceStatus] Posted',
    PaymentProcessed = '[WorkOrderInvoiceStatus] PaymentProcessed'
}

export namespace WorkOrderInvoiceStatus {
    export function toString(type: WorkOrderInvoiceStatus): string {
        switch (type) {
            case WorkOrderInvoiceStatus.InProgress:
                return 'Incomplete';
            case WorkOrderInvoiceStatus.Submitted:
                return 'Submitted';
            case WorkOrderInvoiceStatus.Approved:
                return 'Approved';
            case WorkOrderInvoiceStatus.Rejected:
                return 'Declined';
            case WorkOrderInvoiceStatus.Posted:
                return 'Posted';
            case WorkOrderInvoiceStatus.PaymentProcessed:
                return 'Payment Processed';
            default:
                return '';
        }
    }

    export function toCssColor(type: WorkOrderInvoiceStatus): string {
        // These colors are taken from ACE theme used in old site taking into
        // account our customizations
        switch (type) {
            case WorkOrderInvoiceStatus.InProgress:
                return '#333333';
            case WorkOrderInvoiceStatus.Submitted:
                return '#FEE188';
            case WorkOrderInvoiceStatus.Approved:
                return '#3A87AD';
            case WorkOrderInvoiceStatus.Rejected:
                return '#D15B47';
            case WorkOrderInvoiceStatus.Posted:
                return '#9585BF';
            case WorkOrderInvoiceStatus.PaymentProcessed:
                return '#82AF6F';
            default:
                return '#919191';
        }
    }

    export const members: WorkOrderInvoiceStatus[] = [
        WorkOrderInvoiceStatus.InProgress,
        WorkOrderInvoiceStatus.Submitted,
        WorkOrderInvoiceStatus.Approved,
        WorkOrderInvoiceStatus.Rejected,
        WorkOrderInvoiceStatus.Posted,
        WorkOrderInvoiceStatus.PaymentProcessed
    ];

    export function toIconSource(s: WorkOrderInvoiceStatus): string {
        switch (s) {
            case WorkOrderInvoiceStatus.InProgress:
                return 'assets/invoice/invoice-inProgress.png';
            case WorkOrderInvoiceStatus.Submitted:
                return 'assets/invoice/invoice-submitted.png';
            case WorkOrderInvoiceStatus.Approved:
                return 'assets/invoice/invoice-approved.png';
            case WorkOrderInvoiceStatus.Rejected:
                return 'assets/invoice/invoice-declined.png';
            case WorkOrderInvoiceStatus.Posted:
                return 'assets/invoice/invoice-posted.png';
            case WorkOrderInvoiceStatus.PaymentProcessed:
                return 'assets/invoice/invoice-paymentProcessed.png';
            default:
                return '';
        }
    }
}
