import { BaseDoc } from '../base/base-doc';

export class PackageWithoutIds extends BaseDoc {
    public _id: any;
    public name: string;
    public description: string;
    public serviceTypeId: any;
    public serviceIds: any[] = [];
    public contractName: string;
    constructor(params?: Partial<PackageWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.description = params.description || this.description;
            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId;
            this.serviceIds = Array.isArray(params.serviceIds)
                ? params.serviceIds
                : this.serviceIds;
            this.contractName = params.contractName ?? this.contractName;
        }
    }
}
