import { WorkOrderStatus, WorkOrderActiveStatus, WorkOrderNotification } from '@gm2/common';

export class WorkOrderStatusChangeDto {
    public workOrderStatus: WorkOrderStatus = null;
    public activeStatus: WorkOrderActiveStatus = null;
    public completionDate: Date = null;
    public message: string = '';
    public notification: WorkOrderNotification = null;

    constructor(params?: Partial<WorkOrderStatusChangeDto>) {
        if (!!params) {
            this.workOrderStatus = params.workOrderStatus || this.workOrderStatus;
            this.activeStatus = params.activeStatus || this.activeStatus;
            this.completionDate = !!params.completionDate
                ? new Date(params.completionDate)
                : this.completionDate;
            this.message = params.message || this.message;
            this.notification = !!params.notification
                ? new WorkOrderNotification(params.notification)
                : this.notification;
        }
    }
}
