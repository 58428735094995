export enum MaterialType {
    RockSalt = '[MaterialType] RockSalt',
    Magnesium = '[MaterialType] Magnesium',
    Calcium = '[MaterialType] Calcium',
    Sand = '[MaterialType] Sand',
}

export namespace MaterialType {
    export function toString(type: MaterialType): string {
        switch (type) {
            case MaterialType.RockSalt:
                return 'Rock Salt';
            case MaterialType.Magnesium:
                return 'Magnesium';
            case MaterialType.Calcium:
                return 'Calcium';
            case MaterialType.Sand:
                return 'Sand';
            default:
                return '';
        }
    }

    export const members: MaterialType[] = [
        MaterialType.RockSalt,
        MaterialType.Magnesium,
        MaterialType.Calcium,
        MaterialType.Sand,
    ];
}
