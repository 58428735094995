import { AutoInvoiceDay, AutoInvoiceType } from '../../enums';

export class AutoInvoiceSettings {
    public enabled: boolean = false;
    public day: AutoInvoiceDay = null;
    public type: AutoInvoiceType = null;
    public email: boolean = false;

    constructor(params?: Partial<AutoInvoiceSettings>) {
        if (!!params) {
            this.enabled = params.enabled || this.enabled;
            this.day = params.day || this.day;
            this.type = params.type || this.type;
            this.email = params.email || this.email;
        }
    }
}
