import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { CompanyActions, ComplexState, UserActions } from '@gm2/ui-state';
import { Observable, of } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    @Select(ComplexState.authenticated)
    public _authenticated: Observable<boolean>;

    constructor(
        private router: Router,
        private store$: Store,
        ) {}

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._authenticated.pipe(
            take(1),
            switchMap(authenticated => {
                if (!authenticated) {
                    this.router.navigate(['/auth/login'], { queryParams: { redirect: state.url } });
                    return of(authenticated);
                } else {
                    return of(authenticated)
                        .pipe(
                            switchMap(() => this.store$.dispatch(new CompanyActions.GetLoggedInCompany())),
                            switchMap(() => this.store$.dispatch(new UserActions.GetMyUser())),
                            map(() => authenticated)
                        );
                }
            }),
        );
    }
}
