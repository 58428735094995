import { TimesheetValidationStatus } from '@gm2/common';

export class TimesheetValidateDto {
    public status: TimesheetValidationStatus;
    public comment: string;

    constructor(status: TimesheetValidationStatus, comment: string) {
        this.status = status;
        this.comment = comment;
    }
}
