import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input
} from '@angular/core';
import { RfxGridDataSource } from '../GridDataSource';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { Pagination } from '@refactor/common';

interface PaginationState {
    count: number;
    page: number;
    limit: number;
    totalPages: number;
    pageSizeOptions: Array<number>;
    viewing: [number, number];
}

@Component({
    selector: 'rfx-grid-paginator',
    templateUrl: './grid-paginator.component.html',
    styleUrls: ['./grid-paginator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridPaginatorComponent<T> implements OnInit {
    @Input()
    public firstPageIcon: string | [string, string] = [
        'far',
        'chevron-double-left'
    ];
    @Input()
    public previousPageIcon: string | [string, string] = [
        'far',
        'chevron-left'
    ];
    @Input()
    public nextPageIcon: string | [string, string] = ['far', 'chevron-right'];
    @Input()
    public lastPageIcon: string | [string, string] = [
        'far',
        'chevron-double-right'
    ];

    /**
     * The count limit. When the count value is greater than this value, the
     * count will display as "x+" where x is the limit.  This indicates that
     * there are more documents but they aren't worth counting.
     *
     * When querying large resultsets, the count operation can end up getting
     * quite slow and resource intensive. To mitigate this you can limit how
     * many documents get counted to some value to where addtional documents
     * are meaningless (usually due to sheer number).
     */
    @Input()
    public countUpperBound?: number = undefined;

    public dataSource: RfxGridDataSource<T>;
    public pageLimitControl: UntypedFormControl;
    public paginationState$: Observable<PaginationState>;

    constructor() {}

    ngOnInit(): void {
        if (!!this.dataSource) {
            this.pageLimitControl = new UntypedFormControl(
                !!this.dataSource.pagination
                    ? this.dataSource.pagination.limit
                    : null
            );
            this.paginationState$ = this.dataSource._state$.pipe(
                map(state => {
                    const totalPages = Math.ceil(
                        state.count / state.pagination.limit
                    );
                    const startCount = Math.max(
                        1,
                        state.pagination.limit * state.pagination.page -
                            state.pagination.limit
                    );
                    const endCount = Math.min(
                        state.pagination.limit * state.pagination.page,
                        state.count
                    );
                    const viewing: [number, number] = [startCount, endCount];
                    return {
                        count: state.count,
                        page: state.pagination.page,
                        limit: state.pagination.limit,
                        totalPages: totalPages,
                        pageSizeOptions: state.pageSizeOptions,
                        viewing: viewing
                    };
                }),
                distinctUntilChanged()
            );
        }
    }

    public get canGoBack(): boolean {
        return this.dataSource.pagination.page > 1;
    }

    public get canGoForward(): boolean {
        return (
            this.dataSource.count >
            this.dataSource.pagination.page * this.dataSource.pagination.limit
        );
    }

    public changePageSize(size: number): void {
        this.dataSource.pagination = new Pagination({
            page: this.dataSource.pagination.page,
            limit: size
        });
    }

    public nextPage(): void {
        if (this.canGoForward) {
            this.dataSource.pagination = new Pagination({
                page: this.dataSource.pagination.page + 1,
                limit: this.dataSource.pagination.limit
            });
        }
    }

    public previousPage(): void {
        if (this.canGoBack) {
            this.dataSource.pagination = new Pagination({
                page: this.dataSource.pagination.page - 1,
                limit: this.dataSource.pagination.limit
            });
        }
    }

    public firstPage(): void {
        if (this.canGoBack) {
            this.dataSource.pagination = new Pagination({
                page: 1,
                limit: this.dataSource.pagination.limit
            });
        }
    }

    public lastPage(): void {
        if (this.canGoForward) {
            const lastPage = Math.ceil(
                this.dataSource.count / this.dataSource.pagination.limit
            );
            this.dataSource.pagination = new Pagination({
                page: lastPage,
                limit: this.dataSource.pagination.limit
            });
        }
    }

    public displayCount(state: PaginationState): string {
        if (typeof this.countUpperBound !== 'undefined' && state.count > this.countUpperBound) {
            return `${this.countUpperBound}+`;
        } else {
            // This should be toLocaleString if we want to support additional
            // localizations (at least up to the user's browser's support of
            // them).
            return state.count.toString();
        }
    }
}
