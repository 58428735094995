import {
    HttpRequest,
    HttpResponse,
    HttpParams,
    HttpHeaders
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HttpService } from '../services/http.service';
import { ResponseType } from '../models/ResponseType';
import { ParamDecoratorType } from '../models/ParamDecoratorType';
import { RequestType } from '../models/RequestType';
import { map } from 'rxjs/operators';

/**
 * Creates a POST request when attached to a function in a class that
 * extends the [HttpService]{@link HttpService}.
 *
 * The decorator also takes an optional second paramter to provide additional
 * options to the request. See [RequestOptions]{@link RequestOptions}
 *
 * ```Typescript
 * @Post('/some/url')
 * public myPostRequest(): Observable<any> {
 *      return null; // All logic is contained in the @Post decorator
 * }
 *
 * @Post('/some/url', { responseType: ResponseType.Blob })
 * public myPostRequest(): Observable<any> {
 *      return null; // All logic is contained in the @Post decorator
 * }
 * ```
 */
export const Post = requestBuilder(RequestType.Post);

/**
 * Creates a GET request when attached to a function in a class that
 * extends the [HttpService]{@link HttpService}.
 *
 * The decorator also takes an optional second paramter to provide additional
 * options to the request. See [RequestOptions]{@link RequestOptions}
 *
 * ```Typescript
 * @Get('/some/url')
 * public myGetRequest(): Observable<any> {
 *      return null; // All logic is contained in the @Get decorator
 * }
 *
 * @Get('/some/url', { responseType: ResponseType.Text })
 * public myGetRequest(): Observable<any> {
 *      return null; // All logic is contained in the @Post decorator
 * }
 */
export const Get = requestBuilder(RequestType.Get);

/**
 * Creates a PUT request when attached to a function in a class that
 * extends the [HttpService]{@link HttpService}.
 *
 * The decorator also takes an optional second paramter to provide additional
 * options to the request. See [RequestOptions]{@link RequestOptions}
 *
 * ```Typescript
 * @Put('/some/url')
 * public myPutRequest(): Observable<any> {
 *      return null; // All logic is contained in the @Put decorator
 * }
 *
 * @Put('/some/url', { responseType: ResponseType.Blob })
 * public myPutRequest(): Observable<any> {
 *      return null; // All logic is contained in the @Post decorator
 * }
 */
export const Put = requestBuilder(RequestType.Put);

/**
 * Creates a PATCH request when attached to a function in a class that
 * extends the [HttpService]{@link HttpService}.
 *
 * The decorator also takes an optional second paramter to provide additional
 * options to the request. See [RequestOptions]{@link RequestOptions}
 *
 * ```Typescript
 * @Patch('/some/url')
 * public myPatchRequest(): Observable<any> {
 *      return null; // All logic is contained in the @Patch decorator
 * }
 *
 * @Patch('/some/url', { responseType: ResponseType.Blob })
 * public myPatchRequest(): Observable<any> {
 *      return null; // All logic is contained in the @Post decorator
 * }
 */
export const Patch = requestBuilder(RequestType.Patch);

/**
 * Creates a DELETE request when attached to a function in a class that
 * extends the [HttpService]{@link HttpService}.
 *
 * The decorator also takes an optional second paramter to provide additional
 * options to the request. See [RequestOptions]{@link RequestOptions}
 *
 * ```Typescript
 * @Delete('/some/url')
 * public myDeleteRequest(): Observable<any> {
 *      return null; // All logic is contained in the @Delete decorator
 * }
 *
 * @Delete('/some/url', { responseType: ResponseType.Text })
 * public myDeleteRequest(): Observable<any> {
 *      return null; // All logic is contained in the @Post decorator
 * }
 */
export const Delete = requestBuilder(RequestType.Delete);

/**
 * Creates a fake HTTPResponse object that gets returned from the method.
 * Used for creating fake responses when a backend api is not yet ready for api
 * calls but still allows the application to function normally.
 *
 * ```Typescript
 * @FakeResponse('test')
 * @Get('/some/url')
 * public myRequest(): Observable<any> {
 *      return null;
 * }
 *
 * @param fakeResponse Response data to return
 */
export const FakeResponse = <T = any>(
    fakeResponse: T,
    options?: Partial<{
        disabled: boolean;
    }>
): Function => (
    target: HttpService,
    propertyKey: string,
    descriptor: any
): void => {
    const opts = {
        disabled: false,
        ...options
    };
    if (opts.disabled !== true) {
        const mKey = `${propertyKey}_FAKE_response`;
        target[mKey] = fakeResponse;
    }
};

/**
 * @ignore
 * Used for internal testing purposes only
 */
export const InvalidDecorator = requestBuilder(null);

/**
 * The different options available for the optional second paramter of the
 * various request decorators
 */
interface RequestOptions {
    /**
     * The expected response type for the request.
     */
    responseType: ResponseType;
    /**
     * Any custom headers for this request that do not use the Header decorator
     */
    customHeaders: { [key: string]: string };
}

/**
 * The default values used when the user does not specify all of the options
 * available when using a request decorator
 */
const defaultRequestOptions: RequestOptions = {
    responseType: ResponseType.Json,
    customHeaders: {}
};

/**
 * @ignore
 *
 * Sanitize the input of a given variable.
 * Used for restricting the input data to a string or not
 *
 * @param val value to sanitize
 * @param allowJSON should allow json
 * @returns sanitized value
 */
function sanitizeValue(val: any, allowJSON?: boolean): any {
    if (typeof val !== 'undefined' && !allowJSON) {
        if (typeof val === 'string') {
            return `${val}`.trim();
        } else if (val instanceof Date) {
            return val.toISOString();
        } else {
            return JSON.stringify(val);
        }
    } else {
        return val;
    }
}

/**
 * @ignore
 *
 * Generates a decorator for a given request type
 *
 * @param type The type of request to build a decorator for
 * @returns decorator
 */
function requestBuilder(
    type: RequestType
): (url: string, options?: Partial<RequestOptions>) => Function {
    return (url: string, options: RequestOptions): Function => (
        target: HttpService,
        propertyKey: string,
        descriptor: any
    ): void => {
        const mergedOptions: RequestOptions = {
            ...defaultRequestOptions,
            ...options
        };
        descriptor.value = function(
            ...args: any[]
        ): Observable<HttpResponse<any>> {
            const rPath =
                target[`${propertyKey}_${ParamDecoratorType.Path}_parameters`];
            const rBody =
                target[`${propertyKey}_${ParamDecoratorType.Body}_parameters`];
            const rQuery =
                target[`${propertyKey}_${ParamDecoratorType.Query}_parameters`];
            const rHeader =
                target[
                    `${propertyKey}_${ParamDecoratorType.Header}_parameters`
                ];
            const fakeResponse = target[`${propertyKey}_FAKE_response`];

            // Path
            let newUrl = `${url}`;
            if (Array.isArray(rPath)) {
                for (const p of rPath) {
                    newUrl = newUrl.replace(
                        new RegExp(`:${p['key']}`, 'gi'),
                        sanitizeValue(args[p['parameterIndex']])
                    );
                }
            }

            // Body
            let body = {};
            if (Array.isArray(rBody)) {
                if (rBody.findIndex(p => p.key === '') > -1) {
                    const p = rBody.find(param => param.key === '');
                    body = sanitizeValue(args[p['parameterIndex']], true);
                }
                for (const p of rBody) {
                    if (p['key'] !== '') {
                        body[p['key']] = sanitizeValue(
                            args[p['parameterIndex']],
                            true
                        );
                    }
                }
            }

            // Query Params
            let params = new HttpParams();
            if (Array.isArray(rQuery)) {
                for (const q of rQuery) {
                    if (typeof args[q['parameterIndex']] !== 'undefined') {
                        params = params.append(
                            q['key'],
                            sanitizeValue(args[q['parameterIndex']])
                        );
                    }
                }
            }

            let request: HttpRequest<any>;
            let headers: HttpHeaders = new HttpHeaders();
            if (
                !!mergedOptions.customHeaders &&
                Object.keys(mergedOptions.customHeaders).length > 0
            ) {
                for (const key in mergedOptions.customHeaders) {
                    if (!!key && !!mergedOptions.customHeaders[key]) {
                        headers = headers.set(
                            key,
                            mergedOptions.customHeaders[key]
                        );
                    }
                }
            }
            if (!!rHeader) {
                for (const h of rHeader) {
                    if (typeof args[h['parameterIndex']] !== 'undefined') {
                        headers = headers.set(
                            [h['key']][0],
                            sanitizeValue(args[h['parameterIndex']])
                        );
                    }
                }
            }

            if (
                [RequestType.Post, RequestType.Patch, RequestType.Put].indexOf(
                    type
                ) > -1
            ) {
                request = new HttpRequest<any>(type, newUrl, body, {
                    responseType: mergedOptions.responseType,
                    headers: headers
                });
            } else if (
                [RequestType.Get, RequestType.Delete].indexOf(type) > -1
            ) {
                request = new HttpRequest<any>(type, newUrl, {
                    params: params,
                    responseType: mergedOptions.responseType,
                    headers: headers
                });
            } else {
                throw new Error(
                    `@rfx/ngx-http Invalid request decorator type: ${type}`
                );
            }

            let observ: Observable<HttpResponse<any>> = !!fakeResponse
                ? of(
                      new HttpResponse({
                          body: fakeResponse,
                          status: 200
                      })
                  )
                : this._http.request(request);

            observ = this.responseInterceptor(observ, descriptor.adapters);
            return observ;
        };
    };
}
