export enum TimesheetStatus {
    InProgress = '[TimesheetStatus] InProgress',
    Completed = '[TimesheetStatus] Completed',
    Invoiced = '[TimesheetStatus] Invoiced',

    // This status corresponds to timesheets that aren't valid due to some
    // issue from either frontend (mobile or desktop). It is accepted so that
    // it doesn't get stuck on the device but must be manually reviewed and
    // corrected before it is put into circulation.
    NeedsReview = '[TimesheetStatus] NeedsReview'
}

export namespace TimesheetStatus {
    export function toString(status: TimesheetStatus): string {
        switch (status) {
            case TimesheetStatus.InProgress:
                return 'In Progress';
            case TimesheetStatus.Completed:
                return 'Completed';
            case TimesheetStatus.Invoiced:
                return 'Invoiced';
            case TimesheetStatus.NeedsReview:
                return 'Needs Review';
            default:
                return '';
        }
    }

    export function toServiceActivityString(status: TimesheetStatus): string {
        switch (status) {
            case TimesheetStatus.InProgress:
                return TimesheetStatus.toString(TimesheetStatus.InProgress);
            case TimesheetStatus.Completed:
            case TimesheetStatus.Invoiced:
            case TimesheetStatus.NeedsReview:
                return TimesheetStatus.toString(TimesheetStatus.Completed);
            default:
                return '';
        }
    }

    export const members: TimesheetStatus[] = [
        TimesheetStatus.InProgress,
        TimesheetStatus.Completed,
        TimesheetStatus.Invoiced,
        TimesheetStatus.NeedsReview
    ];
}
