export * from './lib/ui-common.module';
export * from './lib/core/enums';
export * from './lib/core/models';
export * from './lib/core/services';
export * from './lib/core/validators';
export * from './lib/core/utils';

// Enum Access needed in views
// export { DateRangeModalComponent } from './lib/core/modals';

// Re-Exports from common
export {
    timezones,
    // Classes
    UserCustomFields,
    UserNotificationSettings,
    UserSettings,
    Address,
    AutoInvoiceSettings,
    CompanySettings,
    isSnowServiceTypeId,
    isLandscapeServiceTypeId,
    TimesheetDates,
    ITokenPayload,
    ServiceSettings,
    LocationOrigin,
    ServiceActivityTimeFrame,
    TimezoneAwareDateTimeBucket,
    FormField,
    AppDefinedField,
    RfpPhase,
    WorkOrderStatusFilter,
    WorkOrderActiveStatusFilter,
    WorkOrderMultiGridData,
    WorkOrderTotals,
    PaginatedListWithCountAndValue,
    isEmpty,
    hasPermission,
    MaterialCalculationResult,
    ServicePartnerCompliant,
    // Utils
    requireTimeAllocation
} from '@gm2/common';

//#region Re-export services from common
export {
    MaterialCalculationService,
    AreaCalculationService,
    canEditInvoice,
    canDeleteInvoice,
    getInvoiceAvailableTimesheetMaxStartDate,
    calculateInvoiceTotals,
    calculateInvoiceMonthlyTotals,
    calculateInvoiceNonMonthlyTotals,
    calcPhaseMaterialItemTotal,
    calcPhaseEquipmentItemTotal,
    calcPhaseLaborItemTotal,
    calcPhaseMaterialTotal,
    calcPhaseEquipmentTotal,
    calcPhaseLaborTotal,
    calcPhaseSalesTaxTotal,
    calcPhaseTotal,
    calcWorkOrderMaterialTotal,
    calcWorkOrderEquipmentTotal,
    calcWorkOrderLaborTotal,
    calcClientWorkOrderMaterialTotal,
    calcClientWorkOrderEquipmentTotal,
    calcClientWorkOrderLaborTotal,
    calcClientWorkOrderSalesTaxTotal,
    calcWorkOrderClientMarkUp,
    calcWorkOrderClientPretaxPrice,
    calcWorkOrderClientPrice,
    calcWorkOrderOgmTotal,
    calcWorkOrderGrossProfit,
    calcWorkOrderGrossProfitPercent,
    calcWorkOrderDaysInCurrentStatus,
    calcInternalPhaseLaborItemTotal,
    calcInternalPhaseEquipmentItemTotal,
    calcInternalPhaseMaterialItemTotal,
    calcInternalPhaseEquipmentTotal,
    calcInternalPhaseMaterialTotal,
    calcInternalPhaseLaborTotal,
    calcInternalPhaseTotal,
    zeroOutWorkOrderSalesTax,
    zeroOutWorkOrderClientPrice,
    canSetWorkOrderStatusCreated,
    canSetWorkOrderStatusPending,
    canSetWorkOrderStatusReview,
    canSetWorkOrderStatusApproved,
    canSetWorkOrderStatusRejected,
    canSetWorkOrderStatusServicePartnerRejected,
    canSetWorkOrderStatusClientRejected,
    canSetWorkOrderStatusCompleted,
    canSetWorkOrderStatusPosted,
    canSetWorkOrderStatusPaymentProcessed,
    canSetWorkOrderActiveStatusActive,
    canSetWorkOrderActiveStatusHold,
    canSetWorkOrderActiveStatusInactive,
    canSetWorkOrderPhaseStatus,
    timesheetDurationMinutes,
    canDeleteWorkOrderInvoice,
    canEditWorkOrderInvoice,
    getWorkOrderInvoiceAvailableWorkOrderMaxStartDate,
    calculateWorkOrderInvoiceTotals
} from '@gm2/common';
//#endregion

//#region Re-Export enums from common
export {
    Status,
    State,
    ApproveType,
    CompanyProfileType,
    CompanyRating,
    CompanyTier,
    CompanyType,
    CompanyStatus,
    ContactType,
    CompanyServiceArea,
    QuestionnaireDocumentType,
    InsuranceStatus,
    ContractStatus,
    AssigneeType,
    BillingType,
    AutoInvoiceDay,
    AutoInvoiceType,
    InvoiceStatus,
    InvoiceRejectionReason,
    LocationMappingApproveType,
    MappingType,
    MaterialMeasurementType,
    ReportType,
    MonthType,
    MaterialOptions,
    WorkOrderBillingTypeReport,
    YearType,
    CrewType,
    AllServiceType,
    ServiceSource,
    MaterialActiveIngredient,
    MaterialExceededRatio,
    MediaDocumentType,
    MediaCollectionName,
    NoteType,
    NoteCollectionName,
    Permission,
    TagColor,
    GroundApplicationType,
    SiteConditionType,
    WeatherConditionType,
    DisplayedTimezone,
    LoginType,
    ServiceClassification,
    ServiceTimesheetField,
    ServiceTypeSubClass,
    MappingClassificationArea,
    MappingClassification,
    ServiceMappingCategory,
    Month,
    TimesheetStatus,
    TimesheetValidationStatus,
    LocationServiceInterval,
    LocationDayOfService,
    WorkOrderActiveStatus,
    WorkOrderBillingType,
    WorkOrderPriority,
    WorkOrderStatus,
    WorkOrderWorkCategory,
    WorkOrderReportedBy,
    WorkOrderGMR,
    WorkOrderPhaseType,
    WorkOrderEquipmentUnit,
    WorkOrderPhaseStatus,
    WorkOrderLaborCostType,
    WorkOrderInvoiceStatus,
    WorkOrderLaborSelectedRate,
    InvoiceMonthlyType,
    FieldType,
    InputType,
    ConditionalRuleOperator,
    ConditionalActionType,
    ConditionalLogicType,
    NotificationStatus,
    MappingLocationDatePair
} from '@gm2/common'; //#endregion
