import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseMediaDocumentWithUrl } from '@gm2/ui-common';

@Component({
    selector: 'gm2-image-carousel-modal',
    templateUrl: './image-carousel-modal.component.html',
    styleUrls: ['./image-carousel-modal.component.scss']
})
export class ImageCarouselModalComponent implements OnInit {
    public readonly slides: BaseMediaDocumentWithUrl[];

    constructor(
        @Inject(MAT_DIALOG_DATA)
        data: {
            images: BaseMediaDocumentWithUrl[],
            index: number;
        }
    ) {
        this.slides = data.images.slice(data.index).concat(data.images.slice(0, data.index));
    }

    ngOnInit(): void {}
}
