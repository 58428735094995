export enum TimesheetType {
    Rfp = '[TimesheetType] Rfp',
    Form = '[TimesheetType] Form'
}

export namespace TimesheetType {
    export function toString(status: TimesheetType): string {
        switch (status) {
            case TimesheetType.Rfp:
                return 'Rfp';
            case TimesheetType.Form:
                return 'Form';
            default:
                return '';
        }
    }

    export const members: TimesheetType[] = [TimesheetType.Rfp, TimesheetType.Form];
}
