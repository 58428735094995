import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ServiceChild, ServiceChildrenDto, ServiceService, ToastService } from '@gm2/ui-common';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

interface IDialogData {
    serviceId: string;
    serviceChild: ServiceChild;
}

@Component({
    selector: 'gm2-child-service-modal',
    templateUrl: './child-service-modal.component.html',
    styleUrls: ['./child-service-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChildServiceModalComponent implements OnInit {

    public serviceId: string = '';
    public serviceChild: ServiceChildrenDto = null;

    public isSubmitting$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    public form: UntypedFormGroup = this._fb.group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        hasMaterial: [null],
        hasClientFee: [null]
    });

    constructor(
        private _fb: UntypedFormBuilder,
        private _serviceService: ServiceService,
        private _toastService: ToastService,
        public dialogRef: MatDialogRef<ChildServiceModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData
    ) {
        this.serviceId = !!data.serviceId
            ? data.serviceId
            : this.serviceId;
        this.serviceChild = !!data.serviceChild
            ? new ServiceChildrenDto({
                _id: data.serviceChild._id,
                name: data.serviceChild.name,
                description: data.serviceChild.description,
                hasMaterial: data.serviceChild.settings.hasMaterial,
                hasClientFee: data.serviceChild.settings.hasClientFee
            })
            : this.serviceChild;
    }

    ngOnInit(): void {
        if (!!this.serviceChild) {
            this.form.patchValue(this.serviceChild);
        }
    }

    public close(): void {
        this.dialogRef.close(null);
    }

    public submit(): void {
        if (this.form.invalid) {
            this._toastService.error('Please check form for errors');
            return;
        }

        const dto = new ServiceChildrenDto(this.form.value);
        const serviceChildId = !!this.serviceChild ? this.serviceChild._id : null;
        dto._id = serviceChildId;

        if (!!this.serviceId) {
            // adding or editing child service for existing service
            this.isSubmitting$.next(true);
            if (serviceChildId) {
                this._serviceService
                    .editChildService(this.serviceId, serviceChildId, dto)
                    .pipe(finalize(() => this.isSubmitting$.next(false)))
                    .subscribe(
                        () => {
                            this._toastService.success('Saved child service');
                            this.dialogRef.close(dto.toServiceChild());
                        },
                        error => {
                            this._toastService.error(error.error);
                        }
                    );
            } else {
                this._serviceService
                    .createChildService(this.serviceId, dto)
                    .pipe(finalize(() => this.isSubmitting$.next(false)))
                    .subscribe(
                        newChildId => {
                            this._toastService.success('Created child service');
                            const child = dto.toServiceChild();
                            child._id = newChildId;
                            this.dialogRef.close(child);
                        },
                        error => {
                            this._toastService.error(error.error);
                        }
                    );
            }

        } else {
            // appending a service child to a yet-to-be-created service
            this.dialogRef.close(dto.toServiceChild());
        }
    }
}
