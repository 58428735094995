// import { ReplaySubject, Observable, Subscription } from 'rxjs';
// import { SelectionModel } from '@angular/cdk/collections';
// import {
//     startWith,
//     scan,
//     shareReplay,
//     take,
//     pluck,
//     distinctUntilChanged,
//     map
// } from 'rxjs/operators';
// import { TrackByFunction } from '@angular/core';
// import { HttpService } from '@refactor/ngx/http';
// import { GridParams, GridPaginationParams, GridSortParams } from '@gm2/ui-common';
// import { FormGroup } from '@angular/forms';
// import _isEqual from 'lodash/isEqual';
// import { SortDirection } from '@refactor/common';

// interface GridState<T> {
//     count: number;
//     initData: Array<T>;
//     data: Array<T>;
//     selected: Array<T>;
//     multi: boolean;
//     selectionEnabled: boolean;
//     filters: FormGroup;
//     pagination: GridPaginationParams;
//     pageSizeOptions: Array<number>;
//     sort: GridSortParams;
//     loading: boolean;
//     detailViewItem: T;
//     detailViewOpen: boolean;
//     sticky: boolean;
//     lastToggledItem: T;
// }

// type QueryFn<T extends HttpService = HttpService> = [T, string];

// interface GridDataSourceStateParam<T, K = { [key: string]: any }> {
//     multi: boolean;
//     selectionEnabled: boolean;
//     selected: T | Array<T>;
//     trackBy: TrackByFunction<T>;
//     queryFn: QueryFn;
//     pagination: GridPagination;
//     pageSizeOptions: Array<number>;
//     sort: GridSort;
//     filters: K;
//     detailView: {
//         open: boolean;
//         item: T;
//     };
//     sticky: boolean;
//     gridId: string;
// }

// interface GridPagination {
//     page: number;
//     limit: number;
// }

// interface GridSort {
//     name: string;
//     direction: SortDirection;
// }

export class RfxGridDataSource<T, K = { [key: string]: any }> {
    //     private _defaultState: GridState<T> = {
    //         count: 0,
    //         initData: [],
    //         data: [],
    //         selected: [],
    //         multi: true,
    //         selectionEnabled: true,
    //         filters: new FormGroup({}),
    //         // No magic numbers disabled to set pagination default to 15
    //         // tslint:disable-next-line: no-magic-numbers
    //         pagination: new GridPaginationParams(1, 15),
    //         // No magic numbers disabled for array init
    //         // tslint:disable-next-line: no-magic-numbers
    //         pageSizeOptions: [5, 10, 15, 20, 25],
    //         sort: new GridSortParams(undefined, undefined),
    //         loading: false,
    //         detailViewItem: null,
    //         detailViewOpen: false,
    //         sticky: false,
    //         lastToggledItem: null
    //     };
    //     // prettier-ignore
    //     private _state: ReplaySubject<Partial<GridState<T>>>
    //         = new ReplaySubject<Partial<GridState<T>>>();
    //     private _queryRequest: Subscription;
    //     /**
    //      * @ignore
    //      *
    //      * Used for internal implementations
    //      */
    //     public _state$: Observable<GridState<T>>;
    //     public _selection: SelectionModel<T>;
    //     public _initalFilterValue: K;
    //     /**
    //      * Stream of data. Emits a new value each time the data array changes
    //      */
    //     public data$: Observable<Array<T>>;
    //     public selected$: Observable<Array<T>>;
    //     public pagination$: Observable<GridPagination>;
    //     public pageSizeOptions$: Observable<Array<number>>;
    //     public loading$: Observable<boolean>;
    //     public detailViewOpen$: Observable<boolean>;
    //     public detailViewItem$: Observable<T>;
    //     public trackBy: TrackByFunction<T>;
    //     public queryFn: QueryFn<HttpService>;
    //     constructor(data: Array<T>, config?: Partial<GridDataSourceStateParam<T, K>>) {
    //         const defaultState = this._buildInitState(data, config);
    //         this._initObservers(defaultState);
    //         // Track by causes a weird bug where changing the data doesn't update the selection model
    //         // and it causes really weird, selecting, but not really selecting bugs.
    //         // this.trackBy = typeof config.trackBy === 'function' ? config.trackBy : undefined;
    //         this.queryFn = typeof config.queryFn !== 'undefined' ? config.queryFn : undefined;
    //         // At the time this updateData is called, the filters have not been registered
    //         // so it wont apply any of the preset filters to the initial request. We need to
    //         // pass these in manually here for that to happen.
    //         if (!!config.filters) {
    //             this._updateData(
    //                 {
    //                     ...defaultState,
    //                     filters: {
    //                         value: config.filters
    //                     } as any
    //                 },
    //                 this.queryFn
    //             );
    //         } else {
    //             this.updateData();
    //         }
    //     }
    //     /**
    //      * Get the currently selected values. Always returns an array,
    //      * even when multi-select is disabled
    //      */
    //     public get selected(): Array<T> {
    //         return !!this._selection ? this._selection.selected : [];
    //     }
    //     public get pagination(): GridPagination {
    //         let pagination: GridPagination;
    //         this._state$
    //             .pipe(
    //                 pluck('pagination'),
    //                 take(1)
    //             )
    //             .subscribe(p => (pagination = p));
    //         return pagination;
    //     }
    //     public set pagination(pagination: GridPagination) {
    //         let state: GridState<T>;
    //         this._state$.pipe(take(1)).subscribe(s => (state = s));
    //         // Check if limit is valid
    //         if (!state.pageSizeOptions.includes(pagination.limit)) {
    //             console.warn(
    //                 `Invalid limit given. ${pagination.limit} is not in ${state.pageSizeOptions.join(
    //                     ', '
    //                 )}. Using ${state.pageSizeOptions[0]} instead.`
    //             );
    //             pagination.limit = state.pageSizeOptions[0];
    //         }
    //         // Check if page is valid with current limit
    //         const maxPage = Math.ceil(state.count / pagination.limit);
    //         pagination.page = Math.min(pagination.page, maxPage);
    //         this._state.next({
    //             pagination: pagination
    //         });
    //         this.updateData();
    //     }
    //     public set sort(sort: GridSort) {
    //         let state: GridState<T>;
    //         this._state$.pipe(take(1)).subscribe(s => (state = s));
    //         this._state.next({
    //             sort: new GridSortParams(sort.name, sort.direction)
    //         });
    //     }
    //     public get data(): Array<T> {
    //         let data: Array<T> = [];
    //         this._state$
    //             .pipe(
    //                 pluck('data'),
    //                 take(1)
    //             )
    //             .subscribe(d => (data = d || []));
    //         return data;
    //     }
    //     public get count(): number {
    //         let count: number = 0;
    //         this._state$
    //             .pipe(
    //                 pluck('count'),
    //                 take(1)
    //             )
    //             .subscribe(c => (count = c));
    //         return count;
    //     }
    //     public set data(data: Array<T>) {
    //         if (typeof data !== 'undefined') {
    //             data = Array.isArray(data) ? data : [data];
    //             this._state.next({
    //                 data: data
    //             });
    //         }
    //     }
    //     public set detailViewItem(item: T) {
    //         this._state.next({
    //             detailViewItem: item
    //         });
    //     }
    //     public get detailViewItem(): T {
    //         let item: T;
    //         this._state$
    //             .pipe(
    //                 pluck('detailViewItem'),
    //                 take(1)
    //             )
    //             .subscribe(i => (item = i));
    //         return item;
    //     }
    //     public registerFilter(key: string, controlGroup: FormGroup): void {
    //         let filterGroup: FormGroup;
    //         this._state$
    //             .pipe(
    //                 pluck('filters'),
    //                 take(1)
    //             )
    //             .subscribe(f => (filterGroup = f));
    //         filterGroup.addControl(key, controlGroup);
    //         const initValue = this._initalFilterValue[key];
    //         if (!!initValue) {
    //             (filterGroup.get(key) as FormGroup).patchValue(initValue);
    //         }
    //         this._state.next({
    //             filters: filterGroup
    //         });
    //     }
    //     public unregisterFilter(key: string): void {
    //         let filterGroup: FormGroup;
    //         this._state$
    //             .pipe(
    //                 pluck('filters'),
    //                 take(1)
    //             )
    //             .subscribe(f => (filterGroup = f));
    //         filterGroup.removeControl(key);
    //         this._state.next({
    //             filters: filterGroup
    //         });
    //     }
    //     public openDetailView(item?: T): void {
    //         const nextState: Partial<GridState<T>> = {
    //             detailViewOpen: true
    //         };
    //         if (!!item) {
    //             nextState.detailViewItem = item;
    //         }
    //         this._state.next(nextState);
    //     }
    //     public closeDetailView(): void {
    //         this._state.next({
    //             detailViewOpen: false
    //         });
    //     }
    //     public updateData(): void {
    //         let state: GridState<T>;
    //         this._state$.pipe(take(1)).subscribe(s => (state = s));
    //         this._updateData(state, this.queryFn);
    //     }
    //     public toggleSelection(item: T | number): void {
    //         if (typeof item === 'number') {
    //             item = this.data[item] as T;
    //         }
    //         this._selection.toggle(item);
    //         this._state.next({
    //             lastToggledItem: item
    //         });
    //     }
    //     public toggleSelectionBetween(item1: T | number, item2?: T | number): void {
    //         const data = this.data;
    //         let item1Index: number = -1;
    //         let item2Index: number = -1;
    //         if (typeof item1 === 'number') {
    //             item1Index = item1;
    //             item1 = data[item1] as T;
    //         } else {
    //             item1Index = data.findIndex(d => d === item1);
    //         }
    //         if (typeof item2 !== 'undefined') {
    //             // Toggle between two items
    //             if (typeof item2 === 'number') {
    //                 item2Index = item2;
    //                 item2 = data[item2] as T;
    //             } else {
    //                 item2Index = data.findIndex(d => d === item2);
    //             }
    //         } else {
    //             // Toggle between last toggle item and new item
    //             this._state$
    //                 .pipe(
    //                     pluck('lastToggledItem'),
    //                     take(1)
    //                 )
    //                 .subscribe(l => (item2 = l));
    //             item2Index = data.findIndex(d => d === item2);
    //         }
    //         if (item1Index > -1 && item2Index > -1 && item1Index !== item2Index) {
    //             let allSelected = true;
    //             let j = Math.min(item1Index, item2Index);
    //             const k = Math.max(item1Index, item2Index);
    //             do {
    //                 allSelected = this._selection.isSelected(data[j++]);
    //             } while (allSelected === true && j <= k);
    //             for (let i = Math.min(item1Index, item2Index); i <= k; i++) {
    //                 const item = data[i];
    //                 if (allSelected) {
    //                     this._selection.deselect(item);
    //                 } else {
    //                     this._selection.select(item);
    //                 }
    //             }
    //         } else {
    //             // Unable to determine range, just toggle item1
    //             this._selection.toggle(item1);
    //         }
    //         this._state.next({
    //             lastToggledItem: item1
    //         });
    //     }
    //     private _updateData(state: GridState<T>, queryFn?: QueryFn): void {
    //         if (!!this._selection) {
    //             this._selection.clear();
    //         }
    //         const latestParams: GridParams = {
    //             pagination: state.pagination,
    //             sort: state.sort,
    //             filters: state.filters.value
    //         };
    //         const detailViewItemIndex = state.data.findIndex(d => _isEqual(d, state.detailViewItem));
    //         if (queryFn) {
    //             if (!!this._queryRequest) {
    //                 this._queryRequest.unsubscribe();
    //                 this._queryRequest = undefined;
    //             }
    //             this._state.next({
    //                 loading: true
    //             });
    //             this._queryRequest = queryFn[0][queryFn[1]].call(queryFn[0], latestParams).subscribe(
    //                 newData => {
    //                     let nextDetailViewItem = state.detailViewItem;
    //                     if (state.detailViewOpen && detailViewItemIndex > -1) {
    //                         const itemExists = newData.docs.find(d =>
    //                             _isEqual(d, state.detailViewItem)
    //                         );
    //                         if (!itemExists) {
    //                             nextDetailViewItem =
    //                                 detailViewItemIndex === 0
    //                                     ? newData.docs[newData.docs.length - 1]
    //                                     : newData.docs[0];
    //                         }
    //                     }
    //                     this._state.next({
    //                         data: newData.docs,
    //                         count: newData.count,
    //                         loading: false,
    //                         detailViewItem: nextDetailViewItem,
    //                         detailViewOpen: state.detailViewOpen && !!nextDetailViewItem
    //                     });
    //                 },
    //                 err => {
    //                     console.error(err);
    //                     this._state.next({
    //                         loading: false
    //                     });
    //                 }
    //             );
    //         } else {
    //             const page = !!state.pagination ? state.pagination.page : null;
    //             const limit = !!state.pagination ? state.pagination.limit : null;
    //             let data = state.initData;
    //             if (!!page && page > 0 && !!limit && limit > 0) {
    //                 data = state.initData.slice(page * limit - limit, page * limit + 1);
    //             }
    //             this._state.next({
    //                 data: data
    //             });
    //         }
    //     }
    //     private _buildInitState(
    //         data: Array<T>,
    //         config?: Partial<GridDataSourceStateParam<T, K>>
    //     ): GridState<T> {
    //         const defaultState = { ...this._defaultState };
    //         defaultState.initData = data;
    //         defaultState.data = data;
    //         if (!!config) {
    //             defaultState.selectionEnabled =
    //                 typeof config.selectionEnabled === 'boolean'
    //                     ? config.selectionEnabled
    //                     : defaultState.selectionEnabled;
    //             if (defaultState.selectionEnabled) {
    //                 defaultState.multi =
    //                     typeof config.multi === 'boolean' ? config.multi : defaultState.multi;
    //                 const defaultSelected =
    //                     typeof config.selected !== 'undefined'
    //                         ? Array.isArray(config.selected)
    //                             ? config.selected
    //                             : [config.selected]
    //                         : [];
    //                 this._selection = new SelectionModel<T>(defaultState.multi, defaultSelected);
    //                 defaultState.selected = defaultSelected;
    //             }
    //             if (!!config.pagination) {
    //                 defaultState.pagination = new GridPaginationParams(
    //                     config.pagination.page,
    //                     config.pagination.limit
    //                 );
    //             }
    //             if (Array.isArray(config.pageSizeOptions) && config.pageSizeOptions.length > 0) {
    //                 defaultState.pageSizeOptions = config.pageSizeOptions;
    //             }
    //             if (!defaultState.pageSizeOptions.includes(defaultState.pagination.limit)) {
    //                 console.warn(
    //                     `Invalid limit given. ${
    //                         defaultState.pagination.limit
    //                     } is not in ${defaultState.pageSizeOptions.join(', ')}. Using ${
    //                         defaultState.pageSizeOptions[0]
    //                     } instead.`
    //                 );
    //                 defaultState.pagination.limit = defaultState.pageSizeOptions[0];
    //             }
    //             if (!!config.sort) {
    //                 defaultState.sort = new GridSortParams(config.sort.name, config.sort.direction);
    //             }
    //             this._initalFilterValue = !!config.filters ? config.filters : ({} as any);
    //             defaultState.sticky = typeof config.sticky === 'boolean' ? config.sticky : true;
    //         }
    //         return defaultState;
    //     }
    //     private _initObservers(defaultState: GridState<T>): void {
    //         this._state$ = this._state.asObservable().pipe(
    //             startWith(defaultState),
    //             scan(
    //                 (state: GridState<T>, command: GridState<T>): GridState<T> => ({
    //                     ...state,
    //                     ...command
    //                 })
    //             ),
    //             shareReplay(1)
    //         );
    //         this.data$ = this._state$.pipe(
    //             pluck('data'),
    //             distinctUntilChanged()
    //         );
    //         this.selected$ = this._state$.pipe(
    //             pluck('selected'),
    //             distinctUntilChanged()
    //         );
    //         this.pagination$ = this._state$.pipe(
    //             pluck('pagination'),
    //             distinctUntilChanged()
    //         );
    //         this.pageSizeOptions$ = this._state$.pipe(
    //             pluck('pageSizeOptions'),
    //             distinctUntilChanged()
    //         );
    //         this.loading$ = this._state$.pipe(
    //             pluck('loading'),
    //             distinctUntilChanged()
    //         );
    //         this.detailViewOpen$ = this._state$.pipe(
    //             pluck('detailViewOpen'),
    //             distinctUntilChanged()
    //         );
    //         this.detailViewItem$ = this._state$.pipe(
    //             map(state => {
    //                 if (state.detailViewItem) {
    //                     let indexInCurrentSet = -1;
    //                     // handle rfx with better find
    //                     if (state.detailViewItem['_id']) {
    //                         indexInCurrentSet = state.data.findIndex(
    //                             d => d['_id'] === state.detailViewItem['_id']
    //                         );
    //                     } else {
    //                         indexInCurrentSet = state.data.indexOf(state.detailViewItem);
    //                     }
    //                     if (indexInCurrentSet === -1) {
    //                         state.detailViewItem = null;
    //                     }
    //                 }
    //                 return state;
    //             }),
    //             pluck('detailViewItem'),
    //             distinctUntilChanged()
    //         );
    //     }
}
