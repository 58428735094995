import { CompanyServiceWithoutIds } from '@gm2/common';

export class CompanyServiceDto extends CompanyServiceWithoutIds {
    public _id: string;

    constructor(params?: Partial<CompanyServiceDto>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
        }
    }
}
