import { TimesheetSimpleWithoutIds } from '@gm2/common';

export class TimesheetSimple extends TimesheetSimpleWithoutIds {
    public _id: string = '';

    constructor(params?: Partial<TimesheetSimple>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
        }
    }
}
