import { BaseDoc } from '../base/base-doc';
import { ServiceSettings } from './service-settings';
import { ServiceChildWithoutIds } from './service-child';

export class ServiceWithoutIds extends BaseDoc {
    public _id: any;
    public name: string;
    public description: string;
    public formId: any;
    public serviceTypeId: any;
    public settings: ServiceSettings = new ServiceSettings();
    public children: ServiceChildWithoutIds[] = [];
    public material?: any

    constructor(params?: Partial<ServiceWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.description = params.description || this.description;
            this.formId = params.formId || this.formId;
            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId;
            this.settings = !!params.settings
                ? new ServiceSettings(params.settings)
                : this.settings;
            this.children = Array.isArray(params.children)
                ? params.children.map(c => new ServiceChildWithoutIds(c))
                : this.children;
            this.material = params.material || this.material;
        }
    }
}
