import { Directive, Input, ElementRef, ViewContainerRef, TemplateRef } from '@angular/core';
import { Permission } from '@gm2/ui-common';
import { Select } from '@ngxs/store';
import { UserState } from '@gm2/ui-state';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
    selector: '[permission]'
})
export class PermissionDirective {
    @Select(UserState.userPermissions)
    public userPermissions$: Observable<Permission[]>;

    private _permissions: Permission[] = [];
    @Input('permission')
    public get permission(): Permission | Permission[] {
        return this._permissions;
    }
    public set permission(value: Permission | Permission[]) {
        if (Array.isArray(value)) {
            this._permissions = value;
        } else {
            this._permissions = [value];
        }
        this._updateView();
    }

    @Input('requireAll')
    public requireAll: boolean = false;

    private _el: ElementRef;
    private _userPermissions: Permission[] = [];
    constructor(
        public el: ElementRef,
        private _viewContainer: ViewContainerRef,
        private _templateRef: TemplateRef<any>
    ) {
        this._el = el;
        this.userPermissions$.pipe(take(1)).subscribe(perms => (this._userPermissions = perms));
    }

    private _updateView(): void {
        if (!this._permissions || this._permissions.length === 0) {
            this._viewContainer.createEmbeddedView(this._templateRef);
            return;
        }
        let hasPermission: boolean = true;
        if (this.requireAll) {
            for (const p of this._permissions) {
                hasPermission = hasPermission && this._userPermissions.indexOf(p) > -1;
            }
        } else {
            hasPermission = false;
            let pI = 0;
            do {
                hasPermission = this._userPermissions.indexOf(this._permissions[pI]) > -1;
            } while (hasPermission === false && this._permissions[++pI]);
        }
        if (hasPermission) {
            this._viewContainer.createEmbeddedView(this._templateRef);
        } else {
            this._viewContainer.clear();
        }
    }
}
