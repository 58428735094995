<mat-tab-group>
    <mat-tab label="Pending">
        <ng-container *ngIf="(pendingServices$ | async)?.length > 0; else noPendingServices">
            <table mat-table [dataSource]="pendingServices$ | async" class="mat-elevation-z1">
                <!-- Name Column -->
                <mat-text-column name="name"></mat-text-column>

                <!-- Price Column -->
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>Price</th>
                    <td mat-cell *matCellDef="let element">{{ element.price | currency }}</td>
                </ng-container>

                <!-- Description Column -->
                <mat-text-column name="description"></mat-text-column>

                <!-- Company Column -->
                <mat-text-column name="company"></mat-text-column>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="align-right">Actions</th>
                    <td mat-cell *matCellDef="let element" class="align-right">
                        <button mat-icon-button color="primary" aria-label="Approve service">
                            <mat-icon>thumb_up</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" aria-label="Disapprove service">
                            <mat-icon>thumb_down</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </ng-container>
        <ng-template #noPendingServices>
            <p>There are no pending services.</p>
        </ng-template>
    </mat-tab>
    <mat-tab label="Approved">
        <ng-container *ngIf="(approvedServices$ | async)?.length > 0; else noApprovedServices">
            <table mat-table [dataSource]="approvedServices$ | async" class="mat-elevation-z1">
                <!-- Name Column -->
                <mat-text-column name="name"></mat-text-column>

                <!-- Price Column -->
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>Price</th>
                    <td mat-cell *matCellDef="let element">{{ element.price | currency }}</td>
                </ng-container>

                <!-- Description Column -->
                <mat-text-column name="description"></mat-text-column>

                <!-- Company Column -->
                <mat-text-column name="company"></mat-text-column>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </ng-container>
        <ng-template #noApprovedServices>
            <p>There are no approved services.</p>
        </ng-template>
    </mat-tab>
    <mat-tab label="Disapproved">
        <ng-container
            *ngIf="(disapprovedServices$ | async)?.length > 0; else noDisapprovedServices"
        >
            <table mat-table [dataSource]="disapprovedServices$ | async" class="mat-elevation-z1">
                <!-- Name Column -->
                <mat-text-column name="name"></mat-text-column>

                <!-- Price Column -->
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>Price</th>
                    <td mat-cell *matCellDef="let element">{{ element.price | currency }}</td>
                </ng-container>

                <!-- Description Column -->
                <mat-text-column name="description"></mat-text-column>

                <!-- Company Column -->
                <mat-text-column name="company"></mat-text-column>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </ng-container>
        <ng-template #noDisapprovedServices>
            <p>There are no disapproved services.</p>
        </ng-template>
    </mat-tab>
</mat-tab-group>
