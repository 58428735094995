<h2 mat-dialog-title>Specification Documents</h2>
<div mat-dialog-content>
    <mat-list *ngIf="documents$ | async as documents; else loading">
        <ng-container *ngIf="documents.length > 0; else noResults">
            <a      mat-list-item
                    [href]="item.url"
                    target="_blank"
                    *ngFor="let item of documents">
                {{ item.name }}
            </a>
        </ng-container>
        <ng-template #noResults>
            <mat-list-item>No Documents</mat-list-item>
        </ng-template>
    </mat-list>
    <ng-template #loading>
        <mat-spinner [diameter]="30"></mat-spinner>
    </ng-template>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="close()">CLOSE</button>
</div>
