import { CompanyIdentityWithoutIds } from '../company/company-identity';
import { LocationIdentityWithoutIds } from '../location/location-identity';

export class TimesheetFormIdentityWithoutIds {
    public _id: any;
    public formUserDataId: any;
    public clientCompanyIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public locationIdentity: LocationIdentityWithoutIds = new LocationIdentityWithoutIds();

    constructor(params?: Partial<TimesheetFormIdentityWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.formUserDataId = params.formUserDataId || this.formUserDataId;

            this.clientCompanyIdentity = !!params.clientCompanyIdentity
                ? new CompanyIdentityWithoutIds(params.clientCompanyIdentity)
                : this.clientCompanyIdentity;

            this.locationIdentity = !!params.locationIdentity
                ? new LocationIdentityWithoutIds(params.locationIdentity)
                : this.locationIdentity;
        }
    }
}
