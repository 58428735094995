import { WorkOrderStatus, WorkOrderActiveStatus, WorkOrderNotification } from '@gm2/common';

export class WorkOrderEditDto {
    public workOrderStatus: WorkOrderStatus = null;
    public activeStatus: WorkOrderActiveStatus = null;
    public completionDate: Date = null;
    public message: string = '';
    public notification: WorkOrderNotification = null;

    constructor(params?: Partial<WorkOrderEditDto>) {
        if (!!params) {
            this.workOrderStatus = !!params.workOrderStatus
                ? params.workOrderStatus : null;
            this.activeStatus = !!params.activeStatus
                ? params.activeStatus : null;
            this.completionDate = !!params.completionDate
                ? params.completionDate : null;
            this.message = !!params.message
                ? params.message : null;
            this.notification = !!params.notification
      				  ? params.notification : null;
        }
    }
}
