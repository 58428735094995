import { LaborWithoutIds } from '../company/labor';
import { isNull } from '../../utils';
import { WorkOrderLaborCompletionWithoutIds } from './work-order-labor-completion';
import { WorkOrderLaborSelectedRate } from '../../enums';

export class WorkOrderLaborWithoutIds {
    public _id: any;
    public labor: LaborWithoutIds;
    public description: string = '';
    public estimate: number = 0;
    public hours: number = 0;
    public total: number = 0;
    public completionInfo: WorkOrderLaborCompletionWithoutIds;
    public servicePartnerRate: number = 0;
    public selectedRate: WorkOrderLaborSelectedRate;
    public markup: number = 0;

    constructor(params?: Partial<WorkOrderLaborWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.labor = !!params.labor ? new LaborWithoutIds(params.labor) : this.labor;
            this.description = params.description || this.description;
            this.hours = !isNull(params.hours) ? params.hours : this.hours;
            this.estimate = !isNull(params.estimate) ? params.estimate : this.estimate;
            this.total = !isNull(params.total) ? params.total : this.total;
            this.completionInfo = !!params.completionInfo
                ? new WorkOrderLaborCompletionWithoutIds(params.completionInfo)
                : this.completionInfo;
            this.servicePartnerRate = params.servicePartnerRate || this.servicePartnerRate;
            this.selectedRate = params.selectedRate || this.selectedRate;
            this.markup = params.markup || this.markup;
        }
    }
}
