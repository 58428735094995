import { Injectable } from '@angular/core';
import {
    HttpService,
    Get,
    MapClass,
    Path,
    Patch,
    Body,
    Post, Delete, Put
} from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { SubcontractorInfo } from '../models/subcontractor-info/subcontractor-info';
import { SubcontractorInfoCreateDto } from '../models/subcontractor-info/subcontractorInfo-create.dto';
import { CompanyStatus } from '@gm2/common';

@Injectable({
    providedIn: 'root'
})
export class SubcontractorInfoService extends HttpService {
    @Get('/subcontractorInfo/:id')
    @MapClass(SubcontractorInfo)
    public getSubcontractorInfoById(@Path('id') id: string): Observable<SubcontractorInfo> {
        return null;
    }

    @Get('/subcontractorInfo/byContract/:id')
    @MapClass(SubcontractorInfo)
    public getSubcontractorInfoByContractOwnerId(@Path('id') id: string): Observable<SubcontractorInfo> {
        return null;
    }

    @Get('/subcontractorInfo/byContractBehalfSp/:id/:spId')
    @MapClass(SubcontractorInfo)
    public getSubcontractorInfoByContractOwnerIdBehalfSpId(@Path('id') id: string, @Path('spId') spId: string): Observable<SubcontractorInfo> {
        return null;
    }

    @Patch('/subcontractorInfo/:subcontractorInfoId')
    public editSubcontractorInfo(
        @Path('subcontractorInfoId') id: string,
        @Body() body: any,
    ): Observable<SubcontractorInfo> {
        return null;
    }

    @Patch('/subcontractorInfo/requireTimeAllocation')
    public toggleRequireTimeAllocation(
        @Body('companyId') companyId: string,
        @Body('requireTimeAllocation') requireTimeAllocation: boolean
    ): Observable<SubcontractorInfo> {
        return null;
    }

    @Put('/subcontractorInfo/update-statuses')
    public updateSubcontractorInfosStatuses(
        @Body('ids') ids: string[],
        @Body('companyStatus') companyStatus: CompanyStatus,
    ): Observable<any> {
        return null;
    }

    @Post('/subcontractorInfo/create')
    public createSubcontractorInfo(@Body() body: SubcontractorInfoCreateDto): Observable<SubcontractorInfo> {
        return null;
    }

    @Post('/subcontractorInfo/create-many')
    public createSubcontractorInfoMany(@Body('ids') ids: string[]): Observable<string[]> {
        return null;
    }

    @Put('/subcontractorInfo/delete')
    public deleteSubcontractorInfo(@Body('ids') ids: string[] ): Observable<SubcontractorInfo> {
        return null;
    }

    @Put('/subcontractorInfo/delete-invalid')
    public deleteInvalidSubcontractor(@Body('id') id: string ): Observable<SubcontractorInfo> {
        return null;
    }
}
