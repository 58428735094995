import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RfxGridFilterControlComponent, RfxGridFilterSelectOption } from '@refactor/ngx/grid';
import { ServiceService, ToastService } from '@gm2/ui-common';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
    selector: 'gm2-grid-filter-service-multiple',
    templateUrl: './grid-filter-service-multiple.component.html',
    styleUrls: ['./grid-filter-service-multiple.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: RfxGridFilterControlComponent,
            useExisting: GridFilterServiceMultipleComponent
        }
    ]
})
export class GridFilterServiceMultipleComponent implements OnInit {
    @Input()
    public name: string = 'serviceIds';
    @Input()
    public placeholder: string = 'Services';
    @Input()
    public options: RfxGridFilterSelectOption[];
    @Input()
    public limit: number;

    public initialOptions: RfxGridFilterSelectOption[] = null;

    public gridServices$: Observable<
        RfxGridFilterSelectOption[]
    > = this._serviceService.getServices().pipe(
        map(services =>
            services.map(service => ({
                value: service._id,
                label: service.name
            }))
        )
    );

    public controlGroup: UntypedFormGroup;

    public searchControl: UntypedFormControl = new UntypedFormControl();

    private _onDestroy: Subject<void> = new Subject<void>();

    private selectedItems: string[];

    public changed(): void {
        if (!this.limit) {
            return;
        }
        if (this.controlGroup.controls.values.value.length <= this.limit) {
            this.selectedItems = this.controlGroup.controls.values.value;
        } else {
            this.controlGroup.controls.values.setValue(this.selectedItems);
            this._toast.error('Limit Reached');
        }
    }

    private filterOnSearch(): void {
        if (!this.options || !this.initialOptions) {
            return;
        }
        let search = this.searchControl.value;
        if (!search) {
            this.options = this.initialOptions.slice();
            return;
        } else {
            search = search.toLowerCase();
        }
        this.options = this.initialOptions.filter(
            service => service.label.toLowerCase().indexOf(search) > -1
        );
    }

    constructor(private _serviceService: ServiceService, private _toast: ToastService) {}

    ngOnInit(): void {
        this.controlGroup = new UntypedFormGroup({
            values: new UntypedFormControl('')
        });
        this.gridServices$.subscribe(services => {
            if (!!services) {
                this.options = services;
                if (
                    this.initialOptions === null ||
                    this.options.length > this.initialOptions.length
                ) {
                    this.initialOptions = services;
                }
            }
        });
        this.searchControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterOnSearch();
        });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}
