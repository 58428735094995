import { ToastService } from '@gm2/ui-common';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { catchError, finalize, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GeospatialService } from '@ngx-gm2/shared/services/geospatial.service';
import { PaginationParams, PaginationResult } from '@ngx-gm2/shared/models/pagination.model';
import { GeospatialModel } from '../../../../../apps/ngx-gm2/src/app/geospatial/models/usage.model';
import { Receiver } from '@ngxs-labs/emitter';

export namespace GeospatialActions {

    export class SetProps {
        static readonly type = '[Geospatial] Set Particular Props';

        constructor(public propToRewrite: Partial<GeospatialStateModel>) {
        }
    }

    export class GetCompaniesUsage {
        static readonly type = '[Geospatial] Get Companies Usage';

        constructor(
            public filters: GeospatialModel.CompanyUsageFilters,
            public pagination: PaginationParams
        ) {
        }
    }

    export class GetPersonalUsage {
        static readonly type = '[Geospatial] Get Personal Usage';

        constructor(public filters: Partial<GeospatialModel.PersonalUsageFilters>) {
        }
    }

    export class UpdateUsageEntry {
        static readonly type = '[Geospatial] Update Usage Entry';

        constructor(
            public companyId: string,
            public propsToUpdate: Partial<GeospatialModel.CompanyUsage>
        ) {
        }
    }
}

export interface GeospatialStateModel {
    companiesUsage: PaginationResult<GeospatialModel.CompanyUsage>;
    companiesUsageLoading: boolean;
    personalUsage: GeospatialModel.PersonalUsage;
    personalUsageLoading: boolean;
}

const initialState: GeospatialStateModel = {
    companiesUsage: null,
    companiesUsageLoading: false,
    personalUsage: null,
    personalUsageLoading: false
};

@State<GeospatialStateModel>({
    name: 'geospatial',
    defaults: initialState
})
@Injectable()
export class GeospatialState {

    @Selector()
    @ImmutableSelector()
    public static selectCompaniesUsage(state: GeospatialStateModel): PaginationResult<GeospatialModel.CompanyUsage> {
        return state.companiesUsage;
    }

    @Selector()
    @ImmutableSelector()
    public static selectCompaniesUsageLoading(state: GeospatialStateModel): boolean {
        return state.companiesUsageLoading;
    }

    @Selector()
    @ImmutableSelector()
    public static selectPersonalUsage(state: GeospatialStateModel): GeospatialModel.PersonalUsage {
        return state.personalUsage;
    }

    @Selector()
    @ImmutableSelector()
    public static selectPersonalUsageLoading(state: GeospatialStateModel): boolean {
        return state.personalUsageLoading;
    }

    constructor(
        private toastService: ToastService,
        private geospatialService: GeospatialService
    ) {
    }


    @Receiver({ action: GeospatialActions.UpdateUsageEntry })
    @ImmutableContext()
    public static updateUsageEntry(
        { setState }: StateContext<GeospatialStateModel>,
        { companyId, propsToUpdate }: GeospatialActions.UpdateUsageEntry
    ): void {
        setState((state: GeospatialStateModel) => {
            state.companiesUsage.items = state.companiesUsage.items
                .map((entry) =>
                    entry.companyId === companyId
                        ? { ...entry, ...propsToUpdate }
                        : entry
                );
            return state;
        });
    }

    @Action(GeospatialActions.GetCompaniesUsage)
    public getCompaniesUsage(ctx: StateContext<GeospatialStateModel>, { filters, pagination }: GeospatialActions.GetCompaniesUsage) {
        ctx.setState((state) => ({ ...state, companiesUsageLoading: true }));

        return this.geospatialService.companiesGeospatialUsage(filters, pagination)
            .pipe(
                tap((companiesUsage) => ctx.setState((state) => ({ ...state, companiesUsage }))),
                finalize(() => ctx.setState((state) => ({ ...state, companiesUsageLoading: false }))),
                catchError((err: any) => {
                    this.toastService.error(err?.error?.message);
                    return of();
                })
            );
    }

    @Action(GeospatialActions.GetPersonalUsage)
    public getPersonalUsage(ctx: StateContext<GeospatialStateModel>, { filters }: GeospatialActions.GetPersonalUsage) {
        ctx.setState((state) => ({ ...state, personalUsageLoading: true }));

        return this.geospatialService.personalGeospatialUsage(filters)
            .pipe(
                tap((personalUsage) => ctx.setState((state) => ({ ...state, personalUsage }))),
                finalize(() => ctx.setState((state) => ({ ...state, personalUsageLoading: false }))),
                catchError((err: any) => {
                    this.toastService.error(err?.error?.message);
                    return of();
                })
            );
    }
}
