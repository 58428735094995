import { WorkOrderInvoiceTotals, WorkOrderPhaseWithoutIds } from '../../models';
import { calcWorkOrderClientPrice, calcWorkOrderOgmTotal } from '../work-order/work-order-methods';
import { WorkOrderLaborCostType, WorkOrderBillingType } from '../../enums';

export function calculateWorkOrderInvoiceTotals<T extends WorkOrderPhaseWithoutIds>(
    phases: T[],
    costType: WorkOrderLaborCostType,
    billingType: WorkOrderBillingType
): WorkOrderInvoiceTotals {
    return new WorkOrderInvoiceTotals({
        total: calcWorkOrderOgmTotal(phases, costType),
        clientTotal: calcWorkOrderClientPrice(phases, billingType)
    });
}
