import { State, Selector, StateContext, Store, createSelector } from '@ngxs/store';
import { ImmutableSelector, ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Receiver, Emittable } from '@ngxs-labs/emitter';
import { UntypedFormGroup } from '@angular/forms';
import { Pagination, Sort } from '@refactor/common';
import { combineLatest, Observable, Subject } from 'rxjs';
import { take, takeUntil, map } from 'rxjs/operators';
import { RfxGridDataSource } from '@refactor/ngx/grid';
import { Injectable } from '@angular/core';

const initialGrids = {
    GRID_USER_LIST: null,
    GRID_BID_SEARCH: null,
    GRID_COMPANY_LIST: null,
    GRID_COMPANY_MATERIAL_LIST: null,
    GRID_COMPANY_SERVICE_LIST: null,
    GRID_CONTRACT_LIST: null,
    GRID_CREW_LIST: null,
    GRID_INVOICE_LIST: null,
    GRID_LOCATION_LIST: null,
    GRID_PACKAGE_LIST: null,
    GRID_ROLE_LIST: null,
    GRID_SERVICE_LIST: null,
    GRID_SERVICE_ACTIVITIES: null,
    GRID_SERVICE_TYPE_LIST: null,
    GRID_TAGS_LIST: null,
    GRID_TIMESHEET_LIST: null,
    GRID_TIMESHEET_VALIDATION: null,
    GRID_WORKORDER_LIST: null,
    GRID_WORKORDER_SEARCH: null,
    GRID_WORKORDER_INVOICE_LIST: null,
    GRID_NOTIFICATION_LIST: null,
};

export interface GridStateModel {
    grids: { [key: string]: IGridState };
}

export interface IGridState {
    key?: string;
    filters: UntypedFormGroup;
    sort: Sort;
    pagination: Pagination;
}

@State<GridStateModel>({
    name: 'grids',
    defaults: {
        grids: initialGrids,
    },
})
@Injectable()
export class GridState {
    public static readonly GRID_USER_LIST: string = 'GRID_USER_LIST';
    public static readonly GRID_BID_SEARCH: string = 'GRID_BID_SEARCH';
    public static readonly GRID_COMPANY_LIST: string = 'GRID_COMPANY_LIST';
    public static readonly GRID_COMPANY_MATERIAL_LIST: string = 'GRID_COMPANY_MATERIAL_LIST';
    public static readonly GRID_COMPANY_SERVICE_LIST: string = 'GRID_COMPANY_SERVICE_LIST';
    public static readonly GRID_CONTRACT_LIST: string = 'GRID_CONTRACT_LSIT';
    public static readonly GRID_CREW_LIST: string = 'GRID_CREW_LIST';
    public static readonly GRID_INVOICE_LIST: string = 'GRID_INVOICE_LIST';
    public static readonly GRID_LOCATION_LIST: string = 'GRID_LOCATION_LIST';
    public static readonly GRID_PACKAGE_LIST: string = 'GRID_PACKAGE_LIST';
    public static readonly GRID_ROLE_LIST: string = 'GRID_ROLE_LIST';
    public static readonly GRID_SERVICE_LIST: string = 'GRID_SERVICE_LIST';
    public static readonly GRID_SERVICE_ACTIVITIES: string = 'GRID_SERVICE_ACTIVITIES';
    public static readonly GRID_SERVICE_TYPE_LIST: string = 'GRID_SERVICE_TYPE_LIST';
    public static readonly GRID_TAGS_LIST: string = 'GRID_TAGS_LIST';
    public static readonly GRID_TIMESHEET_LIST: string = 'GRID_TIMESHEET_LIST';
    public static readonly GRID_TIMESHEET_VALIDATION: string = 'GRID_TIMESHEET_VALIDATION';
    public static readonly GRID_WORKORDER_LIST: string = 'GRID_WORKORDER_LIST';
    public static readonly GRID_WORKORDER_SEARCH: string = 'GRID_WORKORDER_SEARCH';
    public static readonly GRID_WORKORDER_INVOICE_LIST: string = 'GRID_WORKORDER_INVOICE_LIST';
    public static readonly GRID_NOTIFICATION_LIST: string = 'GRID_NOTIFICATION_LIST';
    public static readonly GRID_GEOSPATIAL_USAGE_LIST: string = 'GRID_GEOSPATIAL_USAGE_LIST';

    @Selector()
    @ImmutableSelector()
    public static gridState(state: GridStateModel): { [key: string]: IGridState } {
        return state.grids;
    }

    public static getSingleGridState(gridKey: string): any {
        return createSelector(
            [GridState],
            (state: GridStateModel) => {
                return state.grids[gridKey];
            },
        );
    }

    @Receiver({ type: '[IGridState] set a grid state' })
    @ImmutableContext()
    public static setGridState(
        { setState }: StateContext<GridStateModel>,
        { payload }: { payload: IGridState },
    ): void {
        setState((state: GridStateModel) => {
            const grids = !!state.grids ? { ...state.grids } : {};

            grids[payload.key] = {
                filters: payload.filters,
                sort: payload.sort,
                pagination: payload.pagination,
            };

            state.grids = grids;

            return state;
        });
    }

    @Receiver({ type: '[IGridState] reset to initial state' })
    @ImmutableContext()
    public static resetToInitialState(
        { setState }: StateContext<GridStateModel>,
    ): void {
        setState((state: GridStateModel) => {
            state.grids = initialGrids;

            return state;
        });
    }
}

export function restoreGridState<T, K, V>(
    storeRef: Store,
    gridKey: string,
    defaultConfig: any,
    filterAdditions?: any,
): RfxGridDataSource<T, K> {
    const stored = storeRef.selectSnapshot<IGridState>((state: GridStateModel) => {
        if (!!state.grids) {
            const root = state.grids;
            if (!!root.grids) {
                const data = root.grids;
                if (!!data[gridKey]) {
                    return data[gridKey];
                }
            }
        }

        return null;
    });

    const queryFn: [V, string] = defaultConfig['queryFn'];

    if (!!stored) {
        const config = {};

        if (!!stored.filters) {
            config['filters'] = stored.filters;
        }

        if (!!stored.sort) {
            config['sort'] = stored.sort;
        }

        if (!!stored.pagination) {
            config['pagination'] = stored.pagination;
        }

        if (!!filterAdditions) {
            // create copy because config['filters'] not extensible
            const filters = { ...config['filters'] };
            Object.keys(filterAdditions)
                .forEach(key => {
                    filters[key] = filterAdditions[key];
                });
            config['filters'] = filters;
        }

        config['queryFn'] = queryFn;

        config['multi'] = defaultConfig['multi'];

        config['selectionEnabled'] = defaultConfig['selectionEnabled'];

        return new RfxGridDataSource<T, K>([], config);
    } else {
        defaultConfig['queryFn'] = queryFn;

        return new RfxGridDataSource<T, K>([], defaultConfig);
    }
}

export function captureGridState(
    gridKey: string,
    gridSource: RfxGridDataSource<any, any>,
    setGridState: Emittable<IGridState>,
    unsub$: Subject<void>,
): void {
    combineLatest(gridSource._state$, gridSource.loading$)
        .pipe(takeUntil(unsub$))
        .subscribe(([state, loading]) => {
            if (!loading) {
                setGridState.emit({
                    key: gridKey,
                    filters: state.filters.getRawValue(),
                    sort: state.sort,
                    pagination: state.pagination,
                });
            }
        });
}
