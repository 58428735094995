import { CompanyType } from '../company/company-type';

export enum WorkOrderStatus {
    Template = '[WorkOrderStatus] Template',
    ClientRequested = '[WorkOrderStatus] ClientRequested',
    ServicePartnerRequested = '[WorkOrderStatus] ServicePartnerRequested',
    Created = '[WorkOrderStatus] Created',
    Pending = '[WorkOrderStatus] Pending',
    Review = '[WorkOrderStatus] Review',
    Approved = '[WorkOrderStatus] Approved',
    Rejected = '[WorkOrderStatus] Rejected',
    ServicePartnerRejected = '[WorkOrderStatus] ServicePartnerRejected',
    ClientRejected = '[WorkOrderStatus] ClientRejected',
    Completed = '[WorkOrderStatus] Completed',
    Posted = '[WorkOrderStatus] Posted',
    PaymentProcessed = '[WorkOrderStatus] PaymentProcessed'
}

export namespace WorkOrderStatus {
    export function toString(status: WorkOrderStatus): string {
        switch (status) {
            case WorkOrderStatus.Template:
                return 'Template';
            case WorkOrderStatus.ClientRequested:
                return 'Client Requested';
            case WorkOrderStatus.ServicePartnerRequested:
                return 'Service Partner Requested';
            case WorkOrderStatus.Created:
                return 'New';
            case WorkOrderStatus.Pending:
                return 'Pending';
            case WorkOrderStatus.Review:
                return 'Review';
            case WorkOrderStatus.Approved:
                return 'Approved';
            case WorkOrderStatus.Rejected:
                return 'Client Declined';
            case WorkOrderStatus.ServicePartnerRejected:
                return 'SP Request Declined';
            case WorkOrderStatus.ClientRejected:
                return 'Client Request Declined';
            case WorkOrderStatus.Completed:
                return 'Field Completed';
            case WorkOrderStatus.Posted:
                return 'Approved for Invoicing';
            case WorkOrderStatus.PaymentProcessed:
                return 'Invoiced';
            default:
                return '';
        }
    }

    export const members: WorkOrderStatus[] = [
        WorkOrderStatus.Template,
        WorkOrderStatus.ClientRequested,
        WorkOrderStatus.ServicePartnerRequested,
        WorkOrderStatus.Created,
        WorkOrderStatus.Pending,
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderStatus.ServicePartnerRejected,
        WorkOrderStatus.ClientRejected,
        WorkOrderStatus.Completed,
        WorkOrderStatus.Posted,
        WorkOrderStatus.PaymentProcessed
    ];

    export const editableMembers: WorkOrderStatus[] = [
        WorkOrderStatus.Template,
        WorkOrderStatus.ClientRequested,
        WorkOrderStatus.ServicePartnerRequested,
        WorkOrderStatus.Created,
        WorkOrderStatus.Pending,
        WorkOrderStatus.Review,
        WorkOrderStatus.Approved,
        WorkOrderStatus.Rejected,
        WorkOrderStatus.ServicePartnerRejected,
        WorkOrderStatus.ClientRejected
    ];

    export const spEditableMembers: WorkOrderStatus[] = [
        WorkOrderStatus.ServicePartnerRequested,
        WorkOrderStatus.Created
    ];

    export const phasesInvoiceAbleMembers: WorkOrderStatus[] = [
        WorkOrderStatus.Posted,
        WorkOrderStatus.PaymentProcessed
    ];

    const companyUiStatusMap: {
        [key in CompanyType]: { [k in WorkOrderStatus]: WorkOrderStatus }
    } = {
        [CompanyType.Admin]: {
            [WorkOrderStatus.Template]: WorkOrderStatus.Template,
            [WorkOrderStatus.ClientRequested]: WorkOrderStatus.ClientRequested,
            [WorkOrderStatus.ServicePartnerRequested]: WorkOrderStatus.ServicePartnerRequested,
            [WorkOrderStatus.Created]: WorkOrderStatus.Created,
            [WorkOrderStatus.Pending]: WorkOrderStatus.Pending,
            [WorkOrderStatus.Review]: WorkOrderStatus.Review,
            [WorkOrderStatus.Approved]: WorkOrderStatus.Approved,
            [WorkOrderStatus.Rejected]: WorkOrderStatus.Rejected,
            [WorkOrderStatus.ServicePartnerRejected]: WorkOrderStatus.ServicePartnerRejected,
            [WorkOrderStatus.ClientRejected]: WorkOrderStatus.ClientRejected,
            [WorkOrderStatus.Completed]: WorkOrderStatus.Completed,
            [WorkOrderStatus.Posted]: WorkOrderStatus.Posted,
            [WorkOrderStatus.PaymentProcessed]: WorkOrderStatus.PaymentProcessed
        },
        [CompanyType.SuperAdmin]: {
            [WorkOrderStatus.Template]: WorkOrderStatus.Template,
            [WorkOrderStatus.ClientRequested]: WorkOrderStatus.ClientRequested,
            [WorkOrderStatus.ServicePartnerRequested]: WorkOrderStatus.ServicePartnerRequested,
            [WorkOrderStatus.Created]: WorkOrderStatus.Created,
            [WorkOrderStatus.Pending]: WorkOrderStatus.Pending,
            [WorkOrderStatus.Review]: WorkOrderStatus.Review,
            [WorkOrderStatus.Approved]: WorkOrderStatus.Approved,
            [WorkOrderStatus.Rejected]: WorkOrderStatus.Rejected,
            [WorkOrderStatus.ServicePartnerRejected]: WorkOrderStatus.ServicePartnerRejected,
            [WorkOrderStatus.ClientRejected]: WorkOrderStatus.ClientRejected,
            [WorkOrderStatus.Completed]: WorkOrderStatus.Completed,
            [WorkOrderStatus.Posted]: WorkOrderStatus.Posted,
            [WorkOrderStatus.PaymentProcessed]: WorkOrderStatus.PaymentProcessed
        },
        [CompanyType.Landscaper]: {
            [WorkOrderStatus.Template]: WorkOrderStatus.Template,
            [WorkOrderStatus.ClientRequested]: WorkOrderStatus.ClientRequested,
            [WorkOrderStatus.ServicePartnerRequested]: WorkOrderStatus.ServicePartnerRequested,
            [WorkOrderStatus.Created]: WorkOrderStatus.Created,
            [WorkOrderStatus.Pending]: WorkOrderStatus.Pending,
            [WorkOrderStatus.Review]: WorkOrderStatus.Review,
            [WorkOrderStatus.Approved]: WorkOrderStatus.Approved,
            [WorkOrderStatus.Rejected]: WorkOrderStatus.Rejected,
            [WorkOrderStatus.ServicePartnerRejected]: WorkOrderStatus.ServicePartnerRejected,
            [WorkOrderStatus.ClientRejected]: WorkOrderStatus.ClientRejected,
            [WorkOrderStatus.Completed]: WorkOrderStatus.Completed,
            [WorkOrderStatus.Posted]: WorkOrderStatus.Posted,
            [WorkOrderStatus.PaymentProcessed]: WorkOrderStatus.PaymentProcessed
        },
        [CompanyType.Client]: {
            [WorkOrderStatus.Template]: null,
            [WorkOrderStatus.ClientRequested]: WorkOrderStatus.ClientRequested,
            [WorkOrderStatus.ServicePartnerRequested]: null,
            [WorkOrderStatus.Created]: WorkOrderStatus.ClientRequested,
            [WorkOrderStatus.Pending]: WorkOrderStatus.ClientRequested,
            [WorkOrderStatus.Review]: WorkOrderStatus.Review,
            [WorkOrderStatus.Approved]: WorkOrderStatus.Approved,
            [WorkOrderStatus.Rejected]: WorkOrderStatus.Rejected,
            [WorkOrderStatus.ServicePartnerRejected]: null,
            [WorkOrderStatus.ClientRejected]: WorkOrderStatus.ClientRejected,
            [WorkOrderStatus.Completed]: WorkOrderStatus.Completed,
            [WorkOrderStatus.Posted]: WorkOrderStatus.Posted,
            [WorkOrderStatus.PaymentProcessed]: WorkOrderStatus.PaymentProcessed
        },
        [CompanyType.Service_Partner]: {
            [WorkOrderStatus.Template]: null,
            [WorkOrderStatus.ClientRequested]: null,
            [WorkOrderStatus.ServicePartnerRequested]: WorkOrderStatus.ServicePartnerRequested,
            [WorkOrderStatus.Created]: WorkOrderStatus.Created,
            [WorkOrderStatus.Pending]: WorkOrderStatus.Pending,
            [WorkOrderStatus.Review]: WorkOrderStatus.Review,
            [WorkOrderStatus.Approved]: WorkOrderStatus.Approved,
            [WorkOrderStatus.Rejected]: null,
            [WorkOrderStatus.ServicePartnerRejected]: WorkOrderStatus.ServicePartnerRejected,
            [WorkOrderStatus.ClientRejected]: null,
            [WorkOrderStatus.Completed]: WorkOrderStatus.Completed,
            [WorkOrderStatus.Posted]: WorkOrderStatus.Posted,
            [WorkOrderStatus.PaymentProcessed]: WorkOrderStatus.Posted
        }
    };

    function uiStatusByCompanyType(
        status: WorkOrderStatus,
        companyType: CompanyType
    ): WorkOrderStatus {
        return companyUiStatusMap[companyType][status];
    }

    export const clientUiMembers: WorkOrderStatus[] = (function(): WorkOrderStatus[] {
        const clientMembers: WorkOrderStatus[] = [];
        for (const m of members) {
            const val = uiStatusByCompanyType(m, CompanyType.Client);
            if (!!val && !clientMembers.includes(val)) {
                clientMembers.push(m);
            }
        }
        return clientMembers;
    })();

    export const clientRequestedQueryMembers: WorkOrderStatus[] = (function(): WorkOrderStatus[] {
        const queryMembers = [];
        for (const s of WorkOrderStatus.members) {
            if (uiStatusByCompanyType(s, CompanyType.Client) !== null) {
                queryMembers.push(s);
            }
        }
        return queryMembers;
    })();

    export const clientNonRequestedQueryMembers: WorkOrderStatus[] = (function(): WorkOrderStatus[] {
        const queryMembers = [];
        for (const s of WorkOrderStatus.members) {
            if (uiStatusByCompanyType(s, CompanyType.Client) !== null) {
                queryMembers.push(s);
            }
        }
        return queryMembers.filter(m => m !== WorkOrderStatus.Created);
    })();

    export const clientProjection: { [key: string]: {} } = (function(): { [key: string]: {} } {
        const branches = [];
        for (const s of WorkOrderStatus.members) {
            if (uiStatusByCompanyType(s, CompanyType.Client) !== s) {
                branches.push({
                    case: { $eq: ['$workOrderStatus', s] },
                    then: uiStatusByCompanyType(s, CompanyType.Client)
                });
            }
        }
        return {
            $switch: {
                branches,
                default: '$workOrderStatus'
            }
        };
    })();

    export const servicePartnerUiMembers: WorkOrderStatus[] = (function(): WorkOrderStatus[] {
        const spMembers: WorkOrderStatus[] = [];
        for (const m of members) {
            const val = uiStatusByCompanyType(m, CompanyType.Service_Partner);
            if (!!val && !spMembers.includes(val)) {
                spMembers.push(val);
            }
        }
        return spMembers;
    })();

    export const servicePartnerQueryMembers: WorkOrderStatus[] = (function(): WorkOrderStatus[] {
        const queryMembers = [];
        for (const s of WorkOrderStatus.members) {
            if (uiStatusByCompanyType(s, CompanyType.Service_Partner) !== null) {
                queryMembers.push(s);
            }
        }
        return queryMembers;
    })();

    export const servicePartnerProjection: { [key: string]: {} } = (function(): {
        [key: string]: {};
    } {
        const branches = [];
        for (const s of WorkOrderStatus.members) {
            if (uiStatusByCompanyType(s, CompanyType.Service_Partner) !== s) {
                branches.push({
                    case: { $eq: ['$workOrderStatus', s] },
                    then: uiStatusByCompanyType(s, CompanyType.Service_Partner)
                });
            }
        }
        return {
            $switch: {
                branches,
                default: '$workOrderStatus'
            }
        };
    })();

    export function disableHours(s: WorkOrderStatus): boolean {
        const disableWhen = [
            WorkOrderStatus.Approved,
            WorkOrderStatus.Completed,
            WorkOrderStatus.Posted,
            WorkOrderStatus.PaymentProcessed
        ];
        return disableWhen.includes(s);
    }

    export function disableHorlyRate(s: WorkOrderStatus): boolean {
        const disableWhen = [
            WorkOrderStatus.Review,
            WorkOrderStatus.Approved,
            WorkOrderStatus.Rejected,
            WorkOrderStatus.ServicePartnerRejected,
            WorkOrderStatus.ClientRejected,
            WorkOrderStatus.Completed,
            WorkOrderStatus.Posted,
            WorkOrderStatus.PaymentProcessed
        ];
        return disableWhen.includes(s);
    }

    export function includeTimeSpent(s: WorkOrderStatus): boolean {
        const includeWhen = [
            WorkOrderStatus.Approved,
            WorkOrderStatus.Completed,
            WorkOrderStatus.Posted,
            WorkOrderStatus.PaymentProcessed
        ];
        return includeWhen.includes(s);
    }

    export function toImageName(type: WorkOrderStatus): string {
        switch (type) {
            case WorkOrderStatus.ClientRequested:
            case WorkOrderStatus.ServicePartnerRequested:
            case WorkOrderStatus.Created:
            case WorkOrderStatus.Pending:
                return 'wo_status-new.png';
            case WorkOrderStatus.Review:
                return 'wo_status-review.png';
            case WorkOrderStatus.Approved:
                return 'wo_status-approved.png';
            case WorkOrderStatus.ServicePartnerRejected:
            case WorkOrderStatus.ClientRejected:
            case WorkOrderStatus.Rejected:
                return 'wo_status-declined.png';
            case WorkOrderStatus.Completed:
                return 'wo_status-completed.png';
            case WorkOrderStatus.Posted:
                return 'wo_status-approvedforinvoicing.png';
            case WorkOrderStatus.PaymentProcessed:
                return 'wo_status-invoiced.png';
            default:
                return null;
        }
    }
}
