export enum InvoiceMonthlyType {
    PerRfp = '[InvoiceMonthlyType] per Rfp',
    MultipleRfps = '[InvoiceMonthlyType] multiple Rfps'
}

export namespace InvoiceMonthlyType {
    export function toString(type: InvoiceMonthlyType): string {
        switch (type) {
            case InvoiceMonthlyType.PerRfp:
                return 'Rfp'; // generate one invoice for each rfp
            case InvoiceMonthlyType.MultipleRfps:
                return 'Multiple'; // generate one invoice for all rfps
            default:
                return '';
        }
    }

    export const members: InvoiceMonthlyType[] = [
        InvoiceMonthlyType.PerRfp,
        InvoiceMonthlyType.MultipleRfps
    ];
}
