import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RfxGridModule } from '@refactor/ngx/grid';
import { RfxFormsModule } from '@refactor/ngx/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        ReactiveFormsModule,
        RfxFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        PdfViewerModule,
        RfxGridModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        NgxMatSelectSearchModule,
        ToastrModule.forRoot({
            tapToDismiss: true,
            newestOnTop: true,
            progressBar: true,
            progressAnimation: 'decreasing',
            timeOut: 5000,
            extendedTimeOut: 1000,
            easeTime: 300
        })
    ],
    exports: [RfxGridModule],
})
export class UiCommonModule {}
