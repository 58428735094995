import { IBooleanFilter } from '../../interface';
import { isBoolean } from 'lodash';

export class BooleanFilter implements IBooleanFilter {
    public value: boolean = null;

    constructor(params?: Partial<BooleanFilter>) {
        if (!!params) {
            this.value = isBoolean(params.value) ? params.value : this.value;
        }
    }
}
