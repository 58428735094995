<form [formGroup]="servicesFormGroup">
    <mat-accordion formArrayName="services">
        <ng-container *ngFor="let s of servicesFormArray.controls; let i = index">
            <mat-expansion-panel
                #panel
                class="custom-header service"
                hideToggle="true"
                [formGroupName]="i"
                [disabled]="isSnow"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span [class.needs-attention]="serviceRequiresMaterialNote(i)">
                            {{ s.value.name }}
                        </span>
                    </mat-panel-title>
                    <mat-panel-description>
                        <fa-icon
                            *ngIf="!evaluateChecked(i)"
                            [icon]="['far', 'circle']"
                            (click)="attemptSelectToggle(i, $event)"
                        ></fa-icon>
                        <fa-icon
                            *ngIf="evaluateChecked(i)"
                            [icon]="['far', 'check-circle']"
                            (click)="attemptSelectToggle(i, $event)"
                        ></fa-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- Stand Alone Service -->
                <ng-container *ngIf="s.get('children').length === 0">
                    <gm2-timesheet-service-list-time-allocator
                        *ngIf="(requireTime$ | async) === true"
                        [form]="s"
                        [timeAvailable$]="remainingTimeAvailableToAllocate$"
                        [timeAvailableString$]="formattedRemainingTimeToAllocate$"
                        [serviceTrigger$]="validationTrigger$"
                    ></gm2-timesheet-service-list-time-allocator>
                    <gm2-timesheet-service-material
                        *ngIf="s.contains('material')"
                        [form]="s.get('material')"
                        [availableMaterials]="getAvailableMaterials(s)"
                        [serviceClassification]="s.get('settings').get('serviceClassification').value"
                        [mappingClassificationAreas$]="mappingClassificationAreas$"
                        [serviceTypeInformation]="serviceTypeInfo$ | async"
                        [calculationTrigger$]="materialTrigger$"
                        [serviceTrigger$]="validationTrigger$"
                    ></gm2-timesheet-service-material>
                </ng-container>

                <!-- Child Services -->
                <ng-container *ngIf="(serviceChildren$ | async)[i] as children">
                    <ng-container *ngIf="children.length > 0" formArrayName="children">
                        <ng-container *ngIf="(serviceMultiSelect$ | async)[i] === true; else single">
                            <ng-container
                                *ngFor="let c of children; let _c = index"
                                [formGroupName]="_c"
                            >
                                <mat-checkbox
                                    #checkbox
                                    formControlName="selected"
                                    (change)="multiChildSelectEvent($event, i, _c, c)"
                                >
                                    {{ c.name }}
                                </mat-checkbox>
                                <br/>
                                <ng-container *ngIf="checkbox.checked">
                                    <gm2-timesheet-service-list-time-allocator
                                        *ngIf="(requireTime$ | async) === true"
                                        [form]="servicesFormArray.at(i).get('children').at(_c)"
                                        [timeAvailable$]="remainingTimeAvailableToAllocate$"
                                        [timeAvailableString$]="formattedRemainingTimeToAllocate$"
                                        [serviceTrigger$]="validationTrigger$"
                                    ></gm2-timesheet-service-list-time-allocator>
                                    <gm2-timesheet-service-material
                                        *ngIf="servicesFormArray.at(i).get('children').at(_c).contains('material')"
                                        [form]="servicesFormArray.at(i).get('children').at(_c).get('material')"
                                        [availableMaterials]="getAvailableMaterials(s)"
                                        [serviceClassification]="servicesFormArray.at(i).get('settings').get('serviceClassification').value"
                                        [mappingClassificationAreas$]="mappingClassificationAreas$"
                                        [serviceTypeInformation]="serviceTypeInfo$ | async"
                                        [calculationTrigger$]="materialTrigger$"
                                        [serviceTrigger$]="validationTrigger$"
                                    ></gm2-timesheet-service-material>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-template #single>
                            <ng-container [formGroupName]="0">
                                <mat-radio-group formControlName="_id">
                                    <mat-radio-button
                                        *ngFor="let c of children"
                                        [value]="c._id"
                                        (change)="singleChildSelectEvent($event, i, c)"
                                    >
                                        {{ c.name }}
                                    </mat-radio-button>
                                </mat-radio-group>
                                <gm2-timesheet-service-list-time-allocator
                                    *ngIf="(requireTime$ | async) === true &&
                                        !!servicesFormArray.at(i).get('children').at(0).get('_id').value"
                                    [form]="servicesFormArray.at(i).get('children').at(0)"
                                    [timeAvailable$]="remainingTimeAvailableToAllocate$"
                                    [timeAvailableString$]="formattedRemainingTimeToAllocate$"
                                    [serviceTrigger$]="validationTrigger$"
                                ></gm2-timesheet-service-list-time-allocator>
                                <gm2-timesheet-service-material
                                    *ngIf="!!servicesFormArray.at(i).get('children').at(0).get('_id').value &&
                                        servicesFormArray.at(i).get('children').at(0).contains('material')"
                                    [form]="servicesFormArray.at(i).get('children').at(0).get('material')"
                                    [availableMaterials]="getAvailableMaterials(s)"
                                    [serviceClassification]="servicesFormArray.at(i).get('settings').get('serviceClassification').value"
                                    [mappingClassificationAreas$]="mappingClassificationAreas$"
                                    [serviceTypeInformation]="serviceTypeInfo$ | async"
                                    [calculationTrigger$]="materialTrigger$"
                                    [serviceTrigger$]="validationTrigger$"
                                ></gm2-timesheet-service-material>
                            </ng-container>
                        </ng-template>
                    </ng-container>
                </ng-container>

                <!-- Actions -->
                <mat-action-row>
                    <button
                        mat-stroked-button
                        color="warn"
                        type="button"
                        (click)="clearServiceGroup(i)"
                    >
                        Clear
                    </button>
                    <button
                        mat-stroked-button
                        color="primary"
                        type="button"
                        (click)="panel.expanded = false"
                    >
                        Close
                    </button>
                </mat-action-row>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</form>
