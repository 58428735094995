<h2 mat-dialog-title>Confirm Pricing Method Change</h2>
<div mat-dialog-content>
    <p>
        I understand that this ONE Grounds Management client requires
        {{ BillingType.toString(originalBillingType) }} pricing. However, I prefer to provide my bid
        pricing in {{ BillingType.toString(newBillingType) }} format. I understand that ONE Grounds
        Management will accept and consider by bid with this alternate bid pricing, however, I
        understand that it does not guarantee that I will be awarded this location.
    </p>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onAccept()">Accept</button>
</div>
