import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
    BillingType,
    Company,
    CompanyPriceService, CompanyService,
    CompanyType,
    ContractAppliedService,
    IBidService,
    MaterialMeasurementType,
    Rfp,
    RfpService,
    ToastService,
    User
} from '@gm2/ui-common';
import { ModalService } from '../../services';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { CompanyState, UserState } from '@gm2/ui-state';
import { ContractApplied, Material } from '@gm2/api-common';

interface LineItem {
    name: string;
    description: string;
    formGroupIndex?: number;
    isChild?: boolean;
    isParent?: boolean;
    hasChildren?: boolean;
}

@Component({
    selector: 'gm2-sp-bid-flyout',
    templateUrl: './sp-bid-flyout.component.html',
    styleUrls: ['./sp-bid-flyout.component.scss'],
})
export class SpBidFlyoutComponent implements OnInit, OnDestroy {
    private rfpId$: Subject<string> = new Subject();
    private disabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private awardedStatus$: BehaviorSubject<string> = new BehaviorSubject('available');
    private billingTypeOverride$: BehaviorSubject<BillingType | null> = new BehaviorSubject(null);
    private _unsub: Subject<void> = new Subject();
    public materialSelectedName$: string;
    public selectedMaterialPrice$: number;
    public materialSelected$: Material;
    // public materialsList$: Material[];
    public materialsApprovedList$: BehaviorSubject<Material[]> = new BehaviorSubject<Material[]>([]);
    public measurmentValue$: MaterialMeasurementType[] = [];
    public MaterialMeasurementType: typeof MaterialMeasurementType = MaterialMeasurementType;
    private rfpValue$: BehaviorSubject<Rfp | null> = new BehaviorSubject(null);
    public materialsInputStatus$: boolean = false;
    public contractName: string = 'Default';
    private contractValue$: BehaviorSubject<ContractApplied | null> = new BehaviorSubject(null);

    @Select(CompanyState.company)
    public company$!: Observable<Company>;

    @Select(CompanyState.companyId)
    public companyId$!: Observable<string>;

    /**
     * Forward BillingType enum to view
     */
    public BillingType: typeof BillingType = BillingType;
    public form: UntypedFormGroup;
    public materialsForm: UntypedFormGroup;

    @Input()
    public set rfpid(value: string) {
        if (typeof value === 'undefined' || value === null) {
            throw new Error('You must specify an rfp id');
        }

        this.rfpId$.next(value);
    }

    @Input()
    public set disabled(value: boolean) {
        this.disabled$.next(value);
    }

    @Input()
    public set awardedStatus(value: string) {
        if (value === 'awarded') {
            this.awardedStatus$.next(value);
        } else {
            this.awardedStatus$.next(value);
        }
    }

    @Output()
    public onSubmit: EventEmitter<void> = new EventEmitter();

    @Select(UserState.user)
    public user$: Observable<User>;

    public rfp$: Observable<Rfp> = combineLatest([this.rfpId$, this.billingTypeOverride$])
        .pipe(
            switchMap(([id, _]) => this.rfpService.getRfp(id)),
            tap((rfp) => {
                console.log('rfp', rfp)
                this.rfpValue$.next(rfp)
            }),
            shareReplay(1),
        );

    public externalContractOwner$: Observable<Company> = combineLatest([this.rfpValue$, this.company$])
        .pipe(
            switchMap(([rfp, myCompany]) => {
                const isServicePartner = myCompany.type === CompanyType.Service_Partner;
                const isExternalContract = myCompany.type === CompanyType.Landscaper && rfp.contractIdentity.createdBy !== myCompany._id;

                if (isServicePartner || isExternalContract) {
                    return this.companyService.getCompany(rfp.contractIdentity.createdBy);
                }
            }),
            shareReplay(1),
        );

    public contractIdentity$: Observable<ContractApplied> = this.rfpValue$
        .pipe(
            switchMap((rfp) => this.contractAppliedService.getContractApplied(rfp.contractIdentity._id)),
            tap((contract) => this.contractValue$.next(contract)),
            shareReplay(1),
        );

    /** List of approved materials */
    public readonly companyMaterials$: Observable<Material[]> = this.companyPriceService.getApprovedMaterialList();

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly rfpService: RfpService,
        private readonly companyService: CompanyService,
        private readonly toastService: ToastService,
        private readonly modalService: ModalService,
        private readonly companyPriceService: CompanyPriceService,
        private readonly contractAppliedService: ContractAppliedService
    ) {
        this.rfp$
            .pipe(
                withLatestFrom(combineLatest([
                    this.disabled$,
                    this.companyId$,
                ])),
                takeUntil(this._unsub),
            )
            .subscribe(([rfp, res]) => {
                const disabled = res[0];
                const companyId = res[1];
                if (this.isMonthly(rfp)) {
                    const services = [];
                    this.form = this.fb.group({
                        agreement: [{ value: false, disabled }, Validators.required],
                        amount: [{ value: null, disabled }],
                        services: this.fb.array([]),
                    });
                    if (rfp.snapShot.package.services.length > 0) {
                        for(const service of rfp.snapShot.package.services) {
                            const hasChildren = service.children.length > 0;
                            const hasParentControl = service.settings.parentControl;

                            if (!hasChildren || hasParentControl) {
                                services.push(this.fb.group({
                                    amount: [{ value: null, disabled }],
                                    serviceId: [service._id],
                                    name: [service.name],
                                    materialReq: service.settings.hasMaterial
                                }));
                            } else {
                                for(const childservice of service.children) {
                                    services.push(this.fb.group({
                                        amount: [{ value: null, disabled }],
                                        serviceId: [childservice._id],
                                        name: [childservice.name],
                                        materialReq: childservice.settings.hasMaterial
                                    }));
                                }
                            }
                        }
                    } else {
                        for(const service of rfp.snapShot.companyService.services) {
                            const hasChildren = service.children.length > 0;
                            const hasParentControl = service.settings.parentControl;

                            if (!hasChildren || hasParentControl) {
                                services.push(this.fb.group({
                                    amount: [{ value: null, disabled }],
                                    serviceId: [service._id],
                                    name: [service.name],
                                    materialReq: service.settings.hasMaterial
                                }));
                            } else {
                                for(const childservice of service.children) {
                                    services.push(this.fb.group({
                                        amount: [{ value: null, disabled }],
                                        serviceId: [childservice._id],
                                        name: [childservice.name],
                                        materialReq: childservice.settings.hasMaterial
                                    }));
                                }
                            }
                        }
                    }

                    this.form = this.fb.group({
                        agreement: [{ value: false, disabled }, Validators.required],
                        services: this.fb.array(services),
                        amount: [{ value: null, disabled }],
                        materials: this.fb.array([]),
                    });
                    if (!!rfp.awardedBid) {
                        this.materialsInputStatus$ = true;
                    }
                } else {
                    const services = [];
                    if (rfp.snapShot.package.services.length > 0) {
                        for(const service of rfp.snapShot.package.services) {
                            const hasChildren = service.children.length > 0;
                            const hasParentControl = service.settings.parentControl;

                            if (!hasChildren || hasParentControl) {
                                services.push(this.fb.group({
                                    amount: [{ value: null, disabled }, Validators.required],
                                    serviceId: [service._id],
                                    name: [service.name],
                                    materialReq: service.settings.hasMaterial
                                }));
                            } else {
                                for(const childservice of service.children) {
                                    services.push(this.fb.group({
                                        amount: [{ value: null, disabled }, Validators.required],
                                        serviceId: [childservice._id],
                                        name: [childservice.name],
                                        materialReq: childservice.settings.hasMaterial
                                    }));
                                }
                            }
                        }
                    } else {
                        for(const service of rfp.snapShot.companyService.services) {
                            const hasChildren = service.children.length > 0;
                            const hasParentControl = service.settings.parentControl;

                            if (!hasChildren || hasParentControl) {
                                services.push(this.fb.group({
                                    amount: [{ value: null, disabled }, Validators.required],
                                    serviceId: [service._id],
                                    name: [service.name],
                                    materialReq: service.settings.hasMaterial
                                }));
                            } else {
                                for(const childservice of service.children) {
                                    services.push(this.fb.group({
                                        amount: [{ value: null, disabled }, Validators.required],
                                        serviceId: [childservice._id],
                                        name: [childservice.name],
                                        materialReq: childservice.settings.hasMaterial
                                    }));
                                }
                            }
                        }
                    }

                    this.form = this.fb.group({
                        agreement: [{ value: false, disabled }, Validators.required],
                        services: this.fb.array(services),
                        amount: [{ value: null, disabled }],
                        materials: this.fb.array([]),
                    });
                    if (!!rfp.awardedBid) {
                        this.materialsInputStatus$ = true;
                    }
                }

                const currentbid = rfp.bids.find(bid => bid.companyIdentity._id === companyId);
                if (currentbid && this.isMonthly(rfp)) {
                    // const value = { amount: currentbid.monthlyRate };
                    // this.form.patchValue(value);
                    // let materialsList = <FormArray>this.materialsForm.controls.materialsList;
                    // if (rfp.snapShot.companyService.material === 'Yes') {
                    //     currentbid.materials.forEach(material => {
                    //         materialsList.push(this.fb.group({ materialName: material.materialName, materialPrice: material.materialPrice }));
                    //     });
                    // }
                    const value = { amount: currentbid.monthlyRate };
                    this.form.patchValue(value);
                    const isAnyMaterialCompanyService = rfp.snapShot.companyService.services.find((ser) => {
                        return ser.settings.hasMaterial === true;
                    })
                    const isAnyMaterialPackage = rfp.snapShot.package.services.find((ser) => {
                        return ser.settings.hasMaterial === true;
                    })
                    if (isAnyMaterialCompanyService) {
                        for(let i = 0; i < currentbid.services.length; i++) {
                            let materialsList = <FormArray>this.materialsForm.controls[`materialsList_${i}`];
                            currentbid.services[i].materials.forEach(material => {
                                this.measurmentValue$.push(material.measurement);
                                materialsList.push(this.fb.group(
                                    {
                                        materialName: material.materialName,
                                        materialPrice: material.materialPrice,
                                        activeIngredient: material.activeIngredient,
                                        description: material.description,
                                        measurement: MaterialMeasurementType.toString(material.measurement),
                                    },
                                ));
                            });
                        }
                    }
                    if (isAnyMaterialPackage) {
                        for(let i = 0; i < currentbid.services.length; i++) {
                            let materialsList = <FormArray>this.materialsForm.controls[`materialsList_${i}`];
                            currentbid.services[i].materials.forEach(material => {
                                this.measurmentValue$.push(material.measurement);
                                materialsList.push(this.fb.group(
                                    {
                                        materialName: material.materialName,
                                        materialPrice: material.materialPrice,
                                        activeIngredient: material.activeIngredient,
                                        description: material.description,
                                        measurement: MaterialMeasurementType.toString(material.measurement),
                                    },
                                ));
                            });
                        }
                    }
                    this.form.patchValue(currentbid);
                } else if (currentbid && !this.isMonthly(rfp)) {
                    // const materialsValue = [{materialsList: [{materialName: "Mat name", materialAmount: 140}]}]
                    // this.materialsForm.patchValue(materialsValue)
                    const isAnyMaterialCompanyService = rfp.snapShot.companyService.services.find((ser) => {
                        return ser.settings.hasMaterial === true;
                    })
                    const isAnyMaterialPackage = rfp.snapShot.package.services.find((ser) => {
                        return ser.settings.hasMaterial === true;
                    })
                    if (isAnyMaterialCompanyService) {
                        for(let i = 0; i < currentbid.services.length; i++) {
                            let materialsList = <FormArray>this.materialsForm.controls[`materialsList_${i}`];
                            currentbid.services[i].materials.forEach(material => {
                                this.measurmentValue$.push(material.measurement);
                                materialsList.push(this.fb.group(
                                    {
                                        materialName: material.materialName,
                                        materialPrice: material.materialPrice,
                                        activeIngredient: material.activeIngredient,
                                        description: material.description,
                                        measurement: MaterialMeasurementType.toString(material.measurement),
                                    },
                                ));
                            });
                        }
                    }
                    if (isAnyMaterialPackage) {
                        for(let i = 0; i < currentbid.services.length; i++) {
                            let materialsList = <FormArray>this.materialsForm.controls[`materialsList_${i}`];
                            if (!!currentbid.services[i].materials) {
                                currentbid.services[i].materials.forEach(material => {
                                    this.measurmentValue$.push(material.measurement);
                                    materialsList.push(this.fb.group(
                                        {
                                            materialName: material.materialName,
                                            materialPrice: material.materialPrice,
                                            activeIngredient: material.activeIngredient,
                                            description: material.description,
                                            measurement: MaterialMeasurementType.toString(material.measurement),
                                        },
                                    ));
                                });
                            }
                        }
                    }
                    this.form.patchValue(currentbid);
                }
            });
    }

    ngOnInit(): void {
        this.materialsForm = this.fb.group({
            materialsList_0: this.fb.array([]),
            materialsList_1: this.fb.array([]),
            materialsList_2: this.fb.array([]),
            materialsList_3: this.fb.array([]),
            materialsList_4: this.fb.array([]),
            materialsList_5: this.fb.array([]),
            materialsList_6: this.fb.array([]),
            materialsList_7: this.fb.array([]),
            materialsList_8: this.fb.array([]),
            materialsList_9: this.fb.array([]),
            materialsList_10: this.fb.array([]),
            materialsList_11: this.fb.array([]),
            materialsList_12: this.fb.array([]),
            materialsList_13: this.fb.array([]),
            materialsList_14: this.fb.array([]),
            materialsList_15: this.fb.array([]),
            materialsList_16: this.fb.array([]),
            materialsList_17: this.fb.array([]),
            materialsList_18: this.fb.array([]),
            materialsList_19: this.fb.array([]),
            materialsList_20: this.fb.array([]),
        });
        this.companyPriceService.getMaterialList()
            .subscribe((res) => {
                this.materialsApprovedList$.next(res);
            });
    }

    ngOnDestroy(): void {
        this._unsub.next();
        this._unsub.complete();
    }

    public get servicesFormArray(): UntypedFormArray {
        return this.form.get('services') as UntypedFormArray;
    }

    get materials() {
        return (<FormArray>this.form.get('materials')).controls;
    }

    // get materialsList() {
    //     return (<FormArray>this.materialsForm.get('materialsList_0')).controls;
    // }

    public getDynamicMaterialsList(index: string) {
        return (<FormArray>this.materialsForm.get(`materialsList_${index}`)).controls;
    }

    public addMaterialToList(index: number) {
        (<FormArray>this.materialsForm.get(`materialsList_${index}`)).push(this.fb.group({
            materialName: [],
            materialPrice: [],
            measurement: [],
            activeIngredient: [],
            description: [],
        }));
    }

    public removeMaterial(i, formIndex) {
        (<FormArray>this.materialsForm.get(`materialsList_${formIndex}`)).removeAt(i);
        this.measurmentValue$.splice(i, 1);
    }

    public trackByServices(index: number, item: { name: string }): string {
        return item.name;
    }

    public hasMaterialCheck(index, service): boolean {
        if (!!service.isChild) {
            const value = this.rfpValue$.getValue();
            if (value.snapShot.companyService.services.length > 0) {
                const hasMaterial = value.snapShot.companyService.services[index]?.settings.hasMaterial === true;
                if (!!hasMaterial) {
                    return true
                } else {
                    return false
                }
            } else if (value.snapShot.package.services.length > 0) {
                const hasMaterial = value.snapShot.package.services[service.serviceIndex].children[service.insideChildFormGroupIndex]?.settings.hasMaterial === true;
                if (!!hasMaterial) {
                    return true
                } else {
                    return false
                }
            }
            return false;
        }
        const value = this.rfpValue$.getValue();
        if (value.snapShot.companyService.services.length > 0) {
            const hasMaterial = value.snapShot.companyService.services[index]?.settings.hasMaterial === true;
            if (!!hasMaterial) {
                return true
            } else {
                return false
            }
        } else if (value.snapShot.package.services.length > 0) {
            const hasMaterial = value.snapShot.package.services[index]?.settings.hasMaterial === true;
            if (!!hasMaterial) {
                return true
            } else {
                return false
            }
        }
    }

    public numberOnlyForPrice(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    public onMaterialSelect(event, index, arrayIndex): void {
        this.materialSelectedName$ = event.value;
        this.companyPriceService.getMaterialList()
            .subscribe((res) => {
                const selectedMaterial = res.find((el) => {
                    return el.name === this.materialSelectedName$;
                });
                this.materialSelected$ = selectedMaterial;
                this.materialsApprovedList$.next(res);
                this.measurmentValue$.push(this.materialSelected$.measurement);
                (<FormArray>this.materialsForm.get(`materialsList_${index}`)).controls[arrayIndex].setValue({
                    materialName: this.materialSelected$.name,
                    materialPrice: this.materialSelected$.price,
                    activeIngredient: this.materialSelected$.activeIngredient,
                    description: this.materialSelected$.description,
                    measurement: MaterialMeasurementType.toString(this.materialSelected$.measurement),
                });
            });
    }

    // public fillMaterialPirceValue(index): number {
    //     console.log(index, "INDEX")
    //     console.log(this.materialsForm.get(`materialsList_${index}`));
    //     const formValue = this.materialsForm.get(`materialsList_${index}`);
    //     return 100
    // }

    // Service extends ServiceChild so this works (for now)
    public getAllServices(rfp: Rfp): LineItem[] {
        let services = [];
        let i = 0;
        if (rfp.snapShot.package.services.length > 0) {
            let sI = 0;
            for(const service of rfp.snapShot.package.services) {
                const hasChildren = service.children.length > 0;
                const hasParentControl = service.settings.parentControl;
                let formGroupIndex: number | undefined;
                let serviceIndex: number | undefined;
                serviceIndex = sI;
                sI += 1;
                if (!hasChildren || hasParentControl) {
                    formGroupIndex = i;
                    i += 1;

                    services.push({
                        name: service.name,
                        description: service.description,
                        formGroupIndex,
                        isParent: true,
                        hasChildren,
                    });
                }
                if (hasChildren) {
                    let j = 0;
                    for(const childservice of service.children) {
                        let childFormGroupIndex: number | undefined;
                        let insideChildFormGroupIndex: number | undefined;
                        if (!hasParentControl) {
                            childFormGroupIndex = i;
                            i += 1;
                            insideChildFormGroupIndex = j;
                            j+=1;

                        }
                        services.push({
                            name: childservice.name,
                            description: childservice.description,
                            formGroupIndex: childFormGroupIndex,
                            insideChildFormGroupIndex: insideChildFormGroupIndex,
                            serviceIndex: serviceIndex,
                            isChild: true,
                        });
                    }
                }
            }
        } else if (rfp.snapShot.companyService.services.length > 0) {
            for(const service of rfp.snapShot.companyService.services) {
                const hasChildren = service.children.length > 0;
                const hasParentControl = service.settings.parentControl;
                let formGroupIndex: number | undefined;
                if (!hasChildren || hasParentControl) {
                    formGroupIndex = i;
                    i += 1;

                    services.push({
                        name: service.name,
                        description: service.description,
                        formGroupIndex,
                        isParent: true,
                        hasChildren,
                    });
                }
                if (hasChildren) {
                    for(const childservice of service.children) {
                        let childFormGroupIndex: number | undefined;
                        if (!hasParentControl) {
                            childFormGroupIndex = i;
                            i += 1;
                        }
                        services.push({
                            name: childservice.name,
                            description: childservice.description,
                            formGroupIndex: childFormGroupIndex,
                            isChild: true,
                        });
                    }
                }
            }
        }
        return services;
    }

    /**
     * Give "isRealBillingType" of true to get the billing type of the current
     * bid or the rfp otherwise regardless of any overrides made in the current
     * session
     */
    public getBillingType(rfp: Rfp, isRealBillingType: boolean = false): BillingType {
        const realBillingType = rfp.contractIdentity.billingType;
        const override =
            this.billingTypeOverride$.value || (rfp.bids && rfp.bids[0] && rfp.bids[0].billingType);
        return isRealBillingType ? realBillingType : override || realBillingType;
    }

    public isMonthly(rfp: Rfp, isRealBillingType: boolean = false): boolean {
        return this.getBillingType(rfp, isRealBillingType) === BillingType.Monthly;
    }

    public isPerEvent(rfp: Rfp, isRealBillingType: boolean = false): boolean {
        return this.getBillingType(rfp, isRealBillingType) === BillingType.PerEvent;
    }

    public isHourly(rfp: Rfp, isRealBillingType: boolean = false): boolean {
        return this.getBillingType(rfp, isRealBillingType) === BillingType.Hourly;
    }

    public showSpecDocs(contractAppliedId: string): void {
        this.modalService.specificationDocuments(contractAppliedId);
    }

    public overrideBillingType(rfp: Rfp, newBillingType: BillingType): void {
        const originalBillingType = this.getBillingType(rfp);
        const realBillingType = this.getBillingType(rfp, true);

        // No need to confirm if going back to the actual billing type of this
        // RFP
        if (newBillingType === realBillingType) {
            this.billingTypeOverride$.next(newBillingType);
            return;
        }

        this.modalService
            .confirmPricingChange(originalBillingType, newBillingType)
            .afterClosed()
            .pipe(filter(x => x))
            .subscribe(_ => {
                this.billingTypeOverride$.next(newBillingType);
            });
    }

    public submit(rfp: Rfp): void {
        console.log(this.form.value, this.form, this.materialsForm);
        const data = this.form.value;
        if (!this.form.valid) {
            this.toastService.error('You must fill out all required fields');
            if (!data.agreement) {
                this.toastService.error('You must accept the agreement');
            }
            return;
        }
        let materials = this.materialsForm.value.materialsList;

        let services: IBidService[] = [];
        let monthlyRate = null;
        if (this.isMonthly(rfp)) {
            monthlyRate = data.amount;
            services = data.services;
        } else {
            services = data.services;
        }

        for(let i = 0; i < services.length; i++) {
            const materialsValue = this.materialsForm.value[`materialsList_${i}`];
            let materialsArray: any[] = [];
            if (!!materialsValue && materialsValue.length > 0) {
                for (let j = 0; j < materialsValue.length; j++) {
                    const matList = this.materialsApprovedList$.getValue();
                    const selectedMaterial = matList.find((el) => {
                        return el.name === materialsValue[j].materialName;
                    });
                    const materialObj = {
                        materialName: materialsValue[j].materialName,
                        activeIngredient: selectedMaterial.activeIngredient,
                        description: selectedMaterial.description,
                        measurement: selectedMaterial.measurement,
                        materialPrice: materialsValue[j].materialPrice,
                    };
                    materialsArray.push(materialObj);
                }
                services[i].materials = materialsArray;
            }
        }

        let isMaterialsValid = true;
        services.forEach((service) => {
            if (!!service?.materialReq) {
                if (service?.materials?.length < 1) {
                    // this.toastService.error('Add materials for services where its required');
                    isMaterialsValid = false
                }
            }
        })

        if (!isMaterialsValid) {
            this.toastService.error('Add materials for services where its required');
            return
        }

        this.rfpService
            .submitBid(rfp._id, {
                billingType: this.getBillingType(rfp),
                services,
                monthlyRate,
            })
            .subscribe(() => {
                    this.onSubmit.emit();
                },
                err => {
                    this.toastService.error(err);
                });
    }
}
