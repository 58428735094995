import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    QueryList,
    ViewChildren,
    ElementRef, OnChanges, SimpleChanges,
} from '@angular/core';
import {
    TimesheetServiceDto,
    Material,
    ToastService,
    MaterialCalculationService,
    MappingClassificationArea,
    CompanyType,
    ServiceClassification,
} from '@gm2/ui-common';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, combineLatest, ReplaySubject } from 'rxjs';
import { map, takeUntil, shareReplay } from 'rxjs/operators';
import { MatRadioChange } from '@angular/material/radio';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatExpansionPanel } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';
import { CompanyState } from '@gm2/ui-state';
import { Select } from '@ngxs/store';

const MINUTES_IN_HOUR = 60;

@Component({
    selector: 'gm2-timesheet-service-list',
    templateUrl: './timesheet-service-list.component.html',
    styleUrls: ['./timesheet-service-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/** Service listing for gm2 desktop app. */
export class TimesheetServiceListComponent implements OnInit, OnChanges {
    @ViewChildren('panel')
    public panels: QueryList<MatExpansionPanel>;

    private _unsub: Subject<void> = new Subject<void>();

    public services$: BehaviorSubject<any[]> = new BehaviorSubject([]);

    public CompanyType: typeof CompanyType = CompanyType;

    @Select(CompanyState.companyType)
    public companyType$: Observable<string>;

    @Input('services')
    public set setServices(value: any) {
        this.services$.next(value);
    }

    @Input('isRfpOldSystem') isRfpOldSystem: boolean;

    @Input('isOnSpBehalf') isOnSpBehalf: boolean;

    @Input('SPValue') SPValue: string;

    public serviceTypeInfo$: BehaviorSubject<any[]> = new BehaviorSubject([]);

    @Input('serviceTypeInfo')
    public set setServiceTypeInfo(value: any) {
        this.serviceTypeInfo$.next(value);
    }

    public mappingClassificationAreas$: BehaviorSubject<MappingClassificationArea[]> = new BehaviorSubject([]);

    @Input('mappingClassificationAreas')
    public set setMappingClassificationAreas(value: any) {
        this.mappingClassificationAreas$.next(value);
    }

    @Input()
    public selectedServices: any[] = [];
    @Input()
    public materials: Material[] = [];
    @Input()
    public isSnow: boolean = true;
    @Input()
    public companyType: any;
    @Input()
    public client: any;
    @Input()
    public requireTime: boolean = false;
    // behavior subject for benefit of template updates
    public requireTime$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public totalAvailableTimeToAllocate$: BehaviorSubject<number> = new BehaviorSubject(0);

    public awardedMaterials$: BehaviorSubject<Material[]> = new BehaviorSubject([]);

    public filteredMaterials: any

    @Input('totalAvailableTimeToAllocate')
    public set totalAvailableTimeToAllocate(value: any) {
        this.totalAvailableTimeToAllocate$.next(value);
    }

    @Input('awardedMaterials')
    public set awardedMaterials(value: any) {
        this.awardedMaterials$.next(value.awardedMaterials);
        this.filteredMaterials = value;
    }

    @Output()
    public validServices: BehaviorSubject<TimesheetServiceDto[]> = new BehaviorSubject([]);

    public servicesFormGroup: UntypedFormGroup = this._fb.group({
        services: this._fb.array([]),
    });

    public readonly serviceChildren$: Observable<any[]> = this.services$.pipe(
        takeUntil(this._unsub),
        map(servicesArr => servicesArr.map(s => s.children)),
    );

    public readonly serviceMultiSelect$: Observable<boolean[]> = this.services$.pipe(
        takeUntil(this._unsub),
        map(servicesArr => servicesArr.map(s => s.settings.multipleChildSelections)),
    );

    // snapshot of form after generation for easy clearing of entries
    public initialFormState: any = null;

    // supplied to material component to force updates of material validation
    public readonly materialTrigger$: ReplaySubject<void> = new ReplaySubject<void>(1);

    // supplied to supporting components to force service validation updates
    public readonly validationTrigger$: Subject<void> = new Subject<void>();

    /**
     * Total amount of time available to allocate
     * based on start/end time and crew size
     */
    public remainingTimeAvailableToAllocate$: Observable<number> = combineLatest([
        this.servicesFormGroup.controls.services.valueChanges,
        this.totalAvailableTimeToAllocate$,
    ])
        .pipe(
            takeUntil(this._unsub),
            map(([servicesArray, totalAvailableTime]) => {
                let timeLogged = 0;
                for(const s of servicesArray) {
                    if (!s.children || s.children.length === 0) {
                        if (typeof s.hours !== 'undefined' && typeof s.minutes !== 'undefined') {
                            timeLogged += s.hours * MINUTES_IN_HOUR + s.minutes;
                        }
                    } else {
                        for(const c of s.children) {
                            if (typeof c.hours !== 'undefined' && typeof c.minutes !== 'undefined') {
                                timeLogged += c.hours * MINUTES_IN_HOUR + c.minutes;
                            }
                        }
                    }
                }
                return totalAvailableTime - timeLogged;
            }),
            shareReplay(1),
        );

    /**
     * Formatted string to display based on remaining
     * time left to allocate
     */
    public formattedRemainingTimeToAllocate$: Observable<string> = combineLatest([
        this.remainingTimeAvailableToAllocate$,
        this.totalAvailableTimeToAllocate$,
    ])
        .pipe(
            takeUntil(this._unsub),
            map(([time, totalAvailableTime]) => {
                if (time < 0) {
                    const totalHours = Math.floor(totalAvailableTime / MINUTES_IN_HOUR);
                    const totalMinutes = totalAvailableTime % MINUTES_IN_HOUR;
                    return `Over Allocated ${totalHours}h ${totalMinutes}m`;
                }
                const hours = Math.floor(time / MINUTES_IN_HOUR);
                const minutes = time % MINUTES_IN_HOUR;
                return `Allocate Remaining ${hours}h ${minutes}m`;
            }),
            shareReplay(1),
        );
    public readonly icemeltServices = [
        ServiceClassification.Icemelt_Parking_Lot,
        ServiceClassification.Icemelt_Sidewalk,
        // ServiceClassification.Other,
    ];

    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly _toast: ToastService,
        private _translateService: TranslateService,
    ) {
    }

    ngOnInit(): void {
    }

    public ngOnChanges(changes: SimpleChanges) {
        console.log(changes);
        this.init();
    }

    ngOnDestroy(): void {
        this._unsub.next();
        this._unsub.complete();
    }

    public async init(): Promise<void> {
        // Manually subscribe so that it can detect valueChanges
        this.remainingTimeAvailableToAllocate$.pipe(takeUntil(this._unsub))
            .subscribe();

        this.services$.pipe(takeUntil(this._unsub))
            .subscribe(services => {
                // Clear out previous services
                this.clearServicesFormArray();

                // Alphabetize services by name
                services.sort((a, b) => (a.name > b.name ? 1 : -1));

                // Create form group for each service
                services.forEach(s => {
                    this.servicesFormArray.push(this._createServiceGroup(s));
                });

                this.emitValidServices();
                this.initialFormState = this.servicesFormArray.value;
            });

        // Listen for changes and emit valid services
        combineLatest([
            this.services$,
            this.servicesFormGroup.valueChanges,
            this.serviceTypeInfo$,
            this.validationTrigger$,
        ])
            .pipe(takeUntil(this._unsub))
            .subscribe(_ => {
                this.emitValidServices();
            });

        // If serviceTypeInfo wasn't initially valid, make sure when it is
        // to re-run material calculations on previouly filled out services.
        combineLatest([this.serviceTypeInfo$, this.mappingClassificationAreas$])
            .pipe(takeUntil(this._unsub))
            .subscribe(([info, areas]) => {
                if (!!info && !!areas) {
                    this.materialTrigger$.next();
                }
            });
        this.requireTime$.next(this.requireTime);
    }

    public getAvailableMaterials(service) {
        let materialsArray = [];
        if (!!this.isRfpOldSystem) {
            materialsArray = this.materials;
            materialsArray
                .filter(obj => !obj.name)
                .map(obj => obj.name = obj.materialName);
            return materialsArray
        }
        if (!!this.filteredMaterials && this.filteredMaterials.length > 0) {
            const founded = this.filteredMaterials.map((el) => {
                return el.materials;
            });

            // Check if all elements in the 'founded' array are undefined
            const allUndefined = founded.every((element) => element === undefined);

            if (allUndefined) {
                console.log("All elements are undefined");
                materialsArray = this.materials;
                materialsArray
                    .filter(obj => !obj.name)
                    .map(obj => obj.name = obj.materialName);
                return materialsArray
            }

            if (this.companyType === CompanyType.Landscaper && !!this.client) {
                console.log("STEPPING UP HERE?????")
                return this.materials;
            }
            const materials = this.filteredMaterials.filter((fM) => {
                const isChild = service.value.children.find((item) => {
                    return fM.serviceId === item._id
                })
                if (!!isChild) {
                    return fM.materials;
                }
                if (fM.serviceId === service.value._id) {
                    return fM.materials;
                }
            })
            const mapped = materials.map((ent) => {
                materialsArray = ent.materials;
                materialsArray.forEach(function(obj) {
                    if (!obj.name) {
                        obj.name = obj.materialName;
                        delete obj.materialName;
                    }
                });
            })
        } else {
            materialsArray = this.materials;
            materialsArray
                ?.filter(obj => !obj.name)
                .map(obj => obj.name = obj.materialName);
        }

        return materialsArray;
    }

    /**
     * Emit services with valid information to parent component
     * Runs on component load and on every instance of form value change
     * Whether services with valid info should be auto-selected depends
     * on whether time allocation is required to services
     */
    public emitValidServices(): void {
        let validServices: TimesheetServiceDto[] = [];
        const autoSelect = this.requireTime === true;
        for(let i = 0; i < this.servicesFormArray.length; ++i) {
            const service = this.servicesFormArray.at(i).value;
            if (service.children.length === 0) {
                if (this.serviceGroupIsValid(i)) {
                    if (autoSelect) {
                        this._setParentGroupSelected(i, true);
                    }
                    if (service.selected) {
                        const validService = new TimesheetServiceDto(service);
                        const group = this.servicesFormArray.at(i) as UntypedFormGroup;
                        if (!group.contains('material')) {
                            delete validService.material;
                        } else {
                            const materialGroup = group.controls.material as UntypedFormGroup;
                            if (materialGroup.controls.note.enabled && !!materialGroup.value.note) {
                                validService.note = materialGroup.value.note;
                            }
                        }
                        validServices.push(validService);
                    }
                } else {
                    this._setParentGroupSelected(i, false);
                }
            } else {
                // child service helper function
                const getChildDto = (child: any, _i: number, _j: number): TimesheetServiceDto => {
                    const childDto = new TimesheetServiceDto(child);
                    const parentGroup = this.servicesFormArray.at(_i) as UntypedFormGroup;
                    const childGroup = (parentGroup.controls.children as UntypedFormArray).at(
                        _j,
                    ) as UntypedFormGroup;
                    if (!childGroup.contains('material')) {
                        delete childDto.material;
                    } else {
                        const materialGroup = childGroup.controls.material as UntypedFormGroup;
                        if (materialGroup.controls.note.enabled && !!materialGroup.value.note) {
                            childDto.note = materialGroup.value.note;
                        }
                    }
                    return  childDto
                    // return { ...childDto, serviceId:parentGroup.value._id };
                };

                if (!service.settings.multipleChildSelections) {
                    const child = service.children[0];
                    if (this.serviceGroupIsValid(i, 0)) {
                        this._setParentGroupSelected(i, true);
                        if (service.selected) {
                            validServices.push(getChildDto(child, i, 0));
                        }
                    } else {
                        this._setParentGroupSelected(i, false);
                    }
                } else {
                    const validChildren: TimesheetServiceDto[] = [];
                    let atLeastOneValid = false;
                    for(let j = 0; j < service.children.length; ++j) {
                        const child = service.children[j];
                        if (this.serviceGroupIsValid(i, j)) {
                            atLeastOneValid = true;
                            validChildren.push(getChildDto(child, i, j));
                        }
                    }
                    if (atLeastOneValid) {
                        this._setParentGroupSelected(i, true);
                        if (this.servicesFormArray.at(i).value?.selected) {
                            validServices = validServices.concat(validChildren);
                        }
                    } else {
                        this._setParentGroupSelected(i, false);
                    }
                }
            }
        }
        this.validServices.next(validServices);
    }

    /** Get services as FormArray. */
    public get servicesFormArray(): UntypedFormArray {
        return this.servicesFormGroup.get('services') as UntypedFormArray;
    }

    /** Remove all formgroups from services formArray. */
    public clearServicesFormArray(): void {
        while (this.servicesFormArray.length !== 0) {
            this.servicesFormArray.removeAt(0);
        }
    }

    private _markDirty(group: UntypedFormGroup): void {
        Object.keys(group.controls)
            .forEach(key => {
                group.controls[key].markAsTouched();
                group.controls[key].markAsDirty();
                group.controls[key].updateValueAndValidity();
                if (group.controls[key] instanceof UntypedFormGroup) {
                    this._markDirty(group.controls[key] as UntypedFormGroup);
                }
            });
    }

    /**
     * Assess whether a particular parent or child service is valid
     *
     * @param i index of parent service in services FormArray.
     * @param j optional - index of child service under parent service
     */
    public serviceGroupIsValid(i: number, j?: number): boolean {
        /*
            This method can be run on either a parent service or a child service.
            Context is distinguished by whether child index j is provided
        */
        if (!this.services$.value[i]) {
            return;
        }
        const isChildService = typeof j !== 'undefined';
        if (isChildService && !this.services$.value[i].children[j]) {
            return;
        }

        const serviceGroup: UntypedFormGroup = !isChildService
            ? (this.servicesFormArray.controls[i] as UntypedFormGroup)
            : (((this.servicesFormArray.controls[i] as UntypedFormGroup).controls
                .children as UntypedFormArray).at(j) as UntypedFormGroup);
        const parentGroup = isChildService
            ? (this.servicesFormArray.controls[i] as UntypedFormGroup)
            : serviceGroup;

        /*
            single-select child service group must have a selection made
            in order to be valid, can determine by looking at _id control

            multi-select child service group must have checkbox selected
        */
        let validSingleSelect = true;
        let validMultiSelect = true;
        if (isChildService) {
            if (!parentGroup.value.settings.multipleChildSelections) {
                validSingleSelect = !!serviceGroup.value._id;
            }
            if (parentGroup.value.settings.multipleChildSelections) {
                validMultiSelect = serviceGroup.value.selected;
            }
        }

        const timeControlsValid = (group: UntypedFormGroup) => {
            return (
                group.contains('hours') &&
                group.contains('minutes') &&
                group.controls.hours.valid &&
                group.controls.minutes.valid &&
                group.value.hours + group.value.minutes > 0
            );
        };

        const validTime = this.requireTime === false || timeControlsValid(serviceGroup);

        let validMaterial = true;
        let validNote = true;
        if (serviceGroup.contains('material')) {
            const materialGroup = serviceGroup.controls.material as UntypedFormGroup;
            validMaterial = materialGroup.valid;
            validNote = materialGroup.controls.note.enabled
                ? materialGroup.controls.note.valid
                : true;
        }

        const validInfo =
            validSingleSelect && validMultiSelect && validTime && validMaterial && validNote;

        return validInfo;
    }

    /**
     * Determine whether to show checkmark in UI according to select
     *
     * @param index to evaluate
     */
    public evaluateChecked(i: number): boolean {
        const group = this.servicesFormArray.at(i) as UntypedFormGroup;
        return group.value.selected;
    }

    /**
     * Observe whether a parent service group in the service list
     * is composed of valid information without side effects
     *
     * @param index of service to be checked
     */
    public peekParentServiceValid(i: number): boolean {
        const group = this.servicesFormArray.at(i) as UntypedFormGroup;
        const hasChildren =
            group.contains('children') && (group.controls.children as UntypedFormArray).length > 0;
        if (!hasChildren) {
            return this.serviceGroupIsValid(i);
        } else {
            const multiChild = this.services$.value[i].settings.multipleChildSelections;
            const children = group.controls.children as UntypedFormArray;
            if (multiChild) {
                // all sub form groups with selected->true must be valid
                // at least one child service must be selected
                let atLeastOne = false;
                for(let j = 0; j < children.length; ++j) {
                    if (children.at(j).value.selected) {
                        atLeastOne = true;
                        if (!this.serviceGroupIsValid(i, j)) {
                            return false;
                        }
                    }
                }
                return atLeastOne;
            } else {
                // only the single child form group must be valid
                return this.serviceGroupIsValid(i, 0);
            }
        }
    }

    /**
     * auto-magically set selected value of parent service form group
     * does NOT check validity of group, only use after validity has
     * been established
     *
     * @param i - index of parent group in form array
     * @param selected - bool value to set
     */
    private _setParentGroupSelected(i: number, selected: boolean): void {
        const group = this.servicesFormArray.at(i) as UntypedFormGroup;
        group.controls.selected.setValue(selected, { emitEvent: false });
    }

    /**
     * Attempt to manually toggle selection of a parent service group
     * Only operable for timesheets where time allocation is not required
     *
     * @param i index of parent service group
     * @param event - mouse click event
     */
    public attemptSelectToggle(i: number, event: any): void {
        //event.stopPropagation();
        const panel = this.panels.toArray()[i];
        const group = this.servicesFormArray.at(i) as UntypedFormGroup;
        const selected = !!group.value.selected;
        if (this.requireTime === true) {
            // time required with auto select
            if (!selected && !this.peekParentServiceValid(i)) {
                this._toast.error(this._translateService.instant('MODAL_MESSAGES.FILL_SERVICE'));
                // this._toast.error('Fill service info to auto select')
                this._markDirty(group);
                panel.open();
            } else {
                this._toast.error(this._translateService.instant('MODAL_MESSAGES.CLEAR_SERVICE'));
                // this._toast.error('Clear service info to deselect')
                panel.open();
            }
        } else {
            // time not required / manual fill in
            if (this.peekParentServiceValid(i)) {
                group.controls.selected.setValue(!selected);
                this.emitValidServices();
            } else {
                // call attention to invalid fields
                this._toast.error(this._translateService.instant('MODAL_MESSAGES.FILL_SERVICE'));
                this._markDirty(group);
                panel.open();
            }
        }
    }

    /**
     * Clears form group fields for parent and child service(s)
     * @param i index of service in services FormArray.
     */
    public clearServiceGroup(i: number): void {
        // reset helper
        const setZeroValues = (group: UntypedFormGroup) => {
            if (group.contains('hours')) {
                group.controls.hours.setValue(0);
            }
            if (group.contains('minutes')) {
                group.controls.minutes.setValue(0);
            }
            if (group.contains('selected')) {
                group.controls.selected.setValue(false);
            }
            if (group.contains('material')) {
                const materialGroup = group.controls.material as UntypedFormGroup;
                materialGroup.reset();
                materialGroup.controls.note.disable();
            }
        };

        const group = this.servicesFormArray.at(i) as UntypedFormGroup;
        group.reset(this.initialFormState[i]);
        setZeroValues(group);

        const children = group.controls.children as UntypedFormArray;
        for(let _c = 0; _c < children.length; ++_c) {
            const c = children.at(_c) as UntypedFormGroup;
            setZeroValues(c);
        }
    }

    /**
     * Returns new formgroup from service. If service matches previously selected
     * service, prefill form field values.
     * @param service
     */
    private _createServiceGroup(service: TimesheetServiceDto): UntypedFormGroup {
        const group = this._serviceGroup(service);
        /*
            Desktop and mobile app have different representations of
            selected services data - desktop retrieves an existing
            timesheet from the API while mobile retrieves a non-
            submitted timesheet from disk. These are represented
            differently. Helper function lets us count on a single
            representation
        */
        const mapDto = (dto: TimesheetServiceDto): any => {
            let obj = {
                serviceId: dto._id,
                durationMinutes: dto.hours * MINUTES_IN_HOUR + dto.minutes,
                note: dto.note,
            };
            if (!dto.hasOwnProperty('hours')) {
                obj = {
                    serviceId: dto.serviceId,
                    durationMinutes: dto.durationMinutes,
                    note: dto.note,
                };
            }
            if (!!dto.material) {
                let materialMatch = this.materials?.find(m => m._id === dto.material._id);
                if (!materialMatch) {
                    if (!!dto.material.snapShot) {
                        materialMatch = this.materials?.find(m => m._id.toString() === dto.material.snapShot.material._id.toString());
                    } else {
                        if (!!dto.material._id) {
                            materialMatch = this.materials?.find(m => m._id.toString() === dto.material._id.toString());
                        } else {
                            materialMatch = this.materials?.find(m => m.name === dto.material.type.name.toString());
                        }
                        if (!materialMatch) {
                            materialMatch = this.materials?.find(m => m.name === dto.material.type.name.toString());
                        }
                    }
                }
                if (!!dto.material.amount) {
                    obj['material'] = {
                        snapShot: {
                            material: {
                                _id: materialMatch?._id,
                                name: materialMatch?.name,
                                activeIngredient: materialMatch?.activeIngredient,
                                measurement: materialMatch?.measurement,
                            },
                        },
                        quantity: dto.material.amount,
                        name: materialMatch?.name,
                    };
                } else {
                    obj['material'] = {
                        snapShot: {
                            material: {
                                _id: materialMatch?._id,
                                name: materialMatch?.name,
                                activeIngredient: materialMatch?.activeIngredient,
                                measurement: materialMatch?.measurement,
                            },
                        },
                        quantity: dto.material.quantity,
                        name: materialMatch?.name,
                    };
                }
            }
            return obj;
        };
        const mappedSelected = this.selectedServices.map(s =>
            mapDto(s)
        );

        const matches: {
            group: UntypedFormGroup;
            serviceData: any;
            selectedData: any;
            isChild?: boolean;
            parentGroup?: UntypedFormGroup;
        }[] = [];

        const parentServiceSelectedMatch = mappedSelected.find(s => s.serviceId === service._id);
        if (!!parentServiceSelectedMatch) {
            matches.push({
                group: group,
                serviceData: service,
                selectedData: parentServiceSelectedMatch,
            });
        } else {
            if (service.children.length > 0) {
                for(let _c = 0; _c < service.children.length; ++_c) {
                    const childService = service.children[_c];
                    const childServiceSelectedMatch = mappedSelected.find(
                        s => s.serviceId === childService._id,
                    );
                    if (!!childServiceSelectedMatch) {
                        let childGroup: UntypedFormGroup = null;
                        if (service.settings.multipleChildSelections) {
                            childGroup = (group.controls.children as UntypedFormArray).at(_c) as UntypedFormGroup;
                        } else {
                            childGroup = (group.controls.children as UntypedFormArray).at(0) as UntypedFormGroup;
                            childGroup.controls._id.setValue(childServiceSelectedMatch.serviceId);
                            if (
                                !childServiceSelectedMatch.material &&
                                childGroup.contains('material')
                            ) {
                                childGroup.removeControl('material');
                            }
                            if (
                                childServiceSelectedMatch.material &&
                                !childGroup.contains('material')
                            ) {
                                this._addMaterialControl(childGroup);
                            }
                        }
                        matches.push({
                            group: childGroup,
                            serviceData: childService,
                            selectedData: childServiceSelectedMatch,
                            isChild: true,
                            parentGroup: group,
                        });
                    }
                }
            }
        }

        for(const match of matches) {
            match.group.controls.selected.setValue(true);
            if (typeof match.isChild === 'boolean' && match.isChild) {
                match.parentGroup.controls.selected.setValue(true);
            }
            const hours = Math.floor(match.selectedData.durationMinutes / MINUTES_IN_HOUR);
            const minutes = match.selectedData.durationMinutes % MINUTES_IN_HOUR;
            match.group.controls.hours.setValue(hours);
            match.group.controls.minutes.setValue(minutes);
            if (match.serviceData.settings.hasMaterial && !!match.selectedData.material) {
                const materialGroup = match.group.controls.material as UntypedFormGroup;
                materialGroup.controls._id.setValue(
                    match.selectedData.material.snapShot.material._id,
                );
                materialGroup.controls.type.setValue(match.selectedData.material);
                materialGroup.controls.amount.setValue(match.selectedData.material.quantity);
                materialGroup.controls.note.setValue(match.selectedData.note);
                if (!!match.selectedData.note) {
                    materialGroup.controls.note.enable();
                }
            }
        }

        return group;
    }

    private _serviceGroup(service: any): UntypedFormGroup {
        const group = this._baseServiceGroup(service, false);
        const children = new UntypedFormArray([]);
        if (service.children.length > 0) {
            if (service.settings.multipleChildSelections) {
                for(const c of service.children) {
                    children.push(this._baseServiceGroup(c, true));
                }
            } else {
                children.push(this._singleSelectChildServiceGroup(service.children));
            }
        }
        group.addControl('children', children);
        group.addControl(
            'settings',
            this._fb.group({
                serviceClassification: service.settings.serviceClassification,
                multipleChildSelections: service.settings.multipleChildSelections,
            }),
        );
        return group;
    }

    private _baseServiceGroup(service: any, isChild: boolean): UntypedFormGroup {
        const group = this._fb.group({
            _id: service._id,
            name: service.name,
            selected: false,
        });
        if (isChild || (!isChild && service.children.length === 0)) {
            group.addControl('hours', new UntypedFormControl(0, [Validators.required, Validators.min(0)]));
            group.addControl(
                'minutes',
                new UntypedFormControl(0, [Validators.required, Validators.min(0)]),
            );
        }
        if (!!this.icemeltServices.includes(service.settings.serviceClassification)) {
            if (!!service.settings.hasMaterial) {
                this._addMaterialControl(group);
            }
        } else {
            if (!!service.settings.hasMaterial) {
                this._addMaterialControl(group);
            }
        }
        return group;
    }

    private _addMaterialControl(group: UntypedFormGroup): void {
        group.addControl(
            'material',
            this._fb.group({
                _id: [''],
                type: ['', [Validators.required]],
                amount: ['', [Validators.required, Validators.min(0)]],
                note: ['', [Validators.required]],
            }),
        );
        (group.controls.material as UntypedFormGroup).controls.note.disable();
    }

    private _singleSelectChildServiceGroup(children: any[]): UntypedFormGroup {
        const group = this._baseServiceGroup(children[0], true);
        group.controls._id.setValue('');
        group.controls.name.setValue('');
        return group;
    }

    public singleChildSelectEvent(event: MatRadioChange, i: number, c: any): void {
        const group = this.servicesFormArray.at(i) as UntypedFormGroup;
        const child = (group.controls.children as UntypedFormArray).at(0) as UntypedFormGroup;
        child.controls.name.setValue(c.name);
        child.controls.selected.setValue(true);
        if (c.settings.hasMaterial && !child.contains('material')) {
            this._addMaterialControl(child);
            /*
                if we've moved to a child that has material, and that
                material form is not valid, and time is not required (manual selections)
                then de-select the parent service
            */
            const validMaterial = child.controls.material.valid;
            const validNote = (child.controls.material as UntypedFormGroup).controls.note.enabled
                ? (child.controls.material as UntypedFormGroup).controls.note.valid
                : true;
            if (this.requireTime === false && (!validMaterial || !validNote)) {
                group.controls.selected.setValue(false, { emitEvent: true });
                this._markDirty(child.controls.material as UntypedFormGroup);
            }
        }
        if (!c.settings.hasMaterial && child.contains('material')) {
            child.removeControl('material');
        }
        this.emitValidServices();
    }

    public multiChildSelectEvent(event: MatCheckboxChange, i: number, j: number, c: any): void {
        /*
            if we've selected a child that has material, our material form
            is not valid, and time allocation is not required (manual selections)
            then de-select the parent service
        */
        const group = this.servicesFormArray.at(i) as UntypedFormGroup;
        if (this.requireTime === false && event.checked) {
            const child = (group.controls.children as UntypedFormArray).at(j) as UntypedFormGroup;
            if (c.settings.hasMaterial) {
                const validMaterial = child.controls.material.valid;
                const validNote = (child.controls.material as UntypedFormGroup).controls.note.enabled
                    ? (child.controls.material as UntypedFormGroup).controls.note.valid
                    : true;
                if (!validMaterial || !validNote) {
                    group.controls.selected.setValue(false, { emitEvent: true });
                    this._markDirty(child.controls.material as UntypedFormGroup);
                }
            }
        }
        /*
            if we've de-selected a multi-select child, there must be at least
            one child service selected for the parent service to remain selected
        */
        if (!event.checked) {
            const children = group.controls.children as UntypedFormArray;
            let atLeastOneSelected = false;
            for(let _c = 0; _c < children.length; ++_c) {
                const child = children.at(_c) as UntypedFormGroup;
                if (child.value.selected === true) {
                    atLeastOneSelected = true;
                    break;
                }
            }
            if (!atLeastOneSelected) {
                group.controls.selected.setValue(false, { emitEvent: true });
            }
        }
        /*
            when time required call attention to form fields on open
        */
        if (this.requireTime === true && event.checked) {
            const child = (group.controls.children as UntypedFormArray).at(j) as UntypedFormGroup;
            this._markDirty(child);
        }

        this.emitValidServices();
    }

    public serviceRequiresMaterialNote(i: number): boolean {
        const group = this.servicesFormArray.at(i) as UntypedFormGroup;
        if (group.contains('material')) {
            const materialGroup = group.controls.material as UntypedFormGroup;
            if (materialGroup.controls.note.enabled && !materialGroup.controls.note.valid) {
                return true;
            }
        }
        const children = group.controls.children as UntypedFormArray;
        for(let _c = 0; _c < children.length; ++_c) {
            const c = children.at(_c) as UntypedFormGroup;
            if (c.contains('material')) {
                const materialGroup = c.controls.material as UntypedFormGroup;
                if (materialGroup.controls.note.enabled && !materialGroup.controls.note.valid) {
                    return true;
                }
            }
        }
        return false;
    }
}
