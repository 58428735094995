import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RfxForm } from '@refactor/common';
import { ExportDateRangeModalComponent } from '../modals/export-date-range-modal/export-date-range-modal.component';
import { FormBuilderConfirmModalComponent } from '../modals/form-builder-confirm-modal/form-builder-confirm-modal.component';

@Injectable({
    providedIn: 'root'
})
export class FormBuilderModalService {
    constructor(private _dialog: MatDialog) {}

    /**
     * @param message       The description of the confirmation.
     * @param title         The action to confirm. This should be in title case.
     *                      Defaults to "Are you sure?"
     * @param confirmation  The final confirmation text used in the "confirm"
     *                      button. This should be in title case.
     *                      Defaults to "Yes"
     */
    public confirm(
        message: string,
        title?: string,
        confirmation?: string
    ): MatDialogRef<FormBuilderConfirmModalComponent> {
        const dialogRef = this._dialog.open(FormBuilderConfirmModalComponent, {
            autoFocus: false,
            maxHeight: '90vh',
            data: {
                message: message,
                title: title,
                confirmation: confirmation
            }
        });
        return dialogRef;
    }

    /**
     * @param form          The form to choose the date range for.
     */
    public chooseExportDateRange(
        form?: RfxForm
    ): MatDialogRef<ExportDateRangeModalComponent> {
        const dialogRef = this._dialog.open(ExportDateRangeModalComponent, {
            autoFocus: false,
            maxHeight: '90vh',
            data: { form: form }
        });
        return dialogRef;
    }
}
