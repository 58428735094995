<rfx-address [addressFormGroup]="addressFormGroup">
    <ng-template let-af="addressForm" let-State="State">
        <div class="address-form" [formGroup]="af">
            <mat-form-field>
                <input matInput placeholder="Address" formControlName="line1" />
                <mat-error [rfxError]="af.get('line1')"></mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Address Line 2" formControlName="line2" />
                <mat-error [rfxError]="af.get('line2')"></mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="City" formControlName="city" />
                <mat-error [rfxError]="af.get('city')"></mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-select placeholder="State" formControlName="state">
                    <mat-option *ngFor="let s of State.members" [value]="s">
                        {{ State.toString(s) }}
                    </mat-option>
                </mat-select>
                <mat-error [rfxError]="af.get('state')"></mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Zip" formControlName="zip" />
                <mat-error [rfxError]="af.get('zip')"></mat-error>
            </mat-form-field>
        </div>
    </ng-template>
</rfx-address>
