import { LocationIdentityWithoutIds } from './location-identity';
import { CompanyIdentityWithoutIds } from '../company/company-identity';
import { LocationServiceTypeWithoutIds } from './location-service-type';

export class LocationSimpleWithoutIds {
    public _id: any;
    public identity: LocationIdentityWithoutIds = new LocationIdentityWithoutIds();
    public companyIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public serviceTypes: LocationServiceTypeWithoutIds[] = [];
    public tags: any[] = [];

    public createdByIdentity: { _id: string; name: string };

    constructor(params?: Partial<LocationSimpleWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.identity = !!params.identity
                ? new LocationIdentityWithoutIds(params.identity)
                : this.identity;
            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentityWithoutIds(params.companyIdentity)
                : this.companyIdentity;
            this.serviceTypes = Array.isArray(params.serviceTypes)
                ? params.serviceTypes.map(s => new LocationServiceTypeWithoutIds(s))
                : this.serviceTypes;
            this.tags = Array.isArray(params.tags) ? params.tags : this.tags;
            this.createdByIdentity = params.createdByIdentity || this.createdByIdentity;
        }
    }
}
