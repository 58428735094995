import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { RfxGridFilterControlComponent, RfxGridFilterSelectOption } from '@refactor/ngx/grid';
import { RoleService, Role, Permission } from '@gm2/ui-common';
import { Observable, Subject, iif, of, combineLatest } from 'rxjs';
import { map, takeUntil, switchMap, shareReplay } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { UserState } from '@gm2/ui-state';

@Component({
	selector: 'gm2-grid-filter-facility-manager-role',
	templateUrl: './grid-filter-facility-manager-role.component.html',
	styleUrls: ['./grid-filter-facility-manager-role.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: RfxGridFilterControlComponent,
			useExisting: GridFilterFacilityManagerRoleComponent
		}
	]
})
export class GridFilterFacilityManagerRoleComponent implements OnInit {
    @Select(UserState.userPermissions)
    public permissions$: Observable<Permission[]>;
	@Input()
	public name: string = 'facilityManagerRoleId';
	@Input()
	public placeholder: string = 'Facility Manager Type';
    @Input()
    public selectedFmRoleIds$: Observable<string[]> = undefined;
    @Output()
    public fmRoleId: EventEmitter<string> =
        new EventEmitter<string>();

    public facilityManagerRoles$: Observable<Role[]> = undefined;

    public options$: Observable<RfxGridFilterSelectOption[]> = undefined;

	public controlGroup: UntypedFormGroup;

	private _onDestroy$: Subject<void> = new Subject();

	constructor(
        private readonly _roleService: RoleService
	){}

	ngOnInit(): void {
		this.controlGroup = new UntypedFormGroup({
			value: new UntypedFormControl('')
		});
        this.controlGroup.controls.value.valueChanges
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(selFmRoleId => this.fmRoleId.emit(selFmRoleId));
	}

    ngOnChanges(): void {
        // initialize facility manager roles pipe once permissions defined
        if (this.facilityManagerRoles$ === undefined && !!this.permissions$) {
            this.facilityManagerRoles$ = this.permissions$.pipe(
                switchMap(permissions =>
                    iif(
                        () => permissions.includes(Permission.FacilityManagerFilterDisplay),
                        this._roleService.getFacilityManagerFilterRoles(),
                        of(null)
                    )
                ),
                shareReplay(1)
            );
        }
        // initialize options pipe once input defined
        if (this.options$ === undefined &&
            !!this.facilityManagerRoles$ &&
            !!this.selectedFmRoleIds$
        ) {
            this.options$ = combineLatest([
                this.facilityManagerRoles$,
                this.selectedFmRoleIds$
            ]).pipe(
                takeUntil(this._onDestroy$),
                map(([fmRoles, selRoleIds]) => {
                    if (!!fmRoles && fmRoles.length > 0) {
                        const roles = (!!selRoleIds && selRoleIds.length > 0)
                            ? fmRoles.filter(r => selRoleIds.includes(r._id))
                            : fmRoles;
                        return [
                            { value: null, label: '' },
                            ...roles.sort((a, b) => a.name < b.name ? -1 : 1)
                                .map(r => ({ value: r._id, label: r.name }))
                        ];
                    } else {
                        return [{ value: null, label: 'None Found'}];
                    }
                })
            );
        }
    }

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

}
