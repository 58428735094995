import { WorkOrderInvoiceStatus } from '../../enums';

export class WorkOrderInvoiceIdentityWithoutIds {
    public _id: any;
    public number: string;
    public status: WorkOrderInvoiceStatus;

    constructor(params?: Partial<WorkOrderInvoiceIdentityWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.number = params.number || this.number;
            this.status = params.status || this.status;
        }
    }
}
