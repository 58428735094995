export class RfxHelpSelectOption {
    public _id: unknown;
    public label: string;

    constructor(params?: Partial<RfxHelpSelectOption>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.label = params.label || this.label;
        }
    }
}
