import { Company } from './Company';
import { CompanyProfileType } from '@gm2/common';

export class CompanyCreateDto {
    public name: string = '';
    public phone: string = '';
    public address: {
        line1: string;
        line2: string;
        city: string;
        state: string;
        postalCode: string;
    };
    public email: string;
    public companyId: string = '';
    public companyProfileType: CompanyProfileType = null;

    constructor(params?: Partial<CompanyCreateDto>) {
        if (!!params) {
            this.name = params.name || this.name;
            this.email = params.email || this.email;
            this.phone = params.phone || this.phone;
            this.address = params.address || this.address;
            this.companyProfileType = params.companyProfileType || this.companyProfileType;
        }
    }

    /**
     * Construct a new instance of this dto from an instance of another class
     */
    public static from<T extends Company>(
        type: { new (params: Partial<T>): T },
        value: T
    ): CompanyCreateDto {
        console.log(value);
        if (type === Company) {
            return new CompanyCreateDto({
                name: value.profile.name,
                email: value.profile.email,
                address: value.profile.address,
                phone: value.profile.phone,
                companyProfileType: value.profile.companyProfileType
            });
        }

        return new CompanyCreateDto();
    }
}
