import { UserIdentity } from '../user/UserIdentity';
import { LocationCreateDto } from './location-create.dto';

export class LocationSubmitDto {
    public name: string;
    public companyId: string;
    public costCenter: string;
    public strategicAccountManagers: UserIdentity[];
    public facilitiesManagers: UserIdentity[];
    public productionManagers: UserIdentity[];
    public timezone: string;
    public accountingJobName: string;
    public accountingJobClass: string;
    public operationHours: {
        weekdays: string;
        friday: string;
        weekend: string;
    };
    public address: {
        line1: string;
        line2: string;
        city: string;
        state: string;
        postalCode: string;
    };
    public email: string;
    public phone: string;
    public serviceDay: string;
    public serviceInterval: string;
    public landscape: string;
    public landscapePerVisit: string;
    public snow: string;

    constructor(params?: Partial<LocationSubmitDto>) {
        if (!!params) {
            this.name = params.name || this.name;
            this.companyId = params.companyId || this.companyId;
            this.costCenter = params.costCenter || this.costCenter;
            this.strategicAccountManagers =
                params.strategicAccountManagers || this.strategicAccountManagers;
            this.facilitiesManagers = params.facilitiesManagers || this.facilitiesManagers;
            this.productionManagers = params.productionManagers || this.productionManagers;
            this.timezone = params.timezone || this.timezone;
            this.accountingJobName = params.accountingJobName || this.accountingJobName;
            this.accountingJobClass = params.accountingJobClass || this.accountingJobClass;
            this.operationHours = params.operationHours || this.operationHours;
            this.address = params.address || this.address;
            this.email = params.email || this.email;
            this.phone = params.phone || this.phone;
            this.serviceDay = params.serviceDay || this.serviceDay;
            this.serviceInterval = params.serviceInterval || this.serviceInterval;
        }
    }
}
