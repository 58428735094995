import { InvoiceStatus } from '../../enums';
import { UserIdentityWithoutIds } from '../user/user-identity';
import { parseISODate } from '@refactor/common';
import { InvoiceDeclinedWithoutIds } from './invoice-declined';

export class InvoiceHistoryWithoutIds {
    public userIdentity: UserIdentityWithoutIds = new UserIdentityWithoutIds();
    public invoiceStatus: InvoiceStatus;
    public date: Date = new Date();
    public declined: InvoiceDeclinedWithoutIds;

    constructor(params?: Partial<InvoiceHistoryWithoutIds>) {
        if (!!params) {
            this.userIdentity = !!params.userIdentity
                ? new UserIdentityWithoutIds(params.userIdentity)
                : this.userIdentity;

            this.invoiceStatus = params.invoiceStatus || this.invoiceStatus;
            this.date = !!params.date ? parseISODate(params.date) : this.date;

            this.declined = !!params.declined
                ? new InvoiceDeclinedWithoutIds(params.declined)
                : this.declined;
        }
    }
}
