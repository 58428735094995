export enum InsuranceStatus {
    Active = '[InsuranceStatus] Active',
    Expired = '[InsuranceStatus] Expired',
    Waived = '[InsuranceStatus] Waived',
    NotNeeded = '[InsuranceStatus] NotNeeded'
}

export namespace InsuranceStatus {
    export function toString(status: InsuranceStatus): string {
        switch (status) {
            case InsuranceStatus.Active:
                return 'Active';
            case InsuranceStatus.Expired:
                return 'Expired';
            case InsuranceStatus.Waived:
                return 'Waived';
            case InsuranceStatus.NotNeeded:
                return 'Not Needed';
            default:
                return '';
        }
    }

    export const members: InsuranceStatus[] = [
        InsuranceStatus.Active,
        InsuranceStatus.Expired,
        InsuranceStatus.Waived,
        InsuranceStatus.NotNeeded
    ];
}
