export enum CompanyStatus {
    Active = '[CompanyStatus] active',
    InActive = '[CompanyStatus] inActive'
}

export namespace CompanyStatus {
    export function toString(status: CompanyStatus): string {
        switch (status) {
            case CompanyStatus.Active:
                return 'Active';
            case CompanyStatus.InActive:
                return 'InActive';
            default:
                return 'All';
        }
    }

    export const members: CompanyStatus[] = [
        CompanyStatus.Active,
        CompanyStatus.InActive
    ];
    export const options: CompanyStatus[] = [
        null,
        CompanyStatus.Active,
        CompanyStatus.InActive
    ];
}
