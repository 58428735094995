import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'gm2-export-date-range-modal',
    templateUrl: './export-date-range-modal.component.html',
    styleUrls: ['./export-date-range-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportDateRangeModalComponent implements OnInit {

    public form: UntypedFormGroup = this._fb.group({
        dateRange: [null]
    });

    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly _dialogRef: MatDialogRef<ExportDateRangeModalComponent>
    ) {}

    ngOnInit(): void {}

    public close(): void {
        this._dialogRef.close(false);
    }

    public confirm(): void {
        this._dialogRef.close(this.form.controls.dateRange.value);
    }
}
