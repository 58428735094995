import { DisplayedTimezone } from '../../enums';

export class UserSettings {
    public acceptedTerms: boolean = false;
    public seenWelcomeModal: boolean = false;
    public displayedTimezone: DisplayedTimezone = DisplayedTimezone.User;

    constructor(params?: Partial<UserSettings>) {
        if (!!params) {
            this.acceptedTerms = params.acceptedTerms || this.acceptedTerms;
            this.seenWelcomeModal = params.seenWelcomeModal || this.seenWelcomeModal;
            this.displayedTimezone = params.displayedTimezone || this.displayedTimezone;
        }
    }
}
