import { BidWithoutIds } from './bid';
import { ClientPricingWithoutIds } from './client-pricing';
import { LocationIdentityWithoutIds } from '../location/location-identity';
import { Address } from '../base/address';
import { parseISODate } from '@refactor/common';
import { ServiceWithoutIds } from '../service/service';
import { BillingType } from '../../enums/contract/billing-type';
import { FirstApprovalWithoutIds } from './first-approval';
import { AwardedBidWithoutIds } from './awarded-bid';
import { ContractIdentityWithoutIds } from '../contract/contract-identity';
import { CompanyIdentityWithoutIds } from '../company/company-identity';

export class RfpBidsSimpleWithoutIds {
    public _id: any;
    public serviceTypeId: any;
    public locationIdentity: LocationIdentityWithoutIds = new LocationIdentityWithoutIds();
    public serviceTypeName: string;
    public address: Address = new Address();
    public bidClosing: Date;
    public contractClosing: Date;
    public billingType: BillingType;
    public bids: BidWithoutIds[] = [];
    public services: ServiceWithoutIds[] = [];
    public companyServices?: ServiceWithoutIds[] = [];
    public clientPricing: ClientPricingWithoutIds;
    public firstApproval: FirstApprovalWithoutIds = new FirstApprovalWithoutIds();
    public awardedBid: AwardedBidWithoutIds = new AwardedBidWithoutIds();
    public contractIdentity: ContractIdentityWithoutIds = new ContractIdentityWithoutIds();
    public createdByIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();

    constructor(params?: Partial<RfpBidsSimpleWithoutIds>) {
        debugger
        if (!!params) {
            this._id = params._id || this._id;
            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId;
            this.locationIdentity = !!params.locationIdentity
                ? new LocationIdentityWithoutIds(params.locationIdentity)
                : this.locationIdentity;
            this.address = !!params.address ? new Address(params.address) : this.address;
            this.serviceTypeName = params.serviceTypeId || this.serviceTypeName;
            this.bidClosing = !!params.bidClosing
                ? parseISODate(params.bidClosing)
                : this.bidClosing;
            this.contractClosing = !!params.contractClosing
                ? parseISODate(params.contractClosing)
                : this.contractClosing;
            this.billingType = params.billingType || this.billingType;
            this.bids = !!params.bids ? params.bids.map(b => new BidWithoutIds(b)) : this.bids;
            this.services = !!params.services ? params.services.map(s => new ServiceWithoutIds(s)) : this.services;
            this.companyServices = !!params.companyServices ? params.companyServices.map(s => new ServiceWithoutIds(s)) : this.companyServices;
            this.clientPricing = !!params.clientPricing ? new ClientPricingWithoutIds(params.clientPricing) : this.clientPricing;
            this.firstApproval = !!params.firstApproval ? new FirstApprovalWithoutIds(params.firstApproval) : this.firstApproval;
            this.awardedBid = !!params.awardedBid ? new AwardedBidWithoutIds(params.awardedBid) : this.awardedBid;
            this.contractIdentity = !!params.contractIdentity
                ? new ContractIdentityWithoutIds(params.contractIdentity)
                : this.contractIdentity;
            this.createdByIdentity = !!params.createdByIdentity
                ? new CompanyIdentityWithoutIds(params.createdByIdentity)
                : this.createdByIdentity;
        }
    }
}
