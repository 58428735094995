import { parseISODate } from '../../date';
import { IDateFilter } from '../../interface';

export class DateFilter implements IDateFilter {
    public startDate: Date = null;

    constructor(params?: Partial<DateFilter>) {
        if (!!params) {
            this.startDate = !!params.startDate ? parseISODate(params.startDate) : this.startDate;
        }
    }
}
