export enum ServiceTimesheetField {
    SiteCondition = 'Site_Condition',
    SnowFall = 'Snow_Fall',
    Temperature = 'Temperature',
    WeatherCondition = 'Weather_Condition',
    SurfaceTemperature = 'Surface_Temperature',
    GroundApplication = 'Ground_Application'
}

export namespace ServiceTimesheetField {
    export function toString(type: ServiceTimesheetField): string {
        switch (type) {
            case ServiceTimesheetField.SiteCondition:
                return 'Site Condition';
            case ServiceTimesheetField.SnowFall:
                return 'Snow Fall';
            case ServiceTimesheetField.Temperature:
                return 'Temperature';
            case ServiceTimesheetField.WeatherCondition:
                return 'Weather Condition';
            case ServiceTimesheetField.SurfaceTemperature:
                return 'Surface Temperature';
            case ServiceTimesheetField.GroundApplication:
                return 'Ground Application';
            default:
                return '';
        }
    }

    export const members: ServiceTimesheetField[] = [
        ServiceTimesheetField.SiteCondition,
        ServiceTimesheetField.SnowFall,
        ServiceTimesheetField.Temperature,
        ServiceTimesheetField.WeatherCondition,
        ServiceTimesheetField.SurfaceTemperature,
        ServiceTimesheetField.GroundApplication
    ];

}
