import { PaginatedList } from '@refactor/common';

export class PaginatedListWithCountAndValue extends PaginatedList<any> {
    public twelveMonthCount: number = 0;
    public twelveMonthValue: number = 0;

    constructor(params?: Partial<PaginatedListWithCountAndValue>) {
        super(params);
        if (!!params) {
            this.twelveMonthCount = params.twelveMonthCount || this.twelveMonthCount;
            this.twelveMonthValue = params.twelveMonthValue || this.twelveMonthValue;
        }
    }
}
