import { Injectable } from '@angular/core';
import {
    HttpService,
    Post,
    Body,
    Query,
    ResponseType,
    Header,
    Path,
    Get,
    MapClass,
    Put,
    Delete,
    MapValue
} from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { PaginatedList, GridParams } from '@refactor/common';
import {
    TimesheetImageDto,
    LocationImageDto,
    LocationsRecentMediaDto,
    BaseMediaDocumentWithCollectionId,
    BaseMediaDocumentWithUrl
} from '../models';

@Injectable({
    providedIn: 'root'
})
export class MediaService extends HttpService {
    /**
     * Upload one or more images to a location
     */
    @Post('/media/location/image')
    public addSiteImage(@Body() body: FormData): Observable<void> {
        return null;
    }

    @Delete('/media/location/image')
    public deleteLocationImage(@Query('path') path: string): Observable<void> {
        return null;
    }

    @Post('/media/location/images')
    @MapValue(
        res =>
            new PaginatedList<LocationImageDto>({
                count: res.count,
                docs: res.docs.map(d => new LocationImageDto(d))
            })
    )
    public getLocationImages(@Body() params: GridParams): Observable<PaginatedList<LocationImageDto>> {
        return null;
    }

    @Post('/media/timesheet/images')
    @MapValue(
        res =>
            new PaginatedList<TimesheetImageDto>({
                count: res.count,
                docs: res.docs.map(d => new TimesheetImageDto(d))
            })
    )
    public getTimesheetImages(@Body() params: any): Observable<PaginatedList<TimesheetImageDto>> {
        return null;
    }

    @Post('/media/locations/recentImages')
    public getLocationsRecentImages(
        @Body() dto: LocationsRecentMediaDto
    ): Observable<BaseMediaDocumentWithCollectionId[]> {
        return null;
    }

    @Get('/media/workOrder/:id/images')
    public getWorkOrderImages(@Path('id') id: string): Observable<BaseMediaDocumentWithUrl[]> {
        return null;
    }

    @Get('/media/workorder/:id/images')
    public getWorkOrderImagesRemote(
        @Header('Authorization') token: string,
        @Path('id') id: string
    ): Observable<BaseMediaDocumentWithUrl[]> {
        return null;
    }

    @Get('/media/workOrder/:id/clientDocuments')
    public getWorkOrderClientDocuments(@Path('id') id: string): Observable<BaseMediaDocumentWithUrl[]> {
        return null;
    }

    @Get('/media/workOrder/:id/oneGMDocuments')
    public getWorkOrderONEGMDocuments(@Path('id') id: string): Observable<BaseMediaDocumentWithUrl[]> {
        return null;
    }

    @Get('/media/workOrder/:id/servicePartnerDocuments')
    public getWorkOrderServicePartnerDocuments(@Path('id') id: string): Observable<BaseMediaDocumentWithUrl[]> {
        return null;
    }

    @Delete('/media/workOrder/')
    public deleteWorkOrderMedia(@Query('path') path: string): Observable<Object> {
        return null;
    }
}
