import { BaseDoc } from '../base/base-doc';
import { CompanyType } from '../../enums';

export class HelpWithoutIds extends BaseDoc {
    public _id: any;
    public name: string;
    public description: string;
    public content: string;
    public page: string;
    public companyTypes: CompanyType[] = [];
    public videoIds: string[] = [];

    constructor(params?: Partial<HelpWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.description = params.description || this.description;
            this.content = params.content || this.content;
            this.page = params.page || this.page;
            this.companyTypes = Array.isArray(params.companyTypes)
                ? params.companyTypes : this.companyTypes;
            this.videoIds = Array.isArray(params.videoIds)
                ? params.videoIds : this.videoIds;
        }
    }
}
