import { QuestionnaireAnswerWithoutIds } from './questionnaire-answer';
import { InsuranceStatus } from '../../enums/company/insurance-status';

export class InsuranceQuestionnaireAnswerWithoutIds extends QuestionnaireAnswerWithoutIds {
    public status: InsuranceStatus;
    constructor(params?: Partial<InsuranceQuestionnaireAnswerWithoutIds>) {
        super(params);
        if (!!params) {
            this.status = params.status || this.status;
        }
    }
}
