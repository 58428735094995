export enum WorkOrderLaborCostType {
    LegacyHoursEstimate = '[WorkOrderLaborCostType] LegacyHoursEstimate',
    MinutesActual = '[WorkOrderLaborCostType] MinutesActual',
    NewHoursEstimate = '[WorkOrderLaborCostType] NewHoursEstimate',
}

export namespace WorkOrderLaborCostType {
    export function toString(t: WorkOrderLaborCostType): string {
        switch (t) {
            case WorkOrderLaborCostType.LegacyHoursEstimate:
                return 'Hours Estimate';
            case WorkOrderLaborCostType.MinutesActual:
                return 'Minutes Actual';
            case WorkOrderLaborCostType.NewHoursEstimate:
                return 'New Hours Estimate';
            default:
                return '';
        }
    }

    export const members: WorkOrderLaborCostType[] = [
        WorkOrderLaborCostType.LegacyHoursEstimate,
        WorkOrderLaborCostType.MinutesActual,
        WorkOrderLaborCostType.NewHoursEstimate
    ];
}
