export class CompanyLicenseCert {
    public name: string = '';
    public file: any = '';
    public fileName?: string = '';

    constructor(params?: Partial<CompanyLicenseCert>) {
        if (!!params) {
            this.name = params.name || this.name;
            this.file = params.file || this.file;
            this.fileName = params.fileName || this.fileName;
        }
    }
}
