import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const MINUTES_IN_HOUR = 60;

@Component({
    selector: 'gm2-timesheet-service-list-time-allocator',
    templateUrl: './timesheet-service-list-time-allocator.component.html',
    styleUrls: ['./timesheet-service-list-time-allocator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimesheetServiceListTimeAllocatorComponent implements OnInit {

    @Input()
    public form: UntypedFormGroup;
    @Input()
    public timeAvailable$: Observable<number>;
    @Input()
    public timeAvailableString$: Observable<string>;
    @Input()
    public serviceTrigger$: Subject<void>;

    private _onDestroy$: Subject<void> = new Subject<void>();

    constructor() { }

    ngOnInit(): void {
        this.form.valueChanges
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(_ => this.serviceTrigger$.next());
    }

    ngOnDestory(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    public allocateTime(available: number): void {
        const existing =
            this.form.value.hours * MINUTES_IN_HOUR +
            this.form.value.minutes;
        const total = existing + available;
        const hours = Math.floor(total / MINUTES_IN_HOUR);
        const minutes = total % MINUTES_IN_HOUR;
        this.form.controls.hours.setValue(hours);
        this.form.controls.minutes.setValue(minutes);
        this.form.controls.hours.updateValueAndValidity();
        this.form.controls.minutes.updateValueAndValidity();
        // highlight invalid group fields
        const markDirty = (group: UntypedFormGroup) => {
            Object.keys(group.controls).forEach(key => {
                group.controls[key].markAsTouched();
                group.controls[key].markAsDirty();
                group.controls[key].updateValueAndValidity();
                if (group.controls[key] instanceof UntypedFormGroup) {
                    markDirty(group.controls[key] as UntypedFormGroup);
                }
            });
        }
        markDirty(this.form);
    }

    public getControl(name:string): UntypedFormControl {
        return this.form.controls[name] as UntypedFormControl;
    }
}
