<mat-card>
	<div class="container">
    <span mat-card-title>Registration</span>
		<div fxLayout="row" fxLayoutAlign="center">
			<form novalidate>
				<div fxLayout="column">
                    <rfx-grid-filter-search-control
                    [placeholder]="'WORK_ORDER.WO_NUMBER'|translate"
                    name="workOrderNumber"
                    ></rfx-grid-filter-search-control>
                    <rfx-grid-filter-search-control
                    [placeholder]="'WORK_ORDER.LABEL2'|translate"
                    name="servicePartnerInvoiceNumber"
                    ></rfx-grid-filter-search-control>
                    <gm2-grid-filter-client-multiple
                    rfxGridCustomControl
                    #client
                    ></gm2-grid-filter-client-multiple>
                    <gm2-grid-filter-location-multiple
                    [clientIds]="client.controlGroup.value.values"
                    rfxGridCustomControl
                    ></gm2-grid-filter-location-multiple>
                    <rfx-grid-filter-select-control
                    name="activeStatus"
                    [placeholder]="'WORK_ORDER.ACTIVE_STATUS'|translate"
                    ></rfx-grid-filter-select-control>
                    <gm2-grid-filter-workorder-progress-multiple
                    rfxGridCustomControl
                    ></gm2-grid-filter-workorder-progress-multiple>
                    <gm2-grid-filter-clear-button
                    rfxGridCustomControl
                    ></gm2-grid-filter-clear-button>
                    <gm2-grid-filter-search-button
                    rfxGridCustomControl
                    ></gm2-grid-filter-search-button>
				</div>
			</form>
		</div>
	</div>
</mat-card>