export enum AutoInvoiceType {
    All = '[AutoInvoiceType] All',
    Location = '[AutoInvoiceType] Location',
    Timesheet = '[AutoInvocieType] Timesheet'
}

export namespace AutoInvoiceType {
    export function toString(type: AutoInvoiceType): string {
        switch (type) {
            case AutoInvoiceType.All:
                return 'All';
            case AutoInvoiceType.Location:
                return 'Location';
            case AutoInvoiceType.Timesheet:
                return 'Timesheet';
            default:
                return '';
        }
    }

    export const members: AutoInvoiceType[] = [
        AutoInvoiceType.All,
        AutoInvoiceType.Location,
        AutoInvoiceType.Timesheet
    ];

    export function getDescription(type: AutoInvoiceType): string {
        switch (type) {
            case AutoInvoiceType.All:
                return 'One invoice for all timesheets';
            case AutoInvoiceType.Location:
                return 'One invoice per location';
            case AutoInvoiceType.Timesheet:
                return 'One invoice per timesheet';
            default:
                return '';
        }
    }
}
