import { CompanyServiceWithoutIds } from '@gm2/common';

export class AdminCompanyService extends CompanyServiceWithoutIds {
    public _id: string;
    public companyName: string;

    constructor(params?: Partial<AdminCompanyService>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.companyName = params.companyName || this.companyName;
        }
    }
}
