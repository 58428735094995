import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'gm2-confirm-timesheet-edit',
    templateUrl: './confirm-timesheet-edit.component.html',
    styleUrls: ['./confirm-timesheet-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmTimesheetEditComponent implements OnInit {
    public confirmation: string = 'Yes';

    constructor(
        public dialogRef: MatDialogRef<ConfirmTimesheetEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.confirmation = !!data.confirmation ? data.confirmation : this.confirmation;
    }

    ngOnInit(): void {}

    public close(): void {
        this.dialogRef.close(false);
    }

    public confirm(): void {
        this.dialogRef.close(true);
    }
}
