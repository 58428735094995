<h2 mat-dialog-title>Manage geospatial services for <br> <b>{{ data.companyName }}</b></h2>
<div mat-dialog-content>
    <form [formGroup]="form">
        <label for="geospatialtoggle" class="toggle-wrapper">
            <mat-slide-toggle
                formControlName="geospatialService" id="geospatialtoggle"
            >
                <mat-label>Enable Routing</mat-label>
            </mat-slide-toggle>
        </label>
        <br>
        <div class="buttons">
            <button
                mat-flat-button
                (click)="close()"
            >
                Cancel
            </button>
            <button
                mat-flat-button
                color="primary"
                (click)="confirm()"
                [disabled]="form.pristine"
            >
                Save
            </button>
        </div>
    </form>
</div>
