import { parseISO, differenceInCalendarDays } from 'date-fns';

export class DateBucket {
    public year: number;
    public month: number;
    public date: number;

    constructor(params?: Partial<DateBucket> | Date) {
        if (!!params) {
            if (params instanceof Date) {
                this.year = params.getFullYear();
                this.month = params.getMonth();
                this.date = params.getDate();
            } else {
                this.year = params.year || this.year;
                this.month = params.month || this.month;
                this.date = params.date || this.date;
            }
        } else {
            const now = new Date();
            this.year = now.getFullYear();
            this.month = now.getMonth();
            this.date = now.getDate();
        }
    }
}

export function isDateBucketToday({ year, month, date }: DateBucket): boolean {
    const now = new DateBucket();
    return year === now.year && month === now.month && date === now.date;
}

export function dateBucketToDate({ year, month, date }: DateBucket): Date {
    return new Date(year, month, date);
}

export function isTodayAfterDateBucket({ year, month, date }: DateBucket, gte?: boolean): boolean {
    const nowBucket = new DateBucket();
    if (!!gte && isDateBucketToday({ year: year, month: month, date: date })) {
        return true;
    }
    // Now is a later year?
    if (nowBucket.year > year) {
        return true;
    }
    // Now is a later month?
    if (nowBucket.year === year && nowBucket.month > month) {
        return true;
    }
    // Now is a later date?
    if (nowBucket.year === year && nowBucket.month === month && nowBucket.date > date) {
        return true;
    }
    return false;
}

export function isTodayBeforeDateBucket({ year, month, date }: DateBucket, lte?: boolean): boolean {
    const nowBucket = new DateBucket();
    if (!!lte && isDateBucketToday({ year: year, month: month, date: date })) {
        return true;
    }
    // Now is an earlier year?
    if (nowBucket.year < year) {
        return true;
    }
    // Now is an earlier month?
    if (nowBucket.year === year && nowBucket.month < month) {
        return true;
    }
    // Now is an earlier date?
    if (nowBucket.year === year && nowBucket.month === month && nowBucket.date < date) {
        return true;
    }
    return false;
}

export function daysAfterDateBucket(bucket: DateBucket): number {
    const now = dateBucketToDate(new DateBucket());
    const dateToCompare = dateBucketToDate(bucket);
    return differenceInCalendarDays(
        now.getTime() > dateToCompare.getTime() ? now : dateToCompare,
        now.getTime() > dateToCompare.getTime() ? dateToCompare : now
    );
}

export function daysElapsed(bucket1: DateBucket, bucket2: DateBucket): number {
    const firstBucket = dateBucketToDate(bucket1);
    const secondBucket = dateBucketToDate(bucket2);
    return differenceInCalendarDays(
        secondBucket.getTime() > firstBucket.getTime() ? secondBucket : firstBucket,
        secondBucket.getTime() > firstBucket.getTime() ? firstBucket : secondBucket
    );
}
