import { Location } from './Location';
import { UserIdentity } from '../user/UserIdentity';

export class LocationCreateDto {
    public name: string;
    public companyId: string;
    public costCenter: string;
    public strategicAccountManagers: UserIdentity[];
    public facilitiesManagers: UserIdentity[];
    public productionManagers: UserIdentity[];
    public timezone: string;
    public accountingJobName: string;
    public accountingJobClass: string;
    public operationHours: {
        weekdays: string;
        friday: string;
        weekend: string;
    };
    public address: {
        line1: string;
        line2: string;
        city: string;
        state: string;
        postalCode: string;
    };
    public email: string;
    public phone: string;
    public serviceDay: string;
    public serviceInterval: string;
    public serviceMonths: string[];
    public landscape: string;
    public landscapePerVisit: string;
    public snow: string;
    public smartNumber: string;

    constructor(params?: Partial<Location>) {
        if (!!params) {
            this.name = params.identity.name || this.name;
            this.companyId = params.companyIdentity._id || this.companyId;
            this.costCenter = params.custom.costCenter || this.costCenter;
            this.strategicAccountManagers =
                params.strategicAccountManagers || this.strategicAccountManagers;
            this.facilitiesManagers = params.facilitiesManagers || this.facilitiesManagers;
            this.productionManagers = params.productionManagers || this.productionManagers;
            this.timezone = params.identity.timezone || this.timezone;
            this.accountingJobName = params.custom.quickbooksName || this.accountingJobName;
            this.accountingJobClass = params.custom.quickbooksClass || this.accountingJobClass;
            this.operationHours = params.custom.operationHours || this.operationHours;
            this.address = params.contact.address || this.address;
            this.email = params.contact.email || this.email;
            this.phone = params.contact.phone || this.phone;
            this.serviceDay = params.custom.serviceDay || this.serviceDay;
            this.serviceInterval = params.custom.serviceInterval || this.serviceInterval;
            this.serviceMonths = params.custom.serviceMonths || this.serviceMonths;
            this.smartNumber = params.custom.smartNumber || this.smartNumber;
        }
    }
}
