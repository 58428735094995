import { BaseDoc } from '../base/base-doc';

export class ContractTemplateWithoutIds extends BaseDoc {
    public _id: any;
    public name: string = '';
    public serviceTypeId: any;
    public packageIds: any[] = [];

    constructor(params?: Partial<ContractTemplateWithoutIds>) {
        super(params);

        if (!!params) {
            this.name = params.name || this.name;
            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId;
            this.packageIds = Array.isArray(params.packageIds)
                ? params.packageIds
                : this.packageIds;
        }
    }
}
