import { IBillingTypeChargeStrategy } from '../interfaces/i-billing-type-charge-strategy';
import { TimesheetWithoutIds, MaterialWithoutIds } from '@gm2/common';

export class SpMonthlyCharges implements IBillingTypeChargeStrategy {
    public calculateCompanyCharges(
        timesheet: TimesheetWithoutIds,
        approvedMaterialDict: { [key: string]: MaterialWithoutIds }
    ): { total: number; duration: number; errors: string[] } {
        return {
            duration: 0,
            total: timesheet.snapShot.rfp.awardedBid.bid.monthlyRate,
            errors: []
        };
    }

    public calculateClientCharges(
        timesheet: TimesheetWithoutIds,
        companyTotalDuration: number
    ): { total: number; duration: number; errors: string[] } {
        let clientTotal = 0;
        if (!!timesheet.snapShot.rfp.clientPricing) {
            clientTotal = timesheet.snapShot.rfp.clientPricing.monthlyClientPrice;
        }

        return {
            duration: 0,
            total: clientTotal,
            errors: []
        };
    }
}
