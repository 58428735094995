<rfx-grid-filter-control>
	<mat-form-field appearance="outline">
		<mat-label>{{ placeholder }}</mat-label>
		<mat-select
			[formControl]="controlGroup.get('values')"
			multiple
		>
			<mat-option 
				*ngFor="let o of (workOrderStatusOptions$ | async)"
				[value]="o.value"
			>
				{{ o.label | translate }}
			</mat-option>
		</mat-select>
	</mat-form-field>
</rfx-grid-filter-control>