import { Component, OnInit, ChangeDetectionStrategy, Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: '[rfxGridListItemDef]'
})
export class GridListItemDef<T> {
    constructor(public template: TemplateRef<any>) {}
}

@Component({
    selector: 'rfx-grid-list-item',
    templateUrl: './grid-list-item.component.html',
    styleUrls: ['./grid-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridListItemComponent<T> implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
