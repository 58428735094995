import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { UiLibraryModule } from '@gm2/ui-library';
import { AppComponent } from './app.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { UiCommonModule } from '@gm2/ui-common';
import { PublicContainerComponent, SecuredContainerComponent } from './shared/containers';
import { AuthGuard } from '@ngx-gm2/routes/guards';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { AgmCoreModule } from '@agm/core';
import { MccColorPickerModule } from 'material-community-components/color-picker';
import { ThemeService } from 'ng2-charts';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { InviteService } from '@ngx-gm2/shared/services/invite.service';
import { CompanyServiceNew } from '@ngx-gm2/shared/services/company.service';
import { CompanyInviteService } from '@ngx-gm2/shared/services/company-invite.service';
import { MatDialogModule } from '@angular/material/dialog';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
@NgModule({
    declarations: [AppComponent],
    imports: [
        MatDialogModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(
            [
                {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: 'dashboard'
                },
                {
                    path: '',
                    // canActivate: [PermissionGuard],
                    component: PublicContainerComponent,
                    children: [
                        {
                            path: 'auth',
                            loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
                        },
                        {
                            path: 'remote-operation',
                            loadChildren: () =>
                                import('./remote-operation/remote-operation.module').then(
                                    m => m.RemoteOperationModule
                                )
                        }
                    ]
                },
                {
                    path: '',
                    component: SecuredContainerComponent,
                    canActivate: [AuthGuard],
                    children: [
                        {
                            path: 'error',
                            loadChildren: () =>
                                import('./error/error.module').then(m => m.ErrorModule)
                        },
                        {
                            path: 'dashboard',
                            loadChildren: () =>
                                import('./dashboard/dashboard.module').then(m => m.DashboardModule)
                        },
                        {
                            path: 'operations',
                            children: [
                                // {
                                //     path: 'location',
                                //     loadChildren: './location/location.module#LocationModule'
                                // },
                                {
                                    path: 'bid',
                                    loadChildren: () =>
                                        import('./bid/bid.module').then(m => m.BidModule)
                                },
                                {
                                    path: 'invoice',
                                    loadChildren: () =>
                                        import('./invoice/invoice.module').then(
                                            m => m.InvoiceModule
                                        )
                                },
                                {
                                    path: 'location',
                                    loadChildren: (): any =>
                                        import('./location/location.module').then(
                                            lm => lm.LocationModule
                                        )
                                },
                                {
                                    path: 'company-material',
                                    loadChildren: () =>
                                        import('./company-material/company-material.module').then(
                                            m => m.CompanyMaterialModule
                                        )
                                },
                                {
                                    path: 'company-service',
                                    loadChildren: () =>
                                        import('./company-service/company-service.module').then(
                                            m => m.CompanyServiceModule
                                        )
                                },
                                {
                                    path: 'service-activities',
                                    loadChildren: () =>
                                        import(
                                            './service-activities/service-activities.module'
                                        ).then(m => m.ServiceActivitiesModule)
                                },
                                {
                                    path: 'timesheet',
                                    loadChildren: () =>
                                        import('./timesheet/timesheet.module').then(
                                            m => m.TimesheetModule
                                        )
                                },
                                {
                                    path: 'timesheet-validation',
                                    loadChildren: () =>
                                        import(
                                            './timesheet-validation/timesheet-validation.module'
                                        ).then(m => m.TimesheetValidationModule)
                                },
                                {
                                    path: 'work-orders',
                                    loadChildren: () =>
                                        import('./workorder/workorder.module').then(
                                            m => m.WorkorderModule
                                        )
                                },
                                {
                                    path: 'work-order-invoice',
                                    loadChildren: () =>
                                        import('./workorder-invoice/workorder-invoice.module').then(
                                            m => m.WorkorderInvoiceModule
                                        )
                                },
                                {
                                    path: 'forms',
                                    loadChildren: () =>
                                        import('./forms/forms.module').then(m => m.FormsModule)
                                },
                            ]
                        },
                        {
                            path: 'geospatial',
                            loadChildren: () =>
                                import('./geospatial/geospatial.module').then(m => m.GeospatialModule)
                        },
                        {
                            path: 'manage',
                            loadChildren: () =>
                                import('./pages/manage/manage.module').then(hm => hm.ManageModule)
                        },
                        {
                            path: 'support',
                            loadChildren: () =>
                                import('./pages/support/support.module').then(
                                    hm => hm.SupportModule
                                )
                        },
                        {
                            path: 'profile',
                            children: [
                                {
                                    path: '',
                                    loadChildren: (): any =>
                                        import('./profile/profile.module').then(
                                            pm => pm.ProfileModule
                                        )
                                }
                            ]
                        },
                        {
                            path: 'administration',
                            loadChildren: () =>
                                import('./pages/administration/administration.module').then(
                                    hm => hm.AdministrationModule
                                )
                        },
                    ]
                },
                {
                    path: 'apps',
                    loadChildren: () =>
                        import('./pages/mobile-apps/mobile-apps.module').then(
                            m => m.MobileAppsModule
                        )
                },
                {
                    path: '**',
                    redirectTo: '/error/404'
                }
            ],
            // We expect users to be on desktop or wifi connections, so we use preload-all for
            // better perceived performance
            // We expect users to be on desktop or wifi connections, so we use preload-all for
            // better perceived performance
            {
                initialNavigation: 'disabled',
                preloadingStrategy: PreloadAllModules,
                relativeLinkResolution: 'legacy'
            }
        ),
        CoreModule,
        SharedModule,
        UiCommonModule,
        UiLibraryModule,
        MatSidenavModule,
        MatGridListModule,
        MatExpansionModule,
        MatCardModule,
        MatInputModule,
        MatIconModule,
        MatMomentDateModule,
        AgmCoreModule,
        MccColorPickerModule.forRoot({
            empty_color: 'transparent'
        }),
        FormsModule,
        // OwlDateTimeModule,
        // OwlNativeDateTimeModule
    ],
    providers: [ThemeService, InviteService, CompanyServiceNew, CompanyInviteService],
    bootstrap: [AppComponent]
})
export class AppModule {}
