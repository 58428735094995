import { IIdFilterWithoutIds } from '../../interface';

export class IdFilterWithoutIds implements IIdFilterWithoutIds {
    public value: any;

    constructor(params?: Partial<IdFilterWithoutIds>) {
        if (!!params) {
            this.value = params.value || this.value;
        }
    }
}
