import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocationSimple } from '@gm2/ui-common';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'gm2-applied-contract-add-location-modal',
    templateUrl: './applied-contract-add-location-modal.component.html',
    styleUrls: ['./applied-contract-add-location-modal.component.scss']
})
export class AppliedContractAddLocationModalComponent implements OnInit {
    public form: UntypedFormGroup = this._fb.group({
        location: ['', Validators.required]
    });
    public readonly locations$: BehaviorSubject<LocationSimple[]> = new BehaviorSubject([]);

    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly _dialogRef: MatDialogRef<AppliedContractAddLocationModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        data: {
            locations: LocationSimple[];
        }
    ) {
        console.log('locations in modal: ', data.locations.length);
        this.locations$.next(data.locations);
    }

    ngOnInit(): void {}

    public submit({ valid, value }: { valid: boolean; value: { location: LocationSimple } }): void {
        if (!valid) {
            return;
        }
        this._dialogRef.close(value.location);
    }

    public close(): void {
        this._dialogRef.close();
    }
}
