import { BaseDoc } from '../base/base-doc';
import { ConditionalActionType, ConditionalLogicType } from '../../enums';
import { FormConditionalRule } from './form-conditional-rule';

export class FormFieldConditional {
    public actionType: ConditionalActionType;
    public logicType: ConditionalLogicType;
    public rules: FormConditionalRule[];

    constructor(params?: Partial<FormFieldConditional>) {
        if (!!params) {
            this.actionType = params.actionType || this.actionType;
            this.logicType = params.logicType || this.logicType;
            this.rules = !!params.rules ? params.rules.map(r => new FormConditionalRule(r)) : this.rules;
        }
    }
}
