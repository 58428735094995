import { BaseDoc } from '../base/base-doc';

export class FormFieldChoice {
    public id: any;
    public value: string;
    public label: string;

    constructor(params?: Partial<FormFieldChoice>) {
        if (!!params) {
            this.id = params.id || this.id;
            this.value = params.value || this.value;
            this.label = params.label || this.label;
        }
    }
}
