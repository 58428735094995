<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h2 mat-dialog-title>Decline WO Invoice</h2>
    <mat-dialog-content>
        <mat-form-field>
            <mat-label>Reason</mat-label>
            <mat-select
                formControlName="reason"
                placeholder="Reason"
            >
                <mat-option *ngFor="let m of InvoiceRejectionReason.members" [value]="m">
                    {{ InvoiceRejectionReason.toString(m) }}
                </mat-option>
            </mat-select>
            <mat-error [rfxError]="form.get('reason')"></mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Message</mat-label>
            <textarea
                matInput
                placeholder="Message"
                formControlName="message"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                cdkAutosizeMaxRows="4"
            ></textarea>
            <mat-error [rfxError]="form.get('message')"></mat-error>
        </mat-form-field>
        <div class="phases">
            <div class="header">
                <h3>Phases to Decline</h3>
                <button
                    mat-stroked-button
                    type="button"
                    (click)="selectAll()"
                >
                    Select All
                </button>
            </div>
            <ng-container *ngFor="let p of phases$ | async">
                <mat-checkbox [formControl]="uxForm.get(p.id)">
                    {{ p.workOrderDetails.workOrderNumber }} |
                    {{ p.workOrderDetails.locationIdentity.name }} |
                    {{ WorkOrderWorkCategory.toString(p.workOrderDetails.workCategory) }}
                </mat-checkbox>
            </ng-container>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button
            mat-button
            (click)="cancel()"
            [disabled]="submitting$ | async"
        >
            Cancel
        </button>
        <button
            mat-flat-button
            color="primary"
            class="spinner"
            type="submit"
            [disabled]="submitting$ | async"
        >
            <mat-spinner *ngIf="submitting$ | async"></mat-spinner>
            Decline
        </button>
    </mat-dialog-actions>
</form>
