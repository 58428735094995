import { CompanyType, CompanyWithoutIds } from '@gm2/common';
import { CompanyProfile } from './CompanyProfile';

export class Company extends CompanyWithoutIds {
    public _id: string = '';
    public profile: CompanyProfile = new CompanyProfile();
    public tags: string[] = [];
    public companies: string[] = [];
    public quickbooksId: string;
    public isSubcontractor?: boolean;
    public isMultiTenant?: boolean;

    constructor(params?: Partial<Company>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.profile = !!params.profile ? new CompanyProfile(params.profile) : this.profile;
            this.tags = Array.isArray(params.tags) ? params.tags : this.tags;
            this.companies = !!params.companies ? params.companies : this.companies;
            this.quickbooksId = !!params.quickbooksId ? params.quickbooksId : this.quickbooksId;
            this.isSubcontractor = params.isSubcontractor ?? this.isSubcontractor;
            this.isMultiTenant = params.isMultiTenant ?? this.isMultiTenant;
        }
    }
}
