import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input
} from '@angular/core';
import { GridFilter } from '../GridFilter';
import { GridFilterControlComponent } from '../grid-filter-control/grid-filter-control.component';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

export interface RfxGridFilterSelectOption {
    value: any;
    label: string;
}

@Component({
    selector: 'rfx-grid-filter-select-control',
    templateUrl: './grid-filter-select-control.component.html',
    styleUrls: ['./grid-filter-select-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: GridFilterControlComponent,
            useExisting: GridFilterSelectControlComponent
        }
    ]
})
export class GridFilterSelectControlComponent implements OnInit, GridFilter {
    public isOptional?: boolean = false;
    public allOptions: RfxGridFilterSelectOption[] = [];

    @Input()
    public name: string = '';
    @Input()
    public placeholder: string = '';
    @Input()
    public set optional(value: boolean | '') {
        this.isOptional = value === '' || value;
    }
    @Input()
    public set options(value: RfxGridFilterSelectOption[]) {
        if (this.isOptional) {
            this.allOptions = [{ value: undefined, label: ''}, ...value];
        } else {
            this.allOptions = value;
        }
    }

    public controlGroup: UntypedFormGroup;

    constructor() {}

    ngOnInit(): void {
        this.controlGroup = new UntypedFormGroup({
            value: new UntypedFormControl('')
        });
    }
}
