import { WorkOrderEquipmentUnit } from '../../enums';
import { isNull } from '../../utils';

export class WorkOrderEquipmentWithoutIds {
    public _id: any;
    public description: string = '';
    public units: WorkOrderEquipmentUnit;
    public quantity: number = 0;
    public price: number = 0;
    public markup: number = 0;
    public total: number = 0;

    constructor(params?: Partial<WorkOrderEquipmentWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.units = params.units || this.units;
            this.description = params.description || this.description;
            this.quantity = !isNull(params.quantity) ? params.quantity : this.quantity;
            this.price = !isNull(params.price) ? params.price : this.price;
            this.markup = !isNull(params.markup) ? params.markup : this.markup;
            this.total = !isNull(params.total) ? params.total : this.total;
        }
    }
}
