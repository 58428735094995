<ng-container
    *ngTemplateOutlet="
        addressTemplate;
        context: {
            addressForm: this.addressFormGroup,
            State: this.State,
            value: this.value,
            validate: this.validate
        }
    "
></ng-container>
