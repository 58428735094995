export enum NoteType {
    OneGM = '[NoteType] OneGM',     // originally private
    Client = '[NoteType] Client',   // originally public
    Landscaper = '[NoteType] Landscaper',
    Service_Partner = '[NoteType] Service Partner'
}

export namespace NoteType {
    export function toString(type: NoteType): string {
        switch (type) {
            case NoteType.OneGM:
                return 'OneGM';
            case NoteType.Client:
                return 'Client';
            case NoteType.Service_Partner:
                return 'Service Partner';
            case NoteType.Landscaper:
                return 'Landscaper';
            default:
                return '';
        }
    }

    export const members: NoteType[] = [
        NoteType.OneGM,
        NoteType.Client,
        NoteType.Service_Partner,
        NoteType.Landscaper,
    ];

    export function toEnum(type: string): NoteType {
        switch(type) {
            case '[NoteType] Client':
                return NoteType.Client;
            case '[NoteType] Service Partner':
                return NoteType.Service_Partner;
            case '[NoteType] Landscaper':
                return NoteType.Landscaper;
            default:
                return NoteType.OneGM;
        }
    }
}
