import { Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RfpService, ToastService, ServiceSource } from '@gm2/ui-common';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

interface IDialogData {
    contractId: string;
    source: ServiceSource;
    parentControl?: boolean;
}

@Component({
    selector: 'gm2-upload-pricing-csv-modal',
    templateUrl: './upload-pricing-csv-modal.component.html',
    styleUrls: ['./upload-pricing-csv-modal.component.scss']
})
export class UploadPricingCsvModalComponent implements OnDestroy {
    private _unsub: Subject<void> = new Subject<void>();

    @ViewChild('csvuploadform', { static: true })
    public csvUploadFormElement: HTMLFormElement;

    public contractId: string;
    public source: ServiceSource;
    public parentControl: boolean;

    constructor(
        private readonly _dialogRef: MatDialogRef<UploadPricingCsvModalComponent, boolean>,
        private readonly _rfpService: RfpService,
        private readonly _toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) data: IDialogData
    ) {
        this.contractId = data.contractId;
        this.source = data.source;
        this.parentControl = data.parentControl;
    }

    public onCancel(): void {
        this._dialogRef.close(false);
    }

    public onSubmit(): void {
        const fd = new FormData(this.csvUploadFormElement.nativeElement);
        fd.append('source', this.source);

        this._rfpService.quickpricing(this.contractId, fd)
            .pipe(takeUntil(this._unsub))
            .subscribe(
                (val) => this._toastService.success('Pricing updated successfully'),
                (err) => this._toastService.error(err.error.message),
                () => this._dialogRef.close(true)
            );
    }

    public isClientService(): boolean {
        return this.source.includes(ServiceSource.ClientService);
    }

    ngOnDestroy(): void {
        this._unsub.next();
        this._unsub.complete();
    }
}
