import { isNull } from '../../utils';
import { UserIdentityWithoutIds } from '../user/user-identity';

export class WorkOrderLaborCompletionWithoutIds {
    // Don't know what the purpose of this _id is, but i see attempts to set it in the code, so am adding it to model
    public _id: any;
    public createdDate: Date;
    public isMobile: boolean = false;
    public userIdentity: UserIdentityWithoutIds;
    public timespent: number = 0;

    constructor(params?: Partial<WorkOrderLaborCompletionWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.createdDate = params.createdDate || this.createdDate;
            this.isMobile = !isNull(params.isMobile) ? params.isMobile : this.isMobile;
            this.userIdentity = !!params.userIdentity
                ? new UserIdentityWithoutIds(params.userIdentity)
                : this.userIdentity;

            this.timespent = !isNull(params.timespent) ? params.timespent : this.timespent;
        }
    }
}
