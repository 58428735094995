<h2 mat-dialog-title>{{ data.message }}</h2>
<div mat-dialog-content>
	<form [formGroup]="clientServiceGroup">
        <mat-form-field>
            <input
                matInput
                required
                placeholder="Service Name"
                formControlName="serviceName"
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                placeholder="Description"
                formControlName="serviceDescription"
            />
            <!-- <mat-error [rfxError]="pricingGroup.get('description')"></mat-error> -->
        </mat-form-field>
        <mat-form-field>
            <mat-select
                required
                placeholder="Select Service Type"
                formControlName="serviceTypeId"
            >
                <mat-option *ngFor="let s of (serviceTypes$ | async)" [value]="s._id">
                    {{ s.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select
                required
                placeholder="Service Classification"
                formControlName="serviceClassification"
            >
                <mat-option *ngFor="let s of ServiceClassification.members" [value]="s">
                    {{ ServiceClassification.toString(s) }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select
                required
                placeholder="Has Material"
                formControlName="hasMaterial"
            >
                <mat-option *ngFor="let s of MaterialOptions.members" [value]="s">
                    {{ MaterialOptions.toString(s) }}
                </mat-option>
            </mat-select>
        </mat-form-field>
		<div class="buttons">
	    <button 
	    	mat-flat-button 
	    	(click)="close()"
    	>
    		Cancel
    	</button>
	    <button 
	    	mat-flat-button 
	    	color="primary" 
	    	(click)="select()"
	    	[disabled]="!clientServiceGroup.valid"
    	>
    		Save
    	</button>
		</div>
	</form>
</div>

