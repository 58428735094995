<h2 mat-dialog-title>Duplicate Location</h2>
<div mat-dialog-content>
    <strong>Conflicting location:</strong> {{ locationName }}<br />
    <strong>Conflicting address:</strong> {{ address }}<br /><br />
    The address of the current location is similar to an already existing location.<br />
    Are you sure you want to submit this location?
</div>
<div class="buttons" mat-dialog-actions align="end">
    <button type="button" mat-raised-button (click)="close(false)">Cancel</button>
    <button type="button" mat-raised-button color="warn" (click)="close(true)">Submit</button>
</div>
