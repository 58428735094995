import { UserProfile } from './UserProfile';
import { UserSimpleWithoutIds } from '@gm2/common';

export class UserSimple extends UserSimpleWithoutIds {
    public _id: any;
    public profile: UserProfile = null;

    constructor(params?: Partial<UserSimple>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.profile = !!params.profile ? new UserProfile(params.profile) : this.profile;
        }
    }
}
