import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GridFilterControlComponent } from '../grid-filter-control/grid-filter-control.component';
import { GridFilter } from '../GridFilter';

@Component({
    selector: 'rfx-grid-filter-search-control',
    templateUrl: './grid-filter-search-control.component.html',
    styleUrls: ['./grid-filter-search-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: GridFilterControlComponent,
            useExisting: GridFilterSearchControlComponent
        }
    ]
})
export class GridFilterSearchControlComponent implements OnInit, GridFilter {
    @Input()
    public name: string = 'searchTerm';
    @Input()
    public placeholder: string = '';

    public controlGroup: UntypedFormGroup;

    constructor() {}

    ngOnInit(): void {
        this.controlGroup = new UntypedFormGroup({
            value: new UntypedFormControl('')
        });
    }
}
