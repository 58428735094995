<rfx-grid #rfxGrid [dataSource]="gridSource">
    <rfx-grid-header>
        <div class="help-header">
            <h1>Help</h1>
            <a
                mat-raised-button
                [href]="(createUrl$ | async)"
            >
                Create Help
            </a>
        </div>
    </rfx-grid-header>
    <rfx-grid-action-bar>
        <rfx-grid-action-bar-delete-control
            (delete)="deleteItems($event)"
        ></rfx-grid-action-bar-delete-control>
    </rfx-grid-action-bar>
    <rfx-grid-filter>
        <rfx-grid-filter-search-control
            name="searchTerm"
            placeholder="Help Name"
        ></rfx-grid-filter-search-control>
    </rfx-grid-filter>
    <rfx-grid-list>
        <rfx-grid-list-item
            *rfxGridListItemDef="let item"
            (click)="gridSource.openDetailView(item)"
            (dblclick)="openItem(item)"
        >
            {{ item.name }}
        </rfx-grid-list-item>
    </rfx-grid-list>
    <rfx-grid-paginator></rfx-grid-paginator>
    <rfx-grid-detail-view *rfxGridDetailViewDef="let view">
        <div rfxGridDetailViewHeader>
            <button
                class="detail-view__button"
                mat-raised-button
                color="primary"
                [disabled]="!view.hasPrevious()"
                (click)="view.previous()"
            >
                Previous
            </button>
            <button
                class="detail-view__button"
                mat-raised-button
                color="primary"
                [disabled]="!view.hasNext()"
                (click)="view.next()"
            >
                Next
            </button>
        </div>
        <div rfxGridDetailViewContent>
            <h2 class="detail-view__title">Help Details</h2>
            <div class="detail-view__group">
                <label>Name:</label>
                <span>{{ view.item.name }}</span>
            </div>
        </div>
        <div rfxGridDetailViewFooter>
            <button
                mat-raised-button
                type="button"
                (click)="openItem(view.item)"
            >
                Edit Help
            </button>
        </div>
    </rfx-grid-detail-view>
</rfx-grid>
