export enum TagColor {
    Green = '[TagColor] green',
    Red = '[TagColor] red',
    Black = '[TagColor] black',
    Orange = '[TagColor] orange',
    Blue = '[TagColor] blue'
}

export namespace TagColor {
    export function toString(type: TagColor): string {
        switch (type) {
            case TagColor.Green:
                return 'Green';
            case TagColor.Red:
                return 'Red';
            case TagColor.Black:
                return 'Black';
            case TagColor.Orange:
                return 'Orange';
            case TagColor.Blue:
                return 'Blue';
            default:
                return '';
        }
    }

    export const members: TagColor[] = [
        TagColor.Green,
        TagColor.Red,
        TagColor.Black,
        TagColor.Orange,
        TagColor.Blue
    ];

    export function toClass(type: TagColor): string {
        switch (type) {
            case TagColor.Green:
                return 'tag-green';
            case TagColor.Red:
                return 'tag-red';
            case TagColor.Black:
                return 'tag-black';
            case TagColor.Orange:
                return 'tag-orange';
            case TagColor.Blue:
                return 'tag-blue';
            default:
                return '';
        }
    }
}
