import { MappingClassification } from '../../enums';

export class MappingClassificationArea {
    public mappingClassification: MappingClassification;
    public totalArea: number = 0;

    constructor(params?: Partial<MappingClassificationArea>) {
        if (!!params) {
            this.mappingClassification = params.mappingClassification || this.mappingClassification;
            this.totalArea = params.totalArea || this.totalArea;
        }
    }
}
