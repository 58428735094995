import { ContractSimpleWithoutIds, ContractStatus, ContractType } from '@gm2/common';

export class ContractSimple extends ContractSimpleWithoutIds {
    public _id: string = '';
    public name: string = '';
    public serviceTypeId: string = '';
    public contractStatus: ContractStatus = null;
    public contractType: ContractType = null;

    constructor(params?: Partial<ContractSimple>) {
        super(params);

        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId;
            this.contractStatus = params.contractStatus || this.contractStatus;
            this.contractType = params.contractType || this.contractType;
        }
    }
};
