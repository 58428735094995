export enum InputType {
    // This enum is used for fields.inputType as well as fileds.conditional.rules.fieldType
    // fields.inputType only had checkbox and radio, drowpdown comes from fieldType
    // assuming it's ok to have them all here/be the same enum?
    Checkbox = 'checkbox',
    Radio = 'radio',
    DropDown = 'dropdown'
}

export namespace InputType {
    export function toString(type: InputType): string {
        switch (type) {
            case InputType.Checkbox:
                return 'Checkbox';
            case InputType.Radio:
                return 'Radio';
            case InputType.DropDown:
                return 'Dropdown';
            default:
                return '';
        }
    }

    export const members: InputType[] = [
        InputType.Checkbox,
        InputType.Radio,
        InputType.DropDown
    ];
}
