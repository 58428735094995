import { SortModel } from '../../interface';
import { SortDirection } from '../../enums';

export class Sort implements SortModel {
    public field: string = '_id';
    public sortOrder: SortDirection = SortDirection.Ascending;

    constructor(params?: Partial<SortModel>) {
        if (!!params) {
            this.field = params.field || this.field;
            this.sortOrder = params.sortOrder || this.sortOrder;
        }
    }
}
