import { parseISODate } from '../../date';
import { DateFilter } from './date-filter';
import { IDateRangeFilter } from '../../interface';

export class DateRangeFilter extends DateFilter implements IDateRangeFilter {
    public endDate: Date = null;

    constructor(params?: Partial<DateRangeFilter>) {
        super(params);
        if (!!params) {
            this.endDate = !!params.endDate ? parseISODate(params.endDate) : this.endDate;
        }
    }
}
