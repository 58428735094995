import { MaterialWithoutIds } from '../company/material';

export class TimesheetMaterialSnapShotWithoutIds {
    public material: MaterialWithoutIds = new MaterialWithoutIds();

    constructor(params?: Partial<TimesheetMaterialSnapShotWithoutIds>) {
        if (!!params) {
            this.material = !!params.material
                ? new MaterialWithoutIds(params.material)
                : this.material;
        }
    }
}
