import { UserIdentityWithoutIds } from '../user/user-identity';
import { WorkOrderStatus, WorkOrderActiveStatus, WorkOrderHistoryType } from '../../enums';
import { BaseDoc } from '../base/base-doc';

export class WorkOrderHistoryWithoutIds extends BaseDoc {
    public _id: any;
    public workOrderId: any;
    public workOrderHistoryType: WorkOrderHistoryType;
    public userIdentity: UserIdentityWithoutIds = new UserIdentityWithoutIds();
    public workOrderStatus: WorkOrderStatus;
    public activeStatus: WorkOrderActiveStatus;
    public message: string;
    public date: Date;

    constructor(params?: Partial<WorkOrderHistoryWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.workOrderId = params.workOrderId || this.workOrderId;
            this.workOrderHistoryType = params.workOrderHistoryType || this.workOrderHistoryType;
            this.userIdentity = !!params.userIdentity
                ? new UserIdentityWithoutIds(params.userIdentity)
                : this.userIdentity;
            this.workOrderStatus = params.workOrderStatus || this.workOrderStatus;
            this.activeStatus = params.activeStatus || this.activeStatus;
            this.message = params.message || this.message;
            this.date = params.date || this.date;
        }
    }
}
