<h2 mat-dialog-title>Decline Invoice</h2>
<div mat-dialog-content>
    <form [formGroup]="form">
        <span class="field-header">Why is the invoice being declined?</span>
        <mat-form-field>
            <!-- <mat-label>Why is the invoice being declined?</mat-label> -->
            <mat-select placeholder="Select a reason" formControlName="reason">
                <mat-option *ngFor="let reason of InvoiceRejectionReason.members" [value]="reason">
                    {{ InvoiceRejectionReason.toString(reason) }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <span class="field-header">
            Please type a message for the Service Partner regarding why the invoice is being
            declined and any corrections that need to be made.
        </span>
        <mat-form-field>
            <textarea matInput formControlName="message"></textarea>
        </mat-form-field>
        <span class="field-header">
            <p>Please select the timesheets that need correction</p>
            <mat-checkbox class="selectall" (click)="selectAll()"></mat-checkbox
            ><span class="subtext">Select / Deselect All</span>
        </span>
        <mat-list formArrayName="timesheets" dense>
            <mat-list-item *ngFor="let timesheet of timesheets; index as i">
                <mat-checkbox [formControlName]="i">
                    {{ getTimesheetTitle(timesheet) }}
                </mat-checkbox>
            </mat-list-item>
        </mat-list>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onSubmit()">
        Confirm
    </button>
</div>
