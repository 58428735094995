import { WorkOrderLaborWithoutIds } from './work-order-labor';
import { WorkOrderPhaseType, WorkOrderPhaseStatus } from '../../enums';
import { WorkOrderMaterialWithoutIds } from './work-order-material';
import { WorkOrderEquipmentWithoutIds } from './work-order-equipment';
import { isNull } from '../../utils';
import { CompanyIdentityWithoutIds } from '../company/company-identity';
import { WorkOrderInvoiceIdentityWithoutIds } from '../work-order-invoice/work-order-invoice-identity';

export class WorkOrderPhaseWithoutIds {
    // Note: 'id' instead of '_id' is used because mongo will not allow arrayFilters query on fields with underscores
    public id: any;
    public material: WorkOrderMaterialWithoutIds[];
    public equipment: WorkOrderEquipmentWithoutIds[];
    public labor: WorkOrderLaborWithoutIds[];
    public description: string = '';
    public dimensions: string = '';
    public comments: string = '';
    public customClientPrice: number;
    public equipmentTotal: number = 0;
    public laborEstimate: number = 0;
    public laborTotal: number = 0;
    public materialTotal: number = 0;
    public phaseEstimate: number = 0;
    public phaseSalesTax: number = 0;
    public phaseTotal: number = 0;
    public phaseType: WorkOrderPhaseType;
    public servicePartnerIdentity: CompanyIdentityWithoutIds;
    public workOrderInvoiceIdentity: WorkOrderInvoiceIdentityWithoutIds;
    public phaseStatus: WorkOrderPhaseStatus;
    public completionDate: Date;

    constructor(params?: Partial<WorkOrderPhaseWithoutIds>) {
        if (!!params) {
            this.id = params.id || this.id;
            this.material = !!params.material
                ? params.material.map(m => new WorkOrderMaterialWithoutIds(m))
                : this.material;
            this.equipment = !!params.equipment
                ? params.equipment.map(e => new WorkOrderEquipmentWithoutIds(e))
                : this.equipment;
            this.labor = !!params.labor
                ? params.labor.map(l => new WorkOrderLaborWithoutIds(l))
                : this.labor;
            this.description = params.description || this.description;
            this.dimensions = params.dimensions || this.dimensions;
            this.comments = params.comments || this.comments;
            this.equipmentTotal = !isNull(params.equipmentTotal)
                ? params.equipmentTotal
                : this.equipmentTotal;
            this.laborEstimate = !isNull(params.laborEstimate)
                ? params.laborEstimate : this.laborEstimate;
            this.laborTotal = !isNull(params.laborTotal) ? params.laborTotal : this.laborTotal;
            this.phaseEstimate = !isNull(params.phaseEstimate)
                ? params.phaseEstimate : this.phaseEstimate;
            this.phaseSalesTax = !isNull(params.phaseSalesTax)
                ? params.phaseSalesTax : this.phaseSalesTax;
            this.phaseTotal = !isNull(params.phaseTotal) ? params.phaseTotal : this.phaseTotal;
            this.materialTotal = !isNull(params.materialTotal)
                ? params.materialTotal
                : this.materialTotal;
            this.customClientPrice = !isNull(params.customClientPrice)
                ? params.customClientPrice
                : this.customClientPrice;
            this.phaseType = params.phaseType || this.phaseType;
            this.servicePartnerIdentity = !!params.servicePartnerIdentity
                ? new CompanyIdentityWithoutIds(params.servicePartnerIdentity)
                : this.servicePartnerIdentity;
            this.workOrderInvoiceIdentity = !!params.workOrderInvoiceIdentity
                ? new WorkOrderInvoiceIdentityWithoutIds(params.workOrderInvoiceIdentity)
                : this.workOrderInvoiceIdentity;
            this.phaseStatus = params.phaseStatus || this.phaseStatus;
            this.completionDate = !!params.completionDate
                ? new Date(params.completionDate)
                : this.completionDate;
        }
    }
}
