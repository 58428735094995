import { CompanyType } from '../../enums';

export class RoleSettings {
    public canEdit: boolean = false;
    public canDelete: boolean = false;
    public isAdminDefault: boolean = false;
    public isSPDefault: boolean = false;
    public isCrewDefault: boolean = false;
    public isClientDefault: boolean = false;
    public isSPRole: boolean = false;
    public isClientRole: boolean = false;
    public isSystem: boolean = false;
    public companyType: CompanyType = CompanyType.Admin;

    constructor(params?: Partial<RoleSettings>) {
        if (!!params) {
            this.canEdit = typeof params.canEdit === 'boolean' ? params.canEdit : this.canEdit;
            this.isSystem = typeof params.isSystem === 'boolean' ? params.isSystem : this.isSystem;
            this.canDelete =
                typeof params.canDelete === 'boolean' ? params.canDelete : this.canDelete;
            this.isAdminDefault =
                typeof params.isAdminDefault === 'boolean'
                    ? params.isAdminDefault
                    : this.isAdminDefault;
            this.isSPDefault =
                typeof params.isSPDefault === 'boolean' ? params.isSPDefault : this.isSPDefault;
            this.isCrewDefault =
                typeof params.isCrewDefault === 'boolean'
                    ? params.isCrewDefault
                    : this.isCrewDefault;
            this.isClientDefault =
                typeof params.isClientDefault === 'boolean'
                    ? params.isClientDefault
                    : this.isClientDefault;
            this.isSPRole = typeof params.isSPRole === 'boolean' ? params.isSPRole : this.isSPRole;
            this.isClientRole =
                typeof params.isClientRole === 'boolean' ? params.isClientRole : this.isClientRole;
            this.companyType = CompanyType.members.includes(params.companyType)
                ? params.companyType
                : CompanyType.Admin;
        }
    }
}
