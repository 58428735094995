import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractAppliedService, BaseMediaDocumentWithUrl } from '@gm2/ui-common';

interface IDialogData {
    contractAppliedId: string;
}

@Component({
    selector: 'gm2-specification-documents-modal',
    templateUrl: './specification-documents-modal.component.html',
    styleUrls: ['./specification-documents-modal.component.scss']
})
export class SpecificationDocumentsModalComponent implements OnInit {
    public documents$: Subject<BaseMediaDocumentWithUrl[]> =
        new Subject<BaseMediaDocumentWithUrl[]>();

    constructor(
        private readonly _dialogRef: MatDialogRef<SpecificationDocumentsModalComponent>,
        private readonly _contractAppliedService: ContractAppliedService,
        @Inject(MAT_DIALOG_DATA) data: IDialogData
    ) {
        this._contractAppliedService
            .getAppliedContractDocuments(data.contractAppliedId)
            .subscribe(docs => {
                this.documents$.next(docs);
            });
    }

    ngOnInit(): void {}

    public close(): void {
        this._dialogRef.close(null);
    }
}
