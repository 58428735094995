import { BaseMediaDocumentWithoutIds } from '@gm2/common';
import { UserIdentity } from '../user/UserIdentity';

export class BaseMediaDocument extends BaseMediaDocumentWithoutIds {
    public _id?: string;
    public userIdentity: UserIdentity = new UserIdentity();

    constructor(params?: Partial<BaseMediaDocument>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.userIdentity = !!params.userIdentity
                ? new UserIdentity(params.userIdentity)
                : this.userIdentity;
        }
    }
}
