export enum WorkOrderActiveStatus {
    Active = '[WorkOrderActiveStatus] Active',
    Hold = '[WorkOrderActiveStatus] Hold',
    Inactive = '[WorkOrderActiveStatus] Inactive'
}

export namespace WorkOrderActiveStatus {
    export function toString(type: WorkOrderActiveStatus): string {
        switch (type) {
            case WorkOrderActiveStatus.Active:
                return 'Active';
            case WorkOrderActiveStatus.Hold:
                return 'Hold';
            case WorkOrderActiveStatus.Inactive:
                return 'Inactive';
            default:
                return '';
        }
    }

    export const members: WorkOrderActiveStatus[] = [
        WorkOrderActiveStatus.Active,
        WorkOrderActiveStatus.Hold,
        WorkOrderActiveStatus.Inactive
    ];
}
