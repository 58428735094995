<h2 mat-dialog-title>Confirm {{ text }}</h2>
<div mat-dialog-content>
    Are you sure you want to {{ text.toLowerCase() }} this item?
</div>
<div class="buttons" mat-dialog-actions align="end">
    <button type="button" mat-raised-button (click)="close(false)">
        Cancel
    </button>
    <button type="button" mat-raised-button color="warn" (click)="close(true)">
        {{ confirmation }}
    </button>
</div>
