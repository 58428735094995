export enum ConditionalRuleOperator {
    In = 'in',
    Is = 'is'
}

export namespace ConditionalRuleOperator {
    export function toString(type: ConditionalRuleOperator): string {
        switch (type) {
            case ConditionalRuleOperator.In:
                return 'In';
            case ConditionalRuleOperator.Is:
                return 'Is';
            default:
                return '';
        }
    }

    export const members: ConditionalRuleOperator[] = [
        ConditionalRuleOperator.In,
        ConditionalRuleOperator.Is
    ];
}
