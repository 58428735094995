import { BaseDoc } from '../base/base-doc';
import { UserIdentityWithoutIds } from '../user/user-identity';
import { NoteCollectionName, NoteType } from '../../enums';

export class NoteWithoutIds extends BaseDoc {
    public _id: any;
    public collection: NoteCollectionName;
    public collectionId: any;
    public userIdentity: UserIdentityWithoutIds = new UserIdentityWithoutIds();
    public note: string;
    public noteType: NoteType = NoteType.OneGM;

    constructor(params?: Partial<NoteWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.collection = params.collection || this.collection;
            this.collectionId = params.collectionId || this.collectionId;
            this.userIdentity = !!params.userIdentity
                ? new UserIdentityWithoutIds(params.userIdentity)
                : this.userIdentity;
            this.note = params.note || this.note;
            this.noteType = params.noteType || this.noteType;
        }
    }
}
