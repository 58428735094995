import { Address } from '../base/address';

export class LocationContact {
    public phone: string = '';
    public email: string = '';
    public address: Address = new Address();

    constructor(params?: Partial<LocationContact>) {
        if (!!params) {
            this.phone = params.phone || this.phone;
            this.email = params.email || this.email;
            this.address = !!params.address ? new Address(params.address) : this.address;
        }
    }
}
