import { NoteWithoutIds } from '../note/note';
import { BaseMediaDocumentWithoutIds } from '../media/base-media-document';

export class LocationRecentWithoutIds {
    public notes: NoteWithoutIds[] = [];
    public images: BaseMediaDocumentWithoutIds[] = [];

    constructor(params?: Partial<LocationRecentWithoutIds>) {
        if (!!params) {
            this.notes = Array.isArray(params.notes)
                ? params.notes.map(note => new NoteWithoutIds(note))
                : this.notes;

            this.images = Array.isArray(params.images)
                ? params.images.map(image => new BaseMediaDocumentWithoutIds(image))
                : this.images;
        }
    }
}
