import { WorkOrderGMR, WorkOrderWorkCategory, WorkOrderPriority, WorkOrderBillingType } from '@gm2/common';

export class WorkOrderClientDto {
    public locationId: string;
    public gmr: WorkOrderGMR;
    public safety: boolean;
    public completeByDate?: Date;
    public workCategory: WorkOrderWorkCategory;
    public priority: WorkOrderPriority;
    public billingType: WorkOrderBillingType;
    public notToExceed?: number;
    public projectDescription: string;

    constructor(params: WorkOrderClientDto) {
        this.locationId = params.locationId;
        this.gmr = params.gmr;
        this.safety = params.safety;
        this.completeByDate = !!params.completeByDate
            ? new Date(params.completeByDate)
            : this.completeByDate;
        this.workCategory = params.workCategory;
        this.priority = params.priority;
        this.billingType = params.billingType;
        this.notToExceed = typeof params.notToExceed === 'number'
            ? params.notToExceed
            : this.notToExceed;
        this.projectDescription = params.projectDescription;
    }
}
