import { PaginatedListModel } from '../../interface';
import { Newable } from '../../utils';
import { isArray } from 'lodash';

export class PaginatedList<T> implements PaginatedListModel<T> {
    public docs: Array<T> = [];
    public count: number = 0;

    constructor();
    constructor(tuple?: [T[], number], type?: Newable<T>);
    constructor(list?: Partial<PaginatedListModel<T>>, type?: Newable<T>);
    constructor(tupleOrList?: [T[], number] | Partial<PaginatedListModel<T>>, type?: Newable<T>) {
        let listModel: PaginatedListModel<T> = {
            docs: this.docs,
            count: this.count
        };
        const dbResultArrayCount = 2;

        if (!!tupleOrList) {
            if ('docs' in tupleOrList) {
                // this is a PaginatedListModel
                listModel = tupleOrList as PaginatedListModel<T>;
            } else if (isArray(tupleOrList)) {
                // this is a [T[], number]
                listModel.docs = tupleOrList[0];
                listModel.count = tupleOrList[1];
            }
            // } else if (isArray(tupleOrList) && tupleOrList.length === dbResultArrayCount) {
            //     // this is a [T[], number]
            //     [listModel.docs, listModel.count] = tupleOrList;
            // }

            if (Array.isArray(listModel.docs)) {
                if (typeof type !== 'undefined') {
                    this.docs = listModel.docs.map(doc => new type(doc));
                } else {
                    this.docs = listModel.docs;
                }
            }
            if (typeof listModel.count === 'number' && listModel.count >= 0) {
                this.count = listModel.count;
            }
        }
    }
}
