<h2 mat-dialog-title>Add Mapping Category</h2>
<div mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <input matInput required placeholder="Name" formControlName="name" />
            <mat-error [rfxError]="form.get('name')"></mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-select
                required
                placeholder="Mapping Classification"
                formControlName="classification"
            >
                <mat-option
                    *ngFor="let classification of MappingClassification.members"
                    [value]="classification"
                >
                    {{ MappingClassification.toString(classification) }}
                </mat-option>
            </mat-select>
            <mat-error [rfxError]="form.get('classification')"></mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-select required placeholder="Mapping Type" formControlName="type">
                <mat-option *ngFor="let type of MappingType.members" [value]="type">
                    {{ MappingType.toString(type) }}
                </mat-option>
            </mat-select>
            <mat-error [rfxError]="form.get('type')"></mat-error>
        </mat-form-field>
        <div class="field-with-action" (click)="disableColorInput()">
            <mat-form-field>
                <input matInput required placeholder="Pick a Color *" formControlName="color" />
                <mat-error [rfxError]="form.get('color')"></mat-error>
            </mat-form-field>
            <mcc-color-picker [isOpen]="false" (selected)="selectColor($event)"> </mcc-color-picker>
        </div>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onAdd()">Add</button>
</div>
