import { TimesheetSnapShotWithoutIds } from './timesheet-snap-shot';
import { TimesheetServicesWithoutIds } from './timesheet-services';
import { TimesheetStatus, ServiceTimesheetField } from '../../enums';
import { RfpIdentityWithoutIds } from '../rfp/rfp-identity';
import { TimesheetDates } from './timesheet-dates';
import { TimesheetValidationWithoutIds } from './timesheet-validation';

export class TimesheetSimpleDetailedWithoutIds {
    public _id: any;
    public snapShot: TimesheetSnapShotWithoutIds = new TimesheetSnapShotWithoutIds();
    public services: TimesheetServicesWithoutIds[] = [];
    public crewSize: number;
    public rfpIdentity: RfpIdentityWithoutIds = new RfpIdentityWithoutIds();
    public dates: TimesheetDates = new TimesheetDates();
    public timesheetNumber: string;
    public timesheetStatus: TimesheetStatus;
    public serviceTypeFields: { [key in ServiceTimesheetField]?: number | string } = {};
    public validation: TimesheetValidationWithoutIds = new TimesheetValidationWithoutIds();
    // for status tracking of images upload asynchronously from the mobile app
    public asyncImageCount: number;
    public syncedImageCount: number;

    constructor(params?: Partial<TimesheetSimpleDetailedWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.rfpIdentity = !!params.rfpIdentity
                ? new RfpIdentityWithoutIds(params.rfpIdentity)
                : this.rfpIdentity;
            this.timesheetNumber = params.timesheetNumber || this.timesheetNumber;
            this.dates = !!params.dates ? new TimesheetDates(params.dates) : this.dates;
            this.crewSize = params.crewSize || this.crewSize;
            this.snapShot = !!params.snapShot
                ? new TimesheetSnapShotWithoutIds(params.snapShot)
                : this.snapShot;
            this.services = !!params.services
                ? params.services.map(s => new TimesheetServicesWithoutIds(s))
                : this.services;
            this.timesheetStatus = params.timesheetStatus || this.timesheetStatus;
            this.serviceTypeFields = !!params.serviceTypeFields
                ? params.serviceTypeFields
                : this.serviceTypeFields;
            this.validation = !!params.validation
                ? new TimesheetValidationWithoutIds(params.validation)
                : this.validation;

            this.asyncImageCount = params.asyncImageCount || this.asyncImageCount;
            this.syncedImageCount = params.syncedImageCount || this.syncedImageCount;
        }
    }
}
