<h2 mat-dialog-title>{{ !!data.title ? data.title : 'Are you sure?' }}</h2>
<div mat-dialog-content>
    <p [innerHTML]="data.message"></p>
</div>
<div mat-dialog-actions align="end">
    <button mat-stroked-button (click)="close()">Cancel</button>
    <button mat-flat-button color="warn" (click)="confirm()">
        {{ !!data.confirmation ? data.confirmation : 'Yes' }}
    </button>
</div>
