import { SpAbstractNonMonthlyCharges } from './sp-abstract-non-monthly-charges';
import { BidServiceWithoutIds, BidWithoutIds, RfpServiceWithoutIds } from '@gm2/common';

export class SpPerEventCharges extends SpAbstractNonMonthlyCharges {
    public calculateServiceDuration(
        duration: number,
        awardedBid: BidWithoutIds,
        awardedBidService: BidServiceWithoutIds
    ): number {
        // it doesnt matter how long it took to perform the service. they are paid by the fact the service was performed
        return 0;
    }

    public calculateServiceClientTotal(
        duration: number,
        clientPricingService: RfpServiceWithoutIds,
        serviceDurationDict: { [serviceId: string]: number }
    ): { total: number; duration: number } {
        return {
            total: (clientPricingService.clientPrice || 0) + (clientPricingService.clientFee || 0),
            duration: duration
        };
    }

    public calculateServiceTotal(
        duration: number,
        awardedBid: BidWithoutIds,
        awardedBidService: BidServiceWithoutIds
    ): number {
        // it doesnt matter how long it took to perform the service. They are paided a flat amount for the service each time its done
        return awardedBidService.amount;
    }

    public adjustClientDurations(
        companyTotalDuration: number,
        serviceDurationDict: {
            [serviceId: string]: number;
        }
    ): { serviceDurationDict: { [serviceId: string]: number }; totalDuration: number } {
        let totalDuration = 0;
        for (const serviceId in serviceDurationDict) {
            totalDuration += serviceDurationDict[serviceId];
        }
        return { serviceDurationDict, totalDuration };
    }
}
