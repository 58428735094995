import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RfxGridFilterControlComponent, RfxGridFilterSelectOption } from '@refactor/ngx/grid';
import { ServiceTypeService } from '@gm2/ui-common';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'gm2-grid-filter-service-type',
	templateUrl: './grid-filter-service-type.component.html',
	styleUrls: ['./grid-filter-service-type.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: RfxGridFilterControlComponent,
			useExisting: GridFilterServiceTypeComponent
		}
	]
})
export class GridFilterServiceTypeComponent implements OnInit {
	@Input()
	public name: string = 'serviceTypeId';
	@Input()
	public placeholder: string = 'Service Type';
	@Input()
	public options: RfxGridFilterSelectOption[];

	public gridServiceTypes$: Observable<RfxGridFilterSelectOption[]> =
    this._serviceTypeService.getServiceTypes().pipe(
        map(servicetypes => servicetypes.map(servicetype => ({
            value: servicetype._id,
            label: servicetype.name
        }))),
        // blank option
        map(options => [
            {value: null, label: ''},
            ...options
        ])
    );

	public controlGroup: UntypedFormGroup;

	private _onDestroy$: Subject<void> = new Subject();

	constructor(
		private _serviceTypeService: ServiceTypeService,
		private _changeDetectorRef: ChangeDetectorRef
	){}

	ngOnInit(): void {
		this.controlGroup = new UntypedFormGroup({
			value: new UntypedFormControl('')
		});
		this.gridServiceTypes$
			.pipe(takeUntil(this._onDestroy$))
			.subscribe(serviceTypes => {
				if (!!serviceTypes) {
					this.options = serviceTypes;
				}
				this._changeDetectorRef.detectChanges();
			});
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}
}
