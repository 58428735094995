import { StringFilter } from './string-filter';
import { IStringTermFilter } from '../../interface/filter/string-term-filter-model';

export class StringTermFilter extends StringFilter implements IStringTermFilter {
    public key: string = '';

    constructor(params?: Partial<StringTermFilter>) {
        super(params);
        if (!!params) {
            this.key = params.key || this.key;
        }
    }
}
