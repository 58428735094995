import { Action, Selector, State, StateContext } from '@ngxs/store';
import { InviteService } from '@ngx-gm2/shared/services/invite.service';
import { Injectable } from '@angular/core';
import { catchError, filter, finalize, tap } from 'rxjs/operators';
import { ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { InviteModel } from '@ngx-gm2/shared/models/invite.model';
import { of } from 'rxjs';
import { ToastService } from '@gm2/ui-common';
import { CompanyInviteService } from '@ngx-gm2/shared/services/company-invite.service';
import { CompanyInvitesModel } from '@ngx-gm2/shared/models/company-invites.model';
import { MatDialogRef } from '@angular/material/dialog';
import { InviteModalComponent } from '../../../../../apps/ngx-gm2/src/app/pages/support/components/invite-modal/invite-modal.component';

export namespace InviteActions {

    export class SetProps {
        static readonly type = '[Invite] Set Particular Props';

        constructor(public propsToRewrite: Partial<InviteStateModel>) {
        }
    }

    export class ResetToInitialState {
        static readonly type = '[Invite] Get Reset To Initial State';
    }

    export class SetPagination {
        static readonly type = '[Invite] Set Pagination';

        constructor(public page: number, public perPage: number) {
        }
    }

    export class GetAllInvites {
        static readonly type = '[Invite] Get All Invites';
    }

    export class SendInvite {
        static readonly type = '[Invite] Send Invite';

        constructor(
            public inviteForm: InviteModel.InviteFormData,
            public dialogRef: MatDialogRef<InviteModalComponent>
        ) {
        }
    }

    export class DeleteInvite {
        static readonly type = '[Invite] Delete Invite';

        constructor(public inviteId: string) {
        }
    }

    export class ResendInvite {
        static readonly type = '[Invite] Resend Invite';

        constructor(public inviteId: string) {
        }
    }

    export class CancelInvite {
        static readonly type = '[Invite] Cancel Invite';

        constructor(public inviteId: string) {
        }
    }

    export class GetCompanyInvites {
        static readonly type = '[Invite] Get All Company Invites';
    }

    export class AcceptCompanyInvite {
        static readonly type = '[Invite] Accept Company Invite';

        constructor(public inviteId: string) {
        }
    }

    export class RejectCompanyInvite {
        static readonly type = '[Invite] Reject Company Invite';

        constructor(public inviteId: string) {
        }
    }

    export class RevokeCompanyInvite {
        static readonly type = '[Invite] Revoke Company Invite';

        constructor(public inviteId: string) {
        }
    }
}


export interface InviteStateModel {
    inviteSending: boolean;
    invites: any [],
    companyInvites: CompanyInvitesModel.CompanyInviteDocument[],
    totalItemsCount: number,
    perPage: number,
    page: number
}

const initialState: InviteStateModel = {
    inviteSending: false,
    invites: null,
    companyInvites: null,
    totalItemsCount: 0,
    perPage: 10,
    page: 0
};

@State<InviteStateModel>({
    name: 'invite',
    defaults: initialState
})

@Injectable()
export class InviteState {
    constructor(
        private inviteService: InviteService,
        private toastService: ToastService,
        private companyInviteService: CompanyInviteService
    ) {
    }

    @Selector()
    @ImmutableSelector()
    public static selectInviteSending(state: InviteStateModel): boolean {
        return state.inviteSending;
    }

    @Selector()
    @ImmutableSelector()
    public static selectInvites(state: InviteStateModel): any[] {
        return state.invites;
    }

    @Selector()
    @ImmutableSelector()
    public static selectCompanyInvites(state: InviteStateModel): CompanyInvitesModel.CompanyInviteDocument[] {
        return state.companyInvites;
    }

    @Selector()
    @ImmutableSelector()
    public static selectTotalItesmCount(state: InviteStateModel): number {
        return state.totalItemsCount;
    }

    @Selector()
    @ImmutableSelector()
    public static selectPage(state: InviteStateModel): number {
        return state.page;
    }

    @Selector()
    @ImmutableSelector()
    public static selectPerPage(state: InviteStateModel): number {
        return state.perPage;
    }

    @Action(InviteActions.SetProps)
    public setProps(ctx: StateContext<InviteStateModel>, { propsToRewrite }: InviteActions.SetProps): void {
        ctx.setState((state) => ({ ...state, ...propsToRewrite }));
    }

    @Action(InviteActions.GetAllInvites)
    public getUserInvites(ctx: StateContext<InviteStateModel>) {
        const state = ctx.getState();
        return this.inviteService.getAll(
            {
                page: state.page,
                perPage: state.perPage
            })
            .pipe(
                tap(res => {
                    const state = ctx.getState();
                    ctx.setState({
                        ...state,
                        invites: res.items,
                        totalItemsCount: res.totalItemsCount
                    });
                }));
    }

    @Action(InviteActions.SetPagination)
    public setPagination(ctx: StateContext<InviteStateModel>, action: InviteActions.SetPagination) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            page: action.page,
            perPage: action.perPage
        });
        ctx.dispatch(new InviteActions.GetAllInvites());

    }

    @Action(InviteActions.ResetToInitialState)
    public resetToInitialState(ctx: StateContext<InviteStateModel>, action: InviteActions.SetPagination) {
        ctx.setState(initialState);
    }

    @Action(InviteActions.SendInvite)
    public sendInvite(ctx: StateContext<InviteStateModel>, action: InviteActions.SendInvite) {
        ctx.setState((state) => ({ ...state, inviteSending: true }));
        return this.inviteService.sendInvite(action.inviteForm)
            .pipe(
                tap((res) => {
                    ctx.dispatch(new InviteActions.GetAllInvites());
                    action.dialogRef.close();
                    this.toastService.success(`The  ${action.inviteForm.companyName} has been successfully invited`);
                }),
                finalize(() => ctx.setState((state) => ({ ...state, inviteSending: false }))),
                catchError((err: any) => {
                    console.log(err);
                    this.toastService.error(err?.error?.message);
                    return of();
                }));
    }

    @Action(InviteActions.DeleteInvite)
    public deleteInvite(ctx: StateContext<InviteStateModel>, action: InviteActions.DeleteInvite) {
        return this.inviteService.delete(
            action.inviteId
        )
            .pipe(
                tap(res => {
                    if (res) {
                        ctx.dispatch(new InviteActions.GetAllInvites());
                    }
                }),
                catchError((err: any) => {
                    console.log(err);
                    this.toastService.error(err?.error?.message);
                    return of();
                }));
    }

    @Action(InviteActions.ResendInvite)
    public resendInvite(ctx: StateContext<InviteStateModel>, action: InviteActions.ResendInvite) {
        return this.inviteService.resend(
            action.inviteId
        )
            .pipe(
                tap(res => {
                    if (res) {
                        this.toastService.success('Invite has been sent');
                    }
                }),
                catchError((err: any) => {
                    console.log(err);
                    this.toastService.error(err?.error?.message);
                    return of();
                }));
    }

    @Action(InviteActions.CancelInvite)
    public cancelInvite(ctx: StateContext<InviteStateModel>, action: InviteActions.CancelInvite) {
        return this.inviteService.cancel(
            action.inviteId
        )
            .pipe(
                tap(res => {
                    if (res) {
                        ctx.dispatch(new InviteActions.GetAllInvites());
                        this.toastService.success('Invite has been canceled');
                    }
                }),
                catchError((err: any) => {
                    console.log(err);
                    this.toastService.error(err?.error?.message);
                    return of();
                }));
    }

    @Action(InviteActions.GetCompanyInvites)
    public getCompanyInvites(ctx: StateContext<InviteStateModel>) {
        const state = ctx.getState();
        return this.companyInviteService.getAll(
            {
                page: state.page,
                perPage: state.perPage
            })
            .pipe(
                tap(res => {
                    const state = ctx.getState();
                    ctx.setState({
                        ...state,
                        companyInvites: res.items,
                        totalItemsCount: res.totalItemsCount
                    });
                }),
                catchError((err: any) => {
                    this.toastService.error(err?.error?.message);
                    return of();
                    // const state = ctx.getState();
                    // ctx.setState({
                    //     ...state,
                    //     companyInvites: [],
                    //     totalItemsCount: 0
                    // });
                }));

    }

    @Action(InviteActions.AcceptCompanyInvite)
    public acceptCompanyInvite(ctx: StateContext<InviteStateModel>, action: InviteActions.AcceptCompanyInvite) {
        return this.companyInviteService.accept(
            action.inviteId
        )
            .pipe(
                tap(res => {
                    if (res) {
                        ctx.dispatch(new InviteActions.GetCompanyInvites());
                        this.toastService.success('Invite has been accepted');
                    }
                }),
                catchError((err: any) => {
                    console.log(err);
                    this.toastService.error(err?.error?.message);
                    return of();
                }));
    }

    @Action(InviteActions.RejectCompanyInvite)
    public rejectCompanyInvite(ctx: StateContext<InviteStateModel>, action: InviteActions.AcceptCompanyInvite) {
        return this.companyInviteService.reject(
            action.inviteId
        )
            .pipe(
                tap(res => {
                    if (res) {
                        ctx.dispatch(new InviteActions.GetCompanyInvites());
                        this.toastService.success('Invite has been rejected');
                    }
                }),
                catchError((err: any) => {
                    console.log(err);
                    this.toastService.error(err?.error?.message);
                    return of();
                }));
    }

    @Action(InviteActions.RevokeCompanyInvite)
    public revokeCompanyInvite(ctx: StateContext<InviteStateModel>, action: InviteActions.AcceptCompanyInvite) {
        return this.companyInviteService.revoke(action.inviteId)
            .pipe(
                filter((res) => Boolean(res)),
                tap(() => this.toastService.success('Invite has been revoked')),
                catchError((err: any) => {
                    console.log(err);
                    this.toastService.error(err?.error?.message);
                    return of();
                }));
    }

}


