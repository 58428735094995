import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Directive,
    TemplateRef,
    ElementRef,
    Renderer2
} from '@angular/core';

@Directive({
    selector: '[rfxGridDetailViewDef]'
})
export class GridDetailViewItemDef<T> {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({
    selector: '[rfxGridDetailViewHeader]'
})
export class GridDetailViewHeader {
    constructor(private _el: ElementRef<HTMLElement>, private _renderer: Renderer2) {}

    ngAfterViewInit(): void {
        this._setStyle(this._el, 'display', 'flex');
        this._setStyle(this._el, 'justify-content', 'space-between');
        this._setStyle(this._el, 'margin', '0 -16px');
        this._setStyle(this._el, 'padding', '0 16px 20px');
        this._setStyle(this._el, 'border-bottom', '1px solid rgba(0, 0, 0, 0.2');
    }

    private _setStyle(el: ElementRef<HTMLElement>, attr: string, value: string): void {
        this._renderer.setStyle(el.nativeElement, attr, value);
    }
}

@Directive({
    selector: '[rfxGridDetailViewContent]'
})
export class GridDetailViewContent {
    constructor(private _el: ElementRef<HTMLElement>, private _renderer: Renderer2) {}

    ngAfterViewInit(): void {
        this._setStyle(this._el, 'padding', '12px 0');
    }

    private _setStyle(el: ElementRef<HTMLElement>, attr: string, value: string): void {
        this._renderer.setStyle(el.nativeElement, attr, value);
    }
}

@Directive({
    selector: '[rfxGridDetailViewFooter]'
})
export class GridDetailViewFooter {
    constructor(private _el: ElementRef<HTMLElement>, private _renderer: Renderer2) {}

    ngAfterViewInit(): void {
        this._setStyle(this._el, 'display', 'flex');
        this._setStyle(this._el, 'justify-content', 'space-between');
        this._setStyle(this._el, 'margin', '0 -16px');
        this._setStyle(this._el, 'padding', '20px 16px 0');
        this._setStyle(this._el, 'border-top', '1px solid rgba(0, 0, 0, 0.2');
    }

    private _setStyle(el: ElementRef<HTMLElement>, attr: string, value: string): void {
        this._renderer.setStyle(el.nativeElement, attr, value);
    }
}

@Component({
    selector: 'rfx-grid-detail-view',
    templateUrl: './grid-detail-view.component.html',
    styleUrls: ['./grid-detail-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridDetailViewComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
