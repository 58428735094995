import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    ChangeDetectorRef
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RfxGridFilterControlComponent, RfxGridFilterSelectOption } from '@refactor/ngx/grid';
import { LocationService } from '@gm2/ui-common';
import { Observable, Subject, ReplaySubject, BehaviorSubject, combineLatest } from 'rxjs';
import { map, takeUntil, withLatestFrom, startWith, tap, shareReplay } from 'rxjs/operators';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
    selector: 'gm2-grid-filter-location-multiple',
    templateUrl: './grid-filter-location-multiple.component.html',
    styleUrls: ['./grid-filter-location-multiple.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: RfxGridFilterControlComponent,
            useExisting: GridFilterLocationMultipleComponent
        }
    ]
})
export class GridFilterLocationMultipleComponent implements OnInit {
    @Input()
    public name: string = 'locationIds';
    @Input()
    public placeholder: string = 'Locations';
    @Input()
    public options: RfxGridFilterSelectOption[];
    @Input()
    public showAllForAdmins: boolean = false;

    private _clientIds$: BehaviorSubject<string[]> = new BehaviorSubject([]);
    @Input()
    public set clientIds(value: string[]) {
        this._clientIds$.next(value);
    }
    public initialOptions: RfxGridFilterSelectOption[] = null;

    public gridLocations$: Observable<RfxGridFilterSelectOption[]> = null;

    public controlGroup: UntypedFormGroup;

    public searchControl: UntypedFormControl = new UntypedFormControl();

    private _savedLocations: string[] = [];
    private _onDestroy: Subject<void> = new Subject<void>();

    private filterOnSearch(): void {
        if (!this.options || !this.initialOptions) {
            return;
        }
        let search = this.searchControl.value;
        if (!search) {
            this.options = this.initialOptions.slice();
            return;
        } else {
            search = search.toLowerCase();
        }
        this.options = this.initialOptions.filter(
            location => location.label.toLowerCase().indexOf(search) > -1
        );
    }

    constructor(
        private _locationService: LocationService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.gridLocations$ = combineLatest(
            this._clientIds$,
            this._locationService.getAwardedSimpleList(this.showAllForAdmins)
        ).pipe(
            map(([clientIds, locations]) => {
                let newLocs;
                if (!!clientIds && clientIds.length > 0) {
                    newLocs = locations.filter(l => clientIds.includes(l.companyIdentity._id));
                } else {
                    newLocs = locations;
                }
                const mapped = newLocs.map(location => ({
                    value: location._id,
                    label: location.identity.name
                }));
                return mapped.sort((a, b) => (a.label > b.label ? 1 : -1));
            }),
            shareReplay(1)
        );

        this.controlGroup = new UntypedFormGroup({
            values: new UntypedFormControl('')
        });

        this.gridLocations$.pipe(takeUntil(this._onDestroy)).subscribe(locations => {
            if (!!locations) {
                this.options = locations;
                if (
                    this.initialOptions === null ||
                    this.options.length > this.initialOptions.length
                ) {
                    this.initialOptions = locations;
                }
            }
            this._changeDetectorRef.detectChanges();
        });

        this.searchControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterOnSearch();
        });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}
