import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faBuilding,
    faUserCircle,
    faArrowRight,
    faReply,
    faMinusCircle,
    faPlusCircle,
    faTrashAlt,
    faFileAlt,
    faUserFriends,
    faStar,
    faInfoCircle,
    faFilter,
} from '@fortawesome/pro-solid-svg-icons';
import {
    faPaperPlane,
    faBell,
    faSignOut,
    faArrowAltFromLeft,
    faArrowAltToLeft,
    faChartLine,
    faCogs,
    faKey,
    faCog,
    faHandsHelping,
    faChevronRight as faCRReg,
    faChevronLeft,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faThumbtack,
    faPlus,
    faTimes,
    faTrash,
    faTag,
    faMapMarker,
    faEdit,
    faDraftingCompass,
    faCheck,
    faCheckCircle,
    faCircle,
    faStickyNote,
    faImage,
    faImages,
    faHandshake,
    faHistory,
    faTasks,
    faSnowflakes,
    faLeaf,
    faCamera,
    faCloudUpload,
    faMap,
    faShovel,
    faStopwatch,
    faUser,
    faUsers,
    faUserPlus,
    faPencil,
    faMapMarkerPlus,
    faMapMarkerTimes,
    faClipboardList,
    faChartBar,
    faSave,
    faMobile,
    faPollH,
    faSync,
    faUserClock,
    faArchive,
    faQuestionCircle,
    faMapMarkerAlt
} from '@fortawesome/pro-regular-svg-icons';

library.add(
    faPaperPlane,
    faCRReg,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faBuilding,
    faChartLine,
    faKey,
    faHandsHelping,
    faBell,
    faUserCircle,
    faUserPlus,
    faSignOut,
    faArrowAltFromLeft,
    faArrowAltToLeft,
    faCogs,
    faThumbtack,
    faPlus,
    faPlusCircle,
    faMinusCircle,
    faTimes,
    faTrash,
    faTag,
    faArrowRight,
    faReply,
    faMapMarker,
    faEdit,
    faDraftingCompass,
    faCheck,
    faCheckCircle,
    faCircle,
    faStickyNote,
    faImage,
    faImages,
    faHandshake,
    faHistory,
    faTasks,
    faSnowflakes,
    faLeaf,
    faCamera,
    faCloudUpload,
    faMap,
    faShovel,
    faStopwatch,
    faUsers,
    faUser,
    faTrashAlt,
    faPencil,
    faMapMarkerPlus,
    faMapMarkerTimes,
    faClipboardList,
    faChartBar,
    faSave,
    faFileAlt,
    faUserFriends,
    faSave,
    faStar,
    faMobile,
    faInfoCircle,
    faPollH,
    faUserClock,
    faFilter,
    faSync,
    faArchive,
    faCog,
    faQuestionCircle,
    faMapMarkerAlt,
);
