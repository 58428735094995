import { Invoice } from '../models';
import { InvoiceStatus, Permission } from '@gm2/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum InvoiceAction {
    Delete = 'delete',
    Edit = 'edit',
    Approve = 'approve',
    Decline = 'decline',
    SpecialDecline = 'specialDecline',
    Post = 'post',
    Paid = 'paid',
    PaidNotQualified = 'paidNotQualified',
    Export = 'export'
    // Special decline --jon says yes keep this
    // Taking off the declines for now though. It is slightly more complicated process with modal
    // Maybe best to leave for the decicated invoice view?
}

interface IActionPermissionsMapping {
    permission: Permission;
    statuses?: InvoiceStatus[];
}

type ActionPermissionsDict = {
    [key in InvoiceAction]: IActionPermissionsMapping
};

/**
 * Map of permissions and current invoice statuses required for performing
 * actions. The statuses field is optional and when ommitted means that any
 * status is valid.
 */
const actionPermissionsDict: ActionPermissionsDict = {
    [InvoiceAction.Delete]: {
        // Sp
        permission: Permission.InvoiceCreateEdit,
        statuses: [
            InvoiceStatus.InProgress,
            InvoiceStatus.Submitted,
            InvoiceStatus.Approved,
            InvoiceStatus.Rejected
        ]
    },
    [InvoiceAction.Edit]: {
        // Sp
        permission: Permission.InvoiceCreateEdit,
        statuses: [
            InvoiceStatus.InProgress,
            InvoiceStatus.Submitted,
            InvoiceStatus.Rejected
        ]
    },
    [InvoiceAction.Approve]: {
        // Admin
        permission: Permission.InvoiceApproval,
        statuses: [InvoiceStatus.Submitted]
    },
    // Does the same thing as special decline but from a different status and
    // with a slightly different permission
    [InvoiceAction.Decline] : {
        // Admin
        permission: Permission.InvoiceApproval,
        statuses: [
            InvoiceStatus.Submitted
        ]
    },
    [InvoiceAction.Post]: {
        // Admin
        permission: Permission.InvoicePost,
        statuses: [InvoiceStatus.Approved]
    },
    [InvoiceAction.Paid]: {
        // Admin
        permission: Permission.InvoiceBilling,
        statuses: [InvoiceStatus.Posted]
    },
    [InvoiceAction.PaidNotQualified]: {
        // Admin
        permission: Permission.InvoiceBilling,
        statuses: [InvoiceStatus.InProgress]
    },
    [InvoiceAction.Export]: {
        // Admin
        permission: Permission.InvoiceBilling,
        statuses: [
            InvoiceStatus.Approved,
            InvoiceStatus.Posted,
            InvoiceStatus.PaymentProcessed
        ]
    },
    [InvoiceAction.SpecialDecline]: {
        permission: Permission.InvoiceSpecialDecline,
        statuses: [
            // Can only do from Posted or PaymentProcessed https://app.clickup.com/t/f0ntrm
            // Approved https://app.clickup.com/t/h6x8z9
            InvoiceStatus.Approved,
            InvoiceStatus.Posted,
            InvoiceStatus.PaymentProcessed
        ]
    }
};

export type InvoiceActionCheckFn =
    (invoice: Invoice, action: InvoiceAction) => Observable<boolean>;

/**
 * Checks if a particular action can be performed on an invoice. Where an
 * "action" is an arbitrary user action that modifies or otherwise interacts
 * with the invoice. Whether an action can be performed depends on the
 * permissions allowed to the user and the current state of the invoice.
 */
export function canPerformAction(
    permissions: Observable<Permission[]>,
    invoice: Invoice,
    action: InvoiceAction
): Observable<boolean> {
    const conditions = actionPermissionsDict[action];
    const validStatus = !conditions.statuses || conditions.statuses.includes(invoice.invoiceStatus);

    return permissions.pipe(
        map(p => p.includes(conditions.permission) && validStatus),
    );
}

/**
 * Shorthand function for binding an observable permissions array to the the
 * check. This is just a handy function that takes advantage of the fact that
 * permissions comes from user state and ends up being shared between calls to
 * this function within a component.
 *
 * @returns The canPerformAction function with the permissions parameter bound
 *          persistently.
 */
export function bindPerformAction(
    permissions: Observable<Permission[]>
): InvoiceActionCheckFn {
    return canPerformAction.bind(window, permissions);
}
