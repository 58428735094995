import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MappingClassification, MappingType } from '@gm2/common';
import { IMappingCategoryLike } from '@gm2/ui-common';
interface IDialogData {}

@Component({
    selector: 'gm2-add-mapping-category-modal',
    templateUrl: './add-mapping-category-modal.component.html',
    styleUrls: ['./add-mapping-category-modal.component.scss']
})
export class AddMappingCategoryModalComponent implements OnInit {
    /**
     * Forward MappingClassification enum to view
     */
    public readonly MappingClassification: typeof MappingClassification = MappingClassification;

    /**
     * Forward MappingType enum to view
     */
    public readonly MappingType: typeof MappingType = MappingType;

    // This should conform to IMappingDetails
    public readonly form: UntypedFormGroup = this._formBuilder.group({
        name: ['', Validators.required],
        classification: ['', Validators.required],
        type: ['', Validators.required],
        color: [{ value: '', disabled: true }, Validators.required]
    });


    @ViewChild('colorPicker', { static: true })
    // public colorPicker: MccColorPickerComponent;
    public colorPicker: any;

    constructor(
        private readonly _dialogRef: MatDialogRef<AddMappingCategoryModalComponent>,
        private readonly _formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) data: IDialogData
    ) {}

    ngOnInit(): void {}

    public selectColor(hex: string): void {
        this.form.controls.color.setValue(hex);
    }

    public onCancel(): void {
        this._dialogRef.close(null);
    }

    public onAdd(): void {
        // Color input won't register or display errors if disabled
        // Click event will re-disable input if user tries to click it
        this.form.controls.color.enable();

        if (!this.form.valid) {
            Object.keys(this.form.controls).forEach(field => {
                const control = this.form.get(field);
                control.markAsTouched({ onlySelf: true });
            });
            return;
        }

        // Serialize form values
        const details: IMappingCategoryLike = this.form.getRawValue();

        this._dialogRef.close(details);
    }

    public disableColorInput(): void {
        // Have to enable color input on submit or it will not register errors
        // Tried to trigger color picker programatically, but didn't work
        // Instead will just re-disable input if it is clicked
        this.form.controls.color.disable();
    }
}
