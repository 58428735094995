import { isNull } from '../../utils';

export class BidServiceWithoutIds {
    public serviceId: any;
    public amount: number;
    public name: string;
    public materials: any[]

    constructor(params?: Partial<BidServiceWithoutIds>) {
        if (!!params) {
            this.serviceId = params.serviceId || this.serviceId;
            this.amount = !isNull(params.amount) ? params.amount : this.amount;
            this.name = params.name || this.name;
            this.materials = params.materials || this.materials
        }
    }
}
