import { isNull } from '../../utils';

export class WorkOrderTotals {
    public laborEstimate: number = 0;
    public laborTotal: number = 0;
    public equipmentTotal: number = 0;
    public materialTotal: number = 0;
    public salesTaxTotal: number = 0;
    public oneGmEstimate: number = 0;
    public oneGmTotal: number = 0;
    public clientPretaxPrice: number = 0;
    public clientEstimate: number = 0;
    public clientPrice: number = 0;
    public grossProfitEstimate: number = 0;
    public grossProfit: number = 0;
    public grossProfitPercentEstimate: number = 0;
    public grossProfitPercent: number = 0;
    public servicePartnerTotal: number = 0;

    constructor(params?: Partial<WorkOrderTotals>) {
        if (!!params) {
            this.laborEstimate = !isNull(params.laborEstimate)
                ? params.laborEstimate : this.laborEstimate;
            this.laborTotal = !isNull(params.laborTotal)
                ? params.laborTotal : this.laborTotal;
            this.equipmentTotal = !isNull(params.equipmentTotal)
                ? params.equipmentTotal : this.equipmentTotal;
            this.materialTotal = !isNull(params.materialTotal)
                ? params.materialTotal : this.materialTotal;
            this.salesTaxTotal = !isNull(params.salesTaxTotal)
                ? params.salesTaxTotal : this.salesTaxTotal;
            this.oneGmEstimate = !isNull(params.oneGmEstimate)
                ? params.oneGmEstimate : this.oneGmEstimate;
            this.oneGmTotal = !isNull(params.oneGmTotal)
                ? params.oneGmTotal : this.oneGmTotal;
            this.clientPretaxPrice = !isNull(params.clientPretaxPrice)
                ? params.clientPretaxPrice : this.clientPretaxPrice;
            this.clientEstimate = !isNull(params.clientEstimate)
                ? params.clientEstimate : this.clientEstimate;
            this.clientPrice = !isNull(params.clientPrice)
                ? params.clientPrice : this.clientPrice;
            this.grossProfitEstimate = !isNull(params.grossProfitEstimate)
                ? params.grossProfitEstimate : this.grossProfitEstimate;
            this.grossProfit = !isNull(params.grossProfit)
                ? params.grossProfit : this.grossProfit;
            this.grossProfitPercentEstimate = !isNull(params.grossProfitPercentEstimate)
                ? params.grossProfitPercentEstimate : this.grossProfitPercentEstimate;
            this.grossProfitPercent = !isNull(params.grossProfitPercent)
                ? params.grossProfitPercent : this.grossProfitPercent;
            this.servicePartnerTotal = !isNull(params.servicePartnerTotal)
                ? params.servicePartnerTotal : this.servicePartnerTotal;
        }
    }
}
