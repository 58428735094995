<form [formGroup]="form" (ngSubmit)="submit(form)">
    <h2 mat-dialog-title>Add Location to Contract</h2>
    <div mat-dialog-content>
        <strong>
            Locations added to this contract will be subject to all dates currently applied to this
            contract. Please verify that the dates are correct before continuing.
        </strong>
        <p>
            Only locations with the contract type enabled that are not already added to this
            contract may be added.
        </p>
        <mat-form-field>
            <mat-select required placeholder="Select Location" formControlName="location">
                <mat-option *ngFor="let l of (locations$ | async)" [value]="l">
                    {{ l.identity.name }}
                </mat-option>
            </mat-select>
            <mat-error [rfxError]="form.get('location')"></mat-error>
        </mat-form-field>
    </div>
    <div class="buttons" mat-dialog-actions align="end">
        <button type="button" mat-raised-button type="button" (click)="close()">Cancel</button>
        <button type="button" mat-raised-button type="submit" color="primary">
            Add Location
        </button>
    </div>
</form>
