import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { RfxGridComponent, RfxGridDataSource, RfxGridFilterControlComponent } from '@refactor/ngx/grid';

@Component({
    selector: 'gm2-grid-filter-clear-button',
    templateUrl: './grid-filter-clear-button.component.html',
    styleUrls: ['./grid-filter-clear-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: RfxGridFilterControlComponent,
            useExisting: GridFilterClearButton
        }
    ]
})
export class GridFilterClearButton implements OnInit {
    @Input()
    public rfxGrid: any;

    @Input()
    public ignore: string[] = [];

    constructor() {}

    ngOnInit(): void {}

    public clearFilters(): void {
        const controls = this.rfxGrid._gridFilterChildNonStatic.gridControls._results;

        for (const control of controls) {
            if (!!control.controlGroup && !this.ignore.includes(control.name)) {
                control.controlGroup.reset();
            }
        }

        // "Auto-search" after clearing filters
        this.rfxGrid.dataSource.updateData();
    }
}
