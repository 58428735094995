import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Body,
    Get,
    HttpService,
    Path,
    Post,
    Put,
    ResponseType
} from '@refactor/ngx/http';
import { FormCreateDto } from '../models/form-create.dto';
import { FormEditDto } from '../models/form-edit.dto';
import { FormExportDto } from '../models/form-export.dto';
import { FormResponseDto } from '../models/form-response.dto';
import { Observable } from 'rxjs';
import { RfxForm, GridParams, PaginatedList } from '@refactor/common';

@Injectable({
    providedIn: 'root'
})
export class FormBuilderService extends HttpService {
    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    @Post('/rfx-form')
    public createForm(@Body() body: FormCreateDto): Observable<void> {
        return null;
    }

    @Put('/rfx-form/:id')
    public editForm(
        @Path('id') id: unknown,
        @Body() body: FormEditDto
    ): Observable<void> {
        return null;
    }

    /*
        The delete() method of Angular's HttpClient class
        does not directly support attaching a request body,
        so we need to use the request() method directly instead.
        Pat D Dec 16 2020
    */
    public deleteForms(ids: unknown[]): Observable<void> {
        const options = {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                ids: ids
            }
        };
        return this._httpClient.request<void>('delete', '/rfx-form', options);
    }

    @Get('/rfx-form/:id')
    public getFormById(@Path('id') id: string): Observable<RfxForm> {
        return null;
    }

    @Post('/rfx-form/list')
    public getFormList(
        @Body() params: GridParams
    ): Observable<PaginatedList<RfxForm>> {
        return null;
    }

    @Post('/rfx-form/submit/:id')
    public submitResponse(
        @Path('id') id: string,
        @Body() body: FormResponseDto
    ): Observable<string> {
        return null;
    }

    @Post('/rfx-form/export/:id', { responseType: ResponseType.Text })
    public exportFormResponses(
        @Path('id') id: string,
        @Body() body: FormExportDto
    ): Observable<string> {
        return null;
    }
}
