import { ServiceActivityWithoutIds } from '@gm2/common';

export class ServiceActivity extends ServiceActivityWithoutIds {
    public serviceId: string = '';
    public userName:string;
    public clientServiceName: string;

    constructor(params?: Partial<ServiceActivity>) {
        super(params);
        if (!!params) {
            this.serviceId = params.serviceId || this.serviceId;
            this.userName = params.userName || this.userName;
            this.clientServiceName = params.clientServiceName || '';
        }
    }
}
