import { DateBucket, parseISODate } from '@refactor/common';
import { BidWithoutIds } from '@gm2/common';
import { CompanyIdentity } from '../company/CompanyIdentity';
import { BidService } from './bid-service';

export class Bid extends BidWithoutIds {
    public services: BidService[] = [];
    public companyIdentity: CompanyIdentity = new CompanyIdentity();

    constructor(params?: Partial<BidWithoutIds>) {
        super(params);
        if (!!params) {
            this.services = Array.isArray(params.services)
                ? params.services.map(service => new BidService(service))
                : this.services;

            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentity(params.companyIdentity)
                : this.companyIdentity;
        }
    }
}
