import { SelectOptionWithoutIds } from '@gm2/common';

export class SelectOption extends SelectOptionWithoutIds {
    public _id: string;

    constructor(params?: Partial<SelectOption>) {
        super(params);
        if (!!params) {
            this._id = !!params._id ? params._id : this._id;
        }
    }
}
