import { CompanyIdentityWithoutIds } from '../company/company-identity';
import { DateBucket, parseISODate } from '@refactor/common';
import { BidWithoutIds } from './bid';
import { UserIdentityWithoutIds } from '../user/user-identity';

export class AwardedBidWithoutIds {
    public awardedByUserIdentity: UserIdentityWithoutIds = new UserIdentityWithoutIds();
    public bid: BidWithoutIds = new BidWithoutIds();
    public awardedDate: Date = new Date();
    public awardedDateBucket: DateBucket = new DateBucket();

    constructor(params?: Partial<AwardedBidWithoutIds>) {
        if (!!params) {
            this.awardedByUserIdentity = !!params.awardedByUserIdentity
                ? new UserIdentityWithoutIds(params.awardedByUserIdentity)
                : this.awardedByUserIdentity;

            this.bid = !!params.bid ? new BidWithoutIds(params.bid) : this.bid;

            this.awardedDate = !!params.awardedDate
                ? parseISODate(params.awardedDate)
                : this.awardedDate;

            this.awardedDateBucket = !!params.awardedDateBucket
                ? new DateBucket(params.awardedDateBucket)
                : this.awardedDateBucket;
        }
    }
}
