import { isNull } from '../../utils';

export class WorkOrderMaterialWithoutIds {
    public _id: any;
    public description: string = '';
    public quantity: number = 0;
    public size: string = '';
    public price: number = 0;
    public markup: number = 0;
    public total: number = 0;

    constructor(params?: Partial<WorkOrderMaterialWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.quantity = !isNull(params.quantity) ? params.quantity : this.quantity;
            this.description = params.description || this.description;
            this.size = !isNull(params.size) ? params.size : this.size;
            this.price = !isNull(params.price) ? params.price : this.price;
            this.markup = !isNull(params.markup) ? params.markup : this.markup;
            this.total = !isNull(params.total) ? params.total : this.total;
        }
    }
}
