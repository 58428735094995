export enum RfxFormFieldConditionRuleComparison {
    Equals = '[RfxFormFieldConditionRuleComparison] equals',
    DoesNotEqual = '[RfxFormFieldConditionRuleComparison] doesNotEqual'
}

export namespace RfxFormFieldConditionRuleComparison {
    export function toString(rule: RfxFormFieldConditionRuleComparison): string {
        switch (rule) {
            case RfxFormFieldConditionRuleComparison.Equals:
                return 'Is';
            case RfxFormFieldConditionRuleComparison.DoesNotEqual:
                return 'Is Not';
            default:
                return '';
        }
    }

    export const members: RfxFormFieldConditionRuleComparison[] = [
        RfxFormFieldConditionRuleComparison.Equals,
        RfxFormFieldConditionRuleComparison.DoesNotEqual
    ];
}
