export enum ServicePartnerCompliant {
    Compliant = '[ServicePartnerCompliant] compliant',
    NonCompliant = '[ServicePartnerCompliant] nonCompliant'
}

export namespace ServicePartnerCompliant {
    export function toString(compliant: ServicePartnerCompliant): string {
        switch (compliant) {
            case ServicePartnerCompliant.Compliant:
                return 'Compliant';
            case ServicePartnerCompliant.NonCompliant:
                return 'Non-Compliant';
            default:
                return ''
        }
    }

    export const members: ServicePartnerCompliant[] = [
        ServicePartnerCompliant.Compliant,
        ServicePartnerCompliant.NonCompliant
    ];
}
