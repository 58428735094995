import {
    Component,
    OnInit,
    Input,
    ChangeDetectionStrategy,
    ViewChild
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Address } from '../../models/Address';
import { AddressComponent } from '../address/address.component';

/**
 * Address Form Component
 *
 * Provides a basic form layout on top of the AddressComponent
 */
@Component({
    selector: 'rfx-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressFormComponent implements OnInit {
    /** @ignore */
    private _addressFormGroup: UntypedFormGroup = Address.toFormGroup();
    /**
     * Set an instance of a FormGroup to use
     */
    @Input()
    public set addressFormGroup(value: UntypedFormGroup) {
        if (value instanceof UntypedFormGroup) {
            this._addressFormGroup = value;
            this.addressComponent.addressFormGroup = value;
        }
    }
    /**
     * Returns the currently used FormGroup instance
     */
    public get addressFormGroup(): UntypedFormGroup {
        return this._addressFormGroup;
    }

    /**
     * @ignore
     *
     * Used for implementing the underlying AddressComponent
     */
    @ViewChild(AddressComponent, { static: true })
    public addressComponent: AddressComponent;

    /**
     * Returns the current value of the Address Form
     */
    public get value(): Address {
        return this.addressComponent.value;
    }

    /**
     * @ignore
     */
    constructor() {}

    /**
     * @ignore
     */
    ngOnInit(): void {}

    /**
     * Validates the current inputs in the form
     *
     * @returns validated status
     */
    public validate(): boolean {
        return this.addressComponent.validate();
    }
}
