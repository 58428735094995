import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    ViewChild,
    TemplateRef,
    Input
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RfxGridAction } from '../GridAction';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { GridActionBarControlComponent } from '../grid-action-bar-control/grid-action-bar-control.component';

@Component({
    selector: 'rfx-grid-action-bar-delete-control',
    templateUrl: './grid-action-bar-delete-control.component.html',
    styleUrls: ['./grid-action-bar-delete-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: GridActionBarControlComponent,
            useExisting: GridActionBarDeleteControlComponent
        }
    ]
})
export class GridActionBarDeleteControlComponent<T>
    implements OnInit, RfxGridAction<T> {
    @ViewChild('deleteConfirmation', { read: TemplateRef, static: true })
    public deleteTemplate: TemplateRef<any>;

    @Output()
    public delete: EventEmitter<T[]> = new EventEmitter<T[]>();

    @Input()
    public icon: string | [string, string] = ['far', 'trash'];

    private _selectionWatcher: Subscription;

    public selectedItems: T[] = [];
    public selection: SelectionModel<T>;

    constructor(private _dialog: MatDialog) {}

    ngOnInit(): void {}

    public setSelection(selection: SelectionModel<T>): void {
        this.selection = selection;
        if (!!this.selection) {
            if (!!this._selectionWatcher) {
                this._selectionWatcher.unsubscribe();
            }
            this._selectionWatcher = this.selection.changed.subscribe(items => {
                this.selectedItems = this.selection.selected;
            });
        }
    }

    public _onDelete(): void {
        const ref = this._dialog.open(this.deleteTemplate);
        ref.afterClosed().subscribe(result => {
            console.log('result', result);
            if (!!result) {
                this.delete.emit(this.selectedItems);
            }
        });
    }

    public updateItems(items: T[]): void {
        console.log('update items', items);
        this.selectedItems = items;
    }
}
