import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import {
    Rfp,
    RfpServiceDto,
    RfpService,
    ToastService,
    ContractApplied,
    RfpServiceSettings,
    AssigneeType,
    ServiceSource
} from '@gm2/ui-common';
import { UntypedFormArray, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
    selector: 'gm2-rfp-pricing',
    templateUrl: './rfp-pricing.component.html',
    styleUrls: ['./rfp-pricing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RfpPricingComponent implements OnInit {
    @Input()
    public rfp: Rfp;
    @Input()
    public contract: ContractApplied;
    @Input()
    public isMonthly: boolean;

    public readonly AssigneeType: typeof AssigneeType = AssigneeType;
    public readonly rfpPricingForm: UntypedFormGroup = this._fb.group({
        services: this._fb.array([]),
        monthlyClientPrice: [0],
        monthlyRequestedPrice: [0],
        requestedPriceLabel: ['']
    });

    public submitDisabledStatus: boolean = false;

    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly _rfpService: RfpService,
        private readonly _toastService: ToastService
    ) {}

    ngOnInit(): void {
        console.log(this.rfp, "RFP")
        this.rfpPricingForm.controls.monthlyClientPrice.patchValue(
            this.rfp.clientPricing.monthlyClientPrice
        );
        this.rfpPricingForm.controls.monthlyRequestedPrice.patchValue(
            this.rfp.clientPricing.monthlyRequestedPrice
        );
        this.rfpPricingForm.controls.requestedPriceLabel.patchValue(
            this.rfp.requestedPriceLabel
        );

        if (this.rfp.status === 0) {
            this.submitDisabledStatus = false
        } else if(this.rfp.status === 1) {
            this.submitDisabledStatus = true
        }

        // ~~~~~~ Populate services formarray ~~~~~~
        // Array of services with clientPricing
        const clientPricing = this.rfp.clientPricing.services;
        if (this.contract.serviceSource === ServiceSource.Package) {
            for (const service of this.rfp.snapShot.package.services) {
                const hasChildren = service.children.length > 0;
                const hasParentControl = service.settings.parentControl;
                // Handle service
                const dto = new RfpServiceDto({
                    serviceId: service._id,
                    name: service.name,
                    description: service.description,
                    settings: new RfpServiceSettings({
                        hasClientFee: service.settings.hasClientFee,
                        hasMaterial: service.settings.hasMaterial,
                        parentControl: service.settings.parentControl
                    })
                });

                if (service.settings.parentControl) {
                    console.log('YOOOOOOOOOOOOOOOO, has parentControl true');
                }

                const matchingClientPricing = clientPricing.find(s => s.serviceId === service._id);
                if (!hasChildren || hasParentControl) {
                    if (matchingClientPricing) {
                        Object.assign(dto, matchingClientPricing);
                    }
                    this.addServicePricing(dto);
                }

                // Handle child services
                for (const child of service.children) {
                    const childDto = new RfpServiceDto({
                        serviceId: child._id,
                        name: child.name,
                        description: child.description,
                        settings: new RfpServiceSettings({
                            hasClientFee: child.settings.hasClientFee,
                            hasMaterial: child.settings.hasMaterial,
                            parentControl: service.settings.parentControl
                        })
                    });

                    const matchingChild = clientPricing.find(s => s.serviceId === child._id);
                    if (matchingChild) {
                        Object.assign(childDto, matchingChild);
                    }
                    this.addServicePricing(childDto, true);
                }
            }
        } else {
            for (const service of this.rfp.snapShot.companyService.services) {
                const hasChildren = service.children.length > 0;
                const hasParentControl = service.settings.parentControl;
                // Handle service
                const dto = new RfpServiceDto({
                    serviceId: service._id,
                    name: service.name,
                    description: service.description,
                    settings: new RfpServiceSettings({
                        hasClientFee: service.settings.hasClientFee,
                        hasMaterial: service.settings.hasMaterial,
                        parentControl: service.settings.parentControl
                    })
                });

                if (service.settings.parentControl) {
                    console.log('YOOOOOOOOOOOOOOOO, has parentControl true');
                }

                const matchingClientPricing = clientPricing.find(s => s.serviceId === service._id);
                if (!hasChildren || hasParentControl) {
                    if (matchingClientPricing) {
                        Object.assign(dto, matchingClientPricing);
                    }
                    this.addServicePricing(dto);
                }

                // Handle child services
                for (const child of service.children) {
                    const childDto = new RfpServiceDto({
                        serviceId: child._id,
                        name: child.name,
                        description: child.description,
                        settings: new RfpServiceSettings({
                            hasClientFee: child.settings.hasClientFee,
                            hasMaterial: child.settings.hasMaterial,
                            parentControl: service.settings.parentControl
                        })
                    });

                    const matchingChild = clientPricing.find(s => s.serviceId === child._id);
                    if (matchingChild) {
                        Object.assign(childDto, matchingChild);
                    }
                    this.addServicePricing(childDto, true);
                }
            }
        }
    }

    public submitRfpPricing({ value }: { value: any }): void {
        if (this.isMonthly) {
            delete value.services;
        } else {
            value.monthlyClientPrice = 0;
            value.monthlyRequestedPrice = 0;
        }
        console.log('submit this for client pricing', value);
        this._rfpService.saveClientPricingToRfp(this.rfp._id, value).subscribe(
            res => {
                this._toastService.success('Saved pricing successfully!');
            },
            err => {
                this._toastService.error(err);
            }
        );
    }

    public get servicePricingFormArray(): UntypedFormArray {
        return this.rfpPricingForm.controls.services as UntypedFormArray;
    }

    public addServicePricing(s?: RfpServiceDto, isChild?: boolean): void {
        this.servicePricingFormArray.push(this.createServicePricing(s, isChild));
    }

    public createServicePricing(s?: RfpServiceDto, isChild?: boolean): UntypedFormGroup {
        return this._fb.group({
            requestedPrice: [s.requestedPrice || 0],
            clientPrice: [s.clientPrice || 0],
            materialPrice: [s.materialPrice || 0],
            clientFee: [s.clientFee || 0],

            // Fields below are for display/saving purposes only
            serviceId: [s.serviceId || ''],
            name: [{ value: s.name || '', disabled: true }],
            description: [{ value: s.description || '', disabled: true }],
            hasClientFee: [{ value: s.settings.hasClientFee, disabled: true }],
            hasMaterial: [{ value: s.settings.hasMaterial, disabled: true }],
            parentControl: [{ value: s.settings.parentControl, disabled: true }],
            isChild: [{ value: isChild, disabled: true }]
        });
    }

    public removeServicePricing(i: number): void {
        this.servicePricingFormArray.removeAt(i);
    }

    public isSPContract(): boolean {
        return this.contract.identity.assigneeType === AssigneeType.ServicePartner;
    }

    public isPLContract(): boolean {
        return this.contract.identity.assigneeType === AssigneeType.Onegm;
    }

    public hasClientPricing(service: UntypedFormGroup): boolean {
        const isChild = !!service.get('isChild').value;
        const parentControl = !!service.get('parentControl').value;

        // isChild NAND parentControl
        return !(isChild && parentControl);
    }

    public hasMaterialPricing(service: UntypedFormGroup): boolean {
        return service.get('hasMaterial').value;
    }

    public hasClientFee(service: UntypedFormGroup): boolean {
        return service.get('hasClientFee').value;
    }
}
