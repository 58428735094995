export class WorkOrderInvoiceTotals {
    public total: number = 0;
    public clientTotal: number = 0;

    constructor(params?: Partial<WorkOrderInvoiceTotals>) {
        if (!!params) {
            this.total = params.total || this.total;
            this.clientTotal = params.clientTotal || this.clientTotal;
        }
    }
}
