import { WorkOrderInvoiceDeclinedWithoutIds } from '@gm2/common';

export class WorkOrderInvoiceDeclined extends WorkOrderInvoiceDeclinedWithoutIds {
    public phaseIds: string[] = [];

    constructor(params?: Partial<WorkOrderInvoiceDeclinedWithoutIds>) {
        super(params);
        if (!!params) {
            this.phaseIds = Array.isArray(params.phaseIds)
                ? params.phaseIds
                : this.phaseIds;
        }
    }
}
