export enum RfpPhase {
    // Note this is not an actual status that lives on the Rfp.
    // It is used for filtering the rfp bid list
    Available = 'available',
    FirstApproval = 'firstApproval',
    Awarded = 'awarded'
}

export namespace RfpPhase {
    export const members: RfpPhase[] = [
        RfpPhase.Available,
        RfpPhase.FirstApproval,
        RfpPhase.Awarded
    ];

    export function toString(member: RfpPhase): string {
        switch (member) {
            case RfpPhase.Available:
                return 'Available';
            case RfpPhase.FirstApproval:
                return 'First Approval';
            case RfpPhase.Awarded:
                return 'Awarded';
            default:
                return '';
        }
    }
}
