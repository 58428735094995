import { LabelPlacement } from '../../enums';

export class FormSettings {
    public serviceId: any;
    public roleIds: any[] = [];
    public labelPlacement: LabelPlacement;
    public hasPages: boolean;
    public description: string;
    public submitBtnLabel: string;
    public printBtnLabel: string;
    public hasPrint: boolean;
    public saveOnPageChange: boolean;
    public formCompleteText: string;
    public isService: boolean;

    constructor(params?: Partial<FormSettings>) {
        if (!!params) {
            this.serviceId = params.serviceId || this.serviceId;
            this.roleIds = params.roleIds || this.roleIds;
            this.labelPlacement = params.labelPlacement || this.labelPlacement;
            this.hasPages = params.hasPages || this.hasPages;
            this.description = params.description || this.description;
            this.submitBtnLabel = params.submitBtnLabel || this.submitBtnLabel;
            this.printBtnLabel = params.printBtnLabel || this.printBtnLabel;
            this.hasPrint = params.hasPrint || this.hasPrint;
            this.saveOnPageChange = params.saveOnPageChange || this.saveOnPageChange;
            this.formCompleteText = params.formCompleteText || this.formCompleteText;
            this.isService = params.isService || this.isService;
        }
    }
}
