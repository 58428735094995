import { LocationSimpleWithoutIds } from '@gm2/common';

export class LocationSimple extends LocationSimpleWithoutIds {
    public _id: string = '';
    public tags: string[] = [];

    constructor(params?: Partial<LocationSimple>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.tags = Array.isArray(params.tags) ? params.tags : this.tags;
        }
    }
}
