import { Company, Tag } from '@gm2/ui-common';
import { State, Selector, StateContext } from '@ngxs/store';
import { ImmutableSelector, ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Receiver } from '@ngxs-labs/emitter';
import { Injectable } from '@angular/core';

export interface TagStateModel {
    tags: Tag[];
}

@State<TagStateModel>({
    name: 'tags',
    defaults: {
        tags: []
    }
})
@Injectable()
export class TagsState {
    @Selector()
    @ImmutableSelector()
    public static tags(state: TagStateModel): Tag[] {
        return state.tags;
    }

    @Receiver({ type: '[Tags] set tags' })
    @ImmutableContext()
    public static setTags(
        { setState }: StateContext<TagStateModel>,
        { payload }: { payload: Tag[] }
    ): void {
        setState((state: TagStateModel) => {
            state.tags = payload;
            return state;
        });
    }

    @Receiver({ type: '[Tags] add tag' })
    @ImmutableContext()
    public static addTag(
        { setState, getState }: StateContext<TagStateModel>,
        { payload }: { payload: Tag }
    ): void {
        const s = getState();
        setState((state: TagStateModel) => {
            if (s.tags.findIndex(t => t._id === payload._id) === -1) {
                state.tags.push(payload);
                state.tags = state.tags.sort((a, b) => {
                    return a.label > b.label ? -1 : a.label < b.label ? 1 : 0;
                });
            }
            return state;
        });
    }
}
