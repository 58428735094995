import { isNull } from '../../utils';

export enum WorkOrderHistoryType {
    StatusChange = '[WorkOrderHistoryType] StatusChange',
    DataChange = '[WorkOrderHistoryType] DataChange',
    Other = '[WorkOrderHistoryType] Other'
}

export namespace WorkOrderHistoryType {
    export function toString(type: WorkOrderHistoryType): string {
        switch (type) {
            case WorkOrderHistoryType.StatusChange:
                return 'Status Change';
            case WorkOrderHistoryType.DataChange:
                return 'Data Change';
            case WorkOrderHistoryType.Other:
                return 'Other';
            default:
                return '';
        }
    }

    export const members: WorkOrderHistoryType[] = [
        WorkOrderHistoryType.StatusChange,
        WorkOrderHistoryType.DataChange,
        WorkOrderHistoryType.Other
    ];
}
