<mat-carousel
  timings="250ms ease-in"
  [autoplay]="false"
  color="accent"
  maxWidth="auto"
  height="auto"
  proportion="100"
  [loop]="true"
  [hideArrows]="false"
  [hideIndicators]="false"
  [useKeyboard]="true"
  [useMouseWheel]="false"
  orientation="ltr"
>
  <mat-carousel-slide
    #matCarouselSlide
    *ngFor="let slide of slides; let i = index"
    [image]="slide.url"
    overlayColor="#00000040"
    [hideOverlay]="false"
  ></mat-carousel-slide>
</mat-carousel>