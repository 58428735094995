import { BidServiceWithoutIds } from '@gm2/common';

export class BidService extends BidServiceWithoutIds {
    public serviceId: string;

    constructor(params?: Partial<BidServiceWithoutIds>) {
        super(params);
        if (!!params) {
            this.serviceId = params.serviceId || this.serviceId;
        }
    }
}
