import { CompanyType, CompanyStatus, CompanySettings } from '../../../../index';
import { CompanyProfile } from './CompanyProfile';
import { SubcontractorInfo } from '../subcontractor-info/subcontractor-info';

export class CompanySimple {
    public _id: string;
    public createdDate: Date = null;
    public modifiedDate: Date = null;
    public type: CompanyType = null;
    public profile: CompanyProfile;
    public companyStatus: CompanyStatus;
    public subcontractorInfo?: SubcontractorInfo;
    public settings?: CompanySettings;

    constructor(params?: Partial<CompanySimple>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.type = params.type || this.type;
            this.profile = new CompanyProfile(params.profile);
            this.createdDate = !!params.createdDate
                ? new Date(params.createdDate)
                : this.createdDate;
            this.modifiedDate = !!params.modifiedDate
                ? new Date(params.modifiedDate)
                : this.modifiedDate;
            this.companyStatus = params.companyStatus || this.companyStatus;
            this.subcontractorInfo = params.subcontractorInfo
                ? new SubcontractorInfo(params.subcontractorInfo)
                : this.subcontractorInfo;
            this.settings = params.settings ?? this.settings;
        }
    }
}
