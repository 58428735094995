import { Address } from '@gm2/common';

export class CompanyProfileDto {
    public name: string;
    public phone: string;
    public fax: string;
    public address: Address = new Address();
    public email: string;
    public notificationEmail: string;
}
