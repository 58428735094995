<h2 mat-dialog-title>Terms & Conditions</h2>
<div mat-dialog-content>
    <pdf-viewer [src]="pdfSrc" [render-text]="true"
                [original-size]="false"
                style="width: 1200px; height: 800px"
    ></pdf-viewer>
</div>
<div mat-dialog-actions align="end">
    <button mat-stroked-button (click)="onNoClick()">Close</button>
    <button mat-flat-button color="primary" *ngIf="!data.accepted" (click)="onTermsAccepted()">
        Accept
    </button>
</div>
