import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subject, Observable, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    MaterialCalculationService,
    Material,
    ServiceClassification,
    MappingClassificationArea,
    ServiceTimesheetField,
    MaterialExceededRatio,
    MaterialMeasurementType
} from '@gm2/ui-common';

@Component({
    selector: 'gm2-timesheet-service-material',
    templateUrl: './timesheet-service-material.component.html',
    styleUrls: ['./timesheet-service-material.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimesheetServiceMaterialComponent implements OnInit {

    @Input()
    public form: UntypedFormGroup;
    @Input()
    public availableMaterials: Material[];
    @Input()
    public serviceClassification: ServiceClassification;
    @Input()
    public mappingClassificationAreas$: Observable<MappingClassificationArea[]>;
    @Input()
    public serviceTypeInformation: any[];
    @Input()
    public calculationTrigger$: Observable<void>;
    @Input()
    public serviceTrigger$: Subject<void>;

    public expectedAmountString$: BehaviorSubject<string> =
        new BehaviorSubject<string>('');
    public measurementString$: BehaviorSubject<string> =
        new BehaviorSubject<string>('');
    public requireComment$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    public warning$: BehaviorSubject<string> =
        new BehaviorSubject<string>('')

    private _onDestroy$: Subject<void> = new Subject<void>();

    public mappingClassificationAreas: MappingClassificationArea[];

    constructor(
        private readonly _calculationService: MaterialCalculationService
    ) {}

    ngOnInit(): void {
        this.form.controls.type.valueChanges
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(val => {
                this.measurementString$.next(
                    !!val && !!val.measurement
                        ? MaterialMeasurementType.toString(val.measurement)
                        : ''
                );
                this.expectedCalculation();
                this.exceededCalculation();
                this.serviceTrigger$.next();
            });

        if (!this.form.value.note) {
            this.form.controls.note.setValue('Default Note')
        }
        combineLatest([
            this.calculationTrigger$,
            this.mappingClassificationAreas$
        ])
        .pipe(takeUntil(this._onDestroy$))
        .subscribe(([_, areas]) => {
            this.mappingClassificationAreas = areas;
            this.expectedCalculation();
            this.exceededCalculation();
        });

        this.form.controls.amount.valueChanges
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(_ => {
                this.expectedCalculation();
                this.exceededCalculation();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    public compareWith(a: Material, b: any): boolean {
        if (!a || !b) {
            return false;
        }

        if (Boolean(a?._id) && Boolean(b?.snapShot?.material?._id)) {
            return a?._id === b?.snapShot?.material?._id
        }
        return a?._id === b?._id;
    }

    public expectedCalculation(): void {
        /*
            Service type information is not provided
            for all services. 'Snow' is an example
            where there will be service type information
        */
        const selected = this.form.controls.type.value;
        if (this.serviceTypeInformation === null ||
            !selected ||
            !this.serviceClassification ||
            !this.mappingClassificationAreas
        ) {
            this.expectedAmountString$.next('n/a');
            return;
        }
        if (!!selected && !!selected._id) {
            this.form.controls._id.setValue(
                !!selected._id
                    ? selected._id
                    : selected.snapShot.material._id
            );
        } else if(!!selected && !selected._id) {
            this.form.controls._id.setValue(
                selected.activeIngredient
            );
        }
        this.form.controls._id.updateValueAndValidity();
        const ingredient = !!selected.snapShot
            ? selected.snapShot.material.activeIngredient
            : selected.activeIngredient;
        const result = this._calculationService
            .getExpectedRateByIngredient(
                this.serviceClassification,
                this.mappingClassificationAreas,
                this.serviceTypeInformation[ServiceTimesheetField.SurfaceTemperature],
                this.serviceTypeInformation[ServiceTimesheetField.GroundApplication],
                ingredient
            );
        this.expectedAmountString$.next(!!result ? result.toString() : 'n/a');
    }

    public exceededCalculation(): void {
        /*
            Service type information is not provided
            for all services. 'Snow' is an example
            where there will be service type information
        */
        const noWarning = () => {
            this.requireComment$.next(false);
            this.warning$.next('');
            this.form.controls.note.disable();
        }
        const selected = this.form.controls.type.value;

        if (this.serviceTypeInformation === null ||
            !selected ||
            !this.serviceClassification ||
            !this.mappingClassificationAreas
        ) {
            noWarning();
            return;
        }
        const ingredient = !!selected.snapShot
            ? selected.snapShot.material.activeIngredient
            : selected.activeIngredient;
        const result = this._calculationService
            .exceededAmountRatio(
                this.serviceClassification,
                this.mappingClassificationAreas,
                this.form.value.amount,
                this.serviceTypeInformation[ServiceTimesheetField.SurfaceTemperature],
                this.serviceTypeInformation[ServiceTimesheetField.GroundApplication],
                ingredient
            );
        const over = [MaterialExceededRatio.Greater25, MaterialExceededRatio.Greater50];
        const under = [MaterialExceededRatio.Less25, MaterialExceededRatio.Less50];

        if (over.includes(result) || under.includes(result)) {
            this.requireComment$.next(true);
            this.form.controls.note.enable();
            this.serviceTrigger$.next();
            if (over.includes(result)) {
                this.warning$.next(
                    'Material use is 25% or more greater than expected.\
                     Please provide a comment explaining why.'
                );
            }
            if (under.includes(result)) {
                this.warning$.next(
                    'Material use is 25% or more underneath expected. \
                     Please provide a comment explaining why.'
                );
            }
        } else {
            noWarning();
        }
    }

    public getControl(name: string): UntypedFormControl {
        return this.form.controls[name] as UntypedFormControl;
    }
}
