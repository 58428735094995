<rfx-grid-action-bar-control
    matTooltip="Delete"
    [matTooltipShowDelay]="500"
    [matTooltipHideDelay]="500"
    (click)="_onDelete()"
    (selectedItems)="updateItems($event)"
>
    <fa-icon [icon]="icon"></fa-icon>
</rfx-grid-action-bar-control>

<ng-template #deleteConfirmation>
    <div class="delete-box">
        <h2 mat-dialog-title class="delete-box__header">Confirm Delete</h2>
        <p mat-dialog-content class="delete-box__description">
            Are you sure you want to delete the
            {{ selectedItems.length }} currently selected item{{
                selectedItems.length === 1 ? '' : 's'
            }}?
        </p>
        <div mat-dialog-actions align="end" class="delete-box__actions">
            <button
                mat-dialog-close
                class="delete-box__action"
                mat-raised-button
            >
                Cancel
            </button>
            <button
                cdkFocusInitial
                [mat-dialog-close]="true"
                class="delete-box__action"
                mat-raised-button
                color="warn"
            >
                Delete
            </button>
        </div>
    </div>
</ng-template>
