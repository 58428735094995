import {
    TimesheetValidationStatus,
    TimesheetStatus,
    BillingType,
    ServiceTimesheetField,
    InvoiceStatus,
} from '../../enums';
import { TimesheetServicesWithoutIds } from './timesheet-services';
import { parseISODate } from '@refactor/common';
import { MappingClassificationArea } from '../mapping/mapping-classification-area';
import { CompanyIdentityWithoutIds } from '../company/company-identity';
import { PackageSnapShotWithoutIds } from '../package/package-snap-shot';
import { TimesheetNativeApp } from './timesheet-native-app';
import { InvoiceIdentityWithoutIds } from '../invoice/invoice-identity';
import { CompanyServiceWithoutIds } from '../company/company-service';

export class TimesheetSimpleWithoutIds {
    public _id: any = null;
    public rfpId: any = null;
    public locationId: any = null;
    public companyIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public totalCharges: number = 0;
    public billingType: BillingType;
    public timesheetNumber: string = '';
    public timesheetStartDate: Date;
    public timesheetStartDateInLocationTz: string = '';
    public timesheetStartTimeInLocationTz: string = '';
    public locationTimezone: string = '';
    public locationName: string = '';
    public validationStatus: TimesheetValidationStatus = null;
    public validationComment: string = '';
    public timesheetStatus: TimesheetStatus = null;
    public packageName: string = '';
    public companyServiceName: string = '';
    public invoiceIdentity: InvoiceIdentityWithoutIds = new InvoiceIdentityWithoutIds();
    public nativeApp: TimesheetNativeApp = new TimesheetNativeApp();
    // for status tracking of images upload asynchronously from the mobile app
    public asyncImageCount: number;
    public syncedImageCount: number;

    // Added per jadryan's request (to match old site I think?) but not in story
    // Leaving these fields out of csv export for now
    public crewSize: number = null;
    public timesheetEndDate: Date;
    public timesheetEndDateInLocationTz: string = '';
    public timesheetEndTimeInLocationTz: string = '';
    public services: TimesheetServicesWithoutIds[];
    public mappingClassificationAreas: MappingClassificationArea[] = [];
    public packageSnapshot: PackageSnapShotWithoutIds;
    public companyServiceSnapshot: CompanyServiceWithoutIds;
    public serviceTypeFields: { [key in ServiceTimesheetField]?: number | string } = {};
    public createdBy: string;
    public contractOwnerIdentity?: CompanyIdentityWithoutIds;

    constructor(params?: Partial<TimesheetSimpleWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.rfpId = params.rfpId || this.rfpId;
            this.locationId = params.locationId || this.locationId;
            this.companyIdentity = params.companyIdentity || this.companyIdentity;
            this.billingType = params.billingType || this.billingType;
            this.totalCharges = params.totalCharges || this.totalCharges;
            this.timesheetNumber = params.timesheetNumber || this.timesheetNumber;
            this.timesheetStartDate = !!params.timesheetStartDate
                ? parseISODate(params.timesheetStartDate)
                : this.timesheetStartDate;
            this.locationTimezone = params.locationTimezone || this.locationTimezone;
            this.locationName = params.locationName || this.locationName;
            this.validationStatus = params.validationStatus || this.validationStatus;
            this.validationComment = params.validationComment || this.validationComment;
            this.timesheetStatus = params.timesheetStatus || this.timesheetStatus;
            this.timesheetStartDateInLocationTz =
                !!this.locationTimezone && !!this.timesheetStartDate
                    ? this.timesheetStartDate.toLocaleDateString('en-US', {
                        timeZone: this.locationTimezone,
                    })
                    : this.timesheetStartDateInLocationTz;
            this.timesheetStartTimeInLocationTz =
                !!this.locationTimezone && !!this.timesheetStartDate
                    ? this.timesheetStartDate.toLocaleTimeString('en-US', {
                        timeZone: this.locationTimezone,
                    })
                    : this.timesheetStartDateInLocationTz;
            this.packageName = params.packageName || this.packageName;
            this.companyServiceName = params.companyServiceName || this.companyServiceName;
            this.invoiceIdentity = !!params.invoiceIdentity ? new InvoiceIdentityWithoutIds(params.invoiceIdentity) : this.invoiceIdentity;

            this.nativeApp = params.nativeApp || this.nativeApp;
            this.crewSize = params.crewSize || this.crewSize;
            this.timesheetEndDate = !!params.timesheetEndDate
                ? parseISODate(params.timesheetEndDate)
                : this.timesheetEndDate;
            this.timesheetEndDateInLocationTz =
                !!this.locationTimezone && !!this.timesheetEndDate
                    ? this.timesheetEndDate.toLocaleDateString('en-US', {
                        timeZone: this.locationTimezone,
                    })
                    : this.timesheetEndDateInLocationTz;
            this.timesheetEndTimeInLocationTz =
                !!this.locationTimezone && !!this.timesheetEndDate
                    ? this.timesheetEndDate.toLocaleTimeString('en-US', {
                        timeZone: this.locationTimezone,
                    })
                    : this.timesheetEndDateInLocationTz;
            this.services = !!params.services
                ? params.services.map(s => new TimesheetServicesWithoutIds(s))
                : this.services;
            this.mappingClassificationAreas = !!params.mappingClassificationAreas
                ? params.mappingClassificationAreas.map(s => new MappingClassificationArea(s))
                : this.mappingClassificationAreas;
            this.packageSnapshot = !!params.packageSnapshot
                ? new PackageSnapShotWithoutIds(params.packageSnapshot)
                : this.packageSnapshot;
            this.companyServiceSnapshot = !!params.companyServiceSnapshot
                ? new CompanyServiceWithoutIds(params.companyServiceSnapshot)
                : this.companyServiceSnapshot;
            this.serviceTypeFields = !!params.serviceTypeFields
                ? params.serviceTypeFields
                : this.serviceTypeFields;

            this.asyncImageCount = params.asyncImageCount || this.asyncImageCount;
            this.syncedImageCount = params.syncedImageCount || this.syncedImageCount;
            this.contractOwnerIdentity = params.contractOwnerIdentity || this.contractOwnerIdentity;
            this.createdBy = params.createdBy || this.createdBy;
        }
    }
}
