import { Injectable } from '@angular/core';
import { HttpService, Post, Get, Put, Delete, Body, MapValue, Path, ResponseType } from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import {
    TimesheetIdentity,
    Timesheet,
    ServiceType,
    TimesheetSimple,
    BaseMediaDocumentWithUrl,
} from '../models';
import { DeviceInfo } from '@gm2/common';
import { GridParams, PaginatedList } from '@refactor/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as jsonToFormData from 'json-form-data';

@Injectable({
    providedIn: 'root'
})
export class TimesheetService extends HttpService {
    // WeakMap to prevent memory leaks
    private _imageUrlCache: WeakMap<TimesheetSimple, Observable<BaseMediaDocumentWithUrl[]>> = new WeakMap();

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    /**
     * Retrieves and caches the images for a given timesheet.
     *
     * It is safe to use this function within angular change detection.
     */
    public getImagesForTimesheet(item: TimesheetSimple): Observable<BaseMediaDocumentWithUrl[]> {
        let imageurls = this._imageUrlCache.get(item);
        if (imageurls) {
            return imageurls;
        }

        imageurls = this.getTimesheetImagesById(item._id);
        this._imageUrlCache.set(item, imageurls);

        return imageurls;
    }

    @Post('/timesheet/listSimple')
    @MapValue(
        res =>
            new PaginatedList<TimesheetSimple>({
                count: res.count,
                docs: res.docs.map(d => new TimesheetSimple(d))
            })
    )
    public getTimesheetList(
        @Body() params: GridParams
    ): Observable<PaginatedList<TimesheetSimple>> {
        return null;
    }

    @Post('/timesheet/listSimpleValidation')
    @MapValue(
        res =>
            new PaginatedList<TimesheetSimple>({
                count: res.count,
                docs: res.docs.map(d => new TimesheetSimple(d))
            })
    )
    public getTimesheetListValidation(
        @Body() params: GridParams
    ): Observable<PaginatedList<TimesheetSimple>> {
        return null;
    }

    @Post('/timesheet/csvExport', {
        responseType: ResponseType.Text
    })
    public timesheetCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }

    @Post('/timesheet/csvExport/validation', {
        responseType: ResponseType.Text
    })
    public timesheetValidationCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }

    public createTimesheet(
        timesheet: TimesheetIdentity,
        images: Blob[],
        accessToken?: string
    ): Observable<Object> {
        delete timesheet.images;
        delete timesheet.appId;
        delete timesheet.isComplete;

        const tsDates = timesheet.dates;
        const tsServiceTypeFields = timesheet.serviceTypeFields;

        delete timesheet.dates;
        delete timesheet.serviceTypeFields;

        const serviceTypeFieldArray: any[] = [];
        for (const serviceTypeField in tsServiceTypeFields) {
            if (
                tsServiceTypeFields.hasOwnProperty(serviceTypeField) &&
                tsServiceTypeFields[serviceTypeField]
            ) {
                serviceTypeFieldArray.push({
                    key: serviceTypeField,
                    value: tsServiceTypeFields[serviceTypeField]
                });
                // timesheetForm.append(
                //     'serviceTypeFields["' + serviceTypeField + '"]',
                //     tsServiceTypeFields[serviceTypeField]
                // );
            }
        }
        if (serviceTypeFieldArray.length > 0) {
            // TODO This is a hack and might cause issues later. Make sure to check
            // here first in case of bugs
            (timesheet as any).serviceTypeFields = serviceTypeFieldArray;
        } else {
            delete timesheet.serviceTypeFields;
        }

        // Delete material from services that don't need them
        for (const service of timesheet.services) {
            if (service.material === undefined) {
                service.material = null;
                // delete service.material;
            }
        }

        const timesheetForm = jsonToFormData(timesheet);
        timesheetForm.append('dates[timesheetStartDate]', tsDates.timesheetStartDate.toISOString());
        timesheetForm.append('dates[timesheetEndDate]', tsDates.timesheetEndDate.toISOString());
        timesheetForm.append('dates[systemStartDate]', tsDates.systemStartDate.toISOString());
        timesheetForm.append('dates[systemEndDate]', tsDates.systemEndDate.toISOString());

        // timesheetForm.append('serviceTypeFields', JSON.stringify(serviceTypeFieldArray));
        if (!!images) {
            for (let i = 0; i < images.length; i++) {
                timesheetForm.append('images[]', images[i]);
            }
        }

        // access token is supplied when mobile app needs to sync a cached timesheet
        // generated by a different user than the currently logged in user
        if (!!accessToken) {
            return this._httpClient.post(
                '/timesheet',
                timesheetForm,
                {
                    headers: new HttpHeaders({
                        'Authorization': `Bearer ${accessToken}`
                    })
                }
            );
        } else {
            return this._httpClient.post(
                '/timesheet',
                timesheetForm
            );
        }
    }

    public editTimesheet(
        timesheet: TimesheetIdentity,
        timesheetId: string,
        images: Blob[]
    ): Observable<Object> {
        delete timesheet.images;
        delete timesheet.appId;
        delete timesheet.isComplete;

        const tsDates = timesheet.dates;
        const tsServiceTypeFields = timesheet.serviceTypeFields;

        delete timesheet.dates;
        delete timesheet.serviceTypeFields;

        // const serviceTypeFieldArray: any[] = [];
        // for (const serviceTypeField in tsServiceTypeFields) {
        //     if (
        //         tsServiceTypeFields.hasOwnProperty(serviceTypeField) &&
        //         tsServiceTypeFields[serviceTypeField]
        //     ) {
        //         serviceTypeFieldArray.push({
        //             key: serviceTypeField,
        //             value: tsServiceTypeFields[serviceTypeField]
        //         });
        //         // timesheetForm.append(
        //         //     'serviceTypeFields["' + serviceTypeField + '"]',
        //         //     tsServiceTypeFields[serviceTypeField]
        //         // );
        //     }
        // }
        // if (serviceTypeFieldArray.length > 0) {
        //     // TODO This is a hack and might cause issues later. Make sure to check
        //     // here first in case of bugs
        //     (timesheet as any).serviceTypeFields = serviceTypeFieldArray;
        // } else {
        //     delete timesheet.serviceTypeFields;
        // }

        // Delete material from services that don't need them
        for (const service of timesheet.services) {
            if (service.material === undefined) {
                service.material = null;
                // delete service.material;
            }
        }

        const timesheetForm = jsonToFormData(timesheet);
        timesheetForm.append('dates[timesheetStartDate]', tsDates.timesheetStartDate.toISOString());
        timesheetForm.append('dates[timesheetEndDate]', tsDates.timesheetEndDate.toISOString());
        timesheetForm.append('dates[systemStartDate]', tsDates.systemStartDate.toISOString());
        timesheetForm.append('dates[systemEndDate]', tsDates.systemEndDate.toISOString());
        timesheetForm.append('id', timesheetId);
        timesheetForm.append('serviceTypeFields[Site_Condition]', tsServiceTypeFields.Site_Condition);
        timesheetForm.append('serviceTypeFields[Ground_Application]', tsServiceTypeFields.Ground_Application);
        timesheetForm.append('serviceTypeFields[Snow_Fall]', tsServiceTypeFields.Snow_Fall);
        timesheetForm.append('serviceTypeFields[Surface_Temperature]', tsServiceTypeFields.Surface_Temperature);
        timesheetForm.append('serviceTypeFields[Temperature]', tsServiceTypeFields.Temperature);
        timesheetForm.append('serviceTypeFields[Weather_Condition]', tsServiceTypeFields.Weather_Condition);
        if (!!images) {
            for (let i = 0; i < images.length; i++) {
                timesheetForm.append('images[]', images[i]);
            }
        }
        console.log(timesheet);
        return this._httpClient.put('/timesheet', timesheetForm);
    }

    @Get('/timesheet')
    public getTimesheets(): Observable<TimesheetIdentity[]> {
        return null;
    }

    @Get('/timesheet/:timesheetId')
    public  getTimesheetById(@Path('timesheetId') id: string): Observable<TimesheetIdentity> {
        return null;
    }

    @Get('/timesheet/:timesheetId/images')
    public getTimesheetImagesById(
        @Path('timesheetId') id: string
    ): Observable<BaseMediaDocumentWithUrl[]> {
        return null;
    }

    @Post('/timesheet/delete')
    public deleteTimesheets(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }

    @Put('/timesheet/:id/validation')
    public validateTimesheet(@Path('id') id: string, @Body() dto): Observable<any> {
        return null;
    }

    public uploadImage(
        timesheetId: string,
        image: Blob,
        accessToken?: string
    ): Observable<Object> {
        const formData = jsonToFormData({});
        formData.append('id', timesheetId);
        formData.append('file', image);
        // access token is supplied when mobile app needs to sync a cached timesheet
        // generated by a different user than the currently logged in user
        if (!!accessToken) {
            return this._httpClient.put(
                '/timesheet/imageUpload',
                formData,
                {
                    headers: new HttpHeaders({
                        'Authorization': `Bearer ${accessToken}`
                    })
                }
            );
        } else {
            return this._httpClient.put(
                '/timesheet/imageUpload',
                formData
            );
        }
    }

    @Post('/timesheet/inProgress')
    public declareTimesheetInProgress(
        @Body() dto: {
            rfpId: string;
            deviceInfo: DeviceInfo;
            date: Date;
        }
    ): Observable<void> {
        return null;
    }

    @Delete('/timesheet/inProgress/:deviceId')
    public cancelTimesheetInProgress(
        @Path('deviceId') deviceId: string
    ): Observable<void> {
        return null;
    }
}
