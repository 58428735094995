/// <reference types="googlemaps" />

import { IPoly } from './IPoly';
import { IOverlay } from './IOverlay';
import { LatLngLiteral } from '@google/maps';
import { MappingType } from '@gm2/common';
import { IIdentifiable } from '../IIdentifiable';

export class Marker implements Partial<IIdentifiable>, IPoly, IOverlay {
    public _id?: string;
    public overlay: google.maps.Marker;
    public type: MappingType;

    public constructor(overlay: google.maps.Marker, _id?: string) {
        this.overlay = overlay;
        this.type = MappingType.Point;
        this._id = _id;
    }

    public get area(): number {
        return 0;
    }

    public get path(): LatLngLiteral[] {
        return [this.overlay.getPosition().toJSON()];
    }

    public set path(value: LatLngLiteral[]) {
        this.overlay.setPosition(value[0]);
    }
}
