import { Injectable } from '@angular/core';
import {
    HttpService,
    Post,
    Get,
    Put,
    MapClass,
    Body,
    Path,
    Patch,
    MapValue,
    Query,
    ResponseType,
} from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { PaginatedList, GridParams } from '@refactor/common';
import { Invoice } from '../models/invoice/Invoice';
import { TimesheetSimple } from '../models/timesheet/timesheet-simple';
import { InvoiceType, InvoiceStatus } from '../enums/index';
import { CreateMonthlyInvoiceDto, IInvoiceRejectDto } from '../models';

interface InvoiceCreateDto {
    type: InvoiceType;
}

interface InvoiceSaveDto {
    id: string;
    invoiceNumber: string;
    timesheetIds: string[];
    landscaperId?: string;
}

@Injectable({
    providedIn: 'root',
})
export class InvoiceService extends HttpService {
    @Get('/invoice/:invoiceId')
    @MapClass(Invoice)
    public getInvoice(@Path('invoiceId') id: string): Observable<Invoice> {
        return null;
    }

    @Get('/invoice/:id/timesheets')
    @MapValue((res: any[]) => res.map(d => new TimesheetSimple(d)))
    public getInvoiceTimesheets(@Path('id') id: string): Observable<TimesheetSimple[]> {
        return null;
    }

    @Post('/invoice/list')
    @MapValue(
        res =>
            new PaginatedList<Invoice>({
                count: res.count,
                docs: res.docs.map(d => new Invoice(d)),
            }),
    )
    public getInvoiceList(@Body() params: GridParams): Observable<PaginatedList<Invoice>> {
        return null;
    }

    @Get('/invoice/timesheets/available')
    public getTimesheetsAvailable(
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string,
        @Query('isMonthly') isMonthly: boolean,
    ): Observable<TimesheetSimple[]> {
        return null;
    }

    @Post('/invoice/create')
    public createInvoice(@Body() createDto: InvoiceCreateDto): Observable<Invoice> {
        return null;
    }

    @Post('/invoice/create/monthly')
    public createInvoiceMonthly(@Body() createDto: CreateMonthlyInvoiceDto): Observable<string[]> {
        return null;
    }

    @Put('/invoice/save')
    public saveInvoice(@Body() saveDto: InvoiceSaveDto): Observable<Invoice> {
        return null;
    }

    @Patch('/invoice/:id/submit')
    public submitInvoice(@Path('id') id: string): Observable<any> {
        return null;
    }

    @Put('/invoice/delete')
    public deleteInvoices(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }

    @Post('/invoice/:invoiceId/approvalstatus')
    public updateApprovalStatus(
        @Path('invoiceId') id: string,
        @Body('status') status: InvoiceStatus,
    ): Observable<Invoice> {
        return null;
    }

    @Get('/invoice/:id/export', {
        responseType: ResponseType.Text,
    })
    public invoiceTimesheetCsvExport(@Path('id') invoiceId: string): Observable<string> {
        return null;
    }

    @Post('/invoice/csvExport', {
        responseType: ResponseType.Text,
    })
    public invoiceCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }

    @Post('/invoice/:id/reject')
    public rejectInvoice(
        @Path('id') id: string,
        @Body() body: IInvoiceRejectDto,
    ): Observable<void> {
        return null;
    }
}
