import { User } from './User';
import { CompanyIdentityWithoutIds } from '@gm2/common';

export class UserCreateDto {
    public userId: string;
    public email: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public phone: string = '';
    public timezone: string = 'America/Denver';
    public roles: string[] = [];
    public companyId: string = '';
    public companyIdentity: CompanyIdentityWithoutIds;
    public crewName: string = '';
    public crewSize: number = 0;
    public shouldSendWelcome: boolean = false;
    public password: string = '';

    constructor(params?: Partial<UserCreateDto>) {
        if (!!params) {
            this.email = params.email || this.email;
            this.firstName = params.firstName || this.firstName;
            this.lastName = params.lastName || this.lastName;
            this.phone = params.phone || this.phone;
            this.timezone = params.timezone || this.timezone;
            this.roles = params.roles || this.roles;
            this.companyId = params.companyId || this.companyId;
            this.companyIdentity = params.companyIdentity || this.companyIdentity;
            this.crewName = params.crewName || this.crewName;
            this.crewSize = params.crewSize || this.crewSize;
            this.shouldSendWelcome = !!params.shouldSendWelcome;
            this.password = params.password || this.password;
        }
    }

    /**
     * Construct a new instance of this dto from an instance of another class
     */
    public static from<T extends User>(
        type: { new (params: Partial<T>): T },
        value: T
    ): UserCreateDto {
        if (type === User) {
            return new UserCreateDto({
                email: value?.profile?.email,
                firstName: value?.profile?.firstName,
                lastName: value?.profile?.lastName,
                phone: value?.profile?.phone,
                timezone: value?.profile?.timezone,
                roles: value?.roleIds,
                companyId: value?.companyId,
                companyIdentity: value?.companyIdentity,
                crewName: value?.crewName,
                crewSize: value?.crewSize,
            });
        }

        return new UserCreateDto();
    }
}
