import { WorkOrderStatus } from './work-order-status';

export enum WorkOrderStatusFilter {
    // Note this is not an actual status that lives on the WO.
    // It is used for filtering the workorder list
    Template = 'template',
    ClientRequested = 'clientRequested',
    ServicePartnerRequested = 'servicePartnerRequested',
    Created = 'new',
    Review = 'review',
    Approved = 'approved',
    ServicePartnerRejected = 'servicePartnerRejected',
    ClientRejected = 'ClientRejected',
    Rejected = 'rejected',
    Completed = 'completed',
    Posted = 'posted',
    PaymentProcessed = 'paymentProcessed'
}

export namespace WorkOrderStatusFilter {
    export function toWorkOrderStatus(type: WorkOrderStatusFilter): string {
        switch (type) {
            case WorkOrderStatusFilter.Template:
                return WorkOrderStatus.Template;
            case WorkOrderStatusFilter.ClientRequested:
                return WorkOrderStatus.ClientRequested;
            case WorkOrderStatusFilter.ServicePartnerRequested:
                return WorkOrderStatus.ServicePartnerRequested;
            case WorkOrderStatusFilter.Created:
                return WorkOrderStatus.Created;
            case WorkOrderStatusFilter.Review:
                return WorkOrderStatus.Review;
            case WorkOrderStatusFilter.Approved:
                return WorkOrderStatus.Approved;
            case WorkOrderStatusFilter.ServicePartnerRejected:
                return WorkOrderStatus.ServicePartnerRejected;
            case WorkOrderStatusFilter.ClientRejected:
                return WorkOrderStatus.ClientRejected;
            case WorkOrderStatusFilter.Rejected:
                return WorkOrderStatus.Rejected;
            case WorkOrderStatusFilter.Completed:
                return WorkOrderStatus.Completed;
            case WorkOrderStatusFilter.Posted:
                return WorkOrderStatus.Posted;
            case WorkOrderStatusFilter.PaymentProcessed:
                return WorkOrderStatus.PaymentProcessed;
            default:
                return '';
        }
    }

    export function toString(type: WorkOrderStatusFilter): string {
        switch (type) {
            case WorkOrderStatusFilter.Template:
                return 'Template';
            case WorkOrderStatusFilter.ClientRequested:
                return 'Client Requested';
            case WorkOrderStatusFilter.ServicePartnerRequested:
                return 'Service Partner Requested';
            case WorkOrderStatusFilter.Created:
                return 'New';
            case WorkOrderStatusFilter.Review:
                return 'Review';
            case WorkOrderStatusFilter.Approved:
                return 'Approved';
            case WorkOrderStatusFilter.ServicePartnerRejected:
                return 'Service Partner Declined';
            case WorkOrderStatusFilter.ClientRejected:
                return 'Client Declined';
            case WorkOrderStatusFilter.Rejected:
                return 'Declined';
            case WorkOrderStatusFilter.Completed:
                return 'Completed';
            case WorkOrderStatusFilter.Posted:
                return 'Approved for Invoicing';
            case WorkOrderStatusFilter.PaymentProcessed:
                return 'Invoiced';
            default:
                return '';
        }
    }

    export const members: WorkOrderStatusFilter[] = [
        WorkOrderStatusFilter.Template,
        WorkOrderStatusFilter.ClientRequested,
        WorkOrderStatusFilter.ServicePartnerRequested,
        WorkOrderStatusFilter.Created,
        WorkOrderStatusFilter.Review,
        WorkOrderStatusFilter.Approved,
        WorkOrderStatusFilter.ServicePartnerRejected,
        WorkOrderStatusFilter.ClientRejected,
        WorkOrderStatusFilter.Rejected,
        WorkOrderStatusFilter.Completed,
        WorkOrderStatusFilter.Posted,
        WorkOrderStatusFilter.PaymentProcessed
    ];
}
