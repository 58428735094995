import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ContentChildren,
    QueryList,
    Input
} from '@angular/core';
import { RfxGridDataSource } from '../GridDataSource';
import { Subscription } from 'rxjs';
import { GridActionBarControlComponent } from '../grid-action-bar-control/grid-action-bar-control.component';
import { RfxGridAction } from '../GridAction';

@Component({
    selector: 'rfx-grid-action-bar',
    templateUrl: './grid-action-bar.component.html',
    styleUrls: ['./grid-action-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridActionBarComponent<T> implements OnInit {
    @ContentChildren(GridActionBarControlComponent)
    public gridControls: QueryList<RfxGridAction<T>>;

    @Input()
    public icon: string | [string, string] = ['far', 'times'];

    private _selectionWatcher: Subscription;

    public dataSource: RfxGridDataSource<T>;

    constructor(private _cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (!!this.dataSource._selection) {
            this._selectionWatcher = this.dataSource._selection.changed.subscribe(
                () => {
                    this._cd.markForCheck();
                }
            );
        }
    }

    ngAfterViewInit(): void {
        if (!!this.dataSource) {
            this.gridControls.forEach(control => {
                control.setSelection(this.dataSource._selection);
            });
        }
    }

    ngOnDestroy(): void {
        if (!!this._selectionWatcher) {
            this._selectionWatcher.unsubscribe();
        }
    }

    public dismiss(): void {
        if (!!this.dataSource._selection) {
            this.dataSource._selection.clear();
        }
    }

    public get itemCount(): number {
        return !!this.dataSource._selection
            ? this.dataSource._selection.selected.length
            : 0;
    }
}
