import { Injectable } from '@angular/core';
import {
    HttpService,
    Post,
    Get,
    Delete,
    FakeResponse,
    MapClass,
    Body,
    Path,
    MapValue,
    Query,
    ResponseType
} from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { Labor } from '@gm2/ui-common';
import {
    Material,
    MaterialApproveDto,
    Service,
    ServiceApproveDto,
    CompanyServiceDto,
    AdminCompanyMaterial,
    AdminCompanyMaterialGridFilters,
    AdminCompanyService,
    AdminCompanyServiceGridFilters,
    CompanyServiceItem
} from '../models';
import { PaginatedList, GridParams } from '@refactor/common';
import { MaterialActiveIngredient } from '@gm2/common';
import { ClientCompanyService } from '@gm2/api-common';

@Injectable({
    providedIn: 'root'
})
export class CompanyPriceService extends HttpService {
    // Services
    @Get('/companyPrice/service/list')
    public getServiceList(): Observable<CompanyServiceItem[]> {
        return null;
    }

    @Post('/companyPrice/service/adminList/company/:id')
    public getServiceListByCompanyId(@Path('id') id: string): Observable<CompanyServiceDto[]> {
        return null;
    }

    @Post('/companyPrice/service/approveOrDecline')
    public approveOrDeclineService(@Body() dto: ServiceApproveDto): Observable<void> {
        return null;
    }

    @Post('/companyPrice/service/adminList')
    @MapValue(
        res =>
            new PaginatedList<AdminCompanyService>({
                count: res.count,
                docs: res.docs.map(d => new AdminCompanyService(d))
            })
    )
    public getAdminServiceList(
        @Body() params: AdminCompanyServiceGridFilters
    ): Observable<PaginatedList<AdminCompanyService>> {
        return null;
    }

    @Post('/companyPrice/service')
    public createService(@Body() dto: CompanyServiceItem): Observable<void> {
        return null;
    }

    @Delete('/companyPrice/service/')
    public deleteService(@Query('id') id: string): Observable<void> {
        return null;
    }

    @Post('/companyPrice/service/csvExport', {
        responseType: ResponseType.Text
    })
    public companyServiceCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }

    // Materials
    @Get('/companyPrice/material/approved')
    public getApprovedMaterialList(): Observable<Material[]> {
        return null;
    }

    // Materials
    @Get('/companyPrice/material/approved/:id')
    public getApprovedMaterialListByCompanyId(@Path('id') id: string): Observable<Material[]> {
        return null;
    }

    @Get('/companyPrice/material/list')
    public getMaterialList(): Observable<Material[]> {
        console.log('Entering the function to get the material list');
        return null;
    }

    @Post('/companyPrice/material/approveOrDecline')
    public approveOrDeclineMaterial(@Body() dto: MaterialApproveDto): Observable<void> {
        return null;
    }

    @Post('/companyPrice/material/adminList')
    @MapValue(
        res =>
            new PaginatedList<AdminCompanyMaterial>({
                count: res.count,
                docs: res.docs.map(d => new AdminCompanyMaterial(d))
            })
    )
    public getAdminMaterialList(
        @Body() params: AdminCompanyMaterialGridFilters
    ): Observable<PaginatedList<AdminCompanyMaterial>> {
        return null;
    }

    @Post('/companyPrice/material')
    public createMaterial(@Body() dto: Material): Observable<void> {
        return null;
    }

    @Delete('/companyPrice/material/')
    public deleteMaterial(@Query('id') id: string): Observable<void> {
        return null;
    }

    @Post('/companyPrice/material/csvExport', {
        responseType: ResponseType.Text
    })
    public companyMaterialCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }

    // TODO: THE ENDPOINT FOR THIS DOESN'T EXIST YET
    // SETTING UP THE FRONTEND FOR IT ANYWAYS
    @Post('/companyPrice/activeIngredient')
    public saveActiveIngredient(@Body() dto: MaterialActiveIngredient): Observable<void> {
        return null;
    }

    // Labor
    @Get('/companyPrice/labor/list')
    public getCompanyLabors(@Query('id') id: string): Observable<Labor[]> {
        return null;
    }

    // Client Company Service
    @Get('/companyPrice/clientCompanyService/list')
    public getCompanyClientServices(@Query('id') id: string): Observable<ClientCompanyService[]> {
        return null;
    }

    // needs the dto?
    @Post('/companyPrice/labor/')
    public createLabor(@Body() dto: any): Observable<void> {
        return null;
    }

    @Post('/companyPrice/clientCompanyService/bulk')
    public createClientServices(@Body() dto: any): Observable<void> {
        return null;
    }

    @Post('/companyPrice/clientCompanyService/')
    public createClientService(@Body() dto: any): Observable<void> {
        return null;
    }

    @Delete('/companyPrice/labor/')
    public deleteLabor(
        @Query('id') id: string,
        @Query('companyId') companyId: string
    ): Observable<void> {
        return null;
    }

    @Delete('/companyPrice/clientCompanyService/')
    public deleteClientService(
        @Query('id') id: string,
        @Query('companyId') companyId: string
    ): Observable<void> {
        return null;
    }

    @Post('/companyPrice/clientCompanyService/edit')
    public editClientService(@Body() dto: any): Observable<void> {
        return null;
    }
}
