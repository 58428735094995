export enum QuestionnaireDocumentType {
    LicenseOrCert = '[QuestionnaireDocumentType] licenseOrCert',
    WorkersCompInsurance = '[QuestionnaireDocumentType] workersCompInsurance',
    GeneralLiabilityInsurance = '[QuestionnaireDocumentType] generalLiabilityInsurance',
    VehicleInsurance = '[QuestionnaireDocumentType] vehicleInsurance',
    UmbrellaInsurance = '[QuestionnaireDocumentType] umbrellaInsurance'
}

export namespace QuestionnaireDocumentType {
    export function toString(type: QuestionnaireDocumentType): string {
        switch (type) {
            case QuestionnaireDocumentType.LicenseOrCert:
                return 'License or Certification';
            case QuestionnaireDocumentType.WorkersCompInsurance:
                return 'Workers Compensation Insurance';
            case QuestionnaireDocumentType.GeneralLiabilityInsurance:
                return 'Genereal Liability Insurance';
            case QuestionnaireDocumentType.VehicleInsurance:
                return 'Vehicle Insurance';
            case QuestionnaireDocumentType.UmbrellaInsurance:
                return 'Umbrella Liability Insurance';
            default:
                return '';
        }
    }

    export function toQuestionnaireKey(type: QuestionnaireDocumentType): string {
        switch (type) {
            case QuestionnaireDocumentType.LicenseOrCert:
                return 'licensesOrCerts';
            case QuestionnaireDocumentType.WorkersCompInsurance:
                return 'workersCompInsuranceExpiration';
            case QuestionnaireDocumentType.GeneralLiabilityInsurance:
                return 'generalLiabilityInsuranceExpiration';
            case QuestionnaireDocumentType.VehicleInsurance:
                return 'vehicleInsuranceExpiration';
            case QuestionnaireDocumentType.UmbrellaInsurance:
                return 'umbrellaInsuranceExpiration';
            default:
                return '';
        }
    }

    export const members: QuestionnaireDocumentType[] = [
        QuestionnaireDocumentType.LicenseOrCert,
        QuestionnaireDocumentType.WorkersCompInsurance,
        QuestionnaireDocumentType.GeneralLiabilityInsurance,
        QuestionnaireDocumentType.VehicleInsurance,
        QuestionnaireDocumentType.UmbrellaInsurance
    ];
}
