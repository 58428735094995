import { ServiceClassification } from '../../enums';

export class ServiceSettings {
    public multipleChildSelections: boolean = false;
    public parentControl: boolean = false;
    public serviceClassification: ServiceClassification;
    public hasClientFee: boolean = false;
    public hasMaterial: boolean = false;

    constructor(params?: Partial<ServiceSettings>) {
        if (!!params) {
            this.multipleChildSelections =
                params.multipleChildSelections || this.multipleChildSelections;
            this.parentControl = params.parentControl || this.parentControl;
            this.serviceClassification = params.serviceClassification || this.serviceClassification;
            this.hasClientFee = params.hasClientFee || this.hasClientFee;
            this.hasMaterial = params.hasMaterial || this.hasMaterial;
        }
    }
}
