<div class="selection-box" *ngIf="!!selection">
    <mat-checkbox
        class="selection-box__checkbox"
        color="primary"
        [class.selection-box__checkbox--checked]="checked"
        [checked]="checked"
        (change)="toggleSelection(item)"
        (click)="onClick($event)"
    ></mat-checkbox>
</div>
<div class="item-container" [class.item-container--selected]="checked">
    <ng-container rfxGridListItemOutlet></ng-container>
</div>
