import { WorkOrderHistoryWithoutIds } from '@gm2/common';

export class WorkOrderHistory extends WorkOrderHistoryWithoutIds {
    public _id: string = '';

    constructor(params?: Partial<WorkOrderHistory>){
        super(params);
        if(!!params){
            this._id = params._id || this._id;
        }
    }
}