import { Injectable } from '@angular/core';
import {
    HttpService,
    Get,
    Patch,
    Body,
    Path,
    Post,
} from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { ExportQuickbooksDto } from '../models/quickbooks/export-quickbooks';

@Injectable({
  providedIn: 'root'
})
export class QuickbooksService extends HttpService {
    @Get('/quickbooks/auth')
    public getQuickbooksAuthLink(): Observable<string> {
        return null;
    }

    @Get('/quickbooks/checkToken/:companyId')
    public checkAccessToken(@Path('companyId') id: string): Observable<boolean> {
        return null;
    }

    @Get('/quickbooks/byCompany/:companyId')
    public getByCompanyId(@Path('companyId') id: string): Observable<any[]> {
        return null;
    }

    @Get('/quickbooks/accounts')
    public getQBAccounts(): Observable<string[]> {
        return null;
    }

    @Patch('/quickbooks/revoke/:companyId')
    public revoke(@Path('companyId') id: string): Observable<void> {
        return null;
    }

    @Post('/quickbooks/export')
    public exportToQuickbooks(@Body() data: any): Observable<any> {
        return null;
    }

    @Post('/quickbooks/wo-mapping')
    public woCategoryMapping(@Body() data: any): Observable<any> {
        return null;
    }

    @Post('/quickbooks/mapping')
    public categoryMapping(@Body() data: any): Observable<any> {
        return null;
    }

    @Post('/quickbooks/classes')
    public classConfiguration(@Body() data: any): Observable<any> {
        return null;
    }

    @Patch('/quickbooks/class-switch/:companyId')
    public updateAcceptClass(
        @Path('companyId') companyId: string,
        @Body() data: { enabledClass: boolean }
    ): Observable<any> {
        return null;
    }
}
