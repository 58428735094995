export enum MappingClassification {
    Sidewalk = '[MappingClassification] Sidewalk',
    ParkingLot = '[MappingClassification] Parking Lot',
    Other = '[MappingClassification] Other'
}

export namespace MappingClassification {
    export function toString(classification: MappingClassification): string {
        switch (classification) {
            case MappingClassification.Sidewalk:
                return 'Sidewalk';
            case MappingClassification.ParkingLot:
                return 'Parking Lot';
            case MappingClassification.Other:
                return 'Other (N/A)';
            default:
                return '';
        }
    }

    export const members: MappingClassification[] = [
        MappingClassification.Sidewalk,
        MappingClassification.ParkingLot,
        MappingClassification.Other
    ];
}
