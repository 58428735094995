import { UserIdentityWithoutIds } from '../user/user-identity';
import { parseISODate } from '@refactor/common';

export class FirstApprovalWithoutIds {
    public selectedCompanyIds: any[] = [];
    public preferredCompanyId: any;
    public awardedByUserIdentity: UserIdentityWithoutIds = new UserIdentityWithoutIds();
    public awardedDate: Date = new Date();

    constructor(params?: Partial<FirstApprovalWithoutIds>) {
        if (!!params) {
            this.selectedCompanyIds = Array.isArray(params.selectedCompanyIds)
                ? params.selectedCompanyIds
                : this.selectedCompanyIds;

            this.preferredCompanyId = params.preferredCompanyId || this.preferredCompanyId;

            this.awardedByUserIdentity = !!params.awardedByUserIdentity
                ? new UserIdentityWithoutIds(params.awardedByUserIdentity)
                : this.awardedByUserIdentity;

            this.awardedDate = !!params.awardedDate
                ? parseISODate(params.awardedDate)
                : this.awardedDate;
        }
    }
}
