export class RingBuffer<T> {
    private readonly _buffer: T[];
    private _index: number = 0;
    private _length: number = 0;

    constructor(private readonly _capacity: number) {
        this._buffer = new Array(_capacity);
    }

    /**
     * Add an item to the end of the buffer
     */
    public push(item: T): void {
        const actualIndex = this._index % this._capacity;
        this._buffer[actualIndex] = item;

        this._index += 1;
        if (this._length < this._capacity) {
            this._length += 1;
        }
    }

    /**
     * Dumps the contents of the buffer into a new array and returns it
     */
    public toArray(): T[] {
        const actualIndex = this._index % this._capacity;
        const end = Math.min(this._length, this._capacity);
        const firstSection = this._buffer.slice(actualIndex, end);
        const secondSection = this._buffer.slice(0, actualIndex);

        return firstSection.concat(secondSection);
    }

    /**
     * Dumps the contents of the buffer into a new array before wiping and
     * resetting the buffer. The former contents of the buffer are returned
     */
    public flush(): T[] {
        const result = this.toArray();

        for (let i = 0; i < this._capacity; i += 1) {
            delete this._buffer[i];
        }

        this._length = 0;
        this._index = 0;

        return result;
    }
}
