export enum AppDefinedField {
    CrlFields = '[AppDefinedField] crlFields'
}

export namespace AppDefinedField {
    export function toString(field: AppDefinedField): string {
        switch (field) {
            case AppDefinedField.CrlFields:
                return 'Location Id';
            default:
                return '';
        }
    }

    export const members: AppDefinedField[] = [
        AppDefinedField.CrlFields,
    ];
}
