import { IBillingTypeChargeStrategy } from '../interfaces/i-billing-type-charge-strategy';
import { calculateClientMaterialsTotal } from '../util/methods';
import { TimesheetWithoutIds, MaterialWithoutIds } from '@gm2/common';

export class OnegmMonthlyCharges implements IBillingTypeChargeStrategy {
    public calculateCompanyCharges(
        timesheet: TimesheetWithoutIds,
        approvedMaterialDict: { [key: string]: MaterialWithoutIds }
    ): { total: number; duration: number; errors: string[] } {
        return null;
    }

    public calculateClientCharges(
        timesheet: TimesheetWithoutIds,
        companyTotalDuration: number
    ): { total: number; duration: number; errors: string[] } {
        let clientTotal = 0;
        if (!!timesheet.snapShot.rfp.clientPricing) {
            clientTotal = timesheet.snapShot.rfp.clientPricing.monthlyClientPrice;
        }

        const materialTotal = calculateClientMaterialsTotal(timesheet);
        const totalDuration = this.getTotalDuration(timesheet);

        return {
            duration: totalDuration,
            total: clientTotal + materialTotal,
            errors: []
        };
    }

    public getTotalDuration(timesheet: TimesheetWithoutIds): number {
        let totalDuration: number = 0;
        for (const service of timesheet.services) {
            totalDuration += service.durationMinutes;
        }

        return totalDuration;
    }
}
