import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'gm2-geospatial-control-modal',
    templateUrl: './geospatial-control-modal.component.html',
    styleUrls: ['./geospatial-control-modal.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeospatialControlModalComponent {


    public form: UntypedFormGroup = this._fb.group({
        geospatialService: [this.data?.geospatialServiceStatus ?? false, Validators.required]
    });


    constructor(
        private _fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<GeospatialControlModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { geospatialServiceStatus: boolean, companyName: string }
    ) {
    }

    public close(): void {
        this.dialogRef.close(false);
    }

    public confirm(): void {
        this.dialogRef.close(this.form.value);
    }
}
