export enum WorkOrderEquipmentUnit {
    Hourly = '[WorkOrderEquipmentUnit] Hourly',
    Daily = '[WorkOrderEquipmentUnit] Daily',
    Weekly = '[WorkOrderEquipmentUnit] Weekly'
}

export namespace WorkOrderEquipmentUnit {
    export function toString(type: WorkOrderEquipmentUnit): string {
        switch (type) {
            case WorkOrderEquipmentUnit.Hourly:
                return 'Hourly';
            case WorkOrderEquipmentUnit.Daily:
                return 'Daily';
            case WorkOrderEquipmentUnit.Weekly:
                return 'Weekly';
            default:
                return '';
        }
    }

    export const members: WorkOrderEquipmentUnit[] = [
        WorkOrderEquipmentUnit.Hourly,
        WorkOrderEquipmentUnit.Daily,
        WorkOrderEquipmentUnit.Weekly
    ];
}
