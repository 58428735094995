import { BaseDoc } from '../base/base-doc';
import { ServiceClassification, ServiceTimesheetField } from '../../enums';
import { BaseMediaDocumentWithoutIds } from '../media/base-media-document';
import { MappingCategoryWithoutIds } from '../mapping/mapping-category';
import { isNull } from '../../utils';

export class ServiceTypeWithoutIds extends BaseDoc {
    public _id: any;
    public name: string;
    public classifications: ServiceClassification[] = [];
    public image: BaseMediaDocumentWithoutIds = null;
    public mappingCategories: MappingCategoryWithoutIds[];
    public verifyTimesheets: boolean = false;
    public timesheetFields: ServiceTimesheetField[] = [];
    public seasonStartMonth: number;
    public seasonEndMonth: number;
    public excessiveMaterialComment: boolean = false;
    public mappingTolerance: boolean = false;

    constructor(params?: Partial<ServiceTypeWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.image = !!params.image ? new BaseMediaDocumentWithoutIds(params.image) : this.image;

            this.verifyTimesheets = params.verifyTimesheets || this.verifyTimesheets;
            this.seasonStartMonth = !isNull(params.seasonStartMonth)
                ? params.seasonStartMonth
                : this.seasonStartMonth;
            this.seasonEndMonth = !isNull(params.seasonEndMonth)
                ? params.seasonEndMonth
                : this.seasonEndMonth;
            this.excessiveMaterialComment =
                params.excessiveMaterialComment || this.excessiveMaterialComment;
            this.mappingTolerance = params.mappingTolerance || this.mappingTolerance;

            this.classifications = Array.isArray(params.classifications)
                ? params.classifications
                : this.classifications;
            this.mappingCategories = Array.isArray(params.mappingCategories)
                ? params.mappingCategories.map(x => new MappingCategoryWithoutIds(x))
                : this.mappingCategories;
            this.timesheetFields = Array.isArray(params.timesheetFields)
                ? params.timesheetFields
                : this.timesheetFields;
        }
    }
}
