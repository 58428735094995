import { LocationMappingApprovalWithoutIds } from './location-mapping-approval';
import { MappingClassificationArea } from '../mapping/mapping-classification-area';

export class LocationMapping {
    public adminApproved: boolean = false;
    public clientApproved: boolean = false;
    public mapCount: number = 0;
    public approvalHistory: LocationMappingApprovalWithoutIds[] = [];
    public calculatedAreas: MappingClassificationArea[] = [];

    constructor(params?: Partial<LocationMapping>) {
        if (!!params) {
            this.adminApproved = params.adminApproved || this.adminApproved;
            this.clientApproved = params.clientApproved || this.clientApproved;
            this.mapCount = params.mapCount || this.mapCount;
            this.approvalHistory = Array.isArray(params.approvalHistory)
                ? params.approvalHistory.map(a => new LocationMappingApprovalWithoutIds(a))
                : this.approvalHistory;
            this.calculatedAreas = Array.isArray(params.calculatedAreas)
                ? params.calculatedAreas.map(area => new MappingClassificationArea(area))
                : this.calculatedAreas;
        }
    }
}
