import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { RfxHelp } from '@refactor/common';

@Component({
  selector: 'rfx-help-modal',
  templateUrl: './rfx-help-modal.component.html',
  styleUrls: ['./rfx-help-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RfxHelpModalComponent implements OnInit {

    public help$: BehaviorSubject<RfxHelp> =
        new BehaviorSubject(null);
    public videoUrls$: BehaviorSubject<SafeResourceUrl[]> =
        new BehaviorSubject([]);

    private readonly _baseYouTubeUrl: string = 'https://www.youtube.com/embed/';

    constructor(
        private readonly _sanitizer: DomSanitizer,
        private readonly _dialogRef: MatDialogRef<RfxHelpModalComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly _data: any
    ) {
        const help: RfxHelp = this._data['rfxHelp'];
        const videoUrls: SafeResourceUrl[] = [];
        for (const id of help.videoIds) {
            const url = this._sanitizer
                .bypassSecurityTrustResourceUrl(
                    this._baseYouTubeUrl + id
                );
            videoUrls.push(url);
        }
        this.help$.next(help);
        this.videoUrls$.next(videoUrls);
    }

    ngOnInit(): void {
    }

    public close(): void {
        this._dialogRef.close();
    }
}
