// Pulled from old site
// TODO Add minute offsets
export const timezones = [
    { key: 'America/New_York', name: 'Eastern Time' },
    { key: 'America/Chicago', name: 'Central Time' },
    { key: 'America/Denver', name: 'Mountain Time' },
    { key: 'America/Phoenix', name: 'Mountain Time (no DST)' },
    { key: 'America/Los_Angeles', name: 'Pacific Time' },
    { key: 'America/Anchorage', name: 'Alaska Time' },
    { key: 'America/Adak', name: 'Hawaii-Aleutian Time' },
    { key: 'America/Honolulu', name: 'Hawaii-Aleutian Time (no DST)' },
];
