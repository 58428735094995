
export enum ServiceActivityTimeFrame {
    Hour1 = '[ServiceActivityTimeFrame] 1 Hour',
    Hours2 = '[ServiceActivityTimeFrame] 2 Hours',
    Hours4 = '[ServiceActivityTimeFrame] 4 Hours',
    Hours6 = '[ServiceActivityTimeFrame] 6 Hours',
    Hours8 = '[ServiceActivityTimeFrame] 8 Hours',
    Hours12 = '[ServiceActivityTimeFrame] 12 Hours',
    Hours24 = '[ServiceActivityTimeFrame] 24 Hours',
    Hours48 = '[ServiceActivityTimeFrame] 48 Hours',
    Days7 = '[ServiceActivityTimeFrame] 7 Days',
    Days14 = '[ServiceActivityTimeFrame] 14 Days',
    Days30 = '[ServiceActivityTimeFrame] 30 Days',
    Days60 = '[ServiceActivityTimeFrame] 60 Days',
    Days90 = '[ServiceActivityTimeFrame] 90 Days'
}

export namespace ServiceActivityTimeFrame {
    export function toString(type: ServiceActivityTimeFrame): string {
        switch (type) {
            case ServiceActivityTimeFrame.Hour1:
                return '1 Hour';
            case ServiceActivityTimeFrame.Hours2:
                return '2 Hours';
            case ServiceActivityTimeFrame.Hours4:
                return '4 Hours';
            case ServiceActivityTimeFrame.Hours6:
                return '6 Hours';
            case ServiceActivityTimeFrame.Hours8:
                return '8 Hours';
            case ServiceActivityTimeFrame.Hours12:
                return '12 Hours';
            case ServiceActivityTimeFrame.Hours24:
                return '24 Hours';
            case ServiceActivityTimeFrame.Hours48:
                return '48 Hours';
            case ServiceActivityTimeFrame.Days7:
                return '7 Days';
            case ServiceActivityTimeFrame.Days14:
                return '14 Days';
            case ServiceActivityTimeFrame.Days30:
                return '30 Days';
            case ServiceActivityTimeFrame.Days60:
                return '60 Days';
            case ServiceActivityTimeFrame.Days90:
                return '90 Days';
            default:
                return '';
        }
    }

    // tslint:disable:no-magic-numbers
    export function toHours(type: ServiceActivityTimeFrame): number {
        switch (type) {
            case ServiceActivityTimeFrame.Hour1:
                return 1;
            case ServiceActivityTimeFrame.Hours2:
                return 2;
            case ServiceActivityTimeFrame.Hours4:
                return 4;
            case ServiceActivityTimeFrame.Hours6:
                return 6;
            case ServiceActivityTimeFrame.Hours8:
                return 8;
            case ServiceActivityTimeFrame.Hours12:
                return 12;
            case ServiceActivityTimeFrame.Hours24:
                return 24;
            case ServiceActivityTimeFrame.Hours48:
                return 48;
            case ServiceActivityTimeFrame.Days7:
                return 168;
            case ServiceActivityTimeFrame.Days14:
                return 336;
            case ServiceActivityTimeFrame.Days30:
                return 720;
            case ServiceActivityTimeFrame.Days60:
                return 1440;
            case ServiceActivityTimeFrame.Days90:
                return 2160;
            default:
                return 0;
        }
    }

    export const members: ServiceActivityTimeFrame[] = [
        ServiceActivityTimeFrame.Hour1,
        ServiceActivityTimeFrame.Hours2,
        ServiceActivityTimeFrame.Hours4,
        ServiceActivityTimeFrame.Hours6,
        ServiceActivityTimeFrame.Hours8,
        ServiceActivityTimeFrame.Hours12,
        ServiceActivityTimeFrame.Hours24,
        ServiceActivityTimeFrame.Hours48,
        ServiceActivityTimeFrame.Days7,
        ServiceActivityTimeFrame.Days14,
        ServiceActivityTimeFrame.Days30,
        ServiceActivityTimeFrame.Days60,
        ServiceActivityTimeFrame.Days90
    ];
}
