export enum MeasurementType {
    Pounds = '[MeasurementType] Pounds',
    Gallons = '[MeasurementType] Gallons',
    Yards = '[MeasurementType] Yards',
    Tons = '[MeasurementType] Tons',
    PerItem = '[MeasurementType] PerItem',
}

export namespace MeasurementType {
    export function toString(type: MeasurementType): string {
        switch (type) {
            case MeasurementType.Pounds:
                return 'Pounds';
            case MeasurementType.Gallons:
                return 'Gallons';
            case MeasurementType.Yards:
                return 'Yards';
            case MeasurementType.Tons:
                return 'Tons';
            case MeasurementType.PerItem:
                return 'Per Item';
            default:
                return '';
        }
    }

    export const members: MeasurementType[] = [
        MeasurementType.Pounds,
        MeasurementType.Gallons,
        MeasurementType.Yards,
        MeasurementType.Tons,
        MeasurementType.PerItem,
    ];
}
