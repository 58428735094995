import { WorkOrderActiveStatus } from './work-order-active-status';

export enum WorkOrderActiveStatusFilter {
    // Note this is not an actual status that lives on the WO.
    // It is used for filtering the workorder list
    Active = 'active',
    Hold = 'hold',
    Inactive = 'inactive'
}

export namespace WorkOrderActiveStatusFilter {
    export function toWorkOrderActiveStatus(type: WorkOrderActiveStatusFilter): string {
        switch (type) {
            case WorkOrderActiveStatusFilter.Active:
                return WorkOrderActiveStatus.Active;
            case WorkOrderActiveStatusFilter.Hold:
                return WorkOrderActiveStatus.Hold;
            case WorkOrderActiveStatusFilter.Inactive:
                return WorkOrderActiveStatus.Inactive;
            default:
                return '';
        }
    }
    export function toString(type: WorkOrderActiveStatusFilter): string {
        switch (type) {
            case WorkOrderActiveStatusFilter.Active:
                return 'Active';
            case WorkOrderActiveStatusFilter.Hold:
                return 'Hold';
            case WorkOrderActiveStatusFilter.Inactive:
                return 'Inactive';
            default:
                return '';
        }
    }

    export const members: WorkOrderActiveStatusFilter[] = [
        WorkOrderActiveStatusFilter.Active,
        WorkOrderActiveStatusFilter.Hold,
        WorkOrderActiveStatusFilter.Inactive
    ];
}
