import { isNull } from '../../utils';

export enum AllServiceType {
    Landscape = '[AllServiceType] Landscape',
    Snow = '[AllServiceType] Snow',
    Irrigation = '[AllServiceType] Irrigation',
    PerVisit = '[AllServiceType] Landscape (per visit)'
}

export namespace AllServiceType {
    export function toString(type: AllServiceType): string {
        switch (type) {
            case AllServiceType.Landscape:
                return 'Landscape';
            case AllServiceType.Snow:
                return 'Snow';
            case AllServiceType.Irrigation:
                return 'Irrigation';
            case AllServiceType.PerVisit:
                return 'Landscape (per visit)';
            default:
                return '';
        }
    }

    export function toStringFromId(id: string): string {
        switch (id) {
            case '574f2c0ec8b8b8e1648b4bb6':
                return 'Landscape';
            case '5790faf0c8b8b8e1318b469b':
                return 'Form';
            case '574f3248c8b8b8c05f8b4ceb':
                return 'Snow';
            case '5f30531101c74c52fd91598b':
                return 'Irrigation';
            case '5c50d639d8a4ae2e078b4567':
                return 'Landscape (per visit)';
            case '642b436d15b7420023a4efbd':
                return 'Service Lucas edit'
            case '64340ac928296d002756ad5d':
                return 'Proxet Dev'
            case '64369d7d09c5aa00296ac6e1':
                return 'Service Type Snow'
            case '6436b4c64f425e00257132cc':
                return 'V - PL1 - Service Type'
            case '643c75f63d8ef40e8ef00358':
                return 'My 1st ST'
            case '6448487ead14ed0027f39592':
                return 'Service Name PL Apr 25 without child'
            case '64484854ad14ed0027f39590':
                return 'Service Name PL Apr 25 with child'
            case '6440750791e96d00222b4b96':
                return 'Service Name PL Apr 19'
            case '643c760d3d8ef40e8ef00359':
                return 'my 1st service'
            case '6436bb404f425e00257132e5':
                return 'Service2'
            case '6436b4da4f425e00257132cd':
                return 'V - PL1 Service1'
            default:
                return '';
        }
    }

    export const members: AllServiceType[] = [
        AllServiceType.Landscape,
        AllServiceType.Snow,
        AllServiceType.Irrigation,
        AllServiceType.PerVisit,
    ];
}
