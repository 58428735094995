import { IMappingLike } from './IMappingLike';
import { MappingType, MappingClassification } from '@gm2/common';

export class MappingCreateDto {
    public type: MappingType;
    public name: string;
    public serviceTypeId: string;
    public mappingCategoryId: string;
    public mappingClassification: MappingClassification;
    public area: number;
    public width: number;
    public notes: string;
    public points: [number, number][];

    constructor(
        public locationId: string,
        mapping: IMappingLike
    ) {
        this.type = mapping.type;
        this.name = mapping.name;
        this.serviceTypeId = mapping.serviceTypeId;
        this.mappingCategoryId = mapping.mappingCategoryId;
        this.mappingClassification = mapping.mappingClassification;
        this.area = mapping.area;
        this.width = mapping.width;
        this.notes = mapping.notes;
        this.points = mapping.path.map(point => [point.lng, point.lat]);
    }
}
