import { ApproveType, MaterialActiveIngredient, MaterialMeasurementType } from '../../enums';
import { isNull } from '../../utils';

export class MaterialWithoutIds {
    public _id: any;
    public name: string;
    public description: string;
    public price: number;
    public measurement: MaterialMeasurementType = null;
    public approveStatus: ApproveType = null;
    public activeIngredient: MaterialActiveIngredient = null;
    public approveOrRejectDate: Date;
    public rejectReason: string;

    constructor(params?: Partial<MaterialWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.description = params.description || this.description;
            this.price = !isNull(params.price) ? params.price : this.price;
            this.measurement = params.measurement || this.measurement;
            this.approveStatus = params.approveStatus || this.approveStatus;
            this.activeIngredient = params.activeIngredient || this.activeIngredient;
            this.approveOrRejectDate = params.approveOrRejectDate || this.approveOrRejectDate;
            this.rejectReason = params.rejectReason || this.rejectReason;
        }
    }
}
