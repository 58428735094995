import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { url } from '../../consts/url.const';
import { PaginationResult, PaginationParams } from '@ngx-gm2/shared/models/pagination.model';
import { GeospatialModel } from '../../geospatial/models/usage.model';

@Injectable({
    providedIn: 'root'
})
export class GeospatialService {

    constructor(
        public http: HttpClient
    ) {
    }

    public companiesGeospatialUsage(filters: GeospatialModel.CompanyUsageFilters, pagination: PaginationParams): Observable<PaginationResult<GeospatialModel.CompanyUsage>> {
        return this.http.post<any>(`${url.geospatial.usage}`, { filters, pagination });
    }
    public personalGeospatialUsage(filters: Partial<GeospatialModel.PersonalUsageFilters>): Observable<GeospatialModel.PersonalUsage> {
        return this.http.post<any>(`${url.geospatial.personalUsage}`, filters);
    }
}
