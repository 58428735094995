<input
    #fileElement
    type="file"
    [attr.name]="name"
    [attr.multiple]="multiple"
    [attr.accept]="accepts.toString()"
    hidden
/>
<div class="upload-files-btn" (click)="openFileDialog()" [class.disable]="isDisabled">
    <ng-container *ngIf="(filedisplay$ | async) as files; else noFiles">
        <ng-container *ngIf="imagepreview; else woimages">
            <ul class="file-upload-image-container" [class.multiple]="multiple === '' || multiple">
                <li *ngFor="let file of files" [matTooltip]="file.name" class="file-upload-image">
                    <img *ngIf="file.preview; else placeholder" [src]="file.preview" />
                    <ng-template #placeholder>
                        <mat-icon>attachment</mat-icon>
                    </ng-template>
                </li>
            </ul>
        </ng-container>
        <ng-template #woimages>
            <mat-list dense>
                <mat-list-item *ngFor="let file of files">
                    <mat-icon matListIcon>attachment</mat-icon>
                    {{ file.name }}
                </mat-list-item>
            </mat-list>
        </ng-template>
    </ng-container>
    <ng-template #noFiles>
        <mat-list>
            <mat-list-item>
                <mat-icon matListIcon>attach_file</mat-icon>
                Click Here to Select Files
            </mat-list-item>
        </mat-list>
    </ng-template>
</div>
<ng-container *ngIf="(filedisplay$ | async)">
    <button type="button" mat-icon-button color="warn" class="remove-btn" (click)="reset()">
        <mat-icon>remove_circle_outline</mat-icon>
    </button>
</ng-container>
