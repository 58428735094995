import { UserIdentity } from '../user/UserIdentity';
import { CompanyIdentity } from '../company/CompanyIdentity';

export class TimesheetImageDto {
    public key: string;
    public name: string;
    public createdDate: Date;
    public submitted: boolean = false;
    public notes?: string;
    public url?: string;
    public userIdentity: UserIdentity;
    public companyIdentity: CompanyIdentity;
    public offlineTimesheetId: string;
    public onlineTimesheetId?: string;

    constructor(params?: Partial<TimesheetImageDto>) {
        if (!!params) {
            this.key = params.key || this.key;
            this.name = params.name || this.name;
            this.createdDate = params.createdDate || this.createdDate;
            this.submitted = params.submitted || this.submitted;
            this.notes = params.notes || this.notes;
            this.url = params.url || this.url;
            this.userIdentity = !!params.userIdentity
                ? new UserIdentity(params.userIdentity)
                : this.userIdentity;
            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentity(params.companyIdentity)
                : this.companyIdentity;
            this.offlineTimesheetId = params.offlineTimesheetId || this.offlineTimesheetId;
            this.onlineTimesheetId = params.onlineTimesheetId || this.onlineTimesheetId;
        }
    }
}
