import { ContractAppliedWithoutIds, ServiceSource } from '@gm2/common';
import { BaseMediaDocumentWithUrl } from '../media/base-media-document-with-url';

export class ContractApplied extends ContractAppliedWithoutIds {
    public _id: string = '';
    public packageIds: string[] = [];
    public serviceTypeId: string = '';
    public documents: BaseMediaDocumentWithUrl[] = [];
    public serviceSource: ServiceSource = ServiceSource.Package;
    public isCopy: boolean = false;
    // public copyInfo: CopyInfo = new CopyInfo();

    constructor(params?: Partial<ContractApplied>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId;
            this.packageIds = Array.isArray(params.packageIds)
                ? params.packageIds
                : this.packageIds;
            this.documents = params.documents
                ? params.documents.map(d => new BaseMediaDocumentWithUrl(d))
                : this.documents;
            this.serviceSource = params.serviceSource || this.serviceSource;
            this.isCopy = params.isCopy ? params.isCopy : this.isCopy;
            // this.copyInfo = !!params.copyInfo ? new CopyInfo(params.copyInfo) : params.copyInfo;
        }
    }
}
