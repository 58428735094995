import { isNull } from '../../utils';

export enum CrewType {
    All = '[CrewType] All',
    Internal = '[CrewType] Internal',
    External = '[CrewType] External',
}

export namespace CrewType {
    export function toString(type: CrewType): string {
        switch (type) {
            case CrewType.All:
                return 'All';
            case CrewType.Internal:
                return 'Internal';
            case CrewType.External:
                return 'External';
            default:
                return '';
        }
    }

    export const members: CrewType[] = [
        CrewType.All,
        CrewType.Internal,
        CrewType.External
    ];
}
