import { getDate } from 'date-fns';

export enum AutoInvoiceDay {
    First = '[AutoInvoiceDay] 1',
    Second = '[AutoInvoiceDay] 2',
    Third = '[AutoInvoiceDay] 3',
    Fourth = '[AutoInvoiceDay] 4'
}

export namespace AutoInvoiceDay {
    export function toString(type: AutoInvoiceDay): string {
        switch (type) {
            case AutoInvoiceDay.First:
                return '1st';
            case AutoInvoiceDay.Second:
                return '2nd';
            case AutoInvoiceDay.Third:
                return '3rd';
            case AutoInvoiceDay.Fourth:
                return '4th';
            default:
                return '';
        }
    }

    export const members: AutoInvoiceDay[] = [
        AutoInvoiceDay.First,
        AutoInvoiceDay.Second,
        AutoInvoiceDay.Third,
        AutoInvoiceDay.Fourth
    ];

    export function fromDate(date: Date): AutoInvoiceDay {
        return AutoInvoiceDay.fromDayInt(getDate(date));
    }
    export function fromDayInt(date: number): AutoInvoiceDay {
        // members is zero based array
        if (date > 0 && date <= AutoInvoiceDay.members.length) {
            return AutoInvoiceDay.members[date - 1];
        }
        return null;
    }
}
