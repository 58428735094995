<form [formGroup]="form" (ngSubmit)="saveSettings(form)" *ngIf="fieldToEdit; else noFieldSelected">
    <h2>{{ RfxFormFieldType.toString(fieldToEdit.type) }} Settings</h2>
    <mat-form-field [class.hidden]="form.get('label').disabled">
        <mat-label attr.for="label">Label</mat-label>
        <input matInput formControlName="label" id="label" required />
        <mat-error [rfxError]="form.get('label')"></mat-error>
    </mat-form-field>
    <mat-form-field [class.hidden]="form.get('description').disabled">
        <mat-label attr.for="description">Description</mat-label>
        <textarea matInput formControlName="description" id="description"></textarea>
        <mat-error [rfxError]="form.get('description')"></mat-error>
    </mat-form-field>
    <div class="group">
        <mat-form-field [class.hidden]="form.get('defaultValue').disabled">
            <mat-label attr.for="defaultValue">Default Value</mat-label>
            <input matInput formControlName="defaultValue" id="defaultValue" />
            <mat-error [rfxError]="form.get('defaultValue')"></mat-error>
        </mat-form-field>
        <mat-form-field [class.hidden]="form.get('placeholder').disabled">
            <mat-label attr.for="placeholder">Placeholder</mat-label>
            <input matInput formControlName="placeholder" id="placeholder" />
            <mat-error [rfxError]="form.get('placeholder')"></mat-error>
        </mat-form-field>
    </div>

    <!-- ###### CHOICES CODE BLOCK ###### -->
    <div [class.hidden]="form.get('choices').disabled" class="choice-fields">
        <div
            class="choice"
            formArrayName="choices"
            *ngFor="let rule of choicesFormArray.controls; let i = index"
        >
            <div [formGroupName]="i" class="group">
                <mat-form-field>
                    <mat-label>Label</mat-label>
                    <input matInput formControlName="label" required />
                    <mat-error [rfxError]="choicesFormArray.at(i).get('label')"></mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Value</mat-label>
                    <input matInput formControlName="value" required />
                    <mat-error [rfxError]="choicesFormArray.at(i).get('value')"></mat-error>
                </mat-form-field>
                <button
                    mat-icon-button
                    type="button"
                    (click)="removeChoice(i)"
                    *ngIf="choicesFormArray.controls.length > 1"
                >
                    <mat-icon>remove_circle</mat-icon>
                </button>
                <button mat-icon-button type="button" (click)="addChoiceAtIndex(i)">
                    <mat-icon>add_circle</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <!-- ###### END CHOICES CODE BLOCK ###### -->

    <mat-checkbox [class.hidden]="form.get('required').disabled" formControlName="required">
        Is Required
    </mat-checkbox>

    <!-- ###### CONDITIONS CODE BLOCK ###### -->
    <!-- Don't allow conditions unless there is a field available to compare to -->
    <div [class.hidden]="(fields$ | async).length <= 1">
        <mat-checkbox
            [class.hidden]="form.get('enableConditions').disabled"
            formControlName="enableConditions"
        >
            Enable Conditions
        </mat-checkbox>
        <div
            [class.hidden]="form.get('condition').disabled || !form.get('enableConditions').value"
            [formGroup]="conditionGroup"
            class="condition-fields"
        >
            <div class="group">
                <mat-form-field class="action">
                    <mat-select formControlName="action" required>
                        <mat-option *ngFor="let opt of actionOptions" [value]="opt.value">{{
                            opt.label
                        }}</mat-option>
                    </mat-select>
                    <mat-error [rfxError]="conditionGroup.get('action')"></mat-error>
                </mat-form-field>
                if
                <mat-form-field class="rules-boolean">
                    <mat-select formControlName="rulesBoolean" required>
                        <mat-option *ngFor="let opt of ruleBooleanOptions" [value]="opt.value">{{
                            opt.label
                        }}</mat-option>
                    </mat-select>
                    <mat-error [rfxError]="conditionGroup.get('rulesBoolean')"></mat-error>
                </mat-form-field>
                of the following match:
            </div>

            <div
                class="rules"
                formArrayName="rules"
                *ngFor="let rule of rulesFormArray.controls; let i = index"
            >
                <div [formGroupName]="i" class="group rule">
                    <mat-form-field>
                        <mat-label>Field</mat-label>
                        <mat-select formControlName="fieldId" required>
                            <mat-option
                                *ngFor="let field of (comparisonFields$ | async)"
                                [value]="field._id"
                                >{{ field.label }}</mat-option
                            >
                        </mat-select>
                        <mat-error [rfxError]="rulesFormArray.at(i).get('fieldId')"></mat-error>
                    </mat-form-field>

                    <mat-form-field class="comparison-field">
                        <mat-label>Comparison</mat-label>
                        <mat-select formControlName="comparison" required>
                            <mat-option
                                *ngFor="let opt of ruleComparisonOptions"
                                [value]="opt.value"
                                >{{ opt.label }}</mat-option
                            >
                        </mat-select>
                        <mat-error [rfxError]="rulesFormArray.at(i).get('comparison')"></mat-error>
                    </mat-form-field>

                    <!-- Value fields -->
                    <mat-form-field
                        [class.hidden]="
                            rulesFormArray.at(i).get('comparisonFieldType').value ===
                            RfxFormFieldType.Checkbox
                        "
                    >
                        <mat-label>Value</mat-label>
                        <input matInput formControlName="value" required />
                        <mat-error [rfxError]="rulesFormArray.at(i).get('value')"></mat-error>
                    </mat-form-field>
                    <mat-form-field
                        [class.hidden]="
                            rulesFormArray.at(i).get('comparisonFieldType').value !==
                            RfxFormFieldType.Checkbox
                        "
                    >
                        <mat-label>Value</mat-label>
                        <mat-select formControlName="value" required>
                            <mat-option
                                *ngFor="let opt of checkboxValueOptions"
                                [value]="opt.value"
                                >{{ opt.label }}</mat-option
                            >
                        </mat-select>
                        <mat-error [rfxError]="rulesFormArray.at(i).get('value')"></mat-error>
                    </mat-form-field>

                    <button
                        mat-icon-button
                        type="button"
                        (click)="removeRule(i)"
                        *ngIf="rulesFormArray.controls.length > 1"
                    >
                        <mat-icon>remove_circle</mat-icon>
                    </button>
                    <button mat-icon-button type="button" (click)="addRuleAtIndex(i)">
                        <mat-icon>add_circle</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- ###### END CONDITIONS CODE BLOCK ###### -->

    <div class="actions">
        <button mat-stroked-button type="button" (click)="cancel.emit(fieldToEdit._id)">
            Cancel
        </button>
        <button mat-flat-button color="primary" type="submit">Save</button>
    </div>
</form>
<ng-template #noFieldSelected>
    <div class="no-field-selected">
        <strong>
            Select a field to edit its settings here
        </strong>
    </div>
</ng-template>
