import { MappingClassification } from '../mapping/mapping-classification';

export enum ServiceClassification {
    Other = '[ServiceClassification]_Other',
    Icemelt_Parking_Lot = '[ServiceClassification]_Icemelt_Parking_Lot',
    Icemelt_Sidewalk = '[ServiceClassification]_Icemelt_Sidewalk',
    Plowing = '[ServiceClassification]_Plowing',
    Shoveling = '[ServiceClassification]_Shoveling',
    Sprinklers = '[ServiceClassification]_Sprinklers',
    Lawn_Services = '[ServiceClassification]_Lawn_Services',
    Parking_Lot = '[ServiceClassification]_Parking_Lot',
    Sidewalk = '[ServiceClassification]_Sidewalk',
    Handicap_Parking = '[ServiceClassification]_Handicap_Parking',
    Curb = '[ServiceClassification]_Curb',
    Other_Landscaping_Services = '[ServiceClassification]_Other_Landscaping_Services'
}

export namespace ServiceClassification {
    export function toString(type: ServiceClassification): string {
        switch (type) {
            case ServiceClassification.Other:
                return 'Other';
            case ServiceClassification.Icemelt_Parking_Lot:
                return 'Icemelt Parking Lot';
            case ServiceClassification.Icemelt_Sidewalk:
                return 'Icemelt Sidewalk';
            case ServiceClassification.Plowing:
                return 'Plowing';
            case ServiceClassification.Shoveling:
                return 'Shoveling';
            case ServiceClassification.Sprinklers:
                return 'Sprinkler';
            case ServiceClassification.Lawn_Services:
                return 'Lawn Services';
            case ServiceClassification.Parking_Lot:
                return 'Parking Lot';
            case ServiceClassification.Sidewalk:
                return 'Sidewalk';
            case ServiceClassification.Handicap_Parking:
                return 'Handicap Parking';
            case ServiceClassification.Curb:
                return 'Curb';
            case ServiceClassification.Other_Landscaping_Services:
                return 'Other Landscaping Services';
            default:
                return '';
        }
    }

    export function toServiceClassification(name: string): ServiceClassification {
        switch (name) {
            case 'Other':
                return ServiceClassification.Other;
            case 'Icemelt Parking Lot':
                return ServiceClassification.Icemelt_Parking_Lot;
            case 'Icemelt Sidewalk':
                return ServiceClassification.Icemelt_Sidewalk;
            case 'Plowing':
                return ServiceClassification.Plowing;
            case 'Shoveling':
                return ServiceClassification.Shoveling;
            case 'Sprinkler':
                return ServiceClassification.Sprinklers;
            case 'Lawn Services':
                return ServiceClassification.Lawn_Services;
            case 'Parking Lot':
                return ServiceClassification.Parking_Lot;
            case 'Sidewalk':
                return ServiceClassification.Sidewalk;
            case 'Handicap Parking':
                return ServiceClassification.Handicap_Parking;
            case 'Curb':
                return ServiceClassification.Curb;
            case 'Other Landscaping Services':
                return ServiceClassification.Other_Landscaping_Services;
            default:
                return ServiceClassification.Other;
        }
    }

    export function toMappingClassification(type: ServiceClassification): MappingClassification {
        switch (type) {
            case ServiceClassification.Icemelt_Sidewalk:
                return MappingClassification.Sidewalk;
            case ServiceClassification.Icemelt_Parking_Lot:
                return MappingClassification.ParkingLot;
            default:
                return null;
        }
    }

    export const SnowMembers: ServiceClassification[] = [
        ServiceClassification.Icemelt_Parking_Lot,
        ServiceClassification.Icemelt_Sidewalk,
        ServiceClassification.Plowing,
        ServiceClassification.Shoveling
    ];

    export const LandscapeMembers: ServiceClassification[] = [];

    export const InspectionMembers: ServiceClassification[] = [];

    //not sure if any of these need to be in either of the two arrays above this.
    export const members: ServiceClassification[] = [
        ...SnowMembers,
        // ...LandscapeMembers,
        // ...InspectionMembers,
        ServiceClassification.Other,
        ServiceClassification.Sprinklers,
        ServiceClassification.Lawn_Services,
        ServiceClassification.Parking_Lot,
        ServiceClassification.Sidewalk,
        ServiceClassification.Handicap_Parking,
        ServiceClassification.Curb,
        ServiceClassification.Other_Landscaping_Services
    ];
}
