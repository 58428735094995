<rfx-grid-filter-control>
	<mat-form-field appearance="outline">
		<mat-label>{{ placeholder }}</mat-label> 
		<mat-select [formControl]="controlGroup.get('value')">
			<ng-container *ngIf="!!options$">
				<mat-option 
					*ngFor="let o of (options$ | async)"
					[value]="o.value" 
				> 
					{{ o.label }}
				</mat-option>
			</ng-container>
		</mat-select> 
	</mat-form-field>
</rfx-grid-filter-control>
