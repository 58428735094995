import { DateBucket } from './DateBucket';

export class DateTimeBucket extends DateBucket {
    public hour: number;
    public minute: number;
    public second: number;
    public tzOffset: number;
    public msSinceEpoch: number;

    constructor(params?: Partial<DateTimeBucket> | Date) {
        super(params);
        if (!!params) {
            if (params instanceof Date) {
                this.hour = params.getHours();
                this.minute = params.getMinutes();
                this.second = params.getSeconds();
                this.tzOffset = params.getTimezoneOffset();
                this.msSinceEpoch = params.getTime();
            } else {
                this.hour = params.hour || this.hour;
                this.minute = params.minute || this.minute;
                this.second = params.second || this.second;
                this.tzOffset = params.tzOffset || this.tzOffset;
                this.msSinceEpoch = params.msSinceEpoch || this.msSinceEpoch;
            }
        } else {
            const now = new Date();
            this.hour = now.getHours();
            this.minute = now.getMinutes();
            this.second = now.getSeconds();
            this.tzOffset = now.getTimezoneOffset();
            this.msSinceEpoch = now.getTime();
        }
    }
}

export function dateTimeBucketToDate({ msSinceEpoch }: DateTimeBucket): Date {
    return new Date(msSinceEpoch);
}
