import { BaseDoc } from '../base/base-doc';
import { CompanyType, InsuranceStatus, CompanyStatus } from '../../enums';
import { CompanyProfileWithoutIds } from './company-profile';
import { CompanyServiceArea } from './company-service-area';
import { CompanySettings } from './company-settings';
import { CompanyQuestionnaireAnswersWithoutIds } from './company-questionnaire-answers';
import { TagWithoutIds } from '../tag/tag';
import { parseISODate } from '@refactor/common';
import { isNull } from '../../utils';
import { CompanyNotificationSettings } from './company-notification-setttings';
import { ServicePartnerCompliant } from '../../enums';

export class CompanyWithoutIds extends BaseDoc {
    public _id: any;
    public type: CompanyType = null;
    public companyStatus: CompanyStatus;
    public profile: CompanyProfileWithoutIds = new CompanyProfileWithoutIds();
    public tags: any[] = [];
    public settings: CompanySettings = new CompanySettings();
    public preQualificationData: any;
    public questionnaireAnswers: CompanyQuestionnaireAnswersWithoutIds = new CompanyQuestionnaireAnswersWithoutIds();
    public pr: any;
    public pbireporting: boolean;
    public wotimeframe: string;
    public serviceAreas: CompanyServiceArea[] = [];
    public notificationSettings: CompanyNotificationSettings = new CompanyNotificationSettings();
    public statusExplaination: string;
    public requireTimeAllocation: boolean = false;
    public servicePartnerCompliant: ServicePartnerCompliant;
    public invoiceCounter: number = 1;
    public workOrderInvoiceCounter: number = 1;
    public invoiceNonceCounter: number = 0;
    public workOrderInvoiceNonceCounter: number = 0;

    constructor(params?: Partial<CompanyWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.type = params.type || this.type;
            this.companyStatus = params.companyStatus || this.companyStatus;
            this.profile = !!params.profile
                ? new CompanyProfileWithoutIds(params.profile)
                : this.profile;
            this.tags = Array.isArray(params.tags) ? params.tags : this.tags;
            this.settings = !!params.settings
                ? new CompanySettings(params.settings)
                : this.settings;
            this.preQualificationData = params.preQualificationData || this.preQualificationData;
            this.questionnaireAnswers = params.questionnaireAnswers || this.questionnaireAnswers;
            this.pr = params.pr || this.pr;
            this.serviceAreas = Array.isArray(params.serviceAreas)
                ? params.serviceAreas.map(t => new CompanyServiceArea(t))
                : this.serviceAreas;
            this.questionnaireAnswers = !!params.questionnaireAnswers
                ? new CompanyQuestionnaireAnswersWithoutIds(params.questionnaireAnswers)
                : this.questionnaireAnswers;
            this.pbireporting = !isNull(params.pbireporting)
                ? params.pbireporting
                : this.pbireporting;
            this.wotimeframe = !isNull(params.wotimeframe) ? params.wotimeframe : this.wotimeframe;

            this.notificationSettings = !!params.notificationSettings
                ? new CompanyNotificationSettings(params.notificationSettings)
                : this.notificationSettings;

            this.statusExplaination = params.statusExplaination || this.statusExplaination;
            this.requireTimeAllocation = params.requireTimeAllocation || this.requireTimeAllocation;
            this.servicePartnerCompliant = params.servicePartnerCompliant || this.servicePartnerCompliant;
            this.invoiceCounter = (typeof params.invoiceCounter === 'number' && params.invoiceCounter > 1)
                ? params.invoiceCounter : this.invoiceCounter;
            this.workOrderInvoiceCounter = (typeof params.workOrderInvoiceCounter === 'number' && params.workOrderInvoiceCounter > 1)
                ? params.workOrderInvoiceCounter : this.workOrderInvoiceCounter;
            this.invoiceNonceCounter = (typeof params.invoiceNonceCounter === 'number' && params.invoiceNonceCounter > 1)
                ? params.invoiceNonceCounter : this.invoiceNonceCounter;
            this.workOrderInvoiceNonceCounter = (typeof params.workOrderInvoiceNonceCounter === 'number' && params.workOrderInvoiceNonceCounter > 1)
                ? params.workOrderInvoiceNonceCounter : this.workOrderInvoiceNonceCounter;
        }
    }
}
