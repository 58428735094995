import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
    RfxFormField,
    RfxFormFieldCondition,
    RfxFormFieldConditionalAction,
    RfxFormFieldConditionRulesBoolean,
    RfxFormFieldType,
    RfxFormFieldConditionRuleComparison,
    RfxFormFieldConditionRule,
    SelectOption
} from '@refactor/common';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'rfx-form-field-settings',
    templateUrl: './form-field-settings.component.html',
    styleUrls: ['./form-field-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldSettingsComponent implements AfterViewInit {
    @Input()
    public fields$: Observable<RfxFormField<string>[]>;

    private _fieldToEdit: RfxFormField<string>;
    @Input()
    public set fieldToEdit(value: RfxFormField<string>) {
        this._fieldToEdit = value;
        if (!!value) {
            this.initField(value);
        }
    }
    public get fieldToEdit(): RfxFormField<string> {
        return this._fieldToEdit;
    }

    @Output()
    public updateField: EventEmitter<RfxFormField<string>> = new EventEmitter();

    @Output()
    public cancel: EventEmitter<string> = new EventEmitter();

    // Forward to template
    public RfxFormFieldType: typeof RfxFormFieldType = RfxFormFieldType;

    public form: UntypedFormGroup = this._fb.group({
        label: ['', Validators.required],
        description: [''],
        defaultValue: [''],
        placeholder: [''],
        required: [false],
        enableConditions: [false],
        choices: this._fb.array([]),
        condition: this._fb.group({
            action: [null, Validators.required],
            rulesBoolean: [null, Validators.required],
            rules: this._fb.array([])
        })
    });

    public actionOptions: SelectOption[] = [
        ...RfxFormFieldConditionalAction.members.map(action => ({
            value: action,
            label: RfxFormFieldConditionalAction.toString(action)
        }))
    ];
    public ruleBooleanOptions: SelectOption[] = [
        ...RfxFormFieldConditionRulesBoolean.members.map(bool => ({
            value: bool,
            label: RfxFormFieldConditionRulesBoolean.toString(bool)
        }))
    ];
    public ruleComparisonOptions: SelectOption[] = [
        ...RfxFormFieldConditionRuleComparison.members.map(comparison => ({
            value: comparison,
            label: RfxFormFieldConditionRuleComparison.toString(comparison)
        }))
    ];
    public checkboxValueOptions: SelectOption[] = [
        {
            value: 'true',
            label: 'True'
        },
        {
            value: 'false',
            label: 'False'
        }
    ];

    /**
     * Array of fields that does not include the field currently being edited.
     */
    public comparisonFields$: Observable<RfxFormField<string>[]>;

    private _unsub: Subject<void> = new Subject();

    constructor(private readonly _fb: UntypedFormBuilder, private readonly _toast: ToastrService) {}

    ngAfterViewInit(): void {
        // Initialize comparison fields
        this.comparisonFields$ = this.fields$.pipe(
            takeUntil(this._unsub),
            map(fields => fields.filter(field => field._id !== this.fieldToEdit._id))
        );

        // Create subscription to monitor enableConditions checkbox
        this.form
            .get('enableConditions')
            .valueChanges.pipe(takeUntil(this._unsub))
            .subscribe(val => {
                if (val) {
                    this.form.get('condition').enable();
                } else {
                    this.form.get('condition').disable();
                }
                this.form.get('condition').updateValueAndValidity();
            });
    }

    ngOnDestroy(): void {
        this._unsub.next();
        this._unsub.complete();
    }

    /**
     * Initialize form field into settings component.
     */
    public initField(field: RfxFormField<string>): void {
        // Reset form and clear form arrays
        this.emptyFormArray(this.choicesFormArray);
        this.emptyFormArray(this.rulesFormArray);
        this.form.reset();

        // Fill form with field properties
        this.form.patchValue(field);

        // Enable every field
        this.form.enable();

        // Disable necessary fields per type
        switch (field.type) {
            case RfxFormFieldType.ApplicationDefined:
                this.form.get('label').disable();
                this.form.get('description').disable();
                this.form.get('defaultValue').disable();
                this.form.get('placeholder').disable();
                this.form.get('choices').disable();
                break;
            case RfxFormFieldType.Title:
            case RfxFormFieldType.Paragraph:
                this.form.get('description').disable();
                this.form.get('defaultValue').disable();
                this.form.get('placeholder').disable();
                this.form.get('choices').disable();
                this.form.get('required').disable();
                break;
            case RfxFormFieldType.String:
            case RfxFormFieldType.Textbox:
            case RfxFormFieldType.Number:
                this.form.get('choices').disable();
                break;
            case RfxFormFieldType.Checkbox:
                this.form.get('choices').disable();
                this.form.get('placeholder').disable();
                break;
            case RfxFormFieldType.Dropdown:
            case RfxFormFieldType.Radio:
                this.form.get('placeholder').disable();
                this.setupChoices(field);
            default:
                break;
        }

        this.setupConditions(field);
    }

    /**
     * Populate choices array if field supports it.
     */
    public setupChoices(field: RfxFormField<string>): void {
        // If already has choices, set enableConditions to true
        if (!!field.choices && field.choices.length > 0) {
            // Populate choices
            for (let i = 0; i < field.choices.length; i++) {
                this.addChoice(field.choices[i]);
            }
        } else {
            // Require minimum 1 choice
            this.addChoice();
        }
        this.form.get('choices').updateValueAndValidity();
    }

    /**
     * Enable/disable and populate condition rules FormArray.
     */
    public setupConditions(field: RfxFormField<string>): void {
        // If already has conditions, set
        // enableConditions to true
        if (!!field.condition && field.condition.rules.length > 0) {
            this.form.get('enableConditions').setValue(true);
            this.form.get('condition').enable();

            for (let i = 0; i < field.condition.rules.length; i++) {
                this.addRule(field.condition.rules[i] as RfxFormFieldConditionRule<string>);
            }
        } else {
            // Require minimum 1 rule
            this.addRule();

            this.form.get('enableConditions').setValue(false);
            this.form.get('condition').disable();
        }
        this.form.get('condition').updateValueAndValidity();
    }

    /**
     * Save field settings to form.
     */
    public saveSettings({
        valid,
        value
    }: {
        valid: boolean;
        value: {
            label: string;
            description: string;
            defaultValue: string;
            placeholder: string;
            required: boolean;
            enableConditions: boolean;
            condition: RfxFormFieldCondition;
            choices: SelectOption[];
        };
    }): void {
        if (!valid) {
            this._toast.error('Please fill out all required fields.');
            this.form.markAllAsTouched();
            return;
        }

        const updatedField = Object.assign({}, this.fieldToEdit, value);

        // If conditions are NOT enabled, remove property
        if (!value.enableConditions) {
            delete updatedField.condition;
        }

        this.updateField.emit(updatedField);
    }

    /**
     * Check and switch the "value" input field for a rule if
     * the comparisonField is a checkbox.
     */
    public updateRuleValueInputType(index: number): void {
        const ruleFormGroup = this.rulesFormArray.at(index) as UntypedFormGroup;

        // Update comparisonFieldType initially without reseting the value field
        this.fields$.pipe(take(1)).subscribe(fields => {
            const id = ruleFormGroup.get('fieldId').value;
            const comparisonField = fields.find(f => f._id === id);

            if (!!comparisonField) {
                ruleFormGroup.get('comparisonFieldType').patchValue(comparisonField.type);
                ruleFormGroup.updateValueAndValidity();
            }
        });

        // Update comparisonFieldType every time fieldId changes and reset value
        ruleFormGroup
            .get('fieldId')
            .valueChanges.pipe(
                takeUntil(this._unsub),
                debounceTime(100),
                withLatestFrom(this.fields$)
            )
            .subscribe(([id, fields]) => {
                const comparisonField = fields.find(f => f._id === id);

                if (!!comparisonField) {
                    ruleFormGroup.get('comparisonFieldType').patchValue(comparisonField.type);
                    ruleFormGroup.get('value').reset();
                    ruleFormGroup.updateValueAndValidity();
                }
            });
    }

    public emptyFormArray(formArray: UntypedFormArray): void {
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }
    }

    // CHOICES FORM ARRAY METHODS
    public get choicesFormArray(): UntypedFormArray {
        return this.form.get('choices') as UntypedFormArray;
    }
    public addChoiceAtIndex(index: number, choice?: SelectOption) {
        this.choicesFormArray.insert(index + 1, this.createChoice(choice));
    }
    public addChoice(choice?: SelectOption): void {
        this.choicesFormArray.push(this.createChoice(choice));
    }
    public createChoice(choice?: SelectOption): UntypedFormGroup {
        return this._fb.group({
            label: [!!choice ? choice.label : null, [Validators.required]],
            value: [!!choice ? choice.value : null, [Validators.required]]
        });
    }
    public removeChoice(i: number): void {
        this.choicesFormArray.removeAt(i);
    }

    // RULES FORM ARRAY METHODS
    public get conditionGroup(): UntypedFormGroup {
        return this.form.get('condition') as UntypedFormGroup;
    }
    public get rulesFormArray(): UntypedFormArray {
        return this.conditionGroup.get('rules') as UntypedFormArray;
    }
    public addRuleAtIndex(index: number, rule?: RfxFormFieldConditionRule<string>): void {
        this.rulesFormArray.insert(index + 1, this.createRule(rule));
        this.updateRuleValueInputType(index + 1);
    }
    public addRule(rule?: RfxFormFieldConditionRule<string>): void {
        this.rulesFormArray.push(this.createRule(rule));
        this.updateRuleValueInputType(this.rulesFormArray.length - 1);
    }
    public createRule(rule?: RfxFormFieldConditionRule<string>): UntypedFormGroup {
        return this._fb.group({
            fieldId: [!!rule ? rule.fieldId : null, [Validators.required]],
            comparison: [!!rule ? rule.comparison : null, [Validators.required]],
            value: [!!rule ? rule.value : null, [Validators.required]],
            comparisonFieldType: [null]
        });
    }
    public removeRule(i: number): void {
        this.rulesFormArray.removeAt(i);
    }
}
