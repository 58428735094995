export class LocationIdentityWithoutIds {
    public _id: any;
    public name: string = '';
    public timezone: string = '';

    constructor(params?: Partial<LocationIdentityWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.timezone = params.timezone || this.timezone;
        }
    }
}
