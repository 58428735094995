import { isNull } from '../../utils';

export class InvoiceMonthlyTimeframe {
    public monthName: string = '';
    public year: number = 0;

    constructor(params?: Partial<InvoiceMonthlyTimeframe>) {
        if (!!params) {
            this.monthName = !isNull(params.monthName) ? params.monthName : this.monthName;
            this.year = params.year || this.year;
        }
    }
}
