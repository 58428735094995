import { BaseDoc } from '../base/base-doc';
import { AwardedBidWithoutIds } from './awarded-bid';
import { BidWithoutIds } from './bid';
import { ClientPricingWithoutIds } from './client-pricing';
import { RfpSnapShotWithoutIds } from './rfp-snap-shot';
import { FirstApprovalWithoutIds } from './first-approval';
import { isNull } from '../../utils';
import { LocationIdentityWithoutIds } from '../location/location-identity';

export class RfpSimpleWithoutIds extends BaseDoc {
    public _id: any;
    public awardedBid: AwardedBidWithoutIds = null;
    public bids: BidWithoutIds[] = [];
    public clientPricing: ClientPricingWithoutIds = new ClientPricingWithoutIds();
    public snapShot: RfpSnapShotWithoutIds = new RfpSnapShotWithoutIds();
    public firstApproval?: FirstApprovalWithoutIds = null;
    public requestedPriceLabel: string = '';
    public locationIdentity: LocationIdentityWithoutIds = new LocationIdentityWithoutIds();

    constructor(params?: Partial<RfpSimpleWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.requestedPriceLabel = params.requestedPriceLabel || this.requestedPriceLabel;

            this.awardedBid = !!params.awardedBid
                ? new AwardedBidWithoutIds(params.awardedBid)
                : this.awardedBid;
            this.bids = Array.isArray(params.bids)
                ? params.bids.map(bid => new BidWithoutIds(bid))
                : this.bids;

            this.clientPricing = !!params.clientPricing
                ? new ClientPricingWithoutIds(params.clientPricing)
                : this.clientPricing;

            this.snapShot = !!params.snapShot
                ? new RfpSnapShotWithoutIds(params.snapShot)
                : this.snapShot;

            this.firstApproval = !isNull(params.firstApproval)
                ? new FirstApprovalWithoutIds(params.firstApproval)
                : this.firstApproval;
            this.locationIdentity = !!params.locationIdentity
                ? new LocationIdentityWithoutIds(params.locationIdentity)
                : this.locationIdentity;
        }
    }
}
