import { StringFilter, DateRangeFilter } from '@refactor/common';

export class LocationServiceHistoryGridFilters {
    public searchTerm: StringFilter = new StringFilter();
    public locationId: StringFilter = new StringFilter();
    public searchDateRange: DateRangeFilter = new DateRangeFilter();

    constructor(params?: Partial<LocationServiceHistoryGridFilters>) {
        if (!!params) {
            this.searchTerm = !!params.searchTerm ? new StringFilter(params.searchTerm) : this.searchTerm;
            this.locationId = !!params.locationId ? new StringFilter(params.locationId) : this.locationId;
            this.searchDateRange = !!params.searchDateRange ? new DateRangeFilter(params.searchDateRange) : this.searchDateRange;
        }
    }
}
