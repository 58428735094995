import { OnegmAbstractNonMonthlyCharges } from './onegm-abstract-non-monthly-charges';
import { RfpServiceWithoutIds } from '@gm2/common';

export class OnegmPerEventCharges extends OnegmAbstractNonMonthlyCharges {
    public calculateServiceClientTotal(
        duration: number,
        clientPricingService: RfpServiceWithoutIds
    ): number {
        return (clientPricingService.clientPrice || 0) + (clientPricingService.clientFee || 0);
    }
}
