export enum CompanyRating {
    R_Plus = '[CompanyRating] R Plus',
    R = '[CompanyRating] R',
    P = '[CompanyRating] P',
    X = '[CompanyRating] X'
}

export namespace CompanyRating {
    export function toString(type: CompanyRating): string {
        switch (type) {
            case CompanyRating.R_Plus:
                return 'R+';
            case CompanyRating.R:
                return 'R';
            case CompanyRating.P:
                return 'P';
            case CompanyRating.X:
                return 'X';
            default:
                return '';
        }
    }

    export const members: CompanyRating[] = [
        CompanyRating.R_Plus,
        CompanyRating.R,
        CompanyRating.P,
        CompanyRating.X
    ];

    export function getColor(type: CompanyRating): string {
        switch (type) {
            case CompanyRating.R_Plus:
                return 'green';
            case CompanyRating.R:
                return 'green';
            case CompanyRating.P:
                return 'yellow';
            case CompanyRating.X:
                return 'red';
            default:
                return '';
        }
    }

    export function getDescription(type: CompanyRating): string {
        switch (type) {
            case CompanyRating.R_Plus:
                return 'Company is responsive and reports in real time.';
            case CompanyRating.R:
                return 'Company is mostly responsive but does not report in real time.';
            case CompanyRating.P:
                return 'Company is on probation.';
            case CompanyRating.X:
                return 'Company is not responsive and does not report in real time. DO NOT USE.';
            default:
                return '';
        }
    }
}
