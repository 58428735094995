import { QuestionnaireAnswerWithoutIds } from './questionnaire-answer';
import { InsuranceQuestionnaireAnswerWithoutIds } from './insurance-questionnaire-answer';

export class CompanyQuestionnaireAnswersWithoutIds {
    public yearsInIndustry: QuestionnaireAnswerWithoutIds = new QuestionnaireAnswerWithoutIds();
    public workVehicles: QuestionnaireAnswerWithoutIds = new QuestionnaireAnswerWithoutIds();
    public capacity: QuestionnaireAnswerWithoutIds = new QuestionnaireAnswerWithoutIds();
    public employeesNeedToHire: QuestionnaireAnswerWithoutIds = new QuestionnaireAnswerWithoutIds();
    public offerDiscounts: QuestionnaireAnswerWithoutIds = new QuestionnaireAnswerWithoutIds();
    public canUseApp: QuestionnaireAnswerWithoutIds = new QuestionnaireAnswerWithoutIds();
    public licensesOrCerts: QuestionnaireAnswerWithoutIds = new QuestionnaireAnswerWithoutIds();
    public haveEmployees: QuestionnaireAnswerWithoutIds = new QuestionnaireAnswerWithoutIds();
    public workersCompInsuranceExpiration: InsuranceQuestionnaireAnswerWithoutIds = new InsuranceQuestionnaireAnswerWithoutIds();
    public generalLiabilityInsuranceExpiration: InsuranceQuestionnaireAnswerWithoutIds = new InsuranceQuestionnaireAnswerWithoutIds();
    public vehicleInsuranceExpiration: InsuranceQuestionnaireAnswerWithoutIds = new InsuranceQuestionnaireAnswerWithoutIds();
    public umbrellaInsuranceExpiration: InsuranceQuestionnaireAnswerWithoutIds = new InsuranceQuestionnaireAnswerWithoutIds();
    public payByDirectDeposit: QuestionnaireAnswerWithoutIds = new QuestionnaireAnswerWithoutIds();

    constructor(params?: Partial<CompanyQuestionnaireAnswersWithoutIds>) {
        if (!!params) {
            this.yearsInIndustry =
                new QuestionnaireAnswerWithoutIds(params.yearsInIndustry) || this.yearsInIndustry;
            this.workVehicles =
                new QuestionnaireAnswerWithoutIds(params.workVehicles) || this.workVehicles;
            this.capacity = new QuestionnaireAnswerWithoutIds(params.capacity) || this.capacity;
            this.employeesNeedToHire =
                new QuestionnaireAnswerWithoutIds(params.employeesNeedToHire) ||
                this.employeesNeedToHire;
            this.offerDiscounts =
                new QuestionnaireAnswerWithoutIds(params.offerDiscounts) || this.offerDiscounts;
            this.canUseApp = new QuestionnaireAnswerWithoutIds(params.canUseApp) || this.canUseApp;
            this.licensesOrCerts =
                new QuestionnaireAnswerWithoutIds(params.licensesOrCerts) || this.licensesOrCerts;
            this.haveEmployees =
                new QuestionnaireAnswerWithoutIds(params.haveEmployees) || this.haveEmployees;
            this.workersCompInsuranceExpiration =
                new InsuranceQuestionnaireAnswerWithoutIds(params.workersCompInsuranceExpiration) ||
                this.workersCompInsuranceExpiration;
            this.generalLiabilityInsuranceExpiration =
                new InsuranceQuestionnaireAnswerWithoutIds(params.generalLiabilityInsuranceExpiration) ||
                this.generalLiabilityInsuranceExpiration;
            this.vehicleInsuranceExpiration =
                new InsuranceQuestionnaireAnswerWithoutIds(params.vehicleInsuranceExpiration) ||
                this.vehicleInsuranceExpiration;
            this.umbrellaInsuranceExpiration =
                new InsuranceQuestionnaireAnswerWithoutIds(params.umbrellaInsuranceExpiration) ||
                this.umbrellaInsuranceExpiration;
            this.payByDirectDeposit =
                new QuestionnaireAnswerWithoutIds(params.payByDirectDeposit) ||
                this.payByDirectDeposit;
        }
    }
}
