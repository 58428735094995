<div class="header">
    <h1>
        {{ (isEditing$ | async) ? 'Edit Form' : 'Create New Form' }}
    </h1>
    <div class="buttons">
        <button mat-button type="button" (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>&nbsp; Back to List
        </button>
        <button
            mat-button
            type="button"
            [disabled]="isSubmitting$ | async"
            (click)="deleteForm()"
            *ngIf="(isEditing$ | async)"
        >
            <mat-icon>delete</mat-icon>&nbsp; Delete
        </button>
        <button mat-button (click)="submit()" [disabled]="isSubmitting$ | async">
            <mat-spinner
                class="csv-spinner"
                [diameter]="17"
                *ngIf="(isSubmitting$ | async)"
            ></mat-spinner>
            Save
        </button>
        <button
            mat-button
            type="button"
            [disabled]="isPublishing$ | async"
            (click)="submit(true)"
            *ngIf="!(currentForm$ | async)?.published"
        >
            <mat-spinner
                class="csv-spinner"
                [diameter]="17"
                *ngIf="(isPublishing$ | async)"
            ></mat-spinner>
            <mat-icon>publish</mat-icon>&nbsp; Publish
        </button>
    </div>
</div>
<div class="page-container">
    <form [formGroup]="formGroup">
        <mat-form-field class="form-name-field">
            <mat-label attr.for="name">Name</mat-label>
            <input matInput formControlName="name" id="name" required />
            <mat-error [rfxError]="formGroup.controls['name']"></mat-error>
        </mat-form-field>
        <mat-form-field class="form-description-field">
            <mat-label attr.for="description">Description</mat-label>
            <input matInput formControlName="description" id="description" required />
            <mat-error [rfxError]="formGroup.controls['description']"></mat-error>
        </mat-form-field>
    </form>
    <div class="drop-group" cdkDropListGroup>
        <rfx-form-field-tray></rfx-form-field-tray>
        <rfx-form-canvas
            [canvasFields$]="fields$"
            (editField)="selectFieldToEdit($event)"
            (deleteField)="deleteField($event)"
        ></rfx-form-canvas>
        <rfx-form-field-settings
            [fieldToEdit]="fieldToEdit$ | async"
            [fields$]="fields$"
            (cancel)="clearFieldToEdit()"
            (updateField)="updateField($event)"
        ></rfx-form-field-settings>
    </div>
</div>
