import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    HttpService,
    Body,
    Get,
    Post,
    Put,
    Delete,
    Path,
    Query
} from '@refactor/ngx/http';
import { PaginatedList, GridParams, RfxHelpSelectOption, RfxHelp } from '@refactor/common';
import { Observable } from 'rxjs';
import { RfxHelpCreateDto } from '../models/rfx-help-create.dto';
import { RfxHelpEditDto } from '../models/rfx-help-edit.dto';

@Injectable({
    providedIn: 'root'
})
export class RfxHelpService extends HttpService {

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    @Post('/rfx-help')
    public createRfxHelp(
        @Body() body: RfxHelpCreateDto
    ): Observable<void> {
        return null;
    }

    @Put('/rfx-help/:id')
    public editRfxHelp(
        @Path('id') id: unknown,
        @Body() body: RfxHelpEditDto
    ): Observable<void> {
        return null;
    }

    @Get('/rfx-help')
    public getRfxHelp(
        @Query('page') page: string
    ): Observable<RfxHelpSelectOption[]> {
        return null;
    }

    /*
        The delete() method of Angular's HttpClient class
        does not directly support attaching a request body,
        so we need to use the request() method directly instead.

        Pat D Dec 8 2020
    */
    public deleteRfxHelp(
        ids: unknown[]
    ): Observable<void> {
        const options = {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                ids: ids
            }
        };
        return this._httpClient.request<void>(
            'delete',
            '/rfx-help',
            options
        );
    }

    @Get('/rfx-help/:id')
    public getRfxHelpById(
        @Path('id') id: unknown
    ): Observable<RfxHelp> {
        return null;
    }

    @Post('/rfx-help/list')
    public getRfxHelpList(
        @Body() params: GridParams
    ): Observable<PaginatedList<RfxHelp>> {
        return null;
    }
}
