import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import {
    User,
    Company,
    AppNotification,
    UserProfile,
    CompanyProfile,
    ITokenPayload,
    UserService,
    ToastService,
    CompanyService,
    NotificationService,
} from '@gm2/ui-common';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { Select } from '@ngxs/store';
import { CompanyState, AuthState, UserState } from '@gm2/ui-state';
import { map, tap, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { Permission } from '@gm2/common';

@Component({
    selector: 'gm2-secured-header',
    templateUrl: './secured-header.component.html',
    styleUrls: ['./secured-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecuredHeaderComponent implements OnInit {
    public readonly Permission: typeof Permission = Permission;

    @Select(CompanyState.profileComplete)
    public profileComplete$: Observable<boolean>;

    @Input()
    public user: User;
    @Input()
    public company: Company;
    // @Input()
    // public notifications: AppNotification[] = [];
    @Input()
    public notificationCount: number;

    @Output()
    public logout: EventEmitter<void> = new EventEmitter<void>();

    @Select(AuthState.tokenPayload)
    public tokenPayload: Observable<ITokenPayload>;

    @Emitter(AuthState.setAuthToken)
    public setAuthToken: Emittable<string>;

    @Emitter(UserState.setUser)
    public setUser: Emittable<User>;

    @Emitter(CompanyState.setCompany)
    public setCompany: Emittable<Company>;

    @Emitter(UserState.setNotificationCount)
    public setNotificationCount: Emittable<number>;

    public isImpersonating$: Observable<boolean>;


    constructor(
        private readonly _router: Router,
        private readonly _userService: UserService,
        private readonly _companyService: CompanyService,
        private readonly _toastService: ToastService,
        private readonly _notificationService: NotificationService,
    ) {
    }

    ngOnInit(): void {
        this.isImpersonating$ = this.tokenPayload.pipe(
            map(payload => payload?.absoluteUserId !== payload?.userId),
        );
    }

    public stopImpersonating(): void {
        // Kinda copied from user-list.component.ts
        this._userService
            .stopImpersonating()
            .pipe(
                tap(token => {
                    this.setAuthToken.emit(token);
                }),
                switchMap(_ =>
                    forkJoin(
                        this._userService.getLoggedInUser(),
                        this._userService.getUserPermissions(),
                        this._companyService.getCompanyOfLoggedInUser(),
                        this._notificationService.getNewNotificationCount(),
                    ),
                ),
                tap(([user, permissions, company, noteCount]) => {
                    user.permissions = permissions;
                    this.setUser.emit(user);
                    this.setCompany.emit(company);
                    this.setNotificationCount.emit(noteCount);
                }),
            )
            .subscribe(
                () => {
                    // Navigate away
                    this._router.navigateByUrl('/support/user/list');
                },
                err => {
                    this._toastService.error(err);
                },
            );
    }

    public completeProfile(): void {
        this._router.navigateByUrl('/profile/company');
    }
}
