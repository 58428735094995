import { Injectable } from '@angular/core';
import {
    HttpService,
    Post,
    MapClass,
    MapValue,
    Body,
    Get,
    Path,
    Put,
    Delete,
    ResponseType
} from '@refactor/ngx/http';
import { ContractTemplate } from '../models';
import { Observable } from 'rxjs';
import { PaginatedList, GridParams } from '@refactor/common';
import { ContractAppliedSimple } from '@gm2/api-common';

@Injectable({
    providedIn: 'root'
})
export class ContractTemplateService extends HttpService {
    // endpoint still needs to be created.  This might need to be changed.
    @Post('/contractTemplate/listSimple')
    @MapValue(
        res =>
            new PaginatedList<ContractTemplate>({
                count: res.count,
                docs: res.docs.map(d => new ContractTemplate(d))
            })
    )
    @Get('/contracts')
    @MapClass(ContractTemplate)
    public getContractTemplateList(
        @Body() params: GridParams
    ): Observable<PaginatedList<ContractTemplate>> {
        return null;
    }

    // need to determine if we need contract dto
    @Post('/contractTemplate')
    public createContract(@Body() body: ContractTemplate): Observable<void> {
        return null;
    }

    @Post('/contractTemplate/:contractId/duplicate')
    public duplicateContract(@Path('contractId') id: string): Observable<string> {
        return null;
    }

    //endpoint still needs to be created to get a contract by id for the purpose of editing it
    @Get('/contractTemplate/:contractId')
    public getContractTemplate(@Path('contractId') id: string): Observable<any> {
        return null;
    }

    @Put('/contractTemplate/:contractId')
    @MapClass(ContractTemplate)
    public editContractTemplate(
        @Path('contractId') id: string,
        @Body() body: ContractTemplate
    ): Observable<ContractTemplate> {
        return null;
    }

    @Delete('/contractTemplate/:contractId')
    public deleteContractTemplate(@Path('contractId') id: string): Observable<void> {
        return null;
    }

    @Post('/contractTemplate/csvExport', {
        responseType: ResponseType.Text
    })
    public contractCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }

    @Post('/contractTemplate/list/byIds')
    public getContractTemplateListByIds(@Body() ids: string[]): Observable<any> {
        return null;
    }
}
