import { Injectable } from '@angular/core';
import {
    HttpService,
    Post,
    MapClass,
    MapValue,
    Body,
    Get,
    Path,
    Put,
    ResponseType,
} from '@refactor/ngx/http';
import { Rfp, RfpBidsSimple, FirstApproval, IRfpBid } from '../models';
import { Observable } from 'rxjs';
import { PaginatedList, GridParams } from '@refactor/common';
import { RfpPhase } from '@gm2/common';

@Injectable({
    providedIn: 'root',
})
export class RfpService extends HttpService {
    @Get('/rfp/:rfpId')
    @MapClass(Rfp)
    public getRfp(@Path('rfpId') id: string): Observable<Rfp> {
        return null;
    }

    @Post('/rfp/byCompany')
    @MapValue(
        res =>
            new PaginatedList<Rfp>({
                count: res.count,
                docs: res.docs.map(d => new Rfp(d)),
            }),
    )
    public getRfpList(@Body() params: GridParams): Observable<PaginatedList<Rfp>> {
        return null;
    }

    @Get('/rfp/byCompany/monthly/count')
    public getMonthlyRfpCountForCompany(): Observable<number> {
        return null;
    }

    @Get('/rfp/byCompany/monthly/landscapers')
    public getMonthlyRfpLandscapers(): Observable<{ name: string; _id: string }[]> {
        return null;
    }

    /** Return rfps for logged in user filterd by location id. */
    @Get('/rfp/byCompany/:id')
    public getRfpsByLocationId(@Path('id') id: string): Observable<Rfp[]> {
        return null;
    }

    /** Return rfps for logged in user filterd by location id. (ON SP BEHALF)*/
    @Get('/rfp/byCompanySpBehalf/:id/:companyId')
    public getRfpsByLocationIdOnSPBehalf(@Path('id') id: string, @Path('companyId') companyId: string): Observable<Rfp[]> {
        return null;
    }

    @Get('/rfp/byCompanyCompanyService/:id')
    public getRfpsByLocationIdCompanyService(@Path('id') id: string): Observable<Rfp[]> {
        return null;
    }

    @Get('/rfp/byCompanyPackage/:id')
    public getRfpsByLocationIdPackage(@Path('id') id: string): Observable<Rfp[]> {
        return null;
    }

    @Post('/rfp/byLocationAndContracts/:id')
    public getRfpsByLocationIdAndContractIds(
        @Path('id') id: string,
        @Body() data: { contractIds: string[] },
    ): Observable<Rfp[]> {
        return null;
    }

    @Get('/rfp/contractApplied/:contractId')
    @MapClass(Rfp)
    public getRfpsByAppliedContractId(@Path('contractId') id: string): Observable<Rfp[]> {
        return null;
    }

    @Get('/rfp/contractAppliedUnSubmitted/:contractId')
    @MapClass(Rfp)
    public getRfpsByAppliedContractIdUnSubmitted(@Path('contractId') id: string): Observable<Rfp[]> {
        return null;
    }


    @Post('/rfp/bidList')
    // @MapValue(
    //     res => {
    //         debugger
    //         console.log(res);
    //         return new PaginatedList<RfpBidsSimple>({
    //             count: 1,
    //             docs:[],
    //         });
    //     },
    // )
    public getRfpBidList(@Body() params: GridParams): Observable<PaginatedList<RfpBidsSimple>> {
        return null;
    }

    @Post('/rfp/:rfpId/submitBid')
    public submitBid(@Path('rfpId') id: string, @Body() dto: IRfpBid): Observable<void> {
        return null;
    }

    @Post('/rfp/:id/firstApproval')
    public postRfpFirstApproval(
        @Path('id') id: string,
        @Body() params: FirstApproval,
    ): Observable<void> {
        return null;
    }

    @Post('/rfp/:id/awardBid')
    public awardBid(@Path('id') id: string, @Body() data: { companyId: string }): Observable<void> {
        return null;
    }

    @Put('/rfp/:id/clientPricing')
    public saveClientPricingToRfp(@Path('id') id: string, @Body() data: any): Observable<void> {
        return null;
    }

    @Put('/rfp/:id/unaward')
    public unawardRfp(@Path('id') id: string): Observable<void> {
        return null;
    }

    @Put('/rfp/quickpricing/:id')
    public quickpricing(@Path('id') contractId: string, @Body() data: FormData): Observable<void> {
        return null;
    }

    @Post('/rfp/availableCountByClient')
    public getAvailableCountByClient(): Observable<{ name: string; count: number }[]> {
        return null;
    }

    @Post('/rfp/bidCsvExport', {
        responseType: ResponseType.Text,
    })
    public bidCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }
}
