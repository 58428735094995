import { SubcontractorInfoWithoutIds } from "@gm2/common";

export class SubcontractorInfo extends SubcontractorInfoWithoutIds {
    public _id: string = '';

    constructor(params?: Partial<SubcontractorInfo>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
        }
    }
}
