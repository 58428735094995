import { WorkOrderLaborCompletionWithoutIds } from '@gm2/common';

export class WorkOrderLaborCompletion extends WorkOrderLaborCompletionWithoutIds {
	public _id: string = '';

	constructor(params?: Partial<WorkOrderLaborCompletion>){
		super(params);
		if(!!params){
			this._id = params._id || this._id;
		}
	}
}