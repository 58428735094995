import {
    BillingType,
    CompanyPriceService,
    CompanyService,
    CompanySimple,
    CompanyType,
    ContractAppliedService,
    LocationService,
    LocationSimple,
    Material,
    Package,
    Rfp,
    RfpService,
    SubcontractorInfoService,
    TimesheetIdentity,
    TimesheetService,
    ToastService
} from '@gm2/ui-common';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { CompanyState } from '@gm2/ui-state';
import { TimesheetContractType } from '../../../../../apps/ngx-gm2/src/app/consts/timesheet-contract-type.const';
import { SubcontractorInfo } from '../../../../ui-common/src/lib/core/models/subcontractor-info/subcontractor-info';

export namespace TimesheetActions {
    export class ResetToInitialState {
        static readonly type = '[Timesheet] Reset To Initial State';
    }

    export class PageLoaderShow {
        static readonly type = '[Timesheet] Page Loader Show';
    }

    export class PageLoaderHide {
        static readonly type = '[Timesheet] Page Loader Hide';
    }

    export class GetSelectedTimesheet {
        static readonly type = '[Timesheet] Get Selected Timesheet';

        constructor(public id: string) {
        }
    }

    export class SetFilters {
        static readonly type = '[Timesheet] Get Filters';

        constructor(public filter: { name: string; value: string }) {
        }
    }

    export class GetClients {
        static readonly type = '[Timesheet] Get Clients ';

        constructor() {
        }
    }

    export class GetLocationAwardedList {
        static readonly type = '[Timesheet] Get Location Awarded List';

        constructor() {
        }
    }


    export class GetRequireTimeAllocation {
        static readonly type = '[Timesheet] Get Require Time Allocation';

        constructor(public payload: {
            locationId: string,
            contractType: boolean,
            packageId: string,
            companyServiceId: string,
            servicePartnerId: string,
        }) {
        }
    }

    export class ResetRequireTimeAllocation {
        static readonly type = '[Timesheet] Reset Require Time Allocation';

        constructor() {
        }
    }

    export class ToggleBehalf {
        static readonly type = '[Timesheet] Toggle Behalf';

        constructor(public behalfToggle: boolean) {
        }
    }

    export class GetRfps {
        static readonly type = '[Timesheet] Get Rfps';

        constructor(public locationId: string, public servicePartnerId: string) {
        }
    }

    export class GetPackages {
        static readonly type = '[Timesheet] Get Packages';

        constructor() {
        }
    }

    export class GetApprovedMaterials {
        static readonly type = '[Timesheet] Get Approved Materials';

        constructor() {
        }
    }
}

export interface TimesheetStateModel {
    filters: {
        clientName: string
    };
    timesheets: TimesheetIdentity [];
    selectedTimesheet: TimesheetIdentity;
    requireTimeAllocation: boolean;
    clients: CompanySimple[];

    awardedLocations: LocationSimple[];
    rfps: Rfp[];
    packages: Package[];
    approvedMaterials: Material[];

    loadingCounter: number;

    behalfToggle: boolean;
    totalItemsCount: number;
    perPage: number;
    page: number;
}

const initialState: TimesheetStateModel = {
    filters: null,
    timesheets: null,
    selectedTimesheet: null,
    requireTimeAllocation: null,
    clients: null,

    awardedLocations: null,
    rfps: null,
    packages: null,
    approvedMaterials: null,

    loadingCounter: 0,

    behalfToggle: false,
    totalItemsCount: 0,
    perPage: 10,
    page: 0
};

@State<TimesheetStateModel>({
    name: 'timesheet',
    defaults: initialState
})

@Injectable()
export class TimesheetState {
    constructor(private readonly timesheetService: TimesheetService,
                private readonly locationService: LocationService,
                private readonly companyService: CompanyService,
                private readonly subcontractorInfoService: SubcontractorInfoService,
                private readonly rfpService: RfpService,
                private readonly contractAppliedService: ContractAppliedService,
                private companyPriceService: CompanyPriceService,
                private toastService: ToastService,
                private store: Store) {
    }

    @Selector()
    @ImmutableSelector()
    public static selectTimesheets(state: TimesheetStateModel): TimesheetIdentity[] {
        return state.timesheets;
    }

    @Selector()
    @ImmutableSelector()
    public static selectSelectedTimesheet(state: TimesheetStateModel): TimesheetIdentity {
        return state.selectedTimesheet;
    }

    @Selector()
    @ImmutableSelector()
    public static selectAwardedLocations(state: TimesheetStateModel): LocationSimple[] {
        return state.awardedLocations;
    }

    @Selector()
    @ImmutableSelector()
    public static selectRfps(state: TimesheetStateModel): Rfp[] {
        return state.rfps;
    }

    @Selector()
    @ImmutableSelector()
    public static selectPackages(state: TimesheetStateModel): Package[] {
        return state.packages;
    }

    @Selector()
    @ImmutableSelector()
    public static selectApprovedMaterials(state: TimesheetStateModel): Material[] {
        return state.approvedMaterials;
    }

    @Selector()
    @ImmutableSelector()
    public static selectBehalfToggle(state: TimesheetStateModel): boolean {
        return state.behalfToggle;
    }

    @Selector()
    @ImmutableSelector()
    public static selectRequireTimeAllocation(state: TimesheetStateModel): boolean {
        return state.requireTimeAllocation;
    }

    @Selector()
    @ImmutableSelector()
    public static selectLoadingCounter$(state: TimesheetStateModel): number {
        return state.loadingCounter;
    }

    @Selector()
    @ImmutableSelector()
    public static selectFilteredClients(state: TimesheetStateModel): CompanySimple[] {
        if (state.filters?.clientName) {
            return state.clients.filter(client => {
                const search = state.filters.clientName.toLowerCase();
                const target = client.profile.name.toLowerCase();
                return target.includes(search);
            });
        } else {
            return state.clients;
        }
    }

    @Action(TimesheetActions.ResetToInitialState)
    public resetToInitialState(ctx: StateContext<TimesheetStateModel>) {
        ctx.setState(initialState);
    }

    @Action(TimesheetActions.PageLoaderShow)
    public pageLoaderShow(ctx: StateContext<TimesheetStateModel>) {
        ctx.setState((state) => ({ ...state, loadingCounter: state.loadingCounter + 1 }));
    }

    @Action(TimesheetActions.PageLoaderHide)
    public pageLoaderHide(ctx: StateContext<TimesheetStateModel>) {
        ctx.setState((state) => ({ ...state, loadingCounter: state.loadingCounter - 1 }));
    }

    @Action(TimesheetActions.GetSelectedTimesheet)
    public getSelectedTimesheet(ctx: StateContext<TimesheetStateModel>, action: TimesheetActions.GetSelectedTimesheet) {
        ctx.dispatch(new TimesheetActions.PageLoaderShow());
        return this.timesheetService.getTimesheetById(action.id)
            .pipe(
                tap(res => {
                    ctx.setState((state) => ({
                        ...state,
                        selectedTimesheet: res
                    }));
                }),
                catchError((err: any) => {
                    this.toastService.error(err?.error?.message);
                    return of();
                }),
                finalize(() => ctx.dispatch(new TimesheetActions.PageLoaderHide()))
            );
    }

    @Action(TimesheetActions.SetFilters)
    public setFilter(ctx: StateContext<TimesheetStateModel>, action: TimesheetActions.SetFilters) {
        ctx.setState((state) => ({
            ...state,
            filters: {
                ...state.filters,
                [action.filter.name]: action.filter.value
            }
        }));
    }

    @Action(TimesheetActions.GetClients)
    public getClients(ctx: StateContext<TimesheetStateModel>) {
        ctx.dispatch(new TimesheetActions.PageLoaderShow());
        return this.companyService.getSimpleList(CompanyType.Client)
            .pipe(
                tap(res => {
                    ctx.setState((state) => ({
                        ...state,
                        clients: res
                    }));
                }),
                catchError((err: any) => {
                    this.toastService.error(err?.error?.message);
                    return of();
                }),
                finalize(() => ctx.dispatch(new TimesheetActions.PageLoaderHide()))
            );
    }

    @Action(TimesheetActions.GetLocationAwardedList)
    public getLocationAwardedList(ctx: StateContext<TimesheetStateModel>, action: TimesheetActions.GetSelectedTimesheet) {
        ctx.dispatch(new TimesheetActions.PageLoaderShow());
        return this.locationService.getAwardedSimpleList(true)
            .pipe(
                tap(res => {
                    ctx.setState((state) => ({
                        ...state,
                        awardedLocations: res
                    }));
                }),
                catchError((err: any) => {
                    this.toastService.error(err?.error?.message);
                    return of();
                }),
                finalize(() => ctx.dispatch(new TimesheetActions.PageLoaderHide()))
            );
    }

    @Action(TimesheetActions.GetRequireTimeAllocation)
    public getRequireTimeAllocation(ctx: StateContext<TimesheetStateModel>, action: TimesheetActions.GetRequireTimeAllocation) {
        const {
            companyServiceId,
            packageId,
            contractType,
            locationId,
            servicePartnerId
        } = action.payload;

        if (!locationId) {
            return of();
        }

        const { rfps } = ctx.getState();
        const matchingRfp = rfps?.find((rfp) => packageId && rfp?.snapShot?.package?._id === packageId || companyServiceId && rfp?.snapShot?.companyService?._id === companyServiceId);

        const bidBillingType = matchingRfp?.awardedBid?.bid?.billingType;
        const contractBillingType = matchingRfp?.contractIdentity?.billingType;

        if (bidBillingType === BillingType.Hourly || contractBillingType === BillingType.Hourly) {
            ctx.setState((state) => ({ ...state, requireTimeAllocation: true }));
            return of();
        }

        if (bidBillingType === BillingType.Monthly || bidBillingType === BillingType.PerEvent || !bidBillingType) {

            const company = this.store.selectSnapshot(CompanyState.company);

            if (contractType === TimesheetContractType.Internal && !ctx.getState().behalfToggle) {
                ctx.setState((state) => ({ ...state, requireTimeAllocation: company.requireTimeAllocation }));
                return of();
            }

            const isServicePartner = company.type === CompanyType.Service_Partner;
            const isPLBehalfOfSP = company.type === CompanyType.Landscaper && ctx.getState().behalfToggle && servicePartnerId;
            const isExternalContract = contractType === TimesheetContractType.External;
            const selectedLocation = ctx.getState().awardedLocations.find(loc => loc._id === locationId);

            if (isPLBehalfOfSP) {
                ctx.dispatch(new TimesheetActions.PageLoaderShow());
                return this.subcontractorInfoService.getSubcontractorInfoByContractOwnerIdBehalfSpId(selectedLocation.createdByIdentity._id, servicePartnerId)
                    .pipe(
                        tap((res: SubcontractorInfo) => ctx.setState((state) => ({
                            ...state,
                            requireTimeAllocation: res?.requireTimeAllocation ?? false
                        }))),
                        finalize(() => ctx.dispatch(new TimesheetActions.PageLoaderHide()))
                    )
            }

            if (isExternalContract || isServicePartner) {
                ctx.dispatch(new TimesheetActions.PageLoaderShow());
                return this.subcontractorInfoService.getSubcontractorInfoByContractOwnerId(selectedLocation.createdByIdentity._id)
                    .pipe(
                        tap((res: SubcontractorInfo) => ctx.setState((state) => ({
                            ...state,
                            requireTimeAllocation: res?.requireTimeAllocation ?? false
                        }))),
                        finalize(() => ctx.dispatch(new TimesheetActions.PageLoaderHide()))
                    );
            }
        }
        return of();
    }

    @Action(TimesheetActions.ResetRequireTimeAllocation)
    public resetRequireTimeAllocation(ctx: StateContext<TimesheetStateModel>, action: TimesheetActions.ResetRequireTimeAllocation) {
        ctx.setState((state) => ({
            ...state,
            requireTimeAllocation: null
        }));
    }

    @Action(TimesheetActions.GetRfps)
    public getRfps(ctx: StateContext<TimesheetStateModel>, { locationId, servicePartnerId }: TimesheetActions.GetRfps): Observable<any> {
        if (!locationId) {
            console.error('Location is null, not sending request');
            return of();
        }
        ctx.dispatch(new TimesheetActions.PageLoaderShow());

        return (servicePartnerId
            ? this.rfpService.getRfpsByLocationIdOnSPBehalf(locationId, servicePartnerId)
            : this.rfpService.getRfpsByLocationId(locationId))
            .pipe(tap((rfps) => ctx.setState((state) => ({ ...state, rfps }))))
            .pipe(tap(() => ctx.dispatch(new TimesheetActions.GetPackages())))
            .pipe(finalize(() => ctx.dispatch(new TimesheetActions.PageLoaderHide())));
    }

    @Action(TimesheetActions.GetPackages)
    public getPackages(ctx: StateContext<TimesheetStateModel>, action: TimesheetActions.GetPackages): Observable<any> {

        const filteredRfps = ctx.getState()?.rfps?.filter((rfp) => Boolean(rfp?.snapShot?.package?.name));

        if (!filteredRfps?.length) {
            console.error('There is no valid RFP, not sending request');
            return of();
        }

        ctx.dispatch(new TimesheetActions.PageLoaderShow());
        const requests: Observable<Package>[] = filteredRfps.map((rfp) => this.contractAppliedService.getContractApplied(rfp.contractIdentity._id)
            .pipe(
                map((contract) => {
                    // init data
                    const packageSnapshot = rfp?.snapShot?.package;
                    const awardedBidServices = rfp?.awardedBid?.bid?.services;

                    // copy materials from awarded bid to package service
                    const services = packageSnapshot?.services?.map((service) => {
                        const awardedBidService = awardedBidServices.find((awardedBid) => awardedBid?.serviceId === service?._id);
                        return { ...service, material: awardedBidService?.materials };
                    });
                    // compile package
                    return { ...packageSnapshot, services, contractName: contract?.name } as unknown as Package;
                }),
                finalize(() => ctx.dispatch(new TimesheetActions.PageLoaderHide()))
            )
        );

        return forkJoin(requests)
            .pipe(map((res) => ctx.setState({ ...ctx.getState(), packages: res })));
    }

    @Action(TimesheetActions.GetApprovedMaterials)
    public getApprovedMaterials(ctx: StateContext<TimesheetStateModel>, action: TimesheetActions.GetApprovedMaterials) {

        ctx.dispatch(new TimesheetActions.PageLoaderShow());
        return this.companyPriceService.getApprovedMaterialList()
            .pipe(
                map((approvedMaterials) => ctx.setState((state) => ({ ...state, approvedMaterials }))),
                finalize(() => ctx.dispatch(new TimesheetActions.PageLoaderHide()))
            );

    }


    @Action(TimesheetActions.ToggleBehalf)
    public toggleBehalf(ctx: StateContext<TimesheetStateModel>, action: TimesheetActions.ToggleBehalf) {
        ctx.setState((state) => ({
            ...state,
            behalfToggle: action.behalfToggle
        }));
    }
}

