<rfx-grid-filter-control>
	<mat-form-field 
		[appearance]="appearance"
	>
		<mat-spinner
			*ngIf="loadingSpinner"
			[diameter]="20"
		></mat-spinner>
		<mat-label>
			{{ placeholder }}
		</mat-label> 
		<mat-select 
			[required]="locationRequiredBool"
			[formControl]="!!controlRef ? controlRef : controlGroup.get('value')"
		>
			<mat-option>
				<ngx-mat-select-search 
				[formControl]="searchControl"
				placeholderLabel="Search"
				noEntriesFoundLabel="No matching results found"
				></ngx-mat-select-search>
			</mat-option>
			<mat-option 
				*ngFor="let o of (options$ | async)"
				[value]="o.value" 
			> 
				{{ o.label }}
			</mat-option>
		</mat-select> 
	</mat-form-field>
</rfx-grid-filter-control>