import { Service } from './Service';
import { ServiceClassification, ServiceSettings } from '@gm2/common';
import { ServiceChild } from './service-child';

export class ServiceDto {
    public id: string;
    public name: string;
    public description: string;
    public serviceTypeId: string;
    public serviceClassification: ServiceClassification;
    public hasMaterial: boolean;
    public hasClientFee: boolean;
    public allowMultipleChildSelections: boolean;
    public parentControlsPrice: boolean;
    public childServices: ServiceChild[] = [];
    public settings: ServiceSettings;

    constructor(params?: Partial<ServiceDto>) {
        if (!!params) {
            this.id = params.id || this.id;
            this.name = params.name || this.name;
            this.description = params.description || this.description;
            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId;
            this.settings = params.settings || this.settings;
            this.childServices = params.childServices || this.childServices;

            if (!!this.settings) {
                /*
                    These fields ought to be tucked inside of the interior
                    ServiceSettings object but need to be top-level because
                    of the constraints imposed by ServiceEditDto in
                    service-edit.dto.ts

                    Complicated boolean logic results because unticked check
                    boxes in the form will yield a value of 'undefined', the
                    fields must evaluate to a boolean, and finally because
                    (false || undefined) evaluates to undefined
                */
                this.allowMultipleChildSelections =
                    this.settings.multipleChildSelections !== true &&
                    this.allowMultipleChildSelections !== true
                        ? false
                        : this.settings.multipleChildSelections ||
                          this.allowMultipleChildSelections;
                this.parentControlsPrice =
                    this.settings.parentControl !== true &&
                    this.parentControlsPrice !== true
                        ? false
                        : this.settings.parentControl ||
                          this.parentControlsPrice;
                this.hasClientFee =
                    this.settings.hasClientFee !== true &&
                    this.hasClientFee !== true
                        ? false
                        : this.settings.hasClientFee ||
                          this.hasClientFee;
                this.hasMaterial =
                    this.settings.hasMaterial !== true &&
                    this.hasMaterial !== true
                        ? false
                        : this.settings.hasMaterial ||
                          this.hasMaterial;
                // ServiceClassification is non-boolean
                this.serviceClassification =
                    this.settings.serviceClassification || this.serviceClassification;
            }
        }
    }

    /**
     * Construct a new instance of this dto from an instance of another class
     */

    public static from<T extends Service>(
        type: { new (params: Partial<T>): T },
        value: T
    ): ServiceDto {
        if (type === Service) {
            return new ServiceDto({
                // name:,
                // description:,
                // serviceTypeId:,
                // serviceClass:,
                // hasMaterial:,
                // hasClientFee:,
                // allowMultipleChildSelections:,
                // parentControlsPrice:,
                // childServices:
            });
        }

        return new ServiceDto();
    }
}
