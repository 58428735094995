import { WorkOrderGMR, WorkOrderReportedBy, WorkOrderWorkCategory, WorkOrderPriority, WorkOrderBillingType } from '@gm2/common';
import { WorkOrderPhaseDto } from './WorkOrderPhaseDto';

export class WorkOrderAdminDto {
    public locationId: string;
    public workOrderNumber?: string;
    public servicePartnerInvoiceNumber?: string;
    public gmr: WorkOrderGMR;
    public safety: boolean = false;
    public completeByDate?: Date;
    public scheduledDate?: Date;
    public reportedBy: WorkOrderReportedBy;
    public workCategory: WorkOrderWorkCategory;
    public priority: WorkOrderPriority;
    public billingType: WorkOrderBillingType;
    public phases?: WorkOrderPhaseDto[];
    public saveAsTemplate: boolean = false;
    public templateName?: string;
    public referredBy?: string;
    public serviceDueDate?: Date;
    public qbInvoiceNumber?: string;
    public clientWorkOrderNumber?: string;

    constructor(params: WorkOrderAdminDto) {
        this.locationId = params.locationId;
        this.workOrderNumber = params.workOrderNumber || this.workOrderNumber;
        this.servicePartnerInvoiceNumber = params.servicePartnerInvoiceNumber || this.servicePartnerInvoiceNumber;
        this.gmr = params.gmr;
        this.safety = params.safety || this.safety;
        this.completeByDate = !!params.completeByDate
            ? new Date(params.completeByDate)
            : this.completeByDate;
        this.scheduledDate = !!params.scheduledDate
            ? new Date(params.scheduledDate)
            : this.scheduledDate;
        this.reportedBy = params.reportedBy;
        this.workCategory = params.workCategory;
        this.priority = params.priority;
        this.billingType = params.billingType;
        this.phases = Array.isArray(params.phases)
            ? params.phases.map(p => new WorkOrderPhaseDto(p))
            : this.phases;
        this.saveAsTemplate = params.saveAsTemplate || this.saveAsTemplate;
        this.templateName = params.templateName || this.templateName;
        this.referredBy = params.referredBy || this.referredBy;
        this.serviceDueDate = !!params.serviceDueDate
            ? new Date(params.serviceDueDate)
            : this.serviceDueDate;
        this.qbInvoiceNumber = params.qbInvoiceNumber || this.qbInvoiceNumber;
        this.clientWorkOrderNumber = params.clientWorkOrderNumber || this.clientWorkOrderNumber;
    }
}
