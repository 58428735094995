
export class WorkOrderMaterialDto {
	public _id: string = '';
	public description: string = '';
	public quantity: number = 0;
	public size: string = '';
	public price: number = 0;
	public markup: number = 0;

	constructor(params?: Partial<WorkOrderMaterialDto>){
		if(!!params){
			this._id = params._id || this._id;
			this.description = params.description || this.description;
			this.quantity = params.quantity || this.quantity;
			this.size = params.size || this.size;
			this.price = params.price || this.price;
			this.markup = params.markup || this.markup;
		}
	}
}