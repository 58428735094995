export enum GroundApplicationType {
    AntiIce = '[GroundApplicationType] Anti-Icing',
    DeIce = '[GroundApplicationType] De-Icing',
    None = '[GroundApplicationType] None'
}

export namespace GroundApplicationType {
    export function toString(type: GroundApplicationType): string {
        switch (type) {
            case GroundApplicationType.AntiIce:
                return 'Anti-Icing';
            case GroundApplicationType.DeIce:
                return 'De-Icing';
            case GroundApplicationType.None:
                return 'None';
            default:
                return '';
        }
    }

    export const members: GroundApplicationType[] = [
        GroundApplicationType.AntiIce,
        GroundApplicationType.DeIce,
        GroundApplicationType.None
    ];
}
