import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'gm2-string-input-modal',
    templateUrl: './string-input-modal.component.html',
    styleUrls: ['./string-input-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StringInputModalComponent implements OnInit {

    public form: UntypedFormGroup = this._fb.group({
        str: ['', Validators.required],
    });

    public forceResponse$: BehaviorSubject<boolean> =
        new BehaviorSubject(false);

    constructor(
        private _fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<StringInputModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            forceResponse?: boolean,
            placeholder: string,
            message: string
        }
    ) {
        if (typeof data.forceResponse === 'boolean') {
            this.forceResponse$.next(true);
        }
    }

    public ngOnInit(): void {}

    public close(): void {
        this.dialogRef.close(false);
    }

    public confirm(): void {
        this.dialogRef.close(this.form.get('str').value);
    }
}
