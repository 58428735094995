export enum ServiceSource {
    Package = '[ServiceSource] Package',
    ClientService = '[ServiceSource] ClientServices',
}

export namespace ServiceSource {
    export function toString(type: ServiceSource): string {
        switch (type) {
            case ServiceSource.Package:
                return 'Package';
            case ServiceSource.ClientService:
                return 'Client Services';
            default:
                return '';
        }
    }

    export function toServiceSource(value: string): ServiceSource {
        switch (value) {
            case 'Package':
                return ServiceSource.Package;
            case 'Client Services':
                return ServiceSource.ClientService;
            default:
                return null;
        }
    }

    export const members: ServiceSource[] = [
        ServiceSource.Package,
        ServiceSource.ClientService,
    ];
}
