import {
    Component,
    OnInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    ViewChildren,
    QueryList,
    ComponentFactoryResolver,
    ChangeDetectorRef
} from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import {
    take,
    finalize,
    switchMap,
    takeUntil,
    map,
    startWith,
    debounceTime
} from 'rxjs/operators';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    UntypedFormControl,
    Validators
} from '@angular/forms';
import { FormBuilderService } from '../../services/form-builder.service';
import { AppDefinedFieldService } from '../../services/app-defined-field.service';
import { AppDefinedField } from '../../models/app-defined-field';
import { AppDefinedFieldTargetComponent } from '../app-defined-field-target/app-defined-field-target.component';
import {
    RfxForm,
    RfxFormField,
    RfxFormFieldConditionalAction,
    RfxFormFieldConditionRule,
    RfxFormFieldConditionRuleComparison,
    RfxFormFieldConditionRulesBoolean,
    RfxFormFieldType
} from '@refactor/common';
import { FormBuilderUIUtil } from '../../models/form-builder-ui-util';

@Component({
    selector: 'rfx-form-generator',
    templateUrl: './form-generator.component.html',
    styleUrls: ['./form-generator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormGeneratorComponent implements OnInit, AfterViewInit {
    public readonly RfxFormFieldType: typeof RfxFormFieldType = RfxFormFieldType;

    @Input('rfxForm$')
    public rfxForm$: Observable<RfxForm>;
    @Input('preview')
    public preview: boolean;
    @Output()
    public responseSuccess: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public responseFailure: EventEmitter<string> = new EventEmitter<string>();
    @ViewChildren(AppDefinedFieldTargetComponent)
    public appDefinedFields: QueryList<AppDefinedFieldTargetComponent>;

    public form: UntypedFormGroup = this._formBuilder.group({});
    public formConfirmFunction: (name: string) => Promise<boolean>;

    public formGroupInitialized$: BehaviorSubject<
        boolean
    > = new BehaviorSubject(false);
    public submitting$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * Async determination of field rendering
     */
    public showField$: BehaviorSubject<
        { [key: string]: boolean }[]
    > = new BehaviorSubject([]);

    private _onDestroy$: Subject<void> = new Subject();

    constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _formService: FormBuilderService,
        private readonly _appDefinedFieldService: AppDefinedFieldService,
        private readonly _componentFactoryResolver: ComponentFactoryResolver,
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _formBuilderUIUtil: FormBuilderUIUtil
    ) {}

    public async ngOnInit(): Promise<void> {
        await this.setupFormFields();
        await this.setupConfirmFunction();
        this.formGroupInitialized$.next(true);
    }

    public ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    public ngAfterViewInit(): void {
        this.appDefinedFields.changes
            .pipe(takeUntil(this._onDestroy$))
            .subscribe((fields: AppDefinedFieldTargetComponent[]) => {
                const map = this._appDefinedFieldService.getFields();
                for (const f of fields) {
                    const factory = this._componentFactoryResolver.resolveComponentFactory(
                        map[f.key]
                    );
                    f.viewContainerRef.clear();
                    const componentRef = f.viewContainerRef.createComponent<
                        AppDefinedField
                    >(factory);
                    componentRef.instance.formControl = this.form.controls[
                        f.fieldId
                    ] as UntypedFormControl;
                }
                this._changeDetectorRef.detectChanges();
            });
    }

    public async setupConfirmFunction(): Promise<void> {
        const rfxForm = await this.rfxForm$.pipe(take(1)).toPromise();
        const confirmFunctions = this._formBuilderUIUtil.getConfirmFunctions();
        this.formConfirmFunction =
            confirmFunctions[rfxForm.confirmFunctionKey];
    }

    public async setupFormFields(): Promise<void> {
        const rfxForm = await this.rfxForm$.pipe(take(1)).toPromise();

        for (const f of rfxForm.fields as RfxFormField<string>[]) {
            if (!RfxFormFieldType.muteFields.includes(f.type)) {
                this.form.addControl(
                    f._id as string,
                    new UntypedFormControl(
                        f.defaultValue ? f.defaultValue : null,
                        f.required
                            ? f.type === RfxFormFieldType.Checkbox
                                ? Validators.requiredTrue
                                : Validators.required
                            : null
                    )
                );
            }

            if (!this.preview) {
                this.showField$.next([
                    ...this.showField$.value,
                    { [f._id]: false }
                ]);

                this.form.valueChanges
                    .pipe(
                        startWith(null),
                        takeUntil(this._onDestroy$),
                        debounceTime(100)
                    )
                    .subscribe(_ => {
                        const showFieldArray = this.showField$.value;
                        showFieldArray[f._id] = this.showField(f);
                        this.showField$.next(showFieldArray);
                    });
            }
        }
    }

    /**
     * Run through conditions to see if this field should be
     * shown/hidden and enabled/disabled.
     */
    public showField(field: RfxFormField<string>): boolean {
        // If there are no conditions or if field hasn't loaded
        // yet, break out of function
        if (this.preview || !field || !field.condition) {
            return true;
        }

        const rules = field.condition.rules as RfxFormFieldConditionRule<
            string
        >[];
        const bool = field.condition.rulesBoolean;
        const action = field.condition.action;

        // Loop through rules and push true/false to rulesBoolArray array
        const rulesBoolArray: boolean[] = [];
        for (const rule of rules) {
            if (this.form.controls[rule.fieldId].disabled) {
                rulesBoolArray.push(false);
            } else {
                const fieldValue = this.form.controls[rule.fieldId].value;
                const ruleValue = rule.value;

                const ruleValueString =
                    ruleValue !== null && ruleValue !== undefined
                        ? ruleValue.toString()
                        : '';
                const fieldValueString =
                    fieldValue !== null && fieldValue !== undefined
                        ? fieldValue.toString()
                        : '';

                switch (rule.comparison) {
                    case RfxFormFieldConditionRuleComparison.Equals:
                        rulesBoolArray.push(
                            ruleValueString === fieldValueString
                        );
                        break;
                    case RfxFormFieldConditionRuleComparison.DoesNotEqual:
                        rulesBoolArray.push(
                            ruleValueString !== fieldValueString
                        );
                        break;
                    default:
                        break;
                }
            }
        }

        // Determine if the rules should resolve to true or false based
        // on the logicType (All or One)
        let rulesRes: boolean;
        if (bool === RfxFormFieldConditionRulesBoolean.And) {
            rulesRes = rulesBoolArray.every(val => val === true);
        } else if (bool === RfxFormFieldConditionRulesBoolean.Or) {
            rulesRes = rulesBoolArray.includes(true);
        }

        // Determine whether to show or hide based on the actionType
        // Also disable/enable field here to maintain proper validation
        const shouldShow =
            action === RfxFormFieldConditionalAction.ShowField
                ? rulesRes
                : !rulesRes;

        if (!RfxFormFieldType.muteFields.includes(field.type)) {
            if (shouldShow) {
                this.form.controls[field._id].enable({ emitEvent: false });
            } else {
                this.form.controls[field._id].disable({ emitEvent: false });
            }
        }
        return shouldShow;
    }

    public async onSubmit(): Promise<void> {
        if (!this.form.valid) {
            return;
        }

        // Cancel submission if confirmation was cancelled
        if (!!this.formConfirmFunction) {
            const formName = await this.rfxForm$
                .pipe(take(1), map(form => form.name))
                .toPromise();
            const confirmed = await this.formConfirmFunction(formName);
            if (!confirmed) {
                return;
            }
        }

        this.submitting$.next(true);

        const fieldValues: { [key: string]: string } = {};

        this.rfxForm$
            .pipe(
                finalize(() => this.submitting$.next(false)),
                map(form => {
                    for (const f of form.fields) {
                        if (!RfxFormFieldType.muteFields.includes(f.type)) {
                            const value = this.form.value[f._id as string];
                            fieldValues[f._id as string] =
                                value !== null && value !== undefined
                                    ? value.toString()
                                    : RfxFormFieldType.stringValueOfNull(
                                          f.type
                                      );
                        }
                    }
                    return form;
                }),
                switchMap(form =>
                    this._formService.submitResponse(form._id as string, {
                        fields: fieldValues
                    })
                )
            )
            .subscribe(
                id => {
                    this.responseSuccess.emit();
                },
                err => {
                    console.error(err);
                    this.responseFailure.emit(err.statusText);
                }
            );
    }
}
