export enum ContractType {
    Template = '[ContractType] Template',
    Applied = '[ContractType] Applied'
}

export namespace ContractType {
    export function toString(type: ContractType): string {
        switch (type) {
            case ContractType.Template:
                return 'Template';
            case ContractType.Applied:
                return 'Applied';
            default:
                return '';
        }
    }

    export const members: ContractType[] = [
        ContractType.Template,
        ContractType.Applied
    ];
}
