import { WorkOrderEquipmentUnit } from '@gm2/common';

export class WorkOrderEquipmentDto {
    public _id: string = '';
    public description: string = '';
    public quantity: number = 0;
    public units: WorkOrderEquipmentUnit;
    public price: number = 0;
    public markup: number = 0;

    constructor(params?: Partial<WorkOrderEquipmentDto>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.description = params.description || this.description;
            this.quantity = params.quantity || this.quantity;
            this.units = params.units || this.units;
            this.price = params.price || this.price;
            this.markup = params.markup || this.markup;
        }
    }
}
