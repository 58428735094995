import { WorkOrderLaborCompletionDto } from './WorkOrderLaborCompletionDto';

export class WorkOrderLaborDto {
    public _id: string = '';
    public description: string = '';
    public hours: number = 0;
    public laborCategoryId: string = '';
    public completionInfo: WorkOrderLaborCompletionDto;
    public markup: number = 0;

    constructor(params?: Partial<WorkOrderLaborDto>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.description = params.description || this.description;
            this.hours = params.hours || this.hours;
            this.laborCategoryId = params.laborCategoryId || this.laborCategoryId;
            this.completionInfo = !!params.completionInfo
                ? new WorkOrderLaborCompletionDto(params.completionInfo)
                : this.completionInfo;
            this.markup = params.markup || this.markup;
        }
    }
}
