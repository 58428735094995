import { LocationServiceTypeWithoutIds } from '@gm2/common';

export class LocationServiceType extends LocationServiceTypeWithoutIds {
    public _id: string;

    constructor(params?: Partial<LocationServiceType>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
        }
    }
}
