<h2 mat-dialog-title>Save {{ typename }}</h2>
<div mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <input matInput required placeholder="Name" formControlName="name" />
        </mat-form-field>
        <mat-form-field>
            <mat-select required formControlName="serviceTypeId">
                <mat-option
                        *ngFor="let serviceType of serviceTypes"
                        [value]="serviceType._id">
                    {{ serviceType.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select required placeholder="Category" formControlName="mappingCategoryId">
                <mat-option
                        *ngFor="let category of mappingCategories$ | async"
                        [value]="category._id">
                    {{ category.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field [class.hidden]="hideClassification$ | async">
            <mat-select
                required
                placeholder="Classification"
                formControlName="mappingClassification"
            >
                <mat-option
                    *ngFor="let classification of MappingClassification.members"
                    [value]="classification"
                >
                    {{ MappingClassification.toString(classification) }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="type === MappingType.Line">
            <input matInput type="number" placeholder="Width (inches)" formControlName="width" />
        </mat-form-field>
        <mat-form-field>
            <textarea matInput placeholder="Notes" formControlName="notes"></textarea>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onSave()">Save</button>
</div>
