import { BaseDoc } from '../base/base-doc';
import { AwardedBidWithoutIds } from './awarded-bid';
import { BidWithoutIds } from './bid';
import { LocationIdentityWithoutIds } from '../location/location-identity';
import { ContractIdentityWithoutIds } from '../contract/contract-identity';
import { RfpIdentityWithoutIds } from './rfp-identity';
import { ClientPricingWithoutIds } from './client-pricing';
import { RfpSnapShotWithoutIds } from './rfp-snap-shot';
import { FirstApprovalWithoutIds } from './first-approval';
import { CompanyIdentityWithoutIds } from '../company/company-identity';
import { isNull } from '../../utils';

export class RfpWithoutIds extends BaseDoc {
    public _id: any;
    public awardedBid: AwardedBidWithoutIds = null;
    public bids: BidWithoutIds[] = [];
    public clientCompanyIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public createdByIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public contractIdentity: ContractIdentityWithoutIds = new ContractIdentityWithoutIds();
    public locationIdentity: LocationIdentityWithoutIds = new LocationIdentityWithoutIds();
    public clientPricing: ClientPricingWithoutIds = new ClientPricingWithoutIds();
    public snapShot: RfpSnapShotWithoutIds = new RfpSnapShotWithoutIds();
    public firstApproval?: FirstApprovalWithoutIds = null;
    public requestedPriceLabel: string = '';
    public awardedBidHistory: AwardedBidWithoutIds[] = [];

    constructor(params?: Partial<RfpWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.requestedPriceLabel = params.requestedPriceLabel || this.requestedPriceLabel;

            this.awardedBid = !!params.awardedBid
                ? new AwardedBidWithoutIds(params.awardedBid)
                : this.awardedBid;
            this.bids = Array.isArray(params.bids)
                ? params.bids.map(bid => new BidWithoutIds(bid))
                : this.bids;
            this.clientCompanyIdentity = !!params.clientCompanyIdentity
                ? new CompanyIdentityWithoutIds(params.clientCompanyIdentity)
                : this.clientCompanyIdentity;
            this.contractIdentity = !!params.contractIdentity
                ? new ContractIdentityWithoutIds(params.contractIdentity)
                : this.contractIdentity;
            this.locationIdentity = !!params.locationIdentity
                ? new LocationIdentityWithoutIds(params.locationIdentity)
                : this.locationIdentity;

            this.clientPricing = !!params.clientPricing
                ? new ClientPricingWithoutIds(params.clientPricing)
                : this.clientPricing;

            this.snapShot = !!params.snapShot
                ? new RfpSnapShotWithoutIds(params.snapShot)
                : this.snapShot;

            this.firstApproval = !isNull(params.firstApproval)
                ? new FirstApprovalWithoutIds(params.firstApproval)
                : this.firstApproval;

            this.awardedBidHistory = Array.isArray(params.awardedBidHistory)
                ? params.awardedBidHistory.map(bid => new AwardedBidWithoutIds(bid))
                : this.awardedBidHistory;

            this.createdByIdentity = !!params.createdByIdentity
                ? new CompanyIdentityWithoutIds(params.createdByIdentity)
                : this.createdByIdentity;
        }
    }
}
