<button
    class="drop-button"
    mat-button
    [matMenuTriggerFor]="helpMenu"
>
    Help <mat-icon>help</mat-icon>
</button>
<mat-menu
    #helpMenu="matMenu"
>
    <button
        mat-menu-item
        *ngFor="let h of (availableHelp$ | async)"
        (click)="selectHelp(h)"
    >
        {{ h.label }}
    </button>
</mat-menu>
