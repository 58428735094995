<mat-form-field [appearance]="appearance">
    <mat-label>{{ placeholder }}</mat-label>
    <ng-container *ngIf="multi; else single">
        <mat-select
            [formControl]="fControl"
            [placeholder]="placeholder"
            multiple
        >
            <mat-option>
                <ngx-mat-select-search
                    [formControl]="searchControl"
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No match found"
                ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let o of (options$ | async)" [value]="o._id">
                {{ o.label }}
            </mat-option>
        </mat-select>
    </ng-container>
    <ng-template #single>
        <mat-select
            [formControl]="fControl"
            [placeholder]="placeholder"
        >
            <mat-option>
                <ngx-mat-select-search
                    [formControl]="searchControl"
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No match found"
                ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let o of (options$ | async)" [value]="o._id">
                {{ o.label }}
            </mat-option>
        </mat-select>
    </ng-template>
    <mat-error *ngIf="fControl.invalid">{{'VALIDATION.REQUIRED_ERROR'|translate}}</mat-error>
</mat-form-field>
