export enum RfxFormFieldType {
    Dropdown = '[RfxFormFieldType] dropdown',
    String = '[RfxFormFieldType] string',
    Number = '[RfxFormFieldType] number',
    Textbox = '[RfxFormFieldType] textbox',
    Radio = '[RfxFormFieldType] radio',
    Checkbox = '[RfxFormFieldType] checkbox',
    Title = '[RfxFormFieldType] title',
    Paragraph = '[RfxFormFieldType] paragraph',
    ApplicationDefined = '[RfxFormFieldType] applicationDefined'
}

export namespace RfxFormFieldType {
    export function toString(type: RfxFormFieldType): string {
        switch (type) {
            case RfxFormFieldType.Dropdown:
                return 'Dropdown';
            case RfxFormFieldType.String:
                return 'Short Text';
            case RfxFormFieldType.Number:
                return 'Number';
            case RfxFormFieldType.Textbox:
                return 'Paragraph Text';
            case RfxFormFieldType.Radio:
                return 'Radio Select';
            case RfxFormFieldType.Checkbox:
                return 'Checkbox';
            case RfxFormFieldType.Title:
                return 'Title';
            case RfxFormFieldType.Paragraph:
                return 'Paragraph';
            case RfxFormFieldType.ApplicationDefined:
                return 'Application Defined';
            default:
                return '';
        }
    }

    export const members: RfxFormFieldType[] = [
        RfxFormFieldType.Dropdown,
        RfxFormFieldType.String,
        RfxFormFieldType.Number,
        RfxFormFieldType.Textbox,
        RfxFormFieldType.Radio,
        RfxFormFieldType.Checkbox,
        RfxFormFieldType.Title,
        RfxFormFieldType.Paragraph,
        RfxFormFieldType.ApplicationDefined
    ];

    export const choicesRequired: RfxFormFieldType[] = [
        RfxFormFieldType.Dropdown,
        RfxFormFieldType.Radio,
    ];

    export const muteFields: RfxFormFieldType[] = [
        RfxFormFieldType.Title,
        RfxFormFieldType.Paragraph
    ];

    export function stringValueOfNull(type: RfxFormFieldType): string {
        switch (type) {
            case RfxFormFieldType.Dropdown:
            case RfxFormFieldType.String:
            case RfxFormFieldType.Number:
            case RfxFormFieldType.Textbox:
            case RfxFormFieldType.Radio:
            case RfxFormFieldType.ApplicationDefined:
                return '';
            case RfxFormFieldType.Checkbox:
                return 'false';
            default:
                return '';
        }
    }
}
