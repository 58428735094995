import { Injectable } from '@angular/core';
import {
    HttpService,
    Post,
    Put,
    MapClass,
    MapValue,
    Body,
    Get,
    Path,
    ResponseType
} from '@refactor/ngx/http';
import { Tag } from '../models';
import { Observable } from 'rxjs';
import { PaginatedList, GridParams } from '@refactor/common';

Injectable({
    providedIn: 'root'
});

@Injectable()
export class TagService extends HttpService {
    @Post('/tag')
    public createTag(@Body() body: Tag): Observable<void> {
        return null;
    }

    @Put('/tag/:id')
    public editTag(@Path('id') id: string, @Body() body: Tag): Observable<void> {
        return null;
    }

    @Post('/tag/list')
    @MapValue(
        res =>
            new PaginatedList<Tag>({
                count: res.count,
                docs: res.docs.map(d => new Tag(d))
            })
    )
    public getTagList(@Body() params: GridParams): Observable<PaginatedList<Tag>> {
        return null;
    }

    @Get('/tag/byCompany/:id')
    public getTags(@Path('id') id: string): Observable<Tag[]> {
        return null;
    }

    @Get('/tag/:id')
    public getTag(@Path('id') id: string): Observable<Tag[]> {
        return null;
    }

    @Put('/tag/delete')
    public deleteTags(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }

    @Post('/tag/csvExport', {
        responseType: ResponseType.Text
    })
    public tagCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }
}
