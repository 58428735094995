import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxCurrencyModule } from 'ngx-currency';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import './icons';
import { SecuredHeaderComponent } from './components/secured-header/secured-header.component';
import { SecuredSidebarComponent } from './components/secured-sidebar/secured-sidebar.component';
import { SecuredFooterComponent } from './components/secured-footer/secured-footer.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { BannerComponent } from './components/banner/banner.component';
import { NotesComponent } from './components/notes/notes.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RfxFormsModule } from '@refactor/ngx/forms';
import { RfxGridModule } from '@refactor/ngx/grid';
import { RfxHelpModule } from '@refactor/ngx/help';
import { RfxFileUploadModule } from '@refactor/ngx/file-upload';
import { ServicesListComponent } from './components/services-list/services-list.component';
import { MaterialsListComponent } from './components/materials-list/materials-list.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SpBidFlyoutComponent } from './components/sp-bid-flyout/sp-bid-flyout.component';
import { WorkorderFlyoutComponent } from './components/workorder-flyout/workorder-flyout.component';
import { GridFilterZipRadiusComponent } from './components/grid-filter-zip-radius/grid-filter-zip-radius.component';
import { GridFilterZipComponent } from './components/grid-filter-zip/grid-filter-zip.component';
import { GridFilterCompanyComponent } from './components/grid-filter-company/grid-filter-company.component';
import { GridFilterClientComponent } from './components/grid-filter-client/grid-filter-client.component';
import { GridFilterClientMultipleComponent } from './components/grid-filter-client-multiple/grid-filter-client-multiple.component';
import { GridFilterServiceMultipleComponent } from './components/grid-filter-service-multiple/grid-filter-service-multiple.component';
import { GridFilterServiceActivitiesServiceMultipleComponent } from './components/grid-filter-service-activities-service-multiple/grid-filter-service-activities-service-multiple.component';
import { GridFilterServiceTypeComponent } from './components/grid-filter-service-type/grid-filter-service-type.component';
import { GridFilterLocationComponent } from './components/grid-filter-location/grid-filter-location.component';
import { GridFilterServicePartnerMultipleComponent } from './components/grid-filter-service-partner-multiple/grid-filter-service-partner-multiple.component';
import { GridFilterLocationMultipleComponent } from './components/grid-filter-location-multiple/grid-filter-location-multiple.component';
import { GridFilterTagMultipleComponent } from './components/grid-filter-tag-multiple/grid-filter-tag-multiple.component';
import { GridFilterWorkOrderProgressMultipleComponent } from './components/grid-filter-workorder-progress-multiple/grid-filter-workorder-progress-multiple.component';
import { GridFilterFacilityManagerComponent } from './components/grid-filter-facility-manager/grid-filter-facility-manager.component';
import { GridFilterFacilityManagerRoleComponent } from './components/grid-filter-facility-manager-role/grid-filter-facility-manager-role.component';
import { GridFilterClearButton } from './components/grid-filter-clear-button/grid-filter-clear-button.component';
import { RfpPricingComponent } from './components/rfp-pricing/rfp-pricing.component';
import { TagsComponent } from './components/tags/tags.component';
import { DateRangeModalComponent } from './modals';
import { modals } from './modals';
import { UiCommonModule } from '@gm2/ui-common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ConfirmTimesheetEditComponent } from './modals';
import { TimesheetServiceListComponent } from './components/timesheet-service-list/timesheet-service-list.component';
import { TimesheetServiceListTimeAllocatorComponent } from './components/timesheet-service-list-time-allocator/timesheet-service-list-time-allocator.component';
import { TimesheetServiceMaterialComponent } from './components/timesheet-service-material/timesheet-service-material.component';
import { SearchSelectComponent } from './components/search-select/search-select.component';
import { DeclineWorkorderInvoiceModalComponent } from './modals';
import { RfxFormCrlFieldsComponent } from './components/rfx-form-crl-fields/rfx-form-crl-fields.component';
import { TranslateModule } from '@ngx-translate/core';
import { GridFormFilter } from './components/filter-form/filter-form.component';
import { GridFilterSearchButton } from './components/grid-filter-search-button/grid-filter-search-button.component';
import { MccColorPickerModule } from 'material-community-components/color-picker';
import { MatCarouselModule } from 'ng-mat-carousel';
import { GeospatialControlModalComponent } from './modals/geospatial-services-control-modal/geospatial-control-modal.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

export { GridFilterZipRadiusComponent };
export { GridFilterZipComponent };
export { DateRangeModalComponent };
export { WorkorderFlyoutComponent };
export { TimesheetServiceListComponent };
export { RfxFormCrlFieldsComponent };

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        MatTooltipModule,
        MatListModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatIconModule,
        MatFormFieldModule,
        RfxFileUploadModule,
        RfxFormsModule,
        RfxGridModule,
        MatTableModule,
        MatTabsModule,
        MatRadioModule,
        NgxCurrencyModule,
        NgxMatSelectSearchModule,
        UiCommonModule,
        PdfViewerModule,
        MccColorPickerModule,
        MatAutocompleteModule,
        MatCardModule,
        RfxHelpModule,
        // MatCarouselModule.forRoot(),
        TranslateModule,
        MatCarouselModule,
        MatSlideToggleModule,
    ],
    declarations: [
        SecuredHeaderComponent,
        SecuredSidebarComponent,
        SecuredFooterComponent,
        PageHeaderComponent,
        BannerComponent,
        NotesComponent,
        MaterialsListComponent,
        ServicesListComponent,
        SpBidFlyoutComponent,
        WorkorderFlyoutComponent,
        GridFilterZipRadiusComponent,
        GridFilterZipComponent,
        GridFilterCompanyComponent,
        GridFilterClientComponent,
        GridFilterClientMultipleComponent,
        GridFilterServiceTypeComponent,
        GridFilterLocationComponent,
        GridFilterServiceMultipleComponent,
        GridFilterServiceActivitiesServiceMultipleComponent,
        GridFilterServicePartnerMultipleComponent,
        GridFilterLocationMultipleComponent,
        GridFilterTagMultipleComponent,
        GridFilterWorkOrderProgressMultipleComponent,
        GridFilterFacilityManagerComponent,
        GridFilterFacilityManagerRoleComponent,
        GridFilterClearButton,
        RfpPricingComponent,
        GridFormFilter,
        GridFilterSearchButton,
        TagsComponent,
        ...modals,
        ConfirmTimesheetEditComponent,
        TimesheetServiceListComponent,
        TimesheetServiceListTimeAllocatorComponent,
        TimesheetServiceMaterialComponent,
        SearchSelectComponent,
        DeclineWorkorderInvoiceModalComponent,
        RfxFormCrlFieldsComponent,
    ],
    exports: [
        FontAwesomeModule,
        MatButtonModule,
        MatTooltipModule,
        MatListModule,
        SecuredHeaderComponent,
        SecuredSidebarComponent,
        SecuredFooterComponent,
        PageHeaderComponent,
        BannerComponent,
        NotesComponent,
        ServicesListComponent,
        MaterialsListComponent,
        SpBidFlyoutComponent,
        WorkorderFlyoutComponent,
        GridFilterZipRadiusComponent,
        GridFilterZipComponent,
        GridFilterCompanyComponent,
        GridFilterClientComponent,
        GridFormFilter,
        GridFilterClientMultipleComponent,
        GridFilterServiceTypeComponent,
        GridFilterLocationComponent,
        GridFilterSearchButton,
        GridFilterServiceMultipleComponent,
        GridFilterServiceActivitiesServiceMultipleComponent,
        GridFilterServicePartnerMultipleComponent,
        GridFilterLocationMultipleComponent,
        GridFilterTagMultipleComponent,
        GridFilterWorkOrderProgressMultipleComponent,
        GridFilterFacilityManagerComponent,
        GridFilterFacilityManagerRoleComponent,
        GridFilterClearButton,
        RfpPricingComponent,
        TagsComponent,
        DateRangeModalComponent,
        TimesheetServiceListComponent,
        TimesheetServiceListTimeAllocatorComponent,
        TimesheetServiceMaterialComponent,
        SearchSelectComponent,
        RfxFormCrlFieldsComponent,
        GeospatialControlModalComponent,
    ]
})
export class UiLibraryModule {}
