import { ServiceClassification, ServiceType } from '@gm2/api-common';
import { ServiceWithoutIds } from '../service/service';

export class ClientCompantServiceWithoutIds {
    public _id: any;
    public name: string;
    public description: string;
    public serviceClassification: ServiceClassification;
    public serviceTypeId: any;
    public material: string;
    public services: ServiceWithoutIds[] = [];

    constructor(params?: Partial<ClientCompantServiceWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.description = params.description || this.description;
            this.serviceClassification = params.serviceClassification || this.serviceClassification;
            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId
            this.material = params.material || this.material
        }
    }
}
