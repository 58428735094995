import { ApproveType, CompanyServiceBillingType } from '../../enums';
import { isNull } from '../../utils';
import { ServiceWithoutIds } from '../service/service';

export class CompanyServiceWithoutIds {
    public _id: any;
    public name: string;
    public description: string;
    public price: number;
    public billingType: CompanyServiceBillingType;
    public approveStatus: ApproveType = null;
    public approveOrRejectDate: Date;
    public material: string;
    public services: ServiceWithoutIds[] = [];
    public serviceTypeId?: any;
    public serviceTypeName?: string;
    public contractName?: string;
    public contractId?: string;

    constructor(params?: Partial<CompanyServiceWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.description = params.description || this.description;
            this.price = !isNull(params.price) ? params.price : this.price;
            this.billingType = params.billingType || this.billingType;
            this.approveStatus = params.approveStatus || this.approveStatus;
            this.approveOrRejectDate = params.approveOrRejectDate || this.approveOrRejectDate;
            this.material = params.material || this.material;
            this.services = Array.isArray(params.services)
                ? params.services.map(l => new ServiceWithoutIds(l))
                : this.services;
            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId;
            this.serviceTypeName = params.serviceTypeName || this.serviceTypeName;
            this.contractName = params.contractName || this.contractName;
            this.contractId = params.contractId || this.contractId;
        }
    }
}
