import { Sort } from './sort';
import { Pagination } from './pagination';
import { GridParamsModel } from '../../interface';

export class GridParams {
    public sort: Sort = new Sort();
    public pagination: Pagination = new Pagination();
    public filters: { [key: string]: any } = {};

    constructor(params?: Partial<GridParamsModel>) {
        if (!!params) {
            this.sort = new Sort(params.sort);
            this.pagination = new Pagination(params.pagination);
            this.filters = !!params.filters ? params.filters : this.filters;
        }
    }
}
