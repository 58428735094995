import { Injectable } from '@angular/core';
import {
    HttpService,
    Post,
    MapClass,
    MapValue,
    Body,
    Get,
    Path,
    Delete,
    Put,
    ResponseType
} from '@refactor/ngx/http';
import { Service, ServiceActivity, ServiceDto, ServiceChildrenDto } from '../models';
import { Observable } from 'rxjs';
import { PaginatedList, GridParams } from '@refactor/common';

@Injectable({
    providedIn: 'root'
})
export class ServiceService extends HttpService {
    @Post('/service/list')
    @MapValue(
        res =>
            new PaginatedList<Service>({
                count: res.count,
                docs: res.docs.map(d => new Service(d))
            })
    )
    public getServiceList(@Body() params: GridParams): Observable<PaginatedList<Service>> {
        return null;
    }

    @Post('/service/serviceActivity/list')
    @MapValue(
        res =>
            new PaginatedList<ServiceActivity>({
                count: res.count,
                docs: res.docs.map(d => new ServiceActivity(d))
            })
    )
    public getServiceActivitiesList(
        @Body() params: GridParams
    ): Observable<PaginatedList<ServiceActivity>> {
        return null;
    }

    @Post('/service/serviceActivity/list/csvExport', {
        responseType: ResponseType.Text
    })
    public getServicesFilteredCsvExport(@Body() body: GridParams): Observable<string> {
        return null;
    }

    @Post('/service')
    public createService(@Body() body: ServiceDto): Observable<any> {
        return null;
    }

    @Get('/service/')
    public getServices(): Observable<Service[]> {
        return null;
    }

    @Post('/service/serviceActivity/serviceList')
    public getServicesFiltered(
        @Body()
        body: {
            serviceTypeId: string;
            timeFrame: string;
            clientIds: string[];
            locationIds: string[];
            strategicAccountManagerId: string;
            productionManagerId: string;
        }
        // @Body('serviceTypeId') serviceTypeId: { value: string },
        // @Body('timeFrame') timeFrame: { value: string },
        // @Body('clientIds') clientIds: { values: string[] },
        // @Body('locationIds') locationIds: { values: string[] },
        // @Body('strategicAccountManagerId') strategicAccountManagerId: { value: string },
        // @Body('productionManagerId') productionManagerId: { value: string }
    ): Observable<Service[]> {
        return null;
    }

    @Get('/service/:serviceId')
    @MapClass(Service)
    public getService(@Path('serviceId') id: string): Observable<Service> {
        return null;
    }

    @Post('/service/delete')
    public deleteServices(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }

    @Put('/service/')
    @MapClass(Service)
    public editService(
        @Path('packageId') id: string,
        @Body() body: ServiceDto
    ): Observable<Service> {
        return null;
    }

    @Post('/service/:serviceId/addChild')
    public createChildService(
        @Path('serviceId') id: string,
        @Body() body: ServiceChildrenDto
    ): Observable<any> {
        return null;
    }

    @Put('/service/:serviceId/editChild/:childId')
    @MapClass(ServiceChildrenDto)
    public editChildService(
        @Path('serviceId') serviceId: string,
        @Path('childId') childId: string,
        @Body() body: ServiceChildrenDto
    ): Observable<ServiceChildrenDto> {
        return null;
    }

    @Post('/service/:serviceId/removeChild/:childId')
    public deleteChildService(
        @Path('serviceId') serviceId: string,
        @Path('childId') childId: string
    ): Observable<void> {
        return null;
    }

    @Post('/service/csvExport', {
        responseType: ResponseType.Text
    })
    public serviceCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }

    @Post('/service/check/byName')
    public checkByName(@Body() data: { name: string, companyId: string }): Observable<boolean> {
        return null;
    }

    @Post('/service/create/bulk')
    public createServices(@Body() data: { services: any[], landscaperId: string }): Observable<string> {
        return null;
    }
}
