export enum LocationDayOfService {
    Sunday = '[LocationDayOfService] Sunday',
    Monday = '[LocationDayOfService] Monday',
    Tuesday = '[LocationDayOfService] Tuesday',
    Wednesday = '[LocationDayOfService] Wednesday',
    Thursday = '[LocationDayOfService] Thursday',
    Friday = '[LocationDayOfService] Friday',
    Saturday = '[LocationDayOfService] Saturday'
}

export const dayOfServiceMap: { [day: string]: LocationDayOfService } = {
    'Sunday': LocationDayOfService.Sunday,
    'Monday': LocationDayOfService.Monday,
    'Tuesday': LocationDayOfService.Tuesday,
    'Wednesday': LocationDayOfService.Wednesday,
    'Thursday': LocationDayOfService.Thursday,
    'Friday': LocationDayOfService.Friday,
    'Saturday': LocationDayOfService.Saturday,
};

export namespace LocationDayOfService {
    export function toString(type: LocationDayOfService): string {
        // console.log('test', type);
        switch (type) {
            case LocationDayOfService.Sunday:
                return 'Sunday';
            case LocationDayOfService.Monday:
                return 'Monday';
            case LocationDayOfService.Tuesday:
                return 'Tuesday';
            case LocationDayOfService.Wednesday:
                return 'Wednesday';
            case LocationDayOfService.Thursday:
                return 'Thursday';
            case LocationDayOfService.Friday:
                return 'Friday';
            case LocationDayOfService.Saturday:
                return 'Saturday';
            default:
                return '';
        }
    }

    export const members: LocationDayOfService[] = [
        LocationDayOfService.Sunday,
        LocationDayOfService.Monday,
        LocationDayOfService.Tuesday,
        LocationDayOfService.Wednesday,
        LocationDayOfService.Thursday,
        LocationDayOfService.Friday,
        LocationDayOfService.Saturday,
    ];
}
