import { CompanyPriceWithoutIds } from '@gm2/common';

export class CompanyPrice extends CompanyPriceWithoutIds {
    public id: string = '';

    constructor(params?: Partial<CompanyPrice>) {
        super(params);
        if (!!params) {
            this.id = params._id || this.id;
        }
    }
}
