import { ApproveType } from '@gm2/common';

export class MaterialApproveDto {
    public materialId: string;
    public companyId: string;
    public approveStatus: ApproveType;
    public rejectReason: string;

    constructor(targetId: string, companyId: string, approveStatus: ApproveType, rejectReason?: string) {
        this.materialId = targetId;
        this.companyId = companyId;
        this.approveStatus = approveStatus;
        this.rejectReason = !!rejectReason ? rejectReason : this.rejectReason;
    }
}
