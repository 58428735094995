import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Subject, ReplaySubject, BehaviorSubject, of } from 'rxjs';
import { takeUntil, tap, filter, take, switchMap, catchError, finalize } from 'rxjs/operators';
import {
    WorkOrderPhaseWithWorkOrderDetails,
    WorkorderInvoiceService,
    WorkOrderWorkCategory,
    ToastService,
    InvoiceRejectionReason,
    IWorkOrderInvoiceRejectDto
} from '@gm2/ui-common';

interface IDialogData {
    invoiceId: string;
    phases: WorkOrderPhaseWithWorkOrderDetails[];
}

@Component({
    selector: 'gm2-decline-workorder-invoice-modal',
    templateUrl: './decline-workorder-invoice-modal.component.html',
    styleUrls: ['./decline-workorder-invoice-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeclineWorkorderInvoiceModalComponent implements OnInit {
    public readonly WorkOrderWorkCategory: typeof WorkOrderWorkCategory = WorkOrderWorkCategory;
    public readonly InvoiceRejectionReason: typeof InvoiceRejectionReason = InvoiceRejectionReason;

    public form: UntypedFormGroup = this._formBuilder.group({
        reason: [null, Validators.required],
        message: [null, Validators.required],
        rejectedPhaseIds: [null, Validators.required]
    });
    public uxForm: UntypedFormGroup = this._formBuilder.group({});

    public invoiceId$: ReplaySubject<string> =
        new ReplaySubject(1);
    public phases$: ReplaySubject<WorkOrderPhaseWithWorkOrderDetails[]> =
        new ReplaySubject(1);

    public submitting$: BehaviorSubject<boolean> =
        new BehaviorSubject(false);
    private _onSubmit$: Subject<void> =
        new Subject();
    private _onDestroy$: Subject<void> =
        new Subject();

    constructor(
        private readonly _dialogRef: MatDialogRef<DeclineWorkorderInvoiceModalComponent>,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _workOrderInvoiceService: WorkorderInvoiceService,
        private readonly _toastService: ToastService,
        @Inject(MAT_DIALOG_DATA)
        private readonly _data: IDialogData
    ) {
        this.invoiceId$.next(_data.invoiceId);
        this.phases$.next(_data.phases);
    }

    public ngOnInit(): void {
        this._onSubmit$.pipe(
            takeUntil(this._onDestroy$),
            tap(() => {
                this.form.markAllAsTouched();
                this.form.updateValueAndValidity();
            })
        ).subscribe();

        this.uxForm.valueChanges.pipe(
            takeUntil(this._onDestroy$),
            tap(val => {
                const rejectedIds = [];
                Object.keys(val).forEach(k => {
                    if (val[k]) {
                        rejectedIds.push(k);
                    }
                });
                this.form.controls.rejectedPhaseIds.setValue(rejectedIds);
            })
        ).subscribe();

        this.phases$.pipe(
            takeUntil(this._onDestroy$),
            filter(phases => phases.length > 0),
            tap(phases => {
                for (const p of phases) {
                    this.uxForm.addControl(p.id, new UntypedFormControl(false));
                }
            })
        ).subscribe();
    }

    public ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    public selectAll(): void {
        this.phases$.pipe(
            take(1),
            tap(phases =>
                Object.keys(this.uxForm.controls).forEach(k => {
                    this.uxForm.controls[k].setValue(true);
                })
            )
        ).subscribe();
    }

    public cancel(): void {
        this._dialogRef.close(false);
    }

    public onSubmit(): void {
        this._onSubmit$.next();
        if (this.form.value.rejectedPhaseIds.length === 0) {
            this._toastService.error('Select at least one phase');
            return;
        }
        if (this.form.invalid) {
            this._toastService.error('Check form for errors');
            return;
        }
        this.invoiceId$.pipe(
            take(1),
            tap(() => this.submitting$.next(true)),
            switchMap(id =>
                this._workOrderInvoiceService
                    .rejectInvoice(id, this.form.value)
            ),
            tap(() => {
                this._toastService.success('Rejected');
                this._dialogRef.close(true);
            }),
            finalize(() => this.submitting$.next(false)),
            catchError(err => {
                console.error(err);
                this._toastService.error(err);
                return of(null);
            })
        ).subscribe();
    }
}
