import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface IDialogData {
    text?: string;
    confirmation?: string;
}

@Component({
    selector: 'gm2-confirm-delete-modal',
    templateUrl: './confirm-delete-modal.component.html',
    styleUrls: ['./confirm-delete-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDeleteModalComponent implements OnInit {
    public text: string = 'Delete';
    public confirmation: string;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDeleteModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: IDialogData
    ) {
        this.text = data.text || this.text;
        this.confirmation = data.confirmation || this.text;
    }

    ngOnInit(): void {}

    public close(confirmed: boolean): void {
        this.dialogRef.close(confirmed);
    }
}
