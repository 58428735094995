export enum InvoiceType {
    Normal = '[InvoiceType] normal',
    Monthly = '[InvoiceType] monthly',
    AutoGeneratedMonthly = '[InvocieType] autoGeneratedMonthly'
}

export namespace InvoiceType {
    export function toString(type: InvoiceType): string {
        switch (type) {
            case InvoiceType.Normal:
                return 'Normal';
            case InvoiceType.Monthly:
                return 'Monthly';
            case InvoiceType.AutoGeneratedMonthly:
                return 'Auto Generated Monthly';
            default:
                return '';
        }
    }

    export const members: InvoiceType[] = [
        InvoiceType.Normal,
        InvoiceType.Monthly,
        InvoiceType.AutoGeneratedMonthly
    ];

}
