import { addMonths } from 'date-fns';
import { WorkOrderInvoiceStatus } from '../../enums';

export function canEditWorkOrderInvoice(invoicestatus: WorkOrderInvoiceStatus): boolean {
    return (
        invoicestatus === WorkOrderInvoiceStatus.InProgress ||
        invoicestatus === WorkOrderInvoiceStatus.Rejected
    );
}

export function canDeleteWorkOrderInvoice(invoicestatus: WorkOrderInvoiceStatus): boolean {
    // the logic is the same for determining if you can edit or delete
    return canEditWorkOrderInvoice(invoicestatus);
}

export function getWorkOrderInvoiceAvailableWorkOrderMaxStartDate(): Date {
    const maxNumberOfMonthsInPast = 3;
    return addMonths(new Date(), -maxNumberOfMonthsInPast);
}
