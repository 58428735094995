import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IStringFilter, SortDirection, Sort, RfxHelp } from '@refactor/common';
import { RfxGridDataSource, RfxGridFilterSelectOption } from '@refactor/ngx/grid';
import { RfxHelpService } from '../../services/rfx-help.service';

interface RfxHelpFilters {
    searchTerm: IStringFilter
}

@Component({
  selector: 'rfx-help-list',
  templateUrl: './rfx-help-list.component.html',
  styleUrls: ['./rfx-help-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RfxHelpListComponent implements OnInit {
public gridSource: RfxGridDataSource<RfxHelp, RfxHelpFilters>;

    public createUrl$: Observable<string> = this._activatedRoute.data.pipe(
        map(data => data.createUrl)
    );
    public editUrl$: Observable<string> = this._activatedRoute.data.pipe(
        map(data => data.editUrl)
    );

    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _rfxRoleService: RfxHelpService
    ) {}

    ngOnInit(): void {
        this.gridSource = new RfxGridDataSource<RfxHelp, RfxHelpFilters>([], {
            queryFn: [this._rfxRoleService, 'getRfxHelpList'],
            sort: new Sort({
                field: 'name',
                sortOrder: SortDirection.Ascending
            })
        });
    }

    public deleteItem(item: RfxHelp): void {
        this.deleteItems([item]);
    }

    public deleteItems(items: RfxHelp[]): void {
        const ids = items.map(h => h._id);
        this._rfxRoleService
            .deleteRfxHelp(ids)
            .subscribe(
                res => {
                    this.gridSource.updateData();
                },
                error => {
                    console.error(error);
                    this.gridSource.updateData();
                }
            );
    }

    public async openItem(item: RfxHelp): Promise<void> {
        const editUrl = await this.editUrl$.pipe(take(1)).toPromise();
        this._router.navigateByUrl(`${editUrl}/${item._id}`);
    }
}
