import { UserProfileWithoutIds } from '../user/user-profile';
import { BaseDoc } from '../base/base-doc';
import { MediaCollectionName, MediaDocumentType } from '../../enums';
import { BaseMediaDocumentWithoutIds } from './base-media-document';

export class MediaWithoutIds extends BaseDoc {
    public _id: any;
    public collection: MediaCollectionName;
    public collectionId: any;
    public latest: BaseMediaDocumentWithoutIds;
    public history: BaseMediaDocumentWithoutIds[] = [];

    constructor(params?: Partial<MediaWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.collection = params.collection || this.collection;
            this.collectionId = params.collectionId || this.collectionId;
            this.latest = (!!params.latest) ? new BaseMediaDocumentWithoutIds(params.latest) : this.latest;
            this.history = Array.isArray(params.history)
                ? params.history.map(h => new BaseMediaDocumentWithoutIds(h))
                : this.history;
        }
    }
}
