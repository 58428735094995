import { Injectable } from '@angular/core';
import { HttpService, Get, Post, Body, ResponseType, Header } from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { CompanyProfile, User } from '../models';
import { ContactType, CompanyServiceArea } from '@gm2/common';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends HttpService {
    @Post('/auth/login', {
        responseType: ResponseType.Text
    })
    public tryLogin(
        @Body('email') email: string,
        @Body('password') password: string
    ): Observable<string> {
        return null;
    }

    @Post('/auth/loginWithRefresh')
    public tryLoginWithRefresh(
        @Body('email') email: string,
        @Body('password') password: string
    ): Observable<{ accessToken: string; refreshToken: string }> {
        return null;
    }

    @Get('/auth/timesheetRefreshToken', { responseType: ResponseType.Text })
    public getTimesheetRefreshToken(): Observable<string> {
        return null;
    }

    @Get('/auth/refresh', { responseType: ResponseType.Text })
    public refresh(
        @Header('Authorization') token: string
    ): Observable<string> {
        return null;
    }

    @Post('/auth/forgotPassword')
    public forgotPassword(@Body('email') email: string): Observable<boolean> {
        return null;
    }

    @Post('/auth/resetPassword')
    public resetPassword(
        @Header('Authorization') token: string,
        @Body('password') newPassword: string
    ): Observable<boolean> {
        return null;
    }

    @Post('/registration/servicePartner')
    public registerServicePartner(
        @Body('companyProfile') companyProfile: CompanyProfile,
        @Body('companyPreferredContact') companyPreferredContact: ContactType,
        @Body('companyServicedZipCodes') companyServicedZipCodes: Array<CompanyServiceArea>,
        @Body('companyUser') companyUser: any,
        @Body('inviteId') inviteId: string,
    ): Observable<any> {
        return null;
    }
}
