import { LocationOperationHours } from './location-operation-hours';
import { LocationDayOfService, LocationServiceInterval, Month } from '../../enums';
import { isNull } from '../../utils';

export class LocationCustom {
    public quickbooksName: string = '';
    public quickbooksClass: string = '';
    public costCenter: string = '';
    public operationHours: LocationOperationHours = new LocationOperationHours();
    public serviceDay: LocationDayOfService;
    public serviceInterval: LocationServiceInterval;
    public serviceMonths: Month[] = [];
    public smartNumber: string = '';

    constructor(params?: Partial<LocationCustom>) {
        if (!!params) {
            this.quickbooksName = params.quickbooksName || this.quickbooksName;
            this.quickbooksClass = params.quickbooksClass || this.quickbooksClass;
            this.costCenter = params.costCenter || this.costCenter;
            this.serviceDay = params.serviceDay || this.serviceDay;
            this.serviceInterval = !isNull(params.serviceInterval)
                ? params.serviceInterval
                : this.serviceInterval;
            this.operationHours = !!params.operationHours
                ? new LocationOperationHours(params.operationHours)
                : this.operationHours;
            this.serviceMonths = params.serviceMonths || this.serviceMonths;
            this.smartNumber = params.smartNumber || this.smartNumber;
        }
    }
}
