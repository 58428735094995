import { ServiceChildSettings } from './service-child-settings';

export class ServiceChildWithoutIds {
    public _id: any;
    public name: string;
    public description: string;
    public settings: ServiceChildSettings;

    constructor(params?: Partial<ServiceChildWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.description = params.description || this.description;
            this.settings = !!params.settings
                ? new ServiceChildSettings(params.settings)
                : this.settings;
        }
    }
}
