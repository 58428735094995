export enum AssigneeType {
    Onegm = '[AssigneeType] Onegm',
    ServicePartner = '[AssigneeType] ServicePartner'
}

export namespace AssigneeType {
    export function toString(type: AssigneeType): string {
        switch (type) {
            case AssigneeType.Onegm:
                return 'ONE GM Crew';
            case AssigneeType.ServicePartner:
                return 'Service Partner';
            default:
                return '';
        }
    }

    export function toAssigneeType(type: string): AssigneeType {
        switch (type) {
            case 'Internal':
                return AssigneeType.Onegm;
            case 'External':
                return AssigneeType.ServicePartner;
            default:
                return null;
        }
    }

    export const members: AssigneeType[] = [
        AssigneeType.Onegm,
        AssigneeType.ServicePartner
    ];
}
