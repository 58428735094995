<form [formGroup]="form" *ngIf="(rfp$ | async) as rfp" (ngSubmit)="submit(rfp)">
    <p class="contract-owner" *ngIf="externalContractOwner$ | async as externalContractOwner">
        <span>Landscaper: </span>
        <span> {{ externalContractOwner?.profile?.name }}</span>
    </p>
    <p class="monthly-hint" *ngIf="isMonthly(rfp, true)">
        {{ rfp.locationIdentity.name }} requires all bids to be placed on a monthly basis. Please
        enter monthly pricing on this set of services.
        <span class="heavy"
            >Months Required:
            <!-- Show months --></span
        >
    </p>
    <p class="monthly-hint" *ngIf="isPerEvent(rfp, true)">
        {{ rfp.locationIdentity.name }} requires all bids to be placed on a per push basis. Please
        enter per push pricing on this set of services.
    </p>
    <p class="monthly-hint" *ngIf="isHourly(rfp, true)">
        {{ rfp.locationIdentity.name }} requires all bids to be placed on a hourly basis. Please
        enter hourly pricing on this set of services.
    </p>
    <div class="description-buttons">
        <a mat-stroked-button [routerLink]="'/operations/location/map/' + rfp.locationIdentity._id">
            Site Engineering Plan
        </a>
        <button mat-stroked-button type="button" (click)="showSpecDocs(rfp.contractIdentity._id)">
            Specification Documents
        </button>
    </div>
    <h2>
        Services to be provided
        <span class="hint">
            (hover over service for detailed explanation)
        </span>
    </h2>
    <!-- Services -->
    <mat-list>
        <ng-container *ngFor="let service of getAllServices(rfp); trackBy: trackByServices">
            <mat-list-item [matTooltip]="service.description" formArrayName="services">
                <ng-template [ngIf]="!isMonthly(rfp) && service.formGroupIndex !== undefined">
                    <mat-form-field [floatLabel]="'never'" [formGroupName]="service.formGroupIndex">
                        <span matPrefix>$ &nbsp;</span>
                        <input
                            matInput
                            type="number"
                            placeholder="Amount"
                            formControlName="amount"
                            required
                        />
                        <mat-error [rfxError]="servicesFormArray.at(service.formGroupIndex).get('amount')"></mat-error>
                    </mat-form-field>
                    &nbsp;
                </ng-template>
                <span   class="service-name"
                        [class.bold]="service.isParent"
                        [class.indent]="service.formGroupIndex === undefined && service.isChild">
                    {{ service.name }}
                </span>
            </mat-list-item>
            <mat-list-item *ngIf="service.hasChildren" class="hint">
                {{ service.name }} includes the following services:
            </mat-list-item>
                <!-- Material -->
    <mat-list class="material-list">
        <button
            *ngIf="hasMaterialCheck(service.formGroupIndex, service)"
            mat-button
            type="button"
            (click)="addMaterialToList(service.formGroupIndex)"
            [disabled]="(disableActions$ | async) || !!this.materialsInputStatus$"
        >
        <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
            Add Material
        </button>
        <a href="/profile/material"
            mat-button color="primary"
            *ngIf="hasMaterialCheck(service.formGroupIndex, service)">
            My Material Pricing
        </a>
        <form *ngIf="hasMaterialCheck(service.formGroupIndex, service)" [formGroup]="materialsForm" class="material-container">
            <div formArrayName="materialsList_{{service.formGroupIndex}}">
              <div *ngFor="let material of getDynamicMaterialsList(service.formGroupIndex); let i = index;" [formGroupName]="i" class="material-list__item">
                <!-- Material: <mat-select
                    required
                    formControlName="materialName"
                    (selectionChange)="onMaterialSelect($event, service.formGroupIndex, i)"
                >
                    <mat-option *ngFor="let m of (companyMaterials$ | async)" [value]="m.name">
                        {{ m.name }}
                    </mat-option>
                </mat-select> -->
                <mat-form-field class="sort-select">
                    <mat-label>Material:</mat-label>
                    <mat-select
                        required
                        formControlName="materialName"
                        (selectionChange)="onMaterialSelect($event, service.formGroupIndex, i)"
                        matNativeControl
                        name="sort"
                        [disabled]="!!this.materialsInputStatus$"
                        >
                        <mat-option *ngFor="let m of (companyMaterials$ | async)" [value]="m.name">
                            {{ m.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field [floatLabel]="'never'">
                    <input [readonly]="this.materialsInputStatus$" matInput (keypress)="numberOnlyForPrice($event)" placeholder="Price" type="text" formControlName="materialPrice">
                </mat-form-field>
                <p class="material-price">/ <input disabled type="text" formControlName="measurement"></p>
                <input hidden formControlName="activeIngredient">
                <input hidden formControlName="description">
                <button [disabled]="!!this.materialsInputStatus$" (click)="removeMaterial(i, service.formGroupIndex)" class="remove-btn">
                    <fa-icon [icon]="['far', 'trash']"></fa-icon>
                </button>
              </div>
            </div>
        </form>
    </mat-list>
        </ng-container>
    </mat-list>
    <h2>
        {{ BillingType.toString(getBillingType(rfp)) }} Contract
        <span class="hint">
            Contract Ends:
            {{ rfp.contractIdentity.contractDates.endDate | date: 'shortDate' }}
        </span>
    </h2>
    <ng-container *ngIf="contractIdentity$ | async as contractIdentity">
        <span *ngIf="!!contractIdentity?.name">
            <span *ngIf="(awardedStatus$ | async) === 'awarded'">
                RFP Name:
                {{contractIdentity.name }}
            </span>
        </span>
    </ng-container>

    <mat-form-field *ngIf="isMonthly(rfp)">
        <span matPrefix>$ &nbsp;</span>
        <input
            matInput
            type="number"
            placeholder="Monthly Amount"
            formControlName="amount"
            required
        />
    </mat-form-field>
    <br>
    <p *ngIf="isMonthly(rfp)">*Material price is included in monthly price</p>
    <!-- Don't allow different billing types on monthly contracts -->
    <div *ngIf="!isMonthly(rfp, true)">
        <p>Click here if you prefer to provide either:</p>
        <div class="description-buttons">
            <button
                mat-stroked-button
                type="button"
                *ngIf="!isMonthly(rfp)"
                (click)="overrideBillingType(rfp, BillingType.Monthly)"
            >
                Seasonal Bid
            </button>
            <button
                mat-stroked-button
                type="button"
                *ngIf="!isPerEvent(rfp)"
                (click)="overrideBillingType(rfp, BillingType.PerEvent)"
            >
                Per Push Based Pricing
            </button>
            <button
                mat-stroked-button
                type="button"
                *ngIf="!isHourly(rfp)"
                (click)="overrideBillingType(rfp, BillingType.Hourly)"
            >
                Hourly Based Pricing
            </button>
        </div>
    </div>
    <ng-container *ngIf="(disabled$ | async); else submitbid">
        <p><b>No further action is required at this point.</b></p>
    </ng-container>
    <ng-template #submitbid>
        <p>
            By checking this box you agree to the specifications and
            <a>site engineering plan</a> for this site, and also agree to maintain the pricing as
            submitted for the duration of the contract period listed above. If awarded this site you
            will receive the contract and agree to review and return it in a timely manner.
        </p>
        <mat-checkbox formControlName="agreement" required>
            I Agree
        </mat-checkbox>
        <button mat-flat-button type="submit" color="primary" class="pull-right">
            Submit Bid
        </button>
    </ng-template>
    <div class="clear"></div>
</form>
