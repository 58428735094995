import { parseISO } from 'date-fns';

export function parseISODate(date: string | number | Date): Date | null {
    if (!date) {
        return null;
    } else if (typeof date === 'string') {
        return parseISO(date);
    } else if (typeof date === 'number' || date instanceof Date) {
        return new Date(date);
    } else {
        return null;
    }
}

export function getOrdinal(date: Date): string {
    const day = date.getDate();
    const suffixes = ['th', 'st', 'nd', 'rd'];
    // tslint:disable-next-line: no-magic-numbers
    const relevantDigits = day < 30 ? day % 20 : day % 30;
    const suffixRange = 3;
    const suffix = relevantDigits <= suffixRange ? suffixes[relevantDigits] : suffixes[0];
    return suffix;
}
