export enum ApproveType {
    ApprovalRequested = '[ApproveType] ApprovalRequested',
    Approved = '[ApproveType] Approved',
    Disapproved = '[ApproveType] Disapproved'
}

export namespace ApproveType {
    export function toString(type: ApproveType): string {
        switch (type) {
            case ApproveType.ApprovalRequested:
                return 'Pending Approval';
            case ApproveType.Approved:
                return 'Approved';
            case ApproveType.Disapproved:
                return 'Disapproved';
            default:
                return '';
        }
    }

    export const members: ApproveType[] = [
        ApproveType.ApprovalRequested,
        ApproveType.Approved,
        ApproveType.Disapproved
    ];
}
