export enum WorkOrderPriority {
    Routine = '[WorkOrderPriority] Routine',
    Priority = '[WorkOrderPriority] Priority',
    Urgent = '[WorkOrderPriority] Urgent',
    Emergency = '[WorkOrderPriority] Emergency',
}

export namespace WorkOrderPriority {
    export function toString(type: WorkOrderPriority): string {
        switch (type) {
            case WorkOrderPriority.Routine:
                return 'Routine';
            case WorkOrderPriority.Priority:
                return 'Priority';
            case WorkOrderPriority.Urgent:
                return 'Urgent';
            case WorkOrderPriority.Emergency:
                return 'Emergency';
            default:
                return '';
        }
    }

    export const members: WorkOrderPriority[] = [
        WorkOrderPriority.Routine,
        WorkOrderPriority.Priority,
        WorkOrderPriority.Urgent,
        WorkOrderPriority.Emergency
    ];
}
