export enum WorkOrderReportedBy {
    ServicePartner = '[WorkOrderReportedBy] ApprovalRequested',
    FacilityManager = '[WorkOrderReportedBy] FacilityManager',
    LocationManager = '[WorkOrderReportedBy] LocationManager',
    OneGMRep = '[WorkOrderReportedBy] OneGMRep',
    CallCenter = '[WorkOrderReportedBy] CallCenter'
}

export namespace WorkOrderReportedBy {
    export function toString(type: WorkOrderReportedBy): string {
        switch (type) {
            case WorkOrderReportedBy.ServicePartner:
                return 'Service Partner';
            case WorkOrderReportedBy.FacilityManager:
                return 'Facility Manager';
            case WorkOrderReportedBy.LocationManager:
                return 'Location Manager';
            case WorkOrderReportedBy.OneGMRep:
                return 'OneGMRep';
            case WorkOrderReportedBy.CallCenter:
                return 'CallCenter';
            default:
                return '';
        }
    }

    export const members: WorkOrderReportedBy[] = [
        WorkOrderReportedBy.ServicePartner,
        WorkOrderReportedBy.FacilityManager,
        WorkOrderReportedBy.LocationManager,
        WorkOrderReportedBy.OneGMRep,
        WorkOrderReportedBy.CallCenter
    ];
}
