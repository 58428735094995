import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ContentChildren,
    QueryList
} from '@angular/core';
import { GridFilterControlComponent } from '../grid-filter-control/grid-filter-control.component';
import { RfxGridDataSource } from '../GridDataSource';
import { RfxGridControl } from '../GridControl';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'rfx-grid-filter',
    templateUrl: './grid-filter.component.html',
    styleUrls: ['./grid-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridFilterComponent<T> implements OnInit {
    @ContentChildren(GridFilterControlComponent)
    public gridControls: QueryList<RfxGridControl>;

    public dataSource: RfxGridDataSource<T>;
    public emptyGroup: UntypedFormGroup = new UntypedFormGroup({});

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.gridControls.forEach(control => {
            if (!!control.name && !!control.controlGroup) {
                this.dataSource.registerFilter(
                    control.name,
                    control.controlGroup
                );
            }
        });
    }

    public search(): void {
        this.dataSource.newSearch();
    }
}
