<!-- Generated Fields (for use with form) -->
<ng-container *ngIf="!preview; else previewFields" [formGroup]="form">
    <div [ngSwitch]="field.type">
        <h2 *ngSwitchCase="RfxFormFieldType.Title">{{ field.label }}</h2>
        <p *ngSwitchCase="RfxFormFieldType.Paragraph">{{ field.label }}</p>

        <mat-form-field *ngSwitchCase="RfxFormFieldType.String">
            <mat-label [attr.for]="field._id">{{ field.label }}</mat-label>
            <input
                matInput
                [formControlName]="field._id"
                [id]="field._id"
                [placeholder]="field.placeholder"
                [required]="field.required"
            />
            <mat-hint>{{ field.description }}</mat-hint>
            <mat-error [rfxError]="form.controls[field._id]"></mat-error>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="RfxFormFieldType.Number">
            <mat-label [attr.for]="field._id">{{ field.label }}</mat-label>
            <input
                matInput
                type="number"
                [formControlName]="field._id"
                [id]="field._id"
                [placeholder]="field.placeholder"
                [required]="field.required"
            />
            <mat-hint>{{ field.description }}</mat-hint>
            <mat-error [rfxError]="form.controls[field._id]"></mat-error>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="RfxFormFieldType.Textbox">
            <mat-label [attr.for]="field._id">{{ field.label }}</mat-label>
            <textarea
                matInput
                [formControlName]="field._id"
                [id]="field._id"
                [placeholder]="field.placeholder"
                [required]="field.required"
            ></textarea>
            <mat-hint>{{ field.description }}</mat-hint>
            <mat-error [rfxError]="form.controls[field._id]"></mat-error>
        </mat-form-field>

        <div *ngSwitchCase="RfxFormFieldType.Radio">
            <mat-label [attr.for]="field._id">{{ field.label }}</mat-label>
            <mat-radio-group [formControlName]="field._id" [id]="field._id">
                <mat-radio-button
                    *ngFor="let opt of field.choices"
                    [value]="opt.value"
                    >{{ opt.label }}</mat-radio-button
                >
            </mat-radio-group>
            <mat-hint class="radiocheckbox-hint">{{
                field.description
            }}</mat-hint>
            <mat-error [rfxError]="form.controls[field._id]"></mat-error>
        </div>

        <div *ngSwitchCase="RfxFormFieldType.Checkbox">
            <mat-checkbox [formControlName]="field._id" [checked]="true">{{
                field.label
            }}</mat-checkbox>
            <mat-hint class="radiocheckbox-hint">{{
                field.description
            }}</mat-hint>
            <mat-error [rfxError]="form.controls[field._id]"></mat-error>
        </div>

        <mat-form-field *ngSwitchCase="RfxFormFieldType.Dropdown">
            <mat-label [attr.for]="field._id">{{ field.label }}</mat-label>
            <mat-select
                [formControlName]="field._id"
                [id]="field._id"
                [compareWith]="compareWith"
                [required]="field.required"
            >
                <mat-option
                    *ngFor="let opt of field.choices"
                    [value]="opt.value"
                    >{{ opt.label }}</mat-option
                >
            </mat-select>
            <mat-hint>{{ field.description }}</mat-hint>
            <mat-error [rfxError]="form.controls[field._id]"></mat-error>
        </mat-form-field>
    </div>
</ng-container>

<!-- Preview Fields (for use without form) -->
<ng-template #previewFields>
    <div class="field-preview" [ngSwitch]="field.type">
        <h2 *ngSwitchCase="RfxFormFieldType.Title">{{ field.label }}</h2>
        <p *ngSwitchCase="RfxFormFieldType.Paragraph">{{ field.label }}</p>

        <mat-form-field *ngSwitchCase="RfxFormFieldType.String">
            <mat-label [attr.for]="field._id">{{ field.label }}</mat-label>
            <input
                matInput
                [id]="field._id"
                [value]="field.defaultValue"
                [placeholder]="field.placeholder"
                [required]="field.required"
            />
            <mat-hint>{{ field.description }}</mat-hint>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="RfxFormFieldType.Number">
            <mat-label [attr.for]="field._id">{{ field.label }}</mat-label>
            <input
                matInput
                type="number"
                [id]="field._id"
                [value]="field.defaultValue"
                [placeholder]="field.placeholder"
                [required]="field.required"
            />
            <mat-hint>{{ field.description }}</mat-hint>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="RfxFormFieldType.Textbox">
            <mat-label [attr.for]="field._id">{{ field.label }}</mat-label>
            <textarea
                matInput
                [id]="field._id"
                [value]="field.defaultValue"
                [placeholder]="field.placeholder"
                [required]="field.required"
            ></textarea>
            <mat-hint>{{ field.description }}</mat-hint>
        </mat-form-field>

        <div *ngSwitchCase="RfxFormFieldType.Radio">
            <mat-label [attr.for]="field._id">{{ field.label }}</mat-label>
            <mat-radio-group [id]="field._id" [value]="field.defaultValue">
                <mat-radio-button
                    *ngFor="let opt of field.choices"
                    [value]="opt.value"
                    >{{ opt.label }}</mat-radio-button
                >
            </mat-radio-group>
            <mat-hint class="radiocheckbox-hint">{{
                field.description
            }}</mat-hint>
        </div>

        <div *ngSwitchCase="RfxFormFieldType.Checkbox">
            <mat-checkbox [checked]="field.defaultValue">{{
                field.label
            }}</mat-checkbox>
            <mat-hint class="radiocheckbox-hint">{{
                field.description
            }}</mat-hint>
        </div>

        <mat-form-field *ngSwitchCase="RfxFormFieldType.Dropdown">
            <mat-label [attr.for]="field._id">{{ field.label }}</mat-label>
            <mat-select
                [id]="field._id"
                [value]="field.defaultValue"
                [placeholder]="field.placeholder"
                [compareWith]="compareWith"
                [required]="field.required"
            >
                <mat-option
                    *ngFor="let opt of field.choices"
                    [value]="opt.value"
                    >{{ opt.label }}</mat-option
                >
            </mat-select>
            <mat-hint>{{ field.description }}</mat-hint>
        </mat-form-field>

        <div *ngSwitchCase="RfxFormFieldType.ApplicationDefined">
            <mat-label>{{ field.label }}</mat-label>
        </div>
    </div>
</ng-template>
