import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    Input,
    AfterViewInit,
    ElementRef
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Observable, of, from, forkJoin } from 'rxjs';
import { map, take, switchMap, mergeMap, toArray, tap, shareReplay } from 'rxjs/operators';

const INVALID_FILE_WARNING =
    'One or more files are too large or an incorrect format and will not be uploaded';

class FileDisplay extends File {
    public preview?: string;
}

/**
 * A class wrapping a Blob that is guaranteed to be an image
 */
class Image {
    private element: HTMLImageElement | null = null;

    constructor(private readonly _file: Blob) {
        if (!/^image\/.*/.test(_file.type)) {
            throw new Error('Image must have valid image MIME type');
        }
    }

    /**
     * Lazily initializes an HTML image tag that can be used for parsing
     * information from this image
     */
    public getImageElement(): Promise<HTMLImageElement> {
        if (this.element) {
            return Promise.resolve(this.element);
        }

        const reader = new FileReader();
        reader.readAsDataURL(this._file);

        const eventObserver = fromEvent(reader, 'load').pipe(
            switchMap(event => {
                const imgElement = document.createElement('img');
                (imgElement as any).loading = 'eager';
                const target = event.target as FileReader;

                // target.result can be of type ArrayBuffer with some
                // options so we want to just make sure that's not the case
                if (typeof target.result === 'string') {
                    imgElement.src = target.result;
                }

                return fromEvent(imgElement, 'load');
            }),
            map(event => event.target as HTMLImageElement),
            tap(element => (this.element = element)),
            take(1)
        );

        return eventObserver.toPromise();
    }

    public getWidth(): Promise<number> {
        return this.getImageElement().then(element => element.width);
    }

    public getHeight(): Promise<number> {
        return this.getImageElement().then(element => element.height);
    }

    public asBlob(): Blob {
        return this._file;
    }

    public asFile(name: string, lastModified?: number): File {
        if (!lastModified) {
            lastModified = Date.now();
        }

        const type = this._file.type;
        return new File([this._file], name, { lastModified, type });
        //return {...this._file, name, lastModified}
    }
}

export interface IDimensions {
    readonly height?: number;
    readonly width?: number;
}

/**
 * Resolves the target final dimensions of the image based on its current size
 * and the given bounds. The dimensions returned will be the largest dimensions
 * that fit entirely within the given bounds while preserving the aspect ratio
 * of the image without cropping.
 *
 * @param image
 * @param dimensionBounds
 */
async function resolveDimensions(image: Image, dimensionBounds: IDimensions): Promise<IDimensions> {
    const [width, height] = await Promise.all([image.getWidth(), image.getHeight()]);
    const aspectRatio = width / height;
    const isVertical = aspectRatio < 1; // Square will count as horizontal

    if (!dimensionBounds.height && !dimensionBounds.width) {
        return { height, width } as const;
    }

    const maxHeight = dimensionBounds.height || dimensionBounds.width / aspectRatio;
    const maxWidth = dimensionBounds.width || dimensionBounds.height * aspectRatio;

    let newWidth: number;
    let newHeight: number;
    if (isVertical) {
        newHeight = Math.max(height, maxHeight);
        newWidth = Math.floor(newHeight * aspectRatio);

        if (newWidth > maxWidth) {
            // Aspect ratio causes scaled image to exceed bounds, scale back
            newWidth = maxWidth;
            newHeight = Math.floor(newWidth / aspectRatio);
        }
    } else {
        newWidth = Math.max(width, maxWidth);
        newHeight = Math.floor(newWidth / aspectRatio);

        if (newHeight > maxHeight) {
            // Aspect ratio causes scaled image to exceed bounds, scale back
            newHeight = maxHeight;
            newWidth = Math.floor(newHeight * aspectRatio);
        }
    }

    return { height: newHeight, width: newWidth } as const;
}

/**
 * Resizes a given image down to the dimensions specified. The image will be
 * resized so that height and width are less than or equal to the given
 * dimensions while maintaining aspect ratio and encoding the resulting file as
 * JPEG. Does nothing if the given file is not a valid image (determined by
 * MIME type).
 *
 * @param image
 * @param dimensionBounds
 */
async function resizeImage(image: Image, dimensionBounds: IDimensions): Promise<Image> {
    const { width, height } = await resolveDimensions(image, dimensionBounds);
    const imageElement = await image.getImageElement();
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(imageElement, 0, 0, width, height);

    return new Promise((resolve, _reject) => {
        const mimeType = 'image/jpeg'; // For GM2 JPEG is fine for all images
        const jpegQuality = 0.5; // Save at 50% JPEG quality
        function onLoadBlob(blob: Blob) {
            resolve(new Image(blob));
        }

        canvas.toBlob(onLoadBlob, mimeType, jpegQuality);
    });
}

/**
 * Resizes a given image down to the dimensions specified. The image will be
 * resized so that height and width are less than or equal to the given
 * dimensions while maintaining aspect ratio and encoding the resulting file as
 * JPEG. Does nothing if the given file is not a valid image (determined by
 * MIME type).
 *
 * @param blob
 * @param dimensionBounds
 */
export async function resizeImageBlob(blob: Blob, dimensionBounds: IDimensions): Promise<Blob> {
    try {
        const image = new Image(blob);
        const resized = await resizeImage(image, dimensionBounds);
        return resized.asBlob();
    } catch (_e) {
        return Promise.resolve(blob);
    }
}

/**
 * Processes each file in a FileList instance, resizing any images that happen
 * to be in there. Since FileList instances are immutable this function returns
 * a new instance of FileList with all images resized.
 *
 * @param files
 * @param dimensionBounds
 */
async function resizeFileList(files: FileList, dimensionBounds: IDimensions): Promise<FileList> {
    // Need to use DataTransfer object to create a new FileList instance with
    // the resized images because FileLists and the Files inside them are both
    // immutable
    const datatransfer = new DataTransfer();
    const newfiles = datatransfer.items;

    // IE doesn't support this feature so we'll just not resize images there.
    // Nothing we can really do about that.
    if (!newfiles) {
        return files;
    }

    if (!dimensionBounds.height && !dimensionBounds.width) {
        // No-Op
        return files;
    }

    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        try {
            let originalImage = new Image(file);
            let image = await resizeImage(originalImage, dimensionBounds);
            // Replace file extension with .jpg
            const name =
                file.name
                    .split('.')
                    .slice(0, -1)
                    .join('.') + '.jpg';
            file = image.asFile(name, file.lastModified);
        } catch (e) {
            // File is not an image that can be resized; ignore and move on
        }
        newfiles.add(file);
    }

    return datatransfer.files;
}

/**
 * Parses a human-readable byte count string.
 *
 * This function accurately supports up to one byte less than 8PiB (due to
 * javascript integer size limitations) with both SI and IEC prefixes.
 * Spaces between the number and the prefix/B are ignored.
 *
 * NOTE:    PARSING IS CASE-SENSITIVE AND STRICT
 * NOTE:    While technically "K" is a JEDEC prefix for 1024 this function
 *          parses it as SI for the sake of consistency while also parsing
 *          the correct SI form "k"
 *
 * Examples
 * "1Mb" => Error
 * "1m" => Error
 * "1mb" => Error
 * "1mB" => Error
 * "1MiB" => 1,048,576 Bytes
 * "1MB" => 1,000,000 Bytes
 * "1B" => 1 Byte
 * "1234" => 1,234 Bytes
 * "1K" => 1,000 Bytes
 */
function parseFilesize(filesize: string): number {
    /**
     * Lookup table for SI (base-10) and IEC (base-2) prefixes.
     */
    const PREFIX_LUT = {
        '': 1,
        // SI
        k: 1_000,
        K: 1_000,
        M: 1_000_000,
        G: 1_000_000_000,
        T: 1_000_000_000_000,
        P: 1_000_000_000_000_000,
        // IEC
        Ki: 1_024,
        Mi: 1_048_576,
        Gi: 1_073_741_824,
        Ti: 1_099_511_627_776,
        Pi: 1_125_899_906_842_624
    };

    /**
     * Regex that matches the number and the SI/SEC prefix for a given byte
     * size
     *
     * Example:
     * "1000 MiB" matches
     *     Group 1: "1000"
     *     Group 2: "Mi"
     */
    const regex = /^([0-9]+(?:\.[0-9]*)?)\s*((?:[KMG]i|[kmgKMG])?)B?$/;
    const matches = regex.exec(filesize);

    if (matches === null) {
        throw new Error(`parseFilesize: Invalid file size string "${filesize}"`);
    }

    const [_originalStr, multiplicandStr, prefixStr] = matches;
    const multiplicand = parseFloat(multiplicandStr);
    const prefix = PREFIX_LUT[prefixStr];

    if (typeof prefix === 'undefined') {
        throw new Error(`parseFilesize: Invalid byte prefix "${prefixStr}"`);
    }

    return Math.floor(multiplicand * prefix);
}

/**
 * Creates a data URL of a given file object that can be used for image
 * previews.
 *
 * Non-images (determined by MIME type) return null.
 */
function previewFile(file: File): Observable<string | null> {
    const reader = new FileReader();

    // Only preview images (MIME type matches "image/*")
    if (/^image\/.*/.test(file.type)) {
        reader.readAsDataURL(file);
    } else {
        return of(null);
    }

    return fromEvent(reader, 'load').pipe(
        take(1),
        map(e => (e.target as FileReader).result as string)
    );
}

@Component({
    selector: 'rfx-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent implements OnInit, AfterViewInit {
    private internalFileElement: HTMLInputElement;

    @ViewChild('fileElement', { static: true })
    private fileElement: ElementRef<HTMLInputElement>;

    /**
     * Name of the internal form field to be submitted.
     */
    @Input()
    public name?: string;

    /**
     * Whether to allow selecting multiple files. By default only a single file
     * is allowed.
     */
    @Input()
    public multiple?: string;

    /**
     * List of file extensions (without the dot) that are to be accepted.
     *
     * e.g. m4a, mp3, png, jpg, jpeg, txt
     */
    public accepts?: string[];

    @Input('accepts')
    public set setAccepts(value: string[] | undefined) {
        // Extensions should always be lowercase and are compared as such
        this.accepts = value && value.map(ext => ext.toLowerCase());
    }

    /**
     * Maximum size for any single file selected.
     */
    @Input()
    public maxfilesize?: string;

    /**
     * Whether to show previews of selected images in the upload box.
     */
    public imagepreview?: boolean;

    @Input('imagepreview')
    public set setImagepreview(value: boolean | '') {
        this.imagepreview = value === '' || value;
    }

    /**
     * Maximum combined size of all selected files. If only one file can be
     * uploaded then this behaves identically to the `maxfilesize` attribute.
     */
    @Input()
    public maxtotalsize?: string;

    /**
     * Maximum number of files that can be uploaded at once. If not specified
     * then no limit is enforced. If the `multiple` attribute resolves to false
     * then this attribute has no effect.
     */
    @Input()
    public maxnumfiles?: number;

    /**
     * If this value is given and nonzero, a selected file is an image
     * (determined by MIME type), and the image has a larger width than this
     * value; then resize the image to be less than or equal to this value
     * while preserving aspect ratio.
     */
    @Input()
    public clampImageWidth?: number;

    /**
     * If this value is given and nonzero, a selected file is an image
     * (determined by MIME type), and the image has a larger height than this
     * value; then resize the image to be less than or equal to this value
     * while preserving aspect ratio.
     */
    @Input()
    public clampImageHeight?: number;

    @Input()
    public isDisabled?: boolean = false;

    /**
     * Stream of file selection events. Whenever files are selected by the user
     * the FileList from that event will be emitted here. Emits null when the
     * list is cleared.
     */
    @Output()
    public files: EventEmitter<FileList | null> = new EventEmitter();

    public filedisplay$: Observable<FileDisplay[]> = this.files.pipe(
        switchMap(filelist => this.previewList(filelist)),
        shareReplay(1)
    );

    constructor(
        private readonly _toastService: ToastrService
    ) {}

    ngOnInit(): void {
        const element: HTMLInputElement = document.createElement('input');
        element.type = 'file';
        element.name = this.name;
        element.multiple = !!this.multiple;

        if (this.accepts) {
            element.accept = this.accepts.toString();
        }

        this.internalFileElement = element;

        console.log('Clamp Width: ', this.clampImageWidth);
        console.log('Clamp Height: ', this.clampImageHeight);
    }

    ngAfterViewInit(): void {
        //fromEvent(this.fileElement.nativeElement, 'change')
        //.subscribe(this.onNewFilesAdded.bind(this));
        fromEvent(this.internalFileElement, 'change').subscribe(this.onNewFilesAdded.bind(this));
    }

    public reset(): void {
        try {
            this.fileElement.nativeElement.value = null;
            this.internalFileElement.value = null;
        } catch (e) {
            // Ignore
        }
        this.files.emit(null);
    }

    public openFileDialog(): void {
        this.isDisabled ? null : this.internalFileElement.click();
    }

    private previewList(filelist: FileList | null): Observable<FileDisplay[] | null> {
        if (filelist === null) {
            return of(null);
        }

        const files = Array.from(filelist);

        if (this.imagepreview) {
            return from(files).pipe(
                mergeMap(file => forkJoin(of(file), previewFile(file))),
                map(([file, preview]: [FileDisplay, string]) => {
                    file.preview = preview;

                    return file;
                }),
                toArray(),
                tap(file => console.log('file', file))
            );
        } else {
            return of(files);
        }
    }

    private async onNewFilesAdded(e: Event): Promise<void> {
        const target = e.target as HTMLInputElement;
        let files = target.files;
        let isValid = true;

        console.log(e);
        console.log(files);

        if (this.maxnumfiles && files.length > this.maxnumfiles) {
            isValid = false;
        } else {
            let totalsize = 0;

            // The resize needs to be the first "check" since it modifies the
            // file and can effect te results of the other checks, namely the
            // max file size and max total size
            if (this.clampImageHeight || this.clampImageWidth) {
                const bounds = {
                    height: this.clampImageHeight,
                    width: this.clampImageWidth
                } as const;
                files = await resizeFileList(files, bounds);
            }

            // TODO Use exceptions in a try-catch to better explain errors to
            // user
            for (let i = 0; i < files.length; i++) {
                if (this.maxfilesize) {
                    const bytes = parseFilesize(this.maxfilesize);
                    if (files[i].size > bytes) {
                        isValid = false;
                        break;
                    }
                }
                if (this.maxtotalsize) {
                    totalsize += files[i].size;
                    const bytes = parseFilesize(this.maxtotalsize);
                    if (totalsize > bytes) {
                        isValid = false;
                        break;
                    }
                }
                if (this.accepts) {
                    const splits = files[i].name.split('.');
                    let ext = splits[splits.length - 1];

                    if (typeof ext === 'undefined') {
                        ext = '';
                    }

                    if (!this.accepts.includes('.' + ext.toLowerCase())) {
                        isValid = false;
                    }
                }
            }
        }

        if (!isValid) {
            this._toastService.error(INVALID_FILE_WARNING, 'Error');
            this.reset();
            return;
        }

        if (files.length <= 0) {
            this.reset();
            return;
        }

        this.fileElement.nativeElement.files = files;
        this.files.emit(files);
    }
}
