import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { url } from '../../consts/url.const';
import { CompanyInvitesModel } from '@ngx-gm2/shared/models/company-invites.model';
import { Invite } from '@ngx-gm2/shared/services/invite.service';
import { PaginationResult } from '@ngx-gm2/shared/models/pagination.model';

@Injectable()
export class CompanyInviteService {

    constructor(private httpClient: HttpClient) {
    }

    public getAll(params: { page: number, perPage: number }): Observable<PaginationResult<CompanyInvitesModel.CompanyInviteDocument>> {
        return this.httpClient.get<PaginationResult<CompanyInvitesModel.CompanyInviteDocument>>(`${url.companyInvite.crud}?page=${params.page}&perPage=${params.perPage}`);
    }

    public accept(id: string): Observable<Invite> {
        return this.httpClient.post<Invite>(`${url.companyInvite.accept(id)}`, {});
    }

    public reject(id: string): Observable<Invite> {
        return this.httpClient.post<Invite>(`${url.companyInvite.reject(id)}`, {});
    }

    public revoke(id: string): Observable<Invite> {
        return this.httpClient.post<Invite>(`${url.companyInvite.revoke(id)}`, {});
    }
}
