import { TimesheetDates, ServiceTimesheetField, DeviceInfo } from '@gm2/common';
import { TimesheetServiceDto } from './timesheet-service';
import { TimesheetImageDto } from './timesheet-image';
import { UserIdentity } from '../user/UserIdentity';
import { CompanyIdentity } from '../company/CompanyIdentity';

export class TimesheetIdentity {
    public _id: string;
    public appId: string;
    public rfpId: string = '';
    public displayName: string = 'Temporary Display Name';
    public deviceInfo: DeviceInfo = null;
    public isComplete: boolean = false;
    public timeActive: boolean = true;
    public crewSize: number = 0;
    public images: TimesheetImageDto[] = [];
    public dates: TimesheetDates = new TimesheetDates();
    public services: TimesheetServiceDto[] = [];
    public serviceTypeFields: { [key in ServiceTimesheetField]?: number | string } = {};
    // for mobile app <-> api understanding
    public asyncImageCount?: number;
    public offlineId?: string;
    // for mobile app offline use
    public userIdentity: UserIdentity;
    public companyIdentity: CompanyIdentity;
    public onSpBehalf?: string = '';

    constructor(params?: Partial<TimesheetIdentity>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.offlineId = params.offlineId || this.offlineId;
            this.userIdentity = !!params.userIdentity
                ? new UserIdentity(params.userIdentity)
                : this.userIdentity;
            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentity(params.companyIdentity)
                : this.companyIdentity;
            this.appId = params.appId || this.appId;
            this.rfpId = params.rfpId || this.rfpId;
            this.displayName = params.displayName || this.displayName;
            this.deviceInfo = params.deviceInfo || this.deviceInfo;
            this.crewSize = params.crewSize || this.crewSize;
            this.images = !!params.images && params.images.length > 0 ? params.images : this.images;
            this.isComplete = params.isComplete || this.isComplete;
            this.dates = !!params.dates ? new TimesheetDates(params.dates) : this.dates;
            this.services = !!params.services
                ? params.services.map(s => new TimesheetServiceDto(s))
                : this.services;
            this.serviceTypeFields = params.serviceTypeFields || this.serviceTypeFields;
            this.asyncImageCount = typeof params.asyncImageCount === 'number' && params.asyncImageCount >= 0
                ? params.asyncImageCount : this.asyncImageCount;
            this.onSpBehalf = params.onSpBehalf || this.onSpBehalf;
        }
    }
}
