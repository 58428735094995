<div
    *ngIf="isMultiSelection"
    class="grid-list-selector"
    [class.grid-list-selector--selected]="hasSelection"
>
    <mat-checkbox
        color="primary"
        [checked]="selectAllChecked"
        [indeterminate]="selectAllIndeterminate"
        (change)="selectAll()"
    ></mat-checkbox>
</div>
<div class="grid-list-wrapper">
    <ng-container rfxGridRowOutlet></ng-container>
</div>
<div *ngIf="(loading$ | async)" class="loading-container">
    <mat-spinner></mat-spinner>
</div>
