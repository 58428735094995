import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CompanyService, CompanyIdentity, LocationService, CompanyType } from '@gm2/ui-common';

@Component({
    selector: 'gm2-service-partner-modal',
    templateUrl: './service-partner-modal.component.html',
    styleUrls: ['./service-partner-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicePartnerModalComponent implements OnInit {

    public servicePartnerForm: UntypedFormGroup = this._fb.group({
        servicePartner: ['', Validators.required],
    });

    public loading$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);

    private initialOptions: { value: CompanyIdentity, label: string }[] = null;

    public options$: BehaviorSubject<{ value: CompanyIdentity, label: string }[]> =
        new BehaviorSubject<{ value: CompanyIdentity, label: string }[]>([]);

    private companies$: Observable<{ value: CompanyIdentity, label: string }[]> =
        this._companyService.getSimpleList(CompanyType.Service_Partner)
            .pipe(
                map(companies => {
                    const landscapers = this.data.awardedLandscapers.map(landscaperCompany => ({
                        value: new CompanyIdentity({ _id: landscaperCompany._id, name: landscaperCompany.name }),
                        label: landscaperCompany.name,
                    }));

                    const formattedCompanies = companies.map(company => ({
                        value: new CompanyIdentity({ _id: company._id, name: company.profile.name }),
                        label: company.profile.name,
                    }));
                    return landscapers.concat(formattedCompanies);
                }),
                tap(companies => {
                    this.loading$.next(false);
                }),
            );

    public searchControl: UntypedFormControl = new UntypedFormControl();

    private filterOnSearch(): void {
        if (!this.options$.value || !this.initialOptions)
            return;
        let search = this.searchControl.value;
        if (!search) {
            this.options$.next(this.initialOptions.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.options$.next(
            this.initialOptions.filter(
                company => company.label.toLowerCase()
                    .indexOf(search) > -1,
            ),
        );
    }

    public allowCancel$: BehaviorSubject<boolean> =
        new BehaviorSubject(false);

    private _onDestroy: Subject<void> = new Subject<void>();

    constructor(
        private _companyService: CompanyService,
        private _fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<ServicePartnerModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { allowCancel: boolean, awardedLandscapers?: { name: string; _id: string }[] },
    ) {
        this.allowCancel$.next(data.allowCancel);
    }

    ngOnInit(): void {

        this.companies$.subscribe(companies => {
            if (!!companies) {
                this.options$.next(companies);
                if (this.initialOptions === null
                    || this.options$.value.length > this.initialOptions.length)
                    this.initialOptions = companies;
            }
        });

        this.searchControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterOnSearch();
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    public close(): void {
        this.dialogRef.close(false);
    }

    public select(): void {
        this.dialogRef.close(this.servicePartnerForm.get('servicePartner').value);
    }
}
