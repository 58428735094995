import { WorkOrderPhaseStatus } from '../../enums';
import { WorkOrderInvoiceIdentityWithoutIds } from '../work-order-invoice/work-order-invoice-identity';

export class WorkOrderPhaseSimpleWithoutIds {
    public id: any;
    public description: string = '';
    public phaseStatus: WorkOrderPhaseStatus;
    public workOrderInvoiceIdentity: WorkOrderInvoiceIdentityWithoutIds;

    constructor(params?: Partial<WorkOrderPhaseSimpleWithoutIds>) {
        if (!!params) {
            this.id = params.id || this.id;
            this.description = params.description || this.description;
            this.phaseStatus = params.phaseStatus || this.phaseStatus;
            this.workOrderInvoiceIdentity = !!params.workOrderInvoiceIdentity
                ? new WorkOrderInvoiceIdentityWithoutIds(params.workOrderInvoiceIdentity)
                : this.workOrderInvoiceIdentity;
        }
    }
}
