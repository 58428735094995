import { WorkOrderStatus, WorkOrderActiveStatus } from '../../enums';
import { PaginatedListWithCountAndValue } from '../grid/paginated-list-with-count-and-value';
import { WorkOrderFacetedQueryResult } from '../../types';

export class WorkOrderMultiGridData {

    private static readonly _multiKeys: string[] =
        WorkOrderStatus.members.map(m => m as string)
            .concat(WorkOrderActiveStatus.members.map(m => m as string));

    public data: {
        [key: string]: PaginatedListWithCountAndValue
    } = {};

    constructor(params?: Partial<WorkOrderMultiGridData>) {
        if (!!params) {
            this.data = !!params.data ? params.data : this.data;
        }
    }

    public static fromFacetedQueryResult(
        result: WorkOrderFacetedQueryResult
    ): WorkOrderMultiGridData {

        // activeStatus faces won't be available for Client and SP
        result.counts = result.statusCounts;
        if (Array.isArray(result.activeStatusCounts)) {
            result.counts = [ ...result.counts, ...result.activeStatusCounts ];
        }
        result.twelveMonth = result.statusTwelveMonth;
        if (Array.isArray(result.activeStatusTwelveMonth)) {
            result.twelveMonth = [ ...result.twelveMonth, ...result.activeStatusTwelveMonth ];
        }

        delete result.activeStatusTwelveMonth;
        delete result.activeStatusCounts;
        delete result.statusTwelveMonth;
        delete result.statusCounts;

        const multi = new WorkOrderMultiGridData();

        for (const k of WorkOrderMultiGridData._multiKeys) {
            const cf = result.counts.filter(c => c.status === k);
            const tf = result.twelveMonth.filter(t => t.status === k);
            const count = cf.length === 1 ? cf[0] : null;
            const twelve = tf.length === 1 ? tf[0] : null;

            if (!!result[k] && !!count && !!twelve) {
                const p = new PaginatedListWithCountAndValue();
                p.docs = result[k];
                p.count = count.count;
                p.twelveMonthCount = twelve.count;
                p.twelveMonthValue = twelve.value;
                multi.data[k] = p;
            }
        }

        return multi;
    }
}
