import { State, Selector, StateContext } from '@ngxs/store';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { Receiver } from '@ngxs-labs/emitter';
import { ITokenPayload } from '@gm2/ui-common';
import { Injectable } from '@angular/core';

export interface AuthStateModel {
    token: string;
    loading: boolean;
}

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        token: undefined,
        loading: false
    }
})
@Injectable()
export class AuthState {
    @Selector()
    public static authenticated(state: AuthStateModel): boolean {
        return !!state.token;
    }

    @Selector()
    @ImmutableSelector()
    public static authToken(state: AuthStateModel): string {
        return state.token;
    }

    @Selector()
    @ImmutableSelector()
    public static loading(state: AuthStateModel): boolean {
        return state.loading;
    }

    @Selector()
    @ImmutableSelector()
    public static tokenPayload(state: AuthStateModel): ITokenPayload | null {
        if (!state.token) {
            return null;
        }

        const parts = state.token.split('.');
        const numJwtParts = 3;
        if (parts.length < numJwtParts) {
            return null;
        }

        try {
            const payload = JSON.parse(atob(parts[1]));
            return payload;
        } catch (_) {
            return null;
        }
    }

    @Receiver({ type: '[Auth] set auth token' })
    @ImmutableContext()
    public static setAuthToken(
        { setState }: StateContext<AuthStateModel>,
        { payload }: { payload: string }
    ): void {
        setState((state: AuthStateModel) => {
            state.token = payload;
            return state;
        });
    }

    @Receiver({ type: '[Auth] set loading state', payload: false })
    @ImmutableContext()
    public static setLoadingState(
        { setState }: StateContext<AuthStateModel>,
        { payload }: { payload: boolean }
    ): void {
        setState((state: AuthStateModel) => {
            state.loading = payload;
            return state;
        });
    }
}
