import { BaseDoc } from '../base/base-doc';
import { Month } from '../../enums';
import { BaseMediaDocumentWithoutIds } from '../media/base-media-document';
import { ContractSnapShotWithoutIds } from './contract-snap-shot';
import { LocationIdentityWithoutIds } from '../location/location-identity';
import { ContractIdentityWithoutIds } from './contract-identity';

export class ContractAppliedWithoutIds extends BaseDoc {
    public _id: any;
    public name: string = '';
    public locationIdentities: LocationIdentityWithoutIds[] = [];
    public identity: ContractIdentityWithoutIds = new ContractIdentityWithoutIds();
    public snapShot: ContractSnapShotWithoutIds = new ContractSnapShotWithoutIds();
    public documents: BaseMediaDocumentWithoutIds[] = [];
    public months: Month[] = [];

    constructor(params?: Partial<ContractAppliedWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;

            this.locationIdentities = Array.isArray(params.locationIdentities)
                ? params.locationIdentities.map(s => new LocationIdentityWithoutIds(s))
                : this.locationIdentities;

            this.identity = !!params.identity
                ? new ContractIdentityWithoutIds(params.identity)
                : this.identity;

            this.snapShot = !!params.snapShot
                ? new ContractSnapShotWithoutIds(params.snapShot)
                : this.snapShot;

            this.documents = Array.isArray(params.documents)
                ? params.documents.map(s => new BaseMediaDocumentWithoutIds(s))
                : this.documents;

            this.months = params.months || this.months;
        }
    }
}
