import { WorkOrderInvoiceHistoryWithoutIds } from '@gm2/common';
import { UserIdentity } from '../user/UserIdentity';
import { WorkOrderInvoiceDeclined } from './work-order-invoice-declined';

export class WorkOrderInvoiceHistory extends WorkOrderInvoiceHistoryWithoutIds {
    public userIdentity: UserIdentity = new UserIdentity();
    public declined: WorkOrderInvoiceDeclined;

    constructor(params?: Partial<WorkOrderInvoiceHistory>) {
        super(params);
        if (!!params) {
            this.userIdentity = !!params.userIdentity
                ? new UserIdentity(params.userIdentity)
                : this.userIdentity;

            this.declined = !!params.declined
                ? new WorkOrderInvoiceDeclined(params.declined)
                : this.declined;
        }
    }
}
