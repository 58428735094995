import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { containers } from './containers';
import { RouterModule } from '@angular/router';
import { UiLibraryModule } from '@gm2/ui-library';
import { UiCommonModule } from '@gm2/ui-common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    declarations: [...containers, BackButtonComponent],
    imports: [
        CommonModule,
        UiLibraryModule,
        UiCommonModule,
        RouterModule,
        MatSidenavModule,
        MatIconModule,
    ],
    exports: [...containers, BackButtonComponent]
})
export class SharedModule {
}
