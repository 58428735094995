export enum WorkOrderBillingTypeReport {
    Billable = '[WorkOrderBillingType] Billable',
    Contractual = '[WorkOrderBillingType] Contractual',
    CapitalProject = '[WorkOrderBillingType] CapitalProject',
    AddedService = '[WorkOrderBillingType] AddedService',
    BillableSnow = '[WorkOrderBillingType] BillableSnow',
    NonBillableSnow = '[WorkOrderBillingType] NonBillableSnow',
    Warranty = '[WorkOrderBillingType] Warranty',
    SelfPerform = '[WorkOrderBillingType] SelfPerform',
    AllOptions = '[WorkOrderBillingType] All Options'
}

export namespace WorkOrderBillingTypeReport {
    export function toString(type: WorkOrderBillingTypeReport): string {
        switch (type) {
            case WorkOrderBillingTypeReport.AllOptions:
                return 'All Options'
            case WorkOrderBillingTypeReport.Billable:
                return 'Billable';
            case WorkOrderBillingTypeReport.Contractual:
                return 'Contractual';
            case WorkOrderBillingTypeReport.CapitalProject:
                return 'Capital Project';
            case WorkOrderBillingTypeReport.AddedService:
                return 'Contract Added Service';
            case WorkOrderBillingTypeReport.SelfPerform:
                return 'Self Perform';
            case WorkOrderBillingTypeReport.BillableSnow:
                return 'Snow Service Call';
            case WorkOrderBillingTypeReport.NonBillableSnow:
                return 'Snow Service Complaint';
            case WorkOrderBillingTypeReport.Warranty:
                return 'Warranty';
            default:
                return '';
        }
    }

    export const members: WorkOrderBillingTypeReport[] = [
        WorkOrderBillingTypeReport.Billable,
        WorkOrderBillingTypeReport.Contractual,
        WorkOrderBillingTypeReport.CapitalProject,
        WorkOrderBillingTypeReport.AddedService,
        WorkOrderBillingTypeReport.SelfPerform,
        WorkOrderBillingTypeReport.BillableSnow,
        WorkOrderBillingTypeReport.NonBillableSnow,
        WorkOrderBillingTypeReport.Warranty,
        WorkOrderBillingTypeReport.AllOptions
    ];
}
