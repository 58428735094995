import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [FileUploadComponent],
    imports: [CommonModule, MatListModule, MatIconModule, MatTooltipModule],
    exports: [FileUploadComponent]
})
export class FileUploadModule {}

export { IDimensions, resizeImageBlob } from './components/file-upload/file-upload.component';

export {
    FileUploadModule as RfxFileUploadModule,
    FileUploadComponent as RfxFileUploadComponent
};
