import { SpAbstractNonMonthlyCharges } from './sp-abstract-non-monthly-charges';
import { BidWithoutIds, BidServiceWithoutIds, RfpServiceWithoutIds } from '@gm2/common';

export class SpHourlyCharges extends SpAbstractNonMonthlyCharges {
    public readonly minutesPerHour: number = 60;

    public calculateServiceDuration(
        duration: number,
        awardedBid: BidWithoutIds,
        awardedBidService: BidServiceWithoutIds
    ): number {
        return duration;
    }

    public calculateServiceTotal(
        duration: number,
        awardedBid: BidWithoutIds,
        awardedBidService: BidServiceWithoutIds
    ): number {
        const durationToCharge = this.getTotalDurationToCharge(duration, awardedBid);
        // convert duration to hours to calculate hourly charge
        const durationInHours = durationToCharge / this.minutesPerHour || 0;
        return durationInHours * awardedBidService.amount;
    }

    public calculateServiceClientTotal(
        duration: number,
        clientPricingService: RfpServiceWithoutIds,
        serviceDurationDict: { [serviceId: string]: number }
    ): { total: number; duration: number } {
        // console.log('duration', duration);
        const durationInHours = duration / this.minutesPerHour || 0;
        return {
            total:
                durationInHours * clientPricingService.clientPrice +
                (clientPricingService.clientFee || 0),
            duration: duration
        };
    }

    public getTotalDurationToCharge(duration: number, awardedBid: BidWithoutIds): number {
        // duration is in minutes
        // minChargeDuration is in hours
        const minChargeDurationInHours =
            awardedBid.minChargeDuration !== null
                ? awardedBid.minChargeDuration * this.minutesPerHour
                : 0;

        return duration < minChargeDurationInHours
            ? awardedBid.minChargeDuration * this.minutesPerHour
            : duration;
    }

    public adjustClientDurations(
        companyTotalDuration: number,
        serviceDurationDict: {
            [serviceId: string]: number;
        }
    ): { serviceDurationDict: { [serviceId: string]: number }; totalDuration: number } {
        // client total for a service uses the total duration that was calculated for the SP
        // no idea why

        let totalDuration = companyTotalDuration;

        const hoursInMinute = 60;
        const defaultOneHour = 60;
        const defualtOneMinute = 1;

        if (totalDuration === 0) {
            totalDuration = defaultOneHour + defualtOneMinute; // use one hour and one minute
        } else if (totalDuration < hoursInMinute) {
            totalDuration += defaultOneHour; // add one hour
        } else if (totalDuration % hoursInMinute === 0) {
            totalDuration += defualtOneMinute; // add one minute
        }

        for (const serviceId in serviceDurationDict) {
            serviceDurationDict[serviceId] = totalDuration;
        }

        return { serviceDurationDict, totalDuration };
    }
}
