import { HttpService } from '../services/http.service';
import { AdapterType } from '../models/AdapterType';
import { Adapter } from '../models/Adapter';

/**
 * @ignore
 *
 * Registers an adapter into an array on the decorator descriptor that will
 * allow it to be accessed later on the decorator target
 *
 * @param adapter An Adapter to be registered
 * @param descriptor The storage mechanism for a decorator
 * @returns void
 */
function registerAdapter(adapter: Adapter, descriptor: any): void {
    if (AdapterType.members.indexOf(adapter.type) > -1) {
        if (Array.isArray(descriptor.adapters)) {
            descriptor.adapters = [...descriptor.adapters, adapter];
        } else {
            descriptor.adapters = [adapter];
        }
    }
}

/**
 * {@link MapValue}
 *
 * Registers a function to be applied to the response body of a request.
 * Can be combined with the [MapClass]{@link MapClass} decorator.
 *
 * ```Typescript
 * @MapValue((body) => {
 *      if (body.status === 'complete') {
 *          body.date = body.updatedDate;
 *      } else {
 *          body.date = body.createdDate;
 *      }
 *      return body;
 * })
 * @Get('/todos/1')
 * public getTodo(): Observable<any> {
 *      return null; // All logic is contained in the @Get decorator
 * }
 * ```
 *
 * @param adapterFn Function to apply as a map
 * @returns decorator
 */
export function MapValue(adapterFn: Function): Function {
    return (target: HttpService, propertyKey: string, descriptor: any): any =>
        registerAdapter(
            {
                type: AdapterType.MapValue,
                value: adapterFn
            },
            descriptor
        );
}

/**
 * {@link MapClass}
 *
 * Reigsters a class to be applied to the response body of a request. Handles
 * both cases where the response is a single object or an array of objects.
 * Can be combined with the [MapValue]{@link MapValue} decorator.
 *
 * ```Typescript
 * export class Todo {
 *      constructor(params) {}
 * }
 *
 * @MapClass(Todo)
 * @Get('/todos/1')
 * public getTodo(): Observable<Todo> {
 *      return null; // All logic is contained in the @Get decorator
 * }
 *
 *
 * @MapClass(Todo)
 * @Get('/todo')
 * public getTodoList(): Observable<Todo[]> {
 *      return null; // All logic is contained in the @Get decorator
 * }
 * ```
 *
 * @param constructorFn A class to map the body response to
 * @returns decorator
 */
export function MapClass<T>(constructorFn: new (params: any) => T): Function {
    return (target: HttpService, propertyKey: string, descriptor: any): any =>
        registerAdapter(
            {
                type: AdapterType.MapClass,
                value: constructorFn
            },
            descriptor
        );
}

/**
 * @ignore
 *
 * Used for internal testing purposes. Creates an invalid adapter
 *
 * @returns decorator
 */
export function InvalidMap(): Function {
    return (target: HttpService, propertyKey: string, descriptor: any): any =>
        registerAdapter(
            {
                type: null,
                value: null
            },
            descriptor
        );
}
