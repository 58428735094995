import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridFilterControlComponent } from '../grid-filter-control/grid-filter-control.component';
import { GridFilter } from '../GridFilter';

/**
 * Modes of operation for the date picker.
 */
enum SelectMode {
    /**
     * Corresponds to a single date input. When this mode is selected, the value emitted
     * by the owl date picker is a plain Date object.
     */
    Single = 'single',
    /**
     * Corresponds to a range date input. When this mode is selected, the value emitted
     * by the owl date picker is a tuple of 2 Date objects.
     */
    Range = 'range',
    // These are supported by owl date picker but not supported by this component
    // RangeFrom = 'rangeFrom',
    // RangeTo = 'rangeTo'
}

/**
 * The value of the form on submit.
 *
 * If the selectMode is 'single', the value of endDate is unknown.
 */
interface IFormValue {
    startDate: Date;
    endDate: Date | unknown;
}

@Component({
	selector: 'rfx-grid-filter-date-control',
	templateUrl: './grid-filter-date-control.component.html',
	styleUrls: ['./grid-filter-date-control.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: GridFilterControlComponent,
            useExisting: GridFilterDateControlComponent
        }
    ]
})
export class GridFilterDateControlComponent implements OnInit, OnDestroy, GridFilter {
    private _onDestroy$: Subject<void> = new Subject();

	@Input()
	public name: string = 'date';

	@Input()
	public placeholder: string = 'Date';

    /**
     * Specify the mode of operation for this component. This determines what kind of
     * dates this filter produces. It can be one of:
     * - 'single': A single date
     * - 'range': A date range
     */
    @Input()
    public selectMode: SelectMode = SelectMode.Single;

	public controlGroup: UntypedFormGroup = new UntypedFormGroup({
        startDate: new UntypedFormControl(undefined),
        endDate: new UntypedFormControl(undefined)
    });

    /**
     * Internal form control bound to the date picker so that the value can be
     * intercepted and transformed before being passed to the actual form group.
     */
    public baseControl: UntypedFormControl = new UntypedFormControl(null);

	constructor() {}

	ngOnInit(): void {
        // Even though this side of the transform still depends on the current value of
        // selectMode$ the datepicker knows what the select mode is and sends the
        // corresponding output type. To avoid any race conditions we'll go off of the
        // date picker output type instead of explicitly checking the value of
        // selectMode$.
        this.baseControl.valueChanges
            .pipe(takeUntil(this._onDestroy$))
            .subscribe((value: Date | [Date, Date]) => {
                let patch: IFormValue;
                if (Array.isArray(value)) {
                    // Range Mode
                    patch = { startDate: value[0], endDate: value[1] };
                } else {
                    // Single Mode
                    patch = { startDate: value, endDate: undefined };
                }
                this.controlGroup.patchValue(patch, { emitEvent: false });
            });
        this.controlGroup.valueChanges
            .pipe(takeUntil(this._onDestroy$))
            .subscribe((value: IFormValue) => {
                let patch: Date | [Date, Date];
                switch (this.selectMode) {
                    case SelectMode.Single:
                        patch = value.startDate;
                        break;
                    case SelectMode.Range:
                        // endDate is type Date when select mode is 'range'
                        patch = [value.startDate, value.endDate as Date];
                        break;
                }
                this.baseControl.patchValue(patch, { emitEvent: false });
            });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }
}
