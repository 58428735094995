<h2 mat-dialog-title>Select Date Range</h2>
<div mat-dialog-content>
	<form [formGroup]="dateRangeForm">
		<mat-form-field>
		  <input 
		  	matInput 
		  	[matDatepicker]="startDate"
		  	placeholder="Start Date"
		  	formControlName="startDate"
	  	>
		  <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
		  <mat-datepicker #startDate></mat-datepicker>
		</mat-form-field>
			<mat-form-field>
		  <input 
		  	matInput 
		  	[matDatepicker]="endDate"
		  	placeholder="End Date"
		  	formControlName="endDate"
	  	>
		  <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
		  <mat-datepicker #endDate></mat-datepicker>
		</mat-form-field>
		<div class="buttons">
	    <button mat-flat-button (click)="close()">Cancel</button>
	    <button 
	    	*ngIf="data.showClearOption"
	    	mat-flat-button
	    	(click)="clear()"
    	>
    		Clear
    	</button>
	    <button 
	    	mat-flat-button 
	    	color="primary" 
	    	(click)="select()"
	    	[disabled]="!dateRangeForm.valid"
    	>
    		Select
    	</button>
		</div>
	</form>
</div>

