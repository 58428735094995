import { AutoInvoiceSettings } from '../invoice/auto-invoice-settings';
import { MaterialActiveIngredient } from '../../enums';

export class CompanySettings {
    public invoicePrefix: string = '';
    public autoInvoice: AutoInvoiceSettings = new AutoInvoiceSettings();
    public invoiceConfirmation: boolean = false;
    public defaultActiveIngredient: MaterialActiveIngredient = null;
    public geospatialService: boolean = false;

    constructor(params?: Partial<CompanySettings>) {
        if (!!params) {
            this.invoicePrefix = params.invoicePrefix || this.invoicePrefix;
            this.autoInvoice = !!params.autoInvoice
                ? new AutoInvoiceSettings(params.autoInvoice)
                : this.autoInvoice;
            this.invoiceConfirmation = params.invoiceConfirmation || this.invoiceConfirmation;
            this.defaultActiveIngredient =
                params.defaultActiveIngredient || this.defaultActiveIngredient;
            this.geospatialService = params.geospatialService || this.geospatialService;
        }
    }
}
