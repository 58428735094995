import { WorkOrderInvoiceIdentityWithoutIds } from '@gm2/common';

export class WorkOrderInvoiceIdentity extends WorkOrderInvoiceIdentityWithoutIds {
    public _id: string;

    constructor(params?: Partial<WorkOrderInvoiceIdentity>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
        }
    }
}
