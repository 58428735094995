import { PackageSnapShotWithoutIds } from '@gm2/common';

export class PackageSnapshot extends PackageSnapShotWithoutIds {
    public _id: any;

    constructor(params?: Partial<PackageSnapshot>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
        }
    }
}
