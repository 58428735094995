export enum DisplayedTimezone {
    Location = '[DisplayedTimezone] location',
    User = '[DisplayedTimezone] user'
}

export namespace DisplayedTimezone {
    export function toString(type: DisplayedTimezone): string {
        switch (type) {
            case DisplayedTimezone.Location:
                return 'Location';
            case DisplayedTimezone.User:
                return 'User';
            default:
                return '';
        }
    }

    export const members: DisplayedTimezone[] = [
        DisplayedTimezone.Location,
        DisplayedTimezone.User
    ];
}
