import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { CompanyState } from '@gm2/ui-state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { take, switchMap, tap, withLatestFrom, startWith, map } from 'rxjs/operators';
import { CompanyType } from '@gm2/ui-common';

@Injectable()
export class DashboardPageGuard implements CanActivate {
    @Select(CompanyState.companyType)
    public type: Observable<string>;

    constructor(private _router: Router) {
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        console.log(route);
        return this.type.pipe(
            take(1),
            map(type => {
                switch (route.routeConfig.path) {
                    case 'admin':
                        if (![CompanyType.Admin.toString(), CompanyType.SuperAdmin.toString(), CompanyType.Landscaper.toString()].includes(type)) {
                            this._router.navigateByUrl('/dashboard');
                            return false;
                        } else {
                            return true;
                        }
                    case 'client':
                        if (type !== CompanyType.Client) {
                            this._router.navigateByUrl('/dashboard');
                            return false;
                        } else {
                            return true;
                        }
                    case 'service-partner':
                        if (type !== CompanyType.Service_Partner) {
                            this._router.navigateByUrl('/dashboard');
                            return false;
                        } else {
                            return true;
                        }
                    default:
                        this._router.navigateByUrl('/dashboard');
                        return false;
                }
            }),
        );
    }
}
