
export class CompanyNotificationSettings {
    public email: string;

    constructor(params?: Partial<CompanyNotificationSettings>) {
        if (!!params) {
            this.email = params.email || this.email;
        }
    }
}
