<rfx-grid #rfxGrid [dataSource]="gridSource">
    <rfx-grid-header>
        <h1>Forms</h1>
        <button
            *ngIf="(canCreate$ | async)"
            mat-raised-button
            color="primary"
            type="button"
            [routerLink]="createUrl$ | async"
        >
            Create Form
        </button>
    </rfx-grid-header>
    <rfx-grid-action-bar>
        <rfx-grid-action-bar-delete-control
            [class.hidden]="!(canDelete$ | async)"
            (delete)="deleteItems($event)"
        ></rfx-grid-action-bar-delete-control>
    </rfx-grid-action-bar>
    <rfx-grid-filter>
        <rfx-grid-filter-search-control
            name="searchTerm"
            placeholder="Search"
        ></rfx-grid-filter-search-control>
        <rfx-grid-filter-clear-button
            rfxGridCustomControl
            [rfxGrid]="rfxGrid"
        ></rfx-grid-filter-clear-button>
    </rfx-grid-filter>
    <rfx-grid-list>
        <rfx-grid-list-item
            *rfxGridListItemDef="let item"
            (click)="gridSource.openDetailView(item)"
        >
            <div class="form-name">
                {{ item.name }}
            </div>
            <div class="form-publish-status">
                <mat-chip-list>
                    <mat-chip *ngIf="item.published">Published</mat-chip>
                </mat-chip-list>
            </div>
        </rfx-grid-list-item>
    </rfx-grid-list>
    <rfx-grid-paginator></rfx-grid-paginator>
    <rfx-grid-detail-view *rfxGridDetailViewDef="let view">
        <div rfxGridDetailViewHeader>
            <button
                class="detail-view__button"
                mat-raised-button
                color="primary"
                type="button"
                [disabled]="!view.hasPrevious()"
                (click)="view.previous()"
            >
                Previous
            </button>
            <button
                class="detail-view__button"
                mat-raised-button
                color="primary"
                type="button"
                [disabled]="!view.hasNext()"
                (click)="view.next()"
            >
                Next
            </button>
        </div>
        <div rfxGridDetailViewContent>
            <h2 class="detail-view__title">Form Details</h2>
            <div class="detail-view__group">
                <label>Name:</label>
                <span>{{ view.item.name }}</span>
            </div>
            <div class="detail-view__group">
                <label>Description:</label>
                <span>{{ view.item.description }}</span>
            </div>
            <div class="detail-view__group">
                <label>Published:</label>
                <span>{{ view.item.published ? 'Yes' : 'No' }}</span>
            </div>
            <div class="detail-view__group">
                <label>Created Date:</label>
                <span>{{ view.item.createdDate | date: 'short' }}</span>
            </div>
            <div class="detail-view__group">
                <label>Last Modified:</label>
                <span>{{ view.item.modifiedDate | date: 'short' }}</span>
            </div>
        </div>
        <div class="action-btns" rfxGridDetailViewFooter>
            <button
                *ngIf="(canDelete$ | async)"
                mat-icon-button
                color="warn"
                (click)="deleteItem(view.item)"
                matTooltip="Delete Form"
            >
                <mat-icon>delete</mat-icon>
            </button>
            <button
                *ngIf="(canExportData$ | async)"
                mat-icon-button
                (click)="exportCSV(view.item)"
                matTooltip="Export Responses (.csv)"
            >
                <mat-spinner
                    class="csv-spinner"
                    [diameter]="17"
                    *ngIf="(requestingCSV$ | async); else exportIcon"
                ></mat-spinner>
                <ng-template #exportIcon>
                    <mat-icon>pie_chart</mat-icon>
                </ng-template>
            </button>
            <button
                *ngIf="(canEdit$ | async)"
                mat-icon-button
                (click)="editItem(view.item)"
                matTooltip="Edit Form"
            >
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="respondToForm(view.item)" matTooltip="Complete Form">
                <mat-icon>description</mat-icon>
            </button>
        </div>
    </rfx-grid-detail-view>
</rfx-grid>
