<h2 mat-dialog-title>{{ data.message }}</h2>
<div mat-dialog-content>
	<form [formGroup]="dateForm">
		<mat-form-field>
		  <input 
		  	matInput 
		  	[matDatepicker]="date"
		  	placeholder="Date"
		  	formControlName="date"
	  	>
		  <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
		  <mat-datepicker #date></mat-datepicker>
		</mat-form-field>
		<div class="buttons">
	    <button 
	    	mat-flat-button 
	    	(click)="close()"
    	>
    		Cancel
    	</button>
	    <button 
	    	mat-flat-button 
	    	color="primary" 
	    	(click)="select()"
	    	[disabled]="!dateForm.valid"
    	>
    		Select
    	</button>
		</div>
	</form>
</div>

