export enum RfxFormFieldConditionRulesBoolean {
    And = '[RfxFormFieldConditionRulesBoolean] and',
    Or = '[RfxFormFieldConditionRulesBoolean] or'
}

export namespace RfxFormFieldConditionRulesBoolean {
    export function toString(type: RfxFormFieldConditionRulesBoolean): string {
        switch (type) {
            case RfxFormFieldConditionRulesBoolean.And:
                return 'All';
            case RfxFormFieldConditionRulesBoolean.Or:
                return 'One';
            default:
                return '';
        }
    }

    export const members: RfxFormFieldConditionRulesBoolean[] = [
        RfxFormFieldConditionRulesBoolean.And,
        RfxFormFieldConditionRulesBoolean.Or
    ];
}
