import { LocationServiceHistoryWithoutIds } from '@gm2/common';

export class LocationServiceHistory extends LocationServiceHistoryWithoutIds {
    public serviceId: string;

    constructor(params?: Partial<LocationServiceHistory>) {
        super(params);
        if (!!params) {
            this.serviceId = params.serviceId || this.serviceId;
        }
    }
}
