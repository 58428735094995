<form [formGroup]="form" (ngSubmit)="submit(form)">
    <h2 mat-dialog-title>Select Preferred Bid</h2>
    <div mat-dialog-content class="bid-select">
        <mat-radio-group required formControlName="companyId">
            <mat-list class="column-list">
                <mat-list-item *ngFor="let bid of (bids$ | async)">
                    <mat-radio-button value="{{ bid.companyIdentity._id }}">
                        <mat-card>
                            <h4>{{ bid.companyIdentity.name }}
                                <fa-icon
                                style="float: right"
                                [icon]="['fas', 'file-alt']"
                                (click)="
                                    openQuestionnaire(bid.companyIdentity._id)
                                "
                            ></fa-icon>
                            </h4>
                            <div *ngIf="!!bid.monthlyRate">
                                $ {{ bid.monthlyRate }} = Monthly Rate
                            </div>
                            <div *ngIf="bid.services.length > 0">
                                <div *ngFor="let s of bid.services">
                                    $ {{ s.amount }} = {{ s.name }}
                                </div>
                            </div>
                        </mat-card>
                    </mat-radio-button>
                </mat-list-item>
            </mat-list>
        </mat-radio-group>
    </div>
    <div class="buttons" mat-dialog-actions align="end">
        <button type="button" mat-raised-button type="button" (click)="close()">Cancel</button>
        <button type="button" mat-raised-button type="submit" color="primary">
            Award First Approval
        </button>
    </div>
</form>
