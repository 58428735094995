import { TimesheetValidationHistoryWithoutIds } from './timesheet-validation-history';
import { TimesheetValidationStatus } from '../../enums';
import { isNull } from '../../utils';

export class TimesheetValidationWithoutIds {
    public status: TimesheetValidationStatus = TimesheetValidationStatus.Submitted;
    public history: TimesheetValidationHistoryWithoutIds[] = [];

    constructor(params?: Partial<TimesheetValidationWithoutIds>) {
        if (!!params) {
            this.status = !isNull(params.status) ? params.status : this.status;
            this.history = Array.isArray(params.history)
                ? params.history.map(history => new TimesheetValidationHistoryWithoutIds(history))
                : this.history;
        }
    }
}
