export enum Status {
    InActive = 0,
    Active = 1
}

export namespace Status {
	export function toString(type: Status): string {
		switch(type){
			case Status.Active: 
				return 'Active';
			case Status.InActive:
				return 'InActive';
			default: 
				return '';
		}
	}

	export const members: Status[] = [
		Status.Active,
		Status.InActive
	];
}