import { ContactType } from '@gm2/common';
import { CompanyProfileDto } from './company-profile-dto';

export class ServicePartnerCreateDto {
    public companyProfile: CompanyProfileDto = null;
    public companyPreferredContact: ContactType = null;

    constructor(params?: Partial<ServicePartnerCreateDto>) {
        if (!!params) {
            this.companyProfile = params.companyProfile || this.companyProfile;
            this.companyPreferredContact =
                params.companyPreferredContact || this.companyPreferredContact;
        }
    }
}
