import { LocationSimpleWithoutIds } from './location-simple';

export class LocationSimpleWithServiceTypesWithoutIds extends LocationSimpleWithoutIds {
    // This field is for the service types a particlular service partner is performing for the location
    // Gotten off the location rfps
    // After this field was added, the location.serviceTypes was added to the LocationSimple
    // Field naming is confusing now, but will leave them as they are
    public serviceTypeNames: string[] = [];

    constructor(params?: Partial<LocationSimpleWithServiceTypesWithoutIds>) {
        super(params);
        if (!!params) {
            this.serviceTypeNames = params.serviceTypeNames || this.serviceTypeNames;
        }
    }
}
