import { WorkOrder } from './WorkOrder';
import { WorkOrderPhaseDto } from './WorkOrderPhaseDto';
import {
    WorkOrderGMR,
    WorkOrderReportedBy,
    WorkOrderWorkCategory,
    WorkOrderPriority,
    WorkOrderBillingType
} from '@gm2/common';

export class WorkOrderCreateDto {
    public clientId: string = null;
    public locationId: string = null;
    public workOrderNumber: string = '';
    public servicePartnerInvoiceNumber: string = '';
    public gmr: WorkOrderGMR = null;
    public safety: boolean = null;
    public completeByDate: Date = null;
    public scheduledDate: Date = null;
    public reportedBy: WorkOrderReportedBy = null;
    public workCategory: WorkOrderWorkCategory = null;
    public priority: WorkOrderPriority = null;
    public billingType: WorkOrderBillingType = null;
    public phases: WorkOrderPhaseDto[] = null;
    public referredBy: string = null;
    public saveAsTemplate: boolean = null;
    public templateName: string = '';
    public serviceDueDate: Date = null;
    public qbInvoiceNumber: string = '';
    public clientWorkOrderNumber: string = '';

    constructor(params?: Partial<WorkOrderCreateDto>) {
        if (!!params) {
            this.clientId = params.clientId || this.clientId;
            this.locationId = params.locationId || this.locationId;
            this.workOrderNumber = params.workOrderNumber || this.workOrderNumber;
            this.servicePartnerInvoiceNumber = params.servicePartnerInvoiceNumber || this.servicePartnerInvoiceNumber;
            this.gmr = params.gmr || this.gmr;
            this.safety = (params.safety !== null && params.safety !== undefined)
                ? params.safety : this.safety;
            this.completeByDate = params.completeByDate || this.completeByDate;
            this.scheduledDate = params.scheduledDate || this.scheduledDate;
            this.reportedBy = params.reportedBy || this.reportedBy;
            this.workCategory = params.workCategory || this.workCategory;
            this.priority = params.priority || this.priority;
            this.billingType = params.billingType || this.billingType;
            this.phases = params.phases || this.phases;
            this.referredBy = params.referredBy || this.referredBy;
            this.saveAsTemplate = (params.saveAsTemplate !== null && params.saveAsTemplate !== undefined)
                ? params.saveAsTemplate : this.saveAsTemplate;
            this.templateName = params.templateName || this.templateName;
            this.serviceDueDate = params.serviceDueDate || this.serviceDueDate;
            this.qbInvoiceNumber = params.qbInvoiceNumber || this.qbInvoiceNumber;
            this.clientWorkOrderNumber = params.clientWorkOrderNumber || this.clientWorkOrderNumber;
        }
    }
}
