import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChildMenuItem, Company, MenuItem, Permission, User } from '@gm2/ui-common';
import { Observable, of, Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { AuthState, CompanyState, UserState } from '@gm2/ui-state';
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import { hasAllPermissions, hasAnyPermission, hasPermission } from '@gm2/common';

interface SidebarState {
    opened: boolean;
    menuItems: MenuItem[];
    expandedItem: MenuItem;
    pinned: boolean;
    tooltipDelay: number;
}


@Component({
    selector: 'gm2-secured-container',
    templateUrl: './secured-container.component.html',
    styleUrls: ['./secured-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SecuredContainerComponent implements OnInit, OnDestroy {
    @Select(UserState.user)
    public user$: Observable<User>;
    @Select(CompanyState.company)
    public company$: Observable<Company>;
    @Select(UserState.userPermissions)
    public permissions$: Observable<Permission[]>;
    @Select(UserState.userNotificationCount)
    public notificationCount$: Observable<number>;

    @Emitter(AuthState.setAuthToken)
    public clearAuthToken: Emittable<void>;
    @Emitter(CompanyState.setCompany)
    public clearCompany: Emittable<void>;
    @Emitter(UserState.setUser)
    public clearUser: Emittable<void>;
    @Emitter(UserState.setNotificationCount)
    public clearNotifications: Emittable<void>;

    private _mobileQueryListener: () => void;
    private _subs: Subscription = new Subscription();

    public permissions: Permission[] = [];
    public sidebarExpanded: boolean = true;
    public mobileQuery: MediaQueryList;
    public siteMenu$: Observable<MenuItem[]>;
    public isExpanded = true;
    public isPinned = false;

    private menuState: SidebarState = {
        opened: false,
        menuItems: [],
        expandedItem: null,
        pinned: false,
        tooltipDelay: 500,
    };

    constructor(
        private _media: MediaMatcher,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
    ) {
        this.mobileQuery = this._media.matchMedia('(max-width: 767px)');
        this._mobileQueryListener = (): void => this._changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        this._subs.add(this.permissions$.subscribe(p => (this.permissions = p)));
        this._subs.add(
            this._router.events
                .pipe(filter(e => e instanceof NavigationEnd))
                .subscribe((e: NavigationEnd) => this._updateSiteMenu(e.urlAfterRedirects)),
        );
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
        this._subs.unsubscribe();
    }

    public sidebarToggle(snav: MatSidenav, shouldOpen?: boolean): void {
        if (typeof shouldOpen === 'boolean') {
            if (shouldOpen === true) {
                snav.open();
            } else {
                snav.close();
            }
        } else {
            snav.toggle();
        }
    }

    public logout(): void {
        this.clearAuthToken.emit();
        this.clearUser.emit();
        this.clearCompany.emit();
        this.clearNotifications.emit();
        this._router.navigateByUrl('/auth/login');
    }

    private _updateSiteMenu(currentUrl: string): void {
        const urlParts = currentUrl.split('/');
        urlParts.shift(); // Remove blank since urls start with '/'
        const primarySlug = urlParts
            .shift()
            .toLowerCase()
            .trim();
        this.siteMenu$ = of(
            [
                new MenuItem({
                    label: 'Dashboard',
                    icon: ['far', 'chart-line'],
                    route: '/dashboard',
                }),
                new MenuItem({
                    label: 'Operations',
                    icon: ['far', 'cogs'],
                    active: primarySlug === 'operations',
                    children: [
                        new ChildMenuItem({
                            label: 'Bids',
                            route: '/operations/bid/list',
                            permission: Permission.RfpListDisplay,
                        }),
                        new ChildMenuItem({
                            label: 'Invoices',
                            route: '/operations/invoice/list',
                            permission: Permission.InvoiceView,
                        }),
                        new ChildMenuItem({
                            label: 'Locations',
                            route: '/operations/location/list',
                            permission: Permission.LocationDisplay,
                        }),
                        // new ChildMenuItem({
                        //     label: 'Company Materials',
                        //     route: '/operations/company-material/list',
                        //     permission: Permission.CompanyMaterialApprove,
                        // }),
                        new ChildMenuItem({
                            label: 'Company Services',
                            route: '/operations/company-service/list',
                            permission: Permission.CompanyServiceApprove,
                        }),
                        new ChildMenuItem({
                            label: 'Service Activites',
                            route: '/operations/service-activities/list',
                            permission: Permission.LocationServiceActivityListDisplay,
                        }),
                        new ChildMenuItem({
                            label: 'Timesheets',
                            route: '/operations/timesheet/list',
                            permission: Permission.TimesheetManage,
                        }),
                        new ChildMenuItem({
                            label: 'Timesheet Validation',
                            route: '/operations/timesheet-validation/list',
                            permission: Permission.TimesheetValidation,
                        }),
                        new ChildMenuItem({
                            label: 'Work Orders',
                            route: '/operations/work-orders/list',
                            permission: Permission.WorkorderView,
                        }),
                        new ChildMenuItem({
                            label: 'Work Order Invoices',
                            route: '/operations/work-order-invoice/list',
                            permission: Permission.InvoiceView,
                        }),
                        new ChildMenuItem({
                            label: 'Forms',
                            route: '/operations/forms/list',
                            permission: Permission.FormList,
                        }),
                    ],
                }),
                new MenuItem({
                    label: 'Manage',
                    icon: ['far', 'key'],
                    active: primarySlug === 'manage',
                    children: [
                        new ChildMenuItem({
                            label: 'Companies',
                            route: '/manage/company/list',
                            permission: Permission.CompanyViewExternal,
                        }),
                        new ChildMenuItem({
                            label: 'Contracts',
                            route: '/manage/contract/list',
                            permission: [
                                Permission.ContractView,
                                Permission.ExternalResources,
                            ],
                        }),
                        new ChildMenuItem({
                            label: 'Crews',
                            route: '/manage/crew/list',
                            permission: Permission.CrewView,
                        }),
                        new ChildMenuItem({
                            label: 'Packages',
                            route: '/manage/package/list',
                            permission: Permission.PackageView,
                        }),
                        new ChildMenuItem({
                            label: 'Services',
                            route: '/manage/service/list',
                            permission: Permission.ServiceView,
                        }),
                        new ChildMenuItem({
                            label: 'Service Types',
                            route: '/manage/service-type/list',
                            permission: Permission.ServiceTypeDisplay,
                        }),
                        new ChildMenuItem({
                            label: 'Tags',
                            route: '/manage/tags/list',
                            permission: Permission.TagView,
                        }),
                    ],
                }),
                new MenuItem({
                    label: 'Support',
                    icon: ['far', 'hands-helping'],
                    active: primarySlug === 'support',
                    children: [
                        new ChildMenuItem({
                            label: 'Invites',
                            route: '/support/invite',
                        }),
                        // Hide this till page is done
                        // new ChildMenuItem({
                        //     label: 'Reports',
                        //     route: '/support/reports/list',
                        //     permission: Permission.ReportingManage
                        // }),
                        new ChildMenuItem({
                            label: 'Roles',
                            route: '/support/role/list',
                            permission: Permission.RoleView,
                        }),
                        new ChildMenuItem({
                            label: 'Users',
                            route: '/support/user/list',
                            permission: Permission.UserView,
                        }),
                        new ChildMenuItem({
                            label: 'Login Builder',
                            route: '/support/login/list',
                            permission: Permission.LoginBuilderView,
                        }),
                        // 2021-12-06 temporarily removed help section because
                        // it's broken
                    ],
                }),
                new MenuItem({
                    label: 'Geospatial',
                    icon: ['far', 'map-marker-alt'],
                    active: primarySlug === 'geospatial',
                    children: [
                        new ChildMenuItem({
                            label: 'Geospatial Services',
                            route: '/geospatial/manage',
                            permission: Permission.ManageGeospatialServices
                        })
                    ]
                }),
                new MenuItem({
                    label: 'Profile',
                    icon: ['far', 'user'],
                    active: primarySlug === 'profile',
                    children: [
                        new ChildMenuItem({
                            label: 'My Profile',
                            route: '/profile/personal',
                            permission: Permission.UserProfile,
                        }),
                        new ChildMenuItem({
                            label: 'My Company',
                            route: '/profile/company',
                            permission: Permission.CompanyProfile,
                        }),
                        new ChildMenuItem({
                            label: 'Material Pricing',
                            route: '/profile/material',
                            permission: Permission.CompanyMaterialCreate, // TODO right permission?
                        }),
                        new ChildMenuItem({
                            label: 'Service Pricing',
                            route: '/profile/service',
                            permission: Permission.CompanyServiceCreate, // TODO right permission?
                        }),
                        new ChildMenuItem({
                            label: 'Notifications',
                            route: '/profile/notifications',
                            permission: Permission.UserNotificationsEdit,
                        }),
                        new ChildMenuItem({
                            label: 'Notification Settings',
                            route: '/profile/notificationSettings',
                            permission: Permission.UserNotificationsEdit,
                        }),
                        // new ChildMenuItem({
                        //     label: 'Questionnaire',
                        //     route: '/profile/questionnaire'
                        // })
                    ],
                }),
                new MenuItem({
                    label: 'Administration',
                    icon: ['far', 'key'],
                    active: primarySlug === 'administration',
                    children: [
                        new ChildMenuItem({
                            label: 'Reports(Beta)',
                            route: '/support/reports/create',
                            permission: Permission.ReportingManage,
                        }),
                        new ChildMenuItem({
                            label: 'QuickBooks Sync',
                            route: '/administration/quickbooks-sync',
                            permission: [
                                Permission.ManageQBConnection,
                                Permission.TriggerQBManually,
                                Permission.ConfigureQBSync,
                            ],
                        }),
                        new ChildMenuItem({
                            label: 'Geospatial service usage',
                            route: '/administration/geospatial/usage',
                            permission: Permission.SuperAdmin,
                        }),
                    ],
                }),
            ]
                .filter(item => {
                    if (!!item.permission) {
                        // If permission on item is set, use that to show/hide top level menu item
                        return hasPermission(this, item.permission);
                    } else {
                        // Otherwise, show/hide section based on if user has at least one of the child section permissions
                        if (!item.children || item.children.length < 1) {
                            return true;
                        }

                        const permissions = item.children.map(child => child.permission);
                        for (const permission of permissions) {
                            if (hasAllPermissions(this, permission)) {
                                return true;
                            }
                        }
                    }
                })
                .map(menu => ({
                    ...menu,
                    children: menu.children.filter((child) => {
                        switch (child.permissionRule) {
                            case Permission.PermissionRule.HasAll:
                                return hasAllPermissions(this, child.permission);
                            case Permission.PermissionRule.HasOne:
                                return hasAnyPermission(this, child.permission)
                        }
                    }),
                })),
        );
    }

    public expandItem(item: MenuItem) {
        this.menuState.expandedItem = !this.isExpanded || item !== this.menuState.expandedItem ? item : null;
        this.isExpanded = true;
    }

    public toggleOpen(expanded: boolean) {
        this.isExpanded = expanded;
    }
}
