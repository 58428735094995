export enum InvoiceStatus {
    InProgress = '[InvoiceStatus] InProgress',
    Submitted = '[InvoiceStatus] Submitted',
    Approved = '[InvoiceStatus] Approved',
    Rejected = '[InvoiceStatus] Rejected',
    Posted = '[InvoiceStatus] Posted',
    PaymentProcessed = '[InvoiceStatus] PaymentProcessed',
    PaidNotQualified = '[InvoiceStatus] PaidNotQualified'
}

export namespace InvoiceStatus {
    export function toString(type: InvoiceStatus): string {
        switch (type) {
            case InvoiceStatus.InProgress:
                return 'Incomplete';
            case InvoiceStatus.Submitted:
                return 'Submitted';
            case InvoiceStatus.Approved:
                return 'Approved';
            case InvoiceStatus.Rejected:
                return 'Declined';
            case InvoiceStatus.Posted:
                return 'Posted';
            case InvoiceStatus.PaymentProcessed:
                return 'Payment Processed';
            case InvoiceStatus.PaidNotQualified:
                return 'Not Qualified';
            default:
                return '';
        }
    }

    export function toCssColor(type: InvoiceStatus): string {
        // These colors are taken from ACE theme used in old site taking into
        // account our customizations
        switch (type) {
            case InvoiceStatus.InProgress:
                return '#333333';
            case InvoiceStatus.Submitted:
                return '#FEE188';
            case InvoiceStatus.Approved:
                return '#3A87AD';
            case InvoiceStatus.Rejected:
                return '#D15B47';
            case InvoiceStatus.Posted:
                return '#9585BF';
            case InvoiceStatus.PaymentProcessed:
                return '#82AF6F';
            case InvoiceStatus.PaidNotQualified:
                return '#919191';
            default:
                return '#919191';
        }
    }

    export const members: InvoiceStatus[] = [
        InvoiceStatus.InProgress,
        InvoiceStatus.Submitted,
        InvoiceStatus.Approved,
        InvoiceStatus.Rejected,
        InvoiceStatus.Posted,
        InvoiceStatus.PaymentProcessed,
        InvoiceStatus.PaidNotQualified
    ];

    export function toIconSource(s: InvoiceStatus): string {
        switch (s) {
            case InvoiceStatus.InProgress:
                return 'assets/invoice/invoice-inProgress.png';
            case InvoiceStatus.Submitted:
                return 'assets/invoice/invoice-submitted.png';
            case InvoiceStatus.Approved:
                return 'assets/invoice/invoice-approved.png';
            case InvoiceStatus.Rejected:
                return 'assets/invoice/invoice-declined.png';
            case InvoiceStatus.Posted:
                return 'assets/invoice/invoice-posted.png';
            case InvoiceStatus.PaymentProcessed:
                return 'assets/invoice/invoice-paymentProcessed.png';
            case InvoiceStatus.PaidNotQualified:
                return 'assets/invoice/invoice-paidnotqualified.png';
            default:
                return '';
        }
    }
}
