import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { url } from '../../consts/url.const';
import { Observable } from 'rxjs';
import { InviteStatus } from '../../enums/invite.enum';
import { Company } from '@gm2/ui-common';

export declare interface Invite {
    _id?: string;
    status: InviteStatus;
    firstName: string;
    companyName: string;
    lastName: string;
    email: string;
    createdBy: string;
    expired_at: Date;
    roleIds: string[];
    companyInfo: Company;
}


@Injectable()
export class InviteService {
    constructor(private httpClient: HttpClient) {
    }

    public sendInvite(data: { firstName: string, lastName: string, email: string }): Observable<any> {
        return this.httpClient.post(url.invite.crud, data);
    }

    public getAll(params: { page: number, perPage: number }): Observable<any> {
        return this.httpClient.get(`${url.invite.crud}?page=${params.page}&perPage=${params.perPage}`);
    }

    public getOne(id: string): Observable<Invite> {
        return this.httpClient.get<Invite>(`${url.invite.crud}/${id}`);
    }

    public delete(id: string): Observable<Invite> {
        return this.httpClient.delete<Invite>(`${url.invite.one(id)}`);
    }

    public resend(id: string): Observable<Invite> {
        return this.httpClient.post<Invite>(`${url.invite.resend(id)}`, {});
    }

    public cancel(id: string): Observable<Invite> {
        return this.httpClient.post<Invite>(`${url.invite.cancel(id)}`, {});
    }
}
