export enum FieldType {
    Dropdown = 'dropdown',
    OgmCrlFields = 'onegm_crl_fields',
    ParagraphText = 'paragraph_text',
    RadioCheckbox = 'radio_checkbox',
    SingleText = 'single_text',
    Title = 'title'
}

export namespace FieldType {
    export function toString(type: FieldType): string {
        switch (type) {
            case FieldType.Dropdown:
                return 'Dropdown';
            case FieldType.OgmCrlFields:
                return 'Ogm Crl Fields';
            case FieldType.ParagraphText:
                return 'Paragraph Text';
            case FieldType.RadioCheckbox:
                return 'Radio Checkbox';
            case FieldType.SingleText:
                return 'Single Text';
            case FieldType.Title:
                return 'Title';
            default:
                return '';
        }
    }

    export const members: FieldType[] = [
        FieldType.Dropdown,
        FieldType.OgmCrlFields,
        FieldType.ParagraphText,
        FieldType.RadioCheckbox,
        FieldType.SingleText,
        FieldType.Title
    ];
}
