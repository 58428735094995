export enum WorkOrderInvoiceable {
    Invoiceable = '[WorkOrderInvoiceable] Invoiceable',
    NonInvoiceableLegacy = '[WorkOrderInvoiceable] NonInvoiceableLegacy'
}

export namespace WorkOrderInvoiceable {
    export function toString(val: WorkOrderInvoiceable): string {
        switch (val) {
            case WorkOrderInvoiceable.Invoiceable:
                return 'Invoiceable';
            case WorkOrderInvoiceable.NonInvoiceableLegacy:
                return 'Non-Invoiceable, Legacy';
            default:
                return '';
        }
    }

    export const members: WorkOrderInvoiceable[] = [
        WorkOrderInvoiceable.Invoiceable,
        WorkOrderInvoiceable.NonInvoiceableLegacy
    ];
}
