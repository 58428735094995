import { LocationWithoutIds } from './location';
import { LocationIdentityWithoutIds } from './location-identity';
import { MongoGeoPoint } from '../base/mongo-geo-point';

/**
 * Fields to project from Location type
 */
type Projection = 'gps' | 'identity';

/**
 * Only the fields from location relevant to it's mapping origin
 */
export class LocationOrigin implements Pick<LocationWithoutIds, Projection> {
    public identity: LocationIdentityWithoutIds = null;
    public gps: MongoGeoPoint = null;

    constructor(params?: Partial<LocationOrigin>) {
        if (!!params) {
            this.identity = params.identity
                ? new LocationIdentityWithoutIds(params.identity)
                : this.identity;
            this.gps = params.gps
                ? new MongoGeoPoint(params.gps)
                : this.gps;
        }
    }
}
