import { UserIdentity } from '../user/UserIdentity';

export class WorkOrderLaborCompletionDto {
	public _id: string = '';
	public isMobile: boolean = false;
	public timespent: number = 0;

	constructor(params?: Partial<WorkOrderLaborCompletionDto>){
		if(!!params){
			this._id = params._id || this._id;
			this.isMobile = params.isMobile || this.isMobile;
			this.timespent = params.timespent || this.timespent;
		}
	}
}