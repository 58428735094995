import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { State } from './State';
import { RfxValidators } from './Validators';

/**
 * Shape of a standard address
 */
export interface AddressModel {
    /** Main Address Line */
    line1: string;
    /** Optional Address Line */
    line2: string;
    /** City */
    city: string;
    /** {@link EState} */
    state: State;
    /** Zip (xxxxx OR xxxxx-xxxx) */
    zip: string;
}

/**
 * Address Class
 *
 * Contains the logic needed for handling address both inside
 * and outside of forms. Use `Address.toFormGroup(address?)` to
 * create an instance of a FormGroup for an address with
 * validators. `new Address()` will either create a new address
 * from an existing address or from a FormGroup
 */
export class Address implements AddressModel {
    /** Main Address Line */
    public line1: string = '';
    /** Optional Address Line */
    public line2: string = '';
    /** City */
    public city: string = '';
    /** {@link EState} */
    public state: State = null;
    /** Zip (xxxxx OR xxxxx-xxxx) */
    public zip: string = '';

    /**
     * Creates a new address
     */
    constructor(params?: Partial<Address> | UntypedFormGroup) {
        if (!!params) {
            if (params instanceof UntypedFormGroup) {
                this.line1 = params.get('line1').value || this.line1;
                this.line2 = params.get('line2').value || this.line2;
                this.city = params.get('city').value || this.city;
                this.state = params.get('state').value || this.state;
                this.zip = params.get('zip').value || this.zip;
            } else {
                this.line1 = params.line1 || this.line1;
                this.line2 = params.line2 || this.line2;
                this.city = params.city || this.city;
                this.state = params.state || this.state;
                this.zip = params.zip || this.zip;
            }
        }
    }

    /**
     * Address.toFormGroup
     *
     * Creates a FormGroup with validators for an address
     *
     * @param address Prepopulate the FormGroup with existing address data
     * @returns FormGroup
     */
    public static toFormGroup(address: Address = null): UntypedFormGroup {
        const regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
        return new UntypedFormGroup({
            line1: new UntypedFormControl(address ? address.line1 : '', [Validators.required]),
            line2: new UntypedFormControl(address ? address.line2 : ''),
            city: new UntypedFormControl(address ? address.city : '', [Validators.required]),
            state: new UntypedFormControl(address ? address.state : null, [Validators.required]),
            zip: new UntypedFormControl(address ? address.zip : '', [
                Validators.required,
                RfxValidators.zipCode
            ])
        });
    }
}
