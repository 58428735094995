import { FormWithoutIds } from '@gm2/common';

export class Form extends FormWithoutIds {
    public _id: string = '';

    constructor(params?: Partial<Form>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
        }
    }
}
