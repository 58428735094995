import { Injectable } from '@angular/core';
import {
    HttpService,
} from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReportDataService extends HttpService {

    public exportUserReportData(
        sendToEmail: string
    ): Observable<any> {
        // return null;
        return this._http
            .get<any>(`https://reports-merge.westus3.azurecontainer.io/reportData/user/csvExport?email=${sendToEmail}`)
            .pipe(map(res => res));
    }

    public exportLocationReportData(
        sendToEmail: string
    ): Observable<any> {
        // return null;
        return this._http
            .get<any>(`https://reports-merge.westus3.azurecontainer.io/reportData/location/csvExport?email=${sendToEmail}`)
            .pipe(map(res => res));
    }

    public exportDailyServiceReport(serviceTypeId: string, day: string, sendToEmail: string): Observable<any> {
            // return null;
    return this._http
        .get<any>(
        `https://reports-merge.westus3.azurecontainer.io/reportData/dailyService/csvExport?serviceTypeId=${serviceTypeId}&&email=${sendToEmail}&&day=${day}`
    )
    .pipe(map(res => res));
    }

    public exportServicesReportData(serviceTypeId: string, startDate: string, endDate: string, sendToEmail: string): Observable<any> {
        // return null;
    return this._http
        .get<any>(
        `https://reports-merge.westus3.azurecontainer.io/reportData/allServices/csvExport?serviceTypeId=${serviceTypeId}&&email=${sendToEmail}&&startDate=${startDate}&&endDate=${endDate}`
    )
    .pipe(map(res => res));
    }

    public exportClientInvoiceReportData(serviceTypeId: string, clientId: string, startDate: string, endDate: string, sendToEmail: string): Observable<any> {
        // return null;
    return this._http
        .get<any>(
        `https://reports-merge.westus3.azurecontainer.io/reportData/clientInvoice/csvExport?serviceTypeId=${serviceTypeId}&&clientId=${clientId}&&email=${sendToEmail}&&startDate=${startDate}&&endDate=${endDate}`
    )
    .pipe(map(res => res));
    }

    public exportSpBillingReportData(serviceTypeId: string, spId: string, startDate: string, endDate: string, sendToEmail: string): Observable<any> {
        // return null;
    return this._http
        .get<any>(
        `https://reports-merge.westus3.azurecontainer.io/reportData/spBilling/csvExport?serviceTypeId=${serviceTypeId}&&spId=${spId}&&email=${sendToEmail}&&startDate=${startDate}&&endDate=${endDate}`
    )
    .pipe(map(res => res));
    }


    public exportTimeSpentReportData(serviceTypeId: string, startDate: string, endDate: string, sendToEmail: string, timesheetType: string, crewType: string, spValue: string, internalCrew: string): Observable<any> {
        // return null;
    return this._http
        .get<any>(
        `https://reports-merge.westus3.azurecontainer.io/reportData/timeSpent/csvExport?serviceTypeId=${serviceTypeId}&&tsType=${timesheetType}&&crewType=${crewType}&&spValue=${spValue}&&internalCrew=${internalCrew}&&email=${sendToEmail}&&startDate=${startDate}&&endDate=${endDate}`
    )
    .pipe(map(res => res));
    }

    public exportActiveLocationsReportData(plId: string, startDate: string, endDate: string, sendToEmail: string): Observable<any> {
        // return null;
    return this._http
        .get<any>(
        `https://reports-merge.westus3.azurecontainer.io/reportData/activeLocation/csvExport?email=${sendToEmail}&&plId=${plId}&&startDate=${startDate}&&endDate=${endDate}`
    )
    .pipe(map(res => res));
    }


    public exportWoBillingReportData(billingType: string, startDate: string, endDate: string, sendToEmail: string): Observable<any> {
        // return null;
    return this._http
        .get<any>(
        `https://reports-merge.westus3.azurecontainer.io/reportData/woBilling/csvExport?billingType=${billingType}&&email=${sendToEmail}&&startDate=${startDate}&&endDate=${endDate}`
    )
    .pipe(map(res => res));
    }


    public exportAwardedBidsReportData(
        serviceTypeId: string,
        sendToEmail: string,
        year: string
    ): Observable<any> {
        // return null;
        return this._http
            .get<any>(`https://reports-merge.westus3.azurecontainer.io/reportData/bids/csvExport?serviceTypeId=${serviceTypeId}&&email=${sendToEmail}&&year=${year}`)
            .pipe(map(res => res));
    }

    public exportQualityControlReportData(
        sendToEmail: string,
        month: string,
        year: string
    ): Observable<any> {
        // return null;
        return this._http
            .get<any>(`https://processing-server-main-v2.westus2.azurecontainer.io/reportData/qc/csvExport?&&email=${sendToEmail}&&year=${year}&&month=${month}`)
            .pipe(map(res => res));
    }
}
