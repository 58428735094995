<div class="header">
    <h1>{{ (form$ | async)?.name }}</h1>
    <button mat-button type="button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
        Back to Forms
    </button>
</div>
<rfx-form-generator
    [rfxForm$]="form$"
    [preview]="false"
    (responseSuccess)="onSuccess()"
    (responseFailure)="onFailure($event)"
></rfx-form-generator>
