import { UserWithoutIds, Permission } from '@gm2/common';
import { UserProfile } from './UserProfile';
import { CompanyProfile } from '../company/CompanyProfile';
import { CompanyIdentity } from '../company/CompanyIdentity';
import { Company } from '../company/Company';

export class User extends UserWithoutIds {
    public _id: string = '';
    public companyId: string = '';
    public companyIdentity: CompanyIdentity = new CompanyIdentity();
    public companyProfile: CompanyProfile = new CompanyProfile();
    public crewName: string = '';
    public crewSize: number = 0;
    public roleIds: string[] = [];
    public tagIds: string[] = [];
    public profile: UserProfile = new UserProfile();
    public permissions: Permission[] = [];
    public companyInfo: Company = null;
    public isMulti: boolean = false;
    public visibleForCompanies: string[] = [];

    constructor(params?: Partial<User>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.companyId = params.companyId || this.companyId;
            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentity(params.companyIdentity)
                : this.companyIdentity;
            this.companyProfile = !!params.companyProfile
                ? new CompanyProfile(params.companyProfile)
                : this.companyProfile;
            this.crewName = !!params.crewName ? params.crewName : this.crewName;
            this.crewSize = !!params.crewSize ? params.crewSize : this.crewSize;
            this.roleIds = Array.isArray(params.roleIds) ? params.roleIds : this.roleIds;
            this.tagIds = Array.isArray(params.tagIds) ? params.tagIds : this.tagIds;
            this.profile = !!params.profile ? new UserProfile(params.profile) : this.profile;
            this.permissions = Array.isArray(params.permissions)
                ? params.permissions
                : this.permissions;
            this.companyInfo = !!params.companyInfo ? params.companyInfo : this.companyInfo;
            this.isMulti = !!params.isMulti ? params.isMulti : this.isMulti;
            this.visibleForCompanies = params.visibleForCompanies || this.visibleForCompanies;
        }
    }
}
