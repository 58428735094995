import { Injectable } from '@angular/core';
import {
    HttpService,
    Post,
    MapClass,
    MapValue,
    Body,
    Get,
    Path,
    FakeResponse,
    Query,
    Delete,
    Put,
    ResponseType,
} from '@refactor/ngx/http';
import {
    Location,
    LocationSimple,
    LocationCreateDto,
    LocationServiceHistory,
    LocationSimpleWithServiceTypes, ServiceType,
} from '../models';
import { Observable } from 'rxjs';
import { PaginatedList, GridParams } from '@refactor/common';
import { AddressDto } from '../../../../../../apps/nst-gm2/src/app/controllers/address-dto';
import { Address } from '@gm2/common';

@Injectable({
    providedIn: 'root',
})
export class LocationService extends HttpService {
    @Post('/location')
    public createLocation(@Body() data: FormData): Observable<string> {
        return null;
    }

    @Put('/location/:id')
    public editLocation(@Path('id') id: string, @Body() data: FormData): Observable<any> {
        return null;
    }

    @Get('/location/:locationId')
    @MapClass(Location)
    public getLocation(@Path('locationId') id: string): Observable<Location> {
        console.log('getting location');
        return null;
    }

    @Post('/location/list')
    @MapValue(
        res =>
            new PaginatedList<Location>({
                count: res.count,
                docs: res.docs.map(d => new Location(d)),
            }),
    )
    public getLocationList(@Body() params: GridParams): Observable<PaginatedList<Location>> {
        return null;
    }

    @Get('/location/list/simple')
    public getSimpleList(@Query('companyId') companyId?: string): Observable<LocationSimple[]> {
        return null;
    }

    @Get('/location/awarded/simple')
    public getAwardedSimpleList(@Query('withOwnLocations') withOwnLocations?: boolean): Observable<LocationSimple[]> {
        return null;
    }

    @Delete('/location/:id')
    public deleteLocation(@Path('id') id: string): Observable<any> {
        return null;
    }

    @Get('/location/serviceHistory/lastThirtyDays/:id')
    @MapClass(LocationServiceHistory)
    public getLocationServiceHistoryLast30Days(@Path('id') id: string): Observable<number> {
        return null;
    }

    @Post('/location/serviceHistory')
    @MapValue(
        res =>
            new PaginatedList<LocationServiceHistory>({
                count: res.count,
                docs: res.docs.map(d => new LocationServiceHistory(d)),
            }),
    )
    public getLocationServiceHistory(
        @Body() params: GridParams,
    ): Observable<PaginatedList<LocationServiceHistory>> {
        return null;
    }

    @Put('/location/delete')
    public deleteLocations(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }

    @Put('/location/reactivate')
    public reactivateLocation(@Body('id') id: string): Observable<unknown> {
        return null;
    }

    // @Put('/location/:id/addTag')
    // public addTag(@Path('id') locationId: string, @Body('id') tagId: string): Observable<any> {
    //     return null;
    // }

    // @Put('/location/id/removeTag')
    // public removeTag(@Path('id') locationId: string, @Body('id') tagId: string): Observable<any> {
    //     return null;
    // }

    @Put('/location/:id/updateTags')
    public updateTags(
        @Path('id') locationId: string,
        @Body('ids') tagIds: string[],
    ): Observable<any> {
        return null;
    }

    @Get('/location/awarded/simple/serviceTypes')
    public getAwardedLocationSimpleWithServiceTypes(): Observable<LocationSimpleWithServiceTypes[]> {
        return null;
    }

    @Post('/location/csvExport', {
        responseType: ResponseType.Text,
    })
    public locationCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }

    @Get('/location/awarded-landscapers/:id')
    public getLocationAwardedLandscapers(@Path('id') id: string): Observable<{ name: string; _id: string }[]> {
        return null;
    }

    @Get('/location/awarded-landscapers')
    public getAwardedLandscapers(): Observable<{ name: string; _id: string }[]> {
        return null;
    }

    @Get('/location/:id/serviceTypes')
    public getLocationServiceType(@Path('id') id: string): Observable<ServiceType[]> {
        return null;
    }

    @Post('/location/create/bulk')
    public createLocations(@Body() data: { locations: any[], landscaperId: string }): Observable<string> {
        return null;
    }

    @Post('/location/check/byName')
    public checkByName(@Body() data: { name: string, companyId: string }): Observable<boolean> {
        return null;
    }

    @Post('/location/check/byAddress')
    public checkByAddress(@Body() data: { address: Address, companyId: string }): Observable<boolean> {
        return null;
    }

    @Get('/location/byClient/:id')
    public getLocationByClient(@Path('id') id: string): Observable<any> {
        return null;
    }

    @Get('/location/awarded/simple/behalfSp/:id')
    public getAwardedLocationSimpleOnSPBehalf(@Path('id') companyId: string): Observable<LocationSimpleWithServiceTypes[]> {
        return null;
    }
}
