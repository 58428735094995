import { UserIdentityWithoutIds } from '../user/user-identity';
import { parseISODate } from '@refactor/common';
import { LocationMappingApproveType } from '../../enums';

export class LocationMappingApprovalWithoutIds {
    public user: UserIdentityWithoutIds;
    public date: Date;
    public approveStatus: LocationMappingApproveType;

    constructor(params?: Partial<LocationMappingApprovalWithoutIds>) {
        if (!!params) {
            this.approveStatus = params.approveStatus || this.approveStatus;
            this.date = !!params.date ? parseISODate(params.date) : this.date;
            this.user = !!params.user ? new UserIdentityWithoutIds(params.user) : this.user;
        }
    }
}
