import { isNull } from '../../utils';

export enum CompanyServiceBillingType {
    Hourly = '[CompanyServiceBillingType] Hourly',
    PerDay = '[CompanyServiceBillingType] PerDay',
    PerWeek = '[CompanyServiceBillingType] PerWeek',
    PerMonth = '[CompanyServiceBillingType] PerMonth',
    PerEvent = '[CompanyServiceBillingType] PerEvent',
    Each = '[CompanyServiceBillingType] Each',
    PerApplication = '[CompanyServiceBillingType] PerApplication',
    PerSquareFoot = '[CompanyServiceBillingType] PerSquareFoot',
    PerTon = '[CompanyServiceBillingType] PerTon',
    PerLoad = '[CompanyServiceBillingType] Per Load',
    PerYard = '[CompanyServiceBillingType] Per Yard'
}

export namespace CompanyServiceBillingType {
    export function toString(type: CompanyServiceBillingType): string {
        switch (type) {
            case CompanyServiceBillingType.Hourly:
                return 'Hourly';
            case CompanyServiceBillingType.PerDay:
                return 'Per Day';
            case CompanyServiceBillingType.PerWeek:
                return 'Per Week';
            case CompanyServiceBillingType.PerMonth:
                return 'Per Month';
            case CompanyServiceBillingType.PerEvent:
                return 'Per Push';
            case CompanyServiceBillingType.Each:
                return 'Each';
            case CompanyServiceBillingType.PerApplication:
                return 'Per Application';
            case CompanyServiceBillingType.PerSquareFoot:
                return 'Per Square Foot';
            case CompanyServiceBillingType.PerTon:
                return 'Per Ton';
            case CompanyServiceBillingType.PerLoad:
                return 'Per Load';
            case CompanyServiceBillingType.PerYard:
                return 'Per Yard';
            default:
                return '';
        }
    }

    export const members: CompanyServiceBillingType[] = [
        CompanyServiceBillingType.Hourly,
        CompanyServiceBillingType.PerDay,
        CompanyServiceBillingType.PerWeek,
        CompanyServiceBillingType.PerMonth,
        CompanyServiceBillingType.PerEvent,
        CompanyServiceBillingType.Each,
        CompanyServiceBillingType.PerApplication,
        CompanyServiceBillingType.PerSquareFoot,
        CompanyServiceBillingType.PerTon,
        CompanyServiceBillingType.PerLoad,
        CompanyServiceBillingType.PerYard
    ];
}
