import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, Subject, of } from 'rxjs';
import { ToastService } from '@gm2/ui-common';
import { Router } from '@angular/router';
import { catchError, debounceTime, filter, tap } from 'rxjs/operators';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { AuthState } from '@gm2/ui-state';

const DEBOUNCE_TIME_MS = 1000;
const HTTP_UNAUTHORIZED = 401;

@Injectable()
export class NotAuthenticatedInterceptor implements HttpInterceptor {
    @Emitter(AuthState.setAuthToken)
    private clearAuthToken: Emittable<void>;
    private errors$: Subject<HttpErrorResponse> = new Subject();

    constructor(private _router: Router, private _toast: ToastService) {
        this.errors$.pipe(
            tap(error => console.log('Caught Error: ', error)),
            filter(error => error.status === HTTP_UNAUTHORIZED),
            debounceTime(DEBOUNCE_TIME_MS)
        ).subscribe(error => {
            let message = 'Login expired';
            if (!!error.error && !!error.error.message) {
                message = error.error.message;
            }
            this._toast.error(message);
            this.clearAuthToken.emit();
            this._router.navigateByUrl(`/auth/login?redirect=${this._router.url}`);
        });
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Errors from grid-internal requests aren't caught...
        return next.handle(req).pipe(
            tap(undefined, err => this.errors$.next(err))
        );
    }
}
