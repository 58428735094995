import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User, UserCreateDto, UserSimple } from '@gm2/ui-common';
import { url } from '../../consts/url.const';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserServiceNew {
    constructor(private http: HttpClient) {
    }

    public getUserList(filters: { [key: string]: string }):Observable<User[]> {
        return this.http.post<User[]>(`${url.user.list}`, filters);
    }

    public attachPlByEmail(email: string, companyId: string): Observable<User> {
        return this.http.post<User>(`${url.user.attachPLByEmail}`, {email, companyId});
    }
    public detachUsersFromPl(userIds: string[]): Observable<void> {
        return this.http.patch<void>(`${url.user.detachPl}`, {userIds});
    }

    public deleteUsers( ids: string[]): Observable<any> {
        return this.http.put(`${url.user.delete}`, {ids});
    }

    public getUserById(userId: string): Observable<User> {
        return this.http.get<User>(`${url.user.getById(userId)}`);
    }

    public getMyUser(): Observable<User> {
        return this.http.get<User>(`${url.user.getMyUser}`);
    }

    public createUser(dto: UserCreateDto): Observable<User> {
        return this.http.post<User>(`${url.user.createUser}`, dto);
    }

    public editUser(dto: UserCreateDto): Observable<User> {
        return this.http.put<User>(`${url.user.editUser(dto.userId)}`, dto);
    }

    public getSubCompanyUsers(companyId: string): Observable<UserSimple[]> {
        return this.http.get<UserSimple[]>(`${url.user.getBySubCompanyId(companyId)}`);
    }
}
