<div>
    <mat-form-field appearance="outline">
        <mat-label>{{ placeholder }}</mat-label>
        <mat-select [formControl]="form.get('tagIds')" multiple (openedChange)="toggleTagInput()">
            <mat-option>
                <ngx-mat-select-search
                    [formControl]="searchControl"
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No matching results found"
                ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let o of options" [value]="o._id" ngClass={{TagColor.toClass(o.color)}}>
                {{ o.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
