import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocationSimple, Bid } from '@gm2/ui-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { CompanyService } from '@gm2/ui-common';
import { pluck } from 'rxjs/operators';

@Component({
    selector: 'gm2-select-preferred-bid-modal',
    templateUrl: './select-preferred-bid-modal.component.html',
    styleUrls: ['./select-preferred-bid-modal.component.scss']
})
export class SelectPreferredBidModalComponent implements OnInit {
    public form: UntypedFormGroup = this._fb.group({
        companyId: ['', Validators.required]
    });
    public readonly bids$: BehaviorSubject<Bid[]> = new BehaviorSubject([]);
    public _modalService: any;

    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly _dialogRef: MatDialogRef<SelectPreferredBidModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        data: {
            approvedBids: Bid[];
            modalService: any
        },
        private readonly _companyService: CompanyService
    ) {
        this.bids$.next(data.approvedBids);
        this._modalService = data.modalService;
    }

    ngOnInit(): void {}

    public submit({ valid, value }: { valid: boolean; value: { companyId: string } }): void {
        if (!valid) {
            return;
        }
        this._dialogRef.close(value.companyId);
    }

    public close(): void {
        this._dialogRef.close();
    }

    public openQuestionnaire(companyId: string): void {
        this._modalService.openQuestionnaire(
            this._companyService.getCompany(companyId).pipe(pluck('questionnaireAnswers'))
        );
    }
}
