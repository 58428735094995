export enum ServiceMappingCategory {
    Sidewalk = '[ServiceMappingCategory] Sidewalk',
    Heated_Sidewalk = '[ServiceMappingCategory] Heated_Sidewalk',
    Parking_Lot_Non_Handicapped = '[ServiceMappingCategory]Parking_Lot_Non_Handicapped',
    Parking_Lot_Handicapped = '[ServiceMappingCategory] Parking_Lot_Handicapped',
    Curb_Lineal_Feet = '[ServiceMappingCategory] Curb_Lineal_Feet',
    Lawn = '[ServiceMappingCategory] Lawn',
    Undeveloped = '[ServiceMappingCategory] Undeveloped',
    Warning = '[ServiceMappingCategory] Warning',
    Information = '[ServiceMappingCategory] Information',
    Irrigated_Lawn_Zones = '[ServiceMappingCategory] Irrigated_Lawn_Zones',
    Irrigated_Bed_Zones = '[ServiceMappingCategory] Irrigated_Bed_Zones',
    Main_Line = '[ServiceMappingCategory] Main_Line',
    Lateral_Line = '[ServiceMappingCategory] Lateral_Line',
    Irrigation_Shut_Off = '[ServiceMappingCategory] Irrigation_Shut_Off',
    Backflow = '[ServiceMappingCategory] Backflow',
    Irrigation_Clock = '[ServiceMappingCategory] Irrigation_Clock',
    Show_Pile = '[ServiceMappingCategory] Show_Pile'
}

export namespace ServiceMappingCategory {
    export function toString(type: ServiceMappingCategory): string {
        switch (type) {
            case ServiceMappingCategory.Sidewalk:
                return 'Sidewalk';
            case ServiceMappingCategory.Heated_Sidewalk:
                return 'Heated Sidewalk';
            case ServiceMappingCategory.Parking_Lot_Non_Handicapped:
                return 'Parking Lot Non Handicapped';
            case ServiceMappingCategory.Parking_Lot_Handicapped:
                return 'Parking Lot Handicapped';
            case ServiceMappingCategory.Curb_Lineal_Feet:
                return 'Curb Lineal Feet';
            case ServiceMappingCategory.Lawn:
                return 'Lawn';
            case ServiceMappingCategory.Undeveloped:
                return 'Undeveloped';
            case ServiceMappingCategory.Warning:
                return 'Warning';
            case ServiceMappingCategory.Information:
                return 'Information';
            case ServiceMappingCategory.Irrigated_Lawn_Zones:
                return 'Irrigated Lawn Zones';
            case ServiceMappingCategory.Irrigated_Bed_Zones:
                return 'Irrigated Bed Zones';
            case ServiceMappingCategory.Main_Line:
                return 'Main Line';
            case ServiceMappingCategory.Lateral_Line:
                return 'Lateral Line';
            case ServiceMappingCategory.Irrigation_Shut_Off:
                return 'Irrigation Shut Off';
            case ServiceMappingCategory.Backflow:
                return 'Backflow';
            case ServiceMappingCategory.Irrigation_Clock:
                return 'Irrigation Clock';
            case ServiceMappingCategory.Show_Pile:
                return 'Show Pile';
            default:
                return '';
        }
    }

    export const members: ServiceMappingCategory[] = [
        ServiceMappingCategory.Sidewalk,
        ServiceMappingCategory.Heated_Sidewalk,
        ServiceMappingCategory.Parking_Lot_Non_Handicapped,
        ServiceMappingCategory.Parking_Lot_Handicapped,
        ServiceMappingCategory.Curb_Lineal_Feet,
        ServiceMappingCategory.Lawn,
        ServiceMappingCategory.Undeveloped,
        ServiceMappingCategory.Warning,
        ServiceMappingCategory.Information,
        ServiceMappingCategory.Irrigated_Lawn_Zones,
        ServiceMappingCategory.Irrigated_Bed_Zones,
        ServiceMappingCategory.Main_Line,
        ServiceMappingCategory.Lateral_Line,
        ServiceMappingCategory.Irrigation_Shut_Off,
        ServiceMappingCategory.Backflow,
        ServiceMappingCategory.Irrigation_Clock,
        ServiceMappingCategory.Show_Pile
    ];
}
