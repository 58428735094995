import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RfxForm, RfxFormField, RfxFormFieldType } from '@refactor/common';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, iif, Observable, of, Subject, throwError } from 'rxjs';
import {
    catchError,
    filter,
    finalize,
    map,
    shareReplay,
    startWith,
    switchMap,
    switchMapTo,
    take,
    tap
} from 'rxjs/operators';
import { FormBuilderModalService } from '../../services/form-builder-modal.service';
import { FormBuilderService } from '../../services/form-builder.service';

@Component({
    selector: 'rfx-form-edit',
    templateUrl: './form-edit.component.html',
    styleUrls: ['./form-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormEditComponent {
    public RfxFormFieldType: typeof RfxFormFieldType = RfxFormFieldType;

    public formGroup: UntypedFormGroup = this._fb.group({
        name: ['', Validators.required],
        description: ['', Validators.required]
    });

    public fields$: BehaviorSubject<RfxFormField<string>[]> = new BehaviorSubject([]);

    public fieldToEdit$: BehaviorSubject<RfxFormField<string>> = new BehaviorSubject(null);

    public isSubmitting$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public isPublishing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public refreshForm$: Subject<void> = new Subject();

    public listUrl$: Observable<string> = this._activatedRoute.data.pipe(
        map(data => data.listUrl),
        shareReplay(1)
    );

    /**
     * Form id from url
     */
    public formId$: Observable<string> = this._activatedRoute.paramMap.pipe(
        map(params => params.get('id')),
        shareReplay(1)
    );

    /**
     * Current Form (if editing)
     */
    public currentForm$: Observable<RfxForm> = this.refreshForm$.pipe(
        startWith(null),
        switchMapTo(this.formId$),
        switchMap(formId =>
            iif(() => !!formId, this._formBuilderService.getFormById(formId), of(null))
        ),
        catchError(err => {
            this._toast.error('Error retrieving form', err.error.message);
            return of(null);
        }),
        tap((curForm: RfxForm) => {
            if (!!curForm) {
                // Editing form (pre-populate fields here)
                this.formGroup.patchValue({
                    name: curForm.name,
                    description: curForm.description
                });
                this.fields$.next(curForm.fields as RfxFormField<string>[]);
            }
        }),
        shareReplay(1)
    );

    /**
     * Creating or Editing
     */
    public isEditing$: Observable<boolean> = this.currentForm$.pipe(
        take(1),
        map(f => !!f),
        shareReplay(1)
    );

    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _fb: UntypedFormBuilder,
        private readonly _formBuilderService: FormBuilderService,
        private readonly _formBuilderModalService: FormBuilderModalService,
        private readonly _router: Router,
        private readonly _toast: ToastrService
    ) {}

    /**
     * Return to form list page.
     */
    public goBack(): void {
        this.listUrl$.pipe(take(1)).subscribe(url => {
            this._router.navigateByUrl(url);
        });
    }

    /**
     * Create form or submit updates to form.
     */
    public submit(publish: boolean = false): void {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._toast.error('Please enter a name and description.');
            return;
        }

        if (publish) {
            this.isPublishing$.next(true);
        } else {
            this.isSubmitting$.next(true);
        }

        const dto = {
            name: this.formGroup.get('name').value,
            description: this.formGroup.get('description').value,
            fields: this.fields$.value,
            published: publish
        } as RfxForm<string>;

        this.currentForm$
            .pipe(
                take(1),
                switchMap(form => {
                    if (!!form && form._id) {
                        // Ensure form stays published
                        if (!publish) {
                            dto.published = form.published;
                        }
                        return this._formBuilderService.editForm(form._id, dto).pipe(
                            catchError(error => throwError(`Unable to edit form ${error.message}`)),
                            map(() => ({
                                id: form._id,
                                message: publish
                                    ? 'Form published successfully'
                                    : 'Form edited successfully'
                            }))
                        );
                    } else {
                        return this._formBuilderService.createForm(dto).pipe(
                            catchError(error =>
                                throwError(`Unable to create form ${error.message}`)
                            ),
                            map(() => ({ id: null, message: 'Form created successfully' }))
                        );
                    }
                }),
                finalize(() => {
                    this.isSubmitting$.next(false);
                    this.isPublishing$.next(false);
                })
            )
            .subscribe(
                status => {
                    this._toast.success(status.message);
                    this.refreshForm$.next();
                    if (!status.id) {
                        this.goBack();
                    }
                },
                error => {
                    this._toast.error(error);
                }
            );
    }

    /**
     * Select a field that will be used to open the
     * field settings panel.
     */
    public selectFieldToEdit(fieldId: string): void {
        const currentFields = this.fields$.value;
        const field = currentFields.find(f => f._id === fieldId);
        this.fieldToEdit$.next(field);
    }

    /**
     * Reset fieldToEdit to null.
     */
    public clearFieldToEdit(): void {
        this.fieldToEdit$.next(null);
    }

    public updateField(field: RfxFormField<string>): void {
        const currentFields = [...this.fields$.value];
        const index = currentFields.findIndex(f => f._id === field._id);
        currentFields.splice(index, 1, field);
        this.fields$.next(currentFields);
        this._toast.success('Field updated successfully');
    }

    public deleteForm(): void {
        this.currentForm$.pipe(take(1)).subscribe(form => {
            this._formBuilderModalService
                .confirm(
                    `Are you sure you want to delete <strong>${form.name}</strong>?`,
                    'Confirm Delete'
                )
                .afterClosed()
                .pipe(
                    filter(confirm => !!confirm),
                    switchMap(form => this._formBuilderService.deleteForms([form._id]))
                )
                .subscribe(
                    _ => {
                        this._toast.success('Form deleted successfully.');
                        this.goBack();
                    },
                    err => {
                        this._toast.error(err, 'Unable to delete form.');
                    }
                );
        });
    }

    public deleteField(fieldId: string): void {
        this._formBuilderModalService
            .confirm(`Are you sure you want to delete this field?`, 'Confirm')
            .afterClosed()
            .pipe(filter(confirm => !!confirm))
            .subscribe(_ => {
                const currentFields = this.fields$.value;
                const index = currentFields.findIndex(f => f._id === fieldId);
                currentFields.splice(index, 1);
                this.fields$.next(currentFields);

                // Clear current field being edited if removed.
                const fieldBeingEdited = this.fieldToEdit$.value;
                if (!!fieldBeingEdited && fieldBeingEdited._id === fieldId) {
                    this.clearFieldToEdit();
                }

                this._toast.success('Field deleted successfully');
            });
    }
}
