<rfx-grid-filter-control>
    <mat-form-field appearance="outline">
        <mat-label>{{
            (gridServices$ | async)?.length > 0 ? placeholder : 'No services'
        }}</mat-label>
        <mat-select
            [formControl]="controlGroup.get('values')"
            (selectionChange)="changed()"
            multiple
            [placeholder]="placeholder"
        >
            <mat-option>
                <ngx-mat-select-search
                    [formControl]="searchControl"
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No matching results found"
                ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let o of options" [value]="o.value">
                {{ o.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</rfx-grid-filter-control>
