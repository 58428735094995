<gm2-secured-header
    [user]="user$ | async"
    [company]="company$ | async"
    [notificationCount]="notificationCount$ | async"
    (logout)="logout()"
></gm2-secured-header>
<!--<mat-sidenav-container>-->
<!--    <mat-sidenav opened #snav [mode]="mobileQuery.matches ? 'over' : 'side'">-->
<!--        <gm2-secured-sidebar-->
<!--            [opened]="snav.opened"-->
<!--            (toggle)="sidebarToggle(snav, $event)"-->
<!--            [menuItems]="siteMenu$ | async"-->
<!--        ></gm2-secured-sidebar>-->
<!--    </mat-sidenav>-->
<!--    <mat-sidenav-content [class.expanded]="snav.opened && !mobileQuery.matches">-->
<!--        <main>-->
<!--            <router-outlet></router-outlet>-->
<!--        </main>-->
<!--        <gm2-secured-footer></gm2-secured-footer>-->
<!--    </mat-sidenav-content>-->
<!--</mat-sidenav-container>-->

<mat-drawer-container class="example-container" style="height: 100%" autosize>
    <mat-drawer #drawer mode="side" disableClose="true" opened="true" class="menu-container">
        <div >
            <div class="toggle-wrapper">
                <div class="toggle item" (click)="isExpanded = !isExpanded;$event.stopPropagation()">
                    <fa-icon
                        class="toggle-icon"
                        [class.opened]="isExpanded"
                        [icon]="['far', isExpanded? 'arrow-alt-to-left' : 'arrow-alt-from-left']"
                        matTooltip="Expand the navigation"
                        [matTooltipDisabled]="isExpanded"
                    ></fa-icon>
                    <label *ngIf="isExpanded">Collapse</label>
                    <fa-icon
                        *ngIf="isExpanded"
                        [class.pinned]="isPinned"
                        (click)="isPinned = !isPinned; $event.stopPropagation()"
                        class="pin-icon"
                        [icon]="['far', 'thumbtack']"
                        matTooltip="Pin the navigation open"
                    ></fa-icon>
                </div>
            </div>
            <hr/>

            <mat-nav-list class="nav-list" [class.collapsed]="!isExpanded">
                <ng-container *ngFor="let menuItem of (siteMenu$ | async)">
                    <a
                        class="nav-item"
                        mat-list-item
                        [routerLink]="menuItem.route"
                        [routerLinkActive]="!!menuItem.route ? 'active' : ''"
                        [class.active]="menuItem.active"
                        [class.expanded]="isExpanded && (menuState.expandedItem === menuItem)"
                        (click)="
                    !!menuItem.route
                        ? toggleOpen(isPinned ? isExpanded : false)
                        : menuItem.children.length > 0
                        ? expandItem(menuItem)
                        : null
                "
                        [matTooltip]="menuItem.label"
                        matTooltipPosition="right"
                        [matTooltipShowDelay]="menuState.tooltipDelay"
                        [matTooltipHideDelay]="menuState.tooltipDelay"
                        [matTooltipDisabled]="isExpanded"
                    >
                        <span class="active-indicator"></span>
                        <fa-icon [icon]="menuItem.icon"></fa-icon>
                        <label *ngIf="isExpanded">
                            {{ menuItem.label }}
                        </label>
                        <fa-icon
                            [class.not-expanded]="!isExpanded || (menuState.expandedItem !== menuItem)"
                            class="expandable-indicator"
                            *ngIf="menuItem.children.length > 0 && isExpanded"
                            [icon]="['fas', 'chevron-down']"
                        ></fa-icon>
                    </a>
                    <mat-nav-list
                        *ngIf="menuItem.children.length > 0"
                        class="sub-item-list total-items-{{ menuItem.children.length }}"
                        [class.expanded]="isExpanded && (menuState.expandedItem === menuItem)"
                    >
                        <a
                            class="nav-item sub-nav-item"
                            mat-list-item
                            *ngFor="let childItem of menuItem.children"
                            [routerLink]="childItem.route"
                            [routerLinkActive]="!!childItem.route ? 'active' : ''"
                            (click)="toggleOpen(isPinned? isExpanded : false)"
                        >
                            <label>{{ childItem.label }}</label>
                        </a>
                    </mat-nav-list>
                </ng-container>
            </mat-nav-list>

        </div>

    </mat-drawer>

    <div class="example-sidenav-content" style="height: 100%;display: flex;
    flex-direction: column;">
        <main>
            <router-outlet></router-outlet>
        </main>
        <gm2-secured-footer></gm2-secured-footer>
    </div>
</mat-drawer-container>
