import { Month } from '../base/month';

export enum ContactType {
    Phone = '[ContactType] Phone',
    Email = '[ContactType] Email'
}

export namespace ContactType {
    export function toString(type: ContactType): string {
        switch (type) {
            case ContactType.Phone:
                return 'Phone';
            case ContactType.Email:
                return 'Email';
            default:
                return '';
        }
    }

    export const members: ContactType[] = [
        ContactType.Phone,
        ContactType.Email
    ];

    export function stringToEnum(month: string): string {
        switch (month) {
            case 'Phone':
                return ContactType.Phone;
            case 'Email':
                return ContactType.Email;
            default:
                return null;
        }
    }

}
