export { isNull } from './is-null';
export { isSnowServiceTypeId, isLandscapeServiceTypeId } from './service-type-check';
export { TimezoneAwareDateTimeBucket, dateToTimezoneAwareDateTimeBucket } from './date';
export { twoDecimalRound } from './number';
export { hasPermission, hasAnyPermission, hasAllPermissions } from './permission';
export { timesheetDurationMinutes, requireTimeAllocation } from './timesheet';
export { RingBuffer } from './ring-buffer';

/**
 * Check if a value is "empty" as defined.
 *
 * This function considers a value "empty" if any of the following conditions
 * are true:
 * - value is an empty string (length === 0)
 * - value is undefined
 * - value is null
 * - value is NaN
 *
 * @param value
 * @returns true if value is "empty" else false
 */
export function isEmpty(value: unknown | undefined | null | string): boolean {
    const isEmptyString = value === '';
    const isUndefined = value === undefined;
    const isNull = value === null;
    const isNaN = typeof value === 'number' && Number.isNaN(value);

    return isEmptyString || isUndefined || isNull || isNaN;
}
