export class WorkOrderNotification {
    public isSent: boolean = false;
    public sendNotification: boolean = false;
    public currentToken: string = '';

    constructor(params?: Partial<WorkOrderNotification>) {
        if (!!params) {
            this.isSent = params.isSent || this.isSent;
            this.sendNotification = params.sendNotification || this.sendNotification;
            this.currentToken = params.currentToken || this.currentToken;
        }
    }
}
