export enum FormPublishStatus {
    InProgress = 1,
    Published = 2
}

export namespace FormPublishStatus {
    export function toString(type: FormPublishStatus): string {
        switch (type) {
            case FormPublishStatus.InProgress:
                return 'In Progress';
            case FormPublishStatus.Published:
                return 'Published';
            default:
                return '';
        }
    }

    export const members: FormPublishStatus[] = [
        FormPublishStatus.InProgress,
        FormPublishStatus.Published
    ];
}
