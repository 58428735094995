import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    TimesheetSimple,
    InvoiceService,
    IInvoiceRejectDto,
    ToastService,
    InvoiceRejectionReason
} from '@gm2/ui-common';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';

interface IDialogData {
    invoiceId: string;
    timesheets: TimesheetSimple[];
}

@Component({
    selector: 'gm2-decline-invoice-modal',
    templateUrl: './decline-invoice-modal.component.html',
    styleUrls: ['./decline-invoice-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeclineInvoiceModalComponent implements OnInit, IDialogData {
    public invoiceId: string;
    public timesheets: TimesheetSimple[];
    public form: UntypedFormGroup;
    public model: object = {};
    public allSelected: boolean = false;

    public InvoiceRejectionReason: typeof InvoiceRejectionReason = InvoiceRejectionReason;

    constructor(
        private readonly _dialogRef: MatDialogRef<DeclineInvoiceModalComponent, boolean>,
        private readonly _fb: UntypedFormBuilder,
        private readonly _invoiceService: InvoiceService,
        private readonly _toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) data: IDialogData
    ) {
        Object.assign(this, data);

        this.form = this._fb.group({
            reason: [undefined, Validators.required],
            message: [undefined, Validators.required],
            timesheets: this._fb.array(this.timesheets.map(_ => this._fb.control(false)))
        });
    }

    ngOnInit(): void {}

    public getTimesheetTitle(timesheet: TimesheetSimple): string {
        const date = timesheet.timesheetStartDateInLocationTz;
        const time = timesheet.timesheetStartTimeInLocationTz;
        const locationName = timesheet.locationName;
        const packageName = timesheet.packageName;

        const datetime = `${date} - ${time}`;
        const timesheetNumber = `Timesheet #${timesheet.timesheetNumber}`;

        return `${datetime} | ${timesheetNumber} | ${locationName} | ${packageName}`;
    }

    public onCancel(): void {
        this._dialogRef.close(false);
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            return;
        }

        const data = this.form.value;
        const dto: IInvoiceRejectDto = {
            reason: data.reason,
            message: data.message,
            rejectedTimesheetIds: []
        };

        for (let i = 0; i < this.timesheets.length; i++) {
            if (data.timesheets[i]) {
                // Checkboxes have boolean value indicating whether they are
                // checked. Use this to push the timesheet ids into the dto
                dto.rejectedTimesheetIds.push(this.timesheets[i]._id);
            }
        }

        if (this.timesheets.length > 0 && dto.rejectedTimesheetIds.length === 0) {
            this._toastService.error('Please select at least one timesheet');
            return;
        }

        this._invoiceService.rejectInvoice(this.invoiceId, dto).subscribe(() => {
            this._toastService.success('Invoice declined');
            this._dialogRef.close(true);
        });
    }

    public selectAll(): void {
        this.allSelected = !this.allSelected;
        const timesheets = this.form.controls.timesheets as UntypedFormArray;
        for (let i = 0; i < timesheets.length; ++i) {
            const control = timesheets.at(i) as UntypedFormControl;
            control.setValue(this.allSelected);
        }
    }
}
