/// <reference types="googlemaps" />

import { IPoly } from './IPoly';
import { IOverlay } from './IOverlay';
import { MappingType } from '@gm2/common';
import { IIdentifiable } from '../IIdentifiable';
import { LatLngLiteral } from '@google/maps';

/**
 * I wrote the following comment in 2015 when I rewrote this the first time and
 * I still don't know what these constants are.
 *
 * I have no idea what these numbers represent and why they are multiplied by
 * the value returned by Google's area compute functions but it's there in the
 * original mapping so I have to include them otherwise the area calculations
 * will be incorrect and significantly smaller now.
 */
const RANDOM_MAGIC_AREA_COMPUTE_MULTIPLIER: number = 10.76;

export class Polygon implements Partial<IIdentifiable>, IPoly, IOverlay {
    public _id?: string;
    public overlay: google.maps.Polygon;
    public type: MappingType;

    public constructor(overlay: google.maps.Polygon, _id?: string) {
        this.overlay = overlay;
        this.type = MappingType.Area;
        this._id = _id;
    }

    public get area(): number {
        return google.maps.geometry.spherical.computeArea(this.overlay.getPath())
            * RANDOM_MAGIC_AREA_COMPUTE_MULTIPLIER;
    }

    public get path(): LatLngLiteral[] {
        return this.overlay
            .getPaths()
            .getAt(0)
            .getArray()
            .map(point => point.toJSON());
    }

    public set path(value: LatLngLiteral[]) {
        this.overlay.setPath(value);
    }
}
