import { ContractStatus, BillingType, Month, AssigneeType } from '../../enums';
import { ContractDates } from './contract-dates';
import { CompanyIdentityWithoutIds } from '../company/company-identity';

export class ContractIdentityWithoutIds {
    public _id: any;
    public clientCompanyIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public contractStatus: ContractStatus;
    public billingType: BillingType;
    public contractDates: ContractDates = new ContractDates();
    public serviceTypeId: any;
    public assigneeType: AssigneeType;
    public createdBy:string;

    constructor(params?: Partial<ContractIdentityWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.clientCompanyIdentity = !!params.clientCompanyIdentity
                ? new CompanyIdentityWithoutIds(params.clientCompanyIdentity)
                : this.clientCompanyIdentity;

            this.contractStatus = params.contractStatus || this.contractStatus;
            this.billingType = params.billingType || this.billingType;

            this.contractDates = !!params.contractDates
                ? new ContractDates(params.contractDates)
                : this.contractDates;

            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId;
            this.assigneeType = params.assigneeType || this.assigneeType;
            this.createdBy = params.createdBy || this.createdBy;
        }
    }
}
