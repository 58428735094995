import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TzInterceptor implements HttpInterceptor {
    constructor() {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const d = new Date();
        const midnight = new Date(d.getTime());
        midnight.setHours(0, 0, 0, 0);
        return next.handle(
            req.clone({
                body: {
                    ...req.body,
                    tz_info: {
                        offset: d.getTimezoneOffset(),
                        date: `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`,
                        time: d.getTime(),
                        midnightUTC: midnight.toISOString()
                    }
                }
            })
        );
    }
}
