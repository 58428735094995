import { StringFilter } from '@refactor/common';

export class AdminCompanyMaterialGridFilters {
    public searchTerm: StringFilter = new StringFilter();
    public companyId: StringFilter = new StringFilter();
    public approveStatus: StringFilter = new StringFilter();

    constructor(params?: Partial<AdminCompanyMaterialGridFilters>) {
        if (!!params) {
            this.searchTerm = !!params.searchTerm ? new StringFilter(params.searchTerm) : this.searchTerm;
            this.companyId = !!params.companyId ? new StringFilter(params.companyId) : this.companyId;
            this.approveStatus = !!params.approveStatus ? new StringFilter(params.approveStatus) : this.approveStatus;
        }
    }
}
