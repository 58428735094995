export enum WorkOrderWorkCategory {
    Beds = '[WorkOrderWorkCategory] Beds',
    Building = '[WorkOrderWorkCategory] Building',
    DebrisRemoval = '[WorkOrderWorkCategory] DebrisRemoval',
    Other = '[WorkOrderWorkCategory] Other',
    ParkingLot = '[WorkOrderWorkCategory] ParkingLot',
    Renovation = '[WorkOrderWorkCategory] Renovation',
    ShrubsTrees = '[WorkOrderWorkCategory] ShrubsTrees',
    Sidewalk = '[WorkOrderWorkCategory] Sidewalk',
    SprinklersIrrigation = '[WorkOrderWorkCategory] SprinklersIrrigation',
    Turf = '[WorkOrderWorkCategory] Turf'
}

export namespace WorkOrderWorkCategory {
    export function toString(type: WorkOrderWorkCategory): string {
        switch (type) {
            case WorkOrderWorkCategory.Beds:
                return 'Beds';
            case WorkOrderWorkCategory.Building:
                return 'Building';
            case WorkOrderWorkCategory.DebrisRemoval:
                return 'Debris Removal';
            case WorkOrderWorkCategory.Other:
                return 'Other';
            case WorkOrderWorkCategory.ParkingLot:
                return 'ParkingLot';
            case WorkOrderWorkCategory.Renovation:
                return 'Renovation';
            case WorkOrderWorkCategory.ShrubsTrees:
                return 'Shrubs and Trees';
            case WorkOrderWorkCategory.Sidewalk:
                return 'Sidewalk';
            case WorkOrderWorkCategory.SprinklersIrrigation:
                return 'Sprinklers and Irrigation';
            case WorkOrderWorkCategory.Turf:
                return 'Turf';
            default:
                return '';
        }
    }

    export const members: WorkOrderWorkCategory[] = [
        WorkOrderWorkCategory.Beds,
        WorkOrderWorkCategory.Building,
        WorkOrderWorkCategory.DebrisRemoval,
        WorkOrderWorkCategory.Other,
        WorkOrderWorkCategory.ParkingLot,
        WorkOrderWorkCategory.Renovation,
        WorkOrderWorkCategory.ShrubsTrees,
        WorkOrderWorkCategory.Sidewalk,
        WorkOrderWorkCategory.SprinklersIrrigation,
        WorkOrderWorkCategory.Turf
    ];
}
