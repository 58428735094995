import { RfpServiceWithoutIds } from '@gm2/common';
import { RfpServiceSettings } from './rfp-service-settings';

export class RfpServiceDto extends RfpServiceWithoutIds {
    public serviceId: string;
    public name: string;
    public description: string;
    public settings: RfpServiceSettings = new RfpServiceSettings();

    constructor(params?: Partial<RfpServiceDto>) {
        super(params);
        if (!!params) {
            this.serviceId = params.serviceId || this.serviceId;
            this.name = params.name || this.name;
            this.description = params.description || this.description;
            this.settings = params.settings || this.settings;
        }
    }
}
