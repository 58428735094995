import {
    Company,
    CompanyService,
    CompanySimple,
    CompanyStatus,
    CompanyType,
    hasPermission,
    Note,
    NoteCreateDto,
    NoteService,
    NoteType,
    Permission,
    Role,
    RoleService,
    SubcontractorInfoService,
    ToastService,
    User,
    UserSimple
} from '@gm2/ui-common';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { Receiver } from '@ngxs-labs/emitter';
import { Injectable, NgZone } from '@angular/core';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CompanyServiceNew } from '@ngx-gm2/shared/services/company.service';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { UserServiceNew } from '@ngx-gm2/shared/services/user.service';
import { SubcontractorInfo } from '@gm2/ui-common';
import { UserState } from '@gm2/ui-state';
import { GeospatialActions } from '@gm2/ui-state';

export namespace CompanyActions {
    export class GetLoggedInCompany {
        static readonly type = '[Company] Get Logged In Company';
    }

    export class GetAvailableRoles {
        static readonly type = '[Company] Get Available Roles';

        constructor(public companyId: string) {
        }
    }

    export class GetAvailableCompaniesByType {
        static readonly type = '[Company] Get Available Companies By Type';

        constructor(public companyType?: CompanyType) {
        }
    }

    export class GetAvailableCompaniesByTypes {
        static readonly type = '[Company] Get Available Companies By Types';

        constructor(public companyTypes: CompanyType[]) {}
    }

    export class GetAvailableCompaniesByName {
        static readonly type = '[Company] Get Available Companies By Name';

        constructor(public name: string, public companyType: CompanyType) {
        }
    }

    export class GetSelectedCompany {
        static readonly type = '[Company] Get Selected Company';

        constructor(public companyId: string) {
        }
    }

    export class GetSelectedCompanyUsers {
        static readonly type = '[Company] Get Selected Company User List';

        constructor(public companyId: string) {
        }
    }

    export class GetSelectedCompanySubcontractorInfo {
        static readonly type = '[Company] Get Selected Company Subcontractor Info';
    }

    export class CreateSubcontractorInfo {
        static readonly type = '[Company] Create Subcontractor Info';
    }

    export class DeleteSelectedCompanyUser {
        static readonly type = '[Company] Delete User of Selected Company';

        constructor(public userId: string) {
        }
    }

    export class SubmitCompanyForm {
        static readonly type = '[Company] Submit Company Form';

        constructor(public form: UntypedFormGroup) {
        }
    }

    export class CreateCompany {
        static readonly type = '[Company] Create Company';

        constructor(public form: UntypedFormGroup) {
        }
    }

    export class EditCompany {
        static readonly type = '[Company] Edit Company';

        constructor(public form: UntypedFormGroup) {
        }
    }

    export class UpdateMyRequireTimeAllocation {
        static readonly type = '[Company] Update My Require Time Allocation';

        constructor(public timeAllocationToggle: boolean) {
        }
    }

    export class UpdateRequireTimeAllocation {
        static readonly type = '[Company] Update Require Time Allocation for Company or Subcontractor';

        constructor(public timeAllocationValue: boolean) {
        }
    }


    export class GetSelectedCompanyNotes {
        static readonly type = '[Company] Get Selected Company Notes';
    }

    export class AddSelectedCompanyNote {
        static readonly type = '[Company] Add Selected Company Note';

        constructor(public note: string, public noteType: string) {
        }
    }

    export class DeleteSelectedCompanyNote {
        static readonly type = '[Company] Delete Selected Company Note';

        constructor(public id: string) {
        }
    }

    export class DeleteSubcontractors {
        static readonly type = '[Company] Delete Subcontractors';

        constructor(public ids: string[]) {
        }
    }

    export class DeleteInvalidSubcontractor {
        static readonly type = '[Company] Delete Subcontractors without Subcontractor Info entity';

        constructor(public companyId: string) {
        }
    }


    export class UpdateCompaniesStatus {
        static readonly type = '[Company] Inactivate Companies';

        constructor(public companies: CompanySimple[], public status: CompanyStatus) {
        }
    }

    export class UpdateGeospatialServiceStatus {
        static readonly type = '[Company] Update Geospatial Service Status';

        constructor(public companyId: string, public geospatialServiceStatus: boolean) {
        }
    }

    export class UpdateSubcontractorsInfosStatus {
        static readonly type = '[Company] Update Subcontractors Infos Status';

        constructor(public companies: CompanySimple[], public status: CompanyStatus) {
        }
    }


    export class SetProps {
        static readonly type = '[Company] Rewrite Particular State Props';

        constructor(public propsToRewrite: Partial<CompanyStateModel>) {
        }
    }
}

export interface CompanyStateModel {
    company: Company;
    availableCompanies: (Company | CompanySimple)[];
    availableCompaniesLoading: boolean;
    availableRoles: Role[];
    selectedCompany: Company;
    selectedCompanyUsers: UserSimple[];
    selectedCompanySubcontractorInfo: SubcontractorInfo;
    selectedCompanyNotes: Note[];
    isSubmitting: boolean;
}

const initialState = {
    company: null,
    availableCompanies: null,
    availableCompaniesLoading: false,
    availableRoles: null,
    selectedCompany: null,
    selectedCompanyUsers: null,
    selectedCompanySubcontractorInfo: null,
    selectedCompanyNotes: null,
    isSubmitting: false,
}

@State<CompanyStateModel>({
    name: 'company',
    defaults: initialState
})
@Injectable()
export class CompanyState {
    constructor(
        private companyService: CompanyService,
        private companyServiceNew: CompanyServiceNew,
        private userService: UserServiceNew,
        private subcontractorInfoService: SubcontractorInfoService,
        private noteService: NoteService,
        private toastService: ToastService,
        private roleService: RoleService,
        private router: Router,
        private ngZone: NgZone,
        private store$: Store,
    ) {
    }

    @Selector()
    @ImmutableSelector()
    public static companyId(state: CompanyStateModel): string {
        return state?.company?._id;
    }

    @Selector()
    @ImmutableSelector()
    public static company(state: CompanyStateModel): Company {
        return state.company;
    }

    @Selector()
    @ImmutableSelector()
    public static companyType(state: CompanyStateModel): string {
        return state?.company?.type;
    }

    @Selector()
    @ImmutableSelector()
    public static availableRoles(state: CompanyStateModel): Role[] {
        return state?.availableRoles;
    }

    @Selector()
    @ImmutableSelector()
    public static availableCompanies(state: CompanyStateModel): (Company | CompanySimple)[] {
        return state?.availableCompanies;
    }

    @Selector()
    @ImmutableSelector()
    public static availableCompaniesLoading(state: CompanyStateModel): boolean {
        return state?.availableCompaniesLoading;
    }

    @Selector()
    @ImmutableSelector()
    public static selectedCompany(state: CompanyStateModel): Company {
        return state?.selectedCompany;
    }

    @Selector()
    @ImmutableSelector()
    public static isMultiPls(state: CompanyStateModel): boolean {
        return state?.company?.companies?.length > 1;
    }

    @Selector()
    @ImmutableSelector()
    public static selectedCompanyUsers(state: CompanyStateModel): UserSimple[] {
        return state?.selectedCompanyUsers;
    }

    @Selector()
    @ImmutableSelector()
    public static selectedCompanySubcontractorInfo(state: CompanyStateModel): SubcontractorInfo {
        return state?.selectedCompanySubcontractorInfo;
    }

    @Selector()
    @ImmutableSelector()
    public static selectedCompanyNotes(state: CompanyStateModel): Note[] {
        return state?.selectedCompanyNotes;
    }

    @Selector()
    @ImmutableSelector()
    public static isSubmitting(state: CompanyStateModel): boolean {
        return state?.isSubmitting;
    }

    @Selector()
    @ImmutableSelector()
    public static spTimeAllocationRequired(state: CompanyStateModel): boolean {
        return (state.company.type === CompanyType.Service_Partner &&
            state.company.requireTimeAllocation);
    }

    @Selector()
    @ImmutableSelector()
    public static profileComplete(state: CompanyStateModel): boolean {
        if (state.company.type === CompanyType.Admin || state.company.type === CompanyType.SuperAdmin || state.company.type === CompanyType.Client) {
            return true;
        }

        let isComplete = true;
        const companyProfile = state.company.profile;
        const questionnaireAnswers = state.company.questionnaireAnswers;

        // Check if profile is complete
        isComplete = isComplete && !!companyProfile.name;
        isComplete = isComplete && !!companyProfile.address.line1;
        isComplete = isComplete && !!companyProfile.address.city;
        isComplete = isComplete && !!companyProfile.address.state;
        isComplete = isComplete && !!companyProfile.address.postalCode;
        isComplete = isComplete && !!companyProfile.phone;
        // isComplete = isComplete && !!companyProfile.fax;
        isComplete = isComplete && !!companyProfile.email;
        isComplete = isComplete && !!companyProfile.preferredContactType;
        isComplete = isComplete && !!questionnaireAnswers.canUseApp.answer;
        // isComplete = isComplete && !!questionnaireAnswers.capacity;
        // isComplete = isComplete && !!questionnaireAnswers.employeesNeedToHire;
        isComplete =
            isComplete && !!questionnaireAnswers.generalLiabilityInsuranceExpiration.answer;
        isComplete = isComplete && !!questionnaireAnswers.haveEmployees.answer;
        // isComplete = isComplete && !!questionnaireAnswers.licensesOrCerts.answer;
        isComplete = isComplete && !!questionnaireAnswers.offerDiscounts.answer;
        isComplete = isComplete && !!questionnaireAnswers.payByDirectDeposit.answer;
        isComplete = isComplete && !!questionnaireAnswers.vehicleInsuranceExpiration.answer;
        isComplete = isComplete && !!questionnaireAnswers.umbrellaInsuranceExpiration.answer;
        isComplete = isComplete && !!questionnaireAnswers.workVehicles.answer;
        // isComplete = isComplete && !!questionnaireAnswers.workersCompInsuranceExpiration.answer;
        isComplete = isComplete && !!questionnaireAnswers.yearsInIndustry.answer;

        return isComplete;
    }

    @Receiver({ type: '[Company] set company' })
    @ImmutableContext()
    public static setCompany(
        { setState }: StateContext<CompanyStateModel>,
        { payload }: { payload: Company }
    ): void {
        setState((state: CompanyStateModel) => {
            state.company = payload;
            return state;
        });
    }

    @Action(CompanyActions.GetLoggedInCompany)
    public getLoggedInCompany(ctx: StateContext<CompanyStateModel>): Observable<void> {
        return this.companyService.getCompanyOfLoggedInUser()
            .pipe(map((company) => {
                ctx.setState({ ...ctx.getState(), company });
            }));
    }

    @Action(CompanyActions.GetAvailableRoles)
    public getAvailableRoles(ctx: StateContext<CompanyStateModel>, { companyId }: CompanyActions.GetAvailableRoles): Observable<void> {
        ctx.setState({ ...ctx.getState(), availableRoles: null });

        if (!companyId) {
            return of();
        }

        return this.roleService.getAvailableRoleList({ companyId })
            .pipe(map((availableRoles) => {
                ctx.setState({ ...ctx.getState(), availableRoles });
            }));
    }

    @Action(CompanyActions.GetAvailableCompaniesByType)
    public getAvailableCompaniesByType(ctx: StateContext<CompanyStateModel>, { companyType }: CompanyActions.GetAvailableCompaniesByType): Observable<void> {
        const me: User = this.store$.snapshot().user.user;

        if (!hasPermission(me, Permission.CompanyViewExternal)) {
            return of();
        }

        return this.companyService.getSimpleList(companyType)
            .pipe(
                map((availableCompanies) => {
                    ctx.setState({ ...ctx.getState(), availableCompanies });
                }),
                catchError((err) => {
                    console.log(err.message);
                    return of(null);
                })
            );
    }

    @Action(CompanyActions.GetAvailableCompaniesByTypes)
    public getAvailableCompaniesByTypes(ctx: StateContext<CompanyStateModel>, { companyTypes }: CompanyActions.GetAvailableCompaniesByTypes): Observable<void> {
        return this.companyServiceNew.getListByTypes( companyTypes )
            .pipe(map((availableCompanies) => {
                ctx.setState({ ...ctx.getState(), availableCompanies });
            }));
    }

    @Action(CompanyActions.GetAvailableCompaniesByName)
    public getAvailableCompaniesByName(ctx: StateContext<CompanyStateModel>, { companyType, name }: CompanyActions.GetAvailableCompaniesByName): Observable<CompanyStateModel> {
        if (name.length <= 3) {
            ctx.setState((state) => ({...state, availableCompanies: null}));
            return of();
        }

        ctx.setState((state) => ({...state, availableCompaniesLoading: true}));
        return this.companyService.checkByName({ name, companyType } )
            .pipe(
                map((availableCompanies) => ctx.setState((state: CompanyStateModel) => ({...state, availableCompanies}))),
                catchError(err => {
                    this.toastService.error('Error getting available companies', err);
                    return of(null);
                }),
                finalize(() => ctx.setState((state: CompanyStateModel) => ({...state, availableCompaniesLoading: false})))
            );
    }

    @Action(CompanyActions.GetSelectedCompany)
    public getSelectedCompany(ctx: StateContext<CompanyStateModel>, { companyId }: CompanyActions.GetSelectedCompany): Observable<void> {
        // reset state before request
        ctx.setState((state) => ({...state, selectedCompany: null}))

        if (!companyId) {
            return of();
        }

        return this.companyService.getCompany(companyId)
            .pipe(
                map((company) => ctx.setState({ ...ctx.getState(), selectedCompany: company })),
                catchError(err => {
                    this.toastService.error('Error getting company', err);
                    return of(null);
                })
            );
    }


    @Action(CompanyActions.SubmitCompanyForm)
    public submitCompanyForm(ctx: StateContext<CompanyStateModel>, { form }: CompanyActions.SubmitCompanyForm): void {
        if (ctx.getState().selectedCompany) {
            ctx.dispatch(new CompanyActions.EditCompany(form));
        } else {
            ctx.dispatch(new CompanyActions.CreateCompany(form));
        }
    }

    @Action(CompanyActions.CreateCompany)
    public createCompany(ctx: StateContext<CompanyStateModel>, { form }: CompanyActions.CreateCompany): Observable<void> {
        // show loader
        ctx.setState({ ...ctx.getState(), isSubmitting: true });

        // transform form to dto
        const { name, phone, fax, address, email, notificationEmail } = form?.value ?? {};

        const dto = {
            companyProfile: { name, phone, fax, address, email, notificationEmail },
            companyPreferredContact: form?.value?.preferredContactMethod,
            ...form?.getRawValue()
        };

        let create: Observable<any>;

        switch (form?.value?.companyProfileType) {
            case CompanyType.Client:
                create = this.companyService.createClientCompany(dto);
                break;
            case CompanyType.Service_Partner:
                create = this.companyService.createServicePartnerCompany(dto);
                break;
            default:
                throw new Error('Unrecognized Company Profile Type');
        }

        return create
            .pipe(
                map(() => this.ngZone.run(() => this.router.navigateByUrl('/manage/company/list'))),
                finalize(() => ctx.setState({ ...ctx.getState(), isSubmitting: false })),
                catchError((err) => {
                    this.toastService.error('Unable to create company profile', err);
                    return of(null)
                })
            );

    }

    @Action(CompanyActions.EditCompany)
    public editCompany(ctx: StateContext<CompanyStateModel>, { form }: CompanyActions.EditCompany): Observable<void> {
        // show loader
        ctx.setState({ ...ctx.getState(), isSubmitting: true });

        // transform form to dto
        const selectedCompany = ctx.getState().selectedCompany;
        const myCompany = ctx.getState().company;
        const { name, phone, fax, address, email, notificationEmail, ...restFormValue } = form.value;

        let dto;

        if (selectedCompany?.type === CompanyType.Service_Partner) {
            dto = {
                companyStatus: restFormValue.companyStatus,
                servicePartnerCompliant: restFormValue.servicePartnerCompliant,
                companyStatusExplanation: restFormValue.companyStatusExplanation,
                rating: restFormValue.companyRating,
                pbireporting: restFormValue.reporting,
                preferredContactType: restFormValue.preferredContactMethod,
                vehicleInsurance: {
                    status: restFormValue.insAutoLiability.status,
                    expiration: !!restFormValue.insAutoLiability.expiration
                        ? new Date(restFormValue.insAutoLiability.expiration)
                        : null
                },
                workersCompensationInsurance: {
                    status: restFormValue.insWorkersComp.status,
                    expiration: !!restFormValue.insWorkersComp.expiration
                        ? new Date(restFormValue.insWorkersComp.expiration)
                        : null
                },
                generalLiabilityInsurance: {
                    status: restFormValue.insGeneralLiability.status,
                    expiration: !!restFormValue.insGeneralLiability.expiration
                        ? new Date(restFormValue.insGeneralLiability.expiration)
                        : null
                },
                umbrellaLiabilityInsurance: {
                    status: restFormValue.insUmbrellaLiability.status,
                    expiration: !!restFormValue.insUmbrellaLiability.expiration
                        ? new Date(restFormValue.insUmbrellaLiability.expiration)
                        : null
                },
                ...form?.getRawValue()
            };
        }
        else {
            const { profile: { phone, email, preferredContactType } } = selectedCompany;

            dto = {
                companyStatus: !!restFormValue.companyStatus ? restFormValue?.companyStatus : selectedCompany.companyStatus,
                companyStatusExplanation: restFormValue.companyStatusExplanation,
                rating: restFormValue.companyRating,
                pbireporting: Boolean(restFormValue.reporting),
                vehicleInsurance: {
                    status: restFormValue.insAutoLiability?.status,
                    expiration: !!restFormValue.insAutoLiability?.expiration
                        ? new Date(restFormValue.insAutoLiability?.expiration)
                        : null
                },
                workersCompensationInsurance: {
                    status: restFormValue.insWorkersComp?.status,
                    expiration: !!restFormValue.insWorkersComp?.expiration
                        ? new Date(restFormValue.insWorkersComp?.expiration)
                        : null
                },
                generalLiabilityInsurance: {
                    status: restFormValue.insGeneralLiability?.status,
                    expiration: !!restFormValue.insGeneralLiability?.expiration
                        ? new Date(restFormValue.insGeneralLiability?.expiration)
                        : null
                },
                umbrellaLiabilityInsurance: {
                    status: restFormValue.insUmbrellaLiability?.status,
                    expiration: !!restFormValue.insUmbrellaLiability?.expiration
                        ? new Date(restFormValue.insUmbrellaLiability?.expiration)
                        : null
                },
                phone,
                email,
                preferredContactType,
                ...form.getRawValue()
            };
        }

        if (dto.fax === '') {
            delete dto.fax;
        }

        if (!dto.notificationEmail) {
            delete dto.notificationEmail;
        }

        const companyType = selectedCompany.isSubcontractor
            ? selectedCompany.type
            : restFormValue.companyProfileType;

        let edit$: Observable<any>;

        switch (companyType) {
            case CompanyType.Admin:
                edit$ = this.companyService.editAdmin(selectedCompany?._id, dto)
                break;
            case CompanyType.Client:
                edit$ = this.companyService.editClient(selectedCompany?._id, dto)
                break;
            case CompanyType.Service_Partner:
                edit$ = this.companyService.editServicePartner(selectedCompany?._id, dto)
                break;
           case CompanyType.Landscaper:
               if (selectedCompany?._id === myCompany._id) {
                   edit$ = this.companyService.editProfile(dto);
               }
                break;
            default:
                throw new Error('Unrecognized Company Profile Type');
        }

        return edit$
            .pipe(
                map(() => this.ngZone.run(() => this.toastService.success('Updated company profile'))),
                catchError((err) => {
                    this.toastService.error('Unable to update company profile', err);
                    return of(null)
                }),
                finalize(() => ctx.setState({ ...ctx.getState(), isSubmitting: false })),
            );
    }

    @Action(CompanyActions.GetSelectedCompanyUsers)
    public getSelectedCompanyUsers(ctx: StateContext<CompanyStateModel>, { companyId }: CompanyActions.GetSelectedCompanyUsers): Observable<any> {
        // reset state before request
        ctx.setState((state) => ({...state, selectedCompanyUsers: null}))

        if (!companyId) {
            return of();
        }
        return this.userService.getSubCompanyUsers(companyId)
            .pipe(
                map((users) => ctx.setState((state) => ({ ...state, selectedCompanyUsers: users }))),
                catchError((err) => {
                    this.toastService.error('Unable to fetch company`s users:', err);
                    return of(null);
                })
            );
    }

    @Action(CompanyActions.DeleteSelectedCompanyUser)
    public deleteSelectedCompanyUser(ctx: StateContext<CompanyStateModel>, { userId }: CompanyActions.DeleteSelectedCompanyUser): Observable<any> {
        if (!userId) {
            return of();
        }
        const myCompany = ctx.getState().company;
        const selectedCompany = ctx.getState().selectedCompany;

        return (myCompany._id === selectedCompany?._id
            ? this.userService.deleteUsers([userId])
            : this.userService.detachUsersFromPl([userId]))
            .pipe(
                tap(() => this.toastService.success('User deleted!')),
                map(() => ctx.dispatch(new CompanyActions.GetSelectedCompanyUsers(selectedCompany?._id))),
                catchError((err) => {
                    this.toastService.error('Could not delete user', err);
                    return of(null);
                })
            );
    }


    @Action(CompanyActions.GetSelectedCompanySubcontractorInfo)
    public getSelectedCompanySubcontractorInfo(ctx: StateContext<CompanyStateModel>): Observable<any> {
        // reset state before request
        ctx.setState((state) => ({...state, selectedCompanySubcontractorInfo: null}))

        const myCompany = ctx.getState().company;
        const selectedCompany = ctx.getState().selectedCompany;

        // stop if data absent
        if (!myCompany || !selectedCompany?._id) {
            return of();
        }

        // stop if my company is editing
        if (myCompany._id === selectedCompany?._id) {
            return of();
        }

        // stop if my company has only one PL
        if (!selectedCompany.isSubcontractor) {
            return of();
        }

        return this.subcontractorInfoService.getSubcontractorInfoById(selectedCompany?._id)
            .pipe(
                map((subcontractorInfo) => subcontractorInfo
                    ? ctx.setState((state) => ({ ...state, selectedCompanySubcontractorInfo: subcontractorInfo }))
                    : ctx.dispatch(new CompanyActions.CreateSubcontractorInfo())),
                catchError((err) => {
                    console.error(err);
                    this.toastService.error('Unable to fetch company`s Subcontractor Info:', err);
                    return of(null);
                })
            );
    }

    @Action(CompanyActions.CreateSubcontractorInfo)
    public createSubcontractorInfo(ctx: StateContext<CompanyStateModel>): Observable<any> {
        const myCompany = ctx.getState().company;
        const selectedCompany = ctx.getState().selectedCompany;

        // stop if data absent
        if (!myCompany || !selectedCompany) {
            return of();
        }

        if (myCompany._id === selectedCompany?._id) {
            return of();
        }

        // extract selected company data
        const {
            _id,
            requireTimeAllocation,
            companyStatus,
            servicePartnerCompliant,
            questionnaireAnswers: {
                workersCompInsuranceExpiration,
                vehicleInsuranceExpiration,
                umbrellaInsuranceExpiration,
                generalLiabilityInsuranceExpiration
            }
        } = selectedCompany;

        const payload = {
            subcontractorId: _id,
            contractOwnerId: myCompany._id,
            requireTimeAllocation,
            companyStatus,
            servicePartnerCompliant,
            questionnaireAnswers: {
                generalLiabilityInsuranceExpiration,
                umbrellaInsuranceExpiration,
                vehicleInsuranceExpiration,
                workersCompInsuranceExpiration
            }
        };

        return this.subcontractorInfoService.createSubcontractorInfo(payload)
            .pipe(
                map((subcontractorInfo) => ctx.setState((state) => ({ ...state, selectedCompanySubcontractorInfo: subcontractorInfo }))),
                catchError((err) => {
                    console.error(err);
                    this.toastService.error('Unable to create company`s Subcontractor Info:', err);
                    return of(null);
                })
            );
    }

    @Action(CompanyActions.UpdateMyRequireTimeAllocation)
    public updateMyRequireTimeAllocation(ctx: StateContext<CompanyStateModel>, action: CompanyActions.UpdateMyRequireTimeAllocation) {
        return this.companyService.toggleRequireTimeAllocation(ctx.getState().company._id, action.timeAllocationToggle)
            .pipe(
                tap((res) => {
                    this.toastService.success('Time Allocation Updated');
                    ctx.setState((state) => ({
                        ...state,
                        company: new Company({
                            ...state.company,
                            requireTimeAllocation: action.timeAllocationToggle
                        })
                    }));
                }),
                catchError((err: any) => {
                    console.log(err);
                    this.toastService.error(err);
                    return of();
                }));
    }

    @Action(CompanyActions.UpdateRequireTimeAllocation)
    public updateRequireTimeAllocation(ctx: StateContext<CompanyStateModel>, action: CompanyActions.UpdateRequireTimeAllocation) {
        const { selectedCompany, selectedCompanySubcontractorInfo: subcontractorInfo } = ctx.getState();
        const { timeAllocationValue } = action;

        const request$ = subcontractorInfo && subcontractorInfo.contractOwnerId !== subcontractorInfo.subcontractorId
            ? this.subcontractorInfoService.toggleRequireTimeAllocation(subcontractorInfo._id, timeAllocationValue)
            : this.companyService.toggleRequireTimeAllocation(selectedCompany?._id, timeAllocationValue);

        return request$.pipe(
            tap(() => this.toastService.success('Time Allocation Updated')),
            catchError((err) => {
                this.toastService.error(err);
                return of();
            })
        );
    }

    @Action(CompanyActions.GetSelectedCompanyNotes)
    public getSelectedCompanyNotes(ctx: StateContext<CompanyStateModel>, action: CompanyActions.GetSelectedCompanyNotes) {
        const me = this.store$.selectSnapshot(UserState.user);
        const {company: myCompany, selectedCompany} = ctx.getState();
        const {_id: selectedCompanyID} = selectedCompany;

        return this.noteService.getNotesForCompany(selectedCompanyID)
            .pipe(
                map((notes) => {
                    if (myCompany.type === CompanyType.Admin) {
                        return notes.filter(note =>
                            note.noteType === NoteType.OneGM ||
                            note.noteType === NoteType.Client ||
                            note.noteType === NoteType.Service_Partner ||
                            note.noteType === NoteType.Landscaper);
                    } else if (myCompany.type === CompanyType.Landscaper && !selectedCompany.isSubcontractor) {
                        return notes.filter(note =>
                            note.noteType === NoteType.OneGM ||
                            note.noteType === NoteType.Client ||
                            note.noteType === NoteType.Service_Partner ||
                            note.noteType === NoteType.Landscaper);
                    } else if (myCompany.type === CompanyType.Landscaper && selectedCompany.isSubcontractor) {
                        return notes.filter(note =>
                            note.noteType === NoteType.Landscaper
                            && note.userIdentity._id === me._id);
                    } else if (myCompany.type === CompanyType.Client) {
                        return notes.filter(note => note.noteType === NoteType.Client);
                    } else {
                        return notes.filter(note => note.noteType === NoteType.Service_Partner);
                    }
                }),
                tap((notes) => ctx.setState((state) => ({
                    ...state,
                    selectedCompanyNotes: notes
                })))
            )
    }

    @Action(CompanyActions.AddSelectedCompanyNote)
    public addSelectedCompanyNote(ctx: StateContext<CompanyStateModel>, action: CompanyActions.AddSelectedCompanyNote) {
        const {note, noteType} = action;
        const id = ctx.getState().selectedCompany?._id;
        const dto = new NoteCreateDto({ id, note, noteType });

        return this.noteService.createNoteForCompany(dto)
            .pipe(
                tap(() => {
                    ctx.dispatch(new CompanyActions.GetSelectedCompanyNotes())
                    this.toastService.success('Note created!')
                }),
                catchError((err, _) => {
                    this.toastService.error('Could not create note', err);
                    return of();
                }),
            );
    }

    @Action(CompanyActions.DeleteSelectedCompanyNote)
    public deleteSelectedCompanyNote(ctx: StateContext<CompanyStateModel>, action: CompanyActions.DeleteSelectedCompanyNote) {

        return this.noteService.deleteNote(action.id)
            .pipe(
                tap(_ => {
                    ctx.setState((state) => ({
                            ...state,
                            selectedCompanyNotes: state.selectedCompanyNotes.filter((note) => note._id !== action.id)
                        })
                    );
                    this.toastService.success('Note deleted!');
                }),
                catchError((err, _) => {
                    this.toastService.error('Could not delete note', err);
                    return of();
                }),
            )
    }

    @Action(CompanyActions.DeleteSubcontractors)
    public deleteSelectedSubcontractor(ctx: StateContext<CompanyStateModel>, { ids }: CompanyActions.DeleteSubcontractors): Observable<any> {

        if (!ids?.length) {
            return of();
        }

        return this.subcontractorInfoService.deleteSubcontractorInfo(ids)
            .pipe(
                map(() => {
                    this.toastService.success('Company(s) Deleted');
                    this.ngZone.run(() => this.router.navigateByUrl('/manage/company/list'));
                }),
                catchError((err) => {
                    console.error(err);
                    this.toastService.error(err);
                    return of();
                })
            );
    }

    @Action(CompanyActions.DeleteInvalidSubcontractor)
    public deleteDefectiveSubcontractor(ctx: StateContext<CompanyStateModel>, { companyId }: CompanyActions.DeleteInvalidSubcontractor): Observable<any> {

        if (!companyId) {
            return of();
        }

        return this.subcontractorInfoService.deleteInvalidSubcontractor(companyId)
            .pipe(
                map(() => {
                    this.toastService.success('Company(s) Deleted');
                    this.ngZone.run(() => this.router.navigateByUrl('/manage/company/list'));
                }),
                catchError((err) => {
                    console.error(err);
                    this.toastService.error(err);
                    return of();
                })
            );
    }

    @Action(CompanyActions.UpdateCompaniesStatus)
    public inactivateCompanies(ctx: StateContext<CompanyStateModel>, { companies, status }: CompanyActions.UpdateCompaniesStatus): Observable<any> {
        const ids = companies.map((item) => item._id);

        if (!ids?.length) {
            return of();
        }

        return this.companyService
            .updateCompanyStatusesByIdList(ids, status)
            .pipe(
                tap((res) => {
                    const message = status === CompanyStatus.Active
                        ? 'Company(s) Activated'
                        : 'Company(s) Inactivated';
                    this.toastService.success(message);
                }),
                catchError((error) => {
                    console.error(error);
                    this.toastService.error(error);
                    return of();
                })
            );
    }

    @Action(CompanyActions.UpdateSubcontractorsInfosStatus)
    public updateSubcontractorsInfosStatus(ctx: StateContext<CompanyStateModel>, { companies, status }: CompanyActions.UpdateSubcontractorsInfosStatus): Observable<any> {
        // check if all companies have subcontractorInfo
        const companiesWithoutSubcontractorInfo = companies
            ?.filter((item) => !Boolean(item.subcontractorInfo))
            ?.map((item) => item._id);

        // create correspond subcontractors if it is absent
        const beforeUpdate = companiesWithoutSubcontractorInfo.length
            ? this.subcontractorInfoService.createSubcontractorInfoMany(companiesWithoutSubcontractorInfo)
            : of([]);

        return beforeUpdate
            .pipe(
                switchMap((newIds: string[]) => {
                    const ids = companies
                        ?.filter((item) => Boolean(item.subcontractorInfo))
                        ?.map((item) => item.subcontractorInfo._id)
                        .concat(newIds);

                    if (!ids?.length) {
                        return of();
                    }

                    return this.subcontractorInfoService
                        .updateSubcontractorInfosStatuses(ids, status)
                        .pipe(
                            tap((res) => {
                                const message = status === CompanyStatus.Active
                                    ? 'Subcontractor(s) Activated'
                                    : 'Subcontractor(s) Inactivated';
                                this.toastService.success(message);
                            }),
                            catchError((error) => {
                                console.error(error);
                                this.toastService.error(error);
                                return of();
                            })
                        );
                })
            );
    }

    @Action(CompanyActions.UpdateGeospatialServiceStatus)
    public updateGeospatialServiceStatus(ctx: StateContext<CompanyStateModel>, action: CompanyActions.UpdateGeospatialServiceStatus) {
        const { geospatialServiceStatus, companyId } = action;

        return this.companyServiceNew.updateGeospatialServiceStatus(companyId, geospatialServiceStatus)
            .pipe(
                tap(() => {
                    this.store$.dispatch(new GeospatialActions.UpdateUsageEntry(companyId, { geospatialServiceStatus }));
                    this.toastService.success('Geospatial Service status updated');
                }),
                catchError((err, _) => {
                    this.toastService.error('Could not update Geospatial Service status', err);
                    return of();
                })
            );
    }

    @Action(CompanyActions.SetProps)
    public resetStateProps(ctx: StateContext<CompanyStateModel>, { propsToRewrite }: CompanyActions.SetProps): void {
        ctx.setState({
            ...ctx.getState(),
            ...propsToRewrite
        });
    }
}
