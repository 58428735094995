<ng-container *ngIf="(paginationState$ | async) as state">
    <span class="spacer"></span>
    <div class="page-size">
        <label class="page-size__label">Items Per Page:</label>
        <mat-form-field class="page-size__select-container">
            <mat-select
                class="page-size__select"
                (valueChange)="changePageSize($event)"
                [formControl]="pageLimitControl"
            >
                <mat-option
                    *ngFor="let option of state.pageSizeOptions"
                    [value]="option"
                >
                    {{ option }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="buttons">
        <div class="back-buttons" [class.back-buttons--disabled]="!canGoBack">
            <fa-icon
                class="back-buttons__icon"
                [icon]="firstPageIcon"
                (click)="firstPage()"
            ></fa-icon>
            <fa-icon
                class="back-buttons__icon"
                [icon]="previousPageIcon"
                (click)="previousPage()"
            ></fa-icon>
        </div>
        <div class="page">
            <label class="page__label">
                {{ state.viewing[0] }} - {{ state.viewing[1] }} of
                {{ displayCount(state) }}
            </label>
        </div>
        <div
            class="forward-buttons"
            [class.forward-buttons--disabled]="!canGoForward"
        >
            <fa-icon
                class="forward-buttons__icon"
                [icon]="nextPageIcon"
                (click)="nextPage()"
            ></fa-icon>
            <fa-icon
                class="forward-buttons__icon"
                [icon]="lastPageIcon"
                (click)="lastPage()"
            ></fa-icon>
        </div>
    </div>
</ng-container>
