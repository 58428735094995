//#region Mapping
export { AreaCalculationService } from './mapping/area-calculation-service';
//#endregion

//#region Timesheet
export {
    MaterialCalculationService,
    MaterialCalculationResult,
} from './timesheet/material-calculation-service';
export { ITimesheetCharges, TimesheetChargesFactory } from './timesheetCharges/';
//#endregion

//#region Rfp
export { RfpCalculationService } from './rfp/rfp-calculation-service';
//#endregion

//#region Invoice
export {
    canDeleteInvoice,
    canEditInvoice,
    getInvoiceAvailableTimesheetMaxStartDate,
} from './invoice/invoice-methods';
export {
    calculateInvoiceTotals,
    calculateInvoiceMonthlyTotals,
    calculateInvoiceNonMonthlyTotals,
} from './invoice/invoice-calculations';
//#endregion

//#region WorkOrderInvoice
export {
    canDeleteWorkOrderInvoice,
    canEditWorkOrderInvoice,
    getWorkOrderInvoiceAvailableWorkOrderMaxStartDate,
} from './work-order-invoice/work-order-invoice-methods';
export {
    calculateWorkOrderInvoiceTotals,
} from './work-order-invoice/work-order-invoice-calculations';
//#endregion

//#region WorkOrder
export {
    canSetWorkOrderStatusCreated,
    canSetWorkOrderStatusPending,
    canSetWorkOrderStatusReview,
    canSetWorkOrderStatusApproved,
    canSetWorkOrderStatusRejected,
    canSetWorkOrderStatusServicePartnerRejected,
    canSetWorkOrderStatusClientRejected,
    canSetWorkOrderStatusCompleted,
    canSetWorkOrderStatusPosted,
    canSetWorkOrderStatusPaymentProcessed,
    canSetWorkOrderActiveStatusActive,
    canSetWorkOrderActiveStatusHold,
    canSetWorkOrderActiveStatusInactive,
    canSetWorkOrderPhaseStatus,
    zeroOutWorkOrderSalesTax,
    zeroOutWorkOrderClientPrice,
    calcPhaseMaterialItemTotal,
    calcPhaseEquipmentItemTotal,
    calcPhaseLaborItemTotal,
    calcPhaseMaterialTotal,
    calcPhaseEquipmentTotal,
    calcPhaseLaborTotal,
    calcPhaseSalesTaxTotal,
    calcPhaseTotal,
    calcWorkOrderMaterialTotal,
    calcWorkOrderEquipmentTotal,
    calcWorkOrderLaborTotal,
    calcClientWorkOrderMaterialTotal,
    calcClientWorkOrderEquipmentTotal,
    calcClientWorkOrderLaborTotal,
    calcClientWorkOrderSalesTaxTotal,
    calcWorkOrderClientMarkUp,
    calcWorkOrderClientPretaxPrice,
    calcWorkOrderClientPrice,
    calcWorkOrderOgmTotal,
    calcWorkOrderGrossProfit,
    calcWorkOrderGrossProfitPercent,
    calcWorkOrderDaysInCurrentStatus,
    calcInternalPhaseLaborItemTotal,
    calcInternalPhaseEquipmentItemTotal,
    calcInternalPhaseMaterialItemTotal,
    calcInternalPhaseEquipmentTotal,
    calcInternalPhaseMaterialTotal,
    calcInternalPhaseLaborTotal,
    calcInternalPhaseTotal,
} from './work-order/work-order-methods';

export { woCreateNewAdmin, woRequestWo, spFormLoad, notSubcontractorFormLoad } from './work-order/work-order-edit';
//endregion
