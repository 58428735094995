import { AbstractControl, ValidatorFn, Validators, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

/**
 * Provides options for the strength level applied to the password validators
 *
 * Basic - minLength 4, uppercase and lowercase required
 * Moderate - minLength 8, uppercase, lowercase, and a number required
 * Strong - minLength 12, uppercase, lowercase, number, and a special character required
 */
export enum PasswordStrengthLevel {
    Basic = 1,
    Moderate,
    Strong
}

/**
 * RfxValidators
 *
 * Class containing all of the Riafox custom validators
 * @dynamic
 */
export class RfxValidators {
    /**
     * RfxValidators.containsLowercase
     *
     * Enforces that the input must contain at least 1 lowercase letter a-z
     *
     * ```Typescript
     * new FormControl('', [RfxValidators.containsLowercase])
     * ```
     */
    public static containsLowercase: ValidatorFn = (
        control: AbstractControl
    ): { [key: string]: boolean } => {
        if (RfxValidators.isPresent(Validators.required(control))) {
            return null;
        }

        const value: string = control.value;
        return /([a-z])/.test(value) ? null : { containsLowercase: true };
    };

    /**
     * RfxValidators.containsNumber
     *
     * Enforces that the input must contain at least 1 number
     *
     * ```Typescript
     * new FormControl('', [RfxValidators.containsNumber])
     * ```
     */
    public static containsNumber: ValidatorFn = (
        control: AbstractControl
    ): { [key: string]: boolean } => {
        if (RfxValidators.isPresent(Validators.required(control))) {
            return null;
        }

        const value: string = control.value;
        return /\d/.test(value) ? null : { containsNumber: true };
    };

    /**
     * RfxValidators.containsSpecialChar
     *
     * Enforces that the input must contain at least 1 character that
     * is not a-z, 0-9, or a space
     *
     * ```Typescript
     * new FormControl('', [RfxValidators.containsSpecialChar])
     * ```
     */
    public static containsSpecialChar: ValidatorFn = (
        control: AbstractControl
    ): { [key: string]: boolean } => {
        if (RfxValidators.isPresent(Validators.required(control))) {
            return null;
        }

        const value: string = control.value;
        return /[^a-zA-Z \d]/.test(value) ? null : { containsSpecialChar: true };
    };

    /**
     * RfxValidators.containsUppercase
     *
     * Enforces that the input must contain at least 1 uppercase letter A-Z
     *
     * ```Typescript
     * new FormControl('', [RfxValidators.containsUppercase])
     * ```
     */
    public static containsUppercase: ValidatorFn = (
        control: AbstractControl
    ): { [key: string]: boolean } => {
        if (RfxValidators.isPresent(Validators.required(control))) {
            return null;
        }

        const value: string = control.value;
        return /([A-Z])/.test(value) ? null : { containsUppercase: true };
    };

    /**
     * RfxValidators.digits
     *
     * Enforces that the input must contain only digits 0-9
     *
     * ```Typescript
     * new FormControl('', [RfxValidators.digits])
     * ```
     */
    public static digits: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } => {
        if (RfxValidators.isPresent(Validators.required(control))) {
            return null;
        }

        const value: string = control.value;
        return /^\d+$/.test(value) ? null : { digits: true };
    };

    /**
     * RfxValidators.emailUniqueAsync
     *
     * Same functionality as uniqueAsync, but returns a different error
     * to facilitate error messages
     *
     * ```Typescript
     * new FormControl('', [], [RfxValidators.emailUniqueAsync(lookupFn, context, delay)])
     * ```
     *
     * @param lookupFn A function that returns an Observable that returns a boolean where
     *      true === unique
     *      false === not unique
     * @param context Context for the lookupFn if it is needed. Pass {} if not needed
     * @param lookupDelay Specify the debounce time between end of user input
     *      and when the request is made. Specify 0 to lookup on every keystroke
     */
    public static emailUniqueAsync = (
        lookupFn: (value: string) => Observable<boolean>,
        context: any,
        lookupDelay: number = 350
    ): AsyncValidatorFn => {
        let emailUniqueAsyncTimeout: number;
        let emailUniqueAsyncPreviousVal: any;
        let emailUniqueAsyncResponse: { [key: string]: boolean };

        return (control: AbstractControl): Promise<{ [key: string]: boolean }> => {
            if (emailUniqueAsyncTimeout) {
                window.clearTimeout(emailUniqueAsyncTimeout);
            }
            return new Promise(
                (resolve, reject): void => {
                    if (RfxValidators.isPresent(Validators.required(control))) {
                        resolve(null);
                    } else {
                        emailUniqueAsyncTimeout = window.setTimeout(() => {
                            if (
                                typeof emailUniqueAsyncResponse !== 'undefined' &&
                                control.value === emailUniqueAsyncPreviousVal
                            ) {
                                resolve(emailUniqueAsyncResponse);
                            } else {
                                (lookupFn.call(context, control.value) as Observable<
                                    boolean
                                >).subscribe(isUnique => {
                                    const res = isUnique ? null : { emailUniqueAsync: true };
                                    emailUniqueAsyncResponse = res;
                                    emailUniqueAsyncPreviousVal = control.value;
                                    resolve(res);
                                });
                            }
                        }, lookupDelay);
                    }
                }
            );
        };
    };

    /**
     * RfxValidators.equal
     *
     * Checks that a given input is strictly equal to a given value
     *
     * ```Typescript
     * new FormControl('', [RfxValidators.equal(42)])
     * ```
     *
     * @param val Value that the form field should match
     */
    public static equal = (val: any): ValidatorFn => (
        control: AbstractControl
    ): { [key: string]: any } => {
        if (RfxValidators.isPresent(Validators.required(control))) {
            return null;
        }

        const value: any = control.value;

        return val === value ? null : { equal: true };
    };

    /**
     * RfxValidators.equalTo
     *
     * Checks that a given input is strictly equal to the value of a second input
     *
     * ```Typescript
     * const control1 = new FormControl('');
     * const control2 = new FormControl('', [RfxValidators.equalTo(control1)])
     * ```
     *
     * @param equalControl FormControl to use for comparison
     */
    public static equalTo = (equalControl: AbstractControl): ValidatorFn => {
        let subscribe: boolean = false;

        return (control: AbstractControl): { [key: string]: boolean } => {
            if (!subscribe) {
                subscribe = true;
                equalControl.valueChanges.subscribe(() => {
                    control.updateValueAndValidity();
                });
            }

            const value: string = control.value;

            return equalControl.value === value ? null : { equalTo: true };
        };
    };

    /**
     * RfxValidators.rangeLength
     *
     * Checks that a given input length is within a given range
     *
     * ```Typescript
     * new FormControl('', [RfxValidators.rangeLength([4, 8])])
     * ```
     *
     * @param rangeLength Tuple where the first value is the minimum length and the second
     *      is the maximum length. The values are inclusive.
     */
    public static rangeLength = (rangeLength: [number, number]): ValidatorFn => (
        control: AbstractControl
    ): { [key: string]: boolean } => {
        if (RfxValidators.isPresent(Validators.required(control))) {
            return null;
        }

        const value: string =
            typeof control.value === 'string' ? control.value : JSON.stringify(control.value);
        return value.length >= rangeLength[0] && value.length <= rangeLength[1]
            ? null
            : { rangeLength: true };
    };

    /**
     * RfxValidators.zipCode
     *
     * Enforces the structure for US-based zip codes (xxxxx OR xxxxx-xxxx)
     *
     * ```Typescript
     * new FormControl('', [RfxValidators.zipCode])
     * ```
     */
    public static zipCode: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } => {
        if (RfxValidators.isPresent(Validators.required(control))) {
            return null;
        }

        const value: string = control.value;
        return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value) ? null : { zipCode: true };
    };

    /**
     * RfxValidators.uniqueAsync
     *
     * Same functionality as emailUniqueAsync, but returns a different error
     * to facilitate error messages
     *
     * ```Typescript
     * new FormControl('', [], [RfxValidators.uniqueAsync(lookupFn, context, delay)])
     * ```
     *
     * @param lookupFn A function that returns an Observable that returns a boolean where
     *      true === unique
     *      false === not unique
     * @param context Context for the lookupFn if it is needed. Pass {} if not needed
     * @param lookupDelay Specify the debounce time between end of user input
     *      and when the request is made. Specify 0 to lookup on every keystroke
     */
    public static uniqueAsync = (
        lookupFn: (value: string) => Observable<boolean>,
        context: any,
        lookupDelay: number = 350
    ): AsyncValidatorFn => {
        let uniqueAsyncTimeout: number;
        let uniqueAsyncPreviousVal: any;
        let uniqueAsyncResponse: { [key: string]: boolean };

        return (control: AbstractControl): Promise<{ [key: string]: boolean }> => {
            if (uniqueAsyncTimeout) {
                window.clearTimeout(uniqueAsyncTimeout);
            }
            return new Promise(
                (resolve, reject): void => {
                    if (RfxValidators.isPresent(Validators.required(control))) {
                        resolve(null);
                    } else {
                        uniqueAsyncTimeout = window.setTimeout(() => {
                            if (
                                typeof uniqueAsyncResponse !== 'undefined' &&
                                control.value === uniqueAsyncPreviousVal
                            ) {
                                resolve(uniqueAsyncResponse);
                            } else {
                                (lookupFn.call(context, control.value) as Observable<
                                    boolean
                                >).subscribe(isUnique => {
                                    const res = isUnique ? null : { emailUniqueAsync: true };
                                    uniqueAsyncResponse = res;
                                    uniqueAsyncPreviousVal = control.value;
                                    resolve(res);
                                });
                            }
                        }, lookupDelay);
                    }
                }
            );
        };
    };

    /**
     * RfxValidators.password
     *
     * Composes a set of validators used to validate password requirements
     *
     * ```Typescript
     * new FormControl('', [RfxValidators.password(1, 8)])
     * ```
     * {@link PasswordStrengthLevel}
     * @param strength Specify which set of requirements to use (See PasswordStrengthLevel)
     * @param minPassLength Override the minPassLength of the individual levels for a custom value
     */
    public static password = (
        strength: PasswordStrengthLevel = PasswordStrengthLevel.Basic,
        minPassLength: number = 0
    ): ValidatorFn => {
        const validators = [Validators.required];
        switch (strength) {
            case PasswordStrengthLevel.Strong:
                validators.push(RfxValidators.containsSpecialChar);
                // tslint:disable-next-line:no-magic-numbers
                minPassLength = minPassLength || 12;
            // falls through
            case PasswordStrengthLevel.Moderate:
                validators.push(RfxValidators.containsNumber);
                // tslint:disable-next-line:no-magic-numbers
                minPassLength = minPassLength || 8;
            // falls through
            case PasswordStrengthLevel.Basic:
            default:
                // tslint:disable-next-line:no-magic-numbers
                minPassLength = minPassLength || 4;
                validators.push(RfxValidators.containsLowercase);
                validators.push(RfxValidators.containsUppercase);
                validators.push(Validators.minLength(minPassLength));
        }
        return Validators.compose(validators);
    };

    /**
     * @ignore
     */
    private static isPresent(obj: any): boolean {
        return obj !== undefined && obj !== null;
    }
}
