import { Injectable } from '@angular/core';

@Injectable()
export abstract class FormBuilderUIUtil {
    /**
     * Whether or not a user can create a form
     */
    public abstract canCreate(): Promise<boolean>;

    /**
     * Whether or not a user can edit a form
     */
    public abstract canEdit(): Promise<boolean>;

    /**
     * Whether or not a user can delete a form
     */
    public abstract canDelete(): Promise<boolean>;

    /**
     * Whether or not a user can export form submissions
     */
    public abstract canExportData(): Promise<boolean>;

    /**
     * Dictionary of confirm function keys and their functions.
     *
     * The confirm function with a key that matches the
     * confirmFunctionKey on the form being viewed will
     * be called before submission of the formbuilder form.
     * Will only proceed with submission if response is true.
     */
    public abstract getConfirmFunctions(): {
        [key: string]: (name: string) => Promise<boolean>;
    };
}
