export enum RfxFormFieldConditionalAction {
    HideField = '[RfxFormFieldConditionalAction] hideField',
    ShowField = '[RfxFormFieldConditionalAction] showField'
}

export namespace RfxFormFieldConditionalAction {
    export function toString(type: RfxFormFieldConditionalAction): string {
        switch (type) {
            case RfxFormFieldConditionalAction.HideField:
                return 'Hide Field';
            case RfxFormFieldConditionalAction.ShowField:
                return 'Show Field';
            default:
                return '';
        }
    }

    export const members: RfxFormFieldConditionalAction[] = [
        RfxFormFieldConditionalAction.HideField,
        RfxFormFieldConditionalAction.ShowField
    ];
}
