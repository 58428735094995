import { Injectable } from '@angular/core';
import { Get, Path, HttpService, Body, Post, Delete, FakeResponse } from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { Note } from '../models/note/Note';
import { NoteCreateDto } from '../models/note/NoteCreateDto';

@Injectable({
    providedIn: 'root'
})
export class NoteService extends HttpService {
    /**
     * Get notes for a site engineering plan
     */
    @Get('/note/map/:id')
    public getNotesForMapping(@Path('id') id: string): Observable<Note[]> {
        return null;
    }

    /**
     * Create note for a site engineering plan
     */
    @Post('/note/map')
    public createNoteForMapping(@Body() dto: NoteCreateDto): Observable<string> {
        return null;
    }

    /**
     * Get notes for a location
     * @param id Location Id
     */
    @Get('/note/location/:id')
    public getNotesForLocation(@Path('id') id: string): Observable<Note[]> {
        return null;
    }

    /**
     * Create note for a location
     * @param dto Note create dto
     */
    @Post('/note/location')
    public createNoteForLocation(@Body() dto: NoteCreateDto): Observable<string> {
        return null;
    }

    /**
     * Get notes for a company
     * @param id Company Id
     */
    @Get('/note/company/:id')
    public getNotesForCompany(@Path('id') id: string): Observable<Note[]> {
        return null;
    }

    /**
     * Create note for a company
     * @param dto Note create dto
     */
    @Post('/note/company')
    public createNoteForCompany(@Body() dto: NoteCreateDto): Observable<string> {
        return null;
    }

    /**
    * Get notes for a work order
    * @param id workOrderId
    */
    @Get('/note/workOrder/:id')
    public getNotesForWorkOrder(@Path('id') id: string): Observable<Note[]> {
        return null;
    }

    /**
    * Create note for a work order
    * @param dto Note create dto
    */
    @Post('/note/workOrder')
    public createNoteForWorkOrder(@Body() dto: NoteCreateDto): Observable<string> {
        return null;
    }

    /**
     * Delete a note
     * @param id Note Id
     */
    @Delete('/note/:id')
    public deleteNote(@Path('id') id: string): Observable<void> {
        return null;
    }
}
