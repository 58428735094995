import { DateBucket, parseISODate } from '@refactor/common';

export class ContractDates {
    public startDateBucket: DateBucket = new DateBucket();
    public startDate: Date = new Date();
    public endDateBucket: DateBucket = new DateBucket();
    public endDate: Date = new Date();

    public bidStartDateBucket: DateBucket = new DateBucket();
    public bidStartDate: Date = new Date();
    public bidEndDateBucket: DateBucket = new DateBucket();
    public bidEndDate: Date = new Date();

    constructor(params?: Partial<ContractDates>) {
        if (!!params) {
            this.startDateBucket = !!params.startDateBucket
                ? new DateBucket(params.startDateBucket)
                : this.startDateBucket;

            this.startDate = !!params.startDate ? parseISODate(params.startDate) : this.startDate;

            this.endDateBucket = !!params.endDateBucket
                ? new DateBucket(params.endDateBucket)
                : this.endDateBucket;

            this.endDate = !!params.endDate ? parseISODate(params.endDate) : this.endDate;

            this.bidStartDateBucket = !!params.bidStartDateBucket
                ? new DateBucket(params.bidStartDateBucket)
                : this.bidStartDateBucket;

            this.bidStartDate = !!params.bidStartDate
                ? parseISODate(params.bidStartDate)
                : this.bidStartDate;

            this.bidEndDateBucket = !!params.bidEndDateBucket
                ? new DateBucket(params.bidEndDateBucket)
                : this.bidEndDateBucket;

            this.bidEndDate = !!params.bidEndDate
                ? parseISODate(params.bidEndDate)
                : this.bidEndDate;
        }
    }
}
