import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface IDialogData {
    message: string;
    title?: string;
    confirmation?: string;
}

@Component({
    selector: 'form-builder-confirm-modal',
    templateUrl: './form-builder-confirm-modal.component.html',
    styleUrls: ['./form-builder-confirm-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormBuilderConfirmModalComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<FormBuilderConfirmModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData
    ) {}

    ngOnInit(): void {}

    public close(): void {
        this.dialogRef.close(false);
    }

    public confirm(): void {
        this.dialogRef.close(true);
    }
}
