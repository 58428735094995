import { BaseDoc } from '../base/base-doc';
import { MediaDocumentType } from '../../enums';
import { UserIdentityWithoutIds } from '../user/user-identity';
import { isNull } from '../../utils';
import { parseISODate } from '@refactor/common';

export class BaseMediaDocumentWithoutIds {
    public path: string = '';
    public name: string = '';
    public type: MediaDocumentType = null;
    public takeBeforeServicePerformed?: boolean = null;
    public dateTaken?: Date;
    public dateUploaded?: Date;
    public userIdentity: UserIdentityWithoutIds = new UserIdentityWithoutIds();

    constructor(params?: Partial<BaseMediaDocumentWithoutIds>) {
        if (!!params) {
            this.path = params.path || this.path;
            this.name = params.name || this.name;
            this.type = params.type || this.type;
            this.takeBeforeServicePerformed = !isNull(params.takeBeforeServicePerformed)
                ? !!params.takeBeforeServicePerformed
                : this.takeBeforeServicePerformed;
            this.dateTaken = !!params.dateTaken ? parseISODate(params.dateTaken) : this.dateTaken;
            this.dateUploaded = !!params.dateUploaded
                ? parseISODate(params.dateUploaded)
                : this.dateUploaded;
            this.userIdentity = !!params.userIdentity
                ? new UserIdentityWithoutIds(params.userIdentity)
                : this.userIdentity;
        }
    }
}
