import { ITimesheetCharges } from '@gm2/common';
import { IBillingTypeChargeStrategy } from '../interfaces/i-billing-type-charge-strategy';
import { BillingTypeChargeStrategyFactory } from '../billing-type-charge-strategy-factory';
import { TimesheetWithoutIds, MaterialWithoutIds, TimesheetCharges } from '@gm2/common';
import { twoDecimalRound } from '@gm2/common';

export class OnegmCharges implements ITimesheetCharges {
    protected timesheet: TimesheetWithoutIds;
    protected approvedMaterials: MaterialWithoutIds[];
    protected approvedMaterialDict: { [key: string]: MaterialWithoutIds } = {};

    protected clientChargeStrategy: IBillingTypeChargeStrategy;

    constructor(timesheet: TimesheetWithoutIds, approvedMaterials: MaterialWithoutIds[]) {
        this.timesheet = timesheet;
        this.approvedMaterials = approvedMaterials;

        const billingTypeFactory = new BillingTypeChargeStrategyFactory();
        this.clientChargeStrategy = billingTypeFactory.createOnegm(
            timesheet.snapShot.contractIdentity.billingType
        );
    }

    public calculate(): TimesheetCharges {
        const clientCharges = this.clientChargeStrategy.calculateClientCharges(this.timesheet, 0);

        return new TimesheetCharges({
            durationMinutes: clientCharges.duration,
            total: twoDecimalRound(clientCharges.total),
            clientCompanyTotal: twoDecimalRound(clientCharges.total)
        });
    }

    public getCompanyChargeBillingType(): string {
        return '';
    }

    public getClientChargeBillingType(): string {
        return (<any>this.clientChargeStrategy).constructor.name;
    }
}
