import { Location, LocationService, ServiceType, ServiceTypeService, TimesheetIdentity, ToastService, UserService, UserSimple } from '@gm2/ui-common';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { catchError, finalize, tap } from 'rxjs/operators';
import { of } from 'rxjs';

export namespace LocationsActions {

    export class SetProps {
        static readonly type = '[Locations] Set Particular Props';
    }

    export class GetSelectedLocation {
        static readonly type = '[Locations] Get Selected Location';

        constructor(public id: string) {
        }
    }

    export class GetServiceTypes {
        static readonly type = '[Locations] Get Service Types';

        constructor(public companyId?: string) {
        }
    }

    export class GetStrategicAccountManagers {
        static readonly type = '[Locations] Get Strategic Account Managers';

        constructor(public companyId?: string) {
        }
    }

    export class GetFacilitiesManagers {
        static readonly type = '[Locations] Get Facilities Managers';

        constructor(public companyId: string) {
        }
    }

    export class GetProductionManagers {
        static readonly type = '[Locations] Get Production Managers';

        constructor(public companyId?: string) {
        }
    }
}

export interface LocationsStateModel {
    selectedLocation: Location;
    strategicAccountManagers: UserSimple[];
    facilitiesManagers: UserSimple[];
    productionManagers: UserSimple[];
    serviceTypes: ServiceType[]
}

const initialState: LocationsStateModel = {
    selectedLocation: null,
    strategicAccountManagers: null,
    facilitiesManagers: null,
    productionManagers: null,
    serviceTypes: null,
};

@State<LocationsStateModel>({
    name: 'locations',
    defaults: initialState
})
@Injectable()
export class LocationsState {

    @Selector()
    @ImmutableSelector()
    public static selectLocation(state: LocationsStateModel): Location {
        return state.selectedLocation;
    }

    @Selector()
    @ImmutableSelector()
    public static selectServiceTypes(state: LocationsStateModel): ServiceType[] {
        return state.serviceTypes;
    }

    @Selector()
    @ImmutableSelector()
    public static selectStrategicAccountManagers(state: LocationsStateModel): UserSimple[] {
        return state.strategicAccountManagers;
    }

    @Selector()
    @ImmutableSelector()
    public static selectFacilitiesManagers(state: LocationsStateModel): UserSimple[] {
        return state.facilitiesManagers;
    }

    @Selector()
    @ImmutableSelector()
    public static selectProductionManagers(state: LocationsStateModel): UserSimple[] {
        return state.productionManagers;
    }

    constructor(
        public locationService: LocationService,
        private userService: UserService,
        private serviceTypeService: ServiceTypeService,
        private toastService: ToastService,
    ) {
    }

    @Action(LocationsActions.GetSelectedLocation)
    public getSelectedTimesheet(ctx: StateContext<LocationsStateModel>, { id }: LocationsActions.GetSelectedLocation) {
        ctx.setState((state) => ({...state, selectedLocation: null}));

        if (!id) {
            return of();
        }

        return this.locationService.getLocation(id)
            .pipe(
                tap((selectedLocation) => ctx.setState((state) => ({ ...state, selectedLocation }))),
                catchError((err: any) => {
                    this.toastService.error(err?.error?.message);
                    return of();
                }),
            );
    }

    @Action(LocationsActions.GetServiceTypes)
    public getServiceTypes(ctx: StateContext<LocationsStateModel>, { companyId }: LocationsActions.GetServiceTypes) {
        ctx.setState((state) => ({...state, serviceTypes: null}));

        return this.serviceTypeService.getServiceTypes(companyId)
            .pipe(
                tap((serviceTypes) => ctx.setState((state) => ({ ...state, serviceTypes }))),
                catchError((err: any) => {
                    this.toastService.error(err?.error?.message);
                    return of();
                }),
            );
    }

    @Action(LocationsActions.GetStrategicAccountManagers)
    public getStrategicAccountManagers(ctx: StateContext<LocationsStateModel>, { companyId }: LocationsActions.GetStrategicAccountManagers) {

        return this.userService.getStrategicAccountManagersByCompanyId(companyId)
            .pipe(
                tap((strategicAccountManagers) => ctx.setState((state) => ({ ...state, strategicAccountManagers }))),
                catchError((err: any) => {
                    this.toastService.error(err?.error?.message);
                    return of();
                }),
            );
    }

    @Action(LocationsActions.GetFacilitiesManagers)
    public getFacilitiesManagers(ctx: StateContext<LocationsStateModel>, { companyId }: LocationsActions.GetFacilitiesManagers) {

        if (!companyId) {
            return of();
        }

        return this.userService.getFacilitiesManagersByCompanyId(companyId)
            .pipe(
                tap((facilitiesManagers) => ctx.setState((state) => ({ ...state, facilitiesManagers }))),
                catchError((err: any) => {
                    this.toastService.error(err?.error?.message);
                    return of();
                }),
            );
    }

    @Action(LocationsActions.GetProductionManagers)
    public getProductionManagers(ctx: StateContext<LocationsStateModel>, { companyId }: LocationsActions.GetProductionManagers) {

        return this.userService.getProductionManagersByCompanyId(companyId)
            .pipe(
                tap((productionManagers) => ctx.setState((state) => ({ ...state, productionManagers }))),
                catchError((err: any) => {
                    this.toastService.error(err?.error?.message);
                    return of();
                }),
            );
    }

}
