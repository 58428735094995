import { InvoiceStatus } from '../../enums';

export class InvoiceIdentityWithoutIds {
    public _id: any;
    public number: string;
    public status: InvoiceStatus;

    constructor(params?: Partial<InvoiceIdentityWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.number = params.number || this.number;
            this.status = params.status || this.status;
        }
    }
}
