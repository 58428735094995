import { NoteType } from '@gm2/common';

export class NoteCreateDto {
    public id: string = '';
    public note: string = '';
    public noteType: string = NoteType.OneGM.toString();

    constructor(params?: Partial<NoteCreateDto>) {
        this.id = params.id || this.id;
        this.note = params.note || this.note;
        this.noteType = params.noteType || this.noteType;
    }
}
