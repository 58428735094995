import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MappingType, IMappingDetails, MappingCategory, ServiceType } from '@gm2/ui-common';
import { MappingClassification } from '@gm2/common';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { takeUntil, tap, map, filter, switchMap, take } from 'rxjs/operators';

const DEFAULT_WIDTH_IN = 60;

@Component({
    selector: 'gm2-finish-mapping-area-modal',
    templateUrl: './finish-mapping-area-modal.component.html',
    styleUrls: ['./finish-mapping-area-modal.component.scss']
})
export class FinishMappingAreaModalComponent implements OnInit {
    // Forward MappingType enum to view
    public readonly MappingType: typeof MappingType = MappingType;

    // This should conform to IMappingDetails
    public readonly form: UntypedFormGroup = this.formBuilder.group({
        name: ['', Validators.required],
        serviceTypeId: ['', Validators.required],
        mappingCategoryId: ['', Validators.required],
        mappingClassification: ['', Validators.required],
        width: [DEFAULT_WIDTH_IN],
        notes: ['']
    });

    public readonly MappingClassification: typeof MappingClassification = MappingClassification;
    public readonly serviceTypes: ServiceType[];
    public readonly serviceTypeId$: Subject<string>;
    public readonly mappingCategories$: Observable<MappingCategory[]>;
    public readonly type: MappingType;
    public readonly typename: string;
    public readonly hideClassification$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _unsub: Subject<void> = new Subject<void>();

    constructor(
        private readonly dialogRef: MatDialogRef<FinishMappingAreaModalComponent>,
        private readonly formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA)
        data: {
            type: MappingType;
            serviceTypeId$: Subject<string>;
            serviceTypes: ServiceType[];
            preload?: IMappingDetails;
        }
    ) {
        this.type = data.type;
        this.typename = MappingType.toString(data.type);
        this.serviceTypes = data.serviceTypes;
        this.serviceTypeId$ = data.serviceTypeId$;
        this.mappingCategories$ = this.serviceTypeId$.pipe(
            takeUntil(this._unsub),
            map(id => data.serviceTypes.find(serviceType => serviceType._id === id)),
            map(serviceType => serviceType.mappingCategories.filter(category =>
                category.type === data.type)),
        );

        this.serviceTypeId$.pipe(take(1)).subscribe(id =>
            this.form.get('serviceTypeId').patchValue(id));

        if (data.preload) {
            this.form.patchValue(data.preload);
            this.checkToHideClassificationField(data.preload.mappingCategoryId);
        }
    }

    ngOnInit(): void {
        this.form.controls.mappingCategoryId.valueChanges
            .pipe(
                takeUntil(this._unsub),
                tap(id => this.checkToHideClassificationField(id))
            )
            .subscribe();
        this.form.controls.serviceTypeId.valueChanges
            .subscribe(this.serviceTypeId$);
    }

    ngOnDestroy(): void {
        this._unsub.next();
        this._unsub.complete();
    }

    public checkToHideClassificationField(id: string): void {
        const lawnsMappingCategoryId = '5ebccb0c0593fa93861f9340';
        const bedsMappingCategoryId = '5ebccb0c0593fa93861f933f';

        if (id === lawnsMappingCategoryId || id === bedsMappingCategoryId) {
            this.hideClassification$.next(true);
            this.form.controls.mappingClassification.setValue(MappingClassification.Other);
        } else {
            this.hideClassification$.next(false);
        }
    }

    public onCancel(): void {
        this.dialogRef.close(null);
    }

    public onSave(): void {
        // Serialize form values
        if (this.form.valid) {
            this.serviceTypeId$.subscribe(serviceTypeId => {
                const details: IMappingDetails = this.form.value;
                details.serviceTypeId = serviceTypeId;

                this.dialogRef.close(details);
            });
        } else {
            this.form.markAllAsTouched();
        }
    }
}
