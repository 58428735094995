import { WorkOrderWithoutIds } from '@gm2/common';
import { CompanyIdentity } from '../company/CompanyIdentity';
import { LocationIdentity } from '../location/location-identity';
import { UserIdentity } from '../user/UserIdentity';
import { WorkOrderPhase } from './WorkOrderPhase';

export class WorkOrder extends WorkOrderWithoutIds {
    public _id: string = '';
    public clientIdentity: CompanyIdentity = new CompanyIdentity();
    public locationIdentity: LocationIdentity = new LocationIdentity();
    public createdByIdentity: UserIdentity = new UserIdentity();
    public createdByCompanyIdentity: CompanyIdentity = new CompanyIdentity();
    public phases: WorkOrderPhase[] = [];
    public plIdentity?: CompanyIdentity;

    public isSubcontractor: boolean;

    constructor(params?: Partial<WorkOrder>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.clientIdentity = !!params.clientIdentity
                ? new CompanyIdentity(params.clientIdentity)
                : this.clientIdentity;
            this.createdByCompanyIdentity = !!params.createdByCompanyIdentity
                ? new CompanyIdentity(params.createdByCompanyIdentity)
                : this.createdByCompanyIdentity;
            this.locationIdentity = !!params.locationIdentity
                ? new LocationIdentity(params.locationIdentity)
                : this.locationIdentity;
            this.createdByIdentity = !!params.createdByIdentity
                ? new UserIdentity(params.createdByIdentity)
                : this.createdByIdentity;
            this.phases = !!params.phases
                ? params.phases.map(p => new WorkOrderPhase(p))
                : this.phases;
            this.isSubcontractor = params.isSubcontractor || this.isSubcontractor;
            this.plIdentity = params.plIdentity ?? this.plIdentity;
        }
    }
}
