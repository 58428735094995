import { BaseDoc } from '../base/base-doc';
import { LaborWithoutIds } from './labor';
import { MaterialWithoutIds } from './material';
import { BasePrices } from './base-prices';
import { CompanyServiceWithoutIds } from './company-service';

export class CompanyPriceWithoutIds extends BaseDoc {
    public _id: any;
    public companyId: any;
    public materials: MaterialWithoutIds[] = [];
    public labors: LaborWithoutIds[] = [];
    public services: CompanyServiceWithoutIds[] = [];
    public basePrices: BasePrices = new BasePrices();

    constructor(params?: Partial<CompanyPriceWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.companyId = params.companyId || this.companyId;

            this.materials = Array.isArray(params.materials)
                ? params.materials.map(m => new MaterialWithoutIds(m))
                : this.materials;

            this.labors = Array.isArray(params.labors)
                ? params.labors.map(l => new LaborWithoutIds(l))
                : this.labors;

            this.services = Array.isArray(params.services)
                ? params.services.map(l => new CompanyServiceWithoutIds(l))
                : this.services;

            this.basePrices = !!params.basePrices
                ? new BasePrices(params.basePrices)
                : this.basePrices;
        }
    }
}
