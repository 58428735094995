import { BaseDoc } from '../base/base-doc';
import { CompanyIdentityWithoutIds } from '../company/company-identity';
import { WorkOrderInvoiceStatus } from '../../enums';
import { WorkOrderInvoiceDeclinedWithoutIds } from './work-order-invoice-declined';
import { WorkOrderInvoiceHistoryWithoutIds } from './work-order-invoice-history';
import { WorkOrderInvoiceTotals } from './work-order-invoice-totals';


export class WorkOrderInvoiceWithoutIds extends BaseDoc {
    public _id: any;
    public companyIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public invoiceNumber: string;
    public invoiceStatus: WorkOrderInvoiceStatus;
    public totals: WorkOrderInvoiceTotals = new WorkOrderInvoiceTotals();
    public phaseIds: any[] = [];
    public workOrderNumber: string; // As of 4/20/2022 wo invoice can only have phases from one work order
    public declined: WorkOrderInvoiceDeclinedWithoutIds = new WorkOrderInvoiceDeclinedWithoutIds();
    public history: WorkOrderInvoiceHistoryWithoutIds[] = [];

    constructor(params?: Partial<WorkOrderInvoiceWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.invoiceNumber = params.invoiceNumber || this.invoiceNumber;
            this.invoiceStatus = params.invoiceStatus || this.invoiceStatus;

            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentityWithoutIds(params.companyIdentity)
                : this.companyIdentity;

            this.totals = !!params.totals ? new WorkOrderInvoiceTotals(params.totals) : this.totals;

            this.phaseIds = Array.isArray(params.phaseIds)
                ? params.phaseIds
                : this.phaseIds;

            this.workOrderNumber = params.workOrderNumber || this.workOrderNumber;

            this.declined = !!params.declined
                ? new WorkOrderInvoiceDeclinedWithoutIds(params.declined)
                : this.declined;

            this.history = Array.isArray(params.history)
                ? params.history.map(history => new WorkOrderInvoiceHistoryWithoutIds(history))
                : this.history;
        }
    }
}
