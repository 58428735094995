<div [formGroup]="form">
    <mat-form-field>
        <mat-select
            placeholder="Material Type"
            formControlName="type"
            [compareWith]="compareWith"
            (selectionChange)="expectedCalculation()"
        >
            <mat-option *ngFor="let m of availableMaterials" [value]="m">
                {{ m.name }}
            </mat-option>
        </mat-select>
        <mat-error [rfxError]="getControl('type')"></mat-error>
    </mat-form-field>
    <div class="material-calculation">
        Expected Amount:
        {{
            !!(expectedAmountString$ | async)
                ? ((expectedAmountString$ | async) + ' ' + (measurementString$ | async))
                : 'n/a'
        }}
    </div>
    <mat-form-field>
        <input
            matInput
            [placeholder]="'Material Amount ' + (measurementString$ | async)"
            type="number"
            min="0"
            formControlName="amount"
            (change)="exceededCalculation()"
        />
        <mat-error [rfxError]="getControl('amount')"></mat-error>
    </mat-form-field>
    <ng-container *ngIf="(requireComment$ | async) && getControl('note').enabled">
        <span
            class="material-calculation"
            [class.warning]="!getControl('note').valid"
        >
            {{ warning$ | async }}
        </span>
        <mat-form-field>
            <mat-label>Comment</mat-label>
            <textarea matInput formControlName="note"></textarea>
            <mat-error [rfxError]="getControl('note')"></mat-error>
        </mat-form-field>
    </ng-container>
</div>
