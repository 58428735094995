import { IMappingLike } from './IMappingLike';
import { MappingCreateDto } from './MappingCreateDto';

export class MappingUpdateDto extends MappingCreateDto {
    public id: string;

    constructor(locationId: string, mapping: IMappingLike) {
        super(locationId, mapping);

        this.id = mapping._id;
    }
}
