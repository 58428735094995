export type Status = Status.EStatus;

export namespace Status {
    export const enum EStatus {
        InActive = 0,
        Active = 1
    }

    export const InActive = EStatus.InActive;
    export const Active = EStatus.Active;

    export const members: Status[] = [Status.InActive, Status.Active];

    export function toString(type: Status): string {
        switch (type) {
            case Status.InActive:
                return 'InActive';
            case Status.Active:
                return 'Active';
            default:
                return '';
        }
    }
}
