import { Permission } from '@gm2/common';
import { ChildMenuItem } from './ChildMenuItem';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

export class MenuItem {
    public label: string = '';
    public active: boolean = false;
    public route: string = null;
    public icon: [string, string]  | IconDefinition;
    public permission: Permission | null = null;
    public children?: Array<ChildMenuItem> = [];
    public clickAction?: (arg: unknown) => void;

    constructor(params?: Partial<MenuItem>) {
        if (!!params) {
            this.label = params.label || this.label;
            this.active = params.active || this.active;
            this.route = params.route || this.route;
            this.icon = params.icon ?? this.icon;
            this.permission = params.permission || this.permission;
            this.children = Array.isArray(params.children)
                ? params.children.map(c => new ChildMenuItem(c))
                : this.children;
            this.clickAction = params.clickAction || this.clickAction;
        }
    }
}
