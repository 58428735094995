import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input
} from '@angular/core';

@Component({
    selector: 'rfx-grid-action-bar-tag-control',
    templateUrl: './grid-action-bar-tag-control.component.html',
    styleUrls: ['./grid-action-bar-tag-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridActionBarTagControlComponent implements OnInit {
    @Input()
    public icon: string | [string, string] = ['far', 'tag'];

    constructor() {}

    ngOnInit(): void {}
}
