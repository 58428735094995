import { Injectable } from '@angular/core';
import { Body, Get, HttpService, MapClass, MapValue, Patch, Path, Post, Put, Query } from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { GridParams, PaginatedList } from '@refactor/common';
import { AdminCompanyMaterial, ClientCreateDto, Company, CompanyProfile, CompanyQuestionnaire, CompanySimple } from '../models';
import { QuestionnaireDocumentType } from '../../../';
import { map } from 'rxjs/operators';
import { AutoInvoiceSettings, CompanyServiceArea, CompanyStatus, CompanyType, MaterialActiveIngredient } from '@gm2/common';
import { CompanyInvitesModel } from '@ngx-gm2/shared/models/company-invites.model';

@Injectable({
    providedIn: 'root',
})
export class CompanyService extends HttpService {
    @Get('/company')
    @MapClass(Company)
    public getCompanyOfLoggedInUser(): Observable<Company> {
        return null;
    }

    @Get('/company/:companyId')
    @MapClass(Company)
    public getCompany(@Path('companyId') id: string): Observable<Company> {
        return null;
    }

    @Post('/company/client')
    public createClientCompany(@Body() body: ClientCreateDto): Observable<void> {
        return null;
    }

    @Post('/company/servicepartner')
    public createServicePartnerCompany(@Body() body: ClientCreateDto): Observable<void> {
        return null;
    }

    @Post('/company/listSimple')
    public getCompanyList(@Body() params: GridParams): Observable<PaginatedList<CompanySimple>> {
        return null;
    }

    @Post('/company/listSimpleCsv')
    public getSimpleListCsv(@Body() params: GridParams): Observable<{ url: string }> {
        return null;
    }

    @Patch('/company/:companyId')
    public editAdmin(
        @Path('companyId') id: string,
        @Body() body: CompanyProfile,
    ): Observable<any> {
        return null;
    }

    @Patch('/company/servicePartner/:companyId')
    public editServicePartner(
        @Path('companyId') id: string,
        @Body() body: CompanyProfile,
    ): Observable<any> {
        return null;
    }

    @Patch('/company/client/:companyId')
    public editClient(
        @Path('companyId') id: string,
        @Body() body: CompanyProfile,
    ): Observable<any> {
        return null;
    }

    @Patch('/company/profile')
    public editProfile(
        @Body() body: CompanyProfile,
    ): Observable<any> {
        return null;
    }

    @Patch('/company/servicepartner/settings')
    public editSpSettings(
        @Body() body: {
            invoiceConfirmation: boolean,
            autoInvoice: AutoInvoiceSettings
        },
    ): Observable<any> {
        return null;
    }

    @Put('/servicePartner/serviceAreas')
    public saveServicePartnerServiceAreas(@Body()
                                              body: {
        serviceAreas: CompanyServiceArea[];
    }): Observable<any> {
        return null;
    }

    @Put('/servicePartner/questionnaire')
    public saveServicePartnerQuestionnaire(@Body() body: CompanyQuestionnaire): Observable<any> {
        return null;
    }

    public uploadServicePartnerQuestionnaireFile(
        file: File,
        documentType: QuestionnaireDocumentType,
    ): Observable<any> {
        const data = new FormData();
        data.append('questionnaireDocumentType', documentType);
        data.append('file', file);
        return this._http
            .put<any>('/servicePartner/questionnaireUpload', data)
            .pipe(map(res => res));
    }

    @Get('/company/list/Simple')
    public getSimpleList(@Query('type') type?: CompanyType): Observable<CompanySimple[]> {
        return null;
    }

    @Get('/company/defaultActiveIngredient')
    @MapValue(res => res.data)
    public getDefaultActiveIngredient(): Observable<MaterialActiveIngredient> {
        return null;
    }

    @Patch('/company/defaultActiveIngredient')
    public updateDefaultActiveIngredient(@Body() data: { activeIngredient: MaterialActiveIngredient }): Observable<void> {
        return null;
    }

    @Put('/company')
    public deleteCompaniesByIdList(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }

    @Patch('/company/requireTimeAllocation')
    public toggleRequireTimeAllocation(
        @Body('companyId') companyId: string,
        @Body('requireTimeAllocation') requireTimeAllocation: boolean,
    ): Observable<any> {
        return null;
    }

    @Put('/company/companyStatus')
    public updateCompanyStatusesByIdList(
        @Body('ids') ids: string[],
        @Body('companyStatus') companyStatus: CompanyStatus,
    ): Observable<any> {
        return null;
    }

    @Get('/company/awarded-subcontractors/list')
    public getAwardedSubcontractors(@Query('skipOwnCompany') skipOwnCompany?: boolean): Observable<CompanySimple[]> {
        return null;
    }

    @Get('/company/byName')
    public getCompaniesByName(@Query('name') name: string): Observable<CompanySimple[]> {
        return null;
    }

    @Get('/company/list/byLandscaper')
    public getCompanyListByLandscaper(@Query('landscaperId') landscaperId: string, @Query('type') type?: CompanyType): Observable<CompanySimple[]> {
        return null;
    }

    @Post('/company/create/bulk')
    public importBulk(@Body() data: { companies: any[], landscaperId: string }): Observable<string> {
        return null;
    }

    @Post('/company/check/if-exist')
    public checkByNameAndLandscaper(@Body() data: { name: string, landscaperId: string }): Observable<boolean> {
        return null;
    }

    @Post('/company/check/byName')
    public checkByName(@Body() data: { name: string, companyType: string }): Observable<CompanySimple[]> {
        return null;
    }

    @Post('/company-invite')
    public inviteCompany(@Body() data: { recipientEmail: string, companyId: string }): Observable<CompanyInvitesModel.CompanyInviteDocument[]> {
        return null;
    }
}
