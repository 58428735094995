<h2 mat-dialog-title>Generate Monthly Invoice</h2>
<div mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field *ngIf="data.landscapers?.length">
            <mat-label>Landscaper</mat-label>
            <mat-select required placeholder="Landscaper" formControlName="landscaper">
                <mat-option
                    *ngFor="let landscaper of data.landscapers"
                    [value]="landscaper._id"
                >
                    {{ landscaper.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <mat-label>Type</mat-label>
        <mat-radio-group aria-label="Select" formControlName="type">
            <mat-radio-button [value]="InvoiceMonthlyType.PerRfp">Separate Invoice per Location</mat-radio-button>
            <mat-radio-button [value]="InvoiceMonthlyType.MultipleRfps">One Invoice for all Locations
            </mat-radio-button>
        </mat-radio-group>
        <mat-form-field>
            <mat-select required placeholder="Service Month" formControlName="month">
                <mat-option
                    *ngFor="let o of monthOptions"
                    [value]="o.value"
                >
                    {{ o.label }}
                </mat-option>
            </mat-select>
            <mat-error [rfxError]="form.get('month')"></mat-error>
        </mat-form-field>
        <div class="buttons">
            <button
                mat-flat-button
                (click)="close()"
            >
                Cancel
            </button>
            <button
                mat-flat-button
                color="primary"
                (click)="confirm()"
                [disabled]="!form.valid || !data.landscapers"
            >
                Confirm
            </button>
        </div>
    </form>
</div>

