import { TimesheetServiceMaterialWithoutIds } from './timesheet-service-material';

export class TimesheetServicesWithoutIds {
    public serviceId: any;
    public durationMinutes: number = 0;
    public material: TimesheetServiceMaterialWithoutIds;
    public note: string;
    public name: string;

    constructor(params?: Partial<TimesheetServicesWithoutIds>) {
        if (!!params) {
            this.serviceId = params.serviceId || this.serviceId;
            this.durationMinutes = params.durationMinutes || this.durationMinutes;
            this.note = params.note || this.note;
            this.name = params.name || this.name;

            this.material = !!params.material
                ? (params.material = new TimesheetServiceMaterialWithoutIds(params.material))
                : this.material;
        }
    }
}
