import { BaseDoc } from '../base/base-doc';
import { FormSettings } from './form-settings';
import { FormField } from './form-field';
import { FormMetrics } from './form-metrics';
import { FormPublishStatus } from '../../enums';
import { FormResponseData } from './form-response-data';

export class FormUserDataWithoutIds extends BaseDoc {
    public _id: any;
    public formId: string;
    public userId: string;
    public migrationStatus: number;
    public data: FormResponseData;

    constructor(params?: Partial<FormUserDataWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.data = params.data || this.data;
            this.formId = params.formId || this.formId;
            this.userId = params.userId || this.userId;
            this.migrationStatus = params.migrationStatus || this.migrationStatus;
        }
    }
}
