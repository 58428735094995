import { TimesheetDates } from '../timesheet/timesheet-dates';
import { TimesheetStatus } from '../../enums';

export class ServiceActivityWithoutIds {
    public serviceId: any;
    public servicePartnerId: any;
    public locationId: any;
    public serviceName: string = '';
    public timezone: string = '';
    public dates: TimesheetDates;
    public locationName: string = '';
    public servicePartnerName: string = '';
    public spContactName: string = '';
    public spContactPhone: string = '';
    public timesheetStatus: TimesheetStatus;

    constructor(params?: Partial<ServiceActivityWithoutIds>) {
        if (!!params) {
            this.serviceId = params.serviceId || this.serviceId;
            this.servicePartnerId = params.servicePartnerId || this.servicePartnerId;
            this.locationId = params.locationName || this.locationId;
            this.serviceName = params.serviceName || this.serviceName;
            this.timezone = params.timezone || this.timezone;
            this.dates = !!params.dates ? new TimesheetDates(params.dates) : this.dates;
            this.locationName = params.locationName || this.locationName;
            this.servicePartnerName = params.servicePartnerName || this.servicePartnerName;
            this.spContactName = params.spContactName || this.spContactName;
            this.spContactPhone = params.spContactPhone || this.spContactPhone;
            this.timesheetStatus = params.timesheetStatus || this.timesheetStatus;
        }
    }
}
