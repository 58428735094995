import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IStringFilter, Sort, SortDirection, RfxForm } from '@refactor/common';
import { RfxGridDataSource } from '@refactor/ngx/grid';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, take, finalize } from 'rxjs/operators';
import { FormBuilderService } from '../../services/form-builder.service';
import { FormBuilderModalService } from '../../services/form-builder-modal.service';
import { FormExportDto } from '../../models/form-export.dto';
import { FormBuilderUIUtil } from '../../models/form-builder-ui-util';

interface FormFilters {
    searchTerm: IStringFilter;
}

@Component({
    selector: 'rfx-form-list',
    templateUrl: './form-list.component.html',
    styleUrls: ['./form-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormListComponent implements OnInit {
    public requestingCSV$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public gridSource: RfxGridDataSource<RfxForm, FormFilters>;

    public createUrl$: Observable<string> = this._activatedRoute.data.pipe(
        map(data => data.createUrl)
    );
    public editUrl$: Observable<string> = this._activatedRoute.data.pipe(map(data => data.editUrl));
    public respondUrl$: Observable<string> = this._activatedRoute.data.pipe(
        map(data => data.respondUrl)
    );
    public canCreate$: Observable<boolean> = from(this._formBuilderUIUtil.canCreate());
    public canEdit$: Observable<boolean> = from(this._formBuilderUIUtil.canEdit());
    public canDelete$: Observable<boolean> = from(this._formBuilderUIUtil.canDelete());
    public canExportData$: Observable<boolean> = from(this._formBuilderUIUtil.canExportData());

    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _formBuilderModalService: FormBuilderModalService,
        private readonly _formBuilderService: FormBuilderService,
        private readonly _formBuilderUIUtil: FormBuilderUIUtil,
        private readonly _router: Router,
        private readonly _toastService: ToastrService
    ) {}

    ngOnInit(): void {
        this.gridSource = new RfxGridDataSource<RfxForm, FormFilters>([], {
            queryFn: [this._formBuilderService, 'getFormList'],
            sort: new Sort({
                field: 'name',
                sortOrder: SortDirection.Ascending
            })
        });
    }

    public editItem(item: RfxForm): void {
        this.editUrl$.pipe(take(1)).subscribe(url => {
            this._router.navigateByUrl(`${url}/${item._id}`);
        });
    }

    public respondToForm(item: RfxForm): void {
        this.respondUrl$.pipe(take(1)).subscribe(url => {
            this._router.navigateByUrl(`${url}/${item._id}`);
        });
    }

    public deleteItem(item: RfxForm<string>): void {
        this.deleteItems([item]);
    }

    public deleteItems(items: RfxForm<string>[]): void {
        const ids = items.map(i => i._id);
        this._formBuilderService.deleteForms(ids).subscribe(
            _ => {
                this._toastService.success('Form(s) deleted successfully');
                this.gridSource.updateData();
            },
            err => {
                this._toastService.error(err, 'Unable to delete form(s)');
                this.gridSource.updateData();
            }
        );
    }

    /**
     * Export user response data for a specified form & date range.
     * @param id Form ID to generate csv for
     */
    public exportCSV(item: RfxForm<string>): void {
        this._formBuilderModalService
            .chooseExportDateRange(item)
            .afterClosed()
            .subscribe(res => {
                if (typeof res === 'object') {
                    const dto: FormExportDto = {
                        startDate: !!res ? new Date(res[0]) : null,
                        endDate: !!res ? new Date(res[1]) : null
                    };
                    this.requestingCSV$.next(true);
                    this._formBuilderService
                        .exportFormResponses(item._id, dto)
                        .pipe(finalize(() => this.requestingCSV$.next(false)))
                        .subscribe(
                            csvurl => {
                                window.open(csvurl, '_blank');
                            },
                            err => {
                                this._toastService.error(err);
                            }
                        );
                }
            });
    }
}
