import { ITimesheetCharges } from './interfaces/i-timesheet-charges';
import { SpCharges } from './spCharges/sp-charges';
import { OnegmCharges } from './onegmCharges/onegm-charges';
import { TimesheetWithoutIds, MaterialWithoutIds } from '../../models';
import { AssigneeType } from '../../enums';

export class TimesheetChargesFactory {
    public create(
        timesheet: TimesheetWithoutIds,
        approvedMaterials: MaterialWithoutIds[]
    ): ITimesheetCharges {
        switch (timesheet.snapShot.contractIdentity.assigneeType) {
            case AssigneeType.Onegm:
                return new OnegmCharges(timesheet, approvedMaterials);
            case AssigneeType.ServicePartner:
                return new SpCharges(timesheet, approvedMaterials);
            default:
                throw new Error(
                    `Timesheet Charges factory not implemented for contract assignee type: ${
                        timesheet.snapShot.contractIdentity.assigneeType
                    }`
                );
        }
    }
}
