import { State, Selector, StateContext } from '@ngxs/store';
import {
    Location,
    LocationService,
    LocationImageDto,
    MediaService,
    ToastService,
    MediaGridParams
} from '@gm2/ui-common';
import { ImmutableSelector, ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Receiver, EmitterAction } from '@ngxs-labs/emitter';
import { Observable, EMPTY } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { IDateRangeFilter, PaginatedListModel, PaginationModel, SortDirection } from '@refactor/common';

function dateRangeToFilter(range: undefined): undefined;
function dateRangeToFilter(range: [Date, Date]): IDateRangeFilter;
function dateRangeToFilter(range: [Date, Date] | undefined): IDateRangeFilter | undefined {
    if (range) {
        const timeoffset = 86400000; // 24 hours
        return {
            startDate: range[0],
            // Add 24 hours to endDate so that filter range is inclusive
            endDate: new Date(timeoffset + +range[1])
        };
    }

    return undefined;
}

/**
 * Actions for internal operations
 */
namespace Action {
    export class RefreshImages {
        public static readonly type: string = '[LocationGallery] Refresh Images';
    }
}

export interface ILocationGalleryFilters {
    dateTaken?: [Date, Date] | null;
    dateUploaded?: [Date, Date] | null;
}

interface ILocationGalleryStateModel {
    location: Location | null;
    images: PaginatedListModel<LocationImageDto> | null;
    pagination: PaginationModel;
    filters: ILocationGalleryFilters;
}

@State<ILocationGalleryStateModel>({
    name: 'locationGallery',
    defaults: {
        location: null,
        images: null,
        pagination: {
            page: 1,
            limit: 25
        },
        filters: {}
    }
})

@Injectable()
export class LocationGalleryState {
    private static locationService: LocationService;
    private static mediaService: MediaService;
    private static toast: ToastService;

    constructor(injector: Injector) {
        LocationGalleryState.locationService = injector.get<LocationService>(LocationService);
        LocationGalleryState.mediaService = injector.get<MediaService>(MediaService);
        LocationGalleryState.toast = injector.get<ToastService>(ToastService);
    }

    @Selector()
    @ImmutableSelector()
    public static location(state: ILocationGalleryStateModel): Location {
        return state.location;
    }

    @Selector()
    @ImmutableSelector()
    public static images(
        state: ILocationGalleryStateModel
    ): PaginatedListModel<LocationImageDto> | null {
        return state.images;
    }

    @Selector()
    @ImmutableSelector()
    public static filters(state: ILocationGalleryStateModel): object {
        return state.filters;
    }

    @Selector()
    @ImmutableSelector()
    public static pagination(state: ILocationGalleryStateModel): PaginationModel {
        return state.pagination;
    }

    @Selector()
    @ImmutableSelector()
    public static imagecount(state: ILocationGalleryStateModel): number | null {
        return state.images && state.images.count;
    }

    @Receiver({ action: Action.RefreshImages })
    @ImmutableContext()
    public static refreshImages({
        getState,
        setState
    }: StateContext<ILocationGalleryStateModel>): Observable<any> {
        const state = getState();

        // MediaGridParams are broken and stringify incorrectly
        const imageparams: unknown = {
            sort: {
                field: 'latest.dateTaken',
                sortOrder: SortDirection.Descending
            },
            filters: {
                collectionId: { value: state.location._id },
                dateTaken: dateRangeToFilter(state.filters.dateTaken),
                uploadedDate: dateRangeToFilter(state.filters.dateUploaded)
            },
            pagination: state.pagination
        };

        setState((draft: ILocationGalleryStateModel) => {
            draft.images = null;

            return draft;
        });

        return this.mediaService.getLocationImages(imageparams as MediaGridParams).pipe(
            tap(images =>
                setState((draft: ILocationGalleryStateModel) => {
                    draft.images = images;

                    return draft;
                })
            )
        );
    }

    @Receiver()
    @ImmutableContext()
    public static setFilters(
        { setState, dispatch }: StateContext<ILocationGalleryStateModel>,
        { payload }: EmitterAction<Partial<ILocationGalleryFilters>>
    ): Observable<any> {
        setState((draft: ILocationGalleryStateModel) => {
            Object.assign(draft.filters, payload);

            return draft;
        });

        return dispatch(new Action.RefreshImages());
    }

    @Receiver()
    @ImmutableContext()
    public static setLocationById(
        { setState, dispatch }: StateContext<ILocationGalleryStateModel>,
        { payload }: EmitterAction<string>
    ): Observable<any> {
        return this.locationService.getLocation(payload).pipe(
            tap(location =>
                setState((state: ILocationGalleryStateModel) => {
                    state.location = location;
                    state.pagination.page = 1;
                    state.filters = {};

                    return state;
                })
            ),
            switchMap(_ => dispatch(new Action.RefreshImages()))
        );
    }

    @Receiver()
    @ImmutableContext()
    public static deleteImage(
        { setState }: StateContext<ILocationGalleryStateModel>,
        { payload }: EmitterAction<string>
    ): Observable<any> {
        return this.mediaService.deleteLocationImage(payload).pipe(
            catchError((err, _) => {
                this.toast.error('Could not delete location image', err.error.message);
                return EMPTY;
            }),
            tap(_ => {
                this.toast.success('Location image deleted');

                setState((state: ILocationGalleryStateModel) => {
                    const index = state.images.docs.findIndex(image => image.path === payload);

                    state.images.docs.splice(index, 1);
                    state.images.count -= 1;

                    return state;
                });

                // setState(patch({
                // images: {
                // docs: removeItem(image => image.path === payload),
                // }
                // }));
            })
        );
    }

    @Receiver()
    @ImmutableContext()
    public static setPage(
        { setState, dispatch }: StateContext<ILocationGalleryStateModel>,
        { payload }: EmitterAction<number>
    ): Observable<any> {
        setState((state: ILocationGalleryStateModel) => {
            state.pagination.page = payload;

            return state;
        });

        return dispatch(new Action.RefreshImages());
    }
}
