import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReactiveFormsModule } from '@angular/forms';
import { RfxGridModule } from '@refactor/ngx/grid';
import { RfxFormsModule } from '@refactor/ngx/forms';
import { FormListComponent } from './pages/form-list/form-list.component';
import { FormEditComponent } from './pages/form-edit/form-edit.component';
import { FormRespondComponent } from './pages/form-respond/form-respond.component';
import { ExportDateRangeModalComponent } from './modals/export-date-range-modal/export-date-range-modal.component';
import { FormBuilderConfirmModalComponent } from './modals/form-builder-confirm-modal/form-builder-confirm-modal.component';
import { FormCanvasComponent } from './components/form-canvas/form-canvas.component';
import { FormGeneratorComponent } from './components/form-generator/form-generator.component';
import { FormFieldGeneratorComponent } from './components/form-field-generator/form-field-generator.component';
import { FormFieldSettingsComponent } from './components/form-field-settings/form-field-settings.component';
import { FormFieldTrayComponent } from './components/form-field-tray/form-field-tray.component';
import { AppDefinedFieldTargetComponent } from './components/app-defined-field-target/app-defined-field-target.component';
import { FormBuilderModalService } from './services/form-builder-modal.service';
import { FormBuilderService } from './services/form-builder.service';
import { AppDefinedFieldService } from './services/app-defined-field.service';
import { AppDefinedField } from './models/app-defined-field';
import { FormBuilderUIUtil } from './models/form-builder-ui-util';

@NgModule({
    declarations: [
        // Pages
        FormListComponent,
        FormEditComponent,
        FormRespondComponent,
        // Components
        ExportDateRangeModalComponent,
        FormBuilderConfirmModalComponent,
        FormCanvasComponent,
        FormGeneratorComponent,
        FormFieldGeneratorComponent,
        FormFieldSettingsComponent,
        FormFieldTrayComponent,
        AppDefinedFieldTargetComponent
    ],
    imports: [
        CommonModule,
        DragDropModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatTooltipModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        ReactiveFormsModule,
        RfxFormsModule,
        RfxGridModule,
        RouterModule
    ],
    exports: [
        FormEditComponent,
        FormListComponent,
        FormRespondComponent,
        FormGeneratorComponent
    ],
    providers: [FormBuilderModalService]
})
export class FormBuilderModule {}

export {
    FormBuilderModule as RfxFormBuilderModule,
    FormBuilderService as RfxFormBuilderService,
    FormEditComponent as RfxFormEditComponent,
    FormListComponent as RfxFormListComponent,
    FormRespondComponent as RfxFormRespondComponent,
    FormGeneratorComponent as RfxFormGeneratorComponent,
    AppDefinedFieldService as RfxAppDefinedFieldService,
    AppDefinedField as RfxAppDefinedField,
    FormBuilderUIUtil as RfxFormBuilderUIUtil
};
