import { UserIdentityWithoutIds } from '../user/user-identity';
import { TimesheetValidationStatus } from '../../enums';
import { isNull } from '../../utils';
import { parseISODate } from '@refactor/common';

export class TimesheetValidationHistoryWithoutIds {
    public comment: string;
    public userIdentity: UserIdentityWithoutIds = new UserIdentityWithoutIds();
    public date: Date = new Date();
    public status: TimesheetValidationStatus = TimesheetValidationStatus.Submitted;

    constructor(params?: Partial<TimesheetValidationHistoryWithoutIds>) {
        if (!!params) {
            this.comment = params.comment || this.comment;
            this.userIdentity = !!params.userIdentity
                ? new UserIdentityWithoutIds(params.userIdentity)
                : this.userIdentity;
            this.date = !!params.date ? parseISODate(params.date) : this.date;
            this.status = !isNull(params.status) ? params.status : this.status;
        }
    }
}
