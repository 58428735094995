<h2>Filters</h2>
<hr />
<form [formGroup]="emptyGroup" (ngSubmit)="search()">
    <div class="grid-filter-container">
        <ng-content
            select="
            [rfxGridCustomControl],
            rfx-grid-filter-control,
            rfx-grid-filter-group,
            rfx-grid-filter-search-control,
            rfx-grid-filter-select-control,
            rfx-grid-filter-date-control,
            ng-container,
            ng-template
        "
        ></ng-content>
    </div>
    <div class="actions">
        <button mat-raised-button color="primary">
            Search
        </button>
    </div>
</form>
