export class LocationSettings {
    public canEdit: boolean = false;
    public canDelete: boolean = false;

    constructor(params?: Partial<LocationSettings>) {
        if (!!params) {
            this.canEdit = typeof params.canEdit === 'boolean' ? params.canEdit : this.canEdit;
            this.canDelete =
                typeof params.canDelete === 'boolean' ? params.canDelete : this.canDelete;
        }
    }
}
