import {
    Component,
    OnInit,
    Input,
    ChangeDetectionStrategy,
    Output,
    EventEmitter
} from '@angular/core';
import { Observable } from 'rxjs';
import { Service, ApproveType, CompanyServiceDto } from '@gm2/ui-common';
import { map } from 'rxjs/operators';

@Component({
    selector: 'gm2-services-list',
    templateUrl: './services-list.component.html',
    styleUrls: ['./services-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServicesListComponent implements OnInit {
    // Inputs
    @Input()
    public services$: Observable<CompanyServiceDto[]>;
    @Input()
    public canModify: boolean = false;
    @Input()
    public showCompanyColumn: boolean = false;

    // Outputs
    @Output()
    public approve: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    public disapprove: EventEmitter<string> = new EventEmitter<string>();

    // Enums
    public ApproveType: typeof ApproveType = ApproveType;

    // Variables related to the table
    public displayedColumns: string[] = ['name', 'price', 'description'];
    public pendingServices$: Observable<CompanyServiceDto[]>;
    public approvedServices$: Observable<CompanyServiceDto[]>;
    public disapprovedServices$: Observable<CompanyServiceDto[]>;

    constructor() {}

    ngOnInit(): void {
        // Only show company column if showCompanyColumn is true
        if (this.showCompanyColumn) {
            this.displayedColumns.push('company');
        }
        // Only show actions column if canModify is true
        if (this.canModify) {
            this.displayedColumns.push('actions');
        }

        // this.services$.subscribe(res => {
        //     console.log(res);
        // });

        // Wait for input to get value before populating pending/approved/disapproved
        this.pendingServices$ = this.services$.pipe(
            map(services =>
                services.filter(service => service.approveStatus === ApproveType.ApprovalRequested)
            )
        );
        this.approvedServices$ = this.services$.pipe(
            map(services =>
                services.filter(service => service.approveStatus === ApproveType.Approved)
            )
        );
        this.disapprovedServices$ = this.services$.pipe(
            map(services =>
                services.filter(service => service.approveStatus === ApproveType.Disapproved)
            )
        );
    }
}
