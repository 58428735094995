import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

interface DtoError {
    target: { key: any };
    value: any;
    property: string;
    constraints: { key: string };
    children: DtoError[];
}

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private _toast: ToastrService) {}

    public success(title: string, message?: string): void {
        this._toast.success(message, title);
    }

    public info(title: string, message?: string): void {
        this._toast.info(message, title);
    }

    public warn(title: string, message?: string): void {
        this._toast.warning(message, title);
    }

    public error(error: HttpErrorResponse): void;
    public error(title: string, message?: string): void;
    public error(title: string, error: HttpErrorResponse): void;
    public error(
        errorOrTitle: string | HttpErrorResponse,
        errorOrMessage?: string | HttpErrorResponse
    ): void {
        const title = typeof errorOrTitle === 'string'
            ? errorOrTitle
            : this.extractErrorMessages(errorOrTitle);

        let message = typeof errorOrMessage === 'string'
            ? errorOrMessage
            : this.extractErrorMessages(errorOrMessage);

        if (!title && !message) {
            let error;
            if (typeof errorOrTitle['error'] === 'string') {
                error = this.parseJsonString(errorOrTitle['error']);
            } else {
                error = errorOrTitle['error'];
            }

            if (error?.message) {
                message = error.message;
            } else if (typeof error?.error === 'string') {
                message = error.error;
            } else {
                console.warn('Unable to parse HttpErrorResponse', errorOrTitle);
                throw new Error('Unable to parse HttpErrorResponse Error');
            }
        }

        this._toast.error(message, title, { enableHtml: true });
    }

    // For getting error strings from errors returned by server dtos
    private extractErrorMessages(httpErr: HttpErrorResponse): string {
        if (!httpErr) {
            return undefined;
        }

        let errors = [];

        // Some reason the error property is sometimes a string, parse it if necessary
        const errorObject =
            typeof httpErr?.error === 'string' ? this.parseJsonString(httpErr?.error) : httpErr.error;

        if (typeof errorObject?.message === 'string') {
            return errorObject.message;
        }
        for (const m of errorObject.message) {
            errors = this.extractErrorsFromErrorItem(m, errors);
        }

        const allErrs = errors.join('</br>');
        console.log('All errors: ', allErrs);
        return allErrs;
    }

    private extractErrorsFromErrorItem(e: DtoError, errors: string[]): string[] {
        for (const key in e.constraints) {
            errors.push(e.constraints[key]);
        }
        for (const c of e.children) {
            errors = this.extractErrorsFromErrorItem(c, errors);
        }
        return errors;
    }

    private parseJsonString(string: string): Record<string, any> {
        try {
            return JSON.parse(string);
        } catch (er) {
            return null;
        }
    }
}
