import { Injectable } from '@angular/core';
import {
    HttpService,
    Post,
    Get,
    Put,
    Patch,
    MapClass,
    MapValue,
    ResponseType,
    Path,
    Query,
    Body
} from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { PaginatedList, GridParams } from '@refactor/common';
import {
    WorkOrderInvoice,
    WorkOrderPhaseWithWorkOrderDetails,
    IWorkOrderInvoiceRejectDto
} from '../models';
import { WorkOrderInvoiceStatus } from '@gm2/common';

interface InvoiceCreateDto {
    invoiceNumber: string;
}

interface InvoiceSaveDto {
    id: string;
    invoiceNumber: string;
    phaseIds: string[];
}

@Injectable({
    providedIn: 'root'
})
export class WorkorderInvoiceService extends HttpService {
    @Get('/workOrderInvoice/:id')
    @MapClass(WorkOrderInvoice)
    public getInvoice(@Path('id') id: string): Observable<WorkOrderInvoice> {
        return null;
    }

    @Get('/workOrderInvoice/:id/phases')
    @MapClass(WorkOrderPhaseWithWorkOrderDetails)
    public getInvoicePhases(
        @Path('id') id: string
    ): Observable<WorkOrderPhaseWithWorkOrderDetails[]> {
        return null;
    }

    @Get('/workOrderInvoice/:id/export', { responseType: ResponseType.Text })
    public exportInvoicePhases(@Path('id') id: string): Observable<string> {
        return null;
    }

    @Post('/workOrderInvoice/csvExport', { responseType: ResponseType.Text })
    public exportInvoices(@Body() params: GridParams): Observable<string> {
        return null;
    }

    @Get('/workOrderInvoice/phases/available')
    @MapClass(WorkOrderPhaseWithWorkOrderDetails)
    public getAvailablePhases(
        @Query('startDate') startDate?: Date,
        @Query('endDate') endDate?: Date
    ): Observable<WorkOrderPhaseWithWorkOrderDetails[]> {
        return null;
    }

    @Post('/workOrderInvoice/create')
    public createInvoice(@Body() dto: InvoiceCreateDto): Observable<string> {
        return null;
    }

    @Put('/workOrderInvoice/save')
    public saveInvoice(@Body() dto: InvoiceSaveDto): Observable<void> {
        return null;
    }

    @Patch('/workOrderInvoice/:id/submit')
    public submitInvoice(@Path('id') id: string): Observable<void> {
        return null;
    }

    @Post('/workOrderInvoice/list')
    @MapValue(
        res =>
            new PaginatedList<WorkOrderInvoice>({
                count: res.count,
                docs: res.docs.map(d => new WorkOrderInvoice(d))
            })
    )
    public getInvoiceList(@Body() params: GridParams): Observable<PaginatedList<WorkOrderInvoice>> {
        return null;
    }

    @Put('/workOrderInvoice/delete')
    public deleteInvoices(@Body('ids') ids: string[]): Observable<void> {
        return null;
    }

    @Post('/workOrderInvoice/:id/approvalstatus')
    public updateApprovalStatus(
        @Path('id') id: string,
        @Body('status') status: WorkOrderInvoiceStatus
    ): Observable<void> {
        return null;
    }

    @Post('/workOrderInvoice/:id/reject')
    public rejectInvoice(
        @Path('id') id: string,
        @Body() dto: IWorkOrderInvoiceRejectDto
    ): Observable<void> {
        return null;
    }
}
