<div class="controls">
    <span class="controls__counter">
        <mat-checkbox
            [checked]="true"
            color="primary"
            disabled="true"
            (click)="dismiss()"
        >
            {{ itemCount }} selected
        </mat-checkbox>
    </span>
    <div class="controls__container">
        <ng-content
            select="
            [rfxGridCustomControl],
            rfx-grid-action-bar-control,
            rfx-grid-action-bar-delete-control,
            rfx-grid-action-bar-tag-control
        "
        ></ng-content>
    </div>
</div>
<div class="dismiss" (click)="dismiss()">
    <span class="dismiss__container">
        <fa-icon class="dismiss__icon" [icon]="icon"></fa-icon>
        Dismiss
    </span>
</div>
