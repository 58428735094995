import { IBillingTypeChargeStrategy } from '../interfaces/i-billing-type-charge-strategy';
import { getClientPricingService, calculateClientMaterialsTotal } from '../util/methods';
import {
    BidWithoutIds,
    BidServiceWithoutIds,
    RfpServiceWithoutIds,
    TimesheetWithoutIds,
    MaterialWithoutIds,
    TimesheetServicesWithoutIds,
    ServiceWithoutIds,
    ServiceSettings
} from '@gm2/common';

export abstract class SpAbstractNonMonthlyCharges implements IBillingTypeChargeStrategy {
    //#region Abstract Methods
    public abstract calculateServiceDuration(
        duration: number,
        awardedBid: BidWithoutIds,
        awardedBidService: BidServiceWithoutIds
    ): number;

    public abstract calculateServiceTotal(
        duration: number,
        awardedBid: BidWithoutIds,
        awardedBidService: BidServiceWithoutIds
    ): number;

    public abstract calculateServiceClientTotal(
        duration: number,
        clientPricingService: RfpServiceWithoutIds,
        serviceDurationDict: { [serviceId: string]: number }
    ): { total: number; duration: number };

    public abstract adjustClientDurations(
        companyTotalDuration: number,
        serviceDurationDict: {
            [serviceId: string]: number;
        }
    ): { serviceDurationDict: { [serviceId: string]: number }; totalDuration: number };
    //#endregion

    //#region IBillingTypeChargeStrategy Methods

    public calculateCompanyCharges(
        timesheet: TimesheetWithoutIds,
        approvedMaterialDict: { [key: string]: MaterialWithoutIds }
    ): { total: number; duration: number; errors: string[] } {
        const serviceDurationDict: {
            [serviceId: string]: number;
        } = this.getTotalDurationByService(timesheet);

        const serviceTotalAndDuration = this.calculateServicesTotalAndDuration(
            timesheet,
            serviceDurationDict
        );

        const materialTotal = this.calculateMaterialsTotal(timesheet, approvedMaterialDict);

        const serviceAndMaterialTotal = serviceTotalAndDuration.total + materialTotal;

        return {
            duration: serviceTotalAndDuration.duration,
            total: serviceAndMaterialTotal,
            errors: serviceTotalAndDuration.errors
        };
    }

    public calculateClientCharges(
        timesheet: TimesheetWithoutIds,
        companyTotalDuration: number
    ): { total: number; duration: number; errors: string[] } {
        if (!timesheet.snapShot.rfp.clientPricing) {
            return {
                duration: 0,
                total: 0,
                errors: []
            };
        }

        let serviceDurationDict: {
            [serviceId: string]: number;
        } = this.getTotalDurationByService(timesheet);

        const adjustClientDurations = this.adjustClientDurations(
            companyTotalDuration,
            serviceDurationDict
        );
        serviceDurationDict = adjustClientDurations.serviceDurationDict;

        let clientTotal: number = 0;
        let durationTotal: number = adjustClientDurations.totalDuration;
        const errors: string[] = [];

        for (const serviceId in serviceDurationDict) {
            // find the clientPricing service based on if the package parent or child service is in control
            const clientPricingService = getClientPricingService(
                timesheet.snapShot.rfp.clientPricing.services,
                serviceId
            );

            if (!clientPricingService) {
                errors.push(`No Client Price data for serviceId - ${serviceId}`);
                continue;
            }

            const currentServiceDuration = serviceDurationDict[serviceId];

            const serviceClientTotalAndDuration = this.calculateServiceClientTotal(
                currentServiceDuration,
                clientPricingService,
                serviceDurationDict
            );

            clientTotal += serviceClientTotalAndDuration.total;
        }

        const materialTotal = calculateClientMaterialsTotal(timesheet);

        return {
            duration: durationTotal,
            total: clientTotal + materialTotal,
            errors: errors
        };
    }

    //#endregion

    //#region Helper Methods
    public calculateServicesTotalAndDuration(
        timesheet: TimesheetWithoutIds,
        serviceDurationDict: {
            [serviceId: string]: number;
        }
    ): {
        duration: number;
        total: number;
        errors: string[];
    } {
        let timesheetDuration: number = 0;
        let timesheetTotal: number = 0;
        const errors: string[] = [];
        const awardedBid = timesheet.snapShot.rfp.awardedBid.bid;

        for (const serviceId in serviceDurationDict) {
            // find the bid service based on if the package parent or child service is in control
            const awardedBidService = this.getAwardedBidService(awardedBid.services, serviceId);

            if (!awardedBidService) {
                errors.push(`No Price data for serviceId - ${serviceId}`);
                continue;
            }

            const currentServiceDuration = serviceDurationDict[serviceId];
            const calculatedServiceDuration = this.calculateServiceDuration(
                currentServiceDuration,
                awardedBid,
                awardedBidService
            );

            timesheetDuration += calculatedServiceDuration;
            timesheetTotal += this.calculateServiceTotal(
                calculatedServiceDuration,
                awardedBid,
                awardedBidService
            );
        }

        return {
            duration: timesheetDuration,
            total: timesheetTotal,
            errors: errors
        };
    }

    public getTotalDurationByService(
        timesheet: TimesheetWithoutIds
    ): { [serviceId: string]: number } {
        const serviceDurationDict: { [serviceId: string]: number } = {};
        const packageServices = timesheet.snapShot.rfp.package.services;
        for (const service of timesheet.services) {
            const serviceResult = this.determineServiceAndDuration(service, packageServices);

            if (!serviceDurationDict.hasOwnProperty(serviceResult.serviceId)) {
                serviceDurationDict[serviceResult.serviceId] = 0;
            }

            serviceDurationDict[serviceResult.serviceId] += serviceResult.duration;
        }

        return serviceDurationDict;
    }

    public calculateMaterialsTotal(
        timesheet: TimesheetWithoutIds,
        approvedMaterialDict: { [key: string]: MaterialWithoutIds }
    ): number {
        let total = 0;

        for (const service of timesheet.services) {
            if (
                service.hasOwnProperty('material') &&
                !!service.material &&
                service.material.hasOwnProperty('snapShot') &&
                !!service.material.snapShot &&
                service.material.snapShot.hasOwnProperty('material') &&
                !!service.material.snapShot.material&&
                approvedMaterialDict.hasOwnProperty(
                    service.material.snapShot.material._id.toString()
                )
            ) {
                total +=
                    service.material.quantity * (service.material.snapShot.material.price || 0);
            }
        }

        return total;
    }

    private determineServiceAndDuration(
        timeSheetService: TimesheetServicesWithoutIds,
        packageServices: ServiceWithoutIds[]
    ): { serviceId: string; duration: number } {
        const packageServiceResult = this.getPackageService(
            packageServices,
            timeSheetService.serviceId.toString()
        );

        // we are either dealing with parent or child serviceId based on settings
        const serviceId = packageServiceResult.settings.parentControl
            ? packageServiceResult.parentServiceId
            : packageServiceResult.childServiceId;

        // if the parentControl is true, then we dont want to add the serviceDuration to the total duration.
        const result = {
            serviceId,
            duration: timeSheetService.durationMinutes
        };

        return result;
    }

    private getPackageService(
        packageServices: ServiceWithoutIds[],
        serviceId: string
    ): { settings: ServiceSettings; parentServiceId: string; childServiceId: string } {
        for (const parentService of packageServices) {
            const parentServiceId = parentService._id.toString();
            if (parentServiceId === serviceId) {
                return {
                    settings: parentService.settings,
                    parentServiceId: parentServiceId,
                    childServiceId: parentServiceId
                };
            }
            for (const childService of parentService.children) {
                const childServiceId = childService._id.toString();
                if (childServiceId === serviceId) {
                    return {
                        settings: parentService.settings,
                        parentServiceId: parentServiceId,
                        childServiceId: childServiceId
                    };
                }
            }
        }
    }

    private getAwardedBidService(
        bidServices: BidServiceWithoutIds[],
        serviceId: string
    ): BidServiceWithoutIds {
        for (const bidService of bidServices) {
            if (bidService.serviceId.toString() === serviceId) {
                return bidService;
            }
        }
        return null;
    }

    //#endregion
}
