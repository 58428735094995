import { WorkOrderPhaseWithWorkOrderDetailsWithoutIds } from '@gm2/common';
import { WorkOrderDetails } from './WorkOrderDetails';
import { WorkOrderEquipment } from './WorkOrderEquipment';
import { WorkOrderLabor } from './WorkOrderLabor';
import { WorkOrderMaterial } from './WorkOrderMaterial';

export class WorkOrderPhaseWithWorkOrderDetails extends WorkOrderPhaseWithWorkOrderDetailsWithoutIds {
	public _id: string = '';
    public material: WorkOrderMaterial[] = [];
    public equipment: WorkOrderEquipment[] = [];
    public labor: WorkOrderLabor[] = [];
    public workOrderDetails: WorkOrderDetails;

    constructor(params?: Partial<WorkOrderPhaseWithWorkOrderDetails>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.material = !!params.material
                ? params.material.map(m => new WorkOrderMaterial(m))
                : this.material;
            this.equipment = !!params.equipment
                ? params.equipment.map(e => new WorkOrderEquipment(e))
                : this.equipment;
            this.labor = !!params.labor
                ? params.labor.map(l => new WorkOrderLabor(l))
                : this.labor;
            this.workOrderDetails = !!params.workOrderDetails
                ? new WorkOrderDetails(params.workOrderDetails)
                : this.workOrderDetails;
        }
    }
}