import { MappingType, MappingClassification } from '../../enums';
import { Status } from '../../enums';
import { isNull } from '../../utils';

export class MappingCategoryWithoutIds {
    public _id: any;
    public status: Status = Status.Active;
    public name: string;
    public color: string;
    public type: MappingType;
    public classification: MappingClassification = MappingClassification.Other;
    public migrationKey: string;

    constructor(params?: Partial<MappingCategoryWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.status = !isNull(params.status) ? params.status : this.status;
            this.name = params.name || this.name;
            this.color = params.color || this.color;
            this.type = params.type || this.type;
            this.classification = params.classification || this.classification;
            this.migrationKey = params.migrationKey || this.migrationKey;
        }
    }
}
