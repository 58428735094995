import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'gm2-confirm-delete-modal',
    templateUrl: './confirm-duplicate-location-modal.component.html',
    styleUrls: ['./confirm-duplicate-location-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDuplicateLocationModalComponent implements OnInit {
    public readonly locationName: string;
    public readonly address: string;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDuplicateLocationModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        data: {
            error?: HttpErrorResponse;
        }
    ) {
        const splitMessage = data.error.error.message.split(': ');
        this.locationName = splitMessage[0];
        this.address = splitMessage[1];
    }

    ngOnInit(): void {}

    public close(confirmed: boolean): void {
        this.dialogRef.close(confirmed);
    }
}
