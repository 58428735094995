<mat-tab-group>
    <mat-tab label="Pending">
        <ng-container *ngIf="(pendingMaterials$ | async)?.length > 0; else noPendingMaterials">
            <table mat-table [dataSource]="pendingMaterials$ | async" class="mat-elevation-z1">
                <!-- Name Column -->
                <mat-text-column name="name"></mat-text-column>

                <!-- Price Column -->
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>Price</th>
                    <td mat-cell *matCellDef="let element">{{ element.price | currency }}</td>
                </ng-container>

                <!-- Materials Type Column -->
                <ng-container matColumnDef="activeIngredient">
                    <th mat-header-cell *matHeaderCellDef>Materials Type</th>
                    <td mat-cell *matCellDef="let element">
                        {{
                            element.activeIngredient
                                ? MaterialActiveIngredient.toString(element.activeIngredient)
                                : 'None'
                        }}
                    </td>
                </ng-container>

                <!-- Description Column -->
                <mat-text-column name="description"></mat-text-column>

                <!-- Company Column -->
                <mat-text-column name="company"></mat-text-column>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="align-right">Actions</th>
                    <td mat-cell *matCellDef="let element" class="align-right">
                        <button mat-icon-button color="primary" aria-label="Approve material">
                            <mat-icon>thumb_up</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" aria-label="Disapprove material">
                            <mat-icon>thumb_down</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </ng-container>
        <ng-template #noPendingMaterials>
            <p>There are no pending materials.</p>
        </ng-template>
    </mat-tab>
    <mat-tab label="Approved">
        <ng-container *ngIf="(approvedMaterials$ | async)?.length > 0; else noApprovedMaterials">
            <table mat-table [dataSource]="approvedMaterials$ | async" class="mat-elevation-z1">
                <!-- Name Column -->
                <mat-text-column name="name"></mat-text-column>

                <!-- Price Column -->
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>Price</th>
                    <td mat-cell *matCellDef="let element">{{ element.price | currency }}</td>
                </ng-container>

                <!-- Materials Type Column -->
                <ng-container matColumnDef="activeIngredient">
                    <th mat-header-cell *matHeaderCellDef>Materials Type</th>
                    <td mat-cell *matCellDef="let element">
                        {{
                            element.activeIngredient
                                ? MaterialActiveIngredient.toString(element.activeIngredient)
                                : 'None'
                        }}
                    </td>
                </ng-container>

                <!-- Description Column -->
                <mat-text-column name="description"></mat-text-column>

                <!-- Company Column -->
                <mat-text-column name="company"></mat-text-column>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </ng-container>
        <ng-template #noApprovedMaterials>
            <p>There are no approved materials.</p>
        </ng-template>
    </mat-tab>
    <mat-tab label="Disapproved">
        <ng-container
            *ngIf="(disapprovedMaterials$ | async)?.length > 0; else noDisapprovedMaterials"
        >
            <table mat-table [dataSource]="disapprovedMaterials$ | async" class="mat-elevation-z1">
                <!-- Name Column -->
                <mat-text-column name="name"></mat-text-column>

                <!-- Price Column -->
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>Price</th>
                    <td mat-cell *matCellDef="let element">{{ element.price | currency }}</td>
                </ng-container>

                <!-- Materials Type Column -->
                <ng-container matColumnDef="activeIngredient">
                    <th mat-header-cell *matHeaderCellDef>Materials Type</th>
                    <td mat-cell *matCellDef="let element">
                        {{
                            element.activeIngredient
                                ? MaterialActiveIngredient.toString(element.activeIngredient)
                                : 'None'
                        }}
                    </td>
                </ng-container>

                <!-- Description Column -->
                <mat-text-column name="description"></mat-text-column>

                <!-- Company Column -->
                <mat-text-column name="company"></mat-text-column>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </ng-container>
        <ng-template #noDisapprovedMaterials>
            <p>There are no disapproved materials.</p>
        </ng-template>
    </mat-tab>
</mat-tab-group>
