export class UserCustomFields {
    public employeeNumber: string = '';
    public employeeRole: string = '';

    constructor(params?: Partial<UserCustomFields>) {
        if (!!params) {
            this.employeeNumber = params.employeeNumber || this.employeeNumber;
            this.employeeRole = params.employeeRole || this.employeeRole;
        }
    }
}
