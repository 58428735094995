import { TimesheetMaterialSnapShotWithoutIds } from './timesheet-material-snap-shot';

export class TimesheetServiceMaterialWithoutIds {
    public quantity: number = 0;
    public snapShot: TimesheetMaterialSnapShotWithoutIds = new TimesheetMaterialSnapShotWithoutIds();

    constructor(params?: Partial<TimesheetServiceMaterialWithoutIds>) {
        if (!!params) {
            this.quantity = params.quantity || this.quantity;
            this.snapShot = !!params.snapShot
                ? new TimesheetMaterialSnapShotWithoutIds(params.snapShot)
                : this.snapShot;
        }
    }
}
