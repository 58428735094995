import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
    selector: 'gm2-company-questionnaire-modal',
    templateUrl: './company-questionnaire-modal.component.html',
    styleUrls: ['./company-questionnaire-modal.component.scss']
})
export class CompanyQuestionnaireModalComponent implements OnInit {
    public readonly answers: Observable<any>;

    constructor(
        private readonly dialogRef: MatDialogRef<CompanyQuestionnaireModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        data: {
            answers: Observable<any>;
        }
    ) {
        this.answers = data.answers;
    }

    ngOnInit(): void {}

    public onCancel(): void {
        this.dialogRef.close(null);
    }
}
