import { WorkOrderWorkCategory, WorkOrderPriority } from '@gm2/common';
import { WorkOrderPhaseDto } from './WorkOrderPhaseDto';

export class WorkOrderServicePartnerDto {
    public locationId: string;
    public safety: boolean;
    public workCategory: WorkOrderWorkCategory;
    public priority: WorkOrderPriority;
    public projectDescription: string;
    public phases: WorkOrderPhaseDto[];

    constructor(params?: WorkOrderServicePartnerDto) {
        this.locationId = params.locationId;
        this.safety = params.safety;
        this.workCategory = params.workCategory;
        this.priority = params.priority;
        this.projectDescription = params.projectDescription;
        this.phases = Array.isArray(params.phases)
            ? params.phases.map(p => new WorkOrderPhaseDto(p))
            : this.phases;
    }
}
