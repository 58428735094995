export enum LocationServiceInterval {
    Weekly = '[LocationServiceInterval] Weekly',
    Monthly = '[LocationServiceInterval] Monthly',
    SemiMonthly = '[LocationServiceInterval] Semi-Monthly',
}

export const locationServiceIntervalMap: { [interval: string]: LocationServiceInterval } = {
    'Weekly': LocationServiceInterval.Weekly,
    'Monthly': LocationServiceInterval.Monthly,
    'Semi-Monthly': LocationServiceInterval.SemiMonthly,
};

export namespace LocationServiceInterval {
    export function toString(type: LocationServiceInterval): string {
        switch (type) {
            case LocationServiceInterval.Weekly:
                return 'Weekly';
            case LocationServiceInterval.Monthly:
                return 'Monthly';
            case LocationServiceInterval.SemiMonthly:
                return 'Semi-Monthly';
            default:
                return '';
        }
    }

    export const members: LocationServiceInterval[] = [
        LocationServiceInterval.Weekly,
        LocationServiceInterval.Monthly,
        LocationServiceInterval.SemiMonthly,
    ];
}
