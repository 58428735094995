import { addMonths } from 'date-fns';
import { InvoiceStatus } from '../../enums';

export function canEditInvoice(invoicestatus: InvoiceStatus): boolean {
    return (
        invoicestatus === InvoiceStatus.InProgress ||
        invoicestatus === InvoiceStatus.Submitted ||
        invoicestatus === InvoiceStatus.Rejected
    );
}

export function canDeleteInvoice(invoicestatus: InvoiceStatus): boolean {
    // the logic is the same for determining if you can edit or delete
    return canEditInvoice(invoicestatus);
}

export function getInvoiceAvailableTimesheetMaxStartDate(): Date {
    const maxNumberOfMonthsInPast = 4;
    return addMonths(new Date(), -maxNumberOfMonthsInPast);
}
