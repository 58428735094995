export enum CompanyProfileType {
    Client = '[CompanyProfileType] Client',
    Service_Partner = '[CompanyProfileType] Service Partner'
}

export namespace CompanyProfileType {
    export function toString(type: CompanyProfileType): string {
        switch (type) {
            case CompanyProfileType.Client:
                return 'Client'
            case CompanyProfileType.Service_Partner:
                return 'Service Partner';
            default:
                return '';
        }
    }


    export const members: CompanyProfileType[] = [
        CompanyProfileType.Client,
        CompanyProfileType.Service_Partner
    ];
}
