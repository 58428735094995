import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input
} from '@angular/core';
import { GridFilterControlComponent } from '../grid-filter-control/grid-filter-control.component';
import { RfxGridComponent } from '../grid.module';

@Component({
    selector: 'rfx-grid-filter-clear-button',
    templateUrl: './grid-filter-clear-button.component.html',
    styleUrls: ['./grid-filter-clear-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: GridFilterControlComponent,
            useExisting: GridFilterClearButtonComponent
        }
    ]
})
export class GridFilterClearButtonComponent<T> implements OnInit {
    @Input()
    public rfxGrid: RfxGridComponent<T>;

    @Input()
    public ignore: string[] = [];

    constructor() {}

    ngOnInit(): void {}

    public clearFilters(): void {
        const controls = this.rfxGrid._gridFilterChildNonStatic.gridControls;

        controls.forEach(control => {
            if (!!control.controlGroup && !this.ignore.includes(control.name)) {
                control.controlGroup.reset();
            }
        });

        // "Auto-search" after clearing filters
        this.rfxGrid.dataSource.updateData();
    }
}
