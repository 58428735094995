import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Company, CompanySimple, CompanyType } from '@gm2/ui-common';
import { url } from '../../consts/url.const';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyServiceNew {
    constructor(private http: HttpClient) {
    }


    public getByFilters(filters: { [key: string]: string }): Observable<Company[]> {
        return this.http.post<Company[]>(`${url.company.list}`, filters);
    }

    public getListByTypes(types?: CompanyType[]): Observable<CompanySimple[]> {
        return this.http.post<Company[]>(`${url.company.listByTypes}`, { types });
    }

    public updateGeospatialServiceStatus(companyId: string, geospatialServiceStatus: boolean): Observable<CompanySimple[]> {
        return this.http.post<Company[]>(`${url.company.updateGeospatialServiceStatus(companyId)}`, { geospatialService: geospatialServiceStatus });
    }
}
