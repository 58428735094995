import { ServiceTypeWithoutIds } from '@gm2/common';
import { MappingCategory } from '../mapping/MappingCategory';

export class ServiceType extends ServiceTypeWithoutIds {
    public _id: string = '';
    public mappingCategories: MappingCategory[];
    public companyId?: any;

    constructor(params?: Partial<ServiceType>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.mappingCategories = Array.isArray(params.mappingCategories)
            ? params.mappingCategories.map(x => new MappingCategory(x))
            : this.mappingCategories;
            this.companyId = params.companyId || this.companyId;
        }
    }
}
