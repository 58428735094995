import { BaseDoc } from '../base/base-doc';

export class FormMetrics {
    public lastCompletedOn: Date;
    public completeCount: number;

    constructor(params?: Partial<FormMetrics>) {
        if (!!params) {
            this.lastCompletedOn = params.lastCompletedOn || this.lastCompletedOn;
            this.completeCount = params.completeCount || this.completeCount;
        }
    }
}
