import { ClientCompantServiceWithoutIds } from '../company/client-company-service';
import { PackageSnapShotWithoutIds } from '../package/package-snap-shot';

export class ContractSnapShotWithoutIds {
    public packages: PackageSnapShotWithoutIds[] = [];
    public companyServices: ClientCompantServiceWithoutIds[] = [];

    constructor(params?: Partial<ContractSnapShotWithoutIds>) {
        if (!!params) {
            this.packages = Array.isArray(params.packages)
                ? params.packages.map(p => new PackageSnapShotWithoutIds(p))
                : this.packages;
            
            this.companyServices = Array.isArray(params.companyServices)
                ? params.companyServices.map(p => new ClientCompantServiceWithoutIds(p))
                : this.companyServices;
        }
    }
}
