export enum LocationMappingApproveType {
    AdminApprove = '[LocationMappingApproveType] Admin Approve',
    ClientApprove = '[LocationMappingApproveType] Client Approve',
    ApprovalReset = '[LocationMappingApproveType] Approval Reset',
}

export namespace LocationMappingApproveType {
    export function toString(type: LocationMappingApproveType): string {
        switch (type) {
            case LocationMappingApproveType.AdminApprove:
                return 'Admin Approve';
            case LocationMappingApproveType.ClientApprove:
                return 'Client Approve';
            case LocationMappingApproveType.ApprovalReset:
                return 'Approval Reset';
            default:
                return '';
        }
    }

    export const members: LocationMappingApproveType[] = [
        LocationMappingApproveType.AdminApprove,
        LocationMappingApproveType.ClientApprove,
        LocationMappingApproveType.ApprovalReset
    ];
}
