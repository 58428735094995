import { ContractTemplateWithoutIds } from '@gm2/common';

export class ContractTemplate extends ContractTemplateWithoutIds {
    public _id: string = '';
    public packageIds: string[] = [];
    public serviceTypeId: string = '';

    constructor(params?: Partial<ContractTemplate>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId;
            this.packageIds = Array.isArray(params.packageIds)
                ? params.packageIds
                : this.packageIds;
        }
    }
}
