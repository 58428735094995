import { Directive, ElementRef, Input, Optional, Inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
    RfxErrorMessages,
    RFX_FORMS_ERROR_CONFIG,
    rfxErrorDefaults,
    RfxErrorConfig,
    RfxErrorConfigToken
} from '../models/RfxErrorMessages';

/**
 * Error Message Directive
 *
 * Used to dynamically apply an error messaged based on errors present
 * in the FormControl object
 */
@Directive({
    selector: '[rfxError]'
})
export class ErrorMessageDirective {
    /**
     * @ignore
     */
    private _control: AbstractControl;
    /**
     * Sets the associated control, throws error if invalid value is given
     */
    @Input('rfxError')
    public set control(value: AbstractControl) {
        if (value instanceof AbstractControl) {
            this._control = value;
        } else {
            throw new Error(
                `@rfx/ngx-forms invalid control provided to 'rfxError', ${JSON.stringify(value)}`
            );
        }
    }
    /**
     * Returns the current control instance
     */
    public get control(): AbstractControl {
        return this._control;
    }

    /**
     * @ignore
     */
    private _customMessages: Partial<RfxErrorMessages> = {};
    /**
     * Sets any custom messages used to override specific error messages
     * for this single instance
     */
    @Input('rfxErrorMessages')
    public set customMessages(value: Partial<RfxErrorMessages>) {
        this._customMessages = value;
        this._messages = this._buildMessages(this._customMessages);
    }
    /**
     * Returns the current custom messages
     */
    public get customMessages(): Partial<RfxErrorMessages> {
        return this._customMessages;
    }

    /**
     * @ignore
     */
    private _showMultipleErrors: boolean = false;
    /**
     * Toggle displaying a single error message, or a list of all error messages
     */
    @Input('rfxErrorMulti')
    public set showMultipleErrors(value: boolean) {
        this._showMultipleErrors = typeof value === 'boolean' ? value : false;
    }
    /**
     * Returns the current toggle status
     */
    public get showMultipleErrors(): boolean {
        return this._showMultipleErrors;
    }

    /**
     * @ignore
     *
     * Stores the reference to the host element
     */
    public _el: ElementRef;
    /**
     * @ignore
     *
     * Stores the current messages including any local overrides
     */
    private _messages: RfxErrorMessages;
    /**
     * @ignore
     *
     * Stores the base messages from the intial config
     */
    private _baseMessages: RfxErrorMessages;

    /**
     * Intial configuration
     *
     * @param el Host Element
     * @param _systemConfig System Config provided via DI
     */
    constructor(
        el: ElementRef,
        @Optional()
        @Inject(RFX_FORMS_ERROR_CONFIG)
        private _systemConfig: RfxErrorConfigToken
    ) {
        this._el = el;
        const config = !!this._systemConfig ? this._systemConfig : {};
        this._baseMessages = this._buildMessages(rfxErrorDefaults, config.messages || {});
        this._messages = this._buildMessages(this.customMessages);
    }

    /**
     * Runs after each change detection cycle to recalculate what error message
     * if any should be shown and updates the host element text content with that message.
     */
    ngAfterViewChecked(): void {
        const elem: HTMLElement = this._el.nativeElement;
        let content = '';
        let unsupportedKeys: string[] = [];
        if (typeof this.control.errors !== 'undefined' && this.control.errors !== null) {
            for (const eKey in this.control.errors) {
                if (!!this._messages[eKey]) {
                    if (this.showMultipleErrors) {
                        if (content !== '') {
                            content += ', ';
                        }
                        content += this._messages[eKey];
                    } else {
                        content = this._messages[eKey];
                        break;
                    }
                } else {
                    unsupportedKeys = [...unsupportedKeys, eKey];
                }
            }
            if (content === '') {
                // Didn't match any error message keys
                content = 'Invalid';
            }
            if (unsupportedKeys.length > 0) {
                console.warn(
                    `@rfx/ngx-forms unsupported error keys detected, ${unsupportedKeys.join(', ')}`
                );
            }
        }
        elem.textContent = content;
    }

    /**
     * @ignore
     *
     * Builds a new object with messages, applying any new items on top
     * of the existing baseMessages
     *
     * @param messages Custom messages to apply on top of existing baseMessages
     * @returns RfxErrorMessages
     */
    private _buildMessages(...messages: Partial<RfxErrorMessages>[]): RfxErrorMessages {
        // tslint:disable-next-line:ter-arrow-body-style
        return messages.reduce((prev, next) => {
            return { ...prev, ...next };
        }, this._baseMessages) as RfxErrorMessages;
    }
}
