<div *ngIf="(answers | async) as q">
    <mat-list>
        <h3 mat-header>Company Questionnaire</h3>
        <mat-list-item>
            <h4 mat-line>
                <strong
                    >How many years have you been in your industry doing this type of work?</strong
                >
            </h4>
            <p mat-line>
                {{ !!q.yearsInIndustry.answer ? q.yearsInIndustry.answer + ' year(s)' : 'N/A' }}
            </p>
        </mat-list-item>
        <mat-list-item>
            <h4 mat-line>
                <strong>How many work vehicles do you have?</strong>
            </h4>
            <p mat-line>{{ !!q.workVehicles.answer ? q.workVehicles.answer : 'N/A' }}</p>
        </mat-list-item>
        <mat-list-item>
            <h4 mat-line>
                <strong>Could you offer any discounts for being awarded all sites you bid?</strong>
            </h4>
            <p mat-line>{{ q.offerDiscounts.answer ? q.offerDiscounts.answer : 'N/A' }}</p>
        </mat-list-item>
        <mat-list-item>
            <h4 mat-line>
                <strong
                    >Are you willing and able to use our proprietary AllGrounds app and take pictures
                    during each service?</strong
                >
            </h4>
            <p mat-line>{{ q.canUseApp.answer ? q.canUseApp.answer : 'N/A' }}</p>
        </mat-list-item>
        <mat-list-item>
            <h4 mat-line>
                <strong>Does your company have any licenses or certifications? </strong>
            </h4>
            <p mat-line>{{ q.licensesOrCerts.answer ? q.licensesOrCerts.answer : 'N/A' }}</p>
        </mat-list-item>
        <mat-list-item>
            <h4 mat-line>
                <strong>Do you have employees? </strong>
            </h4>
            <p mat-line>{{ q.haveEmployees.answer ? q.haveEmployees.answer : 'N/A' }}</p>
        </mat-list-item>
    </mat-list>
</div>
