import { SpMonthlyCharges } from './spCharges/sp-monthly-charges';
import { SpHourlyCharges } from './spCharges/sp-hourly-charges';
import { SpPerEventCharges } from './spCharges/sp-per-event-charges';
import { IBillingTypeChargeStrategy } from './interfaces/i-billing-type-charge-strategy';
import { OnegmHourlyCharges } from './onegmCharges/onegm-hourly-charges';
import { OnegmPerEventCharges } from './onegmCharges/onegm-per-event';
import { OnegmMonthlyCharges } from './onegmCharges/onegm-monthly-charges';
import { BillingType } from '../../enums';

export class BillingTypeChargeStrategyFactory {
    public createSp(billingType: BillingType): IBillingTypeChargeStrategy {
        switch (billingType) {
            case BillingType.Monthly:
                return new SpMonthlyCharges();
            case BillingType.PerEvent:
                return new SpPerEventCharges();
            case BillingType.Hourly:
                return new SpHourlyCharges();
            default:
                throw new Error(
                    `SP Timesheet Billing Type Charges Strategy factory not implemented for billing type: ${billingType}`
                );
        }
    }

    public createOnegm(billingType: BillingType): IBillingTypeChargeStrategy {
        switch (billingType) {
            case BillingType.Monthly:
                return new OnegmMonthlyCharges();
            case BillingType.PerEvent:
                return new OnegmPerEventCharges();
            case BillingType.Hourly:
                return new OnegmHourlyCharges();
            default:
                throw new Error(
                    `Onegm Timesheet Billing Type Charges Strategy factory not implemented for billing type: ${billingType}`
                );
        }
    }
}
