import { WorkOrderInvoiceWithoutIds } from '@gm2/common';
import { CompanyIdentity } from '../company/CompanyIdentity';
import { WorkOrderInvoiceDeclined } from './work-order-invoice-declined';
import { WorkOrderInvoiceHistory } from './work-order-invoice-history';

export class WorkOrderInvoice extends WorkOrderInvoiceWithoutIds {
    public _id: string;
    public companyIdentity: CompanyIdentity = new CompanyIdentity();
    public phaseIds: string[] = [];
    public declined: WorkOrderInvoiceDeclined = new WorkOrderInvoiceDeclined();
    public history: WorkOrderInvoiceHistory[] = [];
    public ownedBy:CompanyIdentity = new CompanyIdentity();
    public syncedToQuickbooks: boolean = false;

    constructor(params?: Partial<WorkOrderInvoice>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;

            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentity(params.companyIdentity)
                : this.companyIdentity;

            this.phaseIds = Array.isArray(params.phaseIds)
                ? params.phaseIds
                : this.phaseIds;

            this.declined = !!params.declined
                ? new WorkOrderInvoiceDeclined(params.declined)
                : this.declined;

            this.history = Array.isArray(params.history)
                ? params.history.map(history => new WorkOrderInvoiceHistory(history))
                : this.history;

            this.ownedBy = !!params.ownedBy
                ? new CompanyIdentity(params.ownedBy)
                : this.ownedBy;

            this.syncedToQuickbooks = !!params.syncedToQuickbooks ? params.syncedToQuickbooks : this.syncedToQuickbooks;
        }
    }
}
