import { Injectable } from '@angular/core';
import { HttpService, Post, Put, Get, MapValue, Body, Path, MapClass, Query } from '@refactor/ngx/http';
import { Role, RoleCreateDto } from '../models';
import { Observable } from 'rxjs';
import { PaginatedList, GridParams } from '@refactor/common';
import { ObjectId } from '@refactor/njs/db/mongo';
import { CompanyType, Permission } from '@gm2/common';

interface RolesAvailableDto {
    companyId?: string;
    companyType?: CompanyType;
    showChildCompanies?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class RoleService extends HttpService {
    @Post('/role/list')
    @MapValue(
        res =>
            new PaginatedList<Role>({
                count: res.count,
                docs: res.docs.map(d => new Role(d)),
            }),
    )
    public getRoleList(@Body() params: GridParams): Observable<PaginatedList<Role>> {
        return null;
    }

    // @Get('/role/byCompany/:id')
    // @MapValue(Role)
    // public getCompanyRoleList(@Path('id') companyId: string): Observable<Role[]> {
    //     return null;
    // }

    @Post('/role/list/available')
    @MapValue(Role)
    public getAvailableRoleList(@Body() dto: RolesAvailableDto): Observable<Role[]> {
        return null;
    }

    @Get('/role/name/crew')
    public getCrewRoleId(): Observable<ObjectId> {
        return null;
    }

    @Put('/role/delete')
    public deleteRoles(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }

    @Get('/role/:id')
    @MapClass(Role)
    public getRole(@Path('id') id: string): Observable<Role> {
        return null;
    }

    @Post('/role')
    public createRole(@Body() body: RoleCreateDto): Observable<void> {
        return null;
    }

    @Put('/role')
    @MapClass(Role)
    public editRole(@Body('id') id: string, @Body() dto: RoleCreateDto): Observable<Role> {
        return null;
    }

    @Get('/role/facilityManagerFilterRoles')
    @MapClass(Role)
    public getFacilityManagerFilterRoles(): Observable<Role[]> {
        return null;
    }

    @Post('/role/byCompanyIds')
    public getRolesByCompanyIds(
        @Body() dto: { ids: string[] },
    ): Observable<Role[]> {
        return null;
    }
}
