import { parseISODate, DateTimeBucket } from '@refactor/common';
import { TimezoneAwareDateTimeBucket } from '../../utils';

export class TimesheetDates {
    public tzAwareBucket: TimezoneAwareDateTimeBucket = new TimezoneAwareDateTimeBucket();

    public systemStartDateBucket: DateTimeBucket = new DateTimeBucket();
    public systemStartDate: Date = new Date();
    public systemEndDateBucket: DateTimeBucket = new DateTimeBucket();
    public systemEndDate: Date = new Date();

    public timesheetStartDateBucket: DateTimeBucket = new DateTimeBucket();
    public timesheetStartDate: Date = new Date();
    public timesheetEndDateBucket: DateTimeBucket = new DateTimeBucket();
    public timesheetEndDate: Date = new Date();

    constructor(params?: Partial<TimesheetDates>) {
        if (!!params) {
            this.tzAwareBucket = !!params.tzAwareBucket
                ? new TimezoneAwareDateTimeBucket(params.tzAwareBucket)
                : this.tzAwareBucket;

            this.systemStartDateBucket = !!params.systemStartDateBucket
                ? new DateTimeBucket(params.systemStartDateBucket)
                : this.systemStartDateBucket;

            this.systemStartDate = !!params.systemStartDate
                ? parseISODate(params.systemStartDate)
                : this.systemStartDate;

            this.systemEndDateBucket = !!params.systemEndDateBucket
                ? new DateTimeBucket(params.systemEndDateBucket)
                : this.systemEndDateBucket;

            this.systemEndDate = !!params.systemEndDate
                ? parseISODate(params.systemEndDate)
                : this.systemEndDate;

            this.timesheetStartDateBucket = !!params.timesheetStartDateBucket
                ? new DateTimeBucket(params.timesheetStartDateBucket)
                : this.timesheetStartDateBucket;

            this.timesheetStartDate = !!params.timesheetStartDate
                ? parseISODate(params.timesheetStartDate)
                : this.timesheetStartDate;

            this.timesheetEndDateBucket = !!params.timesheetEndDateBucket
                ? new DateTimeBucket(params.timesheetEndDateBucket)
                : this.timesheetEndDateBucket;

            this.timesheetEndDate = !!params.timesheetEndDate
                ? parseISODate(params.timesheetEndDate)
                : this.timesheetEndDate;
        }
    }
}
