import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'gm2-date-modal',
    templateUrl: './date-modal.component.html',
    styleUrls: ['./date-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateModalComponent implements OnInit {

    public dateForm: UntypedFormGroup = this._fb.group({
        date: ['', Validators.required],
    });

    constructor(
        private _fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<DateModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {}

    public close(): void {
        this.dialogRef.close(false);
    }

    public select(): void {
        this.dialogRef.close(this.dateForm);
    }
}