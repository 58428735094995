import { environment } from '@ngx-gm2/env';

export const url = {
    invite: {
        crud: `${environment.webserver}/invites`,
        one: (id: string) => `${environment.webserver}/invites/${id}`,
        resend: (id: string) => `${environment.webserver}/invites/resend/${id}`,
        cancel: (id: string) => `${environment.webserver}/invites/cancel/${id}`,
    },
    user: {
        getByRoleIds: `${environment.webserver}/user/getUsers/by-role-ids`,
        getBySubCompanyId:  (id:string) => `${environment.webserver}/user/by-sub-company/${id}`,
        list: `${environment.webserver}/user/list-by-filters`,
        attachPLByEmail: `${environment.webserver}/user/attach-pl-by-email`,
        detachPl: `${environment.webserver}/user/detach-pl`,
        getById: (id:string) => `${environment.webserver}/user/${id}`,
        getMyUser: `${environment.webserver}/user`,
        createUser: `${environment.webserver}/user/create`,
        editUser: (id:string) => `${environment.webserver}/user/${id}`,
        delete: `${environment.webserver}/user/delete`,

    },
    company: {
        list: `${environment.webserver}/company/list-by-filters`,
        listByTypes: `${environment.webserver}/company/list-by-types`,
        updateGeospatialServiceStatus: (companyId: string) => `${environment.webserver}/company/geospatial-service/${companyId}`,
    },
    companyInvite: {
        crud: `${environment.webserver}/company-invite`,
        accept: (id: string) => `${environment.webserver}/company-invite/accept/${id}`,
        reject: (id: string) => `${environment.webserver}/company-invite/reject/${id}`,
        revoke: (id: string) => `${environment.webserver}/company-invite/revoke/${id}`,
    },
    geospatial: {
        usage: `${environment.webserver}/geospatial/usage`,
        personalUsage: `${environment.webserver}/geospatial/personal-usage`,
    }

};
