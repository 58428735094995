import { Injectable } from '@angular/core';
import {
    HttpService,
    Post,
    Get,
    MapClass,
    Body,
    MapValue,
    Path,
    Put,
    Delete,
    ResponseType, Query,
} from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { PaginatedList, GridParams } from '@refactor/common';
import { ServiceType, IMappingCategoryLike } from '../models';

@Injectable({
    providedIn: 'root',
})
export class ServiceTypeService extends HttpService {
    @Post('/serviceType/')
    // don't yet know if we need a DTO for this or not.
    public createServiceType(@Body() body: ServiceType): Observable<void> {
        return null;
    }

    @Post('/serviceType/list')
    @MapValue(
        res =>
            new PaginatedList<ServiceType>({
                count: res.count,
                docs: res.docs.map(d => new ServiceType(d)),
            }),
    )
    public getServiceTypeList(@Body() params: GridParams): Observable<PaginatedList<ServiceType>> {
        return null;
    }

    @Get('/serviceType/')
    public getServiceTypes(@Query('companyId') id?: string): Observable<ServiceType[]> {
        return null;
    }

    @Get('/serviceType/all')
    public getServiceTypesAll(): Observable<ServiceType[]> {
        return null;
    }

    @Get('/serviceType/:serviceTypeId')
    @MapClass(ServiceType)
    public getServiceType(@Path('serviceTypeId') id: string): Observable<ServiceType> {
        return null;
    }

    @Put('/serviceType/:serviceTypeId')
    @MapClass(ServiceType)
    public editServiceType(
        @Path('serviceTypeId') id: string,
        @Body() body: ServiceType,
    ): Observable<ServiceType> {
        return null;
    }

    @Delete('/serviceType/:serviceTypeId/mappingCategory/:mappingCategoryId')
    public deleteMappingCategory(
        @Path('serviceTypeId') serviceTypeId: string,
        @Path('mappingCategoryId') mappingCategoryId: string,
    ): Observable<void> {
        return null;
    }

    @Post('/serviceType/:serviceTypeId/mappingCategory')
    public addMappingCategory(
        @Path('serviceTypeId') serviceTypeId: string,
        @Body() mappingCategory: IMappingCategoryLike,
    ): Observable<string> {
        return null;
    }

    @Post('/serviceType/delete')
    public deleteServiceTypes(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }

    @Post('/serviceType/csvExport', {
        responseType: ResponseType.Text,
    })
    public serviceTypeCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }


    @Get('/serviceType/external-contracts/list')
    public getServiceTypesExternalContracts(): Observable<ServiceType[]> {
        return null;
    }
}
