import { BaseDoc } from '../base/base-doc';
import { CompanyIdentityWithoutIds } from '../company/company-identity';
import { LocationIdentityWithoutIds } from '../location/location-identity';
import { UserIdentityWithoutIds } from '../user/user-identity';
import {
    WorkOrderReportedBy,
    WorkOrderWorkCategory,
    WorkOrderPriority,
    WorkOrderActiveStatus,
    WorkOrderStatus,
    WorkOrderBillingType,
    WorkOrderGMR,
    WorkOrderInvoiceable,
    CompanyType,
    WorkOrderLaborCostType
} from '../../enums';
import { WorkOrderNotification } from './work-order-notification';
import { WorkOrderTotals } from './work-order-totals';
import { WorkOrderPhaseWithoutIds } from './work-order-phase';
import { isNull } from '../../utils';

export class WorkOrderWithoutIds extends BaseDoc {
    public _id: any;
    public clientIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public locationIdentity: LocationIdentityWithoutIds = new LocationIdentityWithoutIds();
    public createdByIdentity: UserIdentityWithoutIds = new UserIdentityWithoutIds();
    public createdByCompanyIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public createdByCompanyType: CompanyType;
    public phases: WorkOrderPhaseWithoutIds[] = [];

    public notification: WorkOrderNotification = new WorkOrderNotification();
    public totals: WorkOrderTotals = new WorkOrderTotals();

    public clientInvoiceNumber: string;
    public servicePartnerInvoiceNumber: string;
    public workOrderNumber: string;
    public gmr: WorkOrderGMR;
    public safety: boolean;
    public completeByDate: Date;
    public completionDate: Date;
    public approvedForInvoicingDate: Date;
    public scheduledDate: Date;
    public templateName: string = '';
    public message: string = '';
    public rejectionMessage: string = '';
    public lastStatusChangedDate: Date;
    public reportedBy: WorkOrderReportedBy;
    public workCategory: WorkOrderWorkCategory;
    public priority: WorkOrderPriority;
    public billingType: WorkOrderBillingType;
    public activeStatus: WorkOrderActiveStatus;
    public isMobile: boolean = false;
    public workOrderStatus: WorkOrderStatus;
    public referredBy: string = '';
    public qbInvoiceNumber: string;
    public clientWorkOrderNumber: string;
    public serviceDueDate: Date;
    public notToExceed: number = null;
    public invoiceable: WorkOrderInvoiceable;
    public laborCostType: WorkOrderLaborCostType;

    constructor(params?: Partial<WorkOrderWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.clientIdentity = !!params.clientIdentity
                ? new CompanyIdentityWithoutIds(params.clientIdentity)
                : this.clientIdentity;
            this.locationIdentity = !!params.locationIdentity
                ? new LocationIdentityWithoutIds(params.locationIdentity)
                : this.locationIdentity;
            this.createdByIdentity = !!params.createdByIdentity
                ? new UserIdentityWithoutIds(params.createdByIdentity)
                : this.createdByIdentity;
            this.createdByCompanyIdentity = !!params.createdByCompanyIdentity
                ? new CompanyIdentityWithoutIds(params.createdByCompanyIdentity)
                : this.createdByCompanyIdentity;
            this.createdByCompanyType = params.createdByCompanyType || this.createdByCompanyType;
            this.phases = !!params.phases
                ? params.phases.map(p => new WorkOrderPhaseWithoutIds(p))
                : this.phases;

            this.notification = !!params.notification
                ? new WorkOrderNotification(params.notification)
                : this.notification;
            this.totals = !!params.totals ? new WorkOrderTotals(params.totals) : this.totals;

            this.clientInvoiceNumber = params.clientInvoiceNumber || this.clientInvoiceNumber;
            this.servicePartnerInvoiceNumber = params.servicePartnerInvoiceNumber || this.servicePartnerInvoiceNumber;
            this.workOrderNumber = params.workOrderNumber || this.workOrderNumber;
            this.safety = params.safety || this.safety;
            this.completeByDate = params.completeByDate || this.completeByDate;
            this.completionDate = params.completionDate || this.completionDate;
            this.approvedForInvoicingDate = params.approvedForInvoicingDate || this.approvedForInvoicingDate;
            this.scheduledDate = params.scheduledDate || this.scheduledDate;
            this.gmr = params.gmr || this.gmr;
            this.message = params.message || this.message;
            this.rejectionMessage = params.rejectionMessage || this.rejectionMessage;
            this.templateName = params.templateName || this.templateName;
            this.lastStatusChangedDate = params.lastStatusChangedDate || this.lastStatusChangedDate;
            this.reportedBy = params.reportedBy || this.reportedBy;
            this.workCategory = params.workCategory || this.workCategory;
            this.priority = params.priority || this.priority;
            this.billingType = params.billingType || this.billingType;
            this.activeStatus = params.activeStatus || this.activeStatus;
            this.workOrderStatus = params.workOrderStatus || this.workOrderStatus;
            this.isMobile = !isNull(params.isMobile) ? params.isMobile : this.isMobile;
            this.referredBy = params.referredBy || this.referredBy;
            this.qbInvoiceNumber = params.qbInvoiceNumber || this.qbInvoiceNumber;
            this.clientWorkOrderNumber = params.clientWorkOrderNumber || this.clientWorkOrderNumber;
            this.serviceDueDate = params.serviceDueDate || this.serviceDueDate;
            this.notToExceed = typeof params.notToExceed === 'number' && params.notToExceed > 0
                ? params.notToExceed
                : this.notToExceed;
            this.invoiceable = params.invoiceable || this.invoiceable;
            this.laborCostType = params.laborCostType || this.laborCostType;
        }
    }
}
