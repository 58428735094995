import { AuthState } from './auth.state';
import { CompanyState } from './company.state';
import { ComplexState } from './complex.state';
import { TagsState } from './tags.state';
import { UserState } from './user.state';
import { ServiceTypeCreateState } from './service-type-create.state';
import { ILocationGalleryFilters, LocationGalleryState } from './location-gallery.state';
import { captureGridState, GridState, IGridState, restoreGridState } from './grid.state';
import { InviteState } from './invite.state';
import { SharedState } from './shared.state';
import { TimesheetState } from './timesheet.state';
import { InvoiceState } from './invoice.state';
import { LocationsState } from './locations.state';
import { GeospatialState } from './geospatial.state';

export const sharedStates = [
    AuthState,
    CompanyState,
    TagsState,
    UserState,
    GridState,
    InviteState,
    LocationGalleryState,
    SharedState,
    TimesheetState,
    InvoiceState,
    LocationsState,
    GeospatialState,
];

export {
    AuthState,
    CompanyState,
    TagsState,
    UserState,
    ComplexState,
    ServiceTypeCreateState,
    LocationGalleryState,
    ILocationGalleryFilters,
    GridState,
    IGridState,
    restoreGridState,
    captureGridState,
    InviteState,
    SharedState,
    TimesheetState,
    InvoiceState,
    LocationsState,
    GeospatialState
};
