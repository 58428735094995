import { TimesheetServiceMaterial } from './timesheet-service-material';
import { ServiceSettings } from '@gm2/common';

export class TimesheetServiceDto {
    public _id: string = '';
    public name: string = '';
    public hours: number = 0;
    public minutes: number = 0;
    public material: TimesheetServiceMaterial;
    public note: string = '';
    public settings: ServiceSettings = new ServiceSettings();
    public children: any[] = [];
    public materials?: any[] = [];
    public durationMinutes?: number = 0;
    public serviceId: string = ''

    constructor(params?: Partial<TimesheetServiceDto>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.hours = params.hours || this.hours;
            this.minutes = params.minutes || this.minutes;
            this.material = params.material || this.material;
            this.note = params.note || this.note;
            this.settings = params.settings || this.settings;
            this.children = params.children || this.children;
            this.materials = params.materials || this.materials;
        }
    }
}
