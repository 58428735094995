import { BaseDoc } from '../base/base-doc';
import { FormFieldChoice } from './form-field-choice';
import { FieldType, InputType, AppButtonSize } from '../../enums';
import { FormFieldConditional } from './form-field-conditional';

export class FormField extends BaseDoc {
    public id: string;
    public type: FieldType;
    public inputType: InputType;
    public group: boolean;
    public label: string;
    public isRequired: string;
    public adminLabel: string;
    public description: string;
    public cssClass: string; // Doesn't appear to be used? All I see in db is empty strings
    public showHelp: boolean;
    public required: boolean;
    public appButtonSize: AppButtonSize;
    public defaultValue: string;
    public placeholder: string;
    public choices: FormFieldChoice[] = [];
    public conditional: FormFieldConditional;

    constructor(params?: Partial<FormField>) {
        super(params);
        if (!!params) {
            this.id = params.id || this.id;
            this.type = params.type || this.type;
            this.inputType = params.inputType || this.inputType;
            this.group = params.group || this.group;
            this.label = params.label || this.label;
            this.isRequired = params.isRequired || this.isRequired;
            this.adminLabel = params.adminLabel || this.adminLabel;
            this.description = params.description || this.description;
            this.cssClass = params.cssClass || this.cssClass;
            this.showHelp = params.showHelp || this.showHelp;
            this.required = params.required || this.required;
            this.appButtonSize = params.appButtonSize || this.appButtonSize;
            this.defaultValue = params.defaultValue || this.defaultValue;
            this.placeholder = params.placeholder || this.placeholder;
            this.choices = !!params.choices ? params.choices.map(c => new FormFieldChoice(c)) : this.choices;
            this.conditional = !!params.conditional ? new FormFieldConditional(params.conditional) : this.conditional;
        }
    }
}
