import { BaseDoc } from '../base/base-doc';
import { CompanyStatus, ServicePartnerCompliant } from '../../enums';

export class SubcontractorInfoWithoutIds extends BaseDoc {
    public _id: any;
    public requireTimeAllocation: boolean = false;
    public questionnaireAnswers: any;
    public contractOwnerId: any;
    public subcontractorId: any;
    public companyStatusExplanation: string;
    public companyStatus: CompanyStatus = CompanyStatus.Active;
    public servicePartnerCompliant: ServicePartnerCompliant;


    constructor(params?: Partial<SubcontractorInfoWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.requireTimeAllocation = params.requireTimeAllocation || this.requireTimeAllocation;
            this.questionnaireAnswers = params.questionnaireAnswers || this.questionnaireAnswers;
            this.contractOwnerId = params.contractOwnerId || this.contractOwnerId;
            this.subcontractorId = params.subcontractorId || this.subcontractorId;
            this.companyStatusExplanation = params.companyStatusExplanation ?? this.companyStatusExplanation;
            this.companyStatus = params.companyStatus ?? this.companyStatus;
            this.servicePartnerCompliant = params.servicePartnerCompliant ?? this.servicePartnerCompliant;
        }
    }
}
