import { MappingWithoutIds, MappingClassificationArea } from '../../models';
import { MappingClassification } from '../../enums';
import { Injectable } from "@angular/core";

@Injectable()
export class AreaCalculationService {
    public allClassifications(mappings: Partial<MappingWithoutIds>[]): MappingClassificationArea[] {
        const classificationDict: { [key: string]: number } = {};

        MappingClassification.members.forEach(classification => {
            classificationDict[classification] = 0;
        });

        mappings.forEach(mapping => {
            if (!!mapping.mappingClassification) {
                classificationDict[mapping.mappingClassification] += mapping.area || 0;
            }
        });

        const areas: MappingClassificationArea[] = [];
        MappingClassification.members.forEach(classification => {
            areas.push(
                new MappingClassificationArea({
                    mappingClassification: classification,
                    totalArea: classificationDict[classification]
                })
            );
        });

        return areas;
    }

    public byClassification(
        classification: MappingClassification,
        mappings: Partial<MappingWithoutIds>[]
    ): MappingClassificationArea {
        let totalArea = 0;

        mappings.forEach(mapping => {
            if (mapping.mappingClassification === classification) {
                totalArea += mapping.area || 0;
            }
        });

        return new MappingClassificationArea({
            mappingClassification: classification,
            totalArea: totalArea
        });
    }
}
