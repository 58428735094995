import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { InvoiceMonthlyType } from '@gm2/common';
import { CreateMonthlyInvoiceDto } from '@gm2/ui-common';

@Component({
    selector: 'gm2-monthly-invoice-modal',
    templateUrl: './monthly-invoice-modal.component.html',
    styleUrls: ['./monthly-invoice-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthlyInvoiceModalComponent implements OnInit {

    public readonly InvoiceMonthlyType: typeof InvoiceMonthlyType = InvoiceMonthlyType;

    public form: UntypedFormGroup = this._fb.group({
        type: ['', Validators.required],
        month: ['', Validators.required],
        landscaper: [''],
    });

    public monthOptions: { value: { month: number; year: number }, label: string }[] = [];

    constructor(
        private _fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<MonthlyInvoiceModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            landscapers: { name: string; _id: string }[]
        },
    ) {
    }

    ngOnInit(): void {
        if (this.data.landscapers?.length === 1) {
            this.form.patchValue({ landscaper: this.data.landscapers[0]._id });
        }
        const numMonths = 6;
        for(let m = 0; m < numMonths; ++m) {
            const date = new Date();
            this.monthTransform(date, m);
            this.monthOptions.push({
                value: { month: date.getMonth(), year: date.getFullYear() },
                label: date.toLocaleString('default', { month: 'long' }),
            });
        }
    }

    private monthTransform(date: Date, months: number): void {
        /*
            roll a date backwards in time across 'months' month
            boundaries, and set its day-of-month to the first
        */
        for(let m = 0; m < months; ++m) {
            const month = date.getMonth();
            while (date.getMonth() === month) {
                date.setDate(date.getDate() - 1);
            }
        }
        date.setDate(1);
    }

    public close(): void {
        this.dialogRef.close(false);
    }

    public confirm(): void {
        this.dialogRef.close(new CreateMonthlyInvoiceDto({
            month: this.form.value.month.month,
            year: this.form.value.month.year,
            monthlyType: this.form.value.type,
            landscaper: this.form.value.landscaper,
        }));
    }
}
