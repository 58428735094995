import { isNull } from '../../utils';

export enum MaterialMeasurementType {
    Pound = '[MaterialMeasurementType] Pound',
    Yard = '[MaterialMeasurementType] Yard',
    Ton = '[MaterialMeasurementType] Ton',
    Gallon = '[MaterialMeasurementType] Gallon',
    PerApplication = '[MaterialMeasurementType] PerApplication',
    PerDay = '[MaterialMeasurementType] PerDay',
    PerWeek = '[MaterialMeasurementType] PerWeek',
    PerMonth = '[MaterialMeasurementType] PerMonth'
}

export namespace MaterialMeasurementType {
    export function toString(type: MaterialMeasurementType): string {
        switch (type) {
            case MaterialMeasurementType.Pound:
                return 'Pound';
            case MaterialMeasurementType.Yard:
                return 'Yard';
            case MaterialMeasurementType.Ton:
                return 'Ton';
            case MaterialMeasurementType.Gallon:
                return 'Gallon';
            case MaterialMeasurementType.PerApplication:
                return 'Per Application';
            case MaterialMeasurementType.PerDay:
                return 'Per Day';
            case MaterialMeasurementType.PerWeek:
                return 'Per Week';
            case MaterialMeasurementType.PerMonth:
                return 'Per Month';

            default:
                return '';
        }
    }

    export function units(type: MaterialMeasurementType): string {
        switch (type) {
            case MaterialMeasurementType.Pound:
                return 'lbs';
            case MaterialMeasurementType.Yard:
                return 'yd';
            case MaterialMeasurementType.Ton:
                return 'ton';
            case MaterialMeasurementType.Gallon:
                return 'gal';
            case MaterialMeasurementType.PerApplication:
                return 'per';
            case MaterialMeasurementType.PerDay:
                return 'day';
            case MaterialMeasurementType.PerWeek:
                return 'week';
            case MaterialMeasurementType.PerMonth:
                return 'month';

            default:
                return '';
        }
    }

    export const members: MaterialMeasurementType[] = [
        MaterialMeasurementType.Pound,
        MaterialMeasurementType.Yard,
        MaterialMeasurementType.Ton,
        MaterialMeasurementType.Gallon,
        MaterialMeasurementType.PerApplication,
        MaterialMeasurementType.PerDay,
        MaterialMeasurementType.PerWeek,
        MaterialMeasurementType.PerMonth
    ];
}
