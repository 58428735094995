import { WorkOrderSimpleWithoutIds } from '@gm2/common';
import { Note } from '../note/Note';
import { CompanyIdentity } from '../company/CompanyIdentity';
import { WorkOrderPhaseSimple } from './WorkOrderPhaseSimple';

export class WorkOrderSimple extends WorkOrderSimpleWithoutIds {
    public _id: string = '';
    public notes: Note[] = [];
    public createdByCompanyIdentity: CompanyIdentity = new CompanyIdentity();
    public phases: WorkOrderPhaseSimple[] = [];
    public spTotal:number = 0;
    public isSubcontractor:boolean = false;
    constructor(params?: Partial<WorkOrderSimple>) {
        super(params);
        if (!!params) {
            this.spTotal = params.spTotal || this.spTotal;
            this._id = params._id || this._id;
            this.notes = !!params.notes
                ? params.notes.map(n => new Note(n))
                : this.notes;
            this.createdByCompanyIdentity = !!params.createdByCompanyIdentity
                ? new CompanyIdentity(params.createdByCompanyIdentity)
                : this.createdByCompanyIdentity;
            this.phases = Array.isArray(params.phases)
                ? params.phases.map(p => new WorkOrderPhaseSimple(p))
                : this.phases;
            this.isSubcontractor = params.isSubcontractor || this.isSubcontractor;

        }
    }
}
