export enum BillingType {
    Hourly = '[BillingType] Hourly',
    PerEvent = '[BillingType] PerEvent',
    Monthly = '[BillingType] Monthly'
}

export namespace BillingType {
    export function toString(type: BillingType): string {
        switch (type) {
            case BillingType.Hourly:
                return 'Hourly';
            case BillingType.PerEvent:
                return 'Per Push';
            case BillingType.Monthly:
                return 'Monthly';
            default:
                return '';
        }
    }

    export function toBillingType(type: string): BillingType {
        switch (type) {
            case 'Hourly':
                return BillingType.Hourly;
            case 'Per Push':
                return BillingType.PerEvent;
            case 'Monthly':
                return BillingType.Monthly;
            default:
                return null;
        }
    }

    export const members: BillingType[] = [
        BillingType.Hourly,
        BillingType.PerEvent,
        BillingType.Monthly
    ];
}
