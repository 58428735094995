import { RfpServiceWithoutIds } from './rfp-service';
import { isNull } from '../../utils';

export class ClientPricingWithoutIds {
    public services: RfpServiceWithoutIds[] = [];
    public monthlyClientPrice: number = null; // only needed on monthly RFP
    public monthlyRequestedPrice: number = null; // only needed on monthly RFP for SP's

    constructor(params?: Partial<ClientPricingWithoutIds>) {
        if (!!params) {
            this.services = Array.isArray(params.services)
                ? params.services.map(service => new RfpServiceWithoutIds(service))
                : this.services;

            this.monthlyClientPrice = !isNull(params.monthlyClientPrice)
                ? params.monthlyClientPrice
                : this.monthlyClientPrice;

            this.monthlyRequestedPrice = !isNull(params.monthlyRequestedPrice)
                ? params.monthlyRequestedPrice
                : this.monthlyRequestedPrice;
        }
    }
}
