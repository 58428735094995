import {
    MaterialActiveIngredient,
    MaterialExceededRatio,
    ServiceClassification,
    GroundApplicationType
} from '../../enums';
import { MappingClassificationArea } from '../../models';
import { Injectable } from "@angular/core";

export type MaterialCalculationResult = { rocksalt: number; magnesium: number; calcium: number };

@Injectable()
export class MaterialCalculationService {
    // Rate of change in melting capacity given a surface temp. Calculated from chart on page 46 of ice manual.
    private _rateOfChange: number = 0.525;

    // Established start degrees at 32.1 degrees Farenheight and the associated pounds of ice that can be melted by 1 pound (lb) of ice melt.
    private _esIce: number = 95.01;

    // Degrees Farenheight that is associated with 95.01 lbs of ice that can be melted with 1 pound of deicer material.
    private _esTemp: number = 32.1;

    // Adjustment Factor used to reconcile differences in rates as a result of solution properties at differing
    // temperatures and to meet the established industry tests of ice melt
    private _adjustmentFactor: number = 3.6;

    // Adjustment Factor used to reconcile differences in rates as a result of solution properties at differing lower
    // temperatures and to meet the established industry tests of ice melt
    private _adjustmentFactorLowTemp: number = 2.6;

    // The adjustment factor applied to convert pounds of Rock Salt needed to pounds of Mag Chloride needed.
    private _magAdjustmentFactor: number = 0.7;

    // The adjustment factor applied to convert pounds of Rock Salt needed to pounds of Cal Chloride needed.
    private _calAdjustmentFactor: number = 0.7;

    // The adjustment factor applied to get the correct anti-icing rate
    private _antiIceAdjustment: number = 0.333;

    // pounds (lbs) of ice that is in 1 square foot of 1/64 inch delth ice. This is a constant.
    private _iceConst: number = 0.130345;

    // Safety factor applied to account for waste
    private _safetyFactor: number = 1.05;

    private _freezingTemp: number = 32;

    private _adjustmentFactorThreshold: number = 11;

    // rounding 2 decimal places
    private _twoFractionDigits: number = 2;

    // multipliers for determining exceeded amount
    private _exceeds50PercentGreater: number = 1.5;
    private _exceeds25PercentGreater: number = 1.25;
    private _exceeds25PercentLess: number = 0.75;
    private _exceeds50PercentLess: number = 0.5;

    public getCalculations(
        surfaceTemp: number = 15,
        areaSqft: number = 1,
        groundApplicationType: GroundApplicationType = GroundApplicationType.None
    ): MaterialCalculationResult {
        surfaceTemp = surfaceTemp > this._freezingTemp ? this._freezingTemp : surfaceTemp;
        const lbsIceBeforeAdjustment =
            this._esIce / (1 + (this._esTemp - surfaceTemp) * this._rateOfChange);
        const lbsIce =
            surfaceTemp < this._adjustmentFactorThreshold
                ? lbsIceBeforeAdjustment - this._adjustmentFactorLowTemp
                : lbsIceBeforeAdjustment - this._adjustmentFactor;
        const lbsAppliedBeforeAdjustments = (areaSqft * this._iceConst) / lbsIce;
        const lbsAppliedWithSafety = lbsAppliedBeforeAdjustments * this._safetyFactor;
        const lbsAppliedWithSafetyAntiIce: number =
            groundApplicationType === GroundApplicationType.AntiIce
                ? lbsAppliedWithSafety * this._antiIceAdjustment
                : lbsAppliedWithSafety;

        // round the values to 2 decimals and then convert to float
        return {
            rocksalt: parseFloat(lbsAppliedWithSafetyAntiIce.toFixed(this._twoFractionDigits)),
            magnesium: parseFloat(
                (lbsAppliedWithSafetyAntiIce * this._magAdjustmentFactor).toFixed(
                    this._twoFractionDigits
                )
            ),
            calcium: parseFloat(
                (lbsAppliedWithSafetyAntiIce * this._calAdjustmentFactor).toFixed(
                    this._twoFractionDigits
                )
            )
        };
    }

    /**
     * Calculate the expected amount of material to use based on the serviceClassification.
     * Null will be returned if calculatedArea data is not found or the the
     * serviceClassification can not be mapped to a MappingClassification
     *
     * @param serviceClassification
     * @param mappingCalculatedAreas
     * @param surfaceTemp
     * @param groundApplicationType
     * @param activeIngredient
     *
     * @returns null | number
     */
    public getExpectedRateByIngredient(
        serviceClassification: ServiceClassification,
        mappingCalculatedAreas: MappingClassificationArea[] = [],
        surfaceTemp: number = 15,
        groundApplicationType: GroundApplicationType = GroundApplicationType.None,
        activeIngredient: MaterialActiveIngredient = MaterialActiveIngredient.Rocksalt
    ): number {
        let areaSqft: number = 0;
        // convert to MappingClassification so we can get the sqft of the correct calculatedArea
        const serviceAsMappingClassification = ServiceClassification.toMappingClassification(
            serviceClassification
        );
        if (serviceAsMappingClassification == null) {
            return null;
        }
        let mappingClassificationFound = false;
        for (let areaIndex = 0; areaIndex < mappingCalculatedAreas.length; areaIndex++) {
            const currentArea = mappingCalculatedAreas[areaIndex];
            if (currentArea.mappingClassification === serviceAsMappingClassification) {
                mappingClassificationFound = true;
                areaSqft = currentArea.totalArea;
                break;
            }
        }
        if (!mappingClassificationFound) {
            return null;
        }

        const rates = this.getCalculations(surfaceTemp, areaSqft, groundApplicationType);
        switch (activeIngredient) {
            case MaterialActiveIngredient.Magnesium:
                return rates.magnesium;
            case MaterialActiveIngredient.Calcium:
                return rates.calcium;
            case MaterialActiveIngredient.Rocksalt:
            default:
                return rates.rocksalt;
        }
    }

    public exceededAmountRatio(
        serviceClassification: ServiceClassification,
        mappingCalculatedAreas: MappingClassificationArea[] = [],
        materialAmountUsed: number = 1,
        surfaceTemp: number = 15,
        groundApplicationType: GroundApplicationType = GroundApplicationType.None,
        activeIngredient: MaterialActiveIngredient = MaterialActiveIngredient.Rocksalt
    ): MaterialExceededRatio {
        const expectedAmount = this.getExpectedRateByIngredient(
            serviceClassification,
            mappingCalculatedAreas,
            surfaceTemp,
            groundApplicationType,
            activeIngredient
        );
        return this.exceededAmountRatioByExpected(expectedAmount, materialAmountUsed);
    }

    public exceededAmountRatioByExpected(
        expectedAmount: number,
        materialAmountUsed: number
    ): MaterialExceededRatio {
        if (materialAmountUsed >= expectedAmount * this._exceeds50PercentGreater) {
            return MaterialExceededRatio.Greater50;
        } else if (materialAmountUsed >= expectedAmount * this._exceeds25PercentGreater) {
            return MaterialExceededRatio.Greater25;
        } else if (materialAmountUsed <= expectedAmount * this._exceeds50PercentLess) {
            return MaterialExceededRatio.Less50;
        } else if (materialAmountUsed <= expectedAmount * this._exceeds25PercentLess) {
            return MaterialExceededRatio.Less25;
        }

        return MaterialExceededRatio.Even;
    }
}
