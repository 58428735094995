import { Injectable, Type } from '@angular/core';
import { RfxAppDefinedFieldService } from '@refactor/ngx/form-builder';
import { AppDefinedField } from '@gm2/common';
import { RfxFormCrlFieldsComponent } from '../components/rfx-form-crl-fields/rfx-form-crl-fields.component';

// I don't think this is needed but like the response transformer the package
// breaks without it so it's just a no-op
@Injectable()
export class AppDefinedFieldService extends RfxAppDefinedFieldService {
    public getFields(): { [key in AppDefinedField]: Type<any> } {
        return {
            [AppDefinedField.CrlFields]: RfxFormCrlFieldsComponent,
        };
    }
    public getFieldSelectOptions(): { value: string; label: string }[] {
        const options = [];
        AppDefinedField.members.forEach(f => {
            options.push({ value: f, label: AppDefinedField.toString(f as AppDefinedField) });
        });
        return options;
    }
}
