export enum WorkOrderGMR {
    GMR = '[WorkOrderGMR] GMR',
    NonGMR = '[WorkOrderGMR] nonGMR'
}

export namespace WorkOrderGMR {
    export function toString(type: WorkOrderGMR): string {
        switch (type) {
            case WorkOrderGMR.GMR:
                return 'GMR';
            case WorkOrderGMR.NonGMR:
                return 'Non-GMR';
            default:
                return '';
        }
    }

    export const members: WorkOrderGMR[] = [
        WorkOrderGMR.GMR,
        WorkOrderGMR.NonGMR,
    ];
}
