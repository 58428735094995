import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Tag, TagColor } from '@gm2/ui-common';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'gm2-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit, OnDestroy {
    @Input()
    public name: string = 'tagIds';
    @Input()
    public placeholder: string = 'Tags';
    @Input()
    public options: Tag[];

    public initialOptions: Tag[] = null;

    private _onDestroy: Subject<void> = new Subject<void>();

    @Input()
    public availableTags$: Observable<Tag[]>;

    @Input()
    public currentTagIds: Tag[];

    @Output()
    public updateTags: EventEmitter<string[]> = new EventEmitter<string[]>();


    public form: UntypedFormGroup = this._fb.group({
        tagIds: ['']
    });

    public searchControl: UntypedFormControl = new UntypedFormControl();

    public TagColor: typeof TagColor = TagColor;

    private filterOnSearch(): void {
        if (!this.options || !this.initialOptions) {
            return;
        }
        let search = this.searchControl.value;
        if (!search) {
            this.options = this.initialOptions.slice();
            return;
        } else {
            search = search.toLowerCase();
        }
        this.options = this.initialOptions.filter(location => location.label.toLowerCase().indexOf(search) > -1);
    }

    constructor(
        private readonly _fb: UntypedFormBuilder
    ) {}

    ngOnInit(): void {

        this.availableTags$.subscribe(availableTags => {
            if (!!availableTags) {
                this.options = availableTags;
                if (this.initialOptions === null || this.options.length > this.initialOptions.length) {
                    this.initialOptions = availableTags;
                    this.form.controls.tagIds.setValue(this.currentTagIds);
                }
            }
        });
        this.searchControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterOnSearch();
            }
        );
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    public toggleTagInput(): void {
        if (!this.form.pristine) {
            this.updateTags.emit(this.form.controls.tagIds.value);
            this.form.markAsPristine();
        }
    }
}

