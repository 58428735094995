export class LocationImageDto {
    public name: string;
    public notes?: string;
    public dateTaken: Date;
    public dateUploaded: Date;
    public webPath: string;
    public imageKey: string;
    public path?: string;
    public url?: string;

    constructor(params?: Partial<LocationImageDto>) {
        if (!!params) {
            this.name = params.name || this.name;
            this.notes = params.notes || this.notes;
            this.dateTaken = params.dateTaken || this.dateTaken;
            this.dateUploaded = params.dateUploaded || this.dateUploaded;
            this.imageKey = params.imageKey || this.imageKey;
            this.webPath = params.webPath || this.webPath;
            this.path = params.path || this.path;
            this.url = params.url || this.url;
        }
    }
}
