import { Injectable } from '@angular/core';
import { Get, Path, HttpService, Body, Post, Delete, FakeResponse, MapValue, Put } from '@refactor/ngx/http';
import { Observable } from 'rxjs';
import { Note } from '../models/note/Note';
import { NoteCreateDto } from '../models/note/NoteCreateDto';
import { PaginatedList, GridParams } from '@refactor/common';
import { AppNotification } from '../models';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends HttpService {
    @Post('/notification/list')
    @MapValue(
        res =>
            new PaginatedList<AppNotification>({
                count: res.count,
                docs: res.docs.map(d => new AppNotification(d))
            })
    )
    public getNotificationList(@Body() params: GridParams): Observable<PaginatedList<Notification>> {
        return null;
    }

    @Get('/notification/count')
    public getNewNotificationCount(): Observable<number> {
        return null;
    }

    @Put('/notification/read')
    public markNotificationsRead(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }

    @Put('/notification/dismiss')
    public dismissNotifications(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }

}
