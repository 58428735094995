<h2 mat-dialog-title>{{ (help$ | async)?.name }}</h2>
<div class="help-content" mat-dialog-content>
    <p>{{ (help$ | async)?.content }}</p>
    <iframe 
        *ngFor="let url of (videoUrls$ | async)"
        [src]="url"
        width="560"
        height="315"
        frameborder="0"
        allow="accelerometer;
            autoplay;
            clipboard-write;
            encrypted-media;
            gyroscope;
            picture-in-picture"
        allowfullscreen
    ></iframe> 
</div>
<div class="help-actions" mat-dialog-actions>
    <button
        mat-raised-button
        type="button"
        (click)="close()"
    >
        Close
    </button>
</div>