export enum MaterialExceededRatio {
    Greater50 = '[MaterialExceededRatio] Greater50',
    Greater25 = '[MaterialExceededRatio] Greater25',
    Even = '[MaterialExceededRatio] Even',
    Less25 = '[MaterialExceededRatio] Less25',
    Less50 = '[MaterialExceededRatio] Less50'
}

export namespace MaterialExceededRatio {
    export function toString(activeIngredient: MaterialExceededRatio): string {
        switch (status) {
            case MaterialExceededRatio.Greater50:
                return '50% greater';
            case MaterialExceededRatio.Greater25:
                return '25% greater';
            case MaterialExceededRatio.Even:
                return 'Even';
            case MaterialExceededRatio.Less25:
                return '25% less';
            case MaterialExceededRatio.Less50:
                return '50% less';
            default:
                return '';
        }
    }

    export const members: MaterialExceededRatio[] = [
        MaterialExceededRatio.Greater50,
        MaterialExceededRatio.Greater25,
        MaterialExceededRatio.Even,
        MaterialExceededRatio.Less25,
        MaterialExceededRatio.Less50
    ];
}
