import { BaseDoc } from '../base/base-doc';
import { InputType, ConditionalRuleOperator } from '../../enums';

export class FormConditionalRule {
    public fieldId: string;
    public fieldType: InputType;
    public operator: ConditionalRuleOperator;
    public value: string;

    constructor(params?: Partial<FormConditionalRule>) {
        if (!!params) {
            this.fieldId = params.fieldId || this.fieldId;
            this.fieldType = params.fieldType || this.fieldType;
            this.operator = params.operator || this.operator;
            this.value = params.value || this.value;
        }
    }
}
