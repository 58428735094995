<mat-expansion-panel [expanded]="true" class="notes">
    <mat-expansion-panel-header>Notes</mat-expansion-panel-header>
    <mat-list>
        <mat-list-item *ngFor="let note of (filteredNotes$ | async); let i = index">
            <mat-icon *ngIf="note.noteType == noteType.OneGM" matListIcon class="muted">lock</mat-icon>
            <!-- These extra mat-lines force the list item to resize to content -->
            <div mat-line></div>
            <div mat-line></div>
            <div mat-line></div>
            <h4 matLine>{{ note.note }}</h4>
            <p matLine class="muted">
                {{ note.userIdentity.fullName }} - {{ note.createdDate | date: 'MMMM d, y h:mm a' }}
            </p>
            <button mat-icon-button type="button" (click)="delete.emit(note._id)" *ngIf="!disableDelete">
                <mat-icon>delete</mat-icon>
            </button>
        </mat-list-item>
        <mat-list-item *ngIf="(notes$ | async)?.length === 0">
            There are no notes at this time
        </mat-list-item>
        <mat-list-item *ngIf="(notes$ | async)?.length > 0 &&
            (filteredNotes$ | async)?.length === 0 &&
            !!searchControl.value"
        >
            Search did not match any notes
        </mat-list-item>
    </mat-list>
</mat-expansion-panel>
<form [formGroup]="noteForm">
    <mat-form-field
        *ngIf="(notes$ | async)?.length > 0"
        class="search"
        appearance="outline"
    >
        <mat-label>Search Notes</mat-label>
        <input
            matInput
            placeholder="Search Notes"
            [formControl]="searchControl"
        >
    </mat-form-field>
    <mat-form-field appearance="outline" class="note-field">
        <mat-label>Add your note here</mat-label>
        <textarea matInput formControlName="note"></textarea>
    </mat-form-field>
</form>
<div class="buttons">
    <button
        *ngIf="canCreateAdminNote"
        mat-raised-button
        color="primary"
        type="button"
        (click)="add.emit({note: noteForm.get('note').value, noteType: noteType.OneGM.toString()})"
        [disabled]="!!!noteForm.get('note').value"
    >
        <fa-icon [icon]="['far', 'key']"></fa-icon> Admin Note
    </button>
    <button
        *ngIf="canCreateLandscaperNote"
        mat-raised-button
        color="primary"
        type="button"
        (click)="add.emit({note: noteForm.get('note').value, noteType: noteType.Landscaper.toString()})"
        [disabled]="!!!noteForm.get('note').value"
    >
        <fa-icon [icon]="['far', 'key']"></fa-icon> Add Note
    </button>
    <button
        *ngIf="canCreateClientNote"
        mat-raised-button
        color="primary"
        type="button"
        (click)="add.emit({note: noteForm.get('note').value, noteType: noteType.Client.toString()})"
        [disabled]="!!!noteForm.get('note').value"
    >
        <fa-icon [icon]="['far', 'user']"></fa-icon> Client Note
    </button>
    <button
        *ngIf="canCreateServicePartnerNote"
        mat-raised-button
        color="primary"
        type="button"
        (click)="add.emit({note: noteForm.get('note').value, noteType: noteType.Service_Partner.toString()})"
        [disabled]="!!!noteForm.get('note').value"
    >
        <fa-icon [icon]="['far', 'handshake']"></fa-icon> Partner Note
    </button>
</div>
