import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'rfx-grid-filter-control',
    templateUrl: './grid-filter-control.component.html',
    styleUrls: ['./grid-filter-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridFilterControlComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
