export enum ConditionalLogicType {
    All = 'All',
    One = 'One'
}

export namespace ConditionalLogicType {
    export function toString(type: ConditionalLogicType): string {
        switch (type) {
            case ConditionalLogicType.All:
                return 'All';
            case ConditionalLogicType.One:
                return 'One';
            default:
                return '';
        }
    }

    export const members: ConditionalLogicType[] = [
        ConditionalLogicType.All,
        ConditionalLogicType.One
    ];
}
