import { PackageWithoutIds } from '@gm2/common';

export class Package extends PackageWithoutIds {
    public _id: string = '';
    public serviceIds: string[] = [];

    constructor(params?: Partial<Package>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.serviceIds = Array.isArray(params.serviceIds) ? params.serviceIds : this.serviceIds;
        }
    }
}
