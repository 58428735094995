import { Material } from './Material';

export class AdminCompanyMaterial extends Material {
    public companyId: string;
    public companyName: string;

    constructor(params?: Partial<AdminCompanyMaterial>) {
        super(params);
        if (!!params) {
            this.companyId = params.companyId || this.companyId;
            this.companyName = params.companyName || this.companyName;
        }
    }
}
