import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Note, NoteType } from '@gm2/ui-common';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { combineLatest, Observable, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'gm2-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
    encapsulation: ViewEncapsulation.None
    // necessary to override .mat-form-field-wrapper styling
})
export class NotesComponent implements OnInit, OnDestroy {
    /**
     * Notes observable
     */
    @Input()
    public notes$: Observable<Note[]>;

    @Input()
    public disableDelete: boolean = false;

    @Input()
    public canCreateAdminNote: boolean = true;

    @Input()
    public canCreateClientNote: boolean = false;

    @Input()
    public canCreateServicePartnerNote: boolean = false;

    @Input()
    public canCreateLandscaperNote: boolean = false;

    @Output()
    public delete: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public add: EventEmitter<{note: string, noteType: string}> =
        new EventEmitter<{note: string, noteType: string}>();

    public initialNotes: Note[];

    public filteredNotes$: BehaviorSubject<Note[]> = new BehaviorSubject<Note[]>([]);

    public noteForm: UntypedFormGroup;

    public searchControl: UntypedFormControl = new UntypedFormControl();

    private _unsub: Subject<void> = new Subject<void>();

    public noteType: typeof NoteType = NoteType;

    constructor(private readonly _fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.noteForm = this._fb.group({
            note: ['']
        });

        // Clear form when notes update (successful note created)
        this.notes$.pipe(takeUntil(this._unsub)).subscribe(res => {
            this.noteForm.reset();
            this.initialNotes = res;
            this.searchControl.setValue('', { emitEvent: true});
        });
        this.notes$.subscribe(res => {
            // console.log(res);
        });

        combineLatest(
            this.notes$,
            this.searchControl.valueChanges
        )
        .pipe(takeUntil(this._unsub))
        .subscribe(([notes, searchInput]) => {
            const search = searchInput.toLowerCase();
            const filtered = !!search
                ? notes.filter(n => n.note.toLowerCase().indexOf(search) > -1)
                : this.initialNotes;
            this.filteredNotes$.next(filtered);
        });
    }

    ngOnDestroy(): void {
        this._unsub.next();
        this._unsub.complete();
    }
}
