import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RfxFormField, RfxFormFieldType } from '@refactor/common';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import * as uuidv1_ from 'uuid/v1';

const uuidv1 = uuidv1_;

@Component({
    selector: 'rfx-form-canvas',
    templateUrl: './form-canvas.component.html',
    styleUrls: ['./form-canvas.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormCanvasComponent {
    public readonly RfxFormFieldType: typeof RfxFormFieldType = RfxFormFieldType;

    @Input()
    public canvasFields$: Observable<RfxFormField<string>[]>;

    @Output()
    public editField: EventEmitter<string> = new EventEmitter();

    @Output()
    public deleteField: EventEmitter<string> = new EventEmitter();

    /**
     * Default options to be used for empty dropdown and radio fields.
     */
    private _defaultChoices: { label: string; value: string }[] = [
        {
            label: 'First Choice',
            value: 'First Value'
        },
        {
            label: 'Second Choice',
            value: 'Second Value'
        },
        {
            label: 'Third Choice',
            value: 'Third Value'
        }
    ];

    public drop(event: CdkDragDrop<{ value: RfxFormFieldType; label: string; key?: string }[]>) {
        this.canvasFields$.pipe(take(1)).subscribe(canvasFields => {
            if (event.previousContainer === event.container) {
                moveItemInArray(canvasFields, event.previousIndex, event.currentIndex);
            } else {
                // Create new field from fieldType
                const fieldType = event.previousContainer.data[event.previousIndex].value;
                const fieldLabel = event.previousContainer.data[event.previousIndex].label;
                const fieldKey = event.previousContainer.data[event.previousIndex].key;
                const newField = {
                    _id: uuidv1(),
                    type: fieldType,
                    label: fieldLabel,
                    required: false,
                    description: '',
                    defaultValue: '',
                    placeholder: ''
                } as RfxFormField<string>;

                // If field requires choices, populate default choices
                if (RfxFormFieldType.choicesRequired.includes(fieldType)) {
                    newField.choices = this._defaultChoices;
                }

                if (!!fieldKey) {
                    newField.appDefinedField = fieldKey;
                }

                // Insert new field at specified index
                canvasFields.splice(event.currentIndex, 0, newField);
            }
        });
    }
}
