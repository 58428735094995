import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RfxGridFilterControlComponent, RfxGridFilterSelectOption } from '@refactor/ngx/grid';
import { CompanyService } from '@gm2/ui-common';
import { CompanyType } from '@gm2/common';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { sort } from '@refactor/njs/db/mongo';

@Component({
    selector: 'gm2-grid-filter-service-partner-multiple',
    templateUrl: './grid-filter-service-partner-multiple.component.html',
    styleUrls: ['./grid-filter-service-partner-multiple.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {
            provide: RfxGridFilterControlComponent,
            useExisting: GridFilterServicePartnerMultipleComponent,
        },
    ],
})
export class GridFilterServicePartnerMultipleComponent implements OnInit, OnChanges {
    @Input()
    public name: string = 'servicePartnerIds';
    @Input()
    public placeholder: string = 'Service Partners';
    @Input()
    public options: RfxGridFilterSelectOption[];

    @Input()
    public isAwardedSubcontractors: boolean = false;

    public initialOptions: RfxGridFilterSelectOption[] = null;

    public gridCompanies$: Observable<RfxGridFilterSelectOption[]>;


    public controlGroup: UntypedFormGroup;

    public searchControl: UntypedFormControl = new UntypedFormControl();

    private filterOnSearch(): void {
        if (!this.options || !this.initialOptions)
            return;
        let search = this.searchControl.value;
        if (!search) {
            this.options = this.initialOptions.slice();
            return;
        } else {
            search = search.toLowerCase();
        }
        this.options = this.initialOptions.filter(company => company.label.toLowerCase()
            .indexOf(search) > -1);
    }

    private _onDestroy: Subject<void> = new Subject<void>();

    constructor(
        private _companyService: CompanyService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {

        this.controlGroup = new UntypedFormGroup({
            values: new UntypedFormControl(''),
        });

        this.gridCompanies$
            .pipe(takeUntil(this._onDestroy))
            .subscribe(companies => {
                if (!!companies) {
                    this.options = companies;
                    if (this.initialOptions === null
                        || this.options.length > this.initialOptions.length)
                        this.initialOptions = companies;
                }
                this._changeDetectorRef.detectChanges();
            });

        this.searchControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterOnSearch();
            });
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.gridCompanies$ = this.isAwardedSubcontractors ?
            this._companyService.getAwardedSubcontractors()
                .pipe(
                    map(companies => companies.map(company => ({
                            value: company._id,
                            label: company.profile.name,
                        }))
                        .sort((a, b) => {
                            if (a.label > b.label) {
                                return 1;
                            } else if (a.label < b.label) {
                                return -1;
                            }
                            return 0;
                        })),
                ) :
            this._companyService.getSimpleList(CompanyType.Service_Partner)
                .pipe(
                    map(companies => companies.map(company => ({
                        value: company._id,
                        label: company.profile.name,
                    }))),
                );
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}
