import { MappingCategoryWithoutIds } from '@gm2/common';

export class MappingCategory extends MappingCategoryWithoutIds {
    public _id: string = '';

    constructor(params?: Partial<MappingCategory>) {
        super(params);
        this._id = params._id || this._id;
    }
}
