import { BaseDoc } from '../base/base-doc';
import { FormSettings } from './form-settings';
import { FormField } from './form-field';
import { FormMetrics } from './form-metrics';
import { FormPublishStatus } from '../../enums';

export class FormWithoutIds extends BaseDoc {
    public _id: any;
    public settings: FormSettings;
    public fields: FormField[] = [];
    public metrics: FormMetrics;
    public name: string;
    public publishStatus: FormPublishStatus;
    public publishedDate: Date;
    public migrationStatus: number;
    public deletedBy: string;
    public deleteDate: Date;

    constructor(params?: Partial<FormWithoutIds>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.settings = !!params.settings ? new FormSettings(params.settings) : this.settings;
            this.fields = !!params.fields ? params.fields.map(f => new FormField(f)) : this.fields;
            this.metrics = !!params.metrics ? new FormMetrics(params.metrics) : this.metrics;
            this.name = params.name || this.name;
            this.publishStatus = params.publishStatus || this.publishStatus;
            this.publishedDate = params.publishedDate || this.publishedDate;
            this.migrationStatus = params.migrationStatus || this.migrationStatus;
            this.deletedBy = params.deletedBy || this.deletedBy;
            this.deleteDate = params.deleteDate || this.deleteDate;
        }
    }
}
