export enum NotificationStatus {
    New = '[NotificationStatus] new',
    Read = '[NotificationStatus] read',
    Dismissed = '[NotificationStatus] dismissed'
}

export namespace NotificationStatus {
    export function toString(type: NotificationStatus): string {
        switch (type) {
            case NotificationStatus.New:
                return 'New';
            case NotificationStatus.Read:
                return 'Read';
            case NotificationStatus.Dismissed:
                return 'Dismissed';
            default:
                return '';
        }
    }

    export const members: NotificationStatus[] = [
        NotificationStatus.New,
        NotificationStatus.Read,
        NotificationStatus.Dismissed,
    ];
}
