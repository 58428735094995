import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridComponent, GridDetailViewOutlet } from './grid/grid.component';
import { GridActionBarComponent } from './grid-action-bar/grid-action-bar.component';
import { GridActionBarControlComponent } from './grid-action-bar-control/grid-action-bar-control.component';
import { GridFilterComponent } from './grid-filter/grid-filter.component';
import { GridFilterControlComponent } from './grid-filter-control/grid-filter-control.component';
import { GridFilterDateControlComponent } from './grid-filter-date-control/grid-filter-date-control.component';
// import { GridFilterDateRangeComponent } from './grid-filter-date-range/grid-filter-date-range.component';
import {
    GridListComponent,
    GridRowOutlet
} from './grid-list/grid-list.component';
import {
    GridListItemComponent,
    GridListItemDef
} from './grid-list-item/grid-list-item.component';
import { GridPaginatorComponent } from './grid-paginator/grid-paginator.component';
import {
    GridDetailViewComponent,
    GridDetailViewItemDef,
    GridDetailViewHeader,
    GridDetailViewContent,
    GridDetailViewFooter
} from './grid-detail-view/grid-detail-view.component';
import { GridActionBarDeleteControlComponent } from './grid-action-bar-delete-control/grid-action-bar-delete-control.component';
import { GridActionBarTagControlComponent } from './grid-action-bar-tag-control/grid-action-bar-tag-control.component';
import { GridFilterSearchControlComponent } from './grid-filter-search-control/grid-filter-search-control.component';
import { GridFilterGroupComponent } from './grid-filter-group/grid-filter-group.component';
import { RfxGridCustomControl } from './GridControl';
import { RfxGridItem } from './Row';
import {
    GridRowComponent,
    GridListItemOutlet
} from './grid-row/grid-row.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GridHeaderComponent } from './grid-header/grid-header.component';
import { RfxGridDataSource } from './GridDataSource';
import { MatButtonModule } from '@angular/material/button';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import {
    GridFilterSelectControlComponent,
    RfxGridFilterSelectOption
} from './grid-filter-select-control/grid-filter-select-control.component';
import { GridFilterClearButtonComponent } from './grid-filter-clear-button/grid-filter-clear-button.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatSelectModule,
        MatDialogModule,
        MatTooltipModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule
    ],
    declarations: [
        GridComponent,
        GridDetailViewOutlet,
        GridActionBarComponent,
        GridActionBarControlComponent,
        GridFilterComponent,
        GridFilterControlComponent,
        GridListComponent,
        GridRowOutlet,
        GridListItemComponent,
        GridListItemDef,
        GridPaginatorComponent,
        GridDetailViewComponent,
        GridDetailViewItemDef,
        GridDetailViewHeader,
        GridDetailViewContent,
        GridDetailViewFooter,
        GridActionBarDeleteControlComponent,
        GridActionBarTagControlComponent,
        GridFilterSearchControlComponent,
        GridFilterGroupComponent,
        RfxGridCustomControl,
        RfxGridItem,
        GridRowComponent,
        GridListItemOutlet,
        GridHeaderComponent,
        GridFilterSelectControlComponent,
        GridFilterDateControlComponent,
        GridFilterClearButtonComponent
        // GridFilterDateRangeComponent
    ],
    exports: [
        GridComponent,
        GridDetailViewOutlet,
        GridActionBarComponent,
        GridActionBarControlComponent,
        GridFilterComponent,
        GridFilterControlComponent,
        GridListComponent,
        GridRowOutlet,
        GridListItemComponent,
        GridListItemDef,
        GridPaginatorComponent,
        GridDetailViewComponent,
        GridDetailViewItemDef,
        GridDetailViewHeader,
        GridDetailViewContent,
        GridDetailViewFooter,
        GridActionBarDeleteControlComponent,
        GridActionBarTagControlComponent,
        GridFilterSearchControlComponent,
        GridFilterGroupComponent,
        RfxGridCustomControl,
        RfxGridItem,
        GridRowComponent,
        GridListItemOutlet,
        GridHeaderComponent,
        GridFilterSelectControlComponent,
        GridFilterDateControlComponent,
        GridFilterClearButtonComponent
        // GridFilterDateRangeComponent
    ]
})
class GridModule {}

export {
    GridModule as RfxGridModule,
    GridComponent as RfxGridComponent,
    GridDetailViewOutlet as RfxGridDetailViewOutlet,
    GridActionBarComponent as RfxGridActionBarComponent,
    GridActionBarControlComponent as RfxActionBarControlComponent,
    GridFilterComponent as RfxGridFilterComponent,
    GridFilterControlComponent as RfxGridFilterControlComponent,
    GridFilterDateControlComponent as RfxGridFilterDateComponent,
    GridFilterClearButtonComponent as RfxGridFilterClearButtonComponent,
    // GridFilterDateRangeComponent as RfxGridFilterDateRangeComponent,
    GridListComponent as RfxGridListComponent,
    GridRowOutlet as RfxGridRowOutlet,
    GridListItemComponent as RfxGridListItemComponent,
    GridListItemDef as RfxGridListItemDef,
    GridPaginatorComponent as RfxGridPaginatorComponent,
    GridDetailViewComponent as RfxGridDetailViewComponent,
    GridDetailViewItemDef as RfxGridDetailViewItemDef,
    GridDetailViewHeader as RfxGridDetailViewHeader,
    GridDetailViewContent as RfxGridDetailViewContent,
    GridDetailViewFooter as RfxGridDetailViewFooter,
    GridActionBarDeleteControlComponent as RfxGridActionBarDeleteControlComponent,
    GridActionBarTagControlComponent as RfxGridActionBarTagControlComponent,
    GridFilterSearchControlComponent as RfxGridFilterSearechControlComponent,
    GridFilterGroupComponent as RfxGridFilterGroupComponent,
    GridFilterSelectControlComponent as RfxGridFilterSelectControlComponent,
    RfxGridFilterSelectOption,
    RfxGridCustomControl,
    RfxGridItem,
    GridRowComponent as RfxGridRowComponent,
    GridListItemOutlet as RfxGridListItemOutlet,
    GridHeaderComponent as RfxGridHeaderComponent,
    RfxGridDataSource
};
