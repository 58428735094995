<h2 mat-dialog-title>Pricing Quick Upload (.csv only)</h2>
<div mat-dialog-content>
    <p>
        Data should be in the following format:
        <br />
        <!-- <strong>
            Location Name, Package Name, Service Name, Client Price, Requested Price, Material
            Price, Client Fee, Custom Label.
        </strong> -->
        <strong *ngIf="isClientService(); else packageService">
            Location Name, Service Name, Client Price, Material Price, Client Fee.
        </strong>
        <ng-template #packageService>
            <strong>
                Location Name, Package Name, <span *ngIf="parentControl">Parent Service Name,</span> Service Name, Client Price, Material Price, Client Fee.
            </strong>
        </ng-template>
        <br />
        <a *ngIf="isClientService(); else packageServiceDownload" download="QuickUploadTemplateClient.csv" href="assets/QuickUploadTemplateClient.csv" target="_blank">
            Download the Template
        </a>
        <ng-template #packageServiceDownload>
            <a download="QuickUploadTemplate.csv" href="assets/QuickUploadTemplate.csv" target="_blank">
                Download the Template
            </a>
        </ng-template>
        <br />
        Leave any field blank to not have it update
    </p>
    <form #csvuploadform>
        <rfx-file-upload
            [accepts]="['.csv']"
            maxfilesize="30MB"
            name="file">
        </rfx-file-upload>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onSubmit()">
        Upload Pricing Data
    </button>
</div>
