import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RfxGridFilterControlComponent, RfxGridFilterSelectOption } from '@refactor/ngx/grid';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { WorkOrderStatus, CompanyType } from '@gm2/common';
import { Company } from '@gm2/ui-common';

@Component({
    selector: 'gm2-grid-filter-workorder-progress-multiple',
    templateUrl: './grid-filter-workorder-progress-multiple.component.html',
    styleUrls: ['./grid-filter-workorder-progress-multiple.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: RfxGridFilterControlComponent,
            useExisting: GridFilterWorkOrderProgressMultipleComponent
        }
    ]
})
export class GridFilterWorkOrderProgressMultipleComponent implements OnInit {
    @Input()
    public name: string = 'workOrderStatuses';
    @Input()
    public placeholder: string = 'Progress';
    @Input('company')
    public company$: Observable<Company>;

    public workOrderStatusOptions$: BehaviorSubject<RfxGridFilterSelectOption[]> =
        new BehaviorSubject([]);

    public controlGroup: UntypedFormGroup = new UntypedFormGroup({
        values: new UntypedFormControl(null)
    });

    constructor() {}

    ngOnInit(): void {
        this.company$.pipe(
            take(1),
            map(c => {
                switch (c.type) {
                    case CompanyType.Admin:
                        return WorkOrderStatus.members.filter(s => s !== WorkOrderStatus.Template);
                    case CompanyType.Client:
                        return WorkOrderStatus.clientUiMembers;
                    case CompanyType.Service_Partner:
                        return WorkOrderStatus.servicePartnerUiMembers;
                    case CompanyType.Landscaper:
                        return WorkOrderStatus.members.filter(s => s !== WorkOrderStatus.Template);
                    default:
                        throw new Error(`Unsupported company type ${c.type}`);
                }
            }),
            map(statuses => [
                ...statuses.map(status => ({
                    value: status,
                    label: WorkOrderStatus.toString(status)
                }))
            ]),
            tap(options => this.workOrderStatusOptions$.next(options))
        ).subscribe();
    }
}
