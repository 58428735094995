import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RfxFormFieldType, SelectOption } from '@refactor/common';
import { AppDefinedFieldService } from '../../services/app-defined-field.service';

interface TraySelectOption extends SelectOption {
    value: RfxFormFieldType;
    label: string;
    key?: string;
}

@Component({
    selector: 'rfx-form-field-tray',
    templateUrl: './form-field-tray.component.html',
    styleUrls: ['./form-field-tray.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldTrayComponent implements OnInit {
    public readonly RfxFormFieldType: typeof RfxFormFieldType = RfxFormFieldType;

    public trayFields: TraySelectOption[] = RfxFormFieldType.members
        .filter(f => f !== RfxFormFieldType.ApplicationDefined)
        .map(f => ({
            value: f,
            label: RfxFormFieldType.toString(f)
        }));

    constructor(private readonly _appDefinedFieldsService: AppDefinedFieldService) {}

    ngOnInit(): void {
        const appDefinedSelectOptions: SelectOption<
            string
        >[] = this._appDefinedFieldsService.getFieldSelectOptions();

        appDefinedSelectOptions.forEach(opt => {
            this.trayFields.push({
                value: RfxFormFieldType.ApplicationDefined,
                label: opt.label,
                key: opt.value
            });
        });
    }

    /** Predicate function that doesn't allow items to be dropped into a list. */
    public noReturnPredicate(): boolean {
        return false;
    }
}
