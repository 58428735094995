import { Injectable } from '@angular/core';
import {
    HttpService,
    Post,
    MapClass,
    MapValue,
    Body,
    Get,
    Path,
    Delete,
    Put,
    Query,
    Header,
    ResponseType,
} from '@refactor/ngx/http';
import {
    Service,
    WorkOrderCreateDto, // TODO: <-- delete
    WorkOrderEditDto, // TODO: <-- delete
    WorkOrderAdminDto,
    WorkOrderClientDto,
    WorkOrderServicePartnerDto,
    WorkOrderStatusChangeDto,
    WorkOrder,
    WorkOrderHistory,
    WorkOrderSimple,
} from '../models';
import { Observable, BehaviorSubject, ReplaySubject, interval } from 'rxjs';
import { take, finalize, debounceTime, debounce } from 'rxjs/operators';
import { PaginatedList, GridParams } from '@refactor/common';
import * as jsonToFormData from 'json-form-data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WorkOrderMultiGridData, WorkOrderStatus, WorkOrderActiveStatus } from '@gm2/common';

@Injectable({
    providedIn: 'root',
})
export class WorkOrderService extends HttpService {

    /*
        Observables to support multi work order grid view
    */
    private _refreshingMultiGrid$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    private _multiGridData: { [key: string]: ReplaySubject<PaginatedList<WorkOrderSimple>> } = {};
    private _multiGridTwelveMonthCounts: { [key: string]: ReplaySubject<number> } = {};
    private _multiGridTwelveMonthValues: { [key: string]: ReplaySubject<number> } = {};
    private readonly _multiKeys: string[] = WorkOrderStatus.members.map(m => m as string)
        .concat(WorkOrderActiveStatus.members.map(m => m as string));

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
        // initialize observables supporting multi work order grid
        for(const k of this._multiKeys) {
            this._multiGridData[k] =
                new ReplaySubject<PaginatedList<WorkOrderSimple>>(1);
            this._multiGridTwelveMonthCounts[k] = new ReplaySubject<number>(1);
            this._multiGridTwelveMonthValues[k] = new ReplaySubject<number>(1);
        }
    }

    /*
        Retrieve paginated list observable corresponding to work order status
        embedded in GridParams argument. If there is no refresh currently
        happening, refresh data for all multi grids and for twelve month
        counts and values
    */
    public getWorkOrderMultiGridData(
        params: GridParams,
    ): Observable<PaginatedList<WorkOrderSimple>> {
        const status: WorkOrderStatus | WorkOrderActiveStatus =
            (!!params.filters['workOrderStatuses'])
                ? params.filters['workOrderStatuses'].values[0]
                : params.filters['activeStatus'].value;
        this._refreshingMultiGrid$
            .pipe(take(1))
            .subscribe(refreshing => {
                if (!refreshing) {
                    this._refreshingMultiGrid$.next(true);
                    this._updateMultiGridData(params)
                        .pipe(take(1))
                        .subscribe(data => {
                            for(const k of this._multiKeys) {
                                let docs, count, twelveCount, twelveValue;
                                if (!!data && !!data.data[k]) {
                                    docs = data.data[k].docs.map(d => new WorkOrderSimple(d));
                                    count = data.data[k].count;
                                    twelveCount = data.data[k].twelveMonthCount;
                                    twelveValue = data.data[k].twelveMonthValue;
                                } else {
                                    docs = [];
                                    count = 0;
                                    twelveCount = 0;
                                    twelveValue = 0;
                                }
                                this._multiGridTwelveMonthCounts[k].next(twelveCount);
                                this._multiGridTwelveMonthValues[k].next(twelveValue);
                                this._multiGridData[k].next(
                                    new PaginatedList<WorkOrderSimple>({
                                        docs: docs,
                                        count: count,
                                    }),
                                );
                            }
                            this._refreshingMultiGrid$.next(false);
                        });
                }
            });
        return this._multiGridData[status];
    }

    public getStatusTwelveMonthCount(
        status: WorkOrderStatus | WorkOrderActiveStatus,
    ): Observable<number> {
        return this._multiGridTwelveMonthCounts[status];
    }

    public getStatusTwelveMonthValue(
        status: WorkOrderStatus | WorkOrderActiveStatus,
    ): Observable<number> {
        return this._multiGridTwelveMonthValues[status];
    }

    @Post('/workOrder/list/simple/multi')
    private _updateMultiGridData(
        @Body() params: GridParams,
    ): Observable<WorkOrderMultiGridData> {
        return null;
    }

    private serializeWorkOrderData(
        dto: WorkOrderCreateDto | WorkOrderAdminDto | WorkOrderClientDto | WorkOrderServicePartnerDto,
        images: Blob[],
        ogmDocs: Blob[],
        clientDocs: Blob[],
        spDocs: Blob[],
    ): FormData {
        const formData = jsonToFormData(dto);

        if (!!images) {
            for(let i = 0; i < images.length; ++i) {
                formData.append('images[]', images[i]);
            }
        }

        if (!!ogmDocs) {
            for(let d = 0; d < ogmDocs.length; ++d) {
                formData.append('ogmDocs[]', ogmDocs[d]);
            }
        }

        if (!!clientDocs) {
            for(let d = 0; d < clientDocs.length; ++d) {
                formData.append('clientDocs[]', clientDocs[d]);
            }
        }

        if (!!spDocs) {
            for(let d = 0; d < spDocs.length; ++d) {
                formData.append('spDocs[]', spDocs[d]);
            }
        }

        return formData;
    }

    public createWorkOrder(
        dto: WorkOrderCreateDto | WorkOrderAdminDto | WorkOrderClientDto | WorkOrderServicePartnerDto,
        images: Blob[],
        ogmDocs: Blob[],
        clientDocs: Blob[],
        spDocs: Blob[] = null, // TODO: Make mandatory
    ): Observable<Object> {
        return this._httpClient.post(
            '/workOrder',
            this.serializeWorkOrderData(dto, images, ogmDocs, clientDocs, spDocs),
        );
    }

    public updateWorkOrder(
        id: string,
        dto: WorkOrderCreateDto | WorkOrderAdminDto | WorkOrderServicePartnerDto,
        images: Blob[],
        ogmDocs: Blob[],
        clientDocs: Blob[],
        spDocs: Blob[] = null, // TODO: Make mandatory
    ): Observable<Object> {
        return this._httpClient.put(
            '/workOrder/' + id,
            this.serializeWorkOrderData(dto, images, ogmDocs, clientDocs, spDocs),
        );
    }

    @Get('/workOrder/:workOrderId')
    @MapClass(WorkOrder)
    public getWorkOrder(@Path('workOrderId') id: string): Observable<WorkOrder> {
        return null;
    }

    @Get('/workOrder/:workOrderId/history')
    public getWorkOrderHistory(@Path('workOrderId') id: string): Observable<WorkOrderHistory[]> {
        return null;
    }

    @Put('/workOrder/:workOrderid/status/created')
    public markWorkOrderCreated(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/pending')
    public sendWorkOrderPending(
        @Path('workOrderId') id: string,
    ): Observable<Object> {
        return null;
    }


    @Put('/workOrder/:workOrderId/status/review')
    public sendWorkOrderForReview(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/approve')
    public approveWorkOrder(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/approveRemote')
    public approveWorkOrderRemote(
        @Header('Authorization') token: string,
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<any> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/servicePartnerReject')
    public rejectServicePartnerWorkOrder(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/clientReject')
    public rejectClientWorkOrder(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/reject')
    public rejectWorkOrder(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/resubmit')
    public resubmitWorkOrder(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/complete')
    public completeWorkOrder(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/unapprove')
    public unapproveWorkOrder(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/post')
    public postWorkOrder(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/billed')
    public billWorkOrder(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/hold')
    public holdWorkOrder(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/release')
    public releaseWorkOrder(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/activate')
    public activateWorkOrder(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/status/deactivate')
    public deactivateWorkOrder(
        @Path('workOrderId') id: string,
        @Body() dto: WorkOrderEditDto | WorkOrderStatusChangeDto,
    ): Observable<Object> {
        return null;
    }

    @Put('/workOrder/:workOrderId/phaseStatus/:phaseId/servicepartneroffered')
    public setPhaseServicePartnerOffered(
        @Path('workOrderId') workOrderId: string,
        @Path('phaseId') phaseId: string,
    ): Observable<void> {
        return null;
    }

    @Put('/workOrder/:workOrderId/phaseStatus/:phaseId/accepted')
    public setPhaseAccepted(
        @Path('workOrderId') workOrderId: string,
        @Path('phaseId') phaseId: string,
    ): Observable<void> {
        return null;
    }

    @Put('/workOrder/:workOrderId/phaseStatus/:phaseId/servicepartnerrejected')
    public setPhaseServicePartnerRejected(
        @Path('workOrderId') workOrderId: string,
        @Path('phaseId') phaseId: string,
    ): Observable<void> {
        return null;
    }

    @Put('/workOrder/:workOrderId/phaseStatus/:phaseId/complete')
    public completePhase(
        @Path('workOrderid') workOrderId: string,
        @Path('phaseId') phaseId: string,
        @Body() dto: { completionDate: Date },
    ): Observable<void> {
        return null;
    }

    @Post('/workOrder/list/simple')
    @MapValue(
        res =>
            new PaginatedList<WorkOrderSimple>({
                count: res.count,
                docs: res.docs.map(d => new WorkOrderSimple(d)),
            }),
    )
    public getWorkOrderSimpleList(
        @Body() params: GridParams,
    ): Observable<PaginatedList<WorkOrderSimple>> {
        return null;
    }

    @Post('/workOrder/list/template/simple')
    @MapValue(
        res =>
            new PaginatedList<WorkOrderSimple>({
                count: res.count,
                docs: res.docs.map(d => new WorkOrderSimple(d)),
            }),
    )
    public getWorkOrderTemplateSimpleList(
        @Body() params: GridParams,
    ): Observable<PaginatedList<WorkOrderSimple>> {
        return null;
    }

    @Put('/workOrder/delete')
    public deleteWorkOrderTemplates(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }

    @Post('/workOrder/csvExport', {
        responseType: ResponseType.Text,
    })
    public workOrderCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }

    @Get('/workOrder/:workOrderId')
    @MapClass(WorkOrder)
    public getWorkOrderRemote(
        @Header('Authorization') token: string,
        @Path('workOrderId') id: string,
    ): Observable<WorkOrder> {
        return null;
    }

    @Put('/workOrder/:workOrderId/phaseStatus/:phaseId/accepted')
    public setPhaseAcceptedRemote(
        @Header('Authorization') token: string,
        @Path('workOrderId') workOrderId: string,
        @Path('phaseId') phaseId: string,
    ): Observable<void> {
        return null;
    }

    @Put('/workOrder/:workOrderId/phaseStatus/:phaseId/servicepartnerrejected')
    public setPhaseServicePartnerRejectedRemote(
        @Header('Authorization') token: string,
        @Path('workOrderId') workOrderId: string,
        @Path('phaseId') phaseId: string,
    ): Observable<void> {
        return null;
    }

    @Post('/workOrder/:locationId/list')
    @MapValue(
        res =>
            new PaginatedList<WorkOrderSimple>({
                count: res.count,
                docs: res.docs.map(d => new WorkOrderSimple(d)),
            }),
    )
    public getWorkOrdersByLocationId(
        @Path('locationId') locationId: string,
    ): Observable<PaginatedList<WorkOrderSimple>> {
        return null;
    }
}
