import { InvoiceTotals, TimesheetWithoutIds } from '../../models';
import { BillingType } from '../../enums';

export function calculateInvoiceTotals<T extends TimesheetWithoutIds>(
    timesheets: T[]
): InvoiceTotals {
    let containsMonthly: boolean = false;
    let containsNonMonthly: boolean = false;
    timesheets.forEach(timesheet =>
        timesheet.snapShot.rfp.awardedBid.bid.billingType === BillingType.Monthly
            ? (containsMonthly = true)
            : (containsNonMonthly = true)
    );
    if (containsMonthly && containsNonMonthly) {
        throw new Error(
            'calculations for invoice can not be done when there is a mix of monthly and non-monthly timesheets'
        );
    }

    return containsMonthly
        ? calculateInvoiceMonthlyTotals(timesheets)
        : calculateInvoiceNonMonthlyTotals(timesheets);
}

export function calculateInvoiceMonthlyTotals<T extends TimesheetWithoutIds>(
    timesheets: T[]
): InvoiceTotals {
    const totals: InvoiceTotals = new InvoiceTotals({ total: 0, clientTotal: 0 });

    const rfpDict: { [key: string]: boolean } = {};
    timesheets.forEach(timesheet => {
        const currentRfp: string = timesheet.rfpIdentity._id.toString();
        // only want to sum the totals from one timesheet for each rfp.
        // monthly rfps are only charged one time per month no matter how many times the service was performed
        if (!rfpDict[currentRfp]) {
            totals.total += timesheet.charges.total;
            totals.clientTotal += timesheet.charges.clientCompanyTotal;
            rfpDict[currentRfp] = true;
        }
    });
    return totals;
}

export function calculateInvoiceNonMonthlyTotals<T extends TimesheetWithoutIds>(
    timesheets: T[]
): InvoiceTotals {
    const totals: InvoiceTotals = new InvoiceTotals({ total: 0, clientTotal: 0 });

    // sum each timesheets total and clientCompanyTotal
    timesheets.reduce((accumulator, timesheet) => {
        accumulator.total += timesheet.charges.total;
        accumulator.clientTotal += timesheet.charges.clientCompanyTotal;

        return accumulator;
    }, totals);

    return totals;
}
