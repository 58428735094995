import { WorkOrderEquipmentWithoutIds } from '@gm2/common';

export class WorkOrderEquipment extends WorkOrderEquipmentWithoutIds {
	public _id: string = '';

	constructor(params?: Partial<WorkOrderEquipment>){
		super(params);
		if(!!params){
			this._id = params._id || this._id;
		}
	}
}