import { RoleWithoutIds } from '@gm2/common';
import { Company } from '../company/Company';
import { CompanyProfile } from '@gm2/api-common';

export class Role extends RoleWithoutIds {
    public _id: string = '';
    public companyId: string = '';
    public companyInfo: Company = null;
    public companyProfile: CompanyProfile = null;

    constructor(params?: Partial<Role>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.companyId = params.companyId || this.companyId;
            this.companyInfo = params.companyInfo || this.companyInfo;
            this.companyProfile = params.companyProfile || this.companyProfile;
        }
    }
}
