import { Permission } from '@gm2/common';
import PermissionRule = Permission.PermissionRule;

export class ChildMenuItem {
    public label: string = '';
    public active: boolean = false;
    public route: string = null;
    public permission: Permission | Permission[] | null = null;
    public permissionRule: PermissionRule = PermissionRule.HasAll;

    constructor(params?: Partial<ChildMenuItem>) {
        if (!!params) {
            this.label = params.label || this.label;
            this.active = params.active || this.active;
            this.route = params.route || this.route;
            this.permission = params.permission || this.permission;
            this.permissionRule = params.permissionRule || this.permissionRule;
        }
    }
}
