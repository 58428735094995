import { isNull } from '../../utils';

export enum YearType {
    Year2016 = '[YearType] 2016',
    Year2017 = '[YearType] 2017',
    Year2018 = '[YearType] 2018',
    Year2019 = '[YearType] 2019',
    Year2020 = '[YearType] 2020',
    Year2021 = '[YearType] 2021',
    Year2022 = '[YearType] 2022',
    Year2023 = '[YearType] 2023'
}

export namespace YearType {
    export function toString(type: YearType): string {
        switch (type) {
            case YearType.Year2016:
                return '2016';
            case YearType.Year2017:
                return '2017';
            case YearType.Year2018:
                return '2018';
            case YearType.Year2019:
                return '2019';
            case YearType.Year2020:
                return '2020';
            case YearType.Year2021:
                return '2021';
            case YearType.Year2022:
                return '2022';
            case YearType.Year2023:
                return '2023';
            default:
                return '';
        }
    }

    export const members: YearType[] = [
        YearType.Year2016,
        YearType.Year2017,
        YearType.Year2018,
        YearType.Year2019,
        YearType.Year2020,
        YearType.Year2021,
        YearType.Year2022,
        YearType.Year2023
    ];
}
