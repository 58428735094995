import { ApproveType } from '@gm2/common';

export class ServiceApproveDto {
    public serviceId: string;
    public companyId: string;
    public approveStatus: ApproveType;

    constructor(targetId: string, companyId: string, approveStatus: ApproveType) {
        this.serviceId = targetId;
        this.companyId = companyId;
        this.approveStatus = approveStatus;
    }
}
