import { ServiceClassification } from '../../enums';

export class ServiceChildSettings {
    public hasClientFee: boolean = false;
    public hasMaterial: boolean = false;

    constructor(params?: Partial<ServiceChildSettings>) {
        if (!!params) {
            this.hasClientFee = params.hasClientFee || this.hasClientFee;
            this.hasMaterial = params.hasMaterial || this.hasMaterial;
        }
    }
}
