import { ServiceWithoutIds } from '../service/service';

export class PackageSnapShotWithoutIds {
    public _id: any;
    public name: string;
    public description: string;
    public serviceTypeId: any;
    public services: ServiceWithoutIds[] = [];
    public contractName?: string

    constructor(params?: Partial<PackageSnapShotWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.name = params.name || this.name;
            this.description = params.description || this.description;
            this.serviceTypeId = params.serviceTypeId || this.serviceTypeId;
            this.services = Array.isArray(params.services)
                ? params.services.map(l => new ServiceWithoutIds(l))
                : this.services;
            this.contractName = params.contractName || this.contractName;
        }
    }
}
