export enum WeatherConditionType {
    BlizzardConditions = '[WeatherConditionType] BlizzardConditions',
    BlowingSnow = '[WeatherConditionType] BlowingSnow',
    Clear = '[WeatherConditionType] Clear',
    Foggy = '[WeatherConditionType] Foggy',
    Overcast = '[WeatherConditionType] Overcast',
    Raining = '[WeatherConditionType] Raining',
    RainingFreezing = '[WeatherConditionType] RainingFreezing',
    RainingSnowMix = '[WeatherConditionType] RainingSnowMix',
    Snowing = '[WeatherConditionType] Snowing',
    SnowingHeavily = '[WeatherConditionType] SnowingHeavily',
    SnowingLightly = '[WeatherConditionType] SnowingLightly'
}

export namespace WeatherConditionType {
    export function toString(type: WeatherConditionType): string {
        switch (type) {
            case WeatherConditionType.BlizzardConditions:
                return 'Blizzard Conditions';
            case WeatherConditionType.BlowingSnow:
                return 'Blowing Snow';
            case WeatherConditionType.Clear:
                return 'Clear';
            case WeatherConditionType.Foggy:
                return 'Foggy';
            case WeatherConditionType.Overcast:
                return 'Overcast';
            case WeatherConditionType.Raining:
                return 'Raining';
            case WeatherConditionType.RainingFreezing:
                return 'Raining Freezing';
            case WeatherConditionType.RainingSnowMix:
                return 'Raining Snow Mix';
            case WeatherConditionType.Snowing:
                return 'Snowing';
            case WeatherConditionType.SnowingHeavily:
                return 'Snowing Heavily';
            case WeatherConditionType.SnowingLightly:
                return 'Snowing Lightly';
            default:
                return '';
        }
    }

    export const members: WeatherConditionType[] = [
        WeatherConditionType.BlizzardConditions,
        WeatherConditionType.BlowingSnow,
        WeatherConditionType.Clear,
        WeatherConditionType.Foggy,
        WeatherConditionType.Overcast,
        WeatherConditionType.Raining,
        WeatherConditionType.RainingFreezing,
        WeatherConditionType.RainingSnowMix,
        WeatherConditionType.Snowing,
        WeatherConditionType.SnowingHeavily,
        WeatherConditionType.SnowingLightly
    ];
}
