import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RfxGridFilterControlComponent, RfxGridFilterSelectOption } from '@refactor/ngx/grid';
import { CompanyService } from '@gm2/ui-common';
import { CompanyType } from '@gm2/common';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'gm2-grid-filter-client-multiple',
    templateUrl: './grid-filter-client-multiple.component.html',
    styleUrls: ['./grid-filter-client-multiple.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {
            provide: RfxGridFilterControlComponent,
            useExisting: GridFilterClientMultipleComponent
        }
    ]
})
export class GridFilterClientMultipleComponent implements OnInit {
    @Input()
    public name: string = 'clientIds';
    @Input()
    public placeholder: string = 'Clients';
    @Input()
    public options: RfxGridFilterSelectOption[];

    public initialOptions: RfxGridFilterSelectOption[] = null;

    public gridCompanies$: Observable<
        RfxGridFilterSelectOption[]
    > = this._companyService.getSimpleList(CompanyType.Client).pipe(
        map(companies =>
            companies.map(company => ({
                value: company._id,
                label: company.profile.name
            }))
        )
    );

    public controlGroup: UntypedFormGroup;

    public searchControl: UntypedFormControl = new UntypedFormControl();

    private filterOnSearch(): void {
        if (!this.options || !this.initialOptions) return;
        let search = this.searchControl.value;
        if (!search) {
            this.options = this.initialOptions.slice();
            return;
        } else {
            search = search.toLowerCase();
        }
        this.options = this.initialOptions.filter(
            company => company.label.toLowerCase().indexOf(search) > -1
        );
    }

    private _onDestroy: Subject<void> = new Subject<void>();

    constructor(
        private _companyService: CompanyService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.controlGroup = new UntypedFormGroup({
            values: new UntypedFormControl('')
        });
        this.gridCompanies$
            .pipe(takeUntil(this._onDestroy))
            .subscribe(companies => {
            if (!!companies) {
                this.options = companies;
                if (
                    this.initialOptions === null ||
                    this.options.length > this.initialOptions.length
                ) {
                    this.initialOptions = companies;
                }
            }
            this._changeDetectorRef.detectChanges();
        });
        this.searchControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterOnSearch();
        });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}
