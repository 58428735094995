import { State, Selector } from '@ngxs/store';
import { ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { AuthState, AuthStateModel } from './auth.state';
import { CompanyState, CompanyStateModel } from './company.state';
import { UserState, UserStateModel } from './user.state';
import { Injectable } from '@angular/core';

interface ComplexStateModel {}

@State<ComplexStateModel>({
    name: 'joinedSelectors',
    defaults: undefined
})
@Injectable()
export class ComplexState {
    @Selector([AuthState, CompanyState, UserState])
    @ImmutableSelector()
    public static authenticated(
        authState: AuthStateModel,
        companyState: CompanyStateModel,
        userState: UserStateModel
    ): boolean {
        return !!authState.token && !!companyState.company && !!userState.user;
    }
}
