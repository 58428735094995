<div
    cdkDropList
    class="canvas"
    (cdkDropListDropped)="drop($event)"
    [cdkDropListData]="canvasFields$ | async"
>
    <ng-container *ngIf="(canvasFields$ | async).length > 0; else noFields">
        <div class="canvas-field" cdkDrag *ngFor="let field of (canvasFields$ | async)">
            <div class="actions">
                <button mat-icon-button (click)="editField.emit(field._id)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button (click)="deleteField.emit(field._id)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <rfx-form-field-generator [field]="field" [preview]="true"></rfx-form-field-generator>
        </div>
    </ng-container>
    <ng-template #noFields>
        <div class="no-fields-placeholder">
            <strong>Drag and drop fields here</strong>
        </div>
    </ng-template>
</div>
