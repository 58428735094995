import { Permission } from "../enums";

interface ISecured {
    permissions?: Permission[];
}

/**
 * Checks that a given object has the given permission
 *
 * @param secured An object optionally containing a `permissions` array to
 *                verify the permissions of
 * @param permission A Permission to check existence of
 */
export function hasPermission(secured: ISecured, permission: Permission): boolean {
    return secured.permissions && secured.permissions.includes(permission);
}

/**
 * Checks that a given object contains one or more of the given array of
 * permissions.
 *
 * @param secured An object optionally containing a `permissions` array to
 *                verify the permissions of
 * @param permissions Permission or array of permissions to check. Any null
 *                    values provided will count as being permitted by secured.
 *                    An empty list of permissions is always considered valid
 *                    if secured permits something.
 */
export function hasAnyPermission(secured: ISecured, permissions: Permission | Permission[]): boolean {
    if (!secured.permissions) {
        return false;
    }

    if (!Array.isArray(permissions)) {
        permissions = [permissions];
    } else if (permissions.length === 0) {
        return true;
    }

    for (const permission of permissions) {
        if (!permission || secured.permissions.includes(permission)) {
            return true;
        }
    }

    return false;
}

/**
 * Checks that a given object contains all of the given array of permissions.
 *
 * @param secured An object optionally containing a `permissions` array to
 *                verify the permissions of
 * @param permissions Permission or array of permissions to check. Null values
 *                    are ignored. An empty list of permissions is considered
 *                    an empty set and therefore is a subset of secured.
 */
export function hasAllPermissions(secured: ISecured, permissions: Permission | Permission[]): boolean {
    if (!secured.permissions) {
        return false;
    }

    if (!Array.isArray(permissions)) {
        permissions = [permissions];
    }

    for (const permission of permissions) {
        if (permission && !secured.permissions.includes(permission)) {
            return false;
        }
    }

    return true;
}
