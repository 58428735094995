export enum AppButtonSize {
    BtnLarge = 'btnLarge',
    BtnSmall = 'btnSmall'
}

export namespace AppButtonSize {
    export function toString(type: AppButtonSize): string {
        switch (type) {
            case AppButtonSize.BtnLarge:
                return 'Large';
            case AppButtonSize.BtnSmall:
                return 'Small';
            default:
                return '';
        }
    }

    export const members: AppButtonSize[] = [
        AppButtonSize.BtnLarge,
        AppButtonSize.BtnSmall
    ];
}
