import { ObjectId, parseObjectId } from '@refactor/njs/db/mongo';
import { UserProfileWithoutIds } from './user-profile';
import { CompanyIdentityWithoutIds } from '../company/company-identity';

export class UserSimpleWithoutIds {
    public _id: any;
    public profile: UserProfileWithoutIds = null;
    public companyIdentity: CompanyIdentityWithoutIds = null;
    public crewName: string;
    public crewSize: number;
    public createdDate: Date;
    public modifiedDate: Date;

    constructor(params?: Partial<UserSimpleWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.profile = !!params.profile
                ? new UserProfileWithoutIds(params.profile)
                : this.profile;
            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentityWithoutIds(params.companyIdentity)
                : this.companyIdentity;
            this.crewName = !!params.crewName ? params.crewName : this.crewName;
            this.crewSize = !!params.crewSize ? params.crewSize : this.crewSize;
            this.createdDate = params.createdDate || this.createdDate;
            this.modifiedDate = params.modifiedDate || this.modifiedDate;
        }
    }
}
