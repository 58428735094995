import { isNull } from '../../utils';

export enum MonthType {
    January = '[MonthType] January',
    February = '[MonthType] February',
    March = '[MonthType] March',
    April = '[MonthType] April',
    May = '[MonthType] May',
    June = '[MonthType] June',
    July = '[MonthType] July',
    August = '[MonthType] August',
    September = '[MonthType] September',
    October = '[MonthType] October',
    November = '[MonthType] November',
    December = '[MonthType] December'
}

export namespace MonthType {
    export function toString(type: MonthType): string {
        switch (type) {
            case MonthType.January:
                return 'January';
            case MonthType.February:
                return 'February';
            case MonthType.March:
                return 'March';
            case MonthType.April:
                return 'April';
            case MonthType.May:
                return 'May';
            case MonthType.June:
                return 'June';
            case MonthType.July:
                return 'July';
            case MonthType.August:
                return 'August';
            case MonthType.September:
                return 'September';
            case MonthType.October:
                return 'October';
            case MonthType.November:
                return 'November';
            case MonthType.December:
                return 'December';
            default:
                return '';
        }
    }

    export function toNumber(type: MonthType): string {
        switch (type) {
            case MonthType.January:
                return '01';
            case MonthType.February:
                return '02';
            case MonthType.March:
                return '03';
            case MonthType.April:
                return '04';
            case MonthType.May:
                return '05';
            case MonthType.June:
                return '06';
            case MonthType.July:
                return '07';
            case MonthType.August:
                return '08';
            case MonthType.September:
                return '09';
            case MonthType.October:
                return '10';
            case MonthType.November:
                return '11';
            case MonthType.December:
                return '12';
            default:
                return '';
        }
    }

    export const members: MonthType[] = [
        MonthType.January,
        MonthType.February,
        MonthType.March,
        MonthType.April,
        MonthType.May,
        MonthType.June,
        MonthType.July,
        MonthType.August,
        MonthType.September,
        MonthType.October,
        MonthType.November,
        MonthType.December
    ];
}
