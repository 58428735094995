import {
    getInvoiceAvailableTimesheetMaxStartDate,
    Invoice,
    InvoiceService,
    InvoiceType,
    ServiceType,
    ServiceTypeService,
    TimesheetService,
    TimesheetSimple,
    ToastService
} from '@gm2/ui-common';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NEVER, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { SaveInvoiceSource } from '../../../../../apps/ngx-gm2/src/app/invoice/enums/after-save-action';

export namespace InvoiceActions {
    export class GetSelectedInvoice {
        static readonly type = '[Invoice] Get Selected Invoice';

        constructor(public id: string) {
        }
    }

    export class GetSelectedInvoiceTimesheets {
        static readonly type = '[Invoice] Get Selected Invoice Timesheets';

        constructor(public id: string) {
        }
    }

    export class GetAvailableTimesheets {
        static readonly type = '[Invoice] Get Available Timesheets';
    }

    export class GetServiceTypeList {
        static readonly type = '[Invoice] Get Service Type List';
    }

    export class SelectTimesheet {
        static readonly type = '[Invoice] Select Timesheet';

        constructor(public id: string) {
        }
    }

    export class DeselectTimesheet {
        static readonly type = '[Invoice] Deselect Timesheet';

        constructor(public id: string) {
        }
    }

    export class ResetSelectedTimesheet {
        static readonly type = '[Invoice] Reset Selected Timesheet';
    }

    export class ResetLoaders {
        static readonly type = '[Invoice] Reset Loaders';
    }

    export class DeleteTimesheet {
        static readonly type = '[Invoice] Delete Timesheet';

        constructor(public id: string) {
        }
    }

    export class SaveInvoice {
        static readonly type = '[Invoice] Save Invoice';

        constructor(
            public landscaperId: string,
            public invoiceNumber: string,
            public source: SaveInvoiceSource
        ) {
        }
    }

    export class SubmitInvoice {
        static readonly type = '[Invoice] Submit Invoice';

        constructor(
            public landscaperId: string,
            public invoiceNumber: string,
        ) {
        }
    }
}

export interface InvoiceStateModel {
    selectedInvoice: Invoice;
    selectedTimesheets: TimesheetSimple[];
    availableTimesheets: TimesheetSimple[];
    serviceTypes: ServiceType[],
    isEditing: boolean;
    isLoadingAvailableTimesheets: boolean;
    isLoadingSelectedTimesheets: boolean;
    isSaving: boolean;
    isSubmitting: boolean;
}

const initialState: InvoiceStateModel = {
    selectedInvoice: null,
    selectedTimesheets: null,
    availableTimesheets: null,
    serviceTypes: null,
    isEditing: false,
    isLoadingAvailableTimesheets: false,
    isLoadingSelectedTimesheets: false,
    isSaving: false,
    isSubmitting: false
};

@State<InvoiceStateModel>({
    name: 'invoice',
    defaults: initialState
})
@Injectable()
export class InvoiceState {


    constructor(
        private invoiceService: InvoiceService,
        private toastService: ToastService,
        private serviceTypeService: ServiceTypeService,
        private timesheetService: TimesheetService,
        private router: Router,
        private ngZone: NgZone,
        private store$: Store
    ) {
    }

    @Selector()
    @ImmutableSelector()
    public static selectedInvoice(state: InvoiceStateModel): Invoice {
        return state?.selectedInvoice;
    }

    @Selector()
    @ImmutableSelector()
    public static selectedTimesheets(state: InvoiceStateModel): TimesheetSimple[] {
        return state?.selectedTimesheets;
    }

    @Selector()
    @ImmutableSelector()
    public static availableTimesheets(state: InvoiceStateModel): TimesheetSimple[] {
        return state?.availableTimesheets;
    }

    @Selector()
    @ImmutableSelector()
    public static serviceTypes(state: InvoiceStateModel): ServiceType[] {
        return state?.serviceTypes;
    }

    @Selector()
    @ImmutableSelector()
    public static isEditing(state: InvoiceStateModel): boolean {
        return state?.isEditing;
    }
    @Selector()
    @ImmutableSelector()
    public static isSubmitting(state: InvoiceStateModel): boolean {
        return state?.isSubmitting;
    }

    @Selector()
    @ImmutableSelector()
    public static isSaving(state: InvoiceStateModel): boolean {
        return state?.isSaving;
    }

    @Selector()
    @ImmutableSelector()
    public static isLoadingAvailableTimesheets(state: InvoiceStateModel): boolean {
        return state?.isLoadingAvailableTimesheets;
    }

    @Selector()
    @ImmutableSelector()
    public static isLoadingSelectedTimesheets(state: InvoiceStateModel): boolean {
        return state?.isLoadingSelectedTimesheets;
    }


    @Action(InvoiceActions.ResetLoaders)
    public resetLoaders(ctx: StateContext<InvoiceStateModel>): void {
        ctx.setState((state) => ({
            ...state,
            isEditing: false,
            isLoadingAvailableTimesheets: false,
            isLoadingSelectedTimesheets: false,
            isSaving: false,
            isSubmitting: false
        }));
    }

    @Action(InvoiceActions.GetSelectedInvoice)
    public getSelectedInvoice(ctx: StateContext<InvoiceStateModel>, { id }: InvoiceActions.GetSelectedInvoice): Observable<any> {
        ctx.setState((state) => ({ ...state, selectedInvoice: null }));
        ctx.setState((state) => ({ ...state, isEditing: Boolean(id) }));

        if (!id) {
            return of();
        }

        return this.invoiceService.getInvoice(id)
            .pipe(
                tap((selectedInvoice) => {
                    ctx.setState((state) => ({ ...state, selectedInvoice, isEditing: true }));
                }),
                catchError(err => {
                    this.toastService.error('Error getting invoice', err.error.message);
                    return of(null);
                })
            );
    }

    @Action(InvoiceActions.GetSelectedInvoiceTimesheets)
    public getSelectedInvoiceTimesheets(ctx: StateContext<InvoiceStateModel>, { id }: InvoiceActions.GetSelectedInvoiceTimesheets): Observable<any> {
        ctx.setState((state) => ({ ...state, selectedTimesheets: null }));

        if (!id) {
            return of();
        }
        // show loader
        ctx.setState((state) => ({ ...state, isLoadingSelectedTimesheets: true }));

        return this.invoiceService.getInvoiceTimesheets(id)
            .pipe(
                tap((result) => {
                    // sort before assign
                    const selectedTimesheets = result.sort((a, b) => new Date(a.timesheetStartDateInLocationTz) > new Date(b.timesheetStartDateInLocationTz) ? 1 : -1);
                    ctx.setState((state) => ({ ...state, selectedTimesheets }));
                }),
                catchError(err => {
                    this.toastService.error('Error getting invoice timesheets', err.error.message);
                    return of(null);
                }),
                finalize(() => ctx.setState((state) => ({ ...state, isLoadingSelectedTimesheets: false })))
            );
    }

    @Action(InvoiceActions.GetAvailableTimesheets)
    public getAvailableTimesheets(ctx: StateContext<InvoiceStateModel>): Observable<any> {
        ctx.setState((state) => ({ ...state, availableTimesheets: null }));
        const startDate = getInvoiceAvailableTimesheetMaxStartDate().toISOString();
        const endDate = new Date().toISOString();

        // show loader
        ctx.setState((state) => ({ ...state, isLoadingAvailableTimesheets: true }));

        return this.invoiceService.getTimesheetsAvailable(startDate, endDate, false)
            .pipe(
                tap((availableTimesheets) => {
                    ctx.setState((state) => ({ ...state, availableTimesheets }));
                }),
                catchError(err => {
                    this.toastService.error('Error getting available timesheets', err.error.message);
                    return of(null);
                }),
                finalize(() => ctx.setState((state) => ({ ...state, isLoadingAvailableTimesheets: false })))
            );
    }

    @Action(InvoiceActions.GetServiceTypeList)
    public getServiceTypeList(ctx: StateContext<InvoiceStateModel>): Observable<any> {
        ctx.setState((state) => ({ ...state, serviceTypes: null }));

        return this.serviceTypeService.getServiceTypesAll()
            .pipe(
                tap((serviceTypes) => {
                    ctx.setState((state) => ({ ...state, serviceTypes }));
                }),
                catchError(err => {
                    this.toastService.error('Error getting service types', err.error.message);
                    return of(null);
                })
            );
    }

    @Action(InvoiceActions.SelectTimesheet)
    public selectTimesheet(ctx: StateContext<InvoiceStateModel>, { id }: InvoiceActions.SelectTimesheet): void {

        const availableTimesheets = [...ctx.getState().availableTimesheets];
        const index = availableTimesheets.findIndex(({ _id }) => _id === id);
        const cutTimesheet = availableTimesheets.splice(index, 1);
        const selectedTimesheets = ctx.getState().selectedTimesheets ?? [];

        ctx.setState((state) => ({
            ...state, availableTimesheets,
            selectedTimesheets: [...selectedTimesheets, ...cutTimesheet]
        }));
    }

    @Action(InvoiceActions.DeselectTimesheet)
    public deselectTimesheet(ctx: StateContext<InvoiceStateModel>, { id }: InvoiceActions.DeselectTimesheet): void {

        const selectedTimesheets = [...ctx.getState().selectedTimesheets];
        const index = selectedTimesheets.findIndex(({ _id }) => _id === id);
        const cutTimesheet = selectedTimesheets.splice(index, 1);
        const availableTimesheets = ctx.getState().availableTimesheets ?? [];

        ctx.setState((state) => ({
            ...state, selectedTimesheets,
            availableTimesheets: [...availableTimesheets, ...cutTimesheet]
        }));
    }

    @Action(InvoiceActions.ResetSelectedTimesheet)
    public resetSelectTimesheet(ctx: StateContext<InvoiceStateModel>): void {

        const selectedTimesheets = ctx.getState().selectedTimesheets ?? [];
        const availableTimesheets = ctx.getState().availableTimesheets ?? [];

        ctx.setState((state) => ({
            ...state,
            selectedTimesheets: [],
            availableTimesheets: [...availableTimesheets, ...selectedTimesheets]
        }));
    }

    @Action(InvoiceActions.DeleteTimesheet)
    public deleteTimesheet(ctx: StateContext<InvoiceStateModel>, { id }: InvoiceActions.DeleteTimesheet): Observable<void> {

        if (!id) {
            return of();
        }

        return this.timesheetService.deleteTimesheets([id])
            .pipe(
                tap((res) => {
                    const selectedTimesheets = ctx.getState().selectedTimesheets.filter((ts) => ts._id !== id);
                    ctx.setState((state) => ({ ...state, selectedTimesheets }));
                    this.toastService.success('Timesheet Deleted');

                }),
                catchError((err) => {
                    this.toastService.error('Unable to delete Timesheet', err.error.message);
                    return of();
                })
            );
    }

    @Action(InvoiceActions.SaveInvoice)
    public saveInvoice(ctx: StateContext<InvoiceStateModel>, { landscaperId, invoiceNumber, source }: InvoiceActions.SaveInvoice): Observable<any> {

        // prevent button mashing
        if (!landscaperId) {
            this.toastService.error('Select landscaper');
            return of();
        }

        // prevent button mashing
        if (ctx.getState().isSaving) {
            return of();
        }

        const timesheetIds = ctx.getState().selectedTimesheets?.map(sheet => sheet._id) ?? [];

        ctx.setState((state) => ({ ...state, isSaving: true }));

        const invoiceId$ = ctx.getState().isEditing
            ? of(ctx.getState().selectedInvoice._id)
            : this.invoiceService.createInvoice({ type: InvoiceType.Normal })
                .pipe(
                    map((selectedInvoice) => {
                        ctx.setState((state) => ({ ...state, selectedInvoice }));
                        return selectedInvoice._id;
                    }),
                );


        return invoiceId$
            .pipe(
                switchMap((id) => this.invoiceService.saveInvoice({ id, invoiceNumber, landscaperId, timesheetIds })
                    .pipe(
                        tap((selectedInvoice) => {
                            ctx.setState((state) => ({ ...state, selectedInvoice, isEditing: true }));

                            switch (source) {
                                case SaveInvoiceSource.OnSave:
                                    return  this.toastService.success('Invoice Saved');
                                case SaveInvoiceSource.OnCreateTimesheet:
                                    return this.ngZone.run(() => this.router.navigateByUrl('/operations/timesheet/create/' + id));
                            }
                        })
                    )),
                catchError((err) => {
                    this.toastService.error('Something went wrong', err.error.message);
                    return throwError(err);
                }),
                finalize(() => ctx.setState((state) => ({ ...state, isSaving: false })))
            );
    }

    @Action(InvoiceActions.SubmitInvoice)
    public submitInvoice(ctx: StateContext<InvoiceStateModel>, { landscaperId, invoiceNumber }: InvoiceActions.SubmitInvoice): Observable<any> {

        const sheets = ctx.getState().selectedTimesheets;

        if (!sheets?.length) {
            this.toastService.error('Select at least one timesheet');
            return of();
        }

        // prevent button mashing
        if (ctx.getState().isSaving || ctx.getState().isSubmitting) {
            return of();
        }

        ctx.setState((state) => ({ ...state, isSubmitting: true }));

        return ctx.dispatch(new InvoiceActions.SaveInvoice(landscaperId, invoiceNumber, SaveInvoiceSource.OnSubmit))
            .pipe(
                catchError((err) => {
                    // handle error output of SaveInvoice action and prevent further execution
                    ctx.setState((state) => ({ ...state, isSubmitting: false }));
                    return NEVER;
                })
            )
            .pipe(tap((state: any) => {
                if (!state.invoice?.selectedInvoice?._id) throw 'Invoice was not created.';
            }))
            .pipe(
                switchMap((res) =>
                    this.invoiceService
                        .submitInvoice(ctx.getState().selectedInvoice._id)
                        .pipe(
                            map((res) => {
                                this.toastService.success('Invoice Submitted');
                                this.router.navigateByUrl('/operations/invoice/list');
                            })
                        )),
                catchError((err) => {
                    this.toastService.error('Something went wrong', err.error.message);
                    return of();
                }),
                finalize(() => ctx.setState((state) => ({ ...state, isSubmitting: false })))
            );
    }
}
