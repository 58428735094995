import { LocationSimpleWithServiceTypesWithoutIds } from '@gm2/common';
import { LocationIdentity } from './location-identity';
import { CompanyIdentity } from '../company/CompanyIdentity';
import { LocationSimple } from './LocationSimple';

export class LocationSimpleWithServiceTypes extends LocationSimpleWithServiceTypesWithoutIds {
    public _id: string = '';
    public identity: LocationIdentity = new LocationIdentity();
    public companyIdentity: CompanyIdentity = new CompanyIdentity();

    constructor(params?: Partial<LocationSimpleWithServiceTypes>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.identity = !!params.identity
                ? new LocationIdentity(params.identity)
                : this.identity;
            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentity(params.companyIdentity)
                : this.companyIdentity;
        }
    }
}
