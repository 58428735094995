export enum ConditionalActionType {
    Hide = 'Hide',
    Show = 'Show'
}

export namespace ConditionalActionType {
    export function toString(type: ConditionalActionType): string {
        switch (type) {
            case ConditionalActionType.Hide:
                return 'Hide';
            case ConditionalActionType.Show:
                return 'Show';
            default:
                return '';
        }
    }

    export const members: ConditionalActionType[] = [
        ConditionalActionType.Hide,
        ConditionalActionType.Show
    ];
}
