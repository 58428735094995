import { isNull } from '../../utils';

export class BasePrices {
    public billingOverridden: boolean = null;
    public billingType: string = null;
    public icemeltMinCharge: number = null;
    public icemeltPerPound: number = null;
    public minCharge: number = null;
    public plowRate: number = null;
    public shovelRate: number = null;

    constructor(params?: Partial<BasePrices>) {
        if (!!params) {
            this.billingOverridden = params.billingOverridden || this.billingOverridden;
            this.billingType = params.billingType || this.billingType;
            this.icemeltMinCharge = !isNull(params.icemeltMinCharge)
                ? params.icemeltMinCharge
                : this.icemeltMinCharge;
            this.icemeltPerPound = !isNull(params.icemeltPerPound)
                ? params.icemeltPerPound
                : this.icemeltPerPound;
            this.minCharge = !isNull(params.minCharge) ? params.minCharge : this.minCharge;
            this.plowRate = !isNull(params.plowRate) ? params.plowRate : this.plowRate;
            this.shovelRate = !isNull(params.shovelRate) ? params.shovelRate : this.shovelRate;
        }
    }
}
