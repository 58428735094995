import { FirstApprovalWithoutIds } from '@gm2/common';
import { UserIdentity } from '../user/UserIdentity';

export class FirstApproval extends FirstApprovalWithoutIds {
    public selectedCompanyIds: string[] = [];
    public preferredCompanyId: string;
    public awardedByUserIdentity: UserIdentity = new UserIdentity();

    constructor(params?: Partial<FirstApproval>) {
        super(params);
        if (!!params) {
            this.selectedCompanyIds = Array.isArray(params.selectedCompanyIds)
                ? params.selectedCompanyIds
                : this.selectedCompanyIds;
            this.preferredCompanyId =
                params.preferredCompanyId; /*!!params.preferredCompanyId
                ? parseObjectId(params.preferredCompanyId)
                : this.preferredCompanyId;*/
            this.awardedByUserIdentity = !!params.awardedByUserIdentity
                ? new UserIdentity(params.awardedByUserIdentity)
                : this.awardedByUserIdentity;
        }
    }
}
