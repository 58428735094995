import { ITimesheetCharges } from '@gm2/common';
import { IBillingTypeChargeStrategy } from '../interfaces/i-billing-type-charge-strategy';
import { BillingTypeChargeStrategyFactory } from '../billing-type-charge-strategy-factory';
import { TimesheetWithoutIds, MaterialWithoutIds, TimesheetCharges } from '@gm2/common';
import { twoDecimalRound } from '@gm2/common';

export class SpCharges implements ITimesheetCharges {
    protected timesheet: TimesheetWithoutIds;
    protected approvedMaterials: MaterialWithoutIds[];
    protected approvedMaterialDict: { [key: string]: MaterialWithoutIds } = {};

    protected companyChargeStrategy: IBillingTypeChargeStrategy;
    protected clientChargeStrategy: IBillingTypeChargeStrategy;

    constructor(timesheet: TimesheetWithoutIds, approvedMaterials: MaterialWithoutIds[]) {
        this.timesheet = timesheet;
        this.approvedMaterials = approvedMaterials;
        this.approvedMaterials.forEach(material => {
            this.approvedMaterialDict[material._id.toString()] = material;
        });

        const billingTypeFactory = new BillingTypeChargeStrategyFactory();
        // it was possible for the awarded bid to have a different billingType than the contract.
        // to stay backwards compatible I kept it this way.
        try {
            this.companyChargeStrategy = billingTypeFactory.createSp(
                timesheet.snapShot.rfp.awardedBid.bid.billingType
            );
        } catch (error) {
            this.companyChargeStrategy = null;
        }

        try {
            this.clientChargeStrategy = billingTypeFactory.createSp(
                timesheet.snapShot.contractIdentity.billingType
            );
        } catch (error) {
            this.clientChargeStrategy = null;
        }
    }

    public calculate(): TimesheetCharges {
        const companyCharges =
            this.companyChargeStrategy !== null
                ? this.companyChargeStrategy.calculateCompanyCharges(
                      this.timesheet,
                      this.approvedMaterialDict
                  )
                : { total: 0, duration: 0, errors: ['Invalid awarded Bid data'] };

        const clientCharges =
            this.clientChargeStrategy !== null
                ? this.clientChargeStrategy.calculateClientCharges(
                      this.timesheet,
                      companyCharges.duration
                  )
                : { total: 0, duration: 0, errors: ['Invalid contract data'] };

        return new TimesheetCharges({
            durationMinutes: clientCharges.duration,
            total: twoDecimalRound(companyCharges.total),
            clientCompanyTotal: twoDecimalRound(clientCharges.total)
        });
    }

    public getCompanyChargeBillingType(): string {
        return this.companyChargeStrategy === null
            ? ''
            : (<any>this.companyChargeStrategy).constructor.name;
    }

    public getClientChargeBillingType(): string {
        return this.clientChargeStrategy === null
            ? ''
            : (<any>this.clientChargeStrategy).constructor.name;
    }
}
