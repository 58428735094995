import { Injectable } from '@angular/core';
import {
    HttpService,
    Post,
    Query,
    MapClass,
    MapValue,
    Body,
    Get,
    Path,
    Delete,
    Put,
    ResponseType
} from '@refactor/ngx/http';
import { Package, PackageSnapshot } from '../models';
import { Observable } from 'rxjs';
import { PaginatedList, GridParams } from '@refactor/common';

@Injectable({
    providedIn: 'root'
})
export class PackageService extends HttpService {
    @Post('/packages/list')
    @MapValue(
        res =>
            new PaginatedList<Package>({
                count: res.count,
                docs: res.docs.map(d => new Package(d))
            })
    )
    @Get('/packages')
    @MapClass(Package)
    public getPackageList(@Body() params: GridParams): Observable<PaginatedList<Package>> {
        return null;
    }

    // need to determine if we need a package dto
    @Post('/packages')
    public createPackage(@Body() body: Package): Observable<void> {
        return null;
    }

    // endpoint needs to be created
    @Get('/packages')
    public getPackages(): Observable<Package[]> {
        return null;
    }

    @Delete('/packages/:packageId')
    public deletePackage(@Path('packageId') id: string): Observable<any> {
        return null;
    }

    @Get('/packages/:packageId')
    @MapClass(Package)
    public getPackage(@Path('packageId') id: string): Observable<Package> {
        return null;
    }

    @Put('/packages/:packageId')
    @MapClass(Package)
    public editPackage(@Path('packageId') id: string, @Body() body: Package): Observable<Package> {
        return null;
    }

    @Post('/packages/snapshots')
    @MapClass(PackageSnapshot)
    public getPackageSnapshots(@Body('ids') ids: string[]): Observable<PackageSnapshot[]> {
        return null;
    }

    @Post('/packages/list-by-serviceType')
    @MapClass(PackageSnapshot)
    public getPackageByserviceType(@Body('id') id: string): Observable<PackageSnapshot[]> {
        return null;
    }

    @Post('/packages/csvExport', {
        responseType: ResponseType.Text
    })
    public packageCsvExport(@Body() params: GridParams): Observable<string> {
        return null;
    }

    @Post('/packages/list-by-landscaper')
    public getLandscaperPackages(@Body('id') id: string): Observable<any[]> {
        return null;
    }
}
