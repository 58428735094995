import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { hasPermission, Permission, WorkOrderActiveStatus, WorkOrderLaborCostType, WorkOrderStatus } from '@gm2/common';
import { WorkOrderEditConfig } from '../../../../../../apps/ngx-gm2/src/app/workorder/shared/work-order-views-shared';
import { Company, User, WorkOrder } from '@gm2/ui-common';

export const woCreateNewAdmin = (form: UntypedFormGroup, uxForm: UntypedFormGroup, editorConfig: WorkOrderEditConfig, u: User): { uxForm: UntypedFormGroup, form: UntypedFormGroup, editorConfig: WorkOrderEditConfig } => {
    uxForm.addControl('clientId', new UntypedFormControl(null));
    form.addControl('workOrderNumber', new UntypedFormControl(null));
    form.addControl(
        'servicePartnerInvoiceNumber',
        new UntypedFormControl(null),
    );
    form.addControl(
        'reportedBy',
        new UntypedFormControl(null, Validators.required),
    );
    form.addControl(
        'billingType',
        new UntypedFormControl(null, Validators.required),
    );
    form.addControl('completeByDate', new UntypedFormControl(null));
    form.addControl('scheduledDate', new UntypedFormControl(null));
    if (hasPermission(u, Permission.WorkorderReferredByEdit)) {
        form.addControl('referredBy', new UntypedFormControl(null));
    }
    form.addControl('gmr', new UntypedFormControl(null));
    form.addControl('safety', new UntypedFormControl(false));
    form.addControl('saveAsTemplate', new UntypedFormControl(false));
    form.addControl('templateName', new UntypedFormControl(null));
    form.addControl('phases', new UntypedFormArray([]));
    form.addControl('serviceDueDate', new UntypedFormControl(null));
    form.addControl('qbInvoiceNumber', new UntypedFormControl(null));
    form.addControl(
        'clientWorkOrderNumber',
        new UntypedFormControl(null),
    );
    const config = {
        ...editorConfig,
        title: 'Create Work Order',
        phases: {
            ...editorConfig.phases,
            buttons: {
                ...editorConfig.phases.buttons,
                type: true,
                material: true,
                equipment: true,
                labor: true,
                phases: true,
            },
            laborRateLabels: {
                internal: 'internal',
                external: 'external',
                servicePartner: 'hourly rate',
            },
        },
        attachments: {
            ...editorConfig.attachments,
            images: {
                ...editorConfig.attachments.images,
                upload: true,
            },
            oneGmDocs: {
                ...editorConfig.attachments.oneGmDocs,
                upload: true,
            },
            clientDocs: {
                ...editorConfig.attachments.clientDocs,
                upload: true,
            },
            servicePartnerDocs: {
                ...editorConfig.attachments
                    .servicePartnerDocs,
                upload: true,
            },
        },
    };

    return {
        uxForm,
        form,
        editorConfig: config,
    };
};

export const woRequestWo = (form: UntypedFormGroup, c: Company, editorConfig: WorkOrderEditConfig): { form: UntypedFormGroup, editorConfig: WorkOrderEditConfig } => {
    form.addControl('safety', new UntypedFormControl(false));
    form.addControl('phases', new UntypedFormArray([]));
    form.addControl(
        'projectDescription',
        new UntypedFormControl(null, Validators.required),
    );
    const config = {
        ...editorConfig,
        title: 'Work Order Request',
        phases: {
            ...editorConfig.phases,
            buttons: {
                ...editorConfig.phases.buttons,
                type: false,
                material: true,
                equipment: true,
                labor: true,
            },
            laborRateLabels: {
                internal: 'std rate',
                external: '',
                servicePartner: 'hourly rate',
            },
        },
        attachments: {
            ...editorConfig.attachments,
            images: {
                ...editorConfig.attachments.images,
                upload: true,
                required: true,
            },
        },
    };
    return {
        form,
        editorConfig: config,
    };
};

export const spFormLoad = (form: UntypedFormGroup, w: WorkOrder, editorConfig: WorkOrderEditConfig): { form: UntypedFormGroup, editorConfig: WorkOrderEditConfig } => {
    form.addControl('safety', new UntypedFormControl(false));
    form.addControl(
        'servicePartnerInvoiceNumber',
        new UntypedFormControl(null),
    );
    form.addControl('serviceDueDate', new UntypedFormControl(null));

    let config = {
        ...editorConfig,
        title: 'Work Order',
        attachments: {
            ...editorConfig.attachments,
            images: {
                ...editorConfig.attachments.images,
                show: true,
                upload: true,
                deleteOwn: true,
            },
            servicePartnerDocs: {
                ...editorConfig.attachments
                    .servicePartnerDocs,
                show: true,
                upload: true,
                delete: true,
            },
            notes: {
                ...editorConfig.attachments.notes,
                show: true,
                servicePartner: true,
            },
        },
        history: {
            ...editorConfig.history,
            showHistory: true,
        },
    };
    if (
        !!WorkOrderStatus.spEditableMembers.includes(w.workOrderStatus)
    ) {

        config = {
            ...config,
            phases: {
                ...config.phases,
                buttons: {
                    ...config.phases.buttons,
                    phases: false,
                    material: true,
                    equipment: true,
                    labor: true,
                },
            },
        };
    }
    return {
        form,
        editorConfig: config,
    };
};

export const notSubcontractorFormLoad = (form: UntypedFormGroup, uxForm: UntypedFormGroup, u: User, w: WorkOrder, editorConfig: WorkOrderEditConfig): { form: UntypedFormGroup, uxForm: UntypedFormGroup, editorConfig: WorkOrderEditConfig } => {
    form.addControl('saveAsTemplate', new UntypedFormControl(false));
    form.addControl('templateName', new UntypedFormControl(null));

    uxForm.addControl('clientId', new UntypedFormControl(null));
    form.addControl(
        'servicePartnerInvoiceNumber',
        new UntypedFormControl(null),
    );
    form.addControl(
        'reportedBy',
        new UntypedFormControl(null, Validators.required),
    );
    form.addControl(
        'billingType',
        new UntypedFormControl(null, Validators.required),
    );
    form.addControl('completeByDate', new UntypedFormControl(null));
    form.addControl('scheduledDate', new UntypedFormControl(null));
    if (hasPermission(u, Permission.WorkorderReferredByEdit)) {
        form.addControl('referredBy', new UntypedFormControl(null));
    }
    form.addControl('gmr', new UntypedFormControl(null));
    form.addControl('safety', new UntypedFormControl(false));
    form.addControl(
        'notToExceed',
        new UntypedFormControl({ value: null, disabled: true }),
    );
    form.addControl('serviceDueDate', new UntypedFormControl(null));
    form.addControl('qbInvoiceNumber', new UntypedFormControl(null));
    form.addControl(
        'clientWorkOrderNumber',
        new UntypedFormControl(null),
    );
    const title =
        w.workOrderStatus === WorkOrderStatus.Template
            ? 'Work Order Template'
            : 'Work Order';
    if (w.workOrderStatus === WorkOrderStatus.Approved) {
        uxForm.addControl(
            'requiredImagesBypass',
            new UntypedFormControl(false),
        );
    }
    const config = {
        title,
        ...editorConfig,
        phases: {
            ...editorConfig.phases,
            buttons: {
                ...editorConfig.phases.buttons,
                type: true,
                material: true,
                equipment: true,
                labor: true,
                phases: true,
            },
        },
        attachments: {
            ...editorConfig.attachments,
            images: {
                ...editorConfig.attachments.images,
                show: true,
                upload: true,
                deleteAny: true,
            },
            oneGmDocs: {
                ...editorConfig.attachments.oneGmDocs,
                show: true,
                upload: true,
                delete: true,
            },
            clientDocs: {
                ...editorConfig.attachments.clientDocs,
                show: true,
                upload: true,
                delete: true,
            },
            servicePartnerDocs: {
                ...editorConfig.attachments
                    .servicePartnerDocs,
                show: true,
                upload: true,
                delete: true,
            },
            notes: {
                ...editorConfig.attachments.notes,
                show: true,
                admin: true,
                client: true,
                servicePartner: true,
            },
        },
        history: {
            ...editorConfig.history,
            showHistory: true,
            showUser: true,
            showActiveStatus: true,
        },
    };
    return {
        form,
        uxForm,
        editorConfig: config,
    };
};
