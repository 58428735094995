import { WorkOrderInvoice } from '../models';
import { WorkOrderInvoiceStatus, Permission } from '@gm2/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum WorkorderInvoiceAction {
    Delete = 'delete',
    Edit = 'edit',
    Approve = 'approve',
    Decline = 'decline',
    SpecialDecline = 'specialDecline',
    Post = 'post',
    Paid = 'paid',
    Export = 'export'
    // Special decline --jon says yes keep this
    // Taking off the declines for now though. It is slightly more complicated process with modal
    // Maybe best to leave for the decicated invoice view?
}

interface IActionPermissionsMapping {
    permission: Permission;
    statuses?: WorkOrderInvoiceStatus[];
}

type ActionPermissionsDict = {
    [key in WorkorderInvoiceAction]: IActionPermissionsMapping
};

/**
 * Map of permissions and current invoice statuses required for performing
 * actions. The statuses field is optional and when ommitted means that any
 * status is valid.
 */
const actionPermissionsDict: ActionPermissionsDict = {
    [WorkorderInvoiceAction.Export]: {
        // Admin or SP
        permission: Permission.InvoiceView,
        statuses: [
            WorkOrderInvoiceStatus.Submitted,
            WorkOrderInvoiceStatus.Approved,
            WorkOrderInvoiceStatus.Posted,
            WorkOrderInvoiceStatus.PaymentProcessed
        ]
    },
    [WorkorderInvoiceAction.Delete]: {
        // Sp
        permission: Permission.InvoiceCreateEdit,
        statuses: [
            WorkOrderInvoiceStatus.InProgress,
            WorkOrderInvoiceStatus.Submitted,
            WorkOrderInvoiceStatus.Approved,
            WorkOrderInvoiceStatus.Rejected
        ]
    },
    [WorkorderInvoiceAction.Edit]: {
        // Sp
        permission: Permission.InvoiceCreateEdit,
        statuses: [
            WorkOrderInvoiceStatus.InProgress,
            WorkOrderInvoiceStatus.Submitted,
            WorkOrderInvoiceStatus.Rejected
        ]
    },
    [WorkorderInvoiceAction.Approve]: {
        // Admin
        permission: Permission.InvoiceApproval,
        statuses: [WorkOrderInvoiceStatus.Submitted]
    },
    // Does the same thing as special decline but from a different status and
    // with a slightly different permission
    [WorkorderInvoiceAction.Decline] : {
        // Admin
        permission: Permission.InvoiceApproval,
        statuses: [
            WorkOrderInvoiceStatus.Submitted
        ]
    },
    [WorkorderInvoiceAction.Post]: {
        // Admin
        permission: Permission.InvoicePost,
        statuses: [WorkOrderInvoiceStatus.Approved]
    },
    [WorkorderInvoiceAction.Paid]: {
        // Admin
        permission: Permission.InvoiceBilling,
        statuses: [WorkOrderInvoiceStatus.Posted]
    },
    [WorkorderInvoiceAction.SpecialDecline]: {
        permission: Permission.InvoiceSpecialDecline,
        statuses: [
            // Can only do from Posted or PaymentProcessed https://app.clickup.com/t/f0ntrm
            // Approved https://app.clickup.com/t/h6x8z9
            WorkOrderInvoiceStatus.Approved,
            WorkOrderInvoiceStatus.Posted,
            WorkOrderInvoiceStatus.PaymentProcessed
        ]
    }
};

export type WorkorderInvoiceActionCheckFn =
    (invoice: WorkOrderInvoice, action: WorkorderInvoiceAction) => Observable<boolean>;

/**
 * Checks if a particular action can be performed on an invoice. Where an
 * "action" is an arbitrary user action that modifies or otherwise interacts
 * with the invoice. Whether an action can be performed depends on the
 * permissions allowed to the user and the current state of the invoice.
 */
export function canPerformWorkorderInvoiceAction(
    permissions: Observable<Permission[]>,
    invoice: WorkOrderInvoice,
    action: WorkorderInvoiceAction
): Observable<boolean> {
    const conditions = actionPermissionsDict[action];
    const validStatus = !conditions.statuses || conditions.statuses.includes(invoice.invoiceStatus);

    return permissions.pipe(
        map(p => p.includes(conditions.permission) && validStatus),
    );
}

/**
 * Shorthand function for binding an observable permissions array to the the
 * check. This is just a handy function that takes advantage of the fact that
 * permissions comes from user state and ends up being shared between calls to
 * this function within a component.
 *
 * @returns The canPerformAction function with the permissions parameter bound
 *          persistently.
 */
export function bindPerformWorkorderInvoiceAction(
    permissions: Observable<Permission[]>
): WorkorderInvoiceActionCheckFn {
    return canPerformWorkorderInvoiceAction.bind(window, permissions);
}
