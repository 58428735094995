import {
    Component,
    OnInit,
    HostBinding,
    Directive,
    ViewContainerRef,
    ElementRef,
    TemplateRef,
    ViewChild,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { RfxGridItemOutletContext } from '../Row';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { RfxGridDataSource } from '../GridDataSource';

@Directive({
    selector: '[rfxGridListItemOutlet]'
})
export class GridListItemOutlet {
    constructor(
        public viewContainer: ViewContainerRef,
        public elementRef: ElementRef
    ) {}
}

@Component({
    selector: 'rfx-grid-row',
    templateUrl: './grid-row.component.html',
    styleUrls: ['./grid-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridRowComponent<T> implements OnInit {
    @HostBinding('class.selected')
    public checked: boolean = false;

    @ViewChild(GridListItemOutlet, { static: true })
    public _listItemOutlet: GridListItemOutlet;

    private _selectionWatcher: Subscription;
    private _usedShift: boolean = false;
    private _dataSource: RfxGridDataSource<T>;

    public item: T;
    public selection: SelectionModel<T>;

    constructor(private _cd: ChangeDetectorRef) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this._listItemOutlet.viewContainer.clear();
        if (!!this._selectionWatcher) {
            this._selectionWatcher.unsubscribe();
        }
    }

    public createItemView(
        template: TemplateRef<any>,
        context: RfxGridItemOutletContext<T>
    ): void {
        this._listItemOutlet.viewContainer.createEmbeddedView(
            template,
            context
        );
        this._cd.markForCheck();
    }

    public initValues(data: T, dataSource: RfxGridDataSource<T>): void {
        this.item = data;
        this._dataSource = dataSource;
        this.selection = dataSource._selection;

        if (!!this.selection) {
            this._selectionWatcher = this.selection.changed.subscribe(() => {
                const newChecked = this.selection.isSelected(this.item);
                if (newChecked !== this.checked) {
                    this.checked = newChecked;
                    this._cd.markForCheck();
                }
            });
            this.checked = this.selection.isSelected(this.item);
        }
    }

    public toggleSelection(item: T): void {
        const usedShift = this._usedShift;
        this._usedShift = false; // Reset immediately
        if (!usedShift) {
            this._dataSource.toggleSelection(item);
        } else {
            this._dataSource.toggleSelectionBetween(item);
        }
    }

    public onClick(event: MouseEvent): void {
        this._usedShift = event.shiftKey;
    }
}
