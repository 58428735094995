import { TermsModalComponent } from './terms-modal/terms-modal.component';
import { WelcomeModalComponent } from './welcome-modal/welcome-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { FinishMappingAreaModalComponent } from './finish-mapping-area-modal/finish-mapping-area-modal.component';
import { AddMappingCategoryModalComponent } from './add-mapping-category-modal/add-mapping-category-modal.component';
import { ConfirmDeleteModalComponent } from './confirm-delete-modal/confirm-delete-modal.component';
import { ConfirmDuplicateLocationModalComponent } from './confirm-duplicate-location-modal/confirm-duplicate-location-modal.component';
import { ConfirmTimesheetEditComponent } from './confirm-timesheet-edit/confirm-timesheet-edit.component';
import { SpecificationDocumentsModalComponent } from './specification-documents-modal/specification-documents-modal.component';
import { ImageLightboxModalComponent } from './image-lightbox-modal/image-lightbox-modal.component';
import { CompanyQuestionnaireModalComponent } from './company-questionnaire-modal/company-questionnaire-modal.component';
import { DateModalComponent } from './date-modal/date-modal.component';
import { ServiceEditModalComponent } from './company-service-edit/company-service-edit.component';
import { DateRangeModalComponent } from './date-range-modal/date-range-modal.component';
import { AppliedContractAddLocationModalComponent } from './applied-contract-add-location-modal/applied-contract-add-location-modal.component';
import { ServicePartnerModalComponent } from './service-partner-modal/service-partner-modal.component';
import { StringInputModalComponent } from './string-input-modal/string-input-modal.component';
import { ConfirmPricingChangeComponent } from './confirm-pricing-change/confirm-pricing-change.component';
import { UploadPricingCsvModalComponent } from './upload-pricing-csv-modal/upload-pricing-csv-modal.component';
import { MonthlyInvoiceModalComponent } from './monthly-invoice-modal/monthly-invoice-modal.component';
import { DeclineInvoiceModalComponent } from './decline-invoice-modal/decline-invoice-modal.component';
import { SelectPreferredBidModalComponent } from './select-preferred-bid-modal/select-preferred-bid-modal.component';
import { ChildServiceModalComponent } from './child-service-modal/child-service-modal.component';
import { WorkOrderActivateModalComponent } from './work-order-activate-modal/work-order-activate-modal.component';
import { DeclineWorkorderInvoiceModalComponent } from './decline-workorder-invoice-modal/decline-workorder-invoice-modal.component';
import { ImageCarouselModalComponent } from './image-carousel-modal/image-carousel-modal.component';
import { GeospatialControlModalComponent } from './geospatial-services-control-modal/geospatial-control-modal.component';

export const modals = [
    TermsModalComponent,
    WelcomeModalComponent,
    FinishMappingAreaModalComponent,
    AddMappingCategoryModalComponent,
    ConfirmDeleteModalComponent,
    ConfirmDuplicateLocationModalComponent,
    ConfirmModalComponent,
    ConfirmTimesheetEditComponent,
    SpecificationDocumentsModalComponent,
    ImageLightboxModalComponent,
    CompanyQuestionnaireModalComponent,
    DateModalComponent,
    ServiceEditModalComponent,
    DateRangeModalComponent,
    AppliedContractAddLocationModalComponent,
    ServicePartnerModalComponent,
    StringInputModalComponent,
    ConfirmPricingChangeComponent,
    UploadPricingCsvModalComponent,
    MonthlyInvoiceModalComponent,
    DeclineInvoiceModalComponent,
    SelectPreferredBidModalComponent,
    ChildServiceModalComponent,
    WorkOrderActivateModalComponent,
    DeclineWorkorderInvoiceModalComponent,
    ImageCarouselModalComponent,
    GeospatialControlModalComponent,
];

export { TermsModalComponent } from './terms-modal/terms-modal.component';
export { WelcomeModalComponent } from './welcome-modal/welcome-modal.component';
export {
    FinishMappingAreaModalComponent
} from './finish-mapping-area-modal/finish-mapping-area-modal.component';
export {
    AddMappingCategoryModalComponent
} from './add-mapping-category-modal/add-mapping-category-modal.component';
export { ConfirmDeleteModalComponent } from './confirm-delete-modal/confirm-delete-modal.component';
export {
    ConfirmDuplicateLocationModalComponent
} from './confirm-duplicate-location-modal/confirm-duplicate-location-modal.component';
export {
    ConfirmTimesheetEditComponent
} from './confirm-timesheet-edit/confirm-timesheet-edit.component';
export {
    SpecificationDocumentsModalComponent
} from './specification-documents-modal/specification-documents-modal.component';
export { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
export { ImageLightboxModalComponent } from './image-lightbox-modal/image-lightbox-modal.component';
export {
    AppliedContractAddLocationModalComponent
} from './applied-contract-add-location-modal/applied-contract-add-location-modal.component';
export {
    CompanyQuestionnaireModalComponent
} from './company-questionnaire-modal/company-questionnaire-modal.component';
export { DateRangeModalComponent } from './date-range-modal/date-range-modal.component';
export {
    ConfirmPricingChangeComponent
} from './confirm-pricing-change/confirm-pricing-change.component';
export {
    UploadPricingCsvModalComponent
} from './upload-pricing-csv-modal/upload-pricing-csv-modal.component';
export {
    DeclineInvoiceModalComponent
} from './decline-invoice-modal/decline-invoice-modal.component';
export {
    SelectPreferredBidModalComponent
} from './select-preferred-bid-modal/select-preferred-bid-modal.component';
export { ChildServiceModalComponent } from './child-service-modal/child-service-modal.component';
export { WorkOrderActivateModalComponent } from './work-order-activate-modal/work-order-activate-modal.component';
export { DeclineWorkorderInvoiceModalComponent } from './decline-workorder-invoice-modal/decline-workorder-invoice-modal.component';
export { ImageCarouselModalComponent } from './image-carousel-modal/image-carousel-modal.component';
export { GeospatialControlModalComponent } from './geospatial-services-control-modal/geospatial-control-modal.component';
