export enum UserNotificationType {
    NewCompanyRegistered = 'newCompanyRegistered',
    SpProfileComplete = 'spProfileComplete',
    ServiceOrMaterialsPendingReview = 'serviceOrMaterialsPendingReview',
    InvoicePendingApproval = 'invoicePendingApproval',
    BidAwarded = 'bidAwarded',
    InvoiceRejected = 'invoiceRejected',
    InsuranceLapsed = 'insuranceLapsed',
    SiteEngineeringPlanApproved = 'siteEngineeringPlanApproved',
    SiteEngineeringPlanAwaitingApproval = 'siteEngineeringPlanAwaitingApproval',
    TimesheetDeclined = 'timesheetDeclined'
}

