import { LocationWithoutIds, LocationCustom } from '@gm2/common';
import { UserIdentity } from '../user/UserIdentity';
import { CompanyIdentity } from '../company/CompanyIdentity';
import { Tag } from '../tag/Tag';

export class Location extends LocationWithoutIds {
    public _id: string = '';
    public companyId: string = '';
    public custom: LocationCustom;
    public strategicAccountManagers: UserIdentity[] = [];
    public facilitiesManagers: UserIdentity[] = [];
    public productionManagers: UserIdentity[] = [];
    public tags: string[] = [];
    public tagsExpanded: Tag[] = [];
    public companyIdentity: CompanyIdentity = new CompanyIdentity();

    constructor(params?: Partial<Location>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.companyId = params.companyId || this.companyId;
            this.tags = params.tags || this.tags;
            this.tagsExpanded = Array.isArray(params.tagsExpanded)
                ? params.tagsExpanded.map(t => new Tag(t))
                : this.tagsExpanded;
            this.companyIdentity = !!params.companyIdentity
                ? new CompanyIdentity(params.companyIdentity)
                : this.companyIdentity;
            this.facilitiesManagers = Array.isArray(params.facilitiesManagers)
                ? params.facilitiesManagers.map(u => new UserIdentity(u))
                : this.facilitiesManagers;
            this.strategicAccountManagers = Array.isArray(params.strategicAccountManagers)
                ? params.strategicAccountManagers.map(u => new UserIdentity(u))
                : this.strategicAccountManagers;
            this.productionManagers = Array.isArray(params.productionManagers)
                ? params.productionManagers.map(u => new UserIdentity(u))
                : this.productionManagers;
        }
    }
}
