import { InjectionToken } from '@angular/core';

/**
 * Built in messages handled by the RfxError directive
 */
export interface RfxErrorMessages {
    /** Validators.required */
    required: string;
    /** Validators.minLength(number) */
    minlength: string;
    /** Validators.maxLength(number) */
    maxlength: string;
    /** Validators.min(number) */
    min: string;
    /** Validators.max(number) */
    max: string;
    /** Validators.email */
    email: string;
    /** RfxValidators.equalTo(AbstractControl) */
    equalto: string;
    /** RfxValidators.rangeLength([min, max]) */
    rangelength: string;
    /** RfxValidators.digits */
    digits: string;
    /** RfxValidators.equal(any) */
    equal: string;
    /** RfxValidators.uppercase */
    containsUppercase: string;
    /** RfxValidators.lowercase */
    containsLowercase: string;
    /** RfxValidators.containsNumber */
    containsNumber: string;
    /** RfxValidators.containsSpecialChar */
    containsSpecialChar: string;
    /** RfxValidators.uniqueAsync(url) */
    uniqueAsync: string;
    /** RfxValidators.emailUnique(url) */
    emailUniqueAsync: string;
    /** Validators.pattern() */
    pattern: string;
    /** Additional custom validators */
    [key: string]: string;
}

/**
 * Configuration object used by RfxError Directive
 */
export interface RfxErrorConfig {
    /**
     * Specify the messages used by the RfxError directive
     */
    messages: Partial<RfxErrorMessages>;
}

export type RfxErrorConfigToken = Partial<RfxErrorConfig>;

/**
 * Allows an application to override the default configuration
 * used by all instances of the RfxError directive
 *
 * ```Typescript
 * providers: [
 *      {
 *          provide: RFX_FORMS_ERROR_CONFIG,
 *          useValue: {
 *              messages: {
 *                  required: 'Custom Required Message'
 *              }
 *          }
 *      }
 * ]
 * ```
 */
export const RFX_FORMS_ERROR_CONFIG: InjectionToken<RfxErrorConfigToken> = new InjectionToken<
    RfxErrorConfigToken
>('rfx.forms.error.messages');

/**
 * Default values used for the error control directive
 */
export const rfxErrorDefaults: RfxErrorMessages = {
    required: 'Required',
    minlength: 'Too short',
    maxlength: 'Too long',
    min: 'Value too low',
    max: 'Value too high',
    email: 'Invalid email',
    equalto: "Fields don't match",
    rangelength: 'Invalid length',
    digits: 'Must contain only digits',
    equal: 'Invalid value',
    containsUppercase: 'An uppercase character is required',
    containsLowercase: 'A lowercase character is required',
    containsNumber: 'A number is required',
    containsSpecialChar: 'A special character is required',
    emailUniqueAsync: 'Email is already in use',
    uniqueAsync: 'Field is not unique',
    pattern: 'Invalid pattern',
    zipCode: 'Invalid zip code'
};
