import { IIdMultipleFilterWithoutIds } from '../../interface';

export class IdMultipleFilterWithoutIds implements IIdMultipleFilterWithoutIds {
    public values: any[] = [];

    constructor(params?: Partial<IdMultipleFilterWithoutIds>) {
        if (!!params) {
            this.values = Array.isArray(params.values) ? params.values : this.values;
        }
    }
}
