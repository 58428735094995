import {
    WorkOrderWorkCategory,
    WorkOrderActiveStatus,
    WorkOrderStatus,
    WorkOrderBillingType,
    CompanyType
} from '../../enums';
import { WorkOrderPhaseSimpleWithoutIds } from './work-order-phase-simple';
import { CompanyIdentityWithoutIds } from '../company/company-identity';

export class WorkOrderSimpleWithoutIds {
    public _id: any;
    public locationName: string;
    public phases: WorkOrderPhaseSimpleWithoutIds[] = [];
    public workOrderStatus: WorkOrderStatus;
    public activeStatus: WorkOrderActiveStatus;
    public createdDate: Date;
    public modifiedDate: Date;
    public workOrderNumber: string;
    public servicePartnerInvoiceNumber: string;
    public workCategory: WorkOrderWorkCategory;
    public completionDate: Date;
    public billingType: WorkOrderBillingType;
    public oneGmTotal: number = 0;
    public clientEstimate: number = 0;
    public clientPrice: number = 0;
    public templateName: string;
    public lastStatusChangedDate: Date;
    public createdByCompanyType: CompanyType;
    public createdByCompanyIdentity: CompanyIdentityWithoutIds = new CompanyIdentityWithoutIds();
    public plIdentity: CompanyIdentityWithoutIds;
    constructor(params?: Partial<WorkOrderSimpleWithoutIds>) {
        if (!!params) {
            this._id = params._id || this._id;
            this.locationName = params.locationName || this.locationName;
            this.oneGmTotal = params.oneGmTotal || this.oneGmTotal;
            this.clientEstimate = params.clientPrice || this.clientEstimate;
            this.clientPrice = params.clientPrice || this.clientPrice;
            this.phases = !!params.phases
                ? params.phases.map(p => new WorkOrderPhaseSimpleWithoutIds(p))
                : this.phases;
            this.workOrderNumber = params.workOrderNumber || this.workOrderNumber;
            this.servicePartnerInvoiceNumber = params.servicePartnerInvoiceNumber || this.servicePartnerInvoiceNumber;
            this.completionDate = params.completionDate || this.completionDate;
            this.workCategory = params.workCategory || this.workCategory;
            this.billingType = params.billingType || this.billingType;
            this.activeStatus = params.activeStatus || this.activeStatus;
            this.workOrderStatus = params.workOrderStatus || this.workOrderStatus;
            this.createdDate = (!!params.createdDate) ? new Date(params.createdDate) : this.createdDate;
            this.modifiedDate = (!!params.modifiedDate) ? new Date(params.modifiedDate) : this.modifiedDate;
            this.templateName = params.templateName || this.templateName;
            this.lastStatusChangedDate = (!!params.lastStatusChangedDate) ? new Date(params.lastStatusChangedDate) : this.lastStatusChangedDate;
            this.createdByCompanyType = params.createdByCompanyType || this.createdByCompanyType;
            this.createdByCompanyIdentity = !!params.createdByCompanyIdentity
                ? new CompanyIdentityWithoutIds(params.createdByCompanyIdentity)
                : this.createdByCompanyIdentity;

            this.plIdentity = !!params.plIdentity
                ? new CompanyIdentityWithoutIds(params.plIdentity)
                : this.plIdentity;
        }
    }
}
