import { CompanyServiceWithoutIds } from '@gm2/common';

export class CompanyServiceItem extends CompanyServiceWithoutIds {
    public _id: string;

    constructor(params?: Partial<CompanyServiceItem>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
        }
    }
}
