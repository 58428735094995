import { BaseMediaDocumentWithoutIds } from '../media/base-media-document';

export class QuestionnaireAnswerWithoutIds {
    public answer: string = '';
    public media: BaseMediaDocumentWithoutIds = null;

    constructor(params?: Partial<QuestionnaireAnswerWithoutIds>) {
        if (!!params) {
            this.answer = params.answer || this.answer;
            this.media = params.media ? new BaseMediaDocumentWithoutIds(params.media) : this.media;
        }
    }
}
