import { Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

export interface RfxGridControl {
    name: string;
    controlGroup: UntypedFormGroup;
}

@Directive({
    selector: '[rfxGridCustomControl]'
})
export class RfxGridCustomControl {}
