<h2 mat-dialog-title>Progress for Re-Activated Work Order</h2>
<div mat-dialog-content>
	<form [formGroup]="form">
        <mat-form-field>
            <textarea
                matInput
                placeholder="Reason for Re-Activation"
                formControlName="message"
                required
            ></textarea>
        </mat-form-field>
		<mat-form-field>
            <mat-label>Progress</mat-label>
            <mat-select required placeholder="Progress" formControlName="status">
                <mat-option *ngFor="let o of statusOptions" [value]="o">
                    {{ WorkOrderStatus.toString(o) }}
                </mat-option>
            </mat-select>
		</mat-form-field>
		<div class="buttons">
    	    <button 
    	    	mat-flat-button 
    	    	(click)="close()"
        	>
        		Cancel
        	</button>
    	    <button 
    	    	mat-flat-button 
    	    	color="primary" 
    	    	(click)="confirm()"
    	    	[disabled]="!form.valid"
        	>
        		Confirm
        	</button>
		</div>
	</form>
</div>

