import { StringFilter } from './string-filter';
import { IStringTermMultipleFilter } from '../../interface';

export class StringTermMultipleFilter implements IStringTermMultipleFilter {
    public key: string = '';
    public values: string[] = [];

    constructor(params?: Partial<StringTermMultipleFilter>) {
        if (!!params) {
            this.key = params.key || this.key;
            this.values = Array.isArray(params.values) ? params.values : this.values;
        }
    }
}
