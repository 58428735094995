import { WorkOrderDetailsWithoutIds } from '@gm2/common';
import { CompanyIdentity } from '../company/CompanyIdentity';
import { LocationIdentity } from '../location/location-identity';

export class WorkOrderDetails extends WorkOrderDetailsWithoutIds {
    public _id: string;
    public clientIdentity: CompanyIdentity = new CompanyIdentity();
    public locationIdentity: LocationIdentity = new LocationIdentity();
    public createdByCompanyIdentity: CompanyIdentity = new CompanyIdentity();
    public plIdentity: CompanyIdentity;

    constructor(params?: Partial<WorkOrderDetails>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.clientIdentity = !!params.clientIdentity
                ? new CompanyIdentity(params.clientIdentity)
                : this.clientIdentity;
            this.locationIdentity = !!params.locationIdentity
                ? new LocationIdentity(params.locationIdentity)
                : this.locationIdentity;
            this.createdByCompanyIdentity = !!params.createdByCompanyIdentity
                ? new CompanyIdentity(params.createdByCompanyIdentity)
                : this.createdByCompanyIdentity;
            this.plIdentity = !!params.plIdentity
                ? new CompanyIdentity(params.plIdentity)
                : this.plIdentity;
        }
    }
}
