export enum WorkOrderLaborSelectedRate {
    OneGMRate = '[WorkOrderLaborSelectedRate] OneGMRate',
    ServicePartnerRate = '[WorkOrderLaborSelectedRate] ServicePartnerRate'
}

export namespace WorkOrderLaborSelectedRate {
    export function toString(s: WorkOrderLaborSelectedRate): string {
        switch (s) {
            case WorkOrderLaborSelectedRate.OneGMRate:
                return 'One GM Rate';
            case WorkOrderLaborSelectedRate.ServicePartnerRate:
                return 'Service Partner Rate';
            default:
                return '';
        }
    }

    export const members: WorkOrderLaborSelectedRate[] = [
        WorkOrderLaborSelectedRate.OneGMRate,
        WorkOrderLaborSelectedRate.ServicePartnerRate
    ];
}
